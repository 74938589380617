import {Trans} from 'react-i18next'
import NotificationBar from '../../../../Common/NotificationBar'
import messages from '../../../../../assets/messages'
import {Link} from 'react-router-dom'
import {StyledComponentProps, withStyles} from '@material-ui/core'

const styles = (theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
})

type CryptoWithdrawalsMessageProps = StyledComponentProps<'link'>;

const _CryptoWithdrawalsMessage = ({classes}: CryptoWithdrawalsMessageProps) => {
  const handleLinkClick = () => {
    window.LC_API?.open_chat_window()
  }

  return (
    <NotificationBar status="info">
      <Trans {...messages.withdrawalByCryptoNotice}>
        If you would like to withdraw profits via crypto, please contact <Link to='#' className={classes?.link} onClick={handleLinkClick}>live chat here</Link> in order to verify wallet details.
      </Trans>
    </NotificationBar>
  )
}

export const CryptoWithdrawalsMessage = withStyles(styles, {withTheme: true})(_CryptoWithdrawalsMessage)
