import React from 'react'
import AppContext from '../../Common/contexts/AppContext'
import withStyles from '@material-ui/core/styles/withStyles'
import {withNamespaces} from 'react-i18next'
import {FullScreenDialog} from '../../Common/Dialog'
import {config} from '../../../config'

const styles = theme => ({
  main: {
    height: '100%',
  },
  iframe: {
    minHeight: 600
  }
})

class LydianAccountView extends React.Component {
  static contextType = AppContext

  render() {
    const {externalIframeAccountLink} = config
    const {classes, match: {params: {accountId}}} = this.props
    return (
      <FullScreenDialog
        desktopOnly
        fullScreen
        open={true}
        classes={this.props.classes}
        onClose={() => this.props.history.push('/accounts')}
      >
        <iframe
          src={externalIframeAccountLink + accountId}
          width="100%"
          height="100%"
          frameBorder="0"
          title="lydian account view"
          className={classes.iframe}
        />
      </FullScreenDialog>
    )
  }
}


export default withStyles(styles, {withTheme: true})(withNamespaces()(LydianAccountView))
