import React, {Component} from 'react'
import {map, get, flowRight as compose} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import messages from '../../assets/messages'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import Images from '../Common/Images'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import {isMobile, setCookie, getCookie} from '../../common/utils'
import Button from '@material-ui/core/Button'
import {AlertDialog} from '../Common/Dialog'
import {referAndEarnDetails} from '../../common/utils/uioptions'
import AppContext from '../Common/contexts/AppContext'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  cardDiv: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 15,
    padding: 15
  },
  closeIcon: {
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: 30,
    color: theme.palette.secondary.main,
  },
  pointer: {
    cursor: 'pointer',
  },
  ol: {
    color: theme.palette.primary.main,
    fontSize: 16,
    paddingLeft: 15
  },
  li: {
    paddingLeft: 5
  },
  img: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 250,
      maxWidth: 250
    },
  },
  subTitle: {
    paddingBottom: 15,
  },
  howDoesWork: {
    paddingBottom: 0
  },
  link: {
    '&:hover':{
      backgroundColor: theme.palette.background.default,
    },
  }
})

class Instructions extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    const cookie = getCookie('instructions_closed')
    super(props)
    this.state = {
      show: !isMobile() && !cookie,
      cookie
    }
  }

  closeInstructions() {
    const {cookie} = this.state
    this.setState({show: false})
    !cookie && setCookie('instructions_closed', true, 30)
  }

  renderContent() {
    const {classes, awards} = this.props
    const {companyObject} = this.context
    const company = companyObject.whiteLabelBrand || companyObject.brandLabel
    const {referrerInstructions, minDeposit, minTrades, days, currency} = referAndEarnDetails

    return <Grid container className={classNames(!isMobile() && classes.cardDiv)} spacing={3}>
      {!isMobile() && <Grid item xs={12}>
        <CloseIcon className={classNames(classes.closeIcon, classes.pointer)} onClick={() => this.closeInstructions()}/>
      </Grid>}
      <Grid item xs={12} md={5}>
        <img className={classes.img} src={Images['referAndEarn.png']} alt='referAndEarn' />
      </Grid>
      <Grid item xs={12} md={7}>
        <Grid container spacing={2}>
          {!isMobile() && <Grid item xs={12} className={classes.subTitle}>
            <Typography variant='h2'><Trans {...messages.howDoesWork} /></Typography>
          </Grid>}
          <Grid item xs={12}>
            <Typography variant='body1'><Trans {...messages.startRefer} values={{company, amountReferrer: `${currency}${get(awards, 'referrerAward')}`,
              amountReferral: `${currency}${get(awards, 'referralAward')}`}}/></Typography>
          </Grid>
          <Grid item xs={12}>
            <ol className={classes.ol}>
              {map(referrerInstructions, (key) => <li key={key} className={classes.li}>
                <Typography variant='body1'>
                  <Trans {...messages[key]} values={{amountReferrer: `${currency}${get(awards, 'referrerAward')}` , minDeposit: `${currency}${minDeposit}`, minTrades, days}}/></Typography>
              </li>)
              }
            </ol>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }

  render() {
    const {classes, t} = this.props
    const {show} = this.state

    return <Grid container spacing={0} justifyContent="flex-end">
      {!show ? <Button color="primary" className={classNames(classes.link, classes.howDoesWork)}
        onClick={() => this.setState({show: true})}>
        <Trans {...messages.howDoesWork} />
      </Button> : !isMobile() && show ? this.renderContent() : <AlertDialog
        title={t(messages.howDoesWork.i18nKey, messages.howDoesWork.defaults)}
        open={show}
        onClose={() => this.closeInstructions()}
      >
        {this.renderContent()}
      </AlertDialog>}
    </Grid>
  }
}

export default  compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(Instructions)
