import React from 'react'
import {graphql} from 'react-apollo'
import {get, first, findIndex, find, isEmpty, includes, filter, flowRight as compose} from 'lodash'
import {withRouter, Link, Redirect} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../Common/Loading'
import PageTitle from '../../Common/PageTitle'
import PageBody from '../../Common/PageBody'
import messages from '../../../assets/messages'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY} from '../../../graphql/queries'
import ForexRoutes from './routes'
import {isMobile} from '../../../common/utils/browser'
import {getItem, hasWebTrader} from '../../../common/utils'
import ForexTradeNow from './ForexTradeNow'
import Typography from '@material-ui/core/Typography'
import {accountTypes} from '@bdswiss/common-enums'
import {getAccountLabel, isMt5ForexAccount} from '../../../common/utils/accounts'
import {config} from '../../../config'
import AppContext from '../../Common/contexts/AppContext'
import {getStrategiesDetails} from '../../../common/utils/requests'
import {InnerAppContext} from '../../../common/types'
const styles = theme => ({
  tradeNowButton: {
    marginTop: 6
  }
})

class ForexAccountView extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext

  state = {
    strategies: {}
  } as any

  handleChange(tabs, value) {
    const {history, match} = this.props
    const currentTab = tabs[value]
    history.push(`${match.url}${currentTab.path}`)
  }

  closeDialog() {
    const {history} = this.props
    history.location.state && history.location.state.from && history.location.state.from==='/login' ?
      this.context.logout() : history.push('/accounts')
  }

  componentDidMount() {
    const {account} = this.props
    getStrategiesDetails(account.id).then((res) => {
      this.setState({strategies: res})
    })
  }

  render() {
    const {location, match, loading, viewerLoading, account, account:{competitions}, classes, history, t, viewer: {company, locale, address}, match:{url}} = this.props
    const {showTab, key, showMT4DownloadLink, downloadTrader, featuresConfig:{copyTradingEnabled, competitions: competitionsAllowed}, common:{globalCopyTradingEnabled}} = config

    if (loading || viewerLoading || !account) return <Loading />
    const accountType = accountTypes[account.__typename]
    const label = getAccountLabel(account, locale)
    const downloadTraderLabel = accountType && downloadTrader[accountType.category]
      ? downloadTrader[accountType.category].shortLabel : downloadTrader.default.shortLabel
    if (accountType.subscription && isEmpty(account.subscription) && location.pathname.indexOf('plan') === -1)
      return <Redirect to={{pathname: `/accounts/${account.id}/plan/payment`}}/>
    const hasSubscription =  !isEmpty(account.subscription)
    const showTradeNow = accountType.subscription ? hasSubscription : true
    const accountSubtype = get(account,'accountSubtype')
    const checkAccountCopyTrading = includes(get(copyTradingEnabled,'accountSubTypes',[]), accountSubtype) &&
    includes(get(copyTradingEnabled,'accountTypesCategory',[]), accountType.category) && !account.isDemo
    && !account.isViewOnly && !account.isArchived && isEmpty(find(get(account,'competitions',[]), 'active'))

    let enableCopyTrading = !isEmpty(this.state.strategies) && checkAccountCopyTrading

    if (enableCopyTrading) {
      const providerType = get(this.state.strategies[0], 'copyTradingProvider')
      enableCopyTrading = get(globalCopyTradingEnabled, providerType)
      || (!get(globalCopyTradingEnabled, providerType) && get(account.copyTrading,'isActive'))
    }

    const showCompetitions = competitionsAllowed && competitions.length > 0 && !includes(get(competitionsAllowed, 'blockedCountries'), get(address, 'country', '')?.toUpperCase())
    const showProviderStats = get(account, 'showProviderStats')
    let  tabs
    if (account.isArchived || account.isViewOnly) {
      tabs = [
        {path: '', label: t(messages.overview.i18nKey, messages.overview.defaults)},
        {path: '/transactions/history', label: t(messages.payments.i18nKey, messages.payments.defaults)},
      ]
    } else {
      tabs = [
        {path: '', label: t(messages.overview.i18nKey, messages.overview.defaults)},
        {path: '/transactions', label: t(messages.payments.i18nKey, messages.payments.defaults), hidden: !showTab?.transactions},
        {path: '/downloadTrader', label: downloadTraderLabel, hidden: !showTab?.downloadTrader},
        {path: '/copyTrading', label: t(messages.copyTrading.i18nKey, messages.copyTrading.defaults), hidden: !enableCopyTrading},
        {path: '/tradingCompetitions', label: t(messages.tradingCompetitions.i18nKey, messages.tradingCompetitions.defaults), hidden: !showCompetitions},
        {path: '/providerStats', label: t(messages.providerStats.i18nKey, messages.providerStats.defaults), hidden: !showProviderStats},
        {path: '/settings', label: t(messages.settings.i18nKey, messages.settings.defaults)},
        {path: '/plan', label: t(messages.plan.i18nKey, messages.plan.defaults), hidden: !hasSubscription},
      ]
    }

    const currentTab =
      find(tabs, (tab) => tab.path.length > 0 && !tab.hidden && location.pathname.indexOf(`${match.url}${tab.path}`) > -1) ||
        first(tabs)
    const currentTabIndex = findIndex(filter(tabs, (t) => !t.hidden), currentTab)

    const fxmt4SupportedCountries = ['bdSwissMauritius', 'tauroMarkets']

    return (<React.Fragment>
      <PageTitle
        title={label}
        onBack={() => this.closeDialog()}
        rightAction={
          <div className={classes.tradeNowButton}>
            {!isMt5ForexAccount(account.__typename) && hasWebTrader() && showTradeNow && <ForexTradeNow type='button' account={account} company={company} locale={locale || getItem('locale', 'en')} />}
            {showMT4DownloadLink && <Link to={`${url}/downloadTrader`}>
              <Typography variant="body2" color="primary" align='center'>
                <Trans {...messages.downloadMT4} values={{product:downloadTraderLabel}}/>
              </Typography>
            </Link>}
            {fxmt4SupportedCountries.includes(key) && config.downloadTrader.forexMt4.Links.web && <a href={config.downloadTrader.forexMt4.Links.web} target="_blank" rel="noreferrer">
              <Typography variant="body2" color="primary" align='center'><Trans {...messages.webtraderMT4}/></Typography>
            </a>}
          </div>
        }
        subtitle={
          <Trans {...messages.accountID} values={{accountId: account.remoteId}} />
        }
      >
        <Tabs
          variant={isMobile()? 'scrollable' : 'standard'}
          value={currentTabIndex}
          onChange={(_, i) => this.handleChange(tabs, i)}
          classes={{root: classes.tabsRoot}}>
          {tabs.map((tab, key) => !tab.hidden &&
            <Tab
              key={key}
              label={tab.label}
              textColor="inherit"
              onClick={() => history.push(`/accounts/${account.id}${tab.path}`)} />
          )}
        </Tabs>
      </PageTitle>
      <PageBody>
        <ForexRoutes/>
      </PageBody>
    </React.Fragment>)
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps: {match}}: any) => {
      const {accountId} = match.params
      const account = get(data, 'viewer.accounts', []).find(account => account.id === Number(accountId))
      return {
        error,
        loading,
        account,
      }
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading: viewerLoading}, data}: any) => ({
      error,
      viewerLoading,
      viewer: get(data, 'viewer'),
    })
  })
)(ForexAccountView)
