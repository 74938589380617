import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {flowRight as compose, get, includes, map, filter} from 'lodash'
import {Link, withRouter} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import AppBar from '@material-ui/core/AppBar'
import {languages} from '@bdswiss/common-enums'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import {withStyles} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import ArrowDown from '@material-ui/icons/ExpandMore'
import AppContext from '../Common/contexts/AppContext'
import {config} from '../../config'
import FlagIcon from '../FlagIcon'
import classNames from 'classnames'
import {getItem, getSessInfoFromLocalStorage} from '../../common/utils'
import {isDarkTheme, getCurrentTheme} from '../../common/utils/general'
import {InnerAppContext, ProvidedProps} from '../../common/types'
import {newStyle} from '../../themes/components'
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints'

const styles = newStyle('Header',theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop:'2rem',
    marginBottom:'1.5rem',
    width: '100%',
  },
  rootPartner: {
    backgroundColor: 'transparent',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    margin: '0 auto',
  },
  container: {
    maxWidth: theme.mainBodyWidth,
    margin:'0 auto',
  },
  menuIcon: {
    marginRight: '14px'
  },
  logo: {
    maxWidth: 230,
    maxHeight: 80,
  },
  logoDiv: {
    flexGrow:1,
  },
  fullWidth: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  link: {
    paddingLeft: 25,
    fontSize: 14,
    color: theme.palette.grey.color
  },
  arrowLink:{
    verticalAlign: 'middle',
    padding: 1,
    marginTop: '-3px',
    color: theme.palette.secondary.dark,
  },
  arrowLinkPartner:{
    verticalAlign: 'middle',
    padding: 1,
    marginTop: '-3px',
    color: theme.palette.secondary.light,
  },
  pointer:{
    cursor: 'pointer'
  }
}))

type HeaderProps = ProvidedProps<ReturnType<typeof styles>> & {
  handleSidebarToggle: (e: any) => void,
  linkTo: string,
  redirectToWebsiteUrl: string,
  outer: boolean,
  customBackground: boolean,
  width: Breakpoint
}

class Header extends Component<HeaderProps,any> {
  static propTypes = {
    handleSidebarToggle: PropTypes.func,
  }
  static contextType = AppContext
  context!: InnerAppContext
  closeLanguage() {
    if (this.props.location.pathname==='/change-language' || this.props.location.pathname==='/language') {
      if (get(this.props.history.location, 'state.prevPath') === '/login') {
        this.props.history.push('/accounts')
      } else {
        this.props.history.goBack()
      }
    }
  }

  render() {
    const {classes, handleSidebarToggle, history, linkTo, redirectToWebsiteUrl, outer, customBackground} = this.props
    const {logoFileName, logoWhiteFileName, name: logoAlt} = config
    let locale = (this.context.clientType) ? this.context.locale:getItem('locale','en')

    if (!includes(map(filter(languages, (lang) => !lang.disabled && !!lang.client), 'value'), locale)) locale = 'en'

    const themePreference = getCurrentTheme(this.context)

    let logo = ''
    const logoAsset = customBackground ||isDarkTheme(themePreference) ? logoWhiteFileName : logoFileName
    try {
      logo = require(`../../assets/images/${logoAsset}`).default
    } catch (e)
    {
      //
    }
    const isMobileView = this.props.location.pathname==='/more'
    const onClickLink = linkTo || '/accounts'

    return (<AppBar position="static"
      elevation={0}
      color="default"
      className={customBackground ? classes.rootPartner : classes.root}
    >
      <Toolbar>
        <Hidden mdUp>
          {!isMobileView && !!getSessInfoFromLocalStorage() && <IconButton
            aria-label="Open drawer"
            className={classes.menuIcon}
            onClick={(e) => handleSidebarToggle && handleSidebarToggle(e)}
          >
            <MenuIcon />
          </IconButton>
          }
        </Hidden>
        <Grid className={classes.container} container alignItems='center'>
          <Grid item className={classes.logoDiv}>
            {redirectToWebsiteUrl ? <a href={redirectToWebsiteUrl}><img src={logo} alt={logoAlt} className={classNames(classes.logo, classes.pointer)}/></a>
              : <img src={logo} alt={logoAlt} className={classNames(classes.logo,classes.pointer)} onClick={()=> history.push(onClickLink)} />}
          </Grid>
          <Grid item>
            <Link to={{pathname: (outer)?'/language':'/change-language', state: {prevLocation: this.props.location}}}
              className={classes.fullWidth} onClick={() => {this.closeLanguage()}}>
              <Grid lang={locale}>
                <FlagIcon
                  code={languages[locale].flag}
                  className={classes.link}
                  size={'lg'}
                />
                <span className={customBackground ? classes.arrowLinkPartner : classes.arrowLink}>
                  {isWidthDown('sm', this.props.width) ? ' ' : languages[locale].native}</span>
                <ArrowDown className={customBackground ? classes.arrowLinkPartner : classes.arrowLink} />
              </Grid>
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>)
  }
}


export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
  withWidth()
)(Header)
