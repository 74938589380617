import React, {Component} from 'react'
import {flowRight as compose, endsWith} from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'
import {withNamespaces} from 'react-i18next'
import AppContext from './contexts/AppContext'
import {config} from '../../config'
import {LiveChatWidget} from '@livechat/widget-react'
import {isMobile} from '../../common/utils'

class LivechatBubble extends Component {
    static contextType = AppContext
    componentDidMount() {
      const {email} = this.props
      const {common: {autoTestEmail}} = config
      //@ts-ignore
      if (!endsWith(email, autoTestEmail) && window.liveChat) {
        window.LC_API?.open_chat_window()
      }
    }

    componentWillUnmount() {
      isMobile() && window.liveChat && window.LC_API?.hide_chat_window()
    }

    addLiveChatBubble (appendLiveChatProp) {
      const chatSelector = document.getElementById('chat-widget-container')
      if (chatSelector) {
        chatSelector.classList.add('livechat-bubble')
        if (appendLiveChatProp) {
          window.liveChat = true
          return
        }
        window.LC_API?.open_chat_window()
      }
    }

    removeLiveChatBubble () {
      const chatSelector = document.getElementById('chat-widget-container')
      if (chatSelector) {
        chatSelector.classList.remove('livechat-bubble')
      }
    }

    render() {
      const {lng: locale, fullName, email} = this.props
      const {featuresConfig: {livechat}, common: {autoTestEmail}} = config
      return <React.Fragment>
        {!endsWith(email, autoTestEmail) && livechat && <LiveChatWidget
          license={livechat.key}
          group={livechat[locale] || livechat['en']}
          customerName={fullName}
          customerEmail={email}
          chatBetweenGroups={false}
          visibility='minimized'
          onVisibilityChanged={(data) => {
            switch (data.visibility) {
              case 'maximized':
                this.addLiveChatBubble()
                break
              case 'minimized':
                this.removeLiveChatBubble()
                break
              default:
                this.removeLiveChatBubble()
                break
            }
          }}
          onReady={() => this.addLiveChatBubble(true)}
        />
        }
      </React.Fragment>
    }
}

export default compose(
  withStyles({},{withTheme: true}),
  withNamespaces(),
)(LivechatBubble)
