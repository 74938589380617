import React, {Component} from 'react'
import {map, orderBy, flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import {Trans} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Avatar from '@material-ui/core/Avatar'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CheckIcon from '@material-ui/icons/Check'
import {currencies} from '@bdswiss/common-enums'
import Typography from '@material-ui/core/Typography'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import messages from '../../../assets/messages'
import {getFormattedAmount} from '../../../common/utils'
import PageSubTitle from '../../Common/PageSubTitle'
import {isMobile} from '../../../common/utils/browser'
import {isCentAccount} from '../../../common/utils/accounts'
import NotificationBar from '../../Common/NotificationBar'

const style = (theme) => ({
  highlightedText: {
    color: theme.palette.primary.main,
    cursor:'pointer'
  },
  logoAvatar: {
    width: 40,
    height: 40,
    borderRadius: '5%',
    background: theme.palette.common.white
  },
  logoImg: {
    width: '100%',
    height: 'unset'
  },
  listItemRoot: {
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
  },
  listItemText: {
    padding: '0 16px'
  }
})

export class PaymentMethodSelection extends Component {
  render() {

    const {amount, account, locale, countryPaymentOptions, classes, onPaymentMethodSelected, selectedPaymentMethodId, goBack} = this.props
    const formattedAmount = isCentAccount(account)
      ? getFormattedAmount({amount: amount / (currencies.CUD.baseCurrencyRate ?? 1), currency: currencies.CUD.baseCurrency, locale})
      : getFormattedAmount({amount, currency: account.currency, locale})
    return (
      <Grid container spacing={!isMobile()?3:0}>
        <Grid item xs={12}>
          <PageSubTitle>
            <Trans
              {...messages.selectDepositMethodForAmount}
              values={{amount: formattedAmount}}
              components={[<span onClick={() => goBack()} className={classes.highlightedText}>amount</span>]}
            />
          </PageSubTitle>
          {isCentAccount(account) && <NotificationBar status="info" noMargin>
            <Typography variant="body1">
              <Trans {...messages.receiveInCUD} values={{amount: Number(amount).toLocaleString()}} />
            </Typography>
          </NotificationBar>}
        </Grid>
        <Grid item xs={12}>
          <List>
            {
              map(orderBy(countryPaymentOptions, (opt) => opt.rank, ['desc']), (opt, i) => {
                const {paymentOption} = opt
                return (
                  <React.Fragment key={i}>
                    <ListItem classes={{root: classes.listItemRoot}} key={i} button onClick={(e) => onPaymentMethodSelected(opt.id)}>
                      <Avatar alt={paymentOption.name} src={paymentOption.logoUrl} className={classes.logoAvatar} imgProps={{className: classes.logoImg}}/>
                      <ListItemText primary={paymentOption.name} className={classes.listItemText}/>
                      { opt.id === selectedPaymentMethodId &&
                        <ListItemSecondaryAction>
                          <CheckIcon className={classes.highlightedText} />
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  </React.Fragment>
                )
              })
            }
          </List>
        </Grid>
      </Grid>
    )
  }
}

PaymentMethodSelection.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  countryPaymentOptions: PropTypes.array.isRequired
}

export default compose(
  withStyles(style)
)(PaymentMethodSelection)
