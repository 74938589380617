//@ts-nocheck
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import {withRouter} from 'react-router-dom'
import Input from '@material-ui/core/Input'
import {withNamespaces, Trans} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import GenericIFramedProvider from './GenericIFramedProvider'
import {debounce, isEmpty, values, find, map, forEach, flowRight as compose} from 'lodash'
import {payzoffPaymentOptions, frontends} from '@bdswiss/common-enums'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import messages from '../../../../assets/messages'
import {getPlatform} from '../../../../common/utils'

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
})

const validators = {
  iban: (value) => !value && messages.requiredField,
  accountholdername: (value) => !value && messages.requiredField,
  bic: (value) => !value && messages.requiredField,
}

const initialState = {form: {}, errors: {}, creatingDeposit: false}
class PayzoffProvider extends Component {

  constructor(props) {
    super(props)
    this.validateFields = debounce(this.validateFields, 200)

    const {providerProperties: {paymentKey}} = this.props
    const payzoffPaymentOption = find(payzoffPaymentOptions, {value: paymentKey})
    forEach(payzoffPaymentOption.fields, (v) => initialState.form[v] = '')
    this.state = initialState
  }

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields())
  }

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      const validate = validators[k]
      errors[k] = validate && validate(form[k])
    })
    this.setState({errors})
  }

  formIsValid() {
    const {providerProperties: {paymentKey}} = this.props
    const payzoffPaymentOption = find(payzoffPaymentOptions, {value: paymentKey})
    if (isEmpty(payzoffPaymentOption.fields)) return true

    const {form, errors} = this.state
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(form).filter((v) => v))
  }

  doCreateDeposit() {
    if (!this.formIsValid()) return

    this.setState({creatingDeposit: true})

    const {providerProperties: {provider, paymentKey}, amount, account, onError, useVirtualPaymentPage, bonusAmount, bonusTerms} = this.props
    const {form} = this.state

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        paymentKey,
        platform: getPlatform(),
        useVirtualPaymentPage,
        fields: {
          ...form,
        },
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment} = newDeposit
        const parsedUrl = JSON.parse(payment.url)
        this.setState({iframeUrl: parsedUrl.payment_url, creatingDeposit: false})
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  render() {
    const {classes, providerProperties: {paymentKey}} = this.props
    const {form, errors, creatingDeposit, iframeUrl} = this.state

    const payzoffPaymentOption = find(payzoffPaymentOptions, {value: paymentKey})

    return (
      <React.Fragment>
        {iframeUrl && <GenericIFramedProvider {...this.props} iframeUrl={iframeUrl} />}
        <Grid container direction="row" spacing={3}>
          {
            map(payzoffPaymentOption.fields, (field) =>
              <Grid item xs={12} key={field}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor={`payzoff-${field}`}>
                    <Trans {...messages[field]} />
                  </InputLabel>
                  <Input
                    id={`payzoff-${field}`}
                    type="text"
                    error={!!errors[field]}
                    value={form[field]}
                    onChange={(e) => this.handleChange(field, e.target.value)}
                  />
                  {
                    errors[field] &&
                    <FormHelperText className={classes.error}><Trans {...errors[field]} /></FormHelperText>
                  }
                </FormControl>
              </Grid>
            )
          }
          <PaymentActionButton
            loading={creatingDeposit}
            disable={!this.formIsValid()}
            onClick={() => this.doCreateDeposit()}
          />
        </Grid>
      </React.Fragment>
    )
  }
}

PayzoffProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withNamespaces(),
  withRouter,
  withCreateDeposit,
)(PayzoffProvider)
