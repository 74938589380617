import React, {Component} from 'react'
import Select, {components} from 'react-select'
import Typography from '@material-ui/core/Typography'
import NoSsr from '@material-ui/core/NoSsr'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import messages from '../../assets/messages'
import {find, flatten, get, flowRight as compose, lowerCase} from 'lodash'
import FlagIcon from '../FlagIcon'
import {countries} from '@bdswiss/common-enums'

const styles = theme => ({
  input: {
    display: 'flex',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: -4
    },
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    flex: 1,
    overflow: 'hidden',
    cursor: 'pointer'
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
    marginBottom: -3,
    marginTop: -1,
  },
  placeholder: {
    position: 'absolute' as const,
    bottom: 1,
    color: theme.palette.grey[500],
    [theme.breakpoints.down('sm')]: {
      bottom: 10,
    },
  },
  paper: {
    position: 'absolute' as const,
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
  },
  red: {
    color: theme.palette.red.color,
    position: 'absolute' as const,
    bottom: 1,
  },
  fontLight:{
    fontWeight: 300
  },
  flagItem: {
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 10,
  },
  inputComponent:{
    color: theme.palette.grey.color
  },
  menuItemRoot: {
    paddingTop: 10,
    paddingBottom: 10
  },
})

function inputComponent({inputRef, ...props}) {
  return <div ref={inputRef} {...props} />
}


function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent: inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      classes={{root: props.selectProps.classes.menuItemRoot}}
      {...props.innerProps}
    >
      <FlagIcon
        code={get(props, 'value').toLowerCase()}
        className={props.selectProps.classes.flagItem}
        size={'lg'}
      />
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      className={(props.selectProps.errors[props.selectProps.name])?props.selectProps.classes.red:props.selectProps.classes.placeholder}
      {...props.innerProps}
      variant="body2"
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps} variant="body2">
      {props.data && props.data.value && <FlagIcon
        code={props.data.value.toLowerCase()}
        className={props.selectProps.classes.flagItem}
        size={'lg'}
      />}
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>
    {props.data && props.data.value && <FlagIcon
      code={props.data.value.toLowerCase()}
      className={props.selectProps.classes.flagItem}
      size={'lg'}
    />}
    {props.children}
  </div>
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
      variant="body2"
    >
      {<Trans {...messages.noOptions}/>}
    </Typography>
  )
}

const Input = ({autoComplete, ...props}) => <components.Input {...props} autoComplete="none" className={props.selectProps.classes.inputComponent} />

export class CountrySelect extends Component<any,any> {

  findValue(options, value, name, prefixValue) {
    const country = countries[lowerCase(value)] || get(find(countries, (c) => c.value === value), 'key')
    if (name === 'prefix') {
      return find(options,(option) => (value && country.callingCodes) ? option.value === value && option.label === prefixValue : option.value === value)
    } else {
      return find(options,(option) => option.label === value)
    }
  }

  render() {
    const {classes, countryList, label, errors, value, name, phonePrefix, returnKey, prefixValue, showRequired} = this.props
    const suggestions = countryList
    const options = flatten(suggestions.map(suggestion => (phonePrefix && suggestion.callingCodes) ?
      suggestion.callingCodes.map((code) => ({
        value:  suggestion.value,
        label: `+${code}`,
        key: suggestion.key,
      }))
      : {
        value:  suggestion.value,
        label: (!phonePrefix) ? get(suggestion, 'nationality') || get(suggestion, 'label') :`+${suggestion.callingCode}`,
        key: suggestion.key,
      }))

    const components = {
      Control,
      Menu,
      NoOptionsMessage,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer,
      Input
    }

    return <div className={classes.root}>
      <NoSsr>
        <Select
          countries={countryList}
          classes={classes}
          options={options}
          components={components}
          value={this.findValue(options,value, name, prefixValue) || ''}
          onChange={(e) => {
            this.props.handleChangeField(e.value)
            this.props.handleChange(name,(!phonePrefix)? ((returnKey) ? e.key : e.value ):e.label)
            this.props.setStateOuter(`${name}Changed`,true)
          }}
          placeholder={(value)?'':`${label} ${showRequired ? '*' : ''}`}
          textFieldProps={{
            label: (!value)?' ':`${label} ${showRequired ? '*' : ''}`,
            InputLabelProps: {
              shrink: true
            },
            error:errors[name]
          }}
          errors={errors}
          name={name}
        />
      </NoSsr>
    </div>
  }
}

export default compose(withStyles(styles, {withTheme: true}), withNamespaces())(CountrySelect)
