import React from 'react'
import {isFunction, get, flowRight as compose} from 'lodash'
import vhCheck from 'vh-check'
import classnames from 'classnames'
import {withRouter} from 'react-router-dom'
import {withNamespaces} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Badge from '@material-ui/core/Badge'
import UiNotification from '../Common/UiNotification'
import AppRoutes from './routes'
import AppContext from '../Common/contexts/AppContext'
import messages from '../../assets/messages'
import {ReactComponent as AccountsIcon} from '../../assets/images/mobile/accounts.svg'
import {ReactComponent as MoreIcon} from '../../assets/images/mobile/more.svg'
import {ReactComponent as NotificationsIcon} from '../../assets/images/mobile/notifications.svg'
import {ReactComponent as PaymentsIcon} from '../../assets/images/mobile/payments.svg'
import {getPendingVerificationsCount} from '../../common/utils/general'
import Button from '@material-ui/core/Button'
import {getAllowedAccounts} from '../../common/utils/accounts'
import {hideElementsDependedOfWL} from '../../common/utils'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  wrap: {
    height: 'calc(100vh - var(--vh-offset, 0px))',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column' as const,
    '-webkit-overflow-scrolling': 'touch',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
  },
  bottomNavigationRoot: {
    stroke: theme.palette.grey.color,
  },
  bottomNavigationSelected: {
    stroke: theme.palette.primary.main,
  },
  bottomNavigationWrapper: {
    boxShadow: `0 0 4px ${theme.palette.lightgrey.color}` as const,
    position: 'sticky' as const,
    bottom: 0,
    zIndex: 2
  },
  invisible: {
    display: 'none'
  },
  badge: {
    backgroundColor: theme.palette.red.color,
    color: theme.palette.red.textColor,
    position: theme.direction === 'rtl' ? 'relative' as const : 'absolute' as const
  },
  floatingButton: {
    width: 48,
    height: 48,
    minWidth: 48,
    background: theme.palette.lightgreyBackgroundSolid.color,
    borderRadius: '50%',
    boxShadow:theme.boxShadowStyle,
    color: theme.palette.primary.main,
    position: 'fixed' as const,
    bottom: 75,
    right: 17,
    '&:hover':{
      background: theme.palette.lightgreyBackgroundSolid.color,
    },
  },
  plus: {
    fontSize: 28,
    fontWeight:300
  }
})

class Mobile extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    vhCheck()
  }

  checkActiveTab() {
    let activeTab = 3
    if (window.location.pathname.indexOf('/accounts') === 0) activeTab = 0
    if (window.location.pathname.indexOf('/transactions') === 0) activeTab = 1
    if (window.location.pathname.indexOf('/support') === 0) activeTab = 2
    return activeTab
  }

  render() {
    const {notification, classes, t, history: {push}, viewer, viewer:{appropTests, globalQuestionnaire, documents, allowedAccountTypes, referralDetails}, location} = this.props
    const {clientType, accounts, companyObject} = this.context
    const activeTab = this.checkActiveTab()

    const badgeClassesArr = [classes.badge]
    const referralAwardNotification = get(referralDetails,'activatedReferralAwards') && !get(referralDetails.activatedReferralAwards,'claimedAt')

    const pendingNoticesCount = get(viewer, 'pendingNoticesCount', 0)
    const totalNotificationsCount = referralAwardNotification ? pendingNoticesCount+1 : pendingNoticesCount
    totalNotificationsCount < 1 && badgeClassesArr.push(classes.invisible)

    const moreBadgeClassesArr = [classes.badge]
    const pendingVerificationsCount = viewer && getPendingVerificationsCount(viewer, appropTests, globalQuestionnaire, documents, clientType)

    const pendingMoreCount = Number(totalNotificationsCount) + Number(pendingVerificationsCount)
    pendingMoreCount < 1 && moreBadgeClassesArr.push(classes.invisible)

    const moreBadgeClasses = classnames(moreBadgeClassesArr)
    const allowedAccounts = getAllowedAccounts(accounts, companyObject, t, allowedAccountTypes || [], viewer)
    const hideComponents = hideElementsDependedOfWL()

    return (<React.Fragment>
      <div className={classes.wrap}>
        <main className={classes.main}>
          <AppRoutes />
        </main>
        <BottomNavigation
          value={activeTab}
          classes={{root: classes.bottomNavigationWrapper}}
          onChange={(_, activeTab) => this.setState({activeTab})}
          showLabels
        >
          <BottomNavigationAction
            onClick={() => {
              push('/accounts')
            }}
            label={t(messages.accounts.i18nKey, messages.accounts.defaults)}
            classes={{root: classes.bottomNavigationRoot, selected: classes.bottomNavigationSelected}}
            icon={<AccountsIcon />}
          />
          {!hideComponents && <BottomNavigationAction
            onClick={() => {
              push('/transactions')
            }}
            label={t(messages.payments.i18nKey, messages.payments.defaults)}
            classes={{root: classes.bottomNavigationRoot, selected: classes.bottomNavigationSelected}}
            icon={<PaymentsIcon />} />}
          <BottomNavigationAction
            onClick={() => {
              push('/support')
            }}
            label={t(messages.support.i18nKey, messages.support.defaults)}
            classes={{root: classes.bottomNavigationRoot, selected: classes.bottomNavigationSelected}}
            icon={<NotificationsIcon />} />
          <BottomNavigationAction
            onClick={() => {
              push('/more')
            }}
            label={t(messages.more.i18nKey, messages.more.defaults)}
            classes={{root: classes.bottomNavigationRoot, selected: classes.bottomNavigationSelected}}
            icon={<Badge badgeContent={pendingMoreCount} classes={{badge: moreBadgeClasses}}><MoreIcon /></Badge>} />
        </BottomNavigation>
        {allowedAccounts.length > 0 && location.pathname === '/accounts' &&
          <Button className={classes.floatingButton} classes={{label: classes.plus}}onClick={() => push('/accounts/add-account')}>+</Button>}
        {
          notification &&
            <UiNotification
              open={!!notification}
              onClose={() => {
                isFunction(notification.onClose) && notification.onClose()
                this.props.onClose()
              }}
              type={notification.type}
              status={notification.status}
              buttonMessage={notification.buttonMessage}
              hideButton={notification.hideButton}
              subTitle={notification.subTitle}
              buttonAction={notification.buttonAction}
              linkAction={notification.linkAction}
              linkActionMessage={notification.linkActionMessage}
            >
              {notification.content}
            </UiNotification>
        }
      </div>
    </React.Fragment>)
  }
}

export default compose(
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true})
)(Mobile)
