import React, {Component} from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../../Common/Loading'
import withStyles from '@material-ui/core/styles/withStyles'
import {find, findIndex, first, map, flowRight as compose} from 'lodash'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ProfileRoutes from './routes'
import PageTitle from '../../../Common/PageTitle'
import {FullScreenDialog} from '../../../Common/Dialog'
import messages from '../../../../assets/messages'
import {config} from '../../../../config'
import AppContext from '../../../Common/contexts/AppContext'
import {isMobile} from '../../../../common/utils'
import {InnerAppContext} from '../../../../common/types'

let availableTabs = [
  {key: 'details', title: messages.personalDetails, relativePath: '/details'},
  {key: 'economic', title: messages.economicProfile, relativePath: '/economic'},
]

const styles = theme => ({
  tabsRoot: {
    marginTop: 30,
    marginBottom: 20,
  },
  titleItem: {
    flex: 1,
  },
})

class PersonalProfile extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    const {match, location} = props
    const currentTab = find(availableTabs, (tab) => location.pathname.indexOf(`${match.url}${tab.relativePath}`) > -1)
      || first(availableTabs)
    this.state = {
      activeTab: findIndex(availableTabs, currentTab)
    }
  }

  handleTabChanged(activeTabIndex) {
    const {history, match} = this.props
    this.setState({activeTab: activeTabIndex})
    const currentTab = availableTabs[activeTabIndex]
    history.push(`${match.path}${currentTab.relativePath}`)
  }


  handleClose(history, state) {
    const {removeForced} = this.state
    const {t} = this.props
    return (state && state.force && !removeForced) ? this.context.logout(t(messages.economicProfile.i18nKey, messages.economicProfile.defaults)) : history.push('/settings/profile')
  }

  render() {
    const {loading, documentsLoading, classes, history, location, location:{state}, match} = this.props
    if (loading || documentsLoading) return <Loading />

    const {productConfigs, accountVerification} = config
    const {clientType, blockedDeposit} = this.context

    let verificationFields
    if (blockedDeposit && clientType && productConfigs[clientType]) {
      verificationFields  = productConfigs[clientType].accountVerification
    } else {
      verificationFields = accountVerification
    }

    if (verificationFields.indexOf('profile') < 0) {
      availableTabs = availableTabs.filter((tab) => tab.key!=='details')
    }

    if (verificationFields.indexOf('economicProfile') < 0) {
      availableTabs = availableTabs.filter((tab) => tab.key!=='economic')
    }

    const currentTab =
      find(availableTabs, (tab) => location.pathname.indexOf(`${match.url}${tab.relativePath}`) > -1) || first(availableTabs)

    const currentTabIndex = findIndex(availableTabs, currentTab)

    return (
      <FullScreenDialog
        desktopOnly
        open={true}
        onClose={() => this.handleClose(history,state)}
      >
        <PageTitle onBack={() => history.push('/settings/profile')}>
          <Trans {...messages.personalProfileCard} />
        </PageTitle>
        <Tabs
          value={currentTabIndex}
          onChange={(_, activeTab) => this.handleTabChanged(activeTab)}
          classes={{root: classes.tabsRoot}}
          variant={isMobile()? 'scrollable' : 'standard'}
          scrollButtons='off'
        >
          {map(availableTabs, (tab) => <Tab key={tab.key} label={<Trans {...tab.title} />} />)}
        </Tabs>
        <ProfileRoutes removeForced={() => this.setState({removeForced: true})}/>
      </FullScreenDialog>
    )
  }
}

export default compose(withNamespaces(), withStyles(styles, {withTheme: true}))(PersonalProfile)
