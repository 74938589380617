import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import InfoIcon from '@material-ui/icons/InfoOutlined'

const styles = theme => ({
  yellowText: {
    color: theme.palette.yellow.color,
  },
  quitDiv: {
    marginBottom: 15,
    padding:10,
    backgroundColor: theme.palette.lightyellow.color
  },
  infoIcon: {
    marginBottom: -6,
    marginRight: 5,
  },
  quitDivText:{
    padding: '15px 34px 5px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0',
    }
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer' as const
  },
  displayInline: {
    display: 'inline',
  },
  textRight: {
    textAlign: 'right'as const
  },
  exitBtn: {
    padding: 0,
    '&:hover' : {
      backgroundColor: 'inherit'
    }
  },
})

class QuitedCompetition extends Component<any,any> {

  render() {
    const {classes, oldEntry, showAccounts} = this.props
    return <Grid item xs={12} className={classes.quitDiv}>
      <Grid container>
        <Grid item xs={12}>
          <InfoIcon className={classNames(classes.infoIcon, classes.yellowText)}/>
          <Typography variant="subtitle1" className={classes.displayInline}> <Trans {...messages.stoppedParticipating} /> </Typography>
        </Grid>
        <Grid item xs={12} className={classes.quitDivText}>
          <Typography variant="body1"> <Trans {...messages.stoppedParticipatingText} values={{accountId: oldEntry.remoteId}} components={[
            <Link to={`/accounts/${oldEntry.id}`} className={classes.link}> </Link>]}/></Typography>
        </Grid>
        <Grid item xs={12} className={classes.textRight}>
          <Button color={'primary'} className={classes.exitBtn}
            onClick={() => showAccounts()}>
            <Trans {...messages.selectDifferentAccount} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(QuitedCompetition)
