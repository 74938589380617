import React, {Component} from 'react'
import classnames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import {graphql} from 'react-apollo'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import TableRow from '@material-ui/core/TableRow'
import ListItem from '@material-ui/core/ListItem'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import {get, map, reject, sortBy, minBy, flowRight as compose} from 'lodash'
import ListItemText from '@material-ui/core/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'
import {currencies, withdrawalPaymentVendors} from '@bdswiss/common-enums'
import messages from '../../../assets/messages'
import PageSubTitle from '../../Common/PageSubTitle'
import {isMobile} from '../../../common/utils/browser'
import {PROFILE_SETTINGS_QUERY} from '../../../graphql/queries'
import {getFormattedAmount} from '../../../common/utils/general'
import {Link} from 'react-router-dom'
import {getAccountLabel, isCentAccount} from '../../../common/utils/accounts'

const styles = theme => ({
  cell: {
    padding: 0,
    width: '1%'
  },
  lastCell: {
    maxWidth:150
  },
  messageGrid: {
    marginTop: 30,
  },
  highlight: {
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  amountLeft: {
    color: theme.palette.green.color,
    fontWeight: 400,
  },
  noPadding: {
    padding: 0,
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 500,
    color: 'inherit'
  },
  paddingLeft: {
    paddingLeft: 40
  },
  textBold: {
    fontWeight: 400
  },
  textItalic: {
    textDecoration: 'underline',
    fontStyle: 'italic'
  },
  paddingTop:{
    paddingTop:'1rem'
  }
})

class ChooseAvailableOption extends Component<any,any> {

  render() {
    const {classes, account, amount, goBack, viewer: {locale}, withdrawablePaymentMethods, t} = this.props

    const accountLabel = getAccountLabel(account, locale)
    const formattedAmount = isCentAccount(account)
      ? getFormattedAmount({amount: amount / (currencies.CUD.baseCurrencyRate ?? 1), currency: currencies.CUD.baseCurrency, locale})
      : getFormattedAmount({amount, currency: account.currency, locale})

    const sortedMethods = sortBy(reject(withdrawablePaymentMethods, (m) => m.amountLeft <= 0), 'order')
    const firstVendor = get(minBy(withdrawablePaymentMethods, 'order'), 'vendor')

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.messageGrid}>
          <PageSubTitle>
            <Trans
              {...messages.withdrawalMessage}
              values={{amount: formattedAmount, accountLabel}}
              components={[
                <span onClick={() => goBack()} className={classes.highlight}>amount</span>,
                <span onClick={() => goBack()} className={classes.highlight}>account</span>
              ]}
            />
          </PageSubTitle>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container direction="column" spacing={0}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cell}><Trans {...messages.paymentMethod} /></TableCell>
                    <TableCell className={classes.cell}><Trans {...messages.details} /></TableCell>
                    <TableCell className={classes.cell}><Trans {...messages.totalDeposits} /></TableCell>
                    <TableCell className={classes.cell}><Trans {...messages.amountLeft} /></TableCell>
                    <TableCell className={classes.cell} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(reject(sortedMethods, (m) => m.vendor !== firstVendor), (m, i) =>
                    <TableRow key={i} aria-label={m.vendor}>
                      <TableCell className={classes.cell} component="th" scope="row">
                        <Typography variant="body1">{withdrawalPaymentVendors[m.vendor].localization.t(locale)}</Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Typography variant="body1">{m.details}</Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Typography variant="body1">
                          {isCentAccount(account)
                            ? getFormattedAmount({amount: m.amount / (currencies.CUD.baseCurrencyRate ?? 1), currency: currencies.CUD.baseCurrency, locale})
                            : getFormattedAmount({amount: m.amount, currency: account.currency, locale})
                          }
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Typography variant="body1" className={classes.amountLeft}>
                          {isCentAccount(account)
                            ? getFormattedAmount({amount: m.amountLeft / (currencies.CUD.baseCurrencyRate ?? 1), currency: currencies.CUD.baseCurrency, locale})
                            : getFormattedAmount({amount: m.amountLeft, currency: account.currency, locale})
                          }
                        </Typography>
                      </TableCell>
                      <TableCell className={classnames([classes.cell, classes.lastCell])}>
                        <Tooltip
                          title={m.vendor !== firstVendor ? t(messages.refundOptionDisabled.i18nKey, messages.refundOptionDisabled.defaults) : ''}
                          placement="top-start"
                        >
                          <span>
                            <Button
                              disableFocusRipple
                              disableRipple
                              variant="text"
                              color="primary"
                              disabled={m.vendor !== firstVendor}
                              onClick={() => this.props.onContinue(m)}
                            >
                              <Trans {...messages.createWithdrawal} />
                            </Button>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>)
                  }
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} className={classes.paddingTop}>
              <Grid container justifyContent="center">
                <Grid item xs={11}>
                  <Typography variant="caption" align="center">
                    <i><Trans {...messages.methodNotAvailable}
                      // className={classes.note}
                      components={[
                        <Link to={'/support'} className={classes.link}>here</Link>,
                        <span className={classes.link} onClick={() => this.props.skipRefund()}> here</span>,
                      ]}
                    /></i>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid item sm={12} className={classes.paddingLeft}>
            <PageSubTitle>
              <Trans {...messages.attention} />
            </PageSubTitle>
          </Grid>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12} sm={11}>
              <ul key="information">
                <li className={classes.listItem}>
                  <Typography variant='body1'><Trans {...messages.withdrawalOptionsSameMethod}/></Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant='body1'>
                    <Trans
                      {...messages.withdrawalOptionsAnotherOption}
                      components={[
                        <span className={classes.textBold}>another</span>,
                        <span className={classes.textItalic}>only</span>,
                      ]}
                    />
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

class MobileChooseAvailableOption extends Component<any,any> {

  render() {
    const {classes, account, amount, goBack, viewer: {locale}, withdrawablePaymentMethods} = this.props

    const accountLabel = getAccountLabel(account, locale)
    const formattedAmount = isCentAccount(account)
      ? getFormattedAmount({amount: amount / (currencies.CUD.baseCurrencyRate ?? 1), currency: currencies.CUD.baseCurrency, locale})
      : getFormattedAmount({amount, currency: account.currency, locale})

    const sortedMethods = sortBy(reject(withdrawablePaymentMethods, (m) => m.amountLeft <= 0), 'order')
    const firstVendor = get(minBy(withdrawablePaymentMethods, 'order'), 'vendor')

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.messageGrid}>
          <PageSubTitle>
            <Trans
              {...messages.withdrawalMessage}
              values={{amount: formattedAmount, accountLabel}}
              components={[
                <span onClick={() => goBack()} className={classes.highlight}>amount</span>,
                <span onClick={() => goBack()} className={classes.highlight}>account</span>
              ]}
            />
          </PageSubTitle>
        </Grid>
        <Grid item xs={12} sm={12} lg={10}>
          <Grid container direction="column" spacing={0}>
            <Grid item xs={12}>
              {map(reject(sortedMethods, (m) => m.vendor !== firstVendor), m => (<React.Fragment key={m.id}>
                <ListItem dense disableGutters>
                  <ListItemText>
                    <Grid container justifyContent="space-between" direction="row">
                      <Grid item>
                        <Typography variant="body1">{withdrawalPaymentVendors[m.vendor].localization.t(locale)}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{m.details}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" direction="row" alignItems="center">
                      <Grid item>
                        <Typography variant="body1" className={classes.amountLeft}>
                          {isCentAccount(account)
                            ? getFormattedAmount({amount: m.amountLeft / (currencies.CUD.baseCurrencyRate ?? 1), currency: currencies.CUD.baseCurrency, locale})
                            : getFormattedAmount({amount: m.amountLeft, currency: account.currency, locale})
                          }
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          disableFocusRipple
                          disableRipple
                          variant="text"
                          color="primary"
                          classes={{root: classes.noPadding}}
                          disabled={m.vendor !== firstVendor}
                          onClick={() => this.props.onContinue(m)}
                        >
                          <Trans {...messages.createWithdrawal} />
                        </Button>
                      </Grid>
                    </Grid>
                    {m.vendor !== firstVendor && <Grid container justifyContent="space-between" direction="row">
                      <Typography variant="caption"><Trans {...messages.refundOptionDisabled} /></Typography>
                    </Grid>}
                  </ListItemText>
                </ListItem>
                <Divider />
              </React.Fragment>))}
            </Grid>
            <Grid item xs={12} className={classes.paddingTop}>
              <Grid container justifyContent="center">
                <Grid item xs={11}>
                  <Typography variant="caption" align="center">
                    <i><Trans {...messages.methodNotAvailable}
                      // className={classes.note}
                      components={[
                        <Link to={'/support'} className={classes.link}>here</Link>,
                        <span className={classes.link} onClick={() => this.props.skipRefund()}> here</span>,
                      ]}
                    /></i>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <ul key="information">
                <li className={classes.listItem}>
                  <Typography variant='body1'><Trans {...messages.withdrawalOptionsSameMethod}/></Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant='body1'>
                    <Trans
                      {...messages.withdrawalOptionsAnotherOption}
                      components={[
                        <span className={classes.textBold}>another</span>,
                        <span className={classes.textItalic}>only</span>,
                      ]}
                    />
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => {
      const viewer = get(props.data,'viewer')
      const documents = get(viewer, 'documents', [])
      return {
        error: props.data?.error,
        loading: props.data?.loading,
        documents,
      }
    }})
)(isMobile() ? MobileChooseAvailableOption : ChooseAvailableOption)
