import React from 'react'
import {Route, Switch, withRouter, Redirect} from 'react-router-dom'
import ForexAccountOverview from './ForexAccountOverview'
import ForexAccountSettings from './ForexAccountSettings'
import ForexAccountDownloadTrader from './ForexAccountDownloadTrader'
import Plan from './Plan'
import CopyTrading from './CopyTrading'
import TradingCompetitions from '../TradingCompetitions'
import ProviderStats from './ProviderStats'
import {config} from '../../../config'

const ForexRoutes = ({match: {path, url}}) => {
  const {showTab} = config
  const showDepositTab = showTab?.deposit ?? true
  const defaultTransactionPath = showDepositTab
    ? '/transactions/:accountId/deposit'
    : '/transactions/:accountId/withdrawal'

  return (
    <Switch>
      <Redirect from={`${path}/transactions/history`} to={{pathname: '/transactions/history', state: {prevPath: url}} }/>
      <Redirect from={`${path}/transactions`} to={{pathname: defaultTransactionPath, state: {prevPath: url}} }/>
      <Route path={`${path}/settings`} component={ForexAccountSettings} />
      <Route path={`${path}/plan`} component={Plan} />
      <Route path={`${path}/downloadTrader`} component={showTab ? ForexAccountOverview : ForexAccountDownloadTrader} />
      {/* <Redirect exact from={`${path}/tools/autochartist`} to={'/tools/autochartist'}/>
    <Redirect exact from={`${path}/tools/tradeCompanion`} to={'/tools/tradeCompanion'}/>
    <Redirect exact from={`${path}/tools/ebook`} to={'/tools/ebook'}/>
    <Redirect exact from={`${path}/tools`} to={'/tools'}/> */}
      <Route path={`${path}/copyTrading`} component={CopyTrading} />
      <Route path={`${path}/tradingCompetitions`} component={TradingCompetitions} />
      <Route path={`${path}/providerStats`} component={ProviderStats} />
      <Route exact path={`${path}`} component={ForexAccountOverview} />
    </Switch>
  )
}

export default withRouter(ForexRoutes)
