import moment from 'moment'
import {Link} from 'react-router-dom'
import React, {Component} from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import Button from '@material-ui/core/Button'
import {isEmpty, isNull, first, get, find, includes, filter, orderBy, head, flowRight as compose, some, pickBy, map} from 'lodash'
import {graphql} from 'react-apollo'
import {tradingStatuses, suspendedTradingStatusReasons, accountTypes, companies, kycStatuses,
  kycRejectionReasons, underMonitoringReasons, appropTestStatuses, scoringResultsV2} from '@bdswiss/common-enums'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import AppContext from '../../Common/contexts/AppContext'
import NotificationBar from '../../Common/NotificationBar'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {isForexAccount, isDemoAccount, isVipOrRaw, getAccountSubtype} from '../../../common/utils/accounts'
import {getMissingVerifications, hasHeldDeposit, safeParseJSON} from '../../../common/utils/general'
import messages from '../../../assets/messages'
import {config} from '../../../config'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import AppropTestExplanation from '../../Common/AppropTestExplanationModal'
import classNames from 'classnames'
import {accountCategoriesDetails} from '../../../common/utils/uioptions'
import {TRADING_GAME_NOTICE} from '../../Common/TermsDialog'
import TradingGameNotice from '../../Common/TradingGameNotice'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  warningIcon: {
    color: theme.palette.yellow.color,
    verticalAlign: 'sub',
    marginRight: '2px'
  },
  link: {
    fontWeight: 500,
    color: theme.palette.lightdark.main,
  },
  highlight:{
    fontWeight:400
  },
  helpIcon: {
    color: theme.palette.grey.color,
    marginBottom: '-2px',
    paddingTop: 5,
    cursor: 'pointer'
  },
  tolltipText:{
    fontSize: 12,
    backgroundColor: theme.palette.lightgrey.color,
    color: theme.palette.secondary.dark,
    fontWeight: 300
  },
  textBold: {
    fontWeight: 400
  },
  floatRight: {
    [theme.breakpoints.up('sm')]: {
      float: 'right'
    }
  },
  pendingNoticesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
})

class LydianClientNotificationBar extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  state = {
    openPopup: false,
    showTradingGameNotice: false,
  } as any

  //Appears on account level when an account is readonly and suspended OR account is readonly and has outstanding deposit OR when account is readonly
  checkAccountVerification(account, isMissingApTest, vipOrRawReadOnlyAccount) {
    const {classes, viewer, latestAptest, globalQuestionnaire, profileSettingsLoading, viewerLoading,
      viewer: {company, ftdDate, kycStatus}} = this.props
    const isReadOnly = get(account, 'isReadOnly', '')
    const isDemo = get(account, 'isDemo', '')
    const {blockedDeposit} = this.context

    let message
    if (isDemo || profileSettingsLoading || viewerLoading) return

    const {productConfigs, accountVerification} = config
    let category : string =  get(accountTypes, `${get(account, '__typename')}.category`) as any

    if (account && isForexAccount(account)) {
      category = 'forex' //TODO: to be removed - fix category name
    }

    const verificationFields = get(productConfigs, `${category}.accountVerification`, accountVerification)

    const missingVerifications =  getMissingVerifications(viewer, latestAptest, globalQuestionnaire, '', verificationFields)

    const kycDays = get(find(companies, {value: company}), 'kycDeadline') as number
    const kycDaysExtension = get(find(companies, {value: company}), 'kycDeadlineExtension', 0)
    const kycDeadline = moment(ftdDate).add(kycDays+kycDaysExtension, 'day')

    let remainingDays
    if (!blockedDeposit && kycDeadline.isAfter(moment()) && kycStatus !== kycStatuses.approved.key) {
      remainingDays = kycDeadline.diff(moment(), 'days')
    }


    if (vipOrRawReadOnlyAccount) {
      const selectedAccount =  get(vipOrRawReadOnlyAccount,'accountSubtype') || get(account,'accountSubtype')
      const selectedSubType = getAccountSubtype(selectedAccount)
      const specificAccount = account ?  `/transactions/${account.id}/deposit` :
        vipOrRawReadOnlyAccount.id ? `/transactions/${vipOrRawReadOnlyAccount.id}/deposit` : '/transactions'
      message = <React.Fragment>
        <Trans {...messages.activateVipRawAccount} values={{type:get(selectedSubType, 'label')}}
          components={[<span className={classes.highlight}>subtype</span>]}/>
        <Link to={specificAccount} className={classNames(classes.link,classes.floatRight)}> <Trans {...messages.depositNow} /> </Link>
      </React.Fragment>
    } else if (isReadOnly && missingVerifications.length > 0) {
      message =
        <React.Fragment>
          <Trans {...messages.accountReadonlyVerificationNeeded}
            components={[
              <Link to={'/accounts/verification'} className={classes.link}> </Link>
            ]}
          />
        </React.Fragment>
    } else if (remainingDays && missingVerifications.length > 0) {
      message =
        <React.Fragment>
          {remainingDays > 0 && <Trans {...messages.accountDaysPleaseVerify}
            values={{days: remainingDays}}
            components={[
              <Link to={'/accounts/verification'} className={classes.link}> </Link>
            ]}
          />}
          {remainingDays <= 0 && <Trans {...messages.accountPleaseVerify}
            components={[
              <Link to={'/accounts/verification'} className={classes.link}> </Link>
            ]}
          />}
        </React.Fragment>
    } else if (missingVerifications.length > 0 && kycStatus !== kycStatuses.rejected.key) {
      message =
        <React.Fragment>
          <Trans {...messages.accountPleaseVerify}
            components={[
              <Link to={'/accounts/verification'} className={classes.link}> </Link>
            ]}
          />
        </React.Fragment>
    } else if (isReadOnly) {
      message =
        <React.Fragment>
          <Trans {...messages.isReadonlyAccount} />
          <Link to="/support" className={classNames(classes.link,classes.floatRight)}> <Trans {...messages.contactSupportForMore} /> </Link>
        </React.Fragment>
    }

    if (!account && isMissingApTest) message = ''
    const notificationStatus = vipOrRawReadOnlyAccount ? 'info' : 'warning'

    return (
      <React.Fragment>
        {message && (<NotificationBar status={notificationStatus}>
          {message}
        </NotificationBar>) }
      </React.Fragment>
    )
  }

  //1st to appear. On Dashboard and account/overview. Appears when depositedAmount is zero
  checkIfHasDepositedAmount(account, eligibleBonus) {
    const {classes} = this.props
    const showDepositMessage= (!account || (account && !account.isDemo))
    const specificAccount = account ? `/transactions/${account.id}/deposit` : '/transactions'
    const showBonusMessage = (!account && get(eligibleBonus, 'eligibleBonus'))
    const showBonusDays = get(eligibleBonus, 'daysLeft')

    return showDepositMessage && <NotificationBar status="info">
      {(showBonusMessage) ? (showBonusDays <= get(eligibleBonus, 'daysRemainingNotification')) ? <Trans {...messages.welcomeBonusDays} values={{days: showBonusDays}}/>
        : <Trans {...messages.welcomeBonus} /> : <Trans {...messages.depositPending} />}
      <Link to={specificAccount} className={classNames(classes.link,classes.floatRight)}> <Trans {...messages.depositNow} /> </Link>
    </NotificationBar>
  }

  // 2nd to appear. On Dashboard. Appears when the client has a held deposit.
  checkIfHasHeldDeposits() {
    const {classes, viewer: {underMonitoring, underMonitoringReasonCode, locale, externalVerificationUrl}} = this.props
    const verifyLink = externalVerificationUrl ? <a href={externalVerificationUrl} className={classes.link}>
      <Trans {...messages.verifyAccount} />
    </a> : <Link to="/accounts/verification" className={classNames(classes.link,classes.floatRight)}> <Trans {...messages.verifyAccount} /> </Link>

    if (underMonitoring && [underMonitoringReasons.thirdPartyDeposits.key,
      underMonitoringReasons.cardCopiesRequired.key].includes(underMonitoringReasonCode)) {
      return <NotificationBar status="warning">
        <Trans {...messages.underMonitoringNotification}
          components={[
            <Link to={'/transactions/history'} className={classes.link}> </Link>,
            <Link to={'/support'} className={classes.link}> </Link>
          ]}
          values={{reason: underMonitoringReasons[underMonitoringReasonCode].localization.t(locale)}}
        />
      </NotificationBar>
    } else {
      return <NotificationBar status="warning">
        <Trans {...messages.depositHeldNotification}
          components={[
            <Link to={'/transactions/history'} className={classes.link}> </Link>
          ]}
        />
        {verifyLink}
      </NotificationBar>
    }
  }

  checkIfHasRejectedKyc() {
    const {classes, viewer: {kycStatusReasonCode, locale}} = this.props
    return <NotificationBar status="warning">
      {(kycStatusReasonCode === 'other')
        ? <React.Fragment>
          <Trans {...messages.kycStatusTempRejected} />
          {this.renderActionMessage()}
        </React.Fragment>
        : <React.Fragment>
          <Trans {...messages.kycRejectedNotification} values={{reason: kycRejectionReasons[kycStatusReasonCode] ?
            kycRejectionReasons[kycStatusReasonCode].localization.t(locale) : kycRejectionReasons['other'].localization.t(locale)}}
          components={[<span className={classes.highlight}>reason</span>]} />
          {(kycStatusReasonCode === 'resubmissionOfAppTest')
            ? <Trans {...messages.reCompleteAppTest} components={[
              <Link key={'app'} to={'/settings/profile/appropriateness-test'} className={classes.link}>  </Link>]} />
            : this.renderActionMessage('documentsOrSupport')}
        </React.Fragment>}
    </NotificationBar>
  }

  openPopup() {
    this.setState({openPopup: true})
  }

  closePopup () {
    this.setState({openPopup: false})
  }

  // 3rd to appear. On Dashboard. Appears when tradingStatus is newAccount(Missing AP Test) or appropTest is empty
  checkIfHasAppropTest(latestAptest, canProcessAppropTest) {
    const {classes} = this.props
    const {companyObject} = this.context
    if (!isEmpty(latestAptest) && latestAptest.status === appropTestStatuses.rejected.value && !canProcessAppropTest) {
      return <NotificationBar status="error">
        <Trans {...messages.appropTestFailed} values={{company: companyObject.trademark}} />
      </NotificationBar>
    } else {
      return <NotificationBar status="error" component={'span'}>
        <Trans {...messages.appropriatenessTestNotification}
          components={[<Link to="/settings/profile/appropriateness-test" className={classes.link}><Trans {...messages.appropriatenessTestLink} /></Link>]}/>
        <InfoIcon className={classes.helpIcon} color="secondary" onClick={() => this.openPopup()}/>
        <Trans {...messages.helpSupport} components={[<Link to="/support" className={classes.link}> </Link>]}/>
      </NotificationBar>
    }
  }

  //4th to appear. On Dashboard. Appears when tradingStatus is suspended and tradingStatusReasonCode is not null
  checkIfSuspended(locale, reason) {
    const {classes} = this.props
    const suspendedReason = suspendedTradingStatusReasons[reason] && suspendedTradingStatusReasons[reason].localization.t(locale)

    const documentsOrSupport = [
      suspendedTradingStatusReasons.missingKycDoc.value,
      suspendedTradingStatusReasons.missingProofAddress.value,
      suspendedTradingStatusReasons.missingProofOfId.value
    ]

    const emailsOrDocuments = [
      suspendedTradingStatusReasons.thirdPartyTransaction.value,
      suspendedTradingStatusReasons.missingProofOfPayment.value
    ]

    const general = [
      suspendedTradingStatusReasons.other.value,
      suspendedTradingStatusReasons.chargebackCase.value,
      suspendedTradingStatusReasons.fraud.value
    ]

    return <NotificationBar status="error">
      <Trans {...messages.isSuspendedTradingStatusWithReason} />
      {!includes(general, reason) && <Trans {...messages.reason} values={{reason:suspendedReason}} components={[<span className={classes.highlight}>reason</span>]}/>}
      {includes(documentsOrSupport, reason)
        ? this.renderActionMessage('documentsOrSupport')
        : (includes(emailsOrDocuments, reason))
          ? this.renderActionMessage('emailsOrDocuments')
          : this.renderActionMessage()
      }
    </NotificationBar>
  }

  //5th to appear. On Dashboard. Appears kyc status is AP Pending
  checkIfAppPending() {
    const {classes} = this.props
    return  <NotificationBar status="warning">
      <Trans {...messages.kycApTestPending}  components={[
        <Link key={'app'} to={'/settings/profile/appropriateness-test'} className={classes.link}>  </Link>]}/>
    </NotificationBar>
  }

  //6th to appear. On Dashboard. Appears client is under monitoring
  checkIfUnderMonitoring(locale) {
    const {classes, viewer: {underMonitoringReasonCode}} = this.props
    const underMonitoringReason = underMonitoringReasons[underMonitoringReasonCode] && underMonitoringReasons[underMonitoringReasonCode].localization.t(locale)
    const general = [
      underMonitoringReasons.other.value,
      underMonitoringReasons.compliance.value,
      underMonitoringReasons.numberFailedTransactions.value,
      underMonitoringReasons.suspiciousTradingBehavior.value
    ]
    return  <NotificationBar status="warning">
      <Trans {...messages.isUnderMonitoringWithReason} />
      {!includes(general, underMonitoringReasonCode) &&  <Trans {...messages.reason} values={{reason:underMonitoringReason}} components={[<span className={classes.highlight}>reason</span>]}/>}
      {(underMonitoringReasonCode === underMonitoringReasons.pepOrUs.value || includes(general, underMonitoringReasonCode))
        ? this.renderActionMessage()
        : this.renderActionMessage('emailsOrDocuments')
      }
    </NotificationBar>
  }

  //7th to appear. On Dashboard. Appears on ap test mau with 1:100 (middle score)
  leverageUpdateMsg(getAccounts) {
    const {classes, viewer:{appropTests}} = this.props
    const getAccount = !isEmpty(getAccounts) && head(orderBy(getAccounts, ['balance'], ['desc']))
    const appropTestScoreCheck = appropTests &&
      get(appropTests[0], 'scoreResult') === scoringResultsV2.productNotSuitableLeverage100.value
    return appropTestScoreCheck && <NotificationBar status="warning">
      <Trans {...messages.leverageUpdateSuggestion}  components={[
        <span className={classes.textBold}>1:100</span>,
        <Link key={'app'} to={`/accounts/${getAccount.id}/settings`} className={classes.link}>  </Link>]}/>
    </NotificationBar>
  }

  //8th to appear. On Dashboard. Appears on allowCopyTrading
  copyTradingMsg(account) {
    const {classes} = this.props
    const getCopyTradingAccount = get(account.copyTrading,'isActive') && isNull(get(account.copyTrading,'stopRequestedDate'))
    return getCopyTradingAccount && <NotificationBar status="success">
      <Trans {...messages.copyTradingSuggestion}
        values={{ibAccount: account.copyTrading.accountCopying.remoteId}}
        components={[
          <Link key={'app'} to={`${account.id}/copyTrading`} className={classes.link}>  </Link>]}/>
    </NotificationBar>
  }

  renderActionMessage(type?) {
    const  {classes}= this.props
    switch (type) {
      case 'documentsOrSupport': return (
        <Trans {...messages.documentsOrSupport} components={[
          <Link key={'doc'} to={'/settings/profile/documents'} className={classes.link}> </Link>,
          <Link key={'sup'} to={'/support'} className={classes.link}>  </Link>]} />
      )
      case 'emailsOrDocuments': return (
        <Trans {...messages.emailsOrDocuments} components={[
          <Link key={'doc'} to={'/settings/profile/documents'} className={classes.link}> </Link>]} />
      )
      default:
        return <Trans {...messages.emailsOrSupport} components={[<Link key={'sup'} to={'/support'} className={classes.link}>  </Link>]} />
    }
  }

  showDormantMessage() {
    const  {classes, viewer: {missingDocuments, missingEP}, t}= this.props

    const missingDocs = safeParseJSON(missingDocuments)
    const dormantFlags = {
      poiUpload: !!missingDocs.poi,
      porUpload: !!missingDocs.por,
      missingep: missingEP,
    }
    const flags = pickBy(dormantFlags, (f) => f === true)
    if (!isEmpty(flags)) {
      const actions = map(Object.keys(flags), (f) => t(messages[f].i18nKey)).join(', ')
      return <NotificationBar status="warning">
        <Trans
          {...messages.dormantMessage}
          components={[
            <Link key={'ep'} to={'/settings/profile/personal-profile/economic'} className={classes.link}> </Link>
          ]}
          values={{actions}}
        />&nbsp;
        {(dormantFlags.poiUpload || dormantFlags.porUpload) && this.renderActionMessage('documentsOrSupport')}
      </NotificationBar>
    }
  }

  showBitnukMessage() {
    const {hasBitnuk} = this.props
    const {locale} = this.context
    return <NotificationBar status="info">
      <Trans {...messages.walletSnackbar} values={{wallet: get(hasBitnuk, 'accountType.localization') && hasBitnuk.accountType.localization.t(locale)}}/>
    </NotificationBar>
  }

  showTradingGameNotice() {
    const {classes} = this.props
    return <NotificationBar status="info">
      <span className={classes.pendingNoticesContainer}>
        <Trans {...messages.pausedTrading} />
        <Button onClick={() => this.setState({showTradingGameNotice: true})}>
          <Trans {...messages.readMore} />
        </Button>
      </span>
    </NotificationBar>
  }

  render() {
    const {account, accounts, latestAptest, viewer: {depositedAmount, kycStatus, canProcessAppropTest,
      underMonitoring, missingDocuments, missingEP, pendingNotices}, hasBitnuk} = this.props
    const {locale, tradingStatus, tradingStatusReasonCode, companyObject} = this.context
    const {openPopup, showTradingGameNotice} = this.state

    const hasZeroBalanceAccount = (account && account.balance <= 0) || (accounts && isEmpty(filter(accounts, (a) => a.balance > 0 && !isDemoAccount(a))))
    const hasDepositedAmount = !hasZeroBalanceAccount || (!isNull(depositedAmount) && depositedAmount > 0)
    const hasHeldDeposits = accounts ? hasHeldDeposit(accounts) : hasHeldDeposit([account])
    const isMissingApTest = companies[companyObject.key].requiresAPTest &&
      ((isEmpty(latestAptest) && tradingStatus === tradingStatuses.newAccount.key) || (!isEmpty(latestAptest) && latestAptest.status === appropTestStatuses.rejected.value) ||
        (isEmpty(latestAptest) && hasDepositedAmount))
    const isSuspended = tradingStatus === tradingStatuses.suspended.key && tradingStatusReasonCode
    const hasApTestPending = kycStatus === kycStatuses.pendingAppropTest.value
    const isKycRejected = kycStatus === kycStatuses.rejected.key
    const getAccounts = filter(accounts, (a) => a.leverage === 100 && !isDemoAccount(a))
    const vipOrRawReadOnlyAccount = accounts ? find(accounts, (a) => isVipOrRaw(a) && a.isReadOnly) :
      get(account,'accountSubtype') && isVipOrRaw(account) && get(account,'isReadOnly')
    const dormantFlags = {
      ...safeParseJSON(missingDocuments),
      missingEP,
    }
    const showDormantMessage = some(dormantFlags, (f) => f === true)
    const today = moment().format()
    const showBitnukDetails = accountCategoriesDetails['cryptoWallet'].bitnuk
    const bitnukEndDate = moment(showBitnukDetails.startDate).add(showBitnukDetails.activeDays, 'd')
    const showBitnuk = hasBitnuk && moment(today).isSameOrBefore(moment(bitnukEndDate).format(), 'day')
    const tradingPausePendingNotice = find(pendingNotices, (notice) => notice.localizationKey === TRADING_GAME_NOTICE)

    return (
      <Grid item xs={12}>
        {showDormantMessage && this.showDormantMessage()}
        {!showDormantMessage && showBitnuk && this.showBitnukMessage()}
        {!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && isMissingApTest && !isKycRejected && !hasApTestPending && this.checkIfHasAppropTest(latestAptest, canProcessAppropTest)}
        {/*{!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && !isMissingApTest && !hasHeldDeposits  && ((accounts && !hasDepositedAmount) || hasZeroBalanceAccount) && this.checkIfHasDepositedAmount(account, eligibleBonus)}*/}
        {!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && !isMissingApTest && hasHeldDeposits && this.checkIfHasHeldDeposits()}
        {!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && isKycRejected && this.checkIfHasRejectedKyc()}
        {!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && !isMissingApTest && !hasHeldDeposits && (hasDepositedAmount && !hasZeroBalanceAccount) && this.checkAccountVerification(account, isMissingApTest, vipOrRawReadOnlyAccount)}
        {!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && hasApTestPending && this.checkIfAppPending()}
        {!showDormantMessage && !showBitnuk && !isSuspended && underMonitoring && this.checkIfUnderMonitoring(locale)}
        {!showDormantMessage && !showBitnuk && isSuspended  && this.checkIfSuspended(locale, tradingStatusReasonCode)}
        {!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && !isMissingApTest && !hasHeldDeposits && !isKycRejected && !isEmpty(getAccounts) && (hasDepositedAmount && !hasZeroBalanceAccount) && this.leverageUpdateMsg(getAccounts)}
        {!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && account && this.copyTradingMsg(account)}
        {tradingPausePendingNotice && this.showTradingGameNotice()}
        <AppropTestExplanation open={openPopup} onClose={() => this.closePopup()} />
        <TradingGameNotice
          open={showTradingGameNotice}
          onClose={() => this.setState({showTradingGameNotice: false})}
          noticeData={tradingPausePendingNotice}
        />
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => {
      const viewer = get(props.data, 'viewer')
      return {
        viewerLoading:props.data?.loading,
        viewerError:props.data?.error,
        viewer
      }
    }
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => {
      const latestAptest = first(get(props.data, 'viewer.appropTests'))
      const globalQuestionnaire = get(props.data, 'viewer.globalQuestionnaire')
      return {
        profileSettingsLoading:props.data?.loading,
        profileSettingsError:props.data?.error,
        latestAptest,
        globalQuestionnaire
      }
    }
  }),
)(LydianClientNotificationBar)
