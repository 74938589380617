import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {map, filter, includes, isEmpty, omit, flowRight as compose, keys, find, get, lowerCase} from 'lodash'
import {nationalities, personalIdDocumentTypes} from '@bdswiss/common-enums'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import {CustomDialog} from '../Common/Dialog'
import LoadingButton from '../Common/LoadingButton'
import messages from '../../assets/messages'
import {UPDATE_OWN_DETAILS_MUTATION} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import withStyles from '@material-ui/core/styles/withStyles'
import AppContext from '../Common/contexts/AppContext'
import CountriesSelect from '../Common/CountriesSelect'
import {nationalitiesListWithFlag} from '../../common/utils'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  selectClass:  {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 17,
    },
  },
  overflowVisible: {
    overflow: 'visible'
  }
})

export class MifirPopup extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      form: {
        nationality: props.nationality || '',
        mifirType: '',
        mifirId: '',
      },
      nationalityChanged: false,
      nationalityFlag:'',
      formErrors: {
        nationality: false,
        mifirType: false,
        mifirId: false,
        onSubmit: false,
      },
      formStatus: '',
      loading: false,
      nationalitiesFlags: nationalitiesListWithFlag(),
    }
  }

  inputOnChange(field, value) {
    const {form, formErrors} = this.state
    let newFormErrors = formErrors
    form[field] = value

    if (isEmpty(form[field])) {
      formErrors[field] = true
      this.setState({form: form, formErrors: formErrors, formStatus: ''})
    } else {
      newFormErrors = omit(newFormErrors, [field, 'onSubmit'])
      this.setState({form: form, formErrors: newFormErrors, formStatus: ''})
    }
  }

  handleChangeNationality(value) {
    const {nationalitiesFlags} = this.state
    this.setState({nationalityFlag: get(find(nationalitiesFlags,(a)=>
      value && (a.key === lowerCase(value) || lowerCase(a.value) === lowerCase(value))), 'label', ''), status: ''})
  }

  setStateOuter = (prop, value) => {
    this.setState({[prop]: value})
  }

  validateForm(showMifirOptions) {
    const {form: {nationality, mifirId, mifirType}, formErrors} = this.state
    if (nationality === '') {
      formErrors.nationality = true
    }

    if (!showMifirOptions) {
      formErrors.mifirId = false
      formErrors.mifirType = false
    } else {
      if (nationality !== '' && mifirId === '') {
        formErrors.mifirId = true
      }

      if (nationality !== '' && mifirType === '') {
        formErrors.mifirType = true
      }
    }

    let newFormErrors = formErrors
    if (this.props.nationality) {
      newFormErrors = omit(newFormErrors, ['nationality'])
    }

    this.setState({formErrors: newFormErrors})
    return isEmpty(find(keys(newFormErrors), (field) => formErrors[field] !== false)) ? true : false
  }

  getOptionsPerNationality() {
    const {form: {nationality}, nationalitiesFlags} = this.state
    const nationalitySelected = this.props.nationality || get(find(nationalitiesFlags,(a)=> nationality && a.key === nationality), 'keyCountry')
    const defaultMifirOptions = [personalIdDocumentTypes.pid.key, personalIdDocumentTypes.concat.key]
    const nationalityObj = find(nationalities, {key: nationalitySelected})
    const mifirOptions = [...filter(get(nationalityObj, 'mifirOptions', defaultMifirOptions), (option) => !personalIdDocumentTypes[option].hidden)]
    mifirOptions.push(personalIdDocumentTypes.nid.key)
    return mifirOptions
  }

  renderMifirMessageOptions(options) {
    const {form: {nationality}, nationalitiesFlags} = this.state
    const nationalitySelected = this.props.nationality || get(find(nationalitiesFlags,(a)=> nationality && a.key === nationality), 'keyCountry')
    let optionsCountry = filter(messages, (message) => {
      if (includes(message.countriesAvailable, nationalitySelected)) {
        // return message
        return true
      }
      return false
    })
    if (nationalitySelected && isEmpty(options)) {
      optionsCountry = [messages.mifirPopupGeneral]
    }
    return (
      <ul>
        {map(optionsCountry, (message: any, index) => (
          <li key={index}> <Typography variant="body2"> <Trans {...message} /> </Typography> </li>
        ))}
      </ul>
    )
  }

  handleSubmit(showMifirOptions) {
    const {onClose} = this.props
    const {form: {nationality, mifirId, mifirType}, nationalitiesFlags} = this.state
    if (this.validateForm(showMifirOptions)) {
      this.setState({loading: true})
      const nationalityLabel = this.props.nationality || get(find(nationalitiesFlags,(a)=> nationality && a.key === nationality), 'keyCountry')
      this.props.updateOwnDetails({variables: {nationality: nationalityLabel, mifirId, mifirType}})
        .then((res) => {
          this.setState({loading: false, formStatus: 'success'})
          onClose()
        })
        .catch((err) => {
          this.setState({loading: false, formStatus: 'failure', formErrors: {onSubmit: err}})
        })
    }
  }

  renderPopupContent() {
    const {form: {nationality, mifirType, mifirId}, formErrors, formStatus, loading, nationalityChanged,
      nationalityFlag, nationalitiesFlags} = this.state
    const {t, classes} = this.props
    const {locale} = this.context
    const options = this.getOptionsPerNationality()
    const nationalitiesDisabledMifir = ['austrian', 'german', 'french', 'hungarian', 'irish', 'luxembourger']
    const showMifirOptions = nationality && !includes(nationalitiesDisabledMifir, nationality)
    const nationalitiesList = filter(nationalitiesFlags, nationality => !nationality.hidden && !nationality.forbidden)
    return (
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="body1"> <Trans {...messages.mifirPopupMessage} /> </Typography>
          {!this.props.nationality && <CountriesSelect
            countryList={nationalitiesList}
            handleChangeField={this.handleChangeNationality.bind(this)}
            handleChange={this.inputOnChange.bind(this)}
            setStateOuter={this.setStateOuter.bind(this)}
            errors={formErrors}
            value={(!nationalityChanged) ?get(find(nationalitiesList,(a)=> nationality && a.keyCountry === nationality), 'label', '') : nationalityFlag}
            name="nationality"
            label={t(messages.mifirPopupNationalitySelectLabel.i18nKey, messages.mifirPopupNationalitySelectLabel.defaults)}
            showRequired={false}
            returnKey
          />}
          {showMifirOptions && this.renderMifirMessageOptions(options)}
          {showMifirOptions && <FormControl fullWidth style={{marginBottom: '30px'}}>
            <InputLabel htmlFor="mifir-type" error={formErrors.mifirType}>
              <Trans {...messages.mifirPopupSelectLabel} />
            </InputLabel>
            <Select
              native
              error={formErrors.mifirType}
              value={mifirType}
              onChange={(e) => this.inputOnChange('mifirType', e.target.value)}
              inputProps={{
                id: 'mifir-type',
                name: 'mifir-type',
                className: classes.selectClass
              }}
            >
              <option value='' disabled></option>
              {map(options, (mifirType) => {
                const mifirOption = find(personalIdDocumentTypes, {value: mifirType})
                return <option key={mifirType} value={mifirType}>
                  {mifirOption!.localization.t(locale)}
                </option>
              }
              )}
            </Select>
          </FormControl>}
          {showMifirOptions && <FormControl fullWidth style={{marginBottom: '30px'}}>
            <TextField
              id="mifir-id"
              value={mifirId}
              label={ <Trans {...messages.mifirPopupInputLabel} /> }
              error={formErrors.mifirId}
              onChange={(e) => this.inputOnChange('mifirId', e.target.value)}
              inputProps={{
                className: classes.selectClass
              }}
            />
          </FormControl>}
          <LoadingButton
            fullWidth
            disabled={loading}
            status={formStatus}
            onClick={() => this.handleSubmit(showMifirOptions)}
          > <Trans {...messages.submit} />
          </LoadingButton>
        </Grid>
      </Grid>
    )
  }

  render() {
    return (
      <CustomDialog
        open={this.props.open}
        title={ <Trans {...messages.mifir} />}
        customClasses={{
          dialogContent: this.props.classes.overflowVisible,
          classes: {paperScrollPaper: this.props.classes.overflowVisible}
        }}
      >
        {this.renderPopupContent()}
      </CustomDialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(UPDATE_OWN_DETAILS_MUTATION, {
    name: 'updateOwnDetails',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    },
  })
)(MifirPopup)
