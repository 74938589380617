import React from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import ProfileSettings from './ProfileSettings'
import ProfileRoutes from './Profile/routes'

const SettingRoutes = ({match: {path}}) => (
  <Switch>
    <Route path={`${path}/profile`} component={ProfileRoutes} />
    <Route path={`${path}/other-settings`} component={ProfileSettings} />
    <Route exact path={`${path}`} component={ProfileRoutes} />
  </Switch>
)

export default withRouter(SettingRoutes)
