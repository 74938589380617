import React, {Component} from 'react'
import {graphql, withApollo} from 'react-apollo'
import {find, get, reject, isEmpty, countBy, size, map, sumBy, filter, minBy, flowRight as compose, isNil, first} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import {currencies, withdrawalPaymentVendors, kycStatuses, withdrawalStatuses} from '@bdswiss/common-enums'
import {Loading} from '../../Common/Loading'
import {withRouter, Link} from 'react-router-dom'
import ChooseMethod from './ChooseMethod'
import ChooseAccount from './ChooseAccount'
import ChooseAvailableOption from './ChooseAvailableOption'
import UiNotification from '../../Common/UiNotification'
import {getItem, isEmptyStr} from '../../../common/utils'
import messages from '../../../assets/messages'
import {blockedWithdrawalAccount, isCentAccount} from '../../../common/utils/accounts'
import {getFormattedAmount, getMissingDocs} from '../../../common/utils/general'
import {CREATE_WITHDRAWAL_MUTATION} from '../../../graphql/mutations'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY, PAYMENTS_ACCOUNTS_QUERY, PROFILE_SETTINGS_QUERY, WITHDRAWABLE_METHODS_QUERY} from '../../../graphql/queries'
import NotificationBar from '../../Common/NotificationBar'
import AppContext from '../../Common/contexts/AppContext'
import Images from '../../Common/Images'
import CustomNotificationAlert from '../../Common/CustomNotificationAlert'
import PageSubTitle from '../../Common/PageSubTitle'
import classnames from 'classnames'
import {config} from '../../../config'
import {InnerAppContext} from '../../../common/types'
import {CryptoWithdrawalsMessage} from './components/CryptoWithdrawalsMessage'

const styles = theme => ({
  modalBody: {
    lineHeight: '1.5rem'
  },
  link: {
    color: theme.palette.primary.main,
  },
  emptyListsIcon: {
    ...theme.emptyListsIcon
  },
  noTradesIconWidth: {
    textAlign: 'center' as const
  },
  makeSureDiv: {
    padding: '10px 20px',
    backgroundColor: theme.palette.lightyellow.color,
    color: theme.palette.black.color,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  zeroMarginTop: {
    marginTop: 0
  },
  highlight: {
    fontWeight: 400
  },
  noteList: {
    paddingLeft: 16,
    '& li': {
      paddingTop: 6
    }
  },
  numberedList: {
    paddingLeft: 16,
    '& li': {
      color: theme.palette.secondary.main,
      paddingLeft: 10,
      fontSize: 14
    }
  },
  hidden: {
    display: 'none'
  }
})

const pendingWithdrawalStatuses = [
  withdrawalStatuses.pending.key,
  withdrawalStatuses.notProcessed.key,
]

class Withdraw extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    const {viewer: {kycStatus}} = props
    this.state = {
      form: {
        account: '',
        amount: '',
        method: '',
        availableMethod: {},
        fields: {},
      },
      accountSelected: false,
      refundSelected: false,
      skipRefund: false,
      uiNotification: {
        show: false,
        status: ''
      },
      submitLoading: false,
      kycStatusApproved: kycStatus === kycStatuses.approved.key,
      methodsLoading: false,
      currentAccount: {},
      lydianWithdrawalModal: {
        show: false,
        status: '',
        message: '',
      }
    }
  }

  accountSelected(accountId, amount) {
    const {accounts, client: apolloClient} = this.props

    this.setState({methodsLoading: true})
    apolloClient.query({query: WITHDRAWABLE_METHODS_QUERY, variables: {id: accountId}, fetchPolicy: 'no-cache'}).then((res) => {
      const withdrawablePaymentMethods = get(res.data, 'viewer.accounts[0].withdrawablePaymentMethods') || []
      const firstVendor = minBy(withdrawablePaymentMethods, 'order')
      const refundSelected = Number(amount) <= get(firstVendor, 'amountLeft', Infinity)
      this.setState(state => ({
        form: {
          ...state.form,
          account: {
            ...find(accounts, {id: accountId}),
            withdrawablePaymentMethods,
          },
          amount: Number(amount),
          availableMethod: refundSelected ? firstVendor : null,
        },
        accountSelected: true,
        methodsLoading: false,
        refundSelected,
      }))
    }).catch(() => {
      this.setState({methodsLoading: false})
    })
  }

  refundSelected(availableMethod) {
    this.setState(state => ({
      form: {
        ...state.form,
        availableMethod,
      },
      refundSelected: true,
    }))
  }

  goBack() {
    this.setState({accountSelected: false, refundSelected: false, skipRefund: false})
  }

  createWithdrawalRequest(variables) {
    const {createWithdrawalRequest} = this.props
    this.setState({submitLoading: true})

    createWithdrawalRequest({variables}).then(() => {
      this.setState(state => ({
        uiNotification: {
          show: true,
          status: 'success'
        },
        form: {
          ...state.form,
          method: variables.paymentVendor,
        },
        submitLoading: false,
        accountSelected: false,
      }))
    }).catch((e) => {
      this.setState({
        uiNotification: {
          show: true,
          status: 'failure',
          errorText: e.message.replace('GraphQL error: ', '')
        },
        submitLoading: false,
      })
    })
  }

  handleChange(prop, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [prop]: value
      }
    }))
  }

  checkRemainingRefunds(amountToWithdraw, availableMethod) {
    const {refundSelected, form: {amount, account}, withdrawablePaymentMethods} = this.state

    if (refundSelected && (amountToWithdraw < amount)) {
      const currentMethods = !isEmpty(withdrawablePaymentMethods) ? withdrawablePaymentMethods : get(account, 'withdrawablePaymentMethods')
      const updatedMethods = reject(map(currentMethods, (m) => {
        if (m.id === availableMethod.id) {
          m.amountLeft = Number((m.amountLeft - amountToWithdraw).toFixed(2))
        }
        return m
      }), (m) => m.amountLeft <= 0)
      this.setState(state => ({
        uiNotification: {show: false, status: ''},
        refundSelected: false,
        withdrawablePaymentMethods: updatedMethods,
        form: {...state.form, amount: amount - amountToWithdraw},
        availableMethod: {},
        accountSelected: true,
      }))
    } else {
      this.setState({withdrawablePaymentMethods: []})
      this.props.history.push('/')
    }
  }

  copyTradingWithdrawalMsg(copyTradingAccount) {
    const {classes} = this.props
    const accountLabel = copyTradingAccount.copyTrading.accountCopying.accountName
    const isActiveCopying = copyTradingAccount.copyTrading.isActive
    return !isNil(copyTradingAccount) && <NotificationBar status="error">
      <Trans {...messages[isActiveCopying ? 'copyTradingWithdrawal' : 'copyTradingWithdrawalPending']}
        values={{ibAccount: `${accountLabel} - ${copyTradingAccount.copyTrading.accountCopying.remoteId}`}}
        components={[
          <span className={classes.highlight}>account</span>,
          <Link to={`/accounts/${copyTradingAccount.id}/copyTrading`} className={classes.link}>  </Link>
        ]}
      />
    </NotificationBar>
  }

  getAccountId(accountSelected) {
    this.setState({
      currentAccount: accountSelected
    })
  }

  setLydianModalData(dataModal) {
    this.setState({
      lydianWithdrawalModal: dataModal
    })
  }

  createLydianWithdrawalRequest(variables, t) {
    const {createWithdrawalRequest} = this.props
    this.setState({submitLoading: true})

    createWithdrawalRequest({variables}).then(() => {
      this.setState({
        lydianWithdrawalModal: {
          show: true,
          status: 'success',
          message: t(messages.lydianSuccessWithdrawal.i18nKey, messages.lydianSuccessWithdrawal.defaults),
        },
        submitLoading: false,
        accountSelected: false,
      })
    }).catch((e) => {
      this.setState({
        lydianWithdrawalModal: {
          show: true,
          status: 'failure',
          message: e.message.replace('GraphQL error: ', '')
        },
        submitLoading: false,
      })
    })
  }

  render() {
    const {showTab} = config
    const showDepositTab = showTab?.deposit ?? true
    const {viewerLoading, accountsLoading, classes, accounts, viewer: {locale, kycStatus, company, email},
      history, t, viewer, documentsLoading} = this.props
    const country = this.props.viewer.address ? this.props.viewer.address.country : null
    const {methodsLoading, lydianWithdrawalModal} = this.state
    const {transactionsConfig, showLydianWithdrawalNotification} = config
    if (viewerLoading || accountsLoading || documentsLoading || methodsLoading) return <Loading />
    const {accountSelected, form: {account, amount, method, availableMethod}, uiNotification, submitLoading, kycStatusApproved,
      refundSelected, skipRefund, currentAccount} = this.state
    const accountsWithBalance = reject(accounts, a =>
      a.isDemo || a.isHidden || (get(a, 'balance') || 0) <= 0 || blockedWithdrawalAccount(a))

    const methodSubmitMessage = !isEmptyStr(get(uiNotification, 'status'))
      ? [messages[`${get(uiNotification, 'status')}Withdrawal`]] : []
    const groupName = get(withdrawalPaymentVendors[method], 'groupName', 'other')
    if (get(uiNotification, 'status') === 'success' && groupName !== 'other') {
      methodSubmitMessage.push(get(messages, `${groupName}WithdrawalSuccess`))
    }

    const {blockedDeposit, themePreference} = this.context
    const missingDocsTotal = getMissingDocs(viewer)
    const missingDocs = get(countBy(missingDocsTotal), 'false') >= size(missingDocsTotal)

    const withdrawablePaymentMethods = get(this.state, 'withdrawablePaymentMethods') || get(account, 'withdrawablePaymentMethods')
    const shouldSelectAccount = !accountSelected && !isEmpty(accountsWithBalance) && kycStatusApproved
    const shouldSelectRefund = accountSelected && !isEmpty(withdrawablePaymentMethods) && !refundSelected && !skipRefund
    const shouldSelectMethod = (shouldSelectRefund && refundSelected) || (!shouldSelectRefund && accountSelected)
    const amountToWithdraw = Number((!isEmpty(availableMethod) && (amount < get(availableMethod, 'amountLeft') ? amount : get(availableMethod, 'amountLeft')))
      || amount)

    const getCurrentAccount = !accountSelected && isEmpty(currentAccount) ? first(accountsWithBalance) : currentAccount
    const blockedWithdrawalsCopyTrading = get(getCurrentAccount, 'withdrawalsBlocked')
    const disabledWithdrawal = getCurrentAccount?.credit > 0 && getCurrentAccount?.hasOpenPositions

    return (
      <div>
        <CustomNotificationAlert />
        <CryptoWithdrawalsMessage />
        {isEmpty(accountsWithBalance) && <React.Fragment>
          <NotificationBar status="info">
            <Trans {...messages.withdrawalAccountWithoutBalance} />
            {!blockedDeposit && showDepositTab &&
              <Link to="/transactions" className={classes.link}> <Trans {...messages.depositNow} /> </Link>}
          </NotificationBar>
          <Grid container
            direction="column"
            justifyContent="space-between"
            alignItems="center">
            <Grid item className={classes.noTradesIconWidth}>
              <img className={classes.emptyListsIcon} src={Images[`payments-history-empty-${themePreference}.png`]} alt='noTrades' />
            </Grid>
          </Grid>
        </React.Fragment>
        }
        {!kycStatusApproved && !isEmpty(accountsWithBalance) && <Grid item xs={12}>
          <NotificationBar status={missingDocs ? 'warning' : 'error'}>
            {missingDocs ?
              <Trans {...messages.reviewingKYCDocs} /> : <React.Fragment>
                <Trans {...messages.pendingKYCDocs} /> <Link to="/settings/profile/documents" className={classes.link}>
                  <Trans {...messages.uploadDocuments} />
                </Link>
              </React.Fragment>
            }
          </NotificationBar></Grid>}
        {blockedWithdrawalsCopyTrading && this.copyTradingWithdrawalMsg(getCurrentAccount)}
        {disabledWithdrawal && (
          <Grid item xs={12}>
            <NotificationBar status={'error'}>
              <Trans {...messages.notifyWithdrawOpenPositions} />
            </NotificationBar>
          </Grid>
        )}
        {(shouldSelectAccount || shouldSelectRefund) && <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            {shouldSelectAccount && <ChooseAccount
              accounts={accountsWithBalance}
              onContinue={(accountId, amount) => this.accountSelected(accountId, amount)}
              getSelectedAccount={(accountSelected) => this.getAccountId(accountSelected)}
              viewer={{
                locale: locale || getItem('locale', 'en'),
                kycStatus,
              }}
              history={history}
              accountId={account.id}
              amount={amount && isCentAccount(account) ? amount / currencies.CUD.baseCurrencyRate! : amount}
              disabledAction={!!blockedWithdrawalsCopyTrading || disabledWithdrawal}
              setLydianModalData={this.setLydianModalData.bind(this)}
              onLydianSubmit={(variables) => this.createLydianWithdrawalRequest(variables, t)}
            />}
          </Grid>
          {blockedWithdrawalsCopyTrading && <Grid item xs={12} sm={5} md={6}>
            <Grid container className={classes.makeSureDiv} spacing={3}>
              <Grid item xs={12}>
                <PageSubTitle><Trans {...messages.description} /> </PageSubTitle>
                <ul className={classnames(classes.noteList, classes.zeroMarginTop)}>
                  <li><Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP1} /> </Typography></li>
                  <li><Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP2} /> </Typography></li>
                  <li>
                    <Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP3} /> </Typography>
                    <ol className={classes.numberedList}>
                      <li><Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP3T1} /> </Typography></li>
                      <li><Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP3T2} /> </Typography></li>
                      <li><Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP3T3} /> </Typography></li>
                    </ol>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>}
          {shouldSelectRefund && <ChooseAvailableOption
            account={account}
            withdrawablePaymentMethods={withdrawablePaymentMethods}
            amount={amount}
            goBack={() => this.goBack()}
            onContinue={(availableMethod) => this.refundSelected(availableMethod)}
            viewer={{
              locale: locale || getItem('locale', 'en'),
              kycStatus,
            }}
            history={history}
            skipRefund={() => this.setState({skipRefund: true})}
          />}
        </Grid>}

        {shouldSelectMethod && <ChooseMethod
          account={account}
          amount={amountToWithdraw}
          viewer={{
            locale: locale || getItem('locale', 'en'),
            kycStatus,
            company,
            country,
          }}
          availableMethod={!isEmpty(withdrawablePaymentMethods) ? availableMethod : {}}
          onSubmit={(variables) => this.createWithdrawalRequest(variables)}
          goBack={() => this.goBack()}
          submitLoading={submitLoading}
          onChangeMethod={() => this.setState(state => ({
            form: {
              ...state.form,
              availableMethod: null,
            },
          }))}
        />}
        {get(uiNotification, 'show') && <UiNotification
          open={get(uiNotification, 'show')}
          status={get(uiNotification, 'status')}
          type="payment"
          buttonMessage={t(messages.close.i18nKey, messages.close.defaults)}
          onClose={(status) => {
            if (status === 'success') {
              this.checkRemainingRefunds(amountToWithdraw, availableMethod)
            } else {
              this.setState({uiNotification: {show: false, status: ''}})
            }
          }}
        >
          {methodSubmitMessage.map((message, i) =>
            <Typography variant='body1' className={classes.modalBody} key={i}>
              <Trans
                {...message}
                values={{
                  amount: getFormattedAmount({amount, currency: account.currency, locale}),
                  email,
                  days: get(transactionsConfig, `withdrawal.${method}.workingDays`) || '5-10',
                }}
                components={[
                  <strong>amount</strong>,
                  <span className={!get(transactionsConfig, 'withdrawal.successMessage24hours') ? classes.hidden : ''}>message</span>,
                  <span className={get(transactionsConfig, 'withdrawal.successMessage24hours') ? classes.hidden : ''}>message</span>,
                ]}
              />
            </Typography>)}
          {get(uiNotification, 'errorText') && <Typography variant='body1' className={classes.modalBody}>
            ({get(uiNotification, 'errorText')})
          </Typography>}
        </UiNotification>}
        {showLydianWithdrawalNotification &&
          <UiNotification
            open={lydianWithdrawalModal.show}
            status={lydianWithdrawalModal.status || 'success'}
            type="payment"
            buttonMessage={t(messages.close.i18nKey, messages.close.defaults)}
            onClose={() => this.props.history.push('/accounts')}
          >
            <Typography variant='body1'>{lydianWithdrawalModal.message}</Typography>
          </UiNotification>
        }
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withRouter,
  withApollo,
  withNamespaces(),
  graphql(CREATE_WITHDRAWAL_MUTATION, {
    name: 'createWithdrawalRequest',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    }
  }),
  graphql(PAYMENTS_ACCOUNTS_QUERY, {
    props: (props) => {
      const accounts : any = reject(get(props.data, 'viewer.accounts'), 'isArchived')
      return {
        error:props.data?.error,
        accountsLoading:props.data?.loading,
        accounts: map(accounts, (a) => {
          const pendingAmount = sumBy(filter(a.withdrawals, (w) => pendingWithdrawalStatuses.includes(w.status)), 'amount') || 0
          return {
            ...a,
            pendingAmount,
          }
        }),
      }
    },
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => {
      const viewer = get(props.data, 'viewer')
      return {
        error: props.data?.error,
        viewerLoading: props.data?.loading,
        viewer,
      }
    }
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => {
      const viewer = get(props.data,'viewer')
      const documents = get(viewer, 'documents', [])
      return {
        error: props.data?.error,
        documentsLoading:props.data?.loading,
        documents,
      }
    }
  }),
)(Withdraw)
