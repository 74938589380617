import React from 'react'
import {get, flowRight as compose} from 'lodash'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import {withRouter} from 'react-router-dom'
import {graphql, withApollo} from 'react-apollo'
import Button from '@material-ui/core/Button'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../Common/Loading'
import withStyles from '@material-ui/core/styles/withStyles'
import AffiliateRoutes from './routes'
import PageTitle from '../../Common/PageTitle'
import PageBody from '../../Common/PageBody'
import messages from '../../../assets/messages'
import {ACCOUNTS_QUERY} from '../../../graphql/queries'
import {AFFILIATE_SSO_LINK_QUERY} from '../../../graphql/mutations'
import {isMobile} from '../../../common/utils/browser'
import AppContext from '../../Common/contexts/AppContext'
import {getAccountLabel} from '../../../common/utils/accounts'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  titleItem: {
    flex: 1,
  },
})

class AffiliateAccountView extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext

  toAffiliatePortal() {
    const {client: apolloClient, account} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab!.document.write('<h4>Please wait</h4>')

    apolloClient.query({query: AFFILIATE_SSO_LINK_QUERY, variables: {accountId: account.id}, fetchPolicy:'network-only'}).then((res) => {
      newTab!.location = res.data.data.link
    }).catch((e) => {
      newTab!.close()
    })
  }

  render() {
    const {loading, account, history, t} = this.props
    const {locale} = this.context

    if (loading) return <Loading />
    const tabs = [
      {path: '', label: t(messages.overview.i18nKey, messages.overview.defaults)},
      {path: '/transactions', label: t(messages.payments.i18nKey, messages.payments.defaults)},
    ]
    const tabIndex = tabs.findIndex(tab =>
      tab.path.length > 0 && window.location.href.endsWith(tab.path)
    )
    const activeTab = tabIndex >= 0 ? tabIndex : 0

    const isButtonDisabled = !['Approved', 'View Only'].includes(get(account, 'serviceFields.affiliateStatus'))

    const label = getAccountLabel(account, locale)

    return (<div>
      <PageTitle
        title={label}
        onBack={() => history.push('/accounts')}
        rightAction={
          <React.Fragment>
            <Button
              onClick={() => this.toAffiliatePortal()}
              variant="contained"
              size="large"
              color="primary"
              disabled={isButtonDisabled}
            >
              <Trans {...messages.partnershipPlatform} />
            </Button>
          </React.Fragment>
        }
        subtitle={
          <Trans {...messages.accountID} values={{accountId: account.login}} />
        }
      >
        <Tabs
          variant={isMobile()? 'scrollable' : 'standard'}
          value={activeTab}
          onChange={(_, activeTab) => this.setState({activeTab})}
        >
          {tabs.map((tab, key) =>
            <Tab
              key={key}
              label={tab.label}
              textColor="inherit"
              onClick={() => history.push(`/accounts/${account.id}${tab.path}`)} />
          )}
        </Tabs>
      </PageTitle>
      <PageBody>
        <AffiliateRoutes />
      </PageBody>
    </div>)
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withApollo,
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    options: (props:any) => ({variables: {id: Number(props.match.params.accountId)}}),
    props: ({data: {error, loading}, data, ownProps: {match}}:any) => ({
      error,
      loading,
      account: get(data, 'viewer.accounts.0'),
    })
  }),
)(AffiliateAccountView)
