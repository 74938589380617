// @ts-nocheck
import React from 'react'
import {keys, isEmpty, map, get, flowRight as compose} from 'lodash'
import {Trans} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'

import {vpsSubscriptionPlans, vpsSubscriptionTypes} from '@bdswiss/common-enums'
import messages from '../../assets/messages'
import {safeParseJSON, getPlanAmount} from '../../common/utils/general'
import NotificationBar from '../Common/NotificationBar'
import {Link} from 'react-router-dom'

const styles = theme => ({
  highlight: {
    fontWeight: 400,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
})

function VpsSubscriptionMsg({subscription, notificationStatus, classes, accounts, verificationRequired, manualVpsPlan, dunningManagementDays}) {
  const accountTypes = map(accounts, (a) => a.accountSubtype)
  const vms = subscription && safeParseJSON(subscription.vms)
  const meta = subscription && safeParseJSON(subscription.meta)
  const subscriptionPlan = subscription && vpsSubscriptionPlans[subscription.planName]
  const renewalDate = subscription && new Date(subscription.nextPaymentDate).toLocaleString()

  let terminationDate = get(subscription,'failedPaymentDate') && new Date(subscription.failedPaymentDate)
  terminationDate = terminationDate &&
    new Date(terminationDate.setDate(terminationDate.getDate()+dunningManagementDays)).toLocaleString()

  const freeSubscription = subscription && subscription.type === vpsSubscriptionTypes.free.value
  let planMessage, actionLink
  if (manualVpsPlan) return false
  switch (true) {
    case meta && meta.last_payment_failed === true:
      planMessage = {
        ...messages['vpsSubscriptionFailedPayment'],
        values: {
          renewalDate: terminationDate || '',
        }
      }
      break
    case meta && 'pending_plan' in meta:
      const pendingPlan = vpsSubscriptionPlans[meta.pending_plan]
      const isFree = get(pendingPlan, 'isFree').some((r) => accountTypes.includes(r))
      planMessage = {
        ...messages[isFree?'vpsSubscriptionPendingChangeFree':'subscriptionPendingChange'],
        values: {
          pendingPlan: pendingPlan.label,
          renewalDate,
          renewalAmount: getPlanAmount(subscriptionPlan, pendingPlan)
        }
      }
      break
    case meta && 'cancelled_at' in meta:
      planMessage = {
        ...messages['vpsSubscriptionCancelled'],
        values: {
          renewalDate,
        }
      }
      break
    case ((!subscription || subscription.isExpired) && !!verificationRequired):
      planMessage = {
        ...messages[verificationRequired === 'pending' ? 'vpsVerificationPending' : 'vpsVerificationRequired'],
      }
      actionLink = verificationRequired !== 'pending' &&
        <Link to={'/accounts/verification'} className={classes.link}><Trans {...messages.verifyAccount} /></Link>
      break
    case (!subscription || subscription.isExpired):
      planMessage = {
        ...messages['vpsSubscriptionDescription'],
      }
      break
    default:
      planMessage = {
        ...messages[freeSubscription?'vpsSubscriptionActiveFree':'subscriptionActive'],
        values: {
          plan: subscriptionPlan.label,
          renewalDate,
          renewalAmount: getPlanAmount(subscriptionPlan)
        }
      }
  }
  return (
    <NotificationBar status={notificationStatus}>
      <Trans
        {...planMessage}
        components={keys(planMessage.values).map((key,i) => <span key={i} className={classes.highlight}>{key}</span>)}
      /><br/>{actionLink}
      {subscription && subscription.isActive && isEmpty(vms) && <Trans {...messages.vpsSetupWait} />}
    </NotificationBar>
  )
}

export default compose(
  withStyles(styles),
)(VpsSubscriptionMsg)
