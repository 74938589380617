import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {withNamespaces, Trans} from 'react-i18next'
import {find, findIndex, first, map, flowRight as compose} from 'lodash'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import withStyles from '@material-ui/core/styles/withStyles'
import messages from '../../assets/messages'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import PaymentRoutes from './routes'
import {FullScreenDialog} from '../Common/Dialog'
import AppContext from '../Common/contexts/AppContext'
import {isMobile} from '../../common/utils'
import {config} from '../../config'
import {InnerAppContext} from '../../common/types'
import {graphql} from 'react-apollo'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'

const {showTab} = config

const showDepositTab = showTab?.deposit ?? true

const tabs = [
  {key: 'deposit', title: messages.deposit, relativePath: '/deposit'},
  {key: 'withdrawal', title: messages.withdraw, relativePath: '/withdraw'},
  {key: 'transfer', title: messages.transfer, relativePath: '/transfer'},
  {key: 'history', title: messages.history, relativePath: '/history'},
]

let availableTabs = showDepositTab
  ? tabs
  : tabs.filter((tab) => tab.key !== 'deposit')

const styles = (theme) => ({
  header: {
    marginBottom: 40,
  },
})
export class Payments extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  static propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object
  }
  constructor(props) {
    super(props)
    this.state = {
      activeTab: findIndex(availableTabs, first(availableTabs)),
      goBackLink: this.props.location.state ? this.props.location.state.prevPath : '/accounts'
    }
  }

  handleTabChanged(activeTabIndex) {
    const {history, match} = this.props
    this.setState({activeTab: activeTabIndex})
    const currentTab = availableTabs[activeTabIndex]
    history.push(`${match.url}${currentTab.relativePath}`)
  }

  render() {
    const {classes, t, location, viewer} = this.props

    const {blockedDeposit} = this.context
    if (blockedDeposit) {
      availableTabs = availableTabs.filter((tab) => tab.key!=='deposit')
    }

    if (viewer.fundManager) {
      availableTabs = availableTabs.filter((tab) => tab.key!=='history')
    }

    const currentTab =
      find(availableTabs, (tab) => location.pathname.indexOf(`${tab.relativePath}`) > -1) || first(availableTabs)

    const currentTabIndex = findIndex(availableTabs, currentTab)

    return (
      <FullScreenDialog
        desktopOnly
        fullScreen
        open={true}
        onClose={() => this.props.history.push(this.state.goBackLink)}
      >
        <PageTitle
          title={t(messages.payments.i18nKey, messages.payments.defaults)}
          onBack={()=> this.props.history.push(this.state.goBackLink)}>
          <Tabs
            value={currentTabIndex}
            onChange={(_, activeTab) => this.handleTabChanged(activeTab)}
            classes={{root: classes.tabsRoot}}
            variant={isMobile()? 'scrollable' : 'standard'}
            scrollButtons='off'
          >
            {map(availableTabs, (tab) => <Tab key={tab.key} label={<Trans {...tab.title} />} />)}
          </Tabs>
        </PageTitle>
        <PageBody>
          <PaymentRoutes />
        </PageBody>
      </FullScreenDialog>
    )
  }
}

export default compose(
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer}}: any) => ({error, loading, viewer})
  }),
)(Payments)
