// @ts-nocheck
import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withStyles} from '@material-ui/core/styles'
import {CLIENT_DATA_QUERY, VPS_SUBSCRIPTIONS_QUERY} from '../../graphql/queries'
import {
  CREATE_VPS_SUBSCRIPTION_MUTATION,
  CHANGE_VPS_BILLING_INFO_MUTATION,
  CHANGE_VPS_SUBSCRIPTION_MUTATION
} from '../../graphql/mutations'
import {get, isEmpty, flowRight as compose} from 'lodash'
import Grid from '@material-ui/core/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import {vpsSubscriptionPlans, vpsSubscriptionStatuses} from '@bdswiss/common-enums'
import messages from '../../assets/messages'
import PageSubTitle from '../Common/PageSubTitle'
import PlanCard from '../Common/PlanCard'
import BillingInfoCard from '../Common/BillingInfoCard'
import {Loading} from '../Common/Loading'
import ChargeBeeProvider from '../Payments/Deposits/PaymentProviders/ChargeBeeProvider'
import RecurlyProvider from '../Payments/Deposits/PaymentProviders/RecurlyProvider'
import {config} from '../../config'
import {Redirect} from 'react-router-dom'

const styles = theme => ({
  highlightedText: {
    color: theme.palette.primary.main,
    cursor:'pointer'
  },
  leftSide:{
    paddingTop: '1rem'
  },
  freeSubnote:{
    paddingBottom: '1.5rem'
  },
  changeDiv:{
    padding: '15px 0'
  }
})

export class Payment extends Component {

  constructor(props) {
    super(props)
    this.state = {
      token: '',
      tokenThreeD: '',
      subscriptionError: '',
      loadingProp: false,
      openDialogThreeD: false,
    }
  }

  async createVpsSubscription(prop, value, tokenThreeD) {
    this.setState({[prop]: value, subscriptionError: '', loadingProp: true, openDialogThreeD: false, tokenThreeD: ''})
    const {history, createVpsSubscription, client} = this.props
    const plan = history.location.state && history.location.state.plan && history.location.state.plan.key
    const {token} = this.state

    return createVpsSubscription({variables: {
      clientId: client.id,
      planName: plan,
      tokenId: token,
      tokenThreeD
    }}).then((res) => {
      const tokenThreeD = get(res, 'data.createVpsSubscription.tokenThreeD')
      if (tokenThreeD) {
        this.setState({tokenThreeD, loadingProp: false, openDialogThreeD: true})
      } else {
        window.location.href = '/vps'
      }
      return res
    }).catch((err) => {
      const subscriptionError = get( err, 'graphQLErrors[0].message') || err.message
      this.setState({subscriptionError:subscriptionError, loadingProp: false})
      return err
    })
  }

  async changeVpsBillingInfo(prop, value, tokenThreeD) {
    this.setState({[prop]: value, subscriptionError: '', loadingProp: true})
    const {client, vpsSubscriptions, changeVpsBillingInfo, changeVpsSubscription, location} = this.props
    const subscription =  !isEmpty(vpsSubscriptions)
    && vpsSubscriptions.find(s => s.status !== vpsSubscriptionStatuses.deleted.value)
    const {token, plan} = this.state
    return changeVpsBillingInfo({variables: {
      clientId: client.id,
      id: subscription.id,
      tokenId: token,
      tokenThreeD
    }}).then((res) => {
      const tokenThreeD = get(res, 'data.changeVpsBillingInfo.tokenThreeD')
      if (tokenThreeD) {
        this.setState({tokenThreeD, loadingProp: false, openDialogThreeD: true})
      } else {
        if (location && location.state && location.state.changePlan) {
          changeVpsSubscription({variables: {
            clientId: client.id,
            planName: get(plan, 'value'),
          }}).then(() => {
            window.location.href = '/vps/plan'
          })
            .catch((err) => {
              const subscriptionError = get( err, 'graphQLErrors[0].message') || err.message
              this.setState({subscriptionError:subscriptionError, loadingProp: false})
            })
        } else {
          window.location.href = '/vps/plan'
          return res
        }
      }
      return res
    }).catch((err) => {
      const subscriptionError = get( err, 'graphQLErrors[0].message') || err.message
      this.setState({subscriptionError:subscriptionError, loadingProp: false})
      return err
    })
  }

  onThreeDError = (err) => {
    this.setState({subscriptionError: err, loadingProp: false, openDialogThreeD: false})
  }

  render() {
    const {
      classes, client, history,
      vpsSubscriptionsLoading, clientLoading, loading,
      vpsSubscriptions
    } = this.props

    if (vpsSubscriptionsLoading || clientLoading || loading) return <Loading />

    const currentSubscription = !isEmpty(vpsSubscriptions)
    && vpsSubscriptions.find(s => s.status !== vpsSubscriptionStatuses.deleted.value)
    const {billingInfo, planName} = currentSubscription || {}

    const {subscriptionError, loadingProp, openDialogThreeD, token, tokenThreeD} = this.state
    const plan = history.location.state && history.location.state.plan
      ? history.location.state.plan
      : planName ? vpsSubscriptionPlans[planName] : {}

    if (isEmpty(plan))
      return <Redirect to={{pathname: '/vps'}} />

    const subscriptionProvider = config.common.subscriptionProvider

    return (
      <Grid container justifyContent='space-between' spacing={0}>
        <Grid item xs={12} sm={8} lg={6}>
          <PageSubTitle>
            <Trans
              {...messages.paymentFor}
              values={{plan: get(plan, 'label')}}
              components={[<span onClick={() => history.goBack()}
                className={classes.highlightedText}>plan</span>]}
            />
          </PageSubTitle>
          {subscriptionProvider === 'recurly' && <RecurlyProvider client={client}
            recurlyAction= {(prop, event, tokenThreeD) => planName === plan.value ?
              this.changeVpsBillingInfo(prop, event, tokenThreeD) : this.createVpsSubscription(prop, event, tokenThreeD)}
            recurlyError={subscriptionError}
            loadingProp={loadingProp}
            parentToken={token}
            openDialogThreeD={openDialogThreeD}
            onThreeDError={this.onThreeDError}
          />}
          {subscriptionProvider === 'chargebee' &&<ChargeBeeProvider
            chargeBeeAction={(prop, event, tokenThreeD) => planName === plan.value
              ? this.changeVpsBillingInfo(prop, event, tokenThreeD)
              : this.createVpsSubscription(prop, event, tokenThreeD)}
            tokenThreeD={tokenThreeD}
          />}
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={classes.leftSide}>
          {billingInfo && <BillingInfoCard billingInfo={billingInfo} />}
          <PlanCard  plan={plan} showActions={false} noActions={true} vps single />
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      clientLoading: loading,
      clientError: error,
      client: get(data, 'viewer'),
    }),
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(VPS_SUBSCRIPTIONS_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      vpsSubscriptionsLoading: loading,
      vpsSubscriptionsError: error,
      vpsSubscriptions: get(data, 'viewer.vpsSubscriptions', []),
    }),
  }),
  graphql(CREATE_VPS_SUBSCRIPTION_MUTATION, {
    name: 'createVpsSubscription',
  }),
  graphql(CHANGE_VPS_BILLING_INFO_MUTATION, {
    name: 'changeVpsBillingInfo',
  }),
  graphql(CHANGE_VPS_SUBSCRIPTION_MUTATION, {
    name: 'changeVpsSubscription',
  }),
)(Payment)
