//@ts-nocheck
import React from 'react'
import {get, flowRight as compose} from 'lodash'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import {withRouter} from 'react-router-dom'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../Common/Loading'
import withStyles from '@material-ui/core/styles/withStyles'
import SMPRimeWalletRoutes from './routes'
import PageTitle from '../../Common/PageTitle'
import messages from '../../../assets/messages'
import {ACCOUNTS_QUERY} from '../../../graphql/queries'
import {isMobile} from '../../../common/utils/browser'
import PageBody from '../../Common/PageBody'
import AppContext from '../../Common/contexts/AppContext'
import {getAccountLabel} from '../../../common/utils/accounts'

const styles = theme => ({
  titleItem: {
    flex: 1,
  }
})

class BitnukAccountView extends React.Component {
  static contextType = AppContext

  render() {
    const {loading, account, history, t} = this.props
    const {locale} = this.context

    if (loading) return <Loading />
    const tabs = [
      {path: '', label: t(messages.overview.i18nKey, messages.overview.defaults)},
      {path: '/transactions', label: t(messages.payments.i18nKey, messages.payments.defaults)},
    ]
    const tabIndex = tabs.findIndex(tab =>
      tab.path.length > 0 && window.location.href.endsWith(tab.path)
    )
    const activeTab = tabIndex >= 0 ? tabIndex : 0

    const label = getAccountLabel(account, locale)

    return (<div>
      <PageTitle
        title={label}
        onBack={() => history.push('/accounts')}
        subtitle={
          <Trans {...messages.accountID} values={{accountId: account.login}} />
        }
      >
        <Tabs
          variant={isMobile()? 'scrollable' : 'standard'}
          value={activeTab}
          onChange={(_, activeTab) => this.setState({activeTab})}
        >
          {tabs.map((tab, key) =>
            <Tab
              key={key}
              label={tab.label}
              textColor="inherit"
              onClick={() => history.push(`/accounts/${account.id}${tab.path}`)} />
          )}
        </Tabs>
      </PageTitle>
      <PageBody>
        <SMPRimeWalletRoutes />
      </PageBody>
    </div>)
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    options: (props) => ({variables: {id: Number(props.match.params.accountId)}}),
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      account: get(data, 'viewer.accounts.0'),
    })
  }),
)(BitnukAccountView)
