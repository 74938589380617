// @ts-nocheck
import React, {PureComponent} from 'react'
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts'
import messages from '../../assets/messages'
import moment from 'moment'
import {config} from '../../config'

export default class LatencyChart extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      chartData: []
    }
  }

  componentDidMount() {
    this.setState(this.fetchData)
  }

  fetchData = () => {
    const endDate = moment().toISOString()
    const startDate = moment().subtract(12, 'hours').toISOString()
    const url = config.prometheusEndpoint + '/api/v1/query_range?query=ping&start=' + startDate + '&end='+ endDate +'&step=10m'

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + config.prometheusAuthToken
    }

    const options = {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
      headers,
    }

    fetch(url, options)
      .then(res => res.json()).then(data => {
        const result = data.data.result
        if (result.length === 0) {
          const chartData = [{time: 0, data: 0}]
          this.setState({chartData})
        } else {
          const dataArr = result[0].values
          const chartData = dataArr.map((item) => ({time: moment.unix(item[0]).format('HH:mm:ss'), data: parseFloat(item[1])}))
          this.setState({chartData})
        }
      })
  }

  render() {
    const {chartData} = this.state
    const {t} = this.props

    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={chartData} margin={{top: 15, right: 30, bottom: 15}}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" label={{value: t(messages.vpsLatencyChartXAxis.i18nKey, messages.vpsLatencyChartXAxis.defaults), position: 'bottom', offset: 0, fill: '#434141'}}/>
          <YAxis label={{value: t(messages.vpsLatencyChartYAxis.i18nKey, messages.vpsLatencyChartYAxis.defaults), angle: -90, position: 'insideLeft', fill: '#434141'}}/>
          <Tooltip />
          <Line name={t(messages.vpsLatencyTooltip.i18nKey, messages.vpsLatencyTooltip.defaults)} type="monotone" dataKey="data" stroke="#007AFF"/>
        </LineChart>
      </ResponsiveContainer>
    )
  }
}
