import {every, flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import List from '@material-ui/core/List'
import {frontends} from '@bdswiss/common-enums'
import ListItem from '@material-ui/core/ListItem'
import {withStyles} from '@material-ui/core/styles'
import {withNamespaces, Trans} from 'react-i18next'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import FormHelperText from '@material-ui/core/FormHelperText'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import messages from '../../../../assets/messages'
import {getPlatform, findCompany} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  bold: {
    fontWeight: 500,
  },
  textCenter: {
    textAlign: 'justify' as const
  }
})

class CardpayCryptoProvider extends Component<any,any> {
  static propTypes = {
    account: PropTypes.shape({
      id: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    amount: PropTypes.number.isRequired,
    providerProperties: PropTypes.shape({
      name: PropTypes.string.isRequired,
      provider: PropTypes.string.isRequired,
      paymentKey: PropTypes.string,
    }).isRequired,
    onError: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
  }
  state : any = {gettingUrl: false, form: {rollbackAddress: ''}, errors: {rollbackAddress: ''}}

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields())
  }

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      errors[k] = !form[k] ? messages.requiredField : ''
    })
    this.setState({errors})
    return errors
  }

  getUrl() {
    const {providerProperties: {provider, paymentKey}, account, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms} = this.props

    const errors = this.validateFields()
    if (every(errors)) {
      return
    }

    this.setState({gettingUrl: true})

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        rollbackAddress: this.state.form.rollbackAddress,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        window.location = url
      })
      .catch((e) => {
        this.setState({gettingUrl: false})
        onError && onError(e)
      })
  }

  render() {
    const {classes} = this.props
    const {gettingUrl, form: {rollbackAddress}, errors} = this.state

    const companyObject = findCompany()
    const {brandLabel: companyLabel, emailDomain} = companyObject

    return (
      <React.Fragment>
        <Grid container direction="row" spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="rollback-address">
                <Trans {...messages.rollbackAddress} />
              </InputLabel>
              <Input
                id="rollback-address"
                type="text"
                error={!!errors.rollbackAddress}
                value={rollbackAddress}
                onChange={(e) => this.handleChange('rollbackAddress', e.target.value)}
              />
              {errors.rollbackAddress &&
                <FormHelperText className={classes.error}><Trans {...errors.rollbackAddress} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <PaymentActionButton
              loading={gettingUrl}
              disable={every(errors)}
              onClick={() =>  this.getUrl()}
              noMargin
            />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h6">
              <Trans {...messages.importantNotes} />
            </Typography>
            <List>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.rollbackAddressNote} components={[<span className={classes.bold}>Rollback Address</span>]} />} />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.cryptoDepositNote1} values={{company: companyLabel}} />} />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.cryptoDepositNote2} />} />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.cryptoDepositNote3} values={{emailDomain}}/>} />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.cryptoDepositNote4} />} />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.cryptoDepositNote5} />} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}



export default compose(
  withMobileDialog(),
  withCreateDeposit,
  withStyles(style),
  withNamespaces(),
)(CardpayCryptoProvider)
