import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {Link} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../../assets/messages'
import {ACCOUNTS_QUERY, ACCOUNT_HAS_OPEN_POSITIONS_QUERY} from '../../../../graphql/queries'
import {get, isEmpty, first, flowRight as compose} from 'lodash'
import PageSubTitle from '../../../Common/PageSubTitle'
import Grid from '@material-ui/core/Grid'
import StrategyAlertDialog from './StrategyAlertDialog'
import StrategyCard from './StrategyCard'
import {getStrategiesDetails, startCopying, stopCopying} from '../../../../common/utils/requests'
import StopStrategyDialog from './StopStrategyDialog'
import {config} from '../../../../config'
import NotificationBar from '../../../Common/NotificationBar'
import classNames from 'classnames'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import Typography from '@material-ui/core/Typography'
import {checkAccountCopyTrading} from '../../../../common/utils/accounts'
import {CHECK_PROTECTED_PASSWORD_STRATEGY_MUTATION} from '../../../../graphql/mutations'

const styles = theme => ({
  root: {
    display: 'block',
    margin: '20px 0px'
  },
  link: {
    color: theme.palette.primary.main,
  },
  linkBtn: {
    padding: 10,
    fontSize: 16
  },
  yellowText: {
    color: theme.palette.yellow.color,
  },
  notificationDiv: {
    marginBottom: 30,
    padding:10,
    backgroundColor: theme.palette.lightyellow.color
  },
  infoIcon: {
    marginBottom: -6,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 5,
  },
  notificationDivText:{
    padding: '5px 0px 5px 35px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0',
    }
  },
  displayInline: {
    display: 'inline',
  },
  notificationDivBtns:{
    padding: 10,
    textAlign: 'right' as const
  },
  spanCredit: {
    display: 'block',
    fontSize: 14,
    paddingLeft: 30
  },
  btnsSpan: {
    display: 'block',
    textAlign: 'right' as const,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center' as const,
      paddingTop: 10,
    }
  },
  btnsCredit: {
    padding: '0 15px',
    fontSize: 14,
  },
})

export class CopyTrading extends Component<any,any> {

  constructor(props) {
    super(props)
    this.state = {
      status: '',
      loading: false,
      showPopup: false,
      showStopPopup: false,
      method: '',
      percentage: '',
      selectedStrategy: {},
      requestedStrategy:{},
      passwordApproved: false,
      form: {password: ''},
      errors: {},
      showPassword: false,
    }
  }

  componentDidMount() {
    const {account, history} = this.props
    const {common:{globalCopyTradingEnabled}} = config

    if (!checkAccountCopyTrading(account)) {
      history.push(`/accounts/${account.id}`)
    } else {
      getStrategiesDetails(account.id).then((res) => {
        if (get(res,'error')) {
          this.setState({strategiesError: true})
        } else {
          if (isEmpty(res)) {
            history.push(`/accounts/${account.id}`)
          } else {
            const providerType = get(res[0], 'copyTradingProvider')
            const enableCopyTrading = get(globalCopyTradingEnabled, providerType)
            || (!get(globalCopyTradingEnabled, providerType) && get(account.copyTrading,'isActive'))
            if (!enableCopyTrading) {
              history.push(`/accounts/${account.id}`)
            }
            this.setState({strategies: res})
          }
        }
      })
    }
  }

  requestAccess(action) {
    const {account} = this.props
    const {selectedStrategy} = this.state
    this.setState({loading:true})

    if (action === 'startCopying') {
      startCopying(account.id, selectedStrategy.accountId).then((res) => {
        if (get(res,'error')) {
          this.setState({loading:false, status: 'failure'})
        } else {
          this.setState({loading:false, status: 'success', showPopup: false})
          this.props.refetchAccounts()
        }
      })
    } else {
      stopCopying(account.id).then((res) => {
        if (get(res,'error')) {
          this.setState({loading:false, status: 'failure'})
        } else {
          this.setState({loading:false, status: 'success', showStopPopup: false})
          if (selectedStrategy.performanceFee > 0) {
            window.location.reload()
          } else {
            this.props.refetchAccounts()
          }
        }
      })
    }
  }

  requestPassword(password) {
    const {t} = this.props
    const {selectedStrategy: {accountId}} = this.state
    this.setState({loading: true})
    this.props.checkProtectedPasswordStrategy({variables: {accountId, password}}).then((res) => {
      if (!!get(res, 'data.checkProtectedPasswordStrategy'))
        this.setState({status: '', loading: false, passwordApproved: true})
      else
        this.setState({status: 'failure', loading: false, errorMessage: t(messages.passwordMismatch.i18nKey, messages.passwordMismatch.defaults)})
    }).catch(() => {
      this.setState({status: 'failure', loading: false})
    })
  }

  handleChangeForm(name, value) {
    this.setState(state => ({
      form: {...state.form, [name]: value},
      errors: {...state.errors, [name]: !value},
      status: '',
      errorMessage: '',
    }))
  }

  render() {
    const {classes, account, hasOpenPositions} = this.props
    const {showPopup, showStopPopup, strategies, status, errorMessage,
      strategiesError, loading, selectedStrategy, passwordApproved, form:{password}, showPassword} = this.state

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12} >
          <PageSubTitle>
            <Trans {...messages.availableStrategies} />
          </PageSubTitle>
        </Grid>
        <Grid container spacing={2}>
          {(account.isReadOnly && !account.copyTrading) && <Grid item xs={12}>
            <NotificationBar status='info'>
              <Trans {...messages.depositPendingCopying} />
              <Link to={`/transactions/${account.id}/deposit`} className={classes.link}> <Trans {...messages.depositNow} /> </Link>
            </NotificationBar>
          </Grid>}
          {!account.isReadOnly && hasOpenPositions && !get(account.copyTrading,'isActive') &&
            <Grid item xs={12} className={classes.notificationDiv}>
              <Grid container>
                <Grid item xs={12}>
                  <InfoIcon className={classNames(classes.infoIcon, classes.yellowText)}/>
                  <Typography variant="subtitle1" className={classes.displayInline}> <Trans {...messages.copyingOpenPositions} /> </Typography>
                </Grid>
                <Grid item xs={12} className={classes.notificationDivText}>
                  <Typography variant="body1"> <Trans {...messages.strategyOpenPositions} /> </Typography>
                </Grid>
                <Grid item xs={12} className={classes.notificationDivBtns}>
                  <Link to={`/accounts/${account.id}`} className={classNames(classes.link, classes.linkBtn)}>
                    <Trans {...messages.managePositions} />
                  </Link>
                  <Link to={'/accounts'} className={classNames(classes.link, classes.linkBtn)}> <Trans {...messages.changeAccount} /> </Link>
                </Grid>
              </Grid>
            </Grid>}
          {strategies && (strategies).map(strategy =>
            <Grid item xs={12} key={strategy.login}>
              <StrategyCard
                account={account}
                strategy={strategy}
                onClick={() => this.setState({showPopup: true, selectedStrategy: strategy, status: '', loading: false})}
                onStop={(enableStopBtn) => this.setState({showStopPopup: true, requestedStrategy: enableStopBtn, status: '', loading: false, selectedStrategy: strategy})}
                hasOpenPositions={hasOpenPositions}
              />
            </Grid>
          )}
          {strategiesError && (<Grid item xs={12}><NotificationBar status="error">
            {<Trans {...messages.somethingWrongError} />}
          </NotificationBar></Grid>)
          }
        </Grid>
        {showPopup && <StrategyAlertDialog open={showPopup} status={status} loading={loading} errorMessage={errorMessage} strategy={selectedStrategy}
          close={() => this.setState(state => ({showPopup: false, passwordApproved: false, form: {...state.form, password: ''}}))}
          onClick={(password) => (get(selectedStrategy, 'protectedPassword') && !passwordApproved) ? this.requestPassword(password) : this.requestAccess('startCopying')}
          handleChangeForm={(name, value) => this.handleChangeForm(name, value)} passwordApproved={passwordApproved}
          onClickShow={() => this.setState(state => ({showPassword: !state.showPassword}))} password={password} showPassword={showPassword}
        />}
        <StopStrategyDialog open={showStopPopup} status={status} loading={loading} close={() => this.setState({showStopPopup: false})}
          onClick={() => this.requestAccess('stopCopying')} strategy={selectedStrategy}
        />
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps: {match}}:any) => {
      const {accountId} = match.params
      const account = get(data, 'viewer.accounts', []).find(account => account.id === Number(accountId))
      return {
        error,
        loading,
        account,
        refetchAccounts: data.refetch
      }
    }
  }),
  graphql(ACCOUNT_HAS_OPEN_POSITIONS_QUERY, {
    options: (props:any) => ({variables: {accountId: props.match.params.accountId}}),
    props: ({data: {error: errorPositions, loading: loadingPositions}, data}:any) => {
      const hasOpenPositions = get(first(get(data, 'viewer.accounts', [])), 'hasOpenPositions')
      return {
        errorPositions,
        loadingPositions,
        hasOpenPositions,
      }
    }
  }),
  graphql(CHECK_PROTECTED_PASSWORD_STRATEGY_MUTATION, {
    name: 'checkProtectedPasswordStrategy',
  }),
)(CopyTrading)
