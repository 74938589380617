import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'
import {withRouter} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import {Trans} from 'react-i18next'
import {AlertDialog} from './Dialog'
import AppContext from './contexts/AppContext'
import messages from '../../assets/messages'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
}

class TradingGameNotice extends Component {
  static contextType = AppContext

  handleClick() {
    this.props.history.push('/support')
  }

  render() {
    const {noticeData, classes} = this.props

    if (!noticeData) return null

    return (
      <div>
        <AlertDialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.onClose}
          title={noticeData.title}
        >
          {<div dangerouslySetInnerHTML={{__html: noticeData.content}} />}
          <div className={classes.container}>
            <Button onClick={this.handleClick.bind(this)} color={'primary'} size="medium" variant="contained">
              <Trans {...messages.contactSupport} />
            </Button>
          </div>
        </AlertDialog>
      </div>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
)(TradingGameNotice)
