import React from 'react'
import PropTypes from 'prop-types'
import {flowRight as compose} from 'lodash'
import Grid from '@material-ui/core/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import WarningIcon from '@material-ui/icons/Warning'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import {AlertDialog} from '../../Common/Dialog'
import messages from '../../../assets/messages'

const styles = theme => ({
  alignCenter: {
    textAlign: 'center' as const,
  },
  yellow: {
    color: theme.palette.yellow.color,
  },
  highlight:{
    fontWeight:400
  },
})

const FreeMarginWarning = (props) => {
  const {open, classes, t, onSubmit, onCancel, amount, freeMargin} = props

  return (
    <AlertDialog
      open={open}
      agreeText={t(messages.changeAmount.i18nKey, messages.changeAmount.defaults)}
      onAgree={() => onSubmit()}
      disagreeText={t(messages.proceedButton.i18nKey, messages.proceedButton.defaults)}
      onDisagree={() => onCancel()}
    >
      <Grid container direction="row" alignContent="center" justifyContent="center">
        <Grid item sm={10} xs={12} className={classes.alignCenter}>
          <WarningIcon fontSize="large" className={classes.yellow} />
        </Grid>
        <Grid item sm={10} xs={12} className={classes.alignCenter}>
          <Typography variant="body2">
            <Trans {...messages.freeMarginWarningTitle} values={{amount, freeMargin}} />
          </Typography>
        </Grid>
        <Grid item sm={10} xs={12}>
          <Typography variant="body1">
            <Trans {...messages.freeMarginWarningMessage1} />
          </Typography>
        </Grid>
        <Grid item sm={10} xs={12}>
          <Typography variant="body1">
            <Trans {...messages.freeMarginWarningMessage2} values={{amount}}
              components={[<span className={classes.highlight}>amount</span>]}
            />
          </Typography>
        </Grid>
      </Grid>
    </AlertDialog>
  )
}

FreeMarginWarning.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired,
  freeMargin: PropTypes.string.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(FreeMarginWarning)
