import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import Typography from '@material-ui/core/Typography'
import {get, find, filter, isEmpty, includes, flowRight as compose} from 'lodash'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import AccountSearchSelect from '../../Common/AccountSearchSelect'
import FormHelperText from '@material-ui/core/FormHelperText'
import LoadingButton from '../../Common/LoadingButton'
import AppContext from '../../Common/contexts/AppContext'
import classnames from 'classnames'
import {isMobile} from '../../../common/utils'
import {config} from '../../../config'
import {accountTypes} from '@bdswiss/common-enums'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  container: {
    padding: '20px 0'
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  checkBoxError: {
    color: theme.palette.error.main,
  },
  textCenter: {
    textAlign: 'center' as const
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  disabledDiv: {
    pointerEvents:'none' as const
  }
})

class CompetitionForm extends Component<any,any> {
    static contextType = AppContext
    context!: InnerAppContext

    render() {
      const {classes, t, client, accounts, rules, handleChange, handleChangeAccount, competitionSign} = this.props
      const {state: {form: {nickname, account, acceptTerms}, errors, loading , status, competitionError, competitionErrorValues,
        nicknameExists, competition, participatedCompetition,cannotParticipateCompetition}} = this.props
      const {locale, companyObject} = this.context
      const {featuresConfig:{competitions}} = config
      const availableAccounts = filter(accounts, (a) => includes(get(competitions,'accountSubTypes',[]), a.accountSubtype) &&
      includes(get(competitions,'accountTypesCategory',[]), accountTypes[a.__typename].category)
      && !a.isDemo && !a.isViewOnly  && !a.isArchived)

      const entryFee = get(find(rules, (r) => r.rule === 'minimumBalance'), 'values.balance')
      const active = moment(get(competition, 'endDate')).utc() > moment().utc()
      const hasCredit = account && get(find(availableAccounts, (acc) => acc.id ===account), 'credit')

      return (active) && <Grid container spacing={isMobile() ? 1 : 3} className={classes.container}>
        <Grid item xs={12} sm={5}>
          <TextField
            id="nickname"
            name="nickname"
            label={t(messages.nickname.i18nKey, messages.nickname.defaults)}
            fullWidth
            error={errors.nickname || errors.nicknameLength}
            value={nickname}
            onChange={(e) => handleChange('nickname', e.target.value)}
            disabled={!!client.nickname || !!competitionError}
          />
          {(nicknameExists || errors.nicknameLength) &&
          <FormHelperText className={classes.checkBoxError}><Trans {...messages[(errors.nicknameLength) ? 'maxLengthError' : 'nicknameExists']} values={{length: 15}}/></FormHelperText>}
        </Grid>
        <Grid item xs={12} sm={7} className={(isEmpty(availableAccounts)) ? classes.disabledDiv : ''}>
          <AccountSearchSelect
            accounts={availableAccounts}
            label={t(messages.selectCompetitionAccount.i18nKey, messages.selectCompetitionAccount.defaults)}
            handleAccountChange={(p, v) => handleChangeAccount(p, v, availableAccounts, entryFee)}
            value={account}
            name="account"
            locale={locale}
            errors={errors}
            hiddenBalance
            disabled={isEmpty(availableAccounts)}
            removeRequired={true}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel
              classes={{label:classes.checkboxLabel}}
              control={
                <Checkbox
                  required
                  checked={!!acceptTerms}
                  onChange={(e) => handleChange('acceptTerms', e.target.checked? 'accept' : '')}
                  color="primary"
                  value={acceptTerms}
                  className={errors.acceptTerms ? classes.checkBoxError : ''}
                />
              }
              label={<Trans {...messages.signUpCompetition} values={{company: companyObject['brandLabel']}} components={[
                <a href={competition.termsLink} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>]} />}
            />
          </FormControl>
        </Grid>
        {!!competitionError &&  <Grid item xs={12} className={classes.textCenter}>
          <Typography variant="caption" className={classes.checkBoxError}>
            <Trans {...messages[`${competitionError}Error`]} components={competitionErrorValues}/>
          </Typography>
        </Grid>}
        {!!hasCredit && <Grid item xs={12} className={classes.textCenter}>
          <Typography variant="caption" className={classes.checkBoxError}>
            <Trans {...messages.bonusCompetitionError} components={[<br key={'br'} />]}/>
          </Typography>
        </Grid>}
        <Grid item xs={12} className={classes.textCenter}>
          <LoadingButton
            id='competitionSign'
            onClick={() => competitionSign()}
            disabled={loading || !!competitionError || !!hasCredit}
            status={status}
            noMargin={true}
            hideProgressBar={!!competitionError || !!hasCredit}
          >
            <Trans {...messages.startCompetition} />
          </LoadingButton>
          {participatedCompetition &&
          <FormHelperText className={classnames(classes.checkBoxError, classes.textCenter)}><Trans {...messages.participatedCompetition} /></FormHelperText>}
          {cannotParticipateCompetition &&
          <FormHelperText className={classnames(classes.checkBoxError, classes.textCenter)}><Trans {...messages.cannotParticipateCompetition} /></FormHelperText>}
        </Grid>
      </Grid>
    }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(CompetitionForm)
