import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import classNames from 'classnames'
import {map, slice, get, isEmpty, flowRight as compose} from 'lodash'
import Images from '../../Common/Images'
import {isMobile} from '../../../common/utils/browser'
import {getLeaderboardDetails} from '../../../common/utils/requests'
import NotificationBar from '../../Common/NotificationBar'
import Grid from '@material-ui/core/Grid'
import {Loading} from '../../Common/Loading'
import moment from 'moment'
import Schedule from '@material-ui/icons/Schedule'
import CustomTooltip from '../../Common/CustomTooltip'

const styles = theme => ({
  rowButton: {
    borderBottom: 'none'
  },
  leadershipIndex :{
    paddingLeft: 12
  },
  textCenter: {
    textAlign: 'center' as const
  },
  boldText: {
    fontWeight: 400
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  place1: {
    color: theme.palette.gold.color,
  },
  place2: {
    color: theme.palette.grey.color,
  },
  place3: {
    color: theme.palette.yellow.color,
  },
  rootTable: {
    '& td, & th':  {
      padding: '5px 25px'
    }
  },
  emptyMessage :{
    marginTop: 16
  },
  textRight: {
    textAlign: 'right' as const
  },
  statusIcon: {
    'vertical-align':'middle',
    'font-size':'24px',
    opacity: 0.4,
    [theme.breakpoints.down('xs')]: {
      'font-size':'22px',
    }
  },
  tooltipText:{
    color: '#ffffff',
  },
  qualifiedParticipant: {
    opacity: 0.7,
  },
})

class Leaderboard extends Component<any,any> {

  constructor(props) {
    super(props)
    this.state = {
      tableRows: 5,
      participants:[],
      leaderboardLoading: false,
      open: [],
      clickedEvent: false,
    }
  }

  componentDidMount() {
    const {competition} = this.props
    this.setState({leaderboardLoading: true})
    getLeaderboardDetails(competition).then((res) => {
      if (get(res,'error')) {
        this.setState({leaderboardError: true, leaderboardLoading: false})
      } else {
        this.setState({participants: res, leaderboardLoading: false})
      }
    })
  }

  render() {
    const {classes, t} = this.props
    const {tableRows, participants, leaderboardLoading} = this.state
    const rows = 5

    return !isEmpty(participants) ?
      <Grid container className={classes.emptyMessage} justifyContent="flex-end">
        <Grid item xs={12}>
          {participants[0].updatedAt && <Typography variant="caption" className={classes.textRight}> <Trans {...messages.lastUpdatedAt} values={{updatedAt:moment(participants[0].updatedAt).format('DD/MM/YYYY HH:mm')}}/> </Typography>}
          <Table classes={{root: isMobile() ? classes.rootTable : ''}}>
            <TableHead>
              <TableRow>
                <TableCell>{t(messages.rank.i18nKey, messages.rank.defaults)}</TableCell>
                <TableCell>{t(messages.nickname.i18nKey, messages.nickname.defaults)}</TableCell>
                <TableCell>{t(messages.roi.i18nKey, messages.roi.defaults)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(slice(participants, 0, tableRows), (value, index) => {
                // eslint-disable-next-line no-self-compare
                const qualifiedParticipant = get(value, 'rank') ?? 0 > 0
                return <TableRow key={index} className={!qualifiedParticipant ? classes.qualifiedParticipant : ''}>
                  {/* eslint-disable-next-line no-self-compare */}
                  <TableCell>{get(value, 'rank') ?? 0 > 0 ? Images[`place${index + 1}-leadership.png`] ?
                    <img src={Images[`place${index + 1}-leadership.png`]} alt={`${index}`}/>
                    : <span className={classes.leadershipIndex}>{index + 1}</span> :
                    <CustomTooltip
                      title={<Typography variant='caption' className={classes.tooltipText}>{t(messages.pendingRulesCompetitions.i18nKey, messages.pendingRulesCompetitions.defaults)}</Typography>}
                      placement={'top-start'}
                    >
                      <Schedule className={classNames({[classes.statusIcon]: true})}/>
                    </CustomTooltip>
                  }
                  </TableCell>
                  <TableCell>{get(value, 'nickname')}</TableCell>
                  <TableCell className={qualifiedParticipant ? classNames(classes[`place${index + 1}`], Images[`place${index + 1}-leadership.png`] && classes.boldText) : ''}>{get(value, 'roi')}%</TableCell>
                </TableRow>})}
              {tableRows < participants.length &&
              <TableRow>
                <TableCell className={classNames(classes.textCenter, classes.rowButton)} colSpan={3}>
                  <Typography variant="caption" className={classes.link} onClick={() => this.setState({tableRows: tableRows + rows})}><Trans {...messages.showMore} /></Typography>
                </TableCell>
              </TableRow>}
            </TableBody>
          </Table>
        </Grid>
      </Grid> :
      leaderboardLoading ?
        <Loading />
        : <Grid container className={classes.emptyMessage}>
          <Grid item>
            <NotificationBar status="info" >
              <Trans {...messages.emptyLeaderboard} />
            </NotificationBar>
          </Grid>
        </Grid>

  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(Leaderboard)
