import {StyleOverrides} from '../types'

export const tauroMarketsStyleOverrides : StyleOverrides = {
  Login: (theme, style) => {
    if (theme.direction === 'rtl') {
      style.wrapper.maxWidth = 485
    }

    return style
  },
  Header: (theme, style) => {
    style.logo.maxHeight = 120

    return style
  },
}
