import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import {get, flowRight as compose, includes, find} from 'lodash'
import classnames from 'classnames'
import {graphql} from 'react-apollo'
import {Trans, withNamespaces} from 'react-i18next'
import Badge from '@material-ui/core/Badge'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import {withStyles} from '@material-ui/core/styles'
import {kycStatuses, referralStatuses} from '@bdswiss/common-enums'
import Typography from '@material-ui/core/Typography'
import LydianListItemLink from './ListItemLink'
import LydianHeader from './Header'
import {config} from '../../../config'
import messages from '../../../assets/messages'
import {ReactComponent as AccountsIcon} from '../../../assets/images/icon_accounts_dashboard.svg'
import {ReactComponent as TransactionsIcon} from '../../../assets/images/icon_transaction_dashboard.svg'
import {ReactComponent as NotificationsIcon} from '../../../assets/images/icon_notification_dashboard.svg'
import {ReactComponent as SupportIcon} from '../../../assets/images/icon_support_dashboard.svg'
import {ReactComponent as LogoutIcon} from '../../../assets/images/icon_logout_dashboard.svg'
// import AcademyComingSoon from '../../../assets/images/lydian/lydian-trading-academy-coming_soon.png'
// import TradingAcademy from '../../../assets/images/lydian/lydian-trading-academy.png'
import TradingAcademyIcon from '../../../assets/images/lydian/trading-academy-logo.svg'
import {isMobile} from '../../../common/utils/browser'
import {CLIENT_DATA_QUERY, PROFILE_SETTINGS_QUERY, REFERRAL_DETAILS_QUERY} from '../../../graphql/queries'
import AppContext from '../../Common/contexts/AppContext'
import PageBody from '../../Common/PageBody'
import {getPendingVerificationsCount} from '../../../common/utils/general'
import VerifiedIcon from '@material-ui/icons/CheckCircleOutlined'
import Missing from '@material-ui/icons/ReportProblemOutlined'
import {hasWalletProductAccount, hasOnlyWalletProductAccount} from '../../../common/utils/accounts'
import Button from '@material-ui/core/Button'
import NonDepositorModal from './NonDepositorModal'
import EnableAcademyAccessModal from './EnableAcademyAccessModal'
import {InnerAppContext} from '../../../common/types'

const styles = (theme) => ({
  invisible: {
    display: 'none'
  },
  badge: {
    top: theme.direction === 'rtl' ? 0 : 15,
    right: theme.direction === 'rtl' ? 0 : -30,
    backgroundColor: theme.palette.red.color,
    color: theme.palette.red.textColor,
    position: theme.direction === 'rtl' ? 'relative' as const : 'absolute' as const
  },
  nameGrid: {
    padding: '10px 24px 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 16px 30px',
    },
  },
  nameText:{
    lineHeight: '1',
    fontWeight: 500,
  },
  statusIcon: {
    'vertical-align':'text-top',
    'font-size':'15px',
    'margin-right':'4px',
  },
  pendingIcon: {
    'color': theme.palette.yellow.color
  },
  doneIcon: {
    'color': theme.palette.green.color
  },
  verificationLink:{
    'color': theme.palette.yellow.color
  },
  buttonClass: {
    marginTop: 20,
    padding: '12px 24px',
    boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: theme.palette.extralightgrey.color
    }
  },
  tradingAcademyBox: {
    border: '1px solid #d5d6d7',
    borderRadius: '5px',
    position: 'relative' as const
  },
  tradingAcademyLogo: {
    position: 'absolute' as const,
    top: 7,
    left: 7,
    zIndex: 1,
  },
  tradingAcademyBoxTop: {
    height: 33,
    lineHeight: '33 px',
    paddingLeft: 57,
    fontWeight: 400,
    paddingRight: 15,
  },
  tradingAcademyBoxBottom: {
    lineHeight: '24 px',
    height: 24,
    backgroundColor: '#03a770',
    paddingLeft: 57,
    textAlign: 'left' as const,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    color: 'white',
  },
  darkButtonClass: {
    backgroundColor: theme.palette.extralightgrey.color,
    '&:hover': {
      backgroundColor: theme.palette.extralightgreyBackground.color
    }
  },
  listItem: {
    display: 'flex',
    [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: 24
  },
  listItemIcon: {
    width: 20,
    height: 20,
    marginRight: 15,
  },
  academyButton: {
    padding: 0,
  },
  academyImage: {
    maxWidth: 200,
  }
})

const accountVerification = (kycStatus, classes) => {
  let verificationIcon
  if (kycStatus === kycStatuses.approved.value) {
    verificationIcon = <React.Fragment>
      <Typography variant="caption" className={classes.doneIcon}>
        <VerifiedIcon className={classnames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
        <Trans {...messages.verified} />
      </Typography>
    </React.Fragment>
  } else {
    verificationIcon = <React.Fragment>
      <Typography variant="caption" className={classes.pendingIcon}>
        <Missing className={classnames({[classes.statusIcon]: true, [classes.pendingIcon]: true})}/>
        <Link to={'/accounts/verification'} className={classes.verificationLink}><Trans {...messages.unVerified} /> </Link>
      </Typography>
    </React.Fragment>
  }
  return verificationIcon
}
const pendingStatuses = [referralStatuses.activated.value, referralStatuses.deposited.value, referralStatuses.verified.value, referralStatuses.pendingActivation.value]

class LydianSidebar extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      showContactSupportModal: false,
      showNonDepositorModal: false,
    }
  }

  handleAcademyClick(isEnabled) {
    const {tradingAcademyLink} = config
    const {viewer: {nonDepositorUser, hasTradingAcademyAccess}} = this.props

    if (!isEnabled || !tradingAcademyLink) return

    if (nonDepositorUser) {
      this.setState({showNonDepositorModal: true})
      return
    }

    if (!hasTradingAcademyAccess && !nonDepositorUser) {
      this.setState({showContactSupportModal: true})
      return
    }

    window.open(tradingAcademyLink, '_blank')
  }

  handleSubmitModal() {
    this.props.history.push('/support')
    this.handleCloseModal()
  }

  handleCloseModal() {
    this.setState({
      showContactSupportModal: false,
      showNonDepositorModal: false,
    })
  }

  render() {
    const {classes, className, loading, viewer: {firstName, lastName, id, pendingNoticesCount = 0},
      viewer, appropTests, globalQuestionnaire, documents, referrals, linkedPartnerClientId, referAndEarn} = this.props
    const {showContactSupportModal, showNonDepositorModal} = this.state
    const {kycStatus} = viewer
    const {clientType, blockedDeposit, accounts} = this.context
    const {featuresConfig:{vps}, isLydianTradingAcademyLaunched} = config
    const isLydianTradingAcademyEnabled = isLydianTradingAcademyLaunched === 'true'
    if (loading) {
      return null
    }
    const pendingReferral = find(referrals, (r) => includes(pendingStatuses, r.status))
    const isIbOrAffiliate = hasWalletProductAccount(accounts) || linkedPartnerClientId
    const showReferral = referAndEarn && (!isIbOrAffiliate || (isIbOrAffiliate && pendingReferral))

    const pendingVerificationsCount = getPendingVerificationsCount(viewer, appropTests, globalQuestionnaire,
      documents, clientType, blockedDeposit)

    const referralAwardNotification = this.props.activatedReferralAwards && !get(this.props.activatedReferralAwards,'claimedAt')

    const badgeClasses = [classes.badge]
    const settingsBadgeClasses = [classes.badge]
    const totalNotificationsCount = referralAwardNotification ? pendingNoticesCount+1 : pendingNoticesCount
    totalNotificationsCount < 1 && badgeClasses.push(classes.invisible)
    pendingVerificationsCount < 1 && settingsBadgeClasses.push(classes.invisible)
    const onlyWalletAccount = hasOnlyWalletProductAccount(accounts)
    // const academyImageLink = isLydianTradingAcademyEnabled ? TradingAcademy : AcademyComingSoon

    return (
      <div className={className}>
        <Grid container className={classes.nameGrid} direction="column">
          <Typography variant="body2" className={classes.nameText}>{[firstName, lastName].join(' ')}</Typography>
          <Typography variant="caption">{`${config.name} ID: ${id || ''}`}</Typography>
          {accountVerification(kycStatus, classes)}
        </Grid>

        <Button
          variant="text"
          onClick={() => this.handleAcademyClick(isLydianTradingAcademyEnabled)}
          className={classes.academyButton}
        >
          <div className={classes.tradingAcademyBox}>
            <img
              alt={'Lydian Trading Academy'}
              src={TradingAcademyIcon}
              className={classes.tradingAcademyLogo}
            />
            <div className={classes.tradingAcademyBoxTop}>Trading Academy</div>
            <div className={classes.tradingAcademyBoxBottom}>Coming Soon</div>
          </div>
        </Button>

        <List component="nav">
          <LydianListItemLink to={'/accounts'} isIndex classesItem={classes.listItem}>
            <AccountsIcon className={classes.listItemIcon} />
            <Trans {...messages.accounts} />
          </LydianListItemLink>
          <LydianListItemLink to={'/transactions'} classesItem={classes.listItem}>
            <TransactionsIcon className={classes.listItemIcon} />
            <Trans {...messages.payments} />
          </LydianListItemLink>
          {/* {(!onlyWalletAccount || (tradingCentralEnabled && hasIbAccount)) && <LydianListItemLink to={'/tools'} classesItem={classes.listItem}>
            <Trans {...messages.tools} />
          </LydianListItemLink>} */}
          <LydianListItemLink to={'/notifications'} classesItem={classes.listItem}>
            <NotificationsIcon className={classes.listItemIcon} />
            <Badge badgeContent={totalNotificationsCount} classes={{badge: classnames(badgeClasses)}} >
              <div>
                <Trans {...messages.notifications} />
              </div>
            </Badge>
          </LydianListItemLink>
          {vps && vps.enabled && !onlyWalletAccount && <LydianListItemLink to={'/vps'} classesItem={classes.listItem}>
            <Trans {...messages.vps} />
          </LydianListItemLink>}
          {showReferral && <LydianListItemLink to={'/referAndEarn'} classesItem={classes.listItem}>
            <Trans {...messages.referAndEarn} />
          </LydianListItemLink>}
          <LydianListItemLink to={'/support'} classesItem={classes.listItem}>
            <SupportIcon className={classes.listItemIcon} />
            <Trans {...messages.support} />
          </LydianListItemLink>
          <LydianListItemLink to={'/logout'} onClick={() => this.context.logout()} classesItem={classes.listItem}>
            <LogoutIcon className={classes.listItemIcon} />
            <Trans {...messages.logout} />
          </LydianListItemLink>
        </List>

        <NonDepositorModal
          open={showNonDepositorModal}
          onClose={this.handleCloseModal.bind(this)}
        />

        <EnableAcademyAccessModal
          open={showContactSupportModal}
          onSubmit={this.handleSubmitModal.bind(this)}
          onClose={this.handleCloseModal.bind(this)}
        />
      </div>
    )
  }
}

class LydianMobileSidebar extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      showContactSupportModal: false,
      showNonDepositorModal: false,
    }
  }

  handleAcademyClick(isEnabled) {
    const {tradingAcademyLink} = config
    const {viewer: {nonDepositorUser, hasTradingAcademyAccess}} = this.props

    if (!isEnabled || !tradingAcademyLink) return

    if (nonDepositorUser) {
      this.setState({showNonDepositorModal: true})
      return
    }

    if (!hasTradingAcademyAccess && !nonDepositorUser) {
      this.setState({showContactSupportModal: true})
      return
    }

    window.open(tradingAcademyLink, '_blank')
  }

  handleSubmitModal() {
    this.props.history.push('/support')
    this.handleCloseModal()
  }

  handleCloseModal() {
    this.setState({
      showContactSupportModal: false,
      showNonDepositorModal: false,
    })
  }

  render() {
    const {classes, className, loading, viewer: {firstName, lastName, id, pendingNoticesCount = 0},
      viewer, appropTests, globalQuestionnaire,
      documents} = this.props
    const {showContactSupportModal, showNonDepositorModal} = this.state
    const {kycStatus} = viewer
    const {clientType} = this.context
    if (loading) {
      return null
    }

    const pendingVerificationsCount = getPendingVerificationsCount(viewer, appropTests, globalQuestionnaire, documents, clientType)

    const settingsBadgeClasses = [classes.badge]
    pendingVerificationsCount < 1 && settingsBadgeClasses.push(classes.invisible)

    const badgeClasses = [classes.badge]
    const referralAwardNotification = this.props.activatedReferralAwards && !get(this.props.activatedReferralAwards,'claimedAt')
    const totalNotificationsCount = referralAwardNotification ? pendingNoticesCount+1 : pendingNoticesCount
    totalNotificationsCount < 1 && badgeClasses.push(classes.invisible)
    const {isLydianTradingAcademyLaunched} = config
    const isLydianTradingAcademyEnabled = isLydianTradingAcademyLaunched === 'true'
    // const academyImageLink = isLydianTradingAcademyEnabled ? TradingAcademy : AcademyComingSoon

    return (
      <PageBody className={className}>
        <LydianHeader />
        <Grid container className={classes.nameGrid} direction="column">
          <Typography variant="body2" className={classes.nameText}>{[firstName, lastName].join(' ')}</Typography>
          <Typography variant="caption">{`${config.name} ID: ${id || ''}`}</Typography>
          {accountVerification(kycStatus, classes)}
        </Grid>

        <Button
          variant="text"
          onClick={() => this.handleAcademyClick(isLydianTradingAcademyEnabled)}
          className={classes.academyButton}
        >
          <div className={classes.tradingAcademyBox}>
            <img
              alt={'Lydian Trading Academy'}
              src={TradingAcademyIcon}
              className={classes.tradingAcademyLogo}
            />
            <div className={classes.tradingAcademyBoxTop}>Trading Academy</div>
            <div className={classes.tradingAcademyBoxBottom}>Coming Soon</div>
          </div>
        </Button>

        <List component="nav">
          <LydianListItemLink to={'/notifications'}>
            <Badge badgeContent={totalNotificationsCount} classes={{badge: classnames(badgeClasses)}}>
              <div>
                <Trans {...messages.notifications} />
              </div>
            </Badge>
          </LydianListItemLink>
          <LydianListItemLink to={'/logout'} onClick={() =>  this.context.logout()}>
            <Trans {...messages.logout} />
          </LydianListItemLink>
        </List>


        <NonDepositorModal
          open={showNonDepositorModal}
          onClose={this.handleCloseModal.bind(this)}
        />

        <EnableAcademyAccessModal
          open={showContactSupportModal}
          onSubmit={this.handleSubmitModal.bind(this)}
          onClose={this.handleCloseModal.bind(this)}
        />
      </PageBody>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  withRouter,
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => ({
      error:props.data?.error,
      loading:props.data?.loading,
      viewer: get(props.data, 'viewer')
    })
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => {
      const viewer = get(props.data, 'viewer')
      const globalQuestionnaire = get(viewer, 'globalQuestionnaire')
      const appropTests = get(viewer, 'appropTests')
      const documents = get(viewer, 'documents')

      return {
        error:props.data?.error,
        loading:props.data?.loading,
        globalQuestionnaire,
        appropTests,
        documents,
      }
    }}),
  graphql(REFERRAL_DETAILS_QUERY, {
    props: (props) => ({
      referralError: props.data?.error,
      referralLoading: props.data?.loading,
      referrals: get(props.data, 'viewer.referralDetails.referrals'),
      activatedReferralAwards: get(props.data, 'viewer.referralDetails.activatedReferralAwards'),
      linkedPartnerClientId:  get(props.data, 'viewer.linkedPartnerClientId'),
      referAndEarn:  get(props.data, 'viewer.referAndEarn'),
    })
  }),
)(isMobile() ? LydianMobileSidebar : LydianSidebar)
