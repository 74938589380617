import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {get, isEmpty, some, flowRight as compose} from 'lodash'
import {withStyles} from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import messages from '../../../assets/messages'
import Grid from '@material-ui/core/Grid'
import {
  CHANGE_PASSWORD_MUTATION,
  CLOSE_MEMBER_ACCOUNT_MUTATION,
} from '../../../graphql/mutations'
import {graphql} from 'react-apollo'
import LoadingButton from '../../Common/LoadingButton'
import NotificationBar from '../../Common/NotificationBar'
import {checkPassword} from '../../../common/utils/general'
import {Password} from '../../Common/Password'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {red} from '@material-ui/core/colors'
import {AlertDialog} from '../../Common/Dialog'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries.js'
import {logoutRequest} from '../../../common/utils/requests'
import {removeSessInfoFromLocalStorage} from '../../../common/utils/index.js'
import {closedTradingStatusReasons, kycStatuses, tradingStatuses} from '@bdswiss/common-enums'


const styles = theme => ({
  formControl:{
    display: 'block',
    margin: theme.spacing(1),
    width: '100%'
  },
  error:{
    color: theme.palette.error.main
  },
  errorMessage:{
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  successDiv:{
    backgroundColor:  theme.palette.lightgreen.color,
    padding: '15px 10px'
  },
  successMessage:{
    color:  theme.palette.lightgreen.textColor
  },
  marginTop:{
    marginTop: '-15px'
  },
  closeMemberAccountDisclaimer:{
    margin: '10px 0px',
    fontStyle: 'italic'
  },
  closeMemberAccountPopupDisclaimer:{
    color: 'red',
    fontStyle: 'italic'
  },
  errorButton: {
    color: red[500],
    borderColor: red[500],
    '&:hover': {
      borderColor: red[700],
      backgroundColor: red[100],
    },
  },
})
export class ProfileSettings extends Component {
  constructor(props) {
    super(props)
    this.textInput = React.createRef()
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmationPassword: '',
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmationPassword: false,
      messageErrorCurrentPassword: false,
      messageErrorNewPassword: false,
      messageErrorConfirmPassword: false,
      showCloseMemberAccountPopup: false,
      submitMessageError:'',
      loading: false,
      status: {},
      errors: {}
    }
  }

  handleChange(prop,event) {
    const {errors} = this.state
    this.setState({
      [prop]: event.target.value,
      errors: {
        ...errors,
        [prop]: !event.target.value,
      }},this.checkForm)
  }
  handleClickShowPassword(prop) {
    this.setState(state => ({[prop]: !state[prop]}))
  }

  handleKeyDown(prop,event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      const el = document.getElementById(prop)
      if (el) {
        el.focus()
      }
    }
  }

  checkForm() {
    const {currentPassword, newPassword, confirmationPassword} = this.state
    this.setState({submitMessageError: '', status: {}})
    if (currentPassword && newPassword && confirmationPassword && (newPassword !== confirmationPassword))
      this.setState({messageErrorConfirmPassword: <Trans {...messages.passwordsNotSame} />})
    else
      this.setState({messageErrorConfirmPassword: ''})
    if (newPassword) {
      const passwordError = checkPassword(newPassword)
      if (isEmpty(passwordError)) {
        this.setState({messageErrorNewPassword: ''})
      } else {
        this.setState({messageErrorNewPassword: <Trans {...messages[passwordError]} />})
      }
    }
  }

  changePassword = () => {
    const {currentPassword, newPassword, confirmationPassword, messageErrorConfirmPassword, messageErrorNewPassword,
      messageErrorCurrentPassword} = this.state
    const errors ={}
    if (isEmpty(currentPassword))
      errors.currentPassword = true
    if (isEmpty(confirmationPassword))
      errors.confirmationPassword = true
    if (isEmpty(newPassword))
      errors.newPassword = true
    if (some(errors)) {
      this.setState({errors})
      return
    }

    const {t} = this.props
    if (currentPassword && confirmationPassword && newPassword && !messageErrorConfirmPassword && !messageErrorNewPassword && !messageErrorCurrentPassword) {
      this.setState({status: {}, loading: true})
      this.props.changePasswordMutation({
        variables: {currentPassword, newPassword}
      }).then((succ) => {
        this.setState({loading: false,status: {changePassword: 'success'},submitMessageError: ''})
      }).catch((err) => {
        if (err.networkError) {
          this.setState({loading: false,status: {changePassword: 'failure'},
            submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {
          this.setState({loading: false,status: {changePassword: 'failure'},
            submitMessageError: get( err, 'graphQLErrors[0].message') || err.message,
          })
        }

      })
    }
  }

  closeAccount = () => {
    const {closeMemberAccount, clientId} = this.props

    closeMemberAccount({variables: {
      clientId,
      tradingStatus: tradingStatuses.closed.key,
      reason: 'Client closed his own account.',
      rejectionReasonCode: closedTradingStatusReasons.clientRequest.key,
    }})
      .then((res) => {
        this.setState({status: {closeMemberAccount: 'success'}})

        setTimeout(() => {
          logoutRequest()
            .then(() => {
              removeSessInfoFromLocalStorage()
              window.location.href = '/login'
            })
        }, 500)
      })
      .catch(err => {
        this.setState({errors: {closeAccountError: err.message.replace('GraphQL error: ', '')}})
      })
  }

  render() {
    const {classes, t, kycStatus} = this.props
    const {loading, currentPassword, showCurrentPassword, showNewPassword, newPassword, showConfirmationPassword, confirmationPassword,
      messageErrorCurrentPassword, messageErrorNewPassword, messageErrorConfirmPassword, submitMessageError, status} = this.state

    return (
      <Grid container>
        {status.changePassword === 'success' &&
          <Grid item xs={12} sm={10}>
            <NotificationBar
              onClose={() => this.setState({status: {changePassword: null}})}
              status='success'>
              <Trans {...messages.passwordChanged} />
            </NotificationBar>
          </Grid>
        }
        {status.closeMemberAccount === 'success' &&
          <Grid item xs={12} sm={10}>
            <NotificationBar status='success'><Trans {...messages.closeMemberAccountSuccess} /></NotificationBar>
          </Grid>
        }
        {this.state.errors.closeAccountError &&
          <Grid item xs={12} sm={10}>
            <NotificationBar
              onClose={() => this.setState({errors: {...this.state.errors, closeAccountError: null}})}
              status="error">
              {this.state.errors.closeAccountError}
            </NotificationBar>
          </Grid>
        }
        <Grid item xs={12} sm={10} lg={8}>
          <Typography variant="h3" color="secondary"> <Trans {...messages.changePassword} /> : </Typography>
          <Grid container className={classes.marginTop}>
            <Grid item xs={12}>
              <Password
                classes={classes}
                showPassword={showCurrentPassword}
                onChange={(e)=>this.handleChange('currentPassword',e)}
                onKeyDown={(e)=>this.handleKeyDown('newPassword',e)}
                error={this.state.errors.currentPassword}
                onClickShow={() => this.handleClickShowPassword('showCurrentPassword')}
                fullWidth
                errorText={messageErrorCurrentPassword}
                label={'currentPassword'}
                value={currentPassword}
                showErrorText={true}
              />
              <Password
                id={'newPassword'}
                classes={classes}
                showPassword={showNewPassword}
                onChange={(e)=>this.handleChange('newPassword',e)}
                onKeyDown={(e)=>this.handleKeyDown('confirmationPassword',e)}
                error={this.state.errors.newPassword}
                onClickShow={() => this.handleClickShowPassword('showNewPassword')}
                fullWidth
                errorText={messageErrorNewPassword}
                label={'newPassword'}
                value={newPassword}
                showErrorText={true}
              />
              <Password
                id={'confirmationPassword'}
                classes={classes}
                showPassword={showConfirmationPassword}
                onChange={(e)=>this.handleChange('confirmationPassword',e)}
                onKeyDown={(e)=>this.handleKeyDown('loadingButton',e)}
                error={this.state.errors.confirmationPassword}
                onClickShow={() => this.handleClickShowPassword('showConfirmationPassword')}
                fullWidth
                errorText={messageErrorConfirmPassword}
                label={'passwordConfirmation'}
                value={confirmationPassword}
                showErrorText={true}
              />
              <LoadingButton
                id='changePasswordBtn'
                onClick={this.changePassword}
                disabled={loading}
                status={status.changePassword}
              ><Trans {...messages.continue} />
              </LoadingButton>
              {status.changePassword === 'failure' && <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} lg={8}>
          <Typography variant="h3" color="secondary"> <Trans {...messages.closeMemberAccount} /> : </Typography>
          <Grid container className={classes.marginTop}>
            <Grid item xs={12}>
              <Typography variant="caption" color="secondary" className={classes.closeMemberAccountDisclaimer}>
                *<Trans {...messages.closeMemberAccountDisclaimer} />
              </Typography>
              {/* The first following version of MUI core that supports button color error is 5.0, which i cant quickly update, so i will just use custom style here for now */}
              {/* Maybe i can make this error button version in custom theme edits, so it can be used globally */}
              {/* Technically its possible to use red button in our LoadingButton component, but i would need to use status failure which is not the correct approach */}
              <Button
                id='closeMemberAccountBtn'
                onClick={() => this.setState({showCloseMemberAccountPopup: true})}
                variant={kycStatus === kycStatuses.approved.value ? 'contained' : 'outlined'}
                size="large"
                disabled={kycStatus === kycStatuses.approved.value}
                className={classes.errorButton}>
                <Trans {...messages.closeMemberAccountBtn} />
              </Button>
            </Grid>
            <AlertDialog
              open={this.state.showCloseMemberAccountPopup}
              title={t(messages.closeMemberAccount.i18nKey, messages.closeMemberAccount.defaults)}
              disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
              agreeText={t(messages.confirm.i18nKey, messages.confirm.defaults)}
              onAgree={() => {
                this.setState({showCloseMemberAccountPopup: false})
                this.closeAccount()
              }}
              onDisagree={() => this.setState({showCloseMemberAccountPopup: false})}
              onClose={() => this.setState({showCloseMemberAccountPopup: false})}
              buttonLoading={loading}
            >
              <Trans {...messages.closeMemberAccountPopupContent} />
              <br/>
              <br/>
              <Typography className={classes.closeMemberAccountPopupDisclaimer}>
                <Trans {...messages.closeMemberAccountPopupDisclaimer} />
              </Typography>
            </AlertDialog>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
export default compose (
  withStyles(styles),
  withNamespaces(),
  graphql(CHANGE_PASSWORD_MUTATION, {
    name: 'changePasswordMutation',
    options: {
      refetchQueries: ['viewer'],
    },
    //@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => ({
      error:props.data?.error,
      viewerLoading: props.data?.loading,
      clientId: get(props.data, 'viewer.id'),
      kycStatus: get(props.data, 'viewer.kycStatus'),
    })
  }),
  graphql(CLOSE_MEMBER_ACCOUNT_MUTATION, {
    name: 'closeMemberAccount'
  })
)(ProfileSettings)
