import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {get, some, isEmpty, find, flowRight as compose} from 'lodash'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {CLIENT_PROFILE_QUERY, CONFIG_QUERY} from '../../graphql/queries'
import {UPDATE_OWN_DETAILS_MUTATION} from '../../graphql/mutations'
import {graphql} from 'react-apollo'
import {Loading} from '../Common/Loading'
import {FullScreenDialog} from '../Common/Dialog'
import PageTitle from '../Common/PageTitle'
import messages from '../../assets/messages'
import {Typography} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import LoadingButton from '../Common/LoadingButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import {accountTypes, phoneVerificationTypes} from '@bdswiss/common-enums'
import Images from '../Common/Images'
import {validatePhone} from '../../common/utils/validations'
import CountriesSelect from '../Common/CountriesSelect'
import TextsmsOutlined from '@material-ui/icons/TextsmsOutlined'
import AppContext from '../Common/contexts/AppContext'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import {callerNumber} from '../../common/utils/variables'
import {getCountries} from '../../common/utils/requests'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  errorMessage:{
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  chip: {
    padding: 10,
    cursor: 'pointer'
  },
  messageIcon:{
    marginRight: 30,
    verticalAlign: 'top',
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    }
  },
  countriesMenu:{
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
    maxHeight: '50vh',
    width: '100%',
    marginLeft: 0,
    paddingLeft: 0,
    border: '1px solid #e8e8e8',
    listStyleType: 'none'
  },
  countryOptions:{
    padding: 10,
    borderTop: '1px solid #e8e8e8',
    cursor: 'pointer',
    fontSize: 16,
    textAlign: 'left' as const,
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0',
    }
  },
  searchInp:{
    padding: '5px 15px',
    [theme.breakpoints.down('sm')]: {
      padding: 5,
    }
  },
  prefixTextField:{
    cursor: 'pointer',
  },
  prefixCountry:{
    cursor: 'pointer',
    marginRight:0,
  },
  flatItem: {
    marginRight: 10,
  },
  successMessage:{
    color: theme.palette.green.color,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  fontBold:{
    fontWeight: 400
  },
  textCenter:{
    textAlign: 'center'as const,
  },
  textLeft:{
    textAlign: 'left'as const,
  },
  img:{
    width:170,
    height:150
  },
  button:{
    marginTop: -15
  },
  infoText:{
    fontSize: '0.75rem',
    color: theme.palette.grey.color
  }
})

class PhoneVerification extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      form:{
        prefix:'',
        phone: '',
      },
      errors: {},
      status: '',
      showPhone:false,
      phoneMessageError:'',
      loading: false,
      loadingPhone: false,
      statusPhone: '',
      showCountries: false,
      prefixFlag:'',
      showCountriesPrefix: false,
    }
  }

  componentDidMount() {
    const {companyObject, accounts} = this.context
    let walletAccount : any = find(accounts, (a) => get(accountTypes[a.__typename], 'walletProduct'))
    if (walletAccount) {
      walletAccount = find(accountTypes, (accountType) => accountType.key === get(walletAccount, '__typename'))
    }
    this.getCountries(walletAccount && walletAccount.value, companyObject.key)
  }

  handleChange (name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      }
    }))
  }

  handleChangePrefix(value) {
    this.setState<'showCountriesPrefix'|'prefixFlag'>({showCountriesPrefix:false,prefixFlag:value})
  }

  setStateOuter = (prop, value) => {
    //@ts-ignore
    this.setState({[prop]: value})
  }

  searchCountry(id,value) {
    const li = document.getElementById(id)?.getElementsByTagName('li')
    if (!li) {
      return
    }
    for (let i = 0; i < li.length; i++) {
      if (li[i].innerHTML.toUpperCase().indexOf(value) > -1) {
        li[i].style.display = ''
      } else {
        li[i].style.display = 'none'
      }
    }
  }

  changePhone() {
    const {form, form:{phone, prefix, verificationType}} = this.state
    const variables = {phone: prefix+phone, type: verificationType}
    const errors = {}

    for (const field of Object.keys(form)) {
      if (field === 'phone') {
        errors[field] = isEmpty(form[field]) || !validatePhone(form[field])
      }
      else {
        errors[field] = isEmpty(form[field])
      }
    }

    if (some(errors)) {
      this.setState<'errors'>({errors})
      return
    }

    this.setState<'loadingPhone'>({loadingPhone: true})
    this.props.updateDetails({variables})
      .then((succ) => {
        this.setState<'loadingPhone'|'statusPhone'|'disableBarPhone'>({loadingPhone: true,statusPhone: 'success',disableBarPhone:true})
        this.props.checkTime(variables)
        setTimeout(() => {
          this.props.handleChangeParent('showPhone',!this.props.showPhone)
        }, 2000)
      })
      .catch((err) => {
        this.setState({loadingPhone: false,statusPhone: 'failure',phoneMessageError: err.error?err.error:''})
      })
  }

  closeDialog() {
    this.context.logout()
  }

  async getCountries(product, company) {
    await getCountries(product, company)
      .then((res) => {
        this.setState<'allowedCountries'>({allowedCountries: res.countries})
      })
      .catch((err) => err)
  }


  render() {
    const {classes, loading, t, handleChangeParent, showPhone, submitTime, secondsToTime, disableSend, viewer:{phone : clientPhone}, configVariables} = this.props
    if (loading) return <Loading />
    const {form: {prefix, phone, verificationType}, loadingPhone, statusPhone, phoneMessageError, errors, disableBarPhone, prefixFlag, allowedCountries} = this.state
    const countryList = allowedCountries || []
    const phoneForce = isEmpty(clientPhone)
    const textToSpeechEnabled = get(configVariables, 'textToSpeechVerificationEnabled')

    return <FullScreenDialog
      desktopOnly
      open
      onClose={() => phoneForce ? this.closeDialog() : handleChangeParent('showPhone',!showPhone)}
    >
      <Grid container spacing={3} className={classes.textCenter} justifyContent="center">
        <PageTitle onBack={() =>  phoneForce ? this.closeDialog() : handleChangeParent('showPhone',!showPhone)}>
          <Trans {...messages.phoneConfirmation} />
        </PageTitle>
        <Grid item xs={12}>
          <img src={Images['phone_verification.png']} alt="phone" className={classes.img}/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Trans {...messages.improveAccountProtection} />
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}  justifyContent="center">
              <Grid item xs={5} sm={3} id="prefixGrid" className={classes.textLeft}>
                <CountriesSelect
                  countryList={countryList}
                  handleChangeField={this.handleChangePrefix.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  setStateOuter={this.setStateOuter.bind(this)}
                  errors={errors}
                  value={prefixFlag}
                  name="prefix"
                  label={t(messages.phoneCode.i18nKey, messages.phoneCode.defaults)}
                  phonePrefix
                />
              </Grid>
              <Grid item xs={7} sm={5} className={classes.phoneInp}>
                <TextField
                  fullWidth
                  required
                  id="phone"
                  name="phone"
                  label={t(messages.phone.i18nKey, messages.phone.defaults)}
                  error={this.state.errors.phone}
                  onChange={(e) => this.handleChange('phone', e.target.value.replace(/^0+/, ''))}
                  value={phone}
                  inputProps={{pattern: '[0-9]*', inputMode:'numeric'}}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={5}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} sm={6} id="sendsms">
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="verificationType"
                            value={phoneVerificationTypes.sms.value}
                            checked={verificationType === phoneVerificationTypes.sms.value || !verificationType}
                            onChange={(e) => this.handleChange('verificationType', e.target.value)}
                            color="primary"
                          />}
                        label={<Trans {...messages.resendSms} />}
                      />
                    </FormControl>
                  </Grid>
                  {textToSpeechEnabled && <Grid item xs={12} sm={6} id="call">
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="verificationType"
                            value={phoneVerificationTypes.textToSpeech.value}
                            checked={verificationType === phoneVerificationTypes.textToSpeech.value}
                            onChange={(e) => this.handleChange('verificationType', e.target.value)}
                            color="primary"
                          />}
                        label={<Trans {...messages.call} />}
                      />
                    </FormControl>
                  </Grid>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {verificationType === phoneVerificationTypes.textToSpeech.value && <Grid item xs={12}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={5} className={classes.infoText}>
                <Trans {...messages.infoCallText} values={{callerNumber}} />
              </Grid>
            </Grid>
          </Grid>}
          {prefix && phone &&
            <Grid item xs={12}>
              <Typography variant="body1">
                <Trans {...messages.codeSendTo} values={{phone:`${prefix} ${phone}`}}
                  components={[
                    <span className={classes.fontBold}>phone</span>]}/>
              </Typography>
            </Grid>}

          <Grid item xs={12}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={6}>
                <LoadingButton
                  id='loadingButtonPhone'
                  onClick={() => {
                    this.changePhone()
                  }}
                  disabled={loadingPhone || disableSend}
                  status={statusPhone}
                  hideProgressBar={disableBarPhone || disableSend}
                >
                  <TextsmsOutlined className={classes.messageIcon}/>
                  {phoneForce ? t(messages.addPhoneNumber.i18nKey, messages.addPhoneNumber.defaults) :
                    t(messages.changePhoneNumber.i18nKey, messages.changePhoneNumber.defaults)}{disableSend && `: ${secondsToTime(submitTime)}`}
                </LoadingButton>
                {statusPhone && <Grid item xs={12}>{(statusPhone==='failure')
                  ?<FormHelperText className={classes.errorMessage}>{phoneMessageError}</FormHelperText>
                  :<FormHelperText className={classes.successMessage}>{t(messages.phoneChangedCodeSent.i18nKey, messages.phoneChangedCodeSent.defaults)}</FormHelperText>}
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullScreenDialog>
  }
}

export default compose (
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_PROFILE_QUERY, {
    props: (props) => {
      const viewer = get(props.data, 'viewer')
      return {
        error: props.data?.error,
        loading: props.data?.loading,
        viewer:viewer,
      }
    }
  }),
  graphql(CONFIG_QUERY, {
    props: (props) => ({
      error: props.data?.error,
      configLoading:props.data?.loading,
      configVariables: get(props.data, 'config'),
    })
  }),
  graphql(UPDATE_OWN_DETAILS_MUTATION, {
    name: 'updateDetails',
    options: {
      refetchQueries: [{query: CLIENT_PROFILE_QUERY}],
    },
    //@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
)(PhoneVerification)
