import React from 'react'
import BackIcon from '@material-ui/icons/ArrowBack'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'
import {flowRight as compose} from 'lodash'
import {Fab} from '@material-ui/core'

const styles = theme => ({
  sidebarIcon:{
    marginTop: 35,
    background: theme.palette.extralightgreyBackground.color,
    color: theme.palette.black.color,
    boxShadow:theme.boxShadowStyle,
    [theme.breakpoints.down('xs')]: {
      float: theme.direction === 'lrt' ? 'right' : 'left',
      marginRight: '1rem'
    },[theme.breakpoints.up('sm')]: {
      float: theme.direction === 'lrt' ? 'left' : 'right',
      marginRight:'12%'
    },
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : 'none',
  }
})

class SidebarDialog extends React.Component {
  render() {
    const {classes, onClick, className} = this.props
    if (!onClick) return null

    return (
      <div className={className}>
        <Fab className={classes.sidebarIcon} onClick={onClick}>
          <BackIcon />
        </Fab>
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth())(SidebarDialog)
