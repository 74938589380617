import React from 'react'
import _ from 'lodash'
import {appropTestStatuses, kycStatuses} from '@bdswiss/common-enums'
import messages from '../../../assets/messages'
import {Trans} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import {config} from '../../../config'
import ElectronicVerification from './ElectronicVerification'
import CheckCircle from '@material-ui/icons/CheckCircle'
import {getMissingVerifications} from '../../../common/utils/general'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import {isMobile} from '../../../common/utils/browser'
import {getItem} from '../../../common/utils/localStorage'
import {isAffiliateClient, isIBClient} from '../../../common/utils/accounts'

const styles = theme => ({
  eVerified: {
    fontSize: 13,
    background: theme.palette.green.color,
    color: '#FFFFFF',
    borderRadius: '5px',
    padding: '4px 5px',
    fontWeight: 400
  },
  greenIcon: {
    fontSize: 15,
    verticalAlign: 'sub',
    marginRight: 5
  },
  textLink: {
    textDecoration: 'underline',
    'color': `${theme.palette.secondary.main}`,
  },
  footNote: {
    padding: 10,
  },
})

/*KYC Status Row - Pending Messages*/
const KycStatusPendingItem = withStyles(styles, {withTheme: true})((props) => {
  const {classes, disabledVerification, showVerifyButton, eVerificationDeclined, extraFields, clientCountry} = props
  return (<React.Fragment>
    {eVerificationDeclined && <Typography variant="body1">
      {<Trans {...messages.kycAutoRejectedExplanation} />}
    </Typography>}
    {showVerifyButton && <ElectronicVerification disabled={disabledVerification} extraFields={extraFields} clientCountry={clientCountry} />}
  </React.Fragment>)
})

/*KYC Status Approved Footer Note*/
export const VerifiedAccountFootNote = withStyles(styles, {withTheme: true})((props) => {
  const {weblinks: {termsAndConditions}} = config
  const {classes} = props
  const locale = getItem('locale') || 'en'
  return (
    <Grid item xs={12} sm={12} lg={12} className={isMobile() ? classes.footNote : ''}>
      <Typography variant='caption'>
        <Trans {...messages.documentsVerifiedFootNote}
          components={[
            <a href={termsAndConditions.replace('{lang}', locale)} className={classes.textLink}
              target="_blank" rel="noopener noreferrer">
              Terms and Conditions</a>,
          ]} />
      </Typography>
    </Grid>
  )
})


/*KYC Status Row - Approved Messages*/
const KycStatusApprovedItem = withStyles(styles, {withTheme: true})((props) => {
  const {classes, eVerified} = props
  return (
    <React.Fragment>
      <Typography variant="body1">
        {eVerified && <span className={classes.eVerified}><CheckCircle className={classes.greenIcon} /><Trans {...messages.eVerified} /></span>} {<Trans {...messages.kycStatusApprovedDescription} />}
      </Typography>
      <VerifiedAccountFootNote />
    </React.Fragment>
  )
})




/*Check if E-Verification is valid*/
export const eligibleEVerification = (clientData) => {
  const {gdcSuppportedCountries} = config
  const clientCountry = _.get(clientData, 'address.country', '')
  return clientData.eIdVerificationResults.length === 0 &&
    _.includes(gdcSuppportedCountries, clientCountry) &&
    clientData.kycStatus !== kycStatuses.rejected.key &&
    !isAffiliateClient(clientData.clientType) && !isIBClient(clientData.clientType)
}

/*Verification Data for all available verification fields*/
export const getVerificationRow = (clientData, aptest, globalQuestionnaire, verificationFields) => {
  const missingVerifications = getMissingVerifications(clientData, aptest, globalQuestionnaire, '', verificationFields)
  const verifications = []
  let verificationItem, profileVerification
  const {gdcSuppportedCountriesExtraFields} = config

  const clientCountry = _.get(clientData, 'address.country', '')
  const findFields = gdcSuppportedCountriesExtraFields && _.keys(_.pickBy(gdcSuppportedCountriesExtraFields, (o) => _.indexOf(o.countries, clientCountry) !== -1))

  const extraFields = !_.isEmpty(findFields) ? findFields : false
  const canProcessAppropTest = _.get(clientData, 'canProcessAppropTest')

  _.map(verificationFields, (field) => {
    if (field === 'profile') {
      if (_.includes(missingVerifications, field)) {
        profileVerification = {
          key: 'profile',
          status: 'missing',
          title: <Trans {...messages.profileMissing} />,
          link: '/settings/profile/personal-profile/details'
        }
      } else {
        profileVerification = {
          key: 'profile',
          status: 'done',
          title: <Trans {...messages.profileSubmitted} />,
        }
      }
      verifications.push(profileVerification)
    }

    if (field === 'economicProfile') {
      if (_.includes(missingVerifications, field)) {
        verificationItem = {
          key: 'economicProfile',
          status: 'missing',
          title: <Trans {...messages.economicProfileMissing} />,
          link: '/settings/profile/personal-profile/economic'
        }
      } else {
        verificationItem = {
          key: 'economicProfile',
          status: 'done',
          title: <Trans {...messages.economicProfileSubmitted} />,
        }
      }
      verifications.push(verificationItem)
    }

    if (field === 'appropriatenessTest') {
      if (_.includes(missingVerifications, field)) {
        if (aptest) {
          verificationItem = (aptest.status === appropTestStatuses.rejected.value)
            ? {
              key: 'appropriatenessTest',
              status: 'missing',
              title: <Trans {...messages.appropriatenessTestRejected} />,
              link: canProcessAppropTest ? '/settings/profile/appropriateness-test' : ''
            }
            : {
              key: 'appropriatenessTest',
              status: 'missing',
              title: <Trans {...messages.appropriatenessTestMissing} />,
              link: '/settings/profile/appropriateness-test'
            }
        } else {
          verificationItem = {
            key: 'appropriatenessTest',
            status: 'missing',
            title: <Trans {...messages.appropriatenessTestMissing} />,
            link: '/settings/profile/appropriateness-test'
          }
        }
      } else {
        verificationItem = {
          key: 'appropriatenessTest',
          status: 'done',
          title: <Trans {...messages.appropriatenessTestSubmitted} />,
        }
      }
      verifications.push(verificationItem)
    }

    if (field === 'kycStatus') {
      const showVerifyButton = clientData.kycStatus === kycStatuses.pending.value && _.get(clientData, 'eIdVerificationApplicable')

      const disabledVerification = profileVerification.status !== 'done'

      const eVerified = clientData.eIdVerificationResults.length > 0 && clientData.eIdVerificationResults.find(({identityReliability}) => identityReliability === 'good')
      const eVerificationDeclined = clientData.eIdVerificationResults.length > 0 && clientData.eIdVerificationResults.find(({identityReliability}) => identityReliability !== 'good')

      if (_.includes(missingVerifications, field)) {
        verificationItem = (clientData.kycStatus === kycStatuses.rejected.key)
          ? {
            key: 'kycStatus',
            status: 'missing',
            title: <Trans {...messages.kycStatusRejected} />,
            link: '/settings/profile/documents',
          }
          : (clientData.kycStatus === kycStatuses.pendingAppropTest.key)
            ? {
              key: 'kycStatus',
              type: 'kyc',
              status: 'pending',
              title: <Trans {...messages.kycStatusPending} />,
              link: '/settings/profile/appropriateness-test',
              children: <Typography variant="body1">{<Trans {...messages.appropriatenessTestNotification} components={['']} />}</Typography>,
            }
            :
            {
              key: 'kycStatus',
              type: 'kyc',
              status: (showVerifyButton || eVerificationDeclined) ? 'pending' : 'missing',
              title: <Trans {...messages.kycStatusPending} />,
              link: disabledVerification ? '/settings/profile/personal-profile/details' : (window.location.pathname.indexOf('/settings/profile/documents') < 0 && window.location.pathname.indexOf('/register/step3') < 0) ? '/settings/profile/documents' : '',
              children: (showVerifyButton || eVerificationDeclined)
                && <KycStatusPendingItem disabledVerification={disabledVerification} eVerificationDeclined={eVerificationDeclined} showVerifyButton={showVerifyButton} extraFields={extraFields} clientCountry={clientCountry} />,
            }
      } else {
        verificationItem = {
          key: 'kycStatus',
          type: 'kyc',
          status: 'done',
          title: <Trans {...messages.kycStatusApproved} />,
          children: <KycStatusApprovedItem eVerified={eVerified} />,
        }
      }
      verifications.push(verificationItem)
    }
  }
  )

  return verifications
}

