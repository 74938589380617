import React from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import PersonalDetails from './PersonalDetails'
import EconomicProfile from './EconomicProfile'

const ProfileRoutes = (props) => (
  <Switch>
    <Route path={`${props.match.path}/details`} component={PersonalDetails} />
    <Route path={`${props.match.path}/economic`} render={() => (
      <EconomicProfile removeForced={() => props.removeForced()}/>
    )}/>
  </Switch>
)

export default withRouter(ProfileRoutes)
