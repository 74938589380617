import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {isEmpty, some, lowerCase, flowRight as compose} from 'lodash'
import moment from 'moment'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../assets/messages'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {clientNoticeAcknowledgementTypes, clientNoticeTypes, companies, regulators} from '@bdswiss/common-enums'
import PageTitle from './PageTitle'
import PageBody from './PageBody'
import {FullScreenDialog} from '../Common/Dialog'
import AppContext from '../Common/contexts/AppContext'
import {withRouter, Redirect} from 'react-router-dom'
import {NOTICE_ACKNOWLEDGEMENT_MUTATION} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY, PENDING_NOTICES_QUERY} from '../../graphql/queries'
import {storeItem, setCookie, addClassToElement, isMobile, removeClassToElement} from '../../common/utils'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  button:{
    margin: 15
  },
  buttonsGrid:{
    marginTop: 20
  },
  pageBody:{
    marginLeft: 30,
    [theme.breakpoints.down('sm')]:{
      marginLeft: 0,
    },
  },
  notificationContent:{
    '& h3':{
      fontWeight:'400'
    },
    '& a':{
      color: theme.palette.secondary.dark,
      textDecoration: 'underline'
    },
    '& th':{
      color: theme.palette.secondary.dark,
      fontWeight:'500'
    },
    '& table':{
      textAlign:'center'
    },
    '& h2 strong':{
      fontWeight:'400'
    },

  }
})

export const TRADING_GAME_NOTICE = 'trading_game_notice'
export const ITALIAN_CLIENT_NOTICE = 'italian_client_notice'

class TermsDialog extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext

  componentDidMount() {
    const {location} = this.props
    const notification = location && location.state && location.state.notice && location.state.notice[0]
    this.sendSeenAcknowledgement(notification)
    storeItem('termsUpdateSeen', true)

    const cookieDomain = window.location.hostname.match(/[^.]+\.\w+$/) ? //@ts-ignore
      window.location.hostname.match(/[^.]+\.\w+$/)[0] : window.location.hostname
    /*Add noredirect flag based on company url for account => access correct regulator docs from anywhere*/
    setCookie('noredirect_flag', true, (5/(24*60)), '.'+ cookieDomain)
    this.hideLiveChat()
  }

  handleClose() {
    this.props.history.push('/')
  }

  hideLiveChat() {
    const checkExist = setInterval(() => {
      const element = document.getElementById('chat-widget-container')
      if (element) {
        addClassToElement('chat-widget-container', 'displayNone')
        clearInterval(checkExist)
      }
    }, 100)
  }

  componentWillUnmount () {
    removeClassToElement('chat-widget-container', 'displayNone')
  }

  sendAcknowledgement(notification, type, callback) {

    const alreadySeen = some(notification.acknowledgements,
      ({acknowledgementType}) => acknowledgementType === clientNoticeAcknowledgementTypes.seen.key)

    if (type===clientNoticeAcknowledgementTypes.seen.key && (alreadySeen || (this.state && this.state.noticeSeen))) {
      callback()
      return
    }

    const variables = {
      type:  clientNoticeAcknowledgementTypes[type].key,
      noticeId: notification.id
    }
    this.props.sendNoticeAcknowledgement({variables})
      .then(()=> callback())
      .catch(console.error) // eslint-disable-line
  }

  sendSeenAcknowledgement(notification) {

    const alreadySeen = some(notification.acknowledgements,
      ({acknowledgementType}) => acknowledgementType === clientNoticeAcknowledgementTypes.seen.key)

    if (alreadySeen) return

    const variables = {
      type:  clientNoticeAcknowledgementTypes.seen.key,
      noticeId: notification.id
    }

    this.props.sendNoticeAcknowledgement({variables}).then(this.setState({noticeSeen:true})).catch(console.error)  // eslint-disable-line

  }

  sendTradingGameAcknowledgement(notification, type) {
    const alreadySeen = some(notification.acknowledgements,
      ({acknowledgementType}) => acknowledgementType === clientNoticeAcknowledgementTypes.seen.key)

    if (type===clientNoticeAcknowledgementTypes.seen.key && (alreadySeen || (this.state && this.state.noticeSeen))) {
      this.handleClose()
      return
    }

    const variables = {
      type:  clientNoticeAcknowledgementTypes[type].key,
      noticeId: notification.id,
      noticeRef: TRADING_GAME_NOTICE,
    }
    this.props.sendNoticeAcknowledgement({variables})
      .then(() => this.props.history.push('/support'))
      // .catch(console.error)
  }

  renderTradingGameNoticeButtons(notification) {
    const {classes} = this.props
    return (
      <>
        {/*<Button color="secondary" variant="contained" size="large" className={classes.button} onClick={() => this.sendTradingGameAcknowledgement(notification, 'seen')}>*/}
        {/*  {<Trans {...messages.clickForFurtherInfo} />}*/}
        {/*</Button>*/}
        <Button color="secondary" variant="contained" size="large" className={classes.button} onClick={() => this.sendTradingGameAcknowledgement(notification, 'seen')}>
          <Trans {...messages.close} />
        </Button>
        <Button color="primary" variant="contained" size="large" className={classes.button} onClick={() => this.sendTradingGameAcknowledgement(notification, 'accepted')}>
          {<Trans {...messages.contactSupport} />}
        </Button>
      </>
    )
  }

  renderItalianClientNoticeButtons(notification) {
    const {classes} = this.props
    const goToSupport = () => this.props.history.push('/support')
    return (
      <>
        <Button color="secondary" variant="contained" size="large" className={classes.button} onClick={() => this.sendAcknowledgement(notification, 'seen',  goToSupport)}>
          <Trans {...messages.contactSupport} />
        </Button>
        <Button color="primary" variant="contained" size="large" className={classes.button} onClick={() => this.sendAcknowledgement(notification, 'accepted', this.handleClose.bind(this))}>
          {<Trans {...messages.continue} />}
        </Button>
      </>
    )
  }

  renderDefaultActions(notification) {
    const {classes} = this.props
    return (
      <>
        <Button color="secondary" variant="contained" size="large" className={classes.button} onClick={() => this.sendAcknowledgement(notification, 'seen', this.handleClose.bind(this))}>
          {<Trans {...messages.actionRemindLater} />}
        </Button>
        <Button color="primary" variant="contained" size="large" className={classes.button} onClick={() => this.sendAcknowledgement(notification, 'accepted', this.handleClose.bind(this))}>
          {<Trans {...messages.accept} />}
        </Button>
      </>
    )
  }

  render() {
    const {classes, location, t} = this.props
    const notification = location && location.state && location.state.notice && location.state.notice[0]
    const {companyObject} = this.context
    if (isEmpty(notification)) return <Redirect to='/' />
    const regulator = regulators[companies[companyObject.key].regulator!].label

    const {localizationKey} = notification
    let actionButtons

    switch (true) {
      case localizationKey === TRADING_GAME_NOTICE:
        actionButtons = this.renderTradingGameNoticeButtons(notification)
        break
      case localizationKey === ITALIAN_CLIENT_NOTICE:
        actionButtons = this.renderItalianClientNoticeButtons(notification)
        break
      default:
        actionButtons = this.renderDefaultActions(notification)
    }

    return (
      <FullScreenDialog
        desktopOnly
        open
      >
        <PageTitle
          modal
          title={notification.title || t(messages.updateTermsTitle.i18nKey, messages.updateTermsTitle.defaults)}
          subtitle={notification.validFromDate && moment(notification.validFromDate).format('DD/MM/YYYY')}
        />
        <PageBody className={classes.pageBody}>
          <Typography variant='body1' align='justify' className={classes.notificationContent} paragraph dangerouslySetInnerHTML={{__html: notification.content}} />
          {notification.type === clientNoticeTypes.partners.key && <Typography variant='caption'><Trans {...messages[`${lowerCase(regulator)}Footer`]} /></Typography>}
          <Grid container justifyContent={isMobile() ? 'center' : 'flex-end'} className={classes.buttonsGrid}>
            {actionButtons}
          </Grid>
        </PageBody>
      </FullScreenDialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withRouter,
  withNamespaces(),
  graphql(NOTICE_ACKNOWLEDGEMENT_MUTATION, {
    name: 'sendNoticeAcknowledgement',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}, {query: PENDING_NOTICES_QUERY}],
    },
  })
)(TermsDialog)
