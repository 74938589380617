import React from 'react'
import {map, get, flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import {Mutation} from 'react-apollo'
import {countries} from '@bdswiss/common-enums'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import {withStyles} from '@material-ui/core/styles'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import messages from '../../../../assets/messages'
import {BEGIN_DEPOSIT_MUTATION, CREATE_DEPOSIT_FROM_SAVED_PCT_MUTATION} from '../../../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../../../graphql/queries'
import LoadingButton from '../../../Common/LoadingButton'


export const withCreateDeposit = (PaymentMethodComponent) => class extends React.Component {
  render() {
    return <Mutation mutation={BEGIN_DEPOSIT_MUTATION} refetchQueries={[{query: CLIENT_DATA_QUERY}]}>
      {(createDepositRequest) =>  <PaymentMethodComponent createDepositRequest={createDepositRequest} {...this.props} />}
    </Mutation>
  }
}

export const withCreateDepositPCT = (PaymentMethodComponent) => class extends React.Component {
  render() {
    return <Mutation mutation={CREATE_DEPOSIT_FROM_SAVED_PCT_MUTATION}>
      {(createDepositRequestPCT) =>  <PaymentMethodComponent createDepositRequestPCT={createDepositRequestPCT} {...this.props} />}
    </Mutation>
  }
}

const proceedToPayStyle = (theme) => ({
  rootGrid: {
    flexGrow: 1,
  },
  topSpacing: {
    marginTop: theme.spacing(10),
  }
})

export const PaymentActionButton = compose(
  withNamespaces(),
  withStyles(proceedToPayStyle),
  withWidth()
)((props) => {
  const {onClick, loading, disable, classes, width, buttonMessage, noMargin} = props
  const onSmallScreen = isWidthDown('xs', width)
  const btnMessage = buttonMessage || messages.proceedToPay

  return (
    <Grid container justifyContent="center" alignItems="center" direction="row" className={classes.rootGrid}>
      <Grid item className={onSmallScreen || noMargin ? '' : classes.topSpacing}>
        <LoadingButton
          id='loadingButton'
          onClick={onClick}
          disabled={ disable || loading || false}
          hideProgressBar = {disable}
          noMargin={noMargin}
        >
          <Trans {...btnMessage} />
        </LoadingButton>
      </Grid>
    </Grid>
  )
})

PaymentActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disable: PropTypes.bool,
  buttonMessage: PropTypes.shape({
    i18nKey: PropTypes.string.isRequired,
    defaults: PropTypes.string.isRequired
  })
}

const billingAddressDetailsStyle = (theme) => ({
  formControl: {
    margin: `${theme.spacing(1)}px 0`
  },
  address: {
    fontWeight: 400,
    margin: '10px 0 0 0',
  },
  addressSubtitle: {
    marginTop: 15,
  },
  countrySelect: {
    height: '1.3em',
    paddingBottom: 10
  },
})

export const BillingAddressDetails = compose(
  withNamespaces(),
  withStyles(billingAddressDetailsStyle),
)((props) => {
  const {classes, errors, form, handleChange, onCardFocus} = props

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant='h4' className={classes.address}><Trans {...messages.billingAddress} /></Typography>
        <Typography variant='subtitle2' className={classes.addressSubtitle}><Trans {...messages.billingAddressNote} /></Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="street">
            <Trans {...messages.streetLabel} />
          </InputLabel>
          <Input
            id="street"
            type="text"
            name="street"
            error={!!errors.street}
            onChange={(e) => handleChange(e)}
            onFocus={() => onCardFocus && onCardFocus()}
            inputProps={{autoComplete: 'off'}}
          />
          {
            errors.street &&
            <FormHelperText className={classes.error}><Trans {...errors.street} /></FormHelperText>
          }
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="city">
            <Trans {...messages.cityLabel} />
          </InputLabel>
          <Input
            id="city"
            type="text"
            name="city"
            error={!!errors.city}
            onChange={(e) => handleChange(e)}
            inputProps={{autoComplete: 'off'}}
          />
          {
            errors.city &&
            <FormHelperText className={classes.error}><Trans {...errors.city} /></FormHelperText>
          }
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="zip">
            <Trans {...messages.zipLabel} />
          </InputLabel>
          <Input
            id="zip"
            type="text"
            name="zip"
            error={!!errors.zip}
            onChange={(e) => handleChange(e)}
            inputProps={{autoComplete: 'off'}}
          />
          {
            errors.zip &&
            <FormHelperText className={classes.error}><Trans {...errors.zip} /></FormHelperText>
          }
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel htmlFor="country">
            <Trans {...messages.countryLabel} />
          </InputLabel>
          <Select
            native
            id="country"
            name="country"
            value={get(form, 'country', '')}
            onChange={(e) => handleChange(e)}
            error={errors.country}
            inputProps={{className: classes.countrySelect}}
          >
            {map(countries, (option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </React.Fragment>
  )
})

BillingAddressDetails.propTypes = {
  form: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}
