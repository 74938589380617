import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Dialog from '@material-ui/core/Dialog'
import {frontends} from '@bdswiss/common-enums'
import ListItem from '@material-ui/core/ListItem'
import {withNamespaces, Trans} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {get, filter, keys, map, isEmpty, flowRight as compose} from 'lodash'
import DialogContent from '@material-ui/core/DialogContent'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import messages from '../../../../assets/messages'
import {getPlatform} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'

const style = (theme) => ({
  iframe: {
    overflow: 'scroll',
    width: '100%',
    minHeight: '99%'
  },
  fullHeight: {
    height: '100%',
  },
  note: {
    color: theme.palette.red.color,
  },
  root: {
    zIndex: 2147483640
  }
})

class GenericIFramedProvider extends Component<any,any> {
  static propTypes = {
    account: PropTypes.shape({
      id: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    amount: PropTypes.number.isRequired,
    providerProperties: PropTypes.shape({
      name: PropTypes.string.isRequired,
      provider: PropTypes.string.isRequired,
      paymentKey: PropTypes.string.isRequired,
    }).isRequired,
    onError: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
  }
  state = {openFrameDialog: false} as any

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.iframeUrl) {
      return {openFrameDialog: true, iframeUrl: nextProps.iframeUrl}
    }
    return null
  }

  getIframeUrl() {
    const {providerProperties: {provider, paymentKey}, account, amount, onError, bonusAmount, bonusTerms} = this.props

    this.setState({gettingIframeUrl: true})

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url: iframeUrl}} = newDeposit
        this.setState({iframeUrl, gettingIframeUrl: false, openFrameDialog: true})
      })
      .catch((e) => {
        this.setState({gettingIframeUrl: false})
        onError && onError(e)
      })
  }

  changeLocation(e) {
    try {
      if (get(e, 'target.contentWindow.location.host') === window.location.host) {
        window.location = get(e, 'target.contentWindow.location.href')
      }
    } catch (e) {
      // ignore iframe error
    }
  }

  render() {
    const {openFrameDialog, iframeUrl, gettingIframeUrl} = this.state
    const {classes, providerProperties: {name: providerName, provider}} = this.props

    const methodInfoMessages = filter(keys(messages), (m:any) => m.indexOf(`${provider}DepositInfo`) === 0).reduce((m, i) => {
      m[i] = messages[i]
      return m
    }, {})
    const methodNoteMessages = filter(keys(messages), (m:any) => m.indexOf(`${provider}DepositNote`) === 0).reduce((m, i) => {
      m[i] = messages[i]
      return m
    }, {})


    return (
      <React.Fragment>
        {!iframeUrl &&
          <PaymentActionButton
            loading={gettingIframeUrl}
            onClick={() =>  this.getIframeUrl()}
          />
        }
        <Dialog fullWidth disableEscapeKeyDown fullScreen open={openFrameDialog} aria-labelledby={providerName} classes={{root: classes.root}}>
          <DialogContent className={classes.fullHeight}>
            <iframe frameBorder="0" title={providerName} src={iframeUrl} className={classes.iframe} onLoad={(e) => this.changeLocation(e)} ></iframe>
          </DialogContent>
        </Dialog>
        <br />
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            {(!isEmpty(methodInfoMessages) || !isEmpty(methodInfoMessages)) && <Typography align="center" variant="h3">
              <Trans {...messages.usefulInfo} />
            </Typography>}
            <List>
              {map((methodInfoMessages), (m:any) =>
                <ListItem key={m.i18nKey}>
                  <Typography variant="body1"><Trans {...m} /></Typography>
                </ListItem>
              )}
              {map((methodNoteMessages), (m:any) =>
                <ListItem key={m.i18nKey}>
                  <Typography className={classes.note} variant="caption"><Trans {...m} /></Typography>
                </ListItem>
              )}
            </List>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}


export default compose(
  withNamespaces(),
  withStyles(style),
  withMobileDialog(),
  withCreateDeposit
)(GenericIFramedProvider)
