import React from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import messages from '../../../assets/messages'
import withStyles from '@material-ui/core/styles/withStyles'
import {withRouter, Link, Redirect} from 'react-router-dom'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {first, get, map, isEmpty, some, find, uniq, includes, filter, flowRight as compose} from 'lodash'
import {safeParseJSON, getMissingVerifications} from '../../../common/utils/general'
import {config} from '../../../config'
import {CLIENT_COMPETITION_MUTATION, CLIENT_EXIT_COMPETITION_MUTATION} from '../../../graphql/mutations'
import {Loading} from '../../Common/Loading'
import Amount from '../../Common/Amount'
import {FullScreenDialog} from '../../Common/Dialog'
import CompetitionCard from './CompetitionCard'
import PageTitle from '../../Common/PageTitle'
import PageBody from '../../Common/PageBody'
import Leaderboard from './Leaderboard'
import CompetitionRules from './CompetitionRules'
import MemberDetails from './MemberDetails'
import QuitedCompetition from './QuitedCompetition'
import CompetitionForm from './CompetitionForm'
import {isMobile} from '../../../common/utils/browser'
import moment from 'moment'
import {validateLength} from '../../../common/utils/validations'
import {accountTypes} from '@bdswiss/common-enums'
import CompetitionMemberDetails from './CompetitionMemberDetails'
import classNames from 'classnames'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  amount: {
    padding: '0 5px'
  },
  amountCurrency: {
    fontSize: 24,
  },
  amountValue: {
    fontSize: 32,
  },
  amountFraction: {
    fontSize: 22,
  },
  spacing: {
    marginTop: 20
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: 10
  },
  yellowText: {
    color: theme.palette.yellow.color,
  },
  verifyDiv: {
    padding:10,
    backgroundColor: theme.palette.lightyellow.color,
    margin: '-20px 0'
  },
  infoIcon: {
    marginBottom: -6,
    marginRight: 5,
  },
  verifyDivText:{
    padding: '15px 34px 5px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0',
    }
  },
  displayInline: {
    display: 'inline',
  },
  textRight: {
    textAlign: 'right' as const
  },
  requestCallbackButton: {
    color: '#FFFFFF',
    backgroundColor: '#007AFF',
    '&:hover' : {
      backgroundColor: '#0055B2',
    }
  },
})

class TradingCompetitions extends React.Component<any,any> {

  constructor(props) {
    super(props)
    this.state = {
      form: {
        nickname: get(props, 'client.nickname') || '',
        account: '',
        acceptTerms: '',
      },
      errors: {},
      loading: false,
      status: '',
      competitionError: '',
      competitionErrorValues: null,
      showAccounts: false,
      nicknameExists: false,
      participatedCompetition: false,
      competition: (props.competitions && props.competitions.length === 1) ? first(props.competitions) : null,
      entry: {}
    }
  }

  componentDidMount() {
    const {accounts} = this.props
    const {featuresConfig:{competitions}} = config
    const availableAccounts = filter(accounts, (a) => includes(get(competitions,'accountSubTypes',[]), a.accountSubtype) &&
    includes(get(competitions,'accountTypesCategory',[]), accountTypes[a.__typename].category)
    && !a.isDemo && !a.isViewOnly  && !a.isArchived)
    if (isEmpty(availableAccounts)) {
      this.setState({competitionError: 'noClassic'})
    }
  }

  handleChange(prop, value) {
    this.setState(state => ({
      form: {...state.form,[prop]: value,},
      errors: {...state.errors,[prop]: !value,  nicknameLength: false}
    }))
  }

  handleChangeAccount(prop, value, accounts, entryFee) {
    const {classes} = this.props
    const {competition} = this.state
    this.setState({competitionError: ''})
    const account = find(accounts, (a) => a.id === value)
    const entry = account.competitions && find(account.competitions, 'active')
    const competitionIds = account.competitions && uniq(map(account.competitions, 'competitionDetails.id'))
    entryFee = entryFee.replace(/\D/g,'')
    if (!entry && includes(competitionIds, competition.id)) {
      this.setState({competitionError: 'quitCompetition'})
    }
    else if (entry && get(entry, 'competitionDetails.id') !== competition.id) {
      this.setState({competitionError: 'differentCompetition'})
    }
    else if (get(account, 'balance') < entryFee) {
      this.setState({competitionError: 'minimumBalanceRequired', competitionErrorValues: [<Amount key={account.id} value={entryFee} currency={get(account, 'currency', 'EUR')} locale='en-US' />,
        <Link to={`/transactions/${account.id}/deposit`} className={classes.link}>depositNow</Link>]})
    }
    else if (get(account.copyTrading,'isActive')) {
      this.setState({competitionError: 'copyTrading'})
    }
    this.handleChange(prop, value)
  }

  competitionSign() {
    const {form, competition} = this.state
    const {client} = this.props
    const errors : any = {}

    for (const field of Object.keys(form)) {
      if (field === 'nickname') {
        errors[field] = !form[field] && isEmpty(form[field])
        errors.nicknameLength = !validateLength(form[field], 15)
      } else {
        errors[field] = !form[field] && isEmpty(form[field])
      }
    }

    if (some(errors)) {
      this.setState({errors})
      return
    }

    const variables = {memberId: client.id, competitionId: competition.id, accountId: form.account, nickname: form.nickname, acceptTerms: form.acceptTerms}
    this.setState({loading: true})
    this.props.clientCompetition({variables})
      .then((succ) => {
        this.setState({loading: false, status: 'success'})
      })
      .catch((e) => {
        this.setState({loading: false, status: 'failure',
          nicknameExists: includes(get( e, 'graphQLErrors[0].code'), 'nicknameExists'),
          participatedCompetition: includes(get( e, 'graphQLErrors[0].code'), 'participatedCompetition'),
          cannotParticipateCompetition: includes(get( e, 'graphQLErrors[0].code'), 'cannotParticipateCompetition')})
      })
  }

  renderVerification() {
    const {classes} = this.props
    return <Grid item xs={12} className={classes.verifyDiv}>
      <Grid container>
        <Grid item xs={12}>
          <InfoIcon className={classNames(classes.infoIcon, classes.yellowText)}/>
          <Typography variant="subtitle1" className={classes.displayInline}> <Trans {...messages.verifyYourAccount} /> </Typography>
        </Grid>
        <Grid item xs={12} className={classes.verifyDivText}>
          <Typography variant="body1"> <Trans {...messages.verifyText} /></Typography>
        </Grid>
        <Grid item xs={12} className={classes.textRight}>
          <Typography variant="body1" className={classes.title}>
            <Link to={'/accounts/verification'} className={classNames(classes.link)}><Trans {...messages.verifyAccount} /></Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  }

  getCompetition(competitions, currentEntry, oldEntry, rules, fullScreen) {
    const {classes, accounts, client} = this.props
    const {competition, nicknameExists , participatedCompetition, competitionErrorValues, form: {account}} = this.state
    const {accountVerification} = config
    const active = moment(get(competition, 'endDate')) > moment()
    const missingVerifications =  getMissingVerifications(client, first(get(client, 'appropTests')),
      get(client, 'globalQuestionnaire'), '', accountVerification)

    return <React.Fragment>
      {fullScreen && <PageTitle
        onBack={()=> (!competition || competitions.length === 1) ? this.props.history.push('/accounts') :
          this.setState(state => ({form: {...state.form, account: '', acceptTerms: ''}, competition: null, competitionError: ''}))}>
        <Trans {...messages.tradingCompetitions} />
      </PageTitle>}
      <PageBody removePadding>
        {(!competition && competitions.length !== 1) ?
          <Grid container spacing={isMobile() ? 1 : 3}>
            {map(competitions, (c) =>
              <Grid item xs={12} key={c.id}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={7}>
                    <CompetitionCard competition={c} accounts={accounts} client={client}
                      onClick={(c) => this.setState({competition: c})} />
                  </Grid>
                </Grid>
              </Grid>)}
          </Grid>
          : <Grid container spacing={isMobile() ? 1 : 4}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Grid container spacing={isMobile() ? 1 : 3}>
                <Grid item xs={12}>
                  <CompetitionCard competition={competition} accounts={accounts} client={client} noHover exitClick={true}  account={account}
                    entry={(oldEntry) => this.setState({entry: oldEntry})} />
                </Grid>

                {active && <Grid item xs={12} className={classes.spacing}>
                  {(!isEmpty(missingVerifications)) ? this.renderVerification() : (!currentEntry && !oldEntry)
                    ? <CompetitionForm rules={rules} state={this.state} client={client} accounts={accounts}
                      handleChange={(prop, value) => this.handleChange(prop, value)}
                      handleChangeAccount={(prop, value, accounts, entryFee) => this.handleChangeAccount(prop, value, accounts, entryFee)}
                      competitionSign={() => this.competitionSign()} participatedCompetition={participatedCompetition} nicknameExists={nicknameExists}
                      competitionErrorValues={competitionErrorValues}/>
                    : <Grid container spacing={isMobile() ? 0 : 3}>
                      {(oldEntry && !currentEntry)
                        ? <QuitedCompetition oldEntry={oldEntry}
                          showAccounts={() => this.setState(state => ({form: {...state.form,'account': ''},status: '',showAccounts: true, entry: {}}))}/>
                        : <MemberDetails currentEntry={currentEntry} nickname={get(client, 'nickname')}/>
                      }
                    </Grid>
                  }
                </Grid>}
                {(!active || (active && (currentEntry))) && <CompetitionMemberDetails competition={competition}
                  currentEntry={currentEntry} oldEntry={oldEntry} rules={rules}/>}
                <Grid item xs={12} className={classes.spacing}>
                  <CompetitionRules rules={rules} competition={competition} />
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} className={isMobile() ? classes.spacing : ''}>
              <Typography variant="h4" className={classes.title}> <Trans {...messages.leaderboard} /> </Typography>
              <Leaderboard competition = {get(competition,'id')}/>
              <Button
                className={classNames(classes.requestCallbackButton, this.props.buttonClass)}
                variant='contained'
                size='medium'
                onClick={() => this.props.history.push({pathname:'/callback', state: {type: 'oneOnOne',  prevPath: '/accounts'}})}
              >
                <Typography variant={'body2'} color="inherit">
                  <span><Trans {...messages.requestCallback} /></span>
                </Typography>
              </Button>
            </Grid>
          </Grid>
        }</PageBody>
    </React.Fragment>
  }

  render() {
    const {accounts, loadingCompetition, loadingClient, loadingAccounts, competitions, client} = this.props
    const {showAccounts, competition, entry} = this.state
    const {featuresConfig:{competitions: competitionsAllowed}} = config
    const country = get(client, 'address.country', '')

    if (loadingCompetition || loadingClient || loadingAccounts) return <Loading />

    if (!competitionsAllowed || competitions.length === 0 || includes(get(competitionsAllowed, 'blockedCountries'), country.toUpperCase()))
      return <Redirect to={{pathname: '/accounts'}}/>

    const currentEntry = find(accounts, (a) => a.competitions && find(a.competitions, (e) =>
      get(e, 'competitionDetails.id') === get(competition, 'id' ) && e.active))
    const oldEntry = !isEmpty(entry) ? entry : !showAccounts && find(accounts, (a) => a.competitions && find(a.competitions, (e) =>
      get(e, 'competitionDetails.id') === get(competition, 'id' ) && !e.active))

    const rules = safeParseJSON(get(competition, 'rules'))
    const fullScreen = !includes(get(this.props ,'location.pathname'), 'accounts')
    const component = this.getCompetition(competitions, currentEntry, oldEntry, rules, fullScreen)

    return (fullScreen) ? <FullScreenDialog
      desktopOnly
      fullScreen
      open={true}
      onClose={() => (!competition || competitions.length === 1) ? this.props.history.push('/accounts') :
        this.setState(state => ({form: {...state.form, account: '', acceptTerms: ''}, competition: null, competitionError: ''}))}
    > {component}
    </FullScreenDialog> : component
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_COMPETITION_MUTATION, {
    name: 'clientCompetition',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}, {query: CLIENT_DATA_QUERY}],
    },
  }),
  graphql(CLIENT_EXIT_COMPETITION_MUTATION, {
    name: 'clientExitCompetition',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}, {query: CLIENT_DATA_QUERY}],
    },
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}: any) => ({
      errorClient: error,
      loadingClient: loading,
      client: get(data, 'viewer'),
      competitions: get(data, 'viewer.competitions'),
    })}),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps, ownProps: {match, competitions}}: any) => {
      const {accountId} = match.params
      const account = get(data, 'viewer.accounts', []).find(account => account.id === Number(accountId))
      let accountCompetitions
      if (account && account.competitions && competitions) {
        accountCompetitions  = competitions.filter((c) => find(account.competitions, (e) =>
          get(e, 'competitionDetails.id') === get(c, 'id' )))
      }

      return {
        error,
        loading,
        accounts: get(data, 'viewer.accounts', []),
        account,
        competitions: accountCompetitions || competitions,
      }
    }
  }),
)(TradingCompetitions)
