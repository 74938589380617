import React from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import History from './History'
import Deposit from './Deposits/Deposit'
import DepositNotification from './Deposits/DepositNotification'
import Withdraw from './Withdrawals/Withdraw'
import Transfers from './Transfers'
import DepositAction from './Deposits/DepositAction'
import {config} from '../../config'

const PaymentRoutes = ({match: {path}}) => {
  const {showTab} = config
  const showDepositTab = showTab?.deposit ?? true
  return (
    <Switch>
      {showDepositTab && <Route path={`${path}/:accountId/deposit/result/:resultstatus`} component={DepositNotification} />}
      {showDepositTab && <Route path={`${path}/:accountId/:action/deposit/:depositId`} component={DepositAction} />}
      {showDepositTab && <Route path={`${path}/:accountId/deposit`} component={Deposit} />}
      <Route path={`${path}/:accountId/transfer`} component={Transfers} />
      <Route path={`${path}/transfer`} component={Transfers} />
      <Route path={`${path}/withdraw`} component={Withdraw} />
      <Route path={`${path}/history`} component={History} />
      {showDepositTab
        ? <Route path={`${path}`} component={Deposit} />
        : <Route path={`${path}`} component={Withdraw} />
      }
    </Switch>
  )
}

export default withRouter(PaymentRoutes)
