import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import {get, size, isEmpty, flowRight as compose} from 'lodash'
import {withStyles} from '@material-ui/core/styles'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import {SEND_PHONE_VERIFICATION_CODE_MUTATION} from '../../graphql/mutations'
import {graphql} from 'react-apollo'
import {Loading} from '../Common/Loading'
import AwaitingVerification from './AwaitingVerification'
import PhoneVerification from './PhoneVerification'
import {storeItem, getItem} from '../../common/utils'
import {withRouter} from 'react-router-dom'
import {phoneVerificationTypes} from '@bdswiss/common-enums'

const styles = theme => ({
  div:{
    display: 'block',
    margin: theme.spacing(1),
    width: '100%'
  }
})

class SMSVerification extends Component<any,any> {
  timer: any
  constructor(props) {
    super(props)
    this.state = {
      form:{
        code:'',
      },
      errors: {},
      showPhone:false,
      loading: false,
      submitTime:30,
      sendMessageTimers:{
        1:30,
        2:60,
        3:180,
        4:300,
        5:21600,
      },
      phoneVerificationId:'',
      disableSend:false
    }
  }

  componentDidMount() {
    const {loading, viewer:{isPhoneVerificationRequired, isPhoneVerified, id, phone}, history} = this.props
    if (!isPhoneVerificationRequired || isPhoneVerified)
      history.push('/')
    if (!loading && (isPhoneVerificationRequired && !isPhoneVerified)) {
      const smsObject = getItem(`smsObject${id}`) as any
      const now = Math.floor(Date.now() / 1000)

      if (!isEmpty(phone)) {
        if (!smsObject || now > smsObject.available) {
          this.checkTime({type: phoneVerificationTypes.sms.key})
        }
        else {
          this.setState({disableSend:true,submitTime: smsObject.available-now})
          this.timer = setInterval(() => this.tick(smsObject), 1000)
        }
      }
    }

  }

  secondsToTime(seconds) {
    const date = new Date()
    date.setSeconds(seconds)
    return date.toISOString().substr(11, 8)
  }

  sendSmsVerification(params) {
    const {viewer:{phone}} = this.props
    const variables = {
      phone: params.phone || phone,
      type: params.type
    }

    this.props.sendSmsVerification({variables})
      .then((res) => {
        this.setState({phoneVerificationId: res['data']['sendSmsVerification']['id'],statusSms: 'success', smsError: ''})
      })
      .catch((error, res) => {
        const smsError = error.toString().indexOf('Phone already used') !== -1 ? 'usedPhone' : 'unableToSend'
        this.setState({statusSms: 'failure', smsError})
        this.checkTime(variables, false)
      })
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  tick (smsObject) {
    const {submitTime} = this.state
    const now = Math.floor(Date.now() / 1000)
    if (now > smsObject.available) {
      this.setState<'disableSend'>({disableSend:false})
      clearInterval(this.timer)
    }
    else
      this.setState<'submitTime'>({submitTime: (submitTime - 1)})
  }

  checkTime(variables, sendCode = true) {
    const {sendMessageTimers} = this.state
    const {viewer: {id}} = this.props
    const now = Math.floor(Date.now() / 1000)
    const smsObject = (getItem(`smsObject${id}`))?getItem(`smsObject${id}`):{time:'', clicks:0, validUntil:now+86400} as any
    if (now > smsObject.validUntil) {
      smsObject.clicks = 0
      smsObject.validUntil = now+86400
    }
    if (smsObject.clicks < size(sendMessageTimers))
      smsObject.clicks++

    if (!sendCode) smsObject.clicks = 3

    smsObject.time = now
    smsObject.available = now + sendMessageTimers[smsObject.clicks]
    storeItem(`smsObject${id}`,smsObject)
    clearInterval(this.timer)

    sendCode && this.sendSmsVerification(variables)
    this.timer = setInterval(() => this.tick(smsObject), 1000)
    this.setState({disableSend:true, submitTime: smsObject.available-now})
  }

  handleChangeParent(name, value) {
    //@ts-ignore
    this.setState({[name]: value})
  }

  render() {
    const {loading, viewer:{phone}} = this.props

    if (loading) return <Loading />
    const {statusSms, smsError, showPhone, submitTime, disableSend, phoneVerificationId} = this.state
    const phoneForce = isEmpty(phone) || showPhone
    return <React.Fragment>
      {!phoneForce &&
        <AwaitingVerification
          sendSmsVerification={this.sendSmsVerification.bind(this)}
          secondsToTime={this.secondsToTime.bind(this)}
          checkTime={this.checkTime.bind(this)}
          disableSend={disableSend}
          handleChangeParent={this.handleChangeParent.bind(this)}
          statusSms={statusSms}
          submitTime={submitTime}
          smsError={smsError}
          phoneVerificationId={phoneVerificationId}
        />
      }
      {phoneForce &&
        <PhoneVerification
          showPhone={phoneForce}
          handleChangeParent={this.handleChangeParent.bind(this)}
          checkTime={this.checkTime.bind(this)}
          disableSend={disableSend}
          secondsToTime={this.secondsToTime.bind(this)}
          submitTime={submitTime}
          sendSmsVerification={this.sendSmsVerification.bind(this)}
        />
      }
    </React.Fragment>
  }
}

export default compose (
  withStyles(styles),
  withNamespaces(),
  withRouter,
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => ({
      loading: props.data?.loading,
      error: props.data?.error,
      viewer: get(props.data, 'viewer')
    })
  }),
  graphql(SEND_PHONE_VERIFICATION_CODE_MUTATION, {
    name: 'sendSmsVerification',
    //@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
)(SMSVerification)
