import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {Loading} from '../../Common/Loading'
import {withNamespaces, Trans} from 'react-i18next'
import {accountTypes, accountCategories, clientTypes, countries, kycStatuses} from '@bdswiss/common-enums'
import _, {get, includes, filter, size, every, isEmpty, flowRight as compose, map, find/*, keys*/} from 'lodash'
import {graphql, withApollo} from 'react-apollo'
import {CLIENT_DATA_QUERY, ACCOUNTS_QUERY} from '../../../graphql/queries'
import messages from '../../../assets/messages'
import AddNewAccountIcon from '../../../assets/images/icon-add-account.svg'
import PageTitle from '../../Common/PageTitle'
import PageBody from '../../Common/PageBody'
import AccountCard from './AccountCard'
import {Link, Redirect} from 'react-router-dom'
import MifirPopup from '../../Accounts/MifirPopup'
import {isForexAccount, isPAMMFundManagerAccount, isAffiliatesAccount, isIntroducingBrokerAccount, hasWalletProductAccount, getAllowedAccounts, isBitnukAccount} from '../../../common/utils/accounts'
import {isMobile, getCookie, getItem} from '../../../common/utils'
import ClientNotificationBar from './ClientNotificationBar'
import NotificationBar from '../../Common/NotificationBar'
import AppContext from '../../Common/contexts/AppContext'
import {config} from '../../../config'
import {AFFILIATE_SSO_LINK_QUERY, IB_SSO_LINK_MUTATION} from '../../../graphql/mutations'
import {AlertDialog} from '../../Common/Dialog'
import CFDNoticePopup from '../../Accounts/CFDNoticePopup'
import UiNotification from '../../Common/UiNotification'
import IbAccountSelectModal from '../../Accounts/Ib/IbAccountSelectModal'
import Images from '../../Common/Images'
import classNames from 'classnames'
import CustomNotificationAlert from '../../Common/CustomNotificationAlert'
import PartnerMigrationPopup from '../../Accounts/PartnerMigrationPopup'
import MigrationToEuPopup from '../../Accounts/MigrationToEuPopup'
import {accountCategoriesDetails} from '../../../common/utils/uioptions'
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
// import moment from 'moment'
import {getExternalAccountData} from '../../../common/utils/requests'
import ExternalAccountCard from './ExternalAccountCard'
import {InnerAppContext} from '../../../common/types'

require('intl/locale-data/jsonp/en')

const styles = theme => ({
  main: {
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: `${theme.sidebarWidth}`,
  },
  header: {
    marginBottom: 40,
    display: 'inline-block'
  },
  flexGrow:{
    flexGrow:1
  },
  zeroPaddingLeft:{
    paddingLeft:0
  },
  tab: {
    fontWeight: 500
  },
  addAccount:{
    width: 227,
    height: 42,
    borderRadius: 8,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center' as const,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.0468204)',
    cursor: 'pointer'
  },
  addAccountIcon: {
    width: 18,
    height: 18,
    marginRight: 6,
    verticalAlign: 'middle',
  },
  addAccountMobile:{
    display: 'block',
    fontSize: 25,
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: 12,
    top: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
    },
  },
  textLink: {
    color: theme.palette.primary.main,
  },
  competitionImg: {
    height: 18,
    verticalAlign: 'middle',
    marginRight: 6,
  },
  displayInline: {
    display: 'inline-flex'
  },
  competitionDivider: {
    lineHeight: '15px',
    minHeight: 15,
  },
  competitionsLink: {
    // [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: `1px solid ${theme.palette.primary.main}`,
    // [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: 30,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 30,
  },
  competitionsLinkMobile: {
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 0,
    // paddingRight: 11,
    // border: 'none'
  },
  enterCompetition: {
    right: 37,
    top: 20,

  },
  enterCompetitionMobile: {
    fontSize: 14,
    right: 17,
  },
  competitionSpan: {
    position: 'relative' as const,
  },
  maxAccountsDiv: {
    textAlign: 'center' as const,
    padding: '15px 0'
  },
  pointer: {
    cursor: 'pointer',
  },
  dot: {
    color: theme.palette.error.main,
    fontSize: 10,
    marginLeft: 5
  }
})

class LydianRootAccounts extends Component<any,any> {
  static propTypes = {
    accounts: PropTypes.array,
    error: PropTypes.object,
    loading: PropTypes.bool,
  }
  static contextType = AppContext
  context!: InnerAppContext
  timerComponentLoad: NodeJS.Timeout =setTimeout(()=>{})
  constructor(props) {
    super(props)
    const appropTestScore = props.history.location.search.split('score=')[1]
    const accountCreated = props.history.location.search.split('accountCreated=')[1]
    this.state = {
      activeTab: 0,
      step: 0,
      appropTestScore: appropTestScore,
      showAppropTestMessage: !!appropTestScore,
      showMifirPopup: true,
      showCFDNoticePopup: true,
      showAffiliatePopup: true,
      showAffiliatePopupClick: false,
      showRedirectionModal: true,
      showAccountCreatedMessage: !!accountCreated,
      showIBPopup: false,
      showIbAccountSelectionModal: false,
      ibAccountSelected: {},
      showRefreshNotification: false,
      showPartnerMigration: true,
      showBitnukPopup: false,
    }
  }

  componentDidMount() {
    const {location} = this.props
    this.timerComponentLoad = setTimeout(() => this.checkComponent(), 30000)
    if (get(location, 'state.showBitnukPopup')) this.setState({showBitnukPopup: !!location.state.showBitnukPopup})
  }

  componentDidUpdate(prevProps) {
    const {viewer} = this.props
    const {externalAccounts} = this.state
    if (config.externalAccountURL && viewer?.id && !externalAccounts) {
      getExternalAccountData({clientId: viewer.id}).then((res) => {
        //@ts-ignore
        const externalAccounts = Object.entries(res?.data ?? {}).filter(([name, data]) => typeof data !== 'string').map(([name, data]) => ({name, ...data}))
        this.setState({externalAccounts})
      })
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timerComponentLoad)
  }

  checkComponent() {
    const {loading, loadingClient, error, errorClient, accounts} = this.props
    if (loading || loadingClient || error || errorClient || !accounts) {
      this.setState({showRefreshNotification: true})
    }
  }

  decreaseStep() {
    const {step} = this.state
    const {history} = this.props;
    (step > 0) ? this.setState({step:step-1}): history.push('/')
  }
  setStep(step) {
    this.setState({step})
  }

  toAffiliatePortal(account) {
    const {client: apolloClient} = this.props
    const newTab = window.open('about:blank', '_blank')
    if (!newTab) {
      return
    }
    newTab.document.write('<h4>Please wait</h4>')

    apolloClient.query({query: AFFILIATE_SSO_LINK_QUERY, variables: {accountId: account.id}, fetchPolicy:'network-only'}).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  toIBPortal(account, ibId) {
    const {createIBSingleSignOnLink} = this.props
    const newTab = window.open('about:blank', '_blank')
    if (!newTab) {
      return
    }
    newTab.document.write('<h4>Please wait</h4>')
    createIBSingleSignOnLink({variables: {accountId: account.id, ibId}}).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  openAccount(account) {
    const {viewer} = this.props
    const verifiedAffiliate = (isAffiliatesAccount(account) && includes(['Approved'],
      get(account, 'serviceFields.affiliateStatus')))
    const approvedIb = (isIntroducingBrokerAccount(account) && get(account, 'approved', false))
    const {history, kycStatus} = this.props
    if (isAffiliatesAccount(account)) {
      !verifiedAffiliate ? this.setState({showAffiliatePopupClick: true, showAffiliatePopup: true}) : this.toAffiliatePortal(account)
    } else if (isIntroducingBrokerAccount(account)) {
      if (!approvedIb) {
        this.setState({showIBPopup: true})
      } else if (get(account, 'ibId', []).length > 1) {
        this.setState({showIbAccountSelectionModal: true, ibAccountSelected: account})
      } else {
        this.toIBPortal(account,undefined)
      }
    } else if (isBitnukAccount(account) && kycStatus !== kycStatuses.approved.value) {
      this.setState({showBitnukPopup: true})
    } else if (account.external) {
      history.push(`/accounts/${viewer?.id}/external`)
    } else {
      history.push(`/accounts/${account.id}`)
    }
  }

  hideMifirPopup() {
    this.props.history.push('/accounts')
    this.setState({showMifirPopup: false})
  }

  hasOnlyWalletAccounts(accounts) {
    return size(accounts) > 0 &&
      every(accounts, (a) => ['fiatWallet', 'cryptoWallet'].includes(accountTypes[a.__typename].category))
  }

  hideAffiliatePopup() {
    this.props.history.push('/accounts')
    this.setState({showAffiliatePopup: false, showAffiliatePopupClick:false})
  }

  continueAffiliatePopup(continueAffiliatePopup) {
    if (continueAffiliatePopup) {
      this.props.history.push( {
        pathname: '/settings/profile/due-diligence',
        state: {force: true}
      })
    }

    this.setState({showAffiliatePopup: false, showAffiliatePopupClick:false})
  }

  hideCFDNoticePopup() {
    this.setState({showCFDNoticePopup: false})
  }

  hideRedirectionsModal() {
    if (getCookie('missing_approp_test') === 'true') {
      this.props.history.push('/settings/profile/appropriateness-test')
    } else {
      this.setState({showRedirectionModal: false})
    }
  }

  renderLinks(showCompetitions) {
    const {classes} = this.props
    return <React.Fragment>
      {showCompetitions && <Link to={'/trading-competitions'} className={classNames(classes.addAccount, isMobile() ? classes.competitionsLinkMobile : classes.competitionsLink)}>
        <Typography variant={'body1'}>
          <img src={Images['competitions.png']} alt={'competitions'} className={classes.competitionImg}/>
          <span>
            {!isMobile() ? <Trans {...messages.enterCompetition} /> : <Trans {...messages.competition} /> }
          </span>
        </Typography>
      </Link>}
      {!isMobile() && <Link to={'/accounts/add-account'} className={classes.addAccount}>
        <Typography variant={'body1'}>
          <img src={AddNewAccountIcon} alt={messages.addNewAccount.defaults} className={classes.addAccountIcon}/>
          <span><Trans {...messages.addNewAccount} /></span>
        </Typography>
      </Link>}
    </React.Fragment>
  }

  render() {
    const {classes, loading, accounts, categories, nationality, mifirId, mifirType, history,
      country, t, depositedAmount, kycStatus, spanishCfdNotice, loadingClient, competitions, allowedAccountTypes, viewer} = this.props
    const {activeTab, showMifirPopup, showCFDNoticePopup, showBitnukPopup,
      showRedirectionModal, showAccountCreatedMessage, showIBPopup,
      showIbAccountSelectionModal, ibAccountSelected, showRefreshNotification, showPartnerMigration, externalAccounts} = this.state
    const {blockedDeposit, clientType, companyObject, locale} = this.context
    const {ibPartnersEmail, featuresConfig: {competitions: competitionsAllowed}, name, common: {nationalitiesDisabledMifir},
      euRegulation: {showTermsPopup}} = config
    if ((loading || loadingClient || !accounts) && !showRefreshNotification) return <Loading />
    const activeCategories = activeTab === 0 ? [] : categories[activeTab-1]?.accountTypeCategories
    const filteredAccounts = accounts
      .map(account => ({...account, accountType: accountTypes[account.__typename]}))
      .filter(account => activeTab === 0 || activeCategories.includes(account.accountType.category))

    const emptyMifir = (mifirId === null || mifirId === '') || (mifirType === null || mifirType === '')
    const checkAff = (clientType === clientTypes.affiliate.value) ||
      clientType === clientTypes.affiliateCorporate.value

    const mifirCondition = depositedAmount > 0 && config.featuresConfig.mifirPopup && emptyMifir
      && !checkAff && !includes(nationalitiesDisabledMifir, nationality) && !this.hasOnlyWalletAccounts(accounts)

    const liveAccounts = filter(accounts, (a)=> isForexAccount(a) && !a.isDemo)
    const hasNonReadonlyLiveAccounts = filter(liveAccounts, (account) => !account.isReadOnly)
    const hasSignedCfdNotice = _.find(spanishCfdNotice, {noticeId: 'generalCFDDisclaimer'})
    const cfdNoticeCondition = config.featuresConfig.spanishCfdNotice && country === countries.es.key && !hasSignedCfdNotice
      && !isEmpty(hasNonReadonlyLiveAccounts) && depositedAmount > 0

    let redirected
    let wlRedirection
    if (this.props.location.query) {
      redirected = this.props.location.query.search.indexOf('refEntity') > 0
      wlRedirection = this.props.location.query.search.indexOf('wlRedirection') > 0
      if (redirected && config.featuresConfig.migrateCompany.enable && !getCookie('__mig-rejected')
        && !!getCookie('RegulationAccepted') && !hasWalletProductAccount(accounts) && !get(viewer, 'euMigration')) {
        return <Redirect to='/migrate' />
      }
    }
    const showCompetitions = competitionsAllowed && get(competitions, 'length') > 0 && (!country || !includes(get(competitionsAllowed, 'blockedCountries'), country.toUpperCase()))
    const accountLimitReached = getAllowedAccounts(accounts, companyObject, t, allowedAccountTypes || [], viewer)
    const showEuMigration = !!showTermsPopup && get(viewer, 'fromCompany') && !get(viewer, 'acceptEuMigrationTerms')
    const multipleReglation = get(viewer ,'multipleRegulation') && showPartnerMigration && !getItem('migrationPopupClosed')
    const hasBitnuk = find(filteredAccounts, (filteredAccount) => isBitnukAccount(filteredAccount) && !filteredAccount.balance)
    // const today = moment().format()

    return (
      <React.Fragment>
        {redirected && showRedirectionModal && !showEuMigration && <AlertDialog
          open={showRedirectionModal}
          title={t(messages.entityRedirectionHeader.i18nKey, {trademark: get(companyObject, 'trademark')})}
          children={<Typography variant="body1"> <Trans {...messages.entityRedirectionMessage} values={{currentEntity: companyObject.trademark}} /> </Typography>}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          onClose={() => this.hideRedirectionsModal()}
          onAgree={() => this.hideRedirectionsModal()}
        />}
        {showEuMigration && <MigrationToEuPopup />}
        {!showEuMigration && mifirCondition && <MifirPopup
          open={showMifirPopup}
          onClose={() => this.hideMifirPopup()}
          nationality={nationality}
        />}
        {!showEuMigration && cfdNoticeCondition && <CFDNoticePopup
          noticeId={'generalCFDDisclaimer'}
          open={showCFDNoticePopup}
          onClose={() => this.hideCFDNoticePopup()}
        />}
        {showRefreshNotification && <UiNotification
          open={true}
          status={'failure'}
          title={'refresh'}
          subTitle={<Trans {...messages.somethingWrongRefresh} />}
          type="page-not-found"
          buttonMessage={t(messages.refresh.i18nKey, messages.refresh.defaults)}
          onClose={() => window.location.reload()}
        >
          <Typography variant="caption">
            <Trans {...messages.somethingWrongRefreshText} />
          </Typography>
        </UiNotification>}
        {wlRedirection && <AlertDialog
          open={showRedirectionModal}
          title={t(messages.entityRedirectionHeader.i18nKey, messages.entityRedirectionHeader.defaults)}
          children={<Typography variant="body1"> <Trans {...messages.entityRedirectionMessage} values={{currentEntity: name}} /> </Typography>}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          onClose={()=> this.hideRedirectionsModal()}
          onAgree={()=> this.hideRedirectionsModal()}
        />}
        <AlertDialog
          open={showIBPopup}
          onClose={() => this.setState({showIBPopup: false})}
          title={t(messages.welcome.i18nKey, messages.welcome.defaults)}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          onAgree={() => this.setState({showIBPopup: false})}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages.ibAccountNotApproved} /></Typography>
              <Typography variant="body1"><Trans {...messages.ibAccountNotApprovedText2} values={{email: ibPartnersEmail}}/></Typography>
            </Grid>
          </Grid>
        </AlertDialog>
        <IbAccountSelectModal
          open={showIbAccountSelectionModal}
          onClose={() => this.setState({showIbAccountSelectionModal: false})}
          account={ibAccountSelected}
          onSelectAccount={(ibId) => this.toIBPortal(ibAccountSelected, ibId)}
        />
        {multipleReglation && <PartnerMigrationPopup client={viewer}/>}
        <AlertDialog
          open={showBitnukPopup}
          onClose={() => this.setState({showBitnukPopup: false})}
          title={t(messages.unlockWallet.i18nKey, {wallet: get(hasBitnuk, 'accountType.localization') && hasBitnuk.accountType.localization.t(locale)})}
          agreeText={t(messages.completeVerification.i18nKey, messages.completeVerification.defaults)}
          disagreeText={t(messages.close.i18nKey, messages.close.defaults)}
          onAgree={() => history.push('/accounts/verification')}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages.bitwalletBenefits} values={{company: companyObject.brandLabel}}/></Typography>
            </Grid>
            <ul>
              {map(get(accountCategoriesDetails, 'cryptoWallet.bitnuk.benefits'), (benefit) => <li key={benefit}>
                <Grid item xs={12}>
                  <Typography variant="body1"><Trans {...messages[benefit]}
                    values={{company: companyObject.brandLabel, wallet: get(hasBitnuk, 'accountType.localization') && hasBitnuk.accountType.localization.t(locale)}}/></Typography>
                </Grid>
              </li>)}
            </ul>
          </Grid>
        </AlertDialog>
        <PageTitle
          hideArrow
          title={t(messages[isMobile() ? 'accounts' : 'allAccounts'].i18nKey, messages[isMobile() ? 'accounts' : 'allAccounts'].defaults)}
          rightAction={this.renderLinks(showCompetitions)}
        >
          <Tabs
            TabIndicatorProps={{style: {background: '#06262E'}}}
            value={isEmpty(accounts) ? 0 : this.state.activeTab}
            onChange={(_, activeTab) => this.setState({activeTab})}
            classes={{root: classes.tabsRoot}}
            variant={!isMobile() ? 'standard' : 'fullWidth'}
          >
            <Tab label="Dashboard" className={classes.tab}/>
            {/*{_.map(categories, category => {*/}
            {/*  const accountTypeDetails = accountCategoriesDetails[category.key]*/}
            {/*  const dot = find(keys(accountTypeDetails), (accountType) => {*/}
            {/*    const accountTypeEndDate = moment(accountTypeDetails[accountType].startDate).add(accountTypeDetails[accountType].activeDays, 'd')*/}
            {/*    return moment(today).isSameOrBefore(moment(accountTypeEndDate).format(), 'day')*/}
            {/*  })*/}
            {/*  return <Tab className={classes.tab} key={category.key} label={(dot && hasBitnuk) ? <span>{category.label}<FiberManualRecordIcon className={classes.dot}/></span> : category.label} />*/}
            {/*}*/}
            {/*)}*/}
          </Tabs>
        </PageTitle>
        <PageBody>
          <Grid container spacing={0}>
            {showAccountCreatedMessage &&
              <Grid item xs={12}>
                <NotificationBar status='success'>
                  <Trans {...messages.createAccountSuccess}
                    components={[
                      <Link to={'/'} className={classes.textLink}>Dismiss</Link>
                    ]} /></NotificationBar>
              </Grid>
            }
            {!blockedDeposit && <ClientNotificationBar accounts={accounts} hasBitnuk={hasBitnuk}/>}
            <Grid item xs={12}>
              <CustomNotificationAlert global/>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={!isMobile() ? 3 : 1}>
                {filteredAccounts.map(account =>
                  <Grid item xs={12} sm={6} key={account.id}>
                    <AccountCard account={account} kycStatus={kycStatus} onClick={account => this.openAccount(account)} />
                  </Grid>
                )}
                {externalAccounts && externalAccounts.map((account) => <Grid item xs={12} sm={6} key={account.name}>
                  <ExternalAccountCard name={account.name} balance={account.balanceEur} onClick={() => this.openAccount({...account, external: true})} />
                </Grid>)}
              </Grid>
            </Grid>
            {accountLimitReached.length < 1 && isMobile() && <Grid item xs={12} className={classes.maxAccountsDiv}>
              <Typography variant="caption"><Trans {...messages.maximumAccounts} /></Typography>
              <Link to={'/support'} className={classes.textLink}><Trans {...messages.contactUs} /></Link>
            </Grid>}
          </Grid>
        </PageBody>
      </React.Fragment>
    )
  }
}


export default compose(
  withApollo,
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: (props) => {
      const accounts = filter(get(props.data, 'viewer.accounts'), (account: any) => !isPAMMFundManagerAccount(account)) as any
      const availableAccountTypeCategories = _.chain(accounts).map(account =>
        accountTypes[account.__typename].category
      ).uniq().value()
      const categories = _.chain(accountCategories).values().filter(category =>
        _.intersection(category.accountTypeCategories, availableAccountTypeCategories).length > 0
      ).value()
      return {
        error:props.data?.error,
        loading:props.data?.loading,
        accounts,
        categories,
      }
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: (props) => {
      const mifirId = get(props.data, 'viewer.mifirId')
      const mifirType = get(props.data, 'viewer.mifirType')
      const nationality = get(props.data, 'viewer.nationality')
      const country = get(props.data, 'viewer.address.country')
      const depositedAmount = get(props.data, 'viewer.depositedAmount')
      const registration = get(props.data, 'viewer.registration')
      const kycStatus = get(props.data, 'viewer.kycStatus')
      const spanishCfdNotice = get(props.data, 'viewer.signableNoticeAckSignatures', '')
      const appropTest = get(props.data, 'viewer.appropTests', '')
      const globalQuestionnaire = get(props.data, 'viewer.globalQuestionnaire', '')
      const competitions = get(props.data, 'viewer.competitions' ,'')
      const allowedAccountTypes = get(props.data, 'viewer.allowedAccountTypes' ,'')
      return {
        errorClient: props.data?.error,
        loadingClient: props.data?.loading,
        mifirId,
        mifirType,
        nationality,
        country,
        depositedAmount,
        registration,
        kycStatus,
        spanishCfdNotice,
        appropTest,
        globalQuestionnaire,
        competitions,
        allowedAccountTypes,
        viewer: get(props.data, 'viewer'),
      }
    }
  }),
  graphql(IB_SSO_LINK_MUTATION, {
    name: 'createIBSingleSignOnLink',
  }),
)(LydianRootAccounts)
