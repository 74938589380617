import React from 'react'
import PropTypes from 'prop-types'
import {graphql,withApollo} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import messages from '../../../assets/messages'
import {isMobile} from '../../../common/utils/browser'
import Typography from '@material-ui/core/Typography'
import Amount from '../../Common/Amount'
import {AlertDialog} from '../../Common/Dialog/index'
import {MT4Connector, FeedEvent} from '@bdswiss/mt4-connector'
import {config} from '../../../config'
import {withRouter, Link} from 'react-router-dom'
import {Loading} from '../../Common/Loading'
import Autorenew from '@material-ui/icons/Autorenew'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import {FOREX_POSITIONS_COUNT, FOREX_POSITIONS} from '../../../graphql/queries'
import ForexPositions from './ForexPositions'
import PageSubTitle from '../../Common/PageSubTitle'
import ForexTradeNow from './ForexTradeNow'
import {
  getItem,
  hasWebTrader,
  hideElementsDependedOfWL,
} from '../../../common/utils'
import Images from '../../Common/Images'
import {get, find, unionBy, reject, some, orderBy, union, assign, flowRight as compose} from 'lodash'
import AppContext from '../../Common/contexts/AppContext'
import classNames from 'classnames'
import {isMt5ForexAccount, isForexAccount} from '../../../common/utils/accounts'
import {accountTypes} from '@bdswiss/common-enums'
import {timeout} from 'rxjs/operators'
import NotificationBar from '../../Common/NotificationBar'
import {Fade} from '../../Common/Fade'
import {Tooltip} from '@material-ui/core'
import Help from '@material-ui/icons/HelpOutlineOutlined'
import {InnerAppContext} from '../../../common/types'

const fontRatio = isMobile() ? 0.5 : 1
const styles = theme => ({
  amount: {
    marginTop: isMobile() ? 2 : 10,
    display: 'block',
  },
  amountCurrency: {
    fontSize: Math.floor(24 * fontRatio),
    fontWeight: 200,
    padding: '0 3px',
  },
  amountValue: {
    fontSize: Math.floor(36 * fontRatio),
    fontWeight: 200,
  },
  amountFraction: {
    fontSize: Math.floor(24 * fontRatio),
    fontWeight: 200,
  },
  financialInfoItem: {
    marginBottom: 20,
    color: theme.palette.secondary.dark,
  },
  noTradesIcon: {
    maxWidth: 180,
    minWidth:120,
    width:'75%'
  },
  noPositionsLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  buttonProgress:{
    color: theme.palette.primary.main,
  },
  buttonProgressCenter: {
    textAlign: 'center' as const
  },
  refetchButton: {
    textAlign: 'right' as const
  },
  marginLevelAmount:{
    marginTop:isMobile() ? 2 : 10,
  },
  error: {
    color: theme.palette.red.color,
  },
  success: {
    backgroundColor: theme.palette.green.color,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  textLeft:{
    textAlign: 'left' as const
  },
  helpIcon: {
    color: theme.palette.secondary.main,
    marginLeft: 5,
    fontSize: 14,
    background:'none',
  },
  displayInline: {
    display: 'inline-flex',
  },
  customTooltip:{
    fontSize: 12,
  },
  helpBtn:{
    padding: 0,
    '&:hover':{
      background:'none'
    }
  },
})

class ForexTradingInfo extends React.Component<any,any> {
  static propTypes = {
    account: PropTypes.object.isRequired,
    status:  PropTypes.oneOf(['open', 'closed', 'pending']).isRequired,
  }
  static contextType = AppContext
  context!: InnerAppContext

  constructor(props) {
    super(props)
    this.state = {
      mt4: MT4Connector.Instance,
      openCloseOrderModal: false,
      closeOrderModalError: false,
      closeOrderModalErrorMessage: '',
      showNotification: false,
      mt4Success: false,
      open: false,
    }
  }


  closeOrderModalClose() {
    this.setState({openCloseOrderModal: false})
  }

  componentDidMount() {
    const {productConfigs:{forex:{mt4SocketUrl}}}= config
    const {account} = this.props
    const accountSocketUrl = account.metaTraderApiUrl || mt4SocketUrl
    const login = {login: account.login,server: account.isDemo ? 'demo' as const : 'real' as const,version: 3,platform: 'web'}
    const mt4= MT4Connector.Instance
    const feed = mt4.getFeed()
    this.setState({subscription:feed.subscribe(this.processFeedMessages)})
    mt4.connect(accountSocketUrl, login)
  }

  componentWillUnmount() {
    const {subscription, mt4} = this.state
    subscription && subscription.unsubscribe()
    mt4.disconnect()
  }

  processFeedMessages = (message) => {
    const {type, payload} = message


    if (type === FeedEvent.BALANCE_UPDATE) {
      const {balance, freeMargin, equity, margin, credit} = payload
      this.setState({mt4Balance : credit > 0 ? balance - credit : balance,mt4FreeMargin: freeMargin,mt4Equity:equity, mt4Margin: margin, mt4Bonus: credit})
    }
    if (type === FeedEvent.LOGIN_SUCCESS) {
      this.setState({positions : orderBy(payload.trades,'order'), pendingPositions : orderBy(payload.pendingOrders,'order'), mt4Success : true})
    }

    if (type === FeedEvent.PRICES) {
      this.setState({assets: payload})
    }

    if (type === FeedEvent.PRICES_UPDATE) {
      const mergedAssets = assign(this.state.assets,payload)
      this.setState({assets : mergedAssets})
    }

    if (type === FeedEvent.ORDER_CLOSED) {
      const mergedClosed = orderBy(union([payload],this.state.closedPositions), 'order')
      this.setState({closedPositions : mergedClosed})
    }

    if (type === FeedEvent.POSITIONS_UPDATE) {
      const currentPositions = reject(this.state.positions, (activePositions) =>
        some(this.state.closedPositions, {order: activePositions.order} )
      )

      const merged = orderBy(unionBy(payload,currentPositions, 'order'), 'order')
      this.setState({positions : merged})
    }

    if (type === FeedEvent.PENDING_ORDER_CANCELED ) {
      const currentPendingPositions = reject(this.state.pendingPositions, (activePositions) =>
        payload.order === activePositions.order
      )
      this.setState({pendingPositions : currentPendingPositions})
    }

    if (type === FeedEvent.PENDING_ORDER_OPENED) {
      const mergedPending = orderBy(unionBy([payload],this.state.pendingPositions, 'order'), 'order')
      this.setState({pendingPositions : mergedPending})
    }

    if (type === FeedEvent.PENDING_ORDER_UPDATED) {
      const mergedPending = orderBy(unionBy([payload],this.state.pendingPositions, 'order'), 'order')
      this.setState({pendingPositions : mergedPending})
    }

    if (type === FeedEvent.PENDING_ORDER_ACTIVATED ) {
      const currentPendingPositions = reject(this.state.pendingPositions, (activePositions) =>
        payload.order === activePositions.order
      )
      this.setState({pendingPositions : currentPendingPositions})
    }
  }

  orderClose(order, status) {
    this.setState({openCloseOrderModal : true, orderNumber: order, orderStatus: status})
  }

  orderCloseConfirmation() {
    const {mt4, orderNumber, orderStatus} = this.state;
    (orderStatus === 'pending')
      ? mt4
        .cancelPendingOrder({order: orderNumber})
        .pipe(
          timeout(2000)
        )
        .subscribe((result)=> {
          this.setState({openCloseOrderModal : false, showNotification: true})
          setTimeout(() => {this.setState({showNotification: false})}, 5000)
        }, (error) => {
          this.setState({openCloseOrderModal : false, closeOrderModalError: true, closeOrderModalErrorMessage: error.message, showNotification: true})
          setTimeout(() => {this.setState({showNotification: false})}, 5000)
        })
      : mt4
        .closeOrder({order: orderNumber})
        .pipe(
          timeout(2000)
        )
        .subscribe((result)=> {
          this.setState({openCloseOrderModal : false, showNotification: true})
          setTimeout(() => {this.setState({showNotification: false})}, 5000)
        }, (error) => {
          this.setState({openCloseOrderModal : false, closeOrderModalError: true, closeOrderModalErrorMessage: error.message, showNotification: true})
          setTimeout(() => {this.setState({showNotification: false})}, 5000)
        })
  }

  refetchPositions (status) {
    this.props.refetchPositions({
      id: Number(this.props.match.params.accountId),
      status: status,
      limit: status === 'closed' ? 10 : 100,
      offset: 0}).then((res) => {
    }).catch((e) => {
      console.log(e) //eslint-disable-line no-console
    })
  }

  bonusTooltip() {
    const {account} = this.props
    return (<React.Fragment>
      <Grid container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}>
        <Grid item xs={9}><Trans {...messages.requiredLots}/></Grid>
        <Grid item xs={3}>{get(account, 'bonus.requiredLots')}</Grid>
        <Grid item xs={9}><Trans {...messages.tradedLots}/></Grid>
        <Grid item xs={3}>{get(account, 'bonus.lotsTraded')}</Grid>
      </Grid>
    </React.Fragment>)
  }

  handleTooltipClose = () => {
    this.setState({open: false})
  }

  handleTooltipToggle = () => {
    this.setState(state => ({open: !state.open}))
  }

  render() {
    const {classes,account,loading, status:positionsType, match: {url}, allPositionsLoading,
      positionsClosedCount, positionsOpenedCountLoading, positionsOpenedCount, t} = this.props
    const {mt4, positions, openCloseOrderModal, closeOrderModalError, closeOrderModalErrorMessage,showNotification,
      orderNumber, mt4Success, pendingPositions, assets} = this.state
    const {locale, company, themePreference} = this.context
    const {downloadTrader, showMT4DownloadLink} = config
    const percentFormatter = new Intl.NumberFormat({
      style: 'percent', maximumFractionDigits: 0,
    } as any)

    if (loading && positionsOpenedCountLoading) return <Loading />

    const downloadTraderLabel = accountTypes[account.__typename] && isForexAccount(account) ?
      downloadTrader[accountTypes[account.__typename].category].shortLabel : downloadTrader.default.shortLabel

    const positionsCount = mt4Success ? mt4.positions.size : positionsOpenedCount
    const pendingPositionsCount = mt4Success ? mt4.pendingOrders.size : 0

    let mt4Balance,mt4FreeMargin,mt4Equity, mt4Margin, mt4Bonus
    if (mt4Success) {
      mt4Balance = this.state.mt4Balance
      mt4FreeMargin = this.state.mt4FreeMargin
      mt4Equity = this.state.mt4Equity
      mt4Margin = this.state.mt4Margin
      mt4Bonus = this.state.mt4Bonus
    } else {
      mt4Balance = account.balance
      mt4FreeMargin = account.balance
      mt4Equity = account.balance
      mt4Bonus = account.credit
    }

    const selectedPosition = find((positionsType === 'pending') ? pendingPositions : positions,(p) => p.order===orderNumber)
    const positionsList = positionsType === 'pending' ? pendingPositions : positions
    const showDepositNowBtn = hideElementsDependedOfWL()

    return (
      <React.Fragment>
        <Fade in={showNotification} timeout={{
          appear: 1000,
          enter: 1000,
          exit: 1000,
        }}>
          <NotificationBar status={closeOrderModalError ? 'error' : 'success'}>
            {orderNumber}: {closeOrderModalError ? <React.Fragment><Trans {...messages.pageError}/>: {closeOrderModalErrorMessage}</React.Fragment> : <Trans {...messages.orderClosed} />}
          </NotificationBar>
        </Fade>
        <AlertDialog
          open={openCloseOrderModal}
          onClose={() => this.closeOrderModalClose()}
          onAgree={() => this.orderCloseConfirmation()}
          onDisagree={() => this.closeOrderModalClose()}
          title={t(messages.closingPosition.i18nKey, messages.closingPosition.defaults)}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          agreeText={t(messages.confirm.i18nKey, messages.confirm.defaults)}
        >
          <Grid container direction="row" alignContent="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.popupText}>
                <Trans {...messages.closeOrderConfirmation} />
              </Typography>
              <Typography variant="body1" className={classes.popupText}>
                {orderNumber}: {selectedPosition && selectedPosition.symbol}
              </Typography>
            </Grid>
          </Grid>
        </AlertDialog>
        <Grid container spacing={3}>
          {(positionsType === 'closed' || isMt5ForexAccount(account.__typename)) &&
          <Grid item xs={12} className={classes.refetchButton}>
            <Button color={'primary'} onClick={()=> this.refetchPositions(positionsType)}><Autorenew /></Button>
          </Grid>}
          <Grid item xs={12} className={!isMobile()? classes.buttonProgressCenter : ''}>
            {allPositionsLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {((positionsCount > 0 && positionsType === 'open') || (pendingPositionsCount > 0 && positionsType === 'pending') ||
              (positionsType === 'closed' && positionsClosedCount > 0)) && !allPositionsLoading &&
              <ForexPositions account={account} onClosePosition={(order) => this.orderClose(order, positionsType)}
                status={positionsType} enablePagination={positionsType === 'closed'}
                positions={((positionsType !== 'closed') && !isMt5ForexAccount(account.__typename)) ? positionsList : false }
                assets={assets}
              />
            }
            {((positionsCount === 0 && positionsType === 'open')  || (pendingPositionsCount === 0 && positionsType === 'pending') ||
              (positionsType === 'closed' && positionsClosedCount === 0)) &&
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={6}><img className={classes.noTradesIcon} src={Images[`no-trades-${themePreference}.png`]} alt='' /></Grid>
                    <Grid item xs={6}>
                      <Typography variant='body1' className={classes.textLeft}><Trans {...messages[`tradesEmpty_${positionsType}`]} /></Typography>
                      <Grid container spacing={0} alignItems="center">
                        {mt4Balance > 0 && showMT4DownloadLink && <React.Fragment>
                          {!isMt5ForexAccount(account.__typename) && hasWebTrader() && <Grid item xs={12}  sm={6}>
                            <ForexTradeNow type='link' account={account} company={company} locale={locale || getItem('locale', 'en')} alignLeft/>
                          </Grid>}
                          <Grid item xs={12} sm={6}>
                            <Link to={`${url}/downloadTrader`} >
                              <Typography variant="body2" color="primary" className={classes.textLeft}>
                                <Trans {...messages.downloadMT4} values={{product:downloadTraderLabel}}/>
                              </Typography>
                            </Link>
                          </Grid>
                        </React.Fragment>
                        }
                        {mt4Balance <= 0 && !showDepositNowBtn &&
                          <Grid item xs={12} sm={6}>
                            <Link to={`/transactions/${account.id}/deposit`} >
                              <Typography variant="body2" color="primary" className={classes.textLeft}>
                                <Trans {...messages.depositNow}/>
                              </Typography>
                            </Link>
                          </Grid>}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <PageSubTitle ><Trans {...messages.financialInfo} /></PageSubTitle>
            <Grid container>
              <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.balance} /></Typography>
                <Amount
                  value={mt4Balance || 0} currency={account.currency} locale='en-US' classes={{
                    root: classes.amount,
                    currency: classes.amountCurrency,
                    value: classes.amountValue,
                    fraction: classes.amountFraction
                  }} />
              </Grid>
              <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.freeMargin} /></Typography>
                <Amount
                  value={mt4FreeMargin || 0} currency={account.currency} locale='en-US' classes={{
                    root: classes.amount,
                    currency: classes.amountCurrency,
                    value: classes.amountValue,
                    fraction: classes.amountFraction
                  }} />
              </Grid>
              <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.equity} /></Typography>
                <Amount
                  value={mt4Equity || 0} currency={account.currency} locale='en-US' classes={{
                    root: classes.amount,
                    currency: classes.amountCurrency,
                    value: classes.amountValue,
                    fraction: classes.amountFraction
                  }} />
              </Grid>
              <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.marginLevel} /></Typography>
                {mt4Margin ?
                  <div className={classNames(classes.marginLevelAmount,classes.amountValue)}>{percentFormatter.format(mt4Equity*100/mt4Margin)}%</div> :
                  <div className={classNames(classes.marginLevelAmount,classes.amountValue)}>-</div>}
              </Grid>
              {mt4Bonus > 0 && <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant="caption" className={classes.displayInline}> <Trans {...messages.bonus} /> </Typography>
                <Tooltip
                  open={this.state.open}
                  title={this.bonusTooltip()}
                  placement="top-start"
                  classes={{tooltip: classes.customTooltip}}
                >
                  <span className={classes.helpBtn}
                    onMouseEnter={this.handleTooltipToggle}
                    onMouseLeave={this.handleTooltipToggle}
                  >
                    <Help className={classes.helpIcon}/>
                  </span>
                </Tooltip>
                <Amount
                  value={mt4Bonus || 0} currency={account.currency} locale='en-US' classes={{
                    root: classes.amount,
                    currency: classes.amountCurrency,
                    value: classes.amountValue,
                    fraction: classes.amountFraction
                  }} />
              </Grid>}
              <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.openPositions} /></Typography>
                <div className={classNames(classes.marginLevelAmount,classes.amountValue)}>{positionsCount}</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}


export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  withApollo,
  withRouter,
  graphql(FOREX_POSITIONS_COUNT, {
    options: (props:any) => ({variables: {id: Number(props.match.params.accountId), status: 'closed'}, fetchPolicy: 'network-only'}),
    props: ({data: {error, loading: positionsClosedCountLoading}, data}:any) => {
      const positionsClosedCount = get(data, 'viewer.accounts.0.forexPositionsCount', 0)
      return {
        error,
        positionsClosedCountLoading,
        positionsClosedCount,
      }
    }
  }),
  graphql(FOREX_POSITIONS_COUNT, {
    options: (props:any) => ({variables: {id: Number(props.match.params.accountId), status: 'open'}, fetchPolicy: 'network-only'}),
    props: ({data: {error, loading: positionsOpenedCountLoading}, data}:any) => {
      const positionsOpenedCount = get(data, 'viewer.accounts.0.forexPositionsCount', 0)
      return {
        error,
        positionsOpenedCountLoading,
        positionsOpenedCount,
      }
    }
  }),
  graphql(FOREX_POSITIONS, {
    options: (props:any) => ({
      variables: {id: Number(props.match.params.accountId), status: props.status, limit: 1, offset: 0},
      fetchPolicy: 'network-only'
    }),
    props: ({data: {viewer, error, loading: allPositionsLoading}, data}:any) => {
      const allPositions = get(viewer, 'accounts.0.forexPositions') || []
      return {
        error,
        allPositionsLoading,
        allPositions,
        refetchPositions: data.refetch
      }
    }
  })
)(ForexTradingInfo)
