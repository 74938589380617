import React from 'react'
import {config} from '../../../config'
import {getItem} from '../../../common/utils'
import {findCompany, getCurrentTheme} from '../../../common/utils/general'

export type Account = {
  id: number
}
// @todo this AppContext not actually being used, but it references the type
const AppContext = React.createContext({
  showNotification: (data: any) => {},
  locale: getItem('locale', 'en') as string,
  themePreference: getCurrentTheme() as any,
  company: config.key,
  companyObject: findCompany(),
  toggleTheme: (x,y) => {}
})

export default AppContext
