import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'

const PWClickParamRoute = props => {
  const {search} = useLocation()
  const urlQuery = new URLSearchParams(search)
  const statusParam = urlQuery.get('status')

  const accountId = props.match.params.accountId

  const status = {
    completed: 'completed',
    cancelled: 'cancelled',
    pending: 'pending',
    failed: 'failed',
  }

  return <Redirect to={`/transactions/${accountId}/deposit/result/${status[statusParam]}`} />
}

export default PWClickParamRoute
