import React from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import PersonalProfile from './PersonalProfile/'
import Documents from '../Documents'
import AppropriatenessTest from './AppropriatenessTest'
import DueDiligence from './DueDiligence'
import SpoaAuthorization from '../SpoaAuthorization'
import Profile from './index'
import PortfolioManagement from './PortfolioManagement'
import {config} from '../../../config'
import LydianProfile from '../../RedesignedWLComponents/Lydian/RootLydianProfile'

const {key} = config

let StyledProfileWl

switch (key) {
  case 'lydian':
    StyledProfileWl = LydianProfile
    break
  default:
    StyledProfileWl = Profile
}

const ProfileRoutes = ({match: {path}}) => (
  <Switch>
    <Route path={`${path}/personal-profile`} component={PersonalProfile} />
    <Route path={`${path}/documents`} component={Documents} />
    <Route path={`${path}/appropriateness-test`} component={AppropriatenessTest} />
    <Route path={`${path}/due-diligence`} component={DueDiligence} />
    <Route path={`${path}/portfolio-management`} component={PortfolioManagement} />
    <Route path={`${path}/spoa-authorization`} component={SpoaAuthorization} />
    <Route path={`${path}`} component={StyledProfileWl} />
  </Switch>
)

export default withRouter(ProfileRoutes)
