import React, {Component} from 'react'
import {withApollo, graphql} from 'react-apollo'
import {LinearProgress} from '@material-ui/core'
import {Redirect} from 'react-router-dom'
import {get, filter, isEmpty, orderBy, find, capitalize, flowRight as compose, includes, map} from 'lodash'
import {logoutRequest} from '../../common/utils/requests'
import {removeSessInfoFromLocalStorage, storeItem, setCookie, getCookie, isMobile} from '../../common/utils'
import {CLIENT_DATA_QUERY, ACCOUNTS_QUERY} from '../../graphql/queries'
import {BugsnagErrorBoundary} from '../../bugsnag'
import {socketManager} from '../../socketManager'
import ErrorPage from '../Common/ErrorPage'
import AppContext from '../Common/contexts/AppContext'
import {getItem} from '../../common/utils/localStorage'
import Desktop from './Desktop'
import Mobile from './Mobile'
import i18nApp from '../../i18n'
import {hasOnlyDepositBlockedAccounts, hasIntroducingBrokerAccount, isIntroducingBrokerAccount,
  isAffiliatesAccount, hasAffiliateAccount, hasOnlyWalletProductAccount} from '../../common/utils/accounts'
import {accountTypes, companies, countries, languages} from '@bdswiss/common-enums'
import {config} from '../../config'
import {findCompany, getCurrentTheme, getLocaleMoment} from '../../common/utils/general'
import {AlertDialog} from '../Common/Dialog'
import messages from '../../assets/messages'
import Typography from '@material-ui/core/Typography'
import {Trans, withNamespaces} from 'react-i18next'
import moment from 'moment'
import queryString from 'querystring'
import {InnerAppContext} from '../../common/types'

class App extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  state = {
    notification: null,
    openPopup: false,
    document: '',
    openTemporaryCeasingPopup: true
  } as any

  componentDidMount() {
    // @ts-ignore
    window.addEventListener('resize', this.hideSidebar)
    if (config.gTagContainerId) {
      //tagmanager body noscript
      const gtmExternal = document.createElement('noscript')
      const gtmIframe = document.createElement('iframe')
      gtmIframe.src=`https://www.googletagmanager.com/ns.html?id=${config.gTagContainerId}`
      gtmIframe.height='0'
      gtmIframe.width='0'
      gtmIframe.setAttribute('style','display:none;visibility:hidden')
      gtmExternal.appendChild(gtmIframe)

      //tagmanager head script
      const gtmElement = document.createElement('script')
      gtmElement.id = 'gtm-script'
      gtmElement.type = 'text/javascript'
      gtmElement.text = `
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
			j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
			'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${config.gTagContainerId}');`
      document.getElementsByTagName('head')?.item(0)?.appendChild(gtmElement)
      document.getElementsByTagName('body')?.item(0)?.appendChild(gtmExternal)
    }

    if (config.googleMapKey) {
      //googleMap script
      const googleMapElement = document.createElement('script')
      googleMapElement.id = 'gmap-script'
      googleMapElement.type = 'text/javascript'
      googleMapElement.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapKey}&libraries=places`
      document.getElementsByTagName('head')?.item(0)?.appendChild(googleMapElement)
    }
  }

  componentWillUnmount() {
    // @ts-ignore
    window.removeEventListener('resize', this.hideSidebar)
  }

  beforeSendErrorReport(report) {
    const {viewer} = this.props
    const clientId = get(viewer, 'id')
    report.user = {id: clientId}
  }

  handleLogout = (forceDocument?) => {
    const {client: apolloClient} = this.props
    if (forceDocument)
      this.setState({openPopup: true, forceDocument})
    else
      logoutRequest().then(() => {
        setCookie('missing_approp_test', false, -1)
        setCookie('missing_economic_profile', false, -1)
        setCookie('approp_test_notification', false, -1)
        removeSessInfoFromLocalStorage()
        window.liveChat && window.LC_API?.hide_chat_window()
        this.setState({loggedOut: true})
        socketManager.deinitialize()
        return apolloClient && apolloClient.clearStore()
      })
        .catch(console.error) // eslint-disable-line no-console
  }

  checkDownloadEbook(ebookRedirect, euMigrationRedirect) {
    if (euMigrationRedirect) return
    const {location} = this.props
    if (ebookRedirect && location.pathname!=='/tools/ebook') {
      return <Redirect to={{pathname: '/tools/ebook', state:{from: location, locationTrack: 'Registration'}}}/>
    }

  }

  checkEmailVerification(ebookRedirect, euMigrationRedirect) {
    if (euMigrationRedirect) return
    const {location, viewer} = this.props
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const clientEmail = get(viewer, 'email')

    if (!emailConfirmed &&
      (emailVerificationRequired && location.pathname!=='/email-verification-needed') &&
      location.pathname.indexOf('/confirm-email') === -1)
      return <Redirect to={{pathname: '/email-verification-needed', state:{from: location, email: clientEmail}}}/>
  }

  checkPhoneVerification(ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return
    const {location, viewer} = this.props
    const phoneVerified = get(viewer, 'isPhoneVerified')
    const isPhoneVerificationRequired = get(viewer, 'isPhoneVerificationRequired')

    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')

    const emailVerificationDone = ((emailConfirmed && emailVerificationRequired) || !emailVerificationRequired) && location.pathname.indexOf('/confirm-email') === -1

    if ((isPhoneVerificationRequired && !phoneVerified && location.pathname!=='/smsverification') && emailVerificationDone)
      return <Redirect to={{pathname: '/smsverification', state:{from: location}}}/>

  }

  checkAppropTest(companyObject, verificationFields, ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return

    const {location, viewer} = this.props
    const phoneVerified = get(viewer, 'isPhoneVerified')
    const isPhoneVerificationRequired = get(viewer, 'isPhoneVerificationRequired')
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const phoneEmailVerificationDone = ((isPhoneVerificationRequired && phoneVerified) || (!isPhoneVerificationRequired)) &&
    ((emailConfirmed && emailVerificationRequired) || !emailVerificationRequired) && location.pathname.indexOf('/confirm-email') === -1
    const termsUpdateSeen = (getItem('termsUpdate') && getItem('termsUpdateSeen')) || !getItem('termsUpdate')

    const forcedVerification = get(viewer, 'verificationActions.forcedVerification')
    const appropTest = get(viewer, 'appropTests')
    const globalQuestionnaire =get(viewer, 'globalQuestionnaire')

    const accountVerificationByEntity = companies[companyObject.key].accountVerification

    const forceAppropTest = forcedVerification === 'appropriatenessTest' && verificationFields.indexOf('appropriatenessTest') > 0
    const forceEconomicProfile = forcedVerification === 'economicProfile' && verificationFields.indexOf('economicProfile') > 0

    const exceptionPage = location.pathname === '/support'

    if (companies[companyObject.key].requiresAPTest && verificationFields.indexOf('appropriatenessTest') > 0) {
      if (isEmpty(appropTest) && (isEmpty(getCookie('missing_approp_test'))  || forceAppropTest) ) {
        setCookie('missing_approp_test', true, 1)
      } else {
        setCookie('missing_approp_test', false, 1)
      }
    }

    const showEconomicProfile = accountVerificationByEntity && accountVerificationByEntity.economicProfile && !isEmpty(accountVerificationByEntity.economicProfile)
    if (showEconomicProfile) {
      if (!globalQuestionnaire && (isEmpty(getCookie('missing_economic_profile')) || forceEconomicProfile) ) {
        setCookie('missing_economic_profile', true, 1)
      } else {
        setCookie('missing_economic_profile', false, 1)
      }
    }

    const appropTestCompleted = (!isEmpty(appropTest) && getCookie('approp_test_completed_step1') && getCookie('approp_test_completed')) ||
       (!isEmpty(appropTest) && !getCookie('approp_test_completed_step1'))
    const appTestPath = '/settings/profile/appropriateness-test'
    const economicPath = '/settings/profile/personal-profile/economic'
    const appTestNotification = getCookie('approp_test_notification')

    if (phoneEmailVerificationDone && termsUpdateSeen &&
      (location.pathname!==appTestPath && (forceAppropTest)) && !exceptionPage ) {
      return <Redirect to={{pathname: appTestPath, state: {force: forceAppropTest}}} />
    }
    else if (phoneEmailVerificationDone &&
      (location.pathname !== economicPath && forceEconomicProfile
      && !forceAppropTest && appropTestCompleted) && !exceptionPage
      && (!appTestNotification || (appTestNotification && location.pathname!==appTestPath))) {
      setCookie('approp_test_completed', true, (1/(24*60)))
      setCookie('approp_test_completed_step1', true, (1/(24*60)))
      return <Redirect to={{pathname: economicPath, state: {force: forceEconomicProfile}}} />
    } else  if (includes([appTestPath, economicPath], get(location, 'state.redirectTo')) && get(location, 'state.prevPath') === '/login') {
      return <Redirect to={{pathname: '/accounts'}}/>
    }
  }

  checkSubscriptionRedirect(ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return
    const {location, viewer, accounts} = this.props
    const phoneVerified = get(viewer, 'isPhoneVerified')
    const isPhoneVerificationRequired = get(viewer, 'isPhoneVerificationRequired')
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const forcedVerification = get(viewer, 'verificationActions.forcedVerification')
    const phoneEmailVerificationDone = ((isPhoneVerificationRequired && phoneVerified) || (!isPhoneVerificationRequired)) &&
    (((emailConfirmed && emailVerificationRequired) || !emailVerificationRequired) && location.pathname.indexOf('/confirm-email') === -1) && !forcedVerification

    if (!getItem('subscription_redirect') && phoneEmailVerificationDone) {
      const rawAccount = filter(accounts, account => (accountTypes[account['__typename']].subscription && isEmpty(account.subscription)))

      if (!isEmpty(rawAccount) && location.pathname.indexOf('plan') === -1) {
        storeItem('subscription_redirect', true)
        return <Redirect to={{pathname: `/accounts/${rawAccount[0]['id']}/plan/payment`, state:{rawAccount}}}/>
      }
    }
  }

  checkDueDiligenceRedirect(ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return
    const {location, viewer, accounts} = this.props
    const phoneVerified = get(viewer, 'isPhoneVerified')
    const isPhoneVerificationRequired = get(viewer, 'isPhoneVerificationRequired')
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const forcedVerification = get(viewer, 'verificationActions.forcedVerification')
    const phoneEmailVerificationDone = ((isPhoneVerificationRequired && phoneVerified) || (!isPhoneVerificationRequired)) &&
    (((emailConfirmed && emailVerificationRequired) || !emailVerificationRequired) && location.pathname.indexOf('/confirm-email') === -1) && !forcedVerification

    const dueDiligences = get(viewer, 'dueDiligences')
    const ibAccount = hasIntroducingBrokerAccount(accounts) && find(accounts, (__typename) => isIntroducingBrokerAccount(__typename))
    const ibApproved = get(ibAccount, 'approved', false)

    const affiliateAccount = hasAffiliateAccount(accounts) && find(accounts, (__typename) => isAffiliatesAccount(__typename))
    const affiliateApproved = get(affiliateAccount, 'serviceFields.affiliateStatus', false) === 'Approved'
    const dueDiligencePath = '/settings/profile/due-diligence'
    const dueDilligenceRequired = (config.common.dueDilligenceRequired.affiliate && (affiliateAccount && !affiliateApproved))
      || (config.common.dueDilligenceRequired.ib && (ibAccount && !ibApproved))

    if (!getItem('ibduediligence_redirect') && phoneEmailVerificationDone && dueDilligenceRequired && isEmpty(dueDiligences)
    && location.pathname.indexOf(dueDiligencePath) === -1) {
      storeItem('ibduediligence_redirect', true)
      return <Redirect to={{pathname: dueDiligencePath, state: {force: hasOnlyWalletProductAccount(accounts)}}}/>
    } else if (get(location, 'state.prevPath') === '/login' && get(location, 'state.redirectTo') === dueDiligencePath) {
      return <Redirect to={{pathname: '/accounts'}}/>
    }
  }

  checkClientNoticesRedirect(ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return
    const {location, viewer} = this.props
    const phoneVerified = get(viewer, 'isPhoneVerified')
    const isPhoneVerificationRequired = get(viewer, 'isPhoneVerificationRequired')
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const phoneEmailVerificationDone = ((isPhoneVerificationRequired && phoneVerified) || (!isPhoneVerificationRequired)) &&
    ((emailConfirmed && emailVerificationRequired) || !emailVerificationRequired) && location.pathname.indexOf('/confirm-email') === -1

    const pendingNotices = get(viewer, 'pendingNotices')
    const notice = pendingNotices && orderBy(pendingNotices, (n) => n.id, ['desc']).filter((n) =>
      (((n.type==='termsChange') || n.type==='partners')) || (n.type==='informative' && isEmpty(n.acknowledgements)))
    if (phoneEmailVerificationDone && !isEmpty(pendingNotices) && !isEmpty(notice) && !getItem('termsUpdate') && !isEmpty(notice[0].content)) {
      storeItem('termsUpdate', true)
      return <Redirect to={{pathname: '/clientNotice', state: {notice: notice}}}/>
    }
  }

  checkEuMigration(euMigrateRedirect) {
    const {location} = this.props
    if (euMigrateRedirect && location.pathname!=='/accounts' && location.pathname.indexOf('/support') === -1)
      return <Redirect to={{pathname: '/accounts'}}/>
  }

  showNotification = ({type, status, content, buttonMessage, onClose, hideButton, subTitle, buttonAction,
    linkAction, linkActionMessage}) => this.setState({
    notification: {
      type,
      status,
      content,
      buttonMessage,
      onClose,
      hideButton,
      subTitle,
      buttonAction,
      linkAction,
      linkActionMessage
    }
  })

  render() {
    const {props: {loading, error, viewer, accounts, loadingAccounts}} = this
    const {loggedOut, notification, openPopup, forceDocument, openTemporaryCeasingPopup} = this.state
    const {location, t, location:{state, search}} = this.props
    const {key, productConfigs, accountVerification, temporaryCeasingPopup, euRegulation: {showTermsPopup}} = config

    if (loggedOut) {
      return (
        <Redirect to={{
          pathname: '/login',
          state: {from: location, loggedOut}
        }} />
      )
    }

    if (loading ||loadingAccounts) {
      document.body.classList.add('loadingApp')
      return <LinearProgress />
    } else {
      document.body.classList.remove('loadingApp')
    }

    // TODO: delete? Not used anymore per tasks SKYG-1082 and SKYG-1238
    // if (accounts && isEmpty(accounts)) {
    //   this.handleLogout()
    //   return null
    // }

    const viewerType = get(viewer, '__typename')

    if (error || (viewerType && viewerType !== 'Client')) {
      if (get(error, 'networkError.result.data.redirectUrl')) {
        const url = new URL(get(error, 'networkError.result.data.redirectUrl'))
        //@ts-ignore
        window.location = url.origin + location.pathname + url.search
        return null
      }
      return <Redirect to={{pathname: '/login', state:{from: location, isError: true}}}/>
    }

    if (get(viewer, 'pendingLoginVerification') && location.pathname.indexOf('/choose-entity') === -1) {
      return <Redirect to={{pathname: '/login-verification'}} />
    }

    let locale = get(viewer, 'locale') || getItem('locale', 'en')

    if (!includes(map(filter(languages, (lang) => !lang.disabled && !!lang.client), 'value'), locale)) locale = 'en'

    moment.locale(getLocaleMoment(locale))
    let themePreference = getCurrentTheme(viewer)
    const cookieDomain = window.location.hostname.match(/[^.]+\.\w+$/) ? //@ts-ignore
      window.location.hostname.match(/[^.]+\.\w+$/)[0] : window.location.hostname
    setCookie('theme', capitalize(getCurrentTheme()), (5/(24*60)), cookieDomain)

    const tradingStatus = get(viewer, 'tradingStatus')
    const tradingStatusReasonCode = get(viewer, 'tradingStatusReasonCode')

    const blockedDeposit = hasOnlyDepositBlockedAccounts(accounts)
    const clientType = blockedDeposit ? accountTypes[accounts[0]?.__typename]?.subCategory
    || accountTypes[accounts[0]?.__typename]?.category : get(viewer, 'clientType')

    let verificationFields
    if (blockedDeposit && clientType && productConfigs[clientType]) {
      verificationFields  = productConfigs[clientType].accountVerification
    } else {
      verificationFields = accountVerification
    }

    if (locale !== getItem('locale'))
    {
      storeItem('locale',locale)
      i18nApp.changeLanguage(locale, this.context)
    }

    if (themePreference !== getItem('themePreference') && getItem('themePreference') !==  getItem('pendingPreference'))
    {
      storeItem('themePreference',themePreference)
      this.context.toggleTheme(themePreference)
    } else {
      themePreference = getCurrentTheme()
      if (this.context.themePreference !== themePreference) {
        this.context.toggleTheme(themePreference)
      }
    }

    socketManager.initialize(get(viewer, 'id'))

    const companyObject = findCompany()
    const queryParams = get(state, 'queryParams')
      || queryString.parse(search.replace('?', ''))
    const ebookParam = get(queryParams, 'ebook')
    !isEmpty(ebookParam) && storeItem('ebook',ebookParam)
    const ebookRedirect = !isEmpty(getItem('ebook'))

    const euMigrateRedirect = !!showTermsPopup && get(viewer, 'euMigration') && !get(viewer, 'acceptEuMigrationTerms')

    const euMigrationRedirect = this.checkEuMigration(euMigrateRedirect)
    if (euMigrationRedirect) return euMigrationRedirect

    const emailVerificationRedirect = this.checkEmailVerification(ebookRedirect, euMigrateRedirect)
    if (emailVerificationRedirect) return emailVerificationRedirect

    const ebookDownloadRedirect = this.checkDownloadEbook(ebookRedirect, euMigrateRedirect)
    if (ebookDownloadRedirect) return ebookDownloadRedirect

    const phoneVerificationRedirect = this.checkPhoneVerification(ebookRedirect, euMigrateRedirect)
    if (phoneVerificationRedirect) return phoneVerificationRedirect

    if (location.pathname.indexOf('/settings/profile/portfolio-management') === -1) {
      const checkClientNoticesRedirect = this.checkClientNoticesRedirect(ebookRedirect, euMigrateRedirect)
      if (checkClientNoticesRedirect) return checkClientNoticesRedirect

      const complianceRedirect = this.checkAppropTest(companyObject, verificationFields, ebookRedirect, euMigrateRedirect)
      if (complianceRedirect) return complianceRedirect

      const ibDueDiligenceRedirect = this.checkDueDiligenceRedirect(ebookRedirect, euMigrateRedirect)
      if (ibDueDiligenceRedirect) return ibDueDiligenceRedirect

      const subscriptionRedirect = this.checkSubscriptionRedirect(ebookRedirect, euMigrateRedirect)
      if (subscriptionRedirect) return subscriptionRedirect
    }

    const showTemporaryCeasingPopup = !getItem('temporaryCeasingPopup') && openTemporaryCeasingPopup && !isEmpty(temporaryCeasingPopup) && includes(temporaryCeasingPopup.countries, get(viewer, 'address.country'))
      && includes(temporaryCeasingPopup.tradingStatuses, get(viewer, 'tradingStatus'))

    return (
      <BugsnagErrorBoundary FallbackComponent={ErrorPage} beforeSend={(report) => this.beforeSendErrorReport(report)}>
        <AppContext.Provider value={{
          //@ts-ignore
          logout: this.handleLogout,
          showNotification: this.showNotification,
          company: get(viewer, 'whiteLabel') ? key : get(viewer, 'company') || key,
          locale,
          themePreference,
          toggleTheme: (theme, locale) => this.context.toggleTheme(theme, locale),
          tradingStatus,
          tradingStatusReasonCode,
          clientType,
          blockedDeposit,
          companyObject,
          hideNotification: () => this.setState({notification: null}),
          accounts,
          country: get(viewer, 'address.country'),
          clientId: get(viewer, 'id'),
          eligibleBonus: get(viewer, 'eligibleBonus.eligibleBonus'),
          acceptedTermsSpoaBonus: get(viewer, 'acceptedTermsSpoaBonus')
        }}>
          {!isMobile() && <Desktop viewer={viewer} notification={notification} onClose={() => this.setState({notification: null})} />}
          {isMobile() && <Mobile viewer={viewer} notification={notification} onClose={() => this.setState({notification: null})} />}
        </AppContext.Provider>
        {openPopup && <AlertDialog
          open={openPopup}
          title={t(messages.importantNotice.i18nKey, messages.importantNotice.defaults)}
          disagreeText={t(messages.logout.i18nKey, messages.logout.defaults)}
          agreeText={t(messages.completeForm.i18nKey, messages.completeForm.defaults)}
          onDisagree={()=> this.handleLogout()}
          onAgree={()=> this.setState({openPopup: false})}
          onClose={()=> this.setState({openPopup: false})}
        >
          <Typography variant="body1"><Trans {...messages.pleaseCompletePopup} values={{forceDocument}}/></Typography>
        </AlertDialog>}
        {showTemporaryCeasingPopup && <AlertDialog
          open={showTemporaryCeasingPopup}
          title={t(messages.importantNotice.i18nKey, messages.importantNotice.defaults)}
          onAgree={() => this.setState({openTemporaryCeasingPopup: false}, () => {storeItem('temporaryCeasingPopup', true)})}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
        >
          <Typography variant="body1">
            <Trans {...messages.temporaryCeasingPopupText} values={{countries: map(temporaryCeasingPopup.countries, (country) => find(countries, {key: country})!.label)}}/>
          </Typography><br />
        </AlertDialog>}
      </BugsnagErrorBoundary>
    )
  }
}

export default compose(
  withApollo,
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}: any) => ({
      loading,
      error,
      viewer: get(data, 'viewer')
    })
  }),
  graphql(ACCOUNTS_QUERY, {
    skip: (props) => !props.viewer,
    props: ({data: {loading:loadingAccounts, error:errorAccounts}, data}: any) => ({
      loadingAccounts,
      errorAccounts,
      accounts: get(data, 'viewer.accounts')
    })
  }),
)(App)

