import React, {Component} from 'react'
import qs from 'querystring'
import {get, find, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import Dialog from '@material-ui/core/Dialog'
import {Loading} from '../Common/Loading'
import withStyles from '@material-ui/core/styles/withStyles'
import WelcomeModal from './WelcomeModal'
import {config} from '../../config'
import {CLIENT_DATA_QUERY, ACCOUNTS_QUERY} from '../../graphql/queries'
import {getLocale} from '../../common/utils/requests'
import {ProvidedProps, Viewer} from '../../common/types'
const styles = {
  paper: {
    flexDirection: 'column' as const
  },
  toolBar:{
    minHeight:20,
    paddingLeft:5
  },
  root: {
    zIndex: 2147483640
  }
}
type ForexProps = ProvidedProps<typeof styles> & {
  match: {
    params: {
      login: string
    }
  },
  accounts: {
    login: string
    id: string
  }[],
  viewer: Viewer
}
class Forex extends Component<ForexProps,any> {

  componentDidMount() {
    window.addEventListener('message', this.handleEvent)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleEvent)
  }

  handleEvent = (e) => {
    // eslint-disable-next-line default-case
    switch (get(e, 'data.type') || get(e, 'data.action') || get(e, 'data')) {
      case 'backToDashboard':
      case 'logo':
        this.onClose()
        break
    }
  }

  onClose() {
    const {accounts, match, history} = this.props
    const login = get(match, 'params.login')
    const account = find(accounts, {login}) as any
    history.push(`/accounts/${account.id}`)
  }

  render() {
    const {match, viewer, accounts, classes} = this.props
    const locale = getLocale(viewer)

    if (!accounts) return <Loading />

    const webtraderUrl = config.productConfigs.forex.webtraderUrl
    const showWelcomeModal = get(qs.parse((window.location.search || '').replace('?', '')), 'showWelcomeModal') === 'true'

    return (
      <Dialog
        open
        fullScreen
        onClose={() => this.onClose()}
        keepMounted
        classes={{paper: classes.paper, root: classes.root}}
      >
        {showWelcomeModal && <WelcomeModal locale={locale} accounts={accounts} />}
        <iframe
          src={`${webtraderUrl}/?embedded=true&login=${match.params.login}`}
          frameBorder="0"
          name="forexTrader"
          id="forexTrader"
          title="forexTrader"
          style={{overflow: 'scroll', height: '100%', width: '100%'}}
          allowFullScreen
        />
      </Dialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}: any) => ({
      error,
      loading,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data}: any) => ({
      error,
      loading,
      accounts: get(data, 'viewer.accounts'),
    })
  })
)(Forex)
