import React, {Component} from 'react'
import {find, head, keys, map, groupBy, pickBy, keyBy, merge, get, values, last, has, includes, isEmpty, omit,
  sortBy, filter, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import CheckIcon from '@material-ui/icons/Check'
import ListItem from '@material-ui/core/ListItem'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import SelectDocument from './SelectDocument'
import PageTitle from '../../Common/PageTitle'
import {FullScreenDialog} from '../../Common/Dialog'
import PageSubTitle from '../../Common/PageSubTitle'
import LoadingButton from '../../Common/LoadingButton'
import AppContext from '../../Common/contexts/AppContext'
import messages from '../../../assets/messages'
import {putFile} from '../../../common/utils/browser'
import {hasVipOrRaw} from '../../../common/utils/accounts'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY, ACCOUNTS_QUERY} from '../../../graphql/queries'
import {DOCUMENT_CATEGORY_TYPES, safeParseJSON, getMissingDocs} from '../../../common/utils/general'
import {SIGN_UPLOAD_URL_MUTATION, CREATE_OWN_DOCUMENT_MUTATION} from '../../../graphql/mutations'
import {config} from '../../../config'
import {checkFileMimeType} from '../../../common/utils/validations'
import Images from '../../Common/Images'
import {acceptedFormats, maxFileSize} from '../../../common/utils/variables'
import {documentTypes, fundingCategories, clientTypes} from '@bdswiss/common-enums'
import {withRouter} from 'react-router-dom'
import Card from '@material-ui/core/Card'
import classNames from 'classnames'
import {Loading} from '../../Common/Loading'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import {documentHelperText} from '../../../common/utils/uioptions'
import DocumentDetails from './DocumentDetails'
import Tooltip from '@material-ui/core/Tooltip'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  checkIcon: {
    color: theme.palette.primary.main
  },
  listItem: {
    borderBottom: '1px solid #e0e0e0',
  },
  listItemBottom: {
    backgroundColor: 'none',
  },
  listExamplesBottom: {
    border: 'none',
    display: 'inline-flex',
    backgroundColor: 'none',
  },
  uploadTypeText: {
    textAlign: 'center' as const,
  },
  description: {
    fontSize:15,
    fontStyle:'italic'
  },
  exampleImg: {
    width:'70%',
    marginTop: '5%'
  },
  pofExampleImg: {
    width:'100%',
  },
  exampleText:{
    lineHeight:1
  },
  exampleBtn:{
    padding:'0 11px',
    textAlign: 'left' as const,
    wordBreak:'break-word' as const,
    '&:hover':{
      background:'none'
    },
    display: 'block',
  },
  defaultImage:{
    width: '100%',
  },
  zeroMarginTop:{
    marginTop:0
  },
  highlightText:{
    fontWeight:400,
    fontStyle:'italic'
  },
  cell: {
    padding: 0,
    verticalAlign: 'middle',
    borderBottom: 0
  },
  logoCell: {
    width: '15%',
  },
  logo: {
    width: 30,
    marginTop: 5,
    marginLeft: 5
  },
  greenText:{
    color: theme.palette.green.color,
    lineHeight:'40px'
  },
  textRight:{
    textAlign: 'right' as const
  },
  typeBadge: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginRight: 18,
    [theme.breakpoints.down('xs')]: {
      marginRight: '10px',
      marginTop: '-7px'
    },
  },
  card:{
    backgroundColor: theme.palette.lightred.color
  },
  bankWire:{
    backgroundColor: theme.palette.lightcyan.color
  },
  onlineBanking:{
    backgroundColor: theme.palette.lightblue.color
  },
  eWallet:{
    backgroundColor: theme.palette.lightgreen.color
  },
  crypto:{
    backgroundColor: theme.palette.lightorange.color
  },
  onlineDeposit:{
    backgroundColor: theme.palette.lightpurple.color
  },
  cardContent:{
    flexGrow:1,
    marginTop: '4px'
  },
  inline: {
    display: 'inline'
  },
  makeSureDiv: {
    marginTop: 30,
    padding: '10px 20px',
    backgroundColor: theme.palette.lightyellow.color,
    color: theme.palette.black.color,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  infoIcon: {
    marginBottom: -6,
    marginRight: 5,
  },
  list: {
    padding: '5px 0'
  },
  greyText:{
    color: theme.palette.grey.color,
  },
  pointer: {
    cursor: 'pointer',
  },
  uploadDiv: {
    marginTop: 20
  },
  disabled: {
    opacity: 0.5,
    '&:hover':{
      backgroundColor: 'inherit',
      cursor: 'default'
    },
  },
  icon: {
    marginTop: 5
  },
})

const partnerTypesToSubcategoryMapping = {
  [clientTypes.affiliate.key]: 'affiliate',
  [clientTypes.affiliateCorporate.key]: 'affiliate',
  [clientTypes.ib.key]: 'ib',
  [clientTypes.ibCorporate.key]: 'ib',
}

const SuccessfulUpload = ({classes, queuePosition, accounts, skipWaitingTime, docProcessingTime, translations}) => {
  const checkTime = docProcessingTime >= 15
  return (<Grid container direction="row" spacing={1}>
    <Grid item xs={12}>
      <Typography variant="body1"><Trans {...messages.documentUploaded} /></Typography>
    </Grid>
    {!hasVipOrRaw(accounts) &&
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body1"><Trans {...messages.documentUploadedMessage} /></Typography>
        </Grid>
        {!skipWaitingTime && <Grid item xs={12}>
          <Typography variant="body2" className={'italicText'}>
            {checkTime ? <React.Fragment><Trans {...messages.documentProcessed}/><sup>*</sup></React.Fragment>
              : <Trans {...messages.waitingTime} values={{docProcessingTime}}/>}
          </Typography>
          {checkTime && <Typography variant="caption">*<Trans {...messages.waitingTimeFootnote} /></Typography>}
          <Typography variant="caption"><Trans {...messages.customerSupport}/> </Typography>
        </Grid>}
      </React.Fragment>}
  </Grid>
  )}

const FailedUpload = () => (
  <Grid container direction="row" spacing={3}>
    <Grid item xs={12}>
      <Typography variant="body1">
        <Trans {...messages.documentFailedUploadMessage} />
      </Typography>
    </Grid>
  </Grid>
)

class ChooseDocument extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    const {clientType} = this.props.viewer
    const category = get(props, 'match.params.category')
    const isPof = category === 'pof'
    const documentCategoryType = find(DOCUMENT_CATEGORY_TYPES, {category: category}) as any
    const paymentMethod = safeParseJSON(get(props, 'location.state.paymentMethod', '{}'))
    const paymentMethods = get(props, 'viewer.paymentMethods') || []
    const fundingCategory = isPof && paymentMethods.length === 1 ? paymentMethods[0].fundingCategory : get(paymentMethod, 'fundingCategory', '')
    const paymentMethodId = isPof && paymentMethods.length === 1 ? paymentMethods[0].id : get(paymentMethod, 'id')

    const documentDisabled = paymentMethods.length === 1 && !get(paymentMethods, '[0].pendingUpload.allowUpload')
    const availableTypes = fundingCategory && isPof ?
      pickBy(documentCategoryType.types, (t) =>  includes(t.fundingCategories, fundingCategory) &&
      (!documentDisabled || (documentDisabled && includes(t.key, 'other')))) : documentCategoryType.types
    // Handle doubleSided documents
    const doubleSidedTypes = groupBy(pickBy(availableTypes, 'doubleSided'), 'groupName')
    const normalisedDoubleSidedTypes = keyBy(map(doubleSidedTypes, (t) => ({
      key: head(t).groupName,
      label: messages[head(t).groupName],
      doubleSided: true,
      fundingCategories : head(t).fundingCategories
    })), 'key')
    let displayTypes = merge({}, pickBy(availableTypes, (t) => !t.doubleSided), normalisedDoubleSidedTypes)
    if (category === 'partner') {
      displayTypes = pickBy(displayTypes, (t) => t.subCategories.includes(partnerTypesToSubcategoryMapping[clientType]))
    }

    this.state = {
      selectedDocTypeKey: head(keys(displayTypes)),
      allAvailableTypes: displayTypes,
      displayTypes,
      uploadedFile: '',
      secUploadedFile: '',
      uploadLoading: false,
      category,
      isPof,
      errorFiles: {},
      open: false,
      fundingCategory,
      paymentMethodId,
      openPopup: false,
    }
  }

  componentDidMount() {
    const {viewer, history} = this.props
    const {category} = this.state
    const missingDocs = getMissingDocs(viewer)

    if (get(missingDocs , category) === false) history.push('/settings/profile/documents')
  }

  generateDocumentTag(clientId, documentType) {
    documentType = (documentType || '').replace(/(Front|Back)/, '')
    return `${clientId}-${documentType}-${Date.now()}`
  }

  uploadDocument(docType, file, internalTag) {
    const {paymentMethodId} = this.state

    if (!file) return Promise.resolve()
    const {signUploadUrl, createOwnDocument, viewer: {id}} = this.props

    return signUploadUrl({variables: {clientId: id}}).then((res) => {
      const {key, signedUrl} = res.data.signedDetails

      return putFile(file, signedUrl).then(() =>
        createOwnDocument({variables: {type: docType, key, internalTag, paymentMethodId}})
      )
    })
    // .catch((error) => {
    //   console.log(error)
    // })
  }

  onUploadDocument() {
    const {selectedDocTypeKey: docType, uploadedFile: file, secUploadedFile: secFile} = this.state
    const {featuresConfig :{skipWaitingList}} = config
    this.setState({uploadLoading: true})

    const {match, classes, accounts, history, viewer: {id}, t} = this.props
    const uploadingDocumentCategory = get(match, 'params.category')
    const documentCategoryType = find(DOCUMENT_CATEGORY_TYPES, {category: uploadingDocumentCategory}) as any
    const docs = values(pickBy(documentCategoryType.types, (t) => t.groupName === docType || t.key === docType))

    const internalTag = this.generateDocumentTag(id, docType)

    this.uploadDocument(head(docs).key, file, internalTag).then((res) => {
      if (!secFile) return Promise.resolve(res)
      return this.uploadDocument(last(docs).key, secFile, internalTag)
    }).then((res) => {
      this.setState({uploadLoading: false, uploadStatus: 'success'})
      const queuePosition = get(res, 'data.newDocument.queuePosition')
      const docProcessingTime = get(res, 'data.newDocument.docProcessingTime')
      const skipWaitingTime = [documentTypes.promotionalMaterial.key, documentTypes.businessLicense.key].includes(docType) ||
        ['pof'].includes(uploadingDocumentCategory)
      const notificationParams = {
        type: 'document-upload',
        status: 'success',
        buttonMessage: <Trans {...messages.continue} />,
        content: <SuccessfulUpload classes={classes} queuePosition={queuePosition} accounts={accounts}
          skipWaitingTime={skipWaitingTime} docProcessingTime={docProcessingTime} translations={t} />,
        linkAction: !skipWaitingTime && (!hasVipOrRaw(accounts) && skipWaitingList ? '/vip-account' : false),
        linkActionMessage: !hasVipOrRaw(accounts) && skipWaitingList
          ? <Trans {...messages.skipWaitingList} /> : <Trans {...messages.close} />,
        onClose: (includes(this.props.location.pathname, 'register'))?history.push('/register/step3'):history.push('../upload')
      }
      this.context.showNotification(notificationParams)
    }).catch(() => {
      this.setState({uploadLoading: false, uploadStatus: 'failure', uploadedFile: '', secUploadedFile: ''})
      const notificationParams = {
        type: 'document-upload',
        status: 'failure',
        buttonMessage: <Trans {...messages.close} />,
        content: <FailedUpload />,
      }
      this.context.showNotification(notificationParams)
    })
  }

  handlSelectDocType(selectedDocTypeKey) {
    this.setState({selectedDocTypeKey})
  }

  handleFileUpload(prop, e) {
    this.setState({[prop]: e.target.files[0], uploadStatus: ''})

    const {errorFiles} = this.state
    map(e.target.files,(file) => {
      try {
        checkFileMimeType(file).then( (res: any) => {
          this.setState({fileChecked: true})
          const fileFormat = res && res.indexOf('image') === -1 && res.indexOf('pdf') === -1
          const fileSize = file.size > maxFileSize * 1024 * 1024
          if (fileFormat || fileSize) {
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [prop]: {
                  name: file.name,
                  error: fileFormat ? 'format' : 'size'
                }
              }
            }))
          }
          else {
            const errors = omit(errorFiles,[prop])
            this.setState({errorFiles:errors})
          }
        })
        e.target.value = null //reset input value to force reupload action in failure
      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
  }

  closeChooseDocument(multiDocs) {
    const {fundingCategory, isPof} = this.state
    if  (isPof && fundingCategory && multiDocs) {
      this.setState({fundingCategory: ''})
    } else if (this.props.location.state && this.props.location.state.prevPath === '/settings/profile') {
      return this.props.history.push('/settings/profile')
    } else if  (includes(this.props.location.pathname, 'register')) {
      return this.props.history.push('/register/step3')
    } else {
      return this.props.history.push('/settings/profile/documents/upload')
    }
  }

  getLocalizedLabel(type) {
    const {t} = this.props
    const {locale} = this.context
    const localizedLabel = has(type, 'label.i18nKey') ?
      t(type.label.i18nKey, type.label.defaults) :
      type.localization.t(locale)
    return localizedLabel
  }

  getPaymentMethodText(deposit) {
    const {locale} = this.context
    const {t} = this.props
    let paymentMethodText = fundingCategories[deposit.fundingCategory].localization.t(locale)
    const depositDetails = (deposit.details !== '-') ? deposit.details : ''
    switch (deposit.fundingCategory) {
      case fundingCategories.card.value:
        paymentMethodText = `${paymentMethodText} - ${t(messages.endingIn.i18nKey)} ${depositDetails}`
        break
      default:
        paymentMethodText = `${paymentMethodText}${depositDetails ? ` - ${depositDetails}` : ''}`
    }
    return paymentMethodText
  }

  onSelectPaymentMethod(m, documentDisabled) {
    const {isPof, allAvailableTypes, selectedDocTypeKey} = this.state
    const availableTypes = isPof ? pickBy(allAvailableTypes, (t) => includes(t.fundingCategories, m.fundingCategory) &&
      (!documentDisabled || (documentDisabled && includes(t.key, 'other')))) : {}

    this.setState({
      fundingCategory: m.fundingCategory,
      paymentMethodId: m.id,
      displayTypes: availableTypes,
      selectedDocTypeKey: !isEmpty(availableTypes) ? head(keys(availableTypes)) : selectedDocTypeKey
    })
  }

  renderPofDocuments() {
    const {classes, viewer, documents, t} = this.props
    const paymentMethods = get(viewer, 'paymentMethods') || []
    const sortedPaymentMethods = sortBy(paymentMethods, (m) => m.fundingCategory)
    const pofDocuments = filter(documents, (d) => documentTypes[d.type] &&
      (documentTypes[d.type].category === 'POF' ||
        get(documentTypes[d.type], 'subCategories', []).includes('POF')
      ))

    return <Grid container spacing={3}>
      {map(sortedPaymentMethods, (m, index) => {
        const documentDisabled = find(pofDocuments, (d) => !get(m.pendingUpload,'allowUpload'))
        return <Grid item xs={12} key={index}>
          <Card onClick={() => this.onSelectPaymentMethod(m, documentDisabled)}>
            <Grid container>
              <Grid item className={classNames(classes.typeBadge,classes[m.fundingCategory])}>
                <img
                  alt={m.fundingCategory}
                  src={Images[`${m.fundingCategory}.svg`]}
                  className={classes.logo}
                />
              </Grid>
              <Grid item className={classes.cardContent}>
                <Typography variant='subtitle1'>{this.getPaymentMethodText(m)}</Typography>
              </Grid>
              {m.confirmed && <Grid item>
                <Typography variant='caption' className={classes.greenText}><Trans {...messages.verified} /></Typography>
              </Grid>
              }
              {!m.confirmed && documentDisabled && <Grid item className={classes.icon}>
                <Tooltip title={t(messages[documentDisabled.status].i18nKey, messages[documentDisabled.status].default)} placement="top">
                  <Typography variant='caption' ><Trans {...messages[documentDisabled.status]} /></Typography>
                </Tooltip>
              </Grid>
              }
            </Grid>
          </Card>
        </Grid>})}
    </Grid>
  }

  render() {
    const {clientType, companyObject} = this.context
    const {classes, width, viewer, loadingViewer} = this.props
    const {selectedDocTypeKey, uploadedFile, secUploadedFile, displayTypes, uploadLoading, category, uploadStatus,
      errorFiles, fundingCategory, isPof, openPopup} = this.state
    const {themePreference} =this.context
    if (loadingViewer) return <Loading />

    const paymentMethods = get(viewer, 'paymentMethods') || []
    const selectedDocType = find(displayTypes, {key: selectedDocTypeKey})
    const showUploadButton = uploadedFile ? selectedDocType.doubleSided ?
      !!secUploadedFile : !!uploadedFile : false
    const messageTitle = category === 'partner' ? messages[`${clientType}VerificationTitle`] :
      messages[`${category}Upload`]
    const multiPaymentMethods = paymentMethods.length > 1
    const formatsAccepted = acceptedFormats.split(', ')
    const bankStatementDocuments = [documentTypes.bankStatementPOR.key, documentTypes.bankStatementPOF.key]

    return (
      <FullScreenDialog
        desktopOnly
        open={true}
        onClose={() => this.closeChooseDocument(multiPaymentMethods)}
      >
        {(isPof && !fundingCategory && multiPaymentMethods)
          ? <Grid container spacing={0}>
            <Grid item xs={12} sm={9} md={6}>{this.renderPofDocuments()}</Grid>
          </Grid>
          : <React.Fragment>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <PageTitle
                  onBack={() => this.closeChooseDocument(multiPaymentMethods)}>
                  <Trans {...messageTitle} />
                </PageTitle>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={7} md={7}>
                <Grid container spacing={1} direction="column">
                  <Grid item xs={12}>
                    <PageSubTitle>
                      <Trans {...messages.documentType} />
                    </PageSubTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      {map(displayTypes, (type) => (<ListItem
                        className={classes.listItem}
                        key={type.key}
                        button
                        onClick={() => this.handlSelectDocType(type.key)}
                      >
                        <ListItemText primary={this.getLocalizedLabel(type)} />
                        {selectedDocTypeKey === type.key && <CheckIcon className={classes.checkIcon} />}
                      </ListItem>)
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" className={classes.greyText}>
                      <Trans {...messages.acceptedFormats} values={{acceptedFormats: acceptedFormats.replace(/(.*),.*/, '$1'), lastAccepted: formatsAccepted[formatsAccepted.length-1]}}/>&nbsp;
                      <Trans {...messages.maxFileSizeText} values={{maxSize: maxFileSize}}/>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.uploadDiv} spacing={3}>
                      <Grid item xs={12} md={6} >
                        <SelectDocument
                          uploadedFile={uploadedFile}
                          onChange={(e) => this.handleFileUpload('uploadedFile', e)}
                          fileKey="1"
                          showLabel = {selectedDocType.doubleSided}
                          category={`${category}-front`}
                          status={uploadedFile && (includes(keys(errorFiles), 'uploadedFile') ? 'error': 'success')}
                          errors={!isEmpty(errorFiles) && errorFiles.uploadedFile}
                          selectedDocTypeKey={selectedDocTypeKey}
                          src={includes(bankStatementDocuments, selectedDocTypeKey) ? Images['upload-bankStatement.png'] : (Images[`upload-${category}-front-${selectedDocTypeKey}.png`] || Images[`upload-${category}-front.png`]) || Images['upload-generic.png']}
                          srcSuccess={includes(bankStatementDocuments, selectedDocTypeKey) ? Images['upload-bankStatement-success.png'] : (Images[`upload-${category}-front-${selectedDocTypeKey}-success.png`] || Images[`upload-${category}-front-success.png`]) || Images['upload-generic-success.png']}
                          srcError={includes(bankStatementDocuments, selectedDocTypeKey) ? Images['upload-bankStatement-error.png'] : (Images[`upload-${category}-front-${selectedDocTypeKey}-error.png`] || Images[`upload-${category}-front-error.png`]) || Images['upload-generic-error.png']}
                        />
                      </Grid>
                      {selectedDocType.doubleSided && <Grid item xs={12} md={6}>
                        <SelectDocument
                          uploadedFile={secUploadedFile}
                          onChange={(e) => this.handleFileUpload('secUploadedFile', e)}
                          fileKey="2"
                          showLabel
                          category={`${category}-back`}
                          status={secUploadedFile && (includes(keys(errorFiles), 'secUploadedFile') ? 'error': 'success')}
                          errors={!isEmpty(errorFiles) && errorFiles.secUploadedFile}
                          selectedDocTypeKey={selectedDocTypeKey}
                          src={Images[`upload-${category}-back-${selectedDocTypeKey}.png`] || Images[`upload-${category}-back.png`] || Images['upload-generic.png']}
                          srcSuccess={Images[`upload-${category}-back-${selectedDocTypeKey}-success.png`] || Images[`upload-${category}-back-success.png`] || Images['upload-generic-success.png']}
                          srcError={Images[`upload-${category}-back-${selectedDocTypeKey}-error.png`] || Images[`upload-${category}-back-error.png`] || Images['upload-generic-error.png']}
                        />
                      </Grid>}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} className={classes.uploadTypeText}>
                        <LoadingButton
                          id='uploadDocLoadingButton'
                          onClick={() => this.onUploadDocument()}
                          disabled={!showUploadButton || !isEmpty(errorFiles) || (uploadLoading && !uploadStatus) || (uploadStatus==='failure')}
                          status={uploadStatus || ''}
                          fullWidth={isWidthDown('sm', width)}
                          hideProgressBar={!showUploadButton || !isEmpty(errorFiles)}
                          noMargin
                        >
                          <Trans {...messages.submitDocument} />
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <Grid container className={classes.makeSureDiv} spacing={3}>
                  {get(documentHelperText[category], 'acceptedDocuments') && <React.Fragment>
                    <Grid item xs={12}>
                      <Typography variant="body2"> <Trans {...messages.acceptedDocuments}/> </Typography>
                    </Grid>
                    <ul className={classNames(classes.zeroMarginTop)}>
                      {map(documentHelperText[category].acceptedDocuments, (key) => (
                        <li key={key} className={classNames(classes.list)}> <Typography variant="body1" classes={{root: classes.inline}}> <Trans {...messages[key]} /> </Typography> </li>
                      ))}
                    </ul>
                  </React.Fragment>}
                  <Grid item xs={12}>
                    <Typography variant="body2"> <Trans {...messages.pleaseMakeSure} /> </Typography>
                  </Grid>
                  <ul className={classNames(classes.zeroMarginTop)}>
                    {map(documentHelperText[category].makeSure[selectedDocTypeKey] ?
                      documentHelperText[category].makeSure[selectedDocTypeKey] :
                      documentHelperText[category].makeSure, (key) => {
                      const checkDoubleSide = get(displayTypes[selectedDocTypeKey], 'doubleSided', false)
                      if (!checkDoubleSide && key === 'makeSureDocumentsTextPoi3') return
                      return <li key={key} className={classNames(classes.list)}> <Typography variant="body1" classes={{root: classes.inline}}> <Trans {...messages[key]} /> </Typography> </li>
                    })}
                  </ul>
                  {(documentHelperText[category].shouldInclude || get(documentHelperText[category], `${selectedDocTypeKey}.shouldInclude`)) && <React.Fragment>
                    <Grid item xs={12}>
                      <Typography variant="body2"> <Trans {...messages.shouldInclude}/> </Typography>
                    </Grid>
                    <ul className={classNames(classes.zeroMarginTop)}>
                      {map(get(documentHelperText[category], `${selectedDocTypeKey}.shouldInclude`) ? documentHelperText[category][selectedDocTypeKey].shouldInclude : documentHelperText[category].shouldInclude, (key) => (
                        <li key={key} className={classNames(classes.list)}> <Typography variant="body1" classes={{root: classes.inline}}> <Trans {...messages[key]} /> </Typography> </li>
                      ))}
                    </ul>
                  </React.Fragment>}
                  {get(documentHelperText[category], `${selectedDocTypeKey}.instructions`) && <React.Fragment>
                    <Grid item xs={12}>
                      <Typography variant="body2"> <Trans {...messages.instructions} /> </Typography>
                      <Typography variant="body1"> <Trans {...messages[get(documentHelperText[category], `${selectedDocTypeKey}.instructions`)]}
                        values={{company: companyObject.brandLabel}}/> </Typography>
                    </Grid>
                  </React.Fragment>}
                  <Grid item xs={12} onClick={() => this.setState({openPopup: true})} className={classes.pointer}>
                    <InfoIcon className={classNames(classes.infoIcon, classes.checkIcon)} />
                    <Typography variant="body2" className={classNames(classes.inline, classes.checkIcon, classes[`${themePreference}Icon`])}> <Trans {...messages.properlyUploadDocuments} /> </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>}

        {openPopup && <DocumentDetails category={category} selectedDocTypeKey={selectedDocTypeKey} displayTypes={displayTypes}
          onClose={() => this.setState({openPopup: false})} getLocalizedLabel={(label) => this.getLocalizedLabel(label)}/>}
      </FullScreenDialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth(),
  withRouter,
  withNamespaces(),
  graphql(SIGN_UPLOAD_URL_MUTATION, {
    name: 'signUploadUrl',
  }),
  graphql(CREATE_OWN_DOCUMENT_MUTATION, {
    name: 'createOwnDocument',
    options: {
      refetchQueries: [{query: PROFILE_SETTINGS_QUERY}],
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => ({
      loadingViewer: props.data?.loading,
      errorViewer: props.data?.error,
      viewer: get(props.data, 'viewer'),
    })
  }),
  graphql(ACCOUNTS_QUERY, {
    props: (props) => ({
      loading: props.data?.loading,
      error: props.data?.error,
      accounts: get(props.data, 'viewer.accounts'),
    })
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => ({
      documentsError: props.data?.error,
      documentsLoading: props.data?.loading,
      documents: get(props.data, 'viewer.documents', []),
    })
  }),
)(ChooseDocument)
