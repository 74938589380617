import React from 'react'
import {Font, Page, Text, Image, Document, StyleSheet, View} from '@react-pdf/renderer'
import messages from '../../../../assets/messages'
import {Trans} from 'react-i18next'
import {findCompany} from '../../../../common/utils'
import Images from '../../../Common/Images'
import {toLower} from 'lodash'
import {companies, regulators} from '@bdswiss/common-enums'

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf'
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: 'white',
    color: '#333333',
  },
  headerTop: {
    fontSize: 12,
    textAlign: 'center',
    color: '#999999',
    textTransform: 'uppercase',
  },
  title: {
    marginTop: 12,
    marginLeft: 12,
    fontSize: 16,
    textAlign: 'justify',
    fontFamily: 'Roboto',
  },
  text: {
    marginLeft: 12,
    marginBottom: 15,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Roboto',
    fontWeight: 100,
  },
  wireTransferNote: {
    marginTop: 8,
    marginLeft: 12,
    marginBottom: 25,
    fontSize: 11,
    textAlign: 'justify',
    fontFamily: 'Roboto',
  },
  header: {
    fontSize: 12,
    marginLeft: 12,
    marginBottom: 5,
    textAlign: 'justify',
    color: '#999999',
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 20,
    right: 20,
    textAlign: 'center',
    color: '#999999',
    marginLeft: 10,
    marginRight: 10,
    borderTopColor: '#d7d7d7',
    borderTopWidth: 1,
    paddingTop: 5,
  },
  image: {
    width: 120,
    textAlign: 'center',
    marginBottom: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  line: {
    borderBottomColor: '#d7d7d7',
    borderBottomWidth: 1,
    margin: 15,
  },
  important: {
    fontSize: 12,
    marginLeft: 12,
    marginBottom: 5,
    textAlign: 'justify',
    color: '#333333',
    textDecoration: 'underline',
    fontWeight: 400,
  },
})

export const BankTransferDetailsPDF = (props) => {
  const {accountId, clientId, transferInfo:{recipient, iban, creditingAccount, swift, bank, address, city, country, currency, reference}} = props
  const companyObject = findCompany()
  const companyBrandLabel = companyObject.brandLabel
  const emailDomain = companyObject.emailDomain
  const regulator = regulators[companies[companyObject.key].regulator].label
  //@ts-ignore
  return <Document>
    {/*@ts-ignore*/}
    <Page size="A4" style={styles.body}>
      <Image
        style={styles.image}
        source={Images[`logo-${toLower(companyObject.brand)}.png`]}
        // alt={'logo'}
      />
      <Text style={styles.headerTop} fixed>
        <Trans {...messages.growingCommunity} />
      </Text>
      <View style={styles.line} />
      <Text style={styles.title}>
        <Trans {...messages.bankTransferTitle} values={{company: companyBrandLabel}} />
      </Text>
      <Text style={styles.wireTransferNote}>
        <Trans {...messages.wireTransferNote} />
      </Text>
      <Text style={styles.header}>
        <Trans {...messages.recipient} />
      </Text>
      <Text style={styles.text}>
        {recipient}
      </Text>
      <Text style={styles.header}>
        <Trans {...messages.iban} />
      </Text>
      <Text style={styles.text}>
        {iban}
      </Text>
      <Text style={styles.header}>
        <Trans {...messages.creditingAccount} />
      </Text>
      <Text style={styles.text}>
        {creditingAccount}
      </Text>
      <Text style={styles.header}>
        {'BIC-SWIFT-SEPA'}
      </Text>
      <Text style={styles.text}>
        {swift}
      </Text>
      <Text style={styles.header}>
        <Trans {...messages.bankName} />
      </Text>
      <Text style={styles.text}>
        {bank}
      </Text>
      <Text style={styles.header}>
        <Trans {...messages.referenceNumber} />
      </Text>
      <Text style={styles.text}>
        {reference ? `${reference}
          ${clientId}-${accountId}` : `${clientId}-${accountId}`}
      </Text>
      <Text style={styles.header}>
        <Trans {...messages.address} />
      </Text>
      <Text style={styles.text}>
        {address}
      </Text>
      <Text style={styles.header}>
        <Trans {...messages.cityName} />
      </Text>
      <Text style={styles.text}>
        {city}
      </Text>
      <Text style={styles.header}>
        <Trans {...messages.countryName} />
      </Text>
      <Text style={styles.text}>
        {country}
      </Text>
      <Text style={styles.header}>
        <Trans {...messages.currency} />
      </Text>
      <Text style={styles.text}>
        {currency}
      </Text>
      <Text style={styles.important}>
        <Trans {...messages.important} />{':'}
      </Text>
      <Text style={styles.text}>
        <Trans {...messages[reference ? 'submittedWireDetailsWarningAdditionalReference' : 'submittedWireDetailsWarningAdditional']} />
      </Text>
      <Text style={styles.footer} fixed>
        <Trans {...messages[`footer${regulator}`]} values={{emailDomain: emailDomain}}/>
      </Text>
    </Page>
  </Document>
}
