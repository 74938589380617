import React from 'react'
import {every, get, flowRight as compose, omitBy} from 'lodash'
import {graphql} from 'react-apollo'
import {Trans, withNamespaces} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'
import {FullScreenDialog} from '../Common/Dialog'
import PageTitle from '../Common/PageTitle'
import messages from '../../assets/messages'
import {config} from '../../config'
import {SPOA_AUTHORIZATION_MUTATION} from '../../graphql/mutations'
import {SPOA_AUTHORIZATION_QUERY} from '../../graphql/queries'
import AppContext from '../../components/Common/contexts/AppContext'
import {withStyles} from '@material-ui/core'
import LoadingButton from '../Common/LoadingButton'
import {isMobile} from '../../common/utils'
import NotificationBar from '../Common/NotificationBar'
import Loading from '../Common/Loading'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  error: {
    color: theme.palette.red.color
  },
  link: {
    color: theme.palette.primary.main,
  },
})

class SpoaAuthorization extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  initialFormState = {
    isValid: false,
    fields: {
      remoteClientId: {
        value: '',
        isValid: 'false',
      },
      agree: {
        value: false,
        isValid: 'false',
      },
      acceptedSpoaBonusTerms: {
        value: false,
        optional: true
      }
    }
  }

  state = {
    form: this.initialFormState,
    loading: false,
    status: '',
  } as any

  validateForm() {
    const {form} = this.state
    //@ts-ignore
    const isValid = every(omitBy(form.fields, 'optional'), o => o.isValid === true)
    this.setState({
      form: {
        ...form,
        isValid,
      }
    })
    return isValid
  }

  handleChange(prop, value) {
    let fields = this.state.form.fields
    fields = {
      ...this.state.form.fields,
      [prop]: {
        ...this.state.form.fields[prop],
        value: value,
        isValid: (prop === 'remoteClientId') ? RegExp(/^\d{4,10}$/).test(value) : !!value
      }
    }
    //@ts-ignore
    this.setState({form: {fields}}, () => this.validateForm())
  }

  handleSubmit = () => {
    const {hasGrantedSpoa, spoaRemoteClient, mutate} = this.props
    const {form: {fields: {acceptedSpoaBonusTerms}}} = this.state
    this.setState({loading: true})
    if (hasGrantedSpoa) { // revoke
      mutate({variables: {remoteClientId: spoaRemoteClient.id, isAuthorized: false}})
        .then(({data: {spoaAuthorize}}) => {
          const response = JSON.parse(spoaAuthorize)
          this.context.showNotification({
            type: 'document-upload',
            status: response.value === 'successfulRevocation' ? 'success' : 'failure',
            content: response.message,
            buttonMessage: <Trans {...messages.continue} />,
            onClose: () => this.setState({status: ''}),
            buttonAction: this.setState({status: ''}),
          })
          this.setState({loading: false, status: 'success'})
        })
        .catch(console.log) // eslint-disable-line
        .finally(() => this.setState({form: this.initialFormState}))
    } else { // grant
      if (this.validateForm()) { // check form is valid
        const remoteClientId = this.state.form.fields.remoteClientId.value
        mutate({variables: {remoteClientId, isAuthorized: true, acceptedSpoaBonusTerms: acceptedSpoaBonusTerms.value}})
          .then(({data: {spoaAuthorize}}) => {
            const response = JSON.parse(spoaAuthorize)
            this.context.showNotification({
              type: 'document-upload',
              status: response.value === 'successfulAuthorization' ? 'success' : 'failure',
              content: response.message,
              buttonMessage: <Trans {...messages.continue} />,
              onClose: () => this.setState({status: ''}),
              buttonAction: this.setState({status: ''}),
            })
            this.setState({loading: false, status: 'success'})
          })
          .catch(console.log) // eslint-disable-line
      }
    }
  }

  render() {
    const {history, hasGrantedSpoa, spoaRemoteClient, classes, loadingSpoa} = this.props
    const {form: {fields: {remoteClientId, agree, acceptedSpoaBonusTerms}, isValid}, status, loading, submitError} = this.state
    const {locale, eligibleBonus, companyObject, acceptedTermsSpoaBonus} = this.context

    if (loadingSpoa) return <Loading />

    return (
      <FullScreenDialog
        desktopOnly
        open
        onClose={() => history.push('/settings/profile')}
      >
        <PageTitle onBack={() => history.push('/settings/profile')}><Trans {...messages.spoaTitle} /></PageTitle>
        <Grid container spacing={3} direction="column">
          {(!hasGrantedSpoa) ? <React.Fragment>
            <Grid item md={8} xs={12}>
              <NotificationBar status="info" >
                <Trans {...messages.spoaNote} values={{company: get(companyObject, 'brandLabel')}}/>
              </NotificationBar>
            </Grid>
            <Grid item xs={8}>
              <FormControl error={!remoteClientId.isValid}>
                <TextField
                  name="remoteClientId"
                  label={<Trans {...messages.ibClientId} />}
                  value={remoteClientId.value}
                  onChange={(e) => this.handleChange('remoteClientId', e.target.value)}
                  fullWidth
                  error={!remoteClientId.isValid}
                />
              </FormControl>
            </Grid>

            <Grid item md={8} xs={12}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agree"
                      checked={agree.value}
                      onChange={(e) => this.handleChange('agree', e.target.checked)}
                      color="primary"
                      className={!agree.isValid ? classes.error : ''}
                    />}
                  label={<Typography variant="body1">
                    <Trans
                      {...messages.spoaAcceptedTerms}
                      components={[<a className={classes.link} href={config.weblinks.termsAndConditions.replace('{lang}', locale)} rel="noopener noreferrer" target="_blank"> </a>]} />
                  </Typography>}
                />
              </FormControl>
              {eligibleBonus && <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="acceptedSpoaBonusTerms"
                      checked={acceptedSpoaBonusTerms.value}
                      onChange={(e) => this.handleChange('acceptedSpoaBonusTerms', e.target.checked)}
                      color="primary"
                    />}
                  label={<Typography variant="body1"><Trans
                    {...messages.bonusAcceptedTerms}
                    components={[<a className={classes.link} href={config.weblinks.bonusTerms.replace('{lang}', locale)} rel="noopener noreferrer" target="_blank"> </a>]} />
                  </Typography>}
                />
              </FormControl>}
            </Grid>
          </React.Fragment>
            : <React.Fragment>
              <Grid item md={8} xs={12}>
                <Typography variant="body1">
                  <Trans {...messages.spoaAuthorization} values={{
                    id: spoaRemoteClient.id,
                    firstName: spoaRemoteClient.firstName,
                    lastName: spoaRemoteClient.lastName,
                  }} />
                </Typography>
              </Grid>
              {(isValid ? acceptedSpoaBonusTerms.value : acceptedTermsSpoaBonus) && <Grid item md={8} xs={12}>
                <Typography variant="body1">
                  <Trans {...messages.spoaAuthorizationText2} />
                </Typography>
              </Grid>}
            </React.Fragment>}

          <Grid item>
            <LoadingButton
              id='loadingButton'
              onClick={this.handleSubmit}
              fullWidth={isMobile()}
              disabled={!hasGrantedSpoa ? (!isValid || loading) : loading}
              status={status}
              hideProgressBar={!hasGrantedSpoa && !isValid}
            >{hasGrantedSpoa ? <Trans {...messages.revokeAuthorization} /> : <Trans {...messages.grantAuthorization} />}
            </LoadingButton>
            {status==='failure' &&
              <FormHelperText className={classes.error}>{submitError}</FormHelperText>}
          </Grid>

        </Grid>
      </FullScreenDialog>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(SPOA_AUTHORIZATION_MUTATION, {
    options: {
      refetchQueries: [{query: SPOA_AUTHORIZATION_QUERY}],
    }
  }),
  graphql(SPOA_AUTHORIZATION_QUERY, {
    props: (props) => ({
      error: props.data?.error,
      loadingSpoa: props.data?.loading,
      spoaRemoteClient: get(props.data, 'viewer.spoaRemoteClient'),
      hasGrantedSpoa: Boolean(get(props.data, 'viewer.spoaRemoteId')),
    })
  }),
)(SpoaAuthorization)
