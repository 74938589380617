import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {map, flowRight as compose, get} from 'lodash'
import {regulators} from '@bdswiss/common-enums'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import {AlertDialog} from '../Common/Dialog'
import messages from '../../assets/messages'
import {ADD_METADATA_TO_CLIENT_MUTATION} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import withStyles from '@material-ui/core/styles/withStyles'
import AppContext from '../Common/contexts/AppContext'
import {Checkbox, FormControlLabel} from '@material-ui/core'
import {euRegulationHelperText} from '../../common/utils/uioptions'
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import {config} from '../../config'
import moment from 'moment'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  checkBoxError: {
    color: theme.palette.red.color
  },
})

export class MigrationToEuPopup extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      acceptTerms: '',
      acceptTermsError: false,
      status: '',
      loading: false
    }
  }

  handleChange(prop, value) {
    this.setState(state => ({[prop]: value, acceptTermsError: !value}))
  }

  submitAcceptTerms() {
    const {acceptTerms} = this.state
    if (!acceptTerms) {
      this.setState({acceptTermsError: true})
      return
    }
    this.setState({loading: true})
    this.props.addMetadataToClient({variables: {metadata: JSON.stringify({acceptEuMigrationTerms: moment()})}}).then(() => {
      this.setState({status: 'success', loading: false, showRedirectionModal: false})
    }).catch(() => {
      this.setState({status: 'failure', loading: false})
    })
  }

  renderEuMigrationConent() {
    const {classes} = this.props
    const {weblinks: {websiteUrl}, euRegulation: {euRegulationQuestion}, supportAccountEmail, supportEmail, weblinks} = config
    const {companyObject, locale} = this.context
    const {acceptTerms, acceptTermsError} = this.state

    return <Grid container spacing={3}>
      {map(euRegulationHelperText[get(companyObject, 'value')], (content) => <Grid item xs={12} key={content}>
        <Typography variant="body1">
          <Trans {...messages[content]}
            values={{
              regulator: regulators[get(companyObject, 'regulator')!] && regulators[get(companyObject, 'regulator')!].label,
              trademark: get(companyObject, 'trademark'),
              leverage: `1:${get(companyObject, 'leverage')}`,
              supportEmail: supportAccountEmail || supportEmail
            }}
            components={[
              (euRegulationQuestion) ? <a href={`${websiteUrl}${euRegulationQuestion}`} target='_blank' rel='noreferrer noopener' className={classNames(classes.displayInline, classes.textLink, classes.pointer)}>link</a> : <span />,
              <Link to={'/support'} target="_blank" className={classNames(classes.displayInline, classes.textLink, classes.pointer)}>support</Link>,
              <a href={`mailto:${supportAccountEmail}`} className={classNames(classes.displayInline, classes.textLink, classes.pointer)}>supportEmail</a>
            ]} />
        </Typography>
      </Grid>)}
      <Grid item xs={12}>
        <FormControl>
          <FormControlLabel
            classes={{label:classes.checkboxLabel}}
            control={
              <Checkbox
                required
                checked={acceptTerms}
                onChange={(e) => this.handleChange('acceptTerms', e.target.checked? 'accept' : '')}
                color="primary"
                value={acceptTerms}
                className={acceptTermsError ? classes.checkBoxError : ''}
              />
            }
            label={ <Trans {...messages.readAcceptTerms} values={{company:companyObject.brandLabel}} components={[
              <a href={weblinks.termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
              <a href={weblinks.legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
              <a href={weblinks.privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
            ]} />}
          />
        </FormControl>
      </Grid>
    </Grid>
  }

  render() {
    const {t, viewer} = this.props
    const {loading, status} = this.state
    const {companyObject} = this.context
    const {euRegulation: {showTermsPopup}} = config
    const showEuMigration = !!showTermsPopup && get(viewer, 'fromCompany') && !get(viewer, 'acceptEuMigrationTerms')

    return (
      <AlertDialog
        open={!!showEuMigration}
        title={t(messages.successfulMigrationTitle.i18nKey, {trademark: get(companyObject, 'trademark')})}
        children={this.renderEuMigrationConent()}
        agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
        onAgree={()=> this.submitAcceptTerms()}
        buttonLoading={loading}
        buttonStatus={status}
      />
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}:any) => ({
      errorClient: error,
      loadingClient: loading,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(ADD_METADATA_TO_CLIENT_MUTATION, {
    name: 'addMetadataToClient',
    options: () => ({
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }),
  }),
)(MigrationToEuPopup)
