import url from 'url'
import {get,pickBy, identity, isEmpty} from 'lodash'
import {config} from '../../config'
import messages from '../../assets/messages'
import {Viewer} from '../types'
import {getItem} from './localStorage'
import {getCookie} from './browser'

export const backendRequest = (endpoint: string, params : any = {}, method: string = 'POST', options : RequestInit = {}) => {
  const headers = get(options, 'headers', {'Content-Type': 'application/json'})
  const reqOptions : RequestInit = {
    method,
    headers,
    body: (params ? JSON.stringify(params) : undefined),
    mode: 'cors' as const,
    credentials: 'include'as const,
    ...options
  }
  return fetch(url.resolve(config.restEndpointUrl, endpoint), reqOptions)
}

export const backendGetRequest = (endpoint, credentials?: boolean, params?: any) => {
  const cleanedParams = pickBy(params, v => v !== undefined)
  if (!isEmpty(cleanedParams)) endpoint = `${endpoint}?${new URLSearchParams(cleanedParams).toString()}`
  const reqOptions : RequestInit = {
    method: 'GET',
    mode: credentials?'cors':undefined,
    credentials: credentials ?'include':undefined
  }
  return fetch(url.resolve(config.restEndpointUrl, endpoint), reqOptions)
}

export const loginRequest = (email, password, recaptcha) =>
  backendRequest('/auth/login-web', {email, password, recaptcha})
    .then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        if (res.status === 429) {
          throw new Error('tooManyRequests')
        } else {
          return res.json().then((result) => {
            const error = get(result, 'error')
            if (!error)
              throw messages.somethingWrongError.i18nKey
            else throw get(messages, error) ? error : result.message
          })
        }
      }
    })


export const logoutRequest = () =>
  backendRequest('/auth/logout?redirect=false')
    .then((res) => {
      if (res.ok) {
        return true
      } else {
        return res.json().then(({message}) => {
          const e = new Error(message)
          // removed because the prop doesn't exist
          // e.isHandled = true
          throw e
        })
      }
    })

export const resetPasswordRequest = (email) =>
  backendRequest('/auth/password-reset/start', {email})
    .then((res) => {
      if (res.ok) {
        return true
      } else {
        return res.json().then(({message}) => {
          throw message
        })
      }
    })

export const resetPasswordFinish = (newPassword, token) =>
  backendRequest('/auth/password-reset/finish', {newPassword, token})
    .then((res) => {
      if (res.ok) {
        return true
      } else {
        return res.json().then(({message}) => {
          throw message
        })
      }
    })

export const getRelatedEntities = () =>
  backendGetRequest('/auth/client/related-entities', true)
    .then((res) =>res.json())
    .catch((error)=>error.json())

export const register = (firstName,lastName,currency,phoneCode,phoneValue,email,password,country,ageAcceptance,
  riskAcceptance,eulaAcceptance,privacyAcceptance,products,accountSubtype,optInMarketing,locale,skypeId,
  website,affiliateCountries,bta,am,company,whiteLabel,isCorporate, customDomain, birthday, checkField, recaptcha,
  referralCode, referral, regulationAcceptance, partnerReferenceNumber, licenseAcknowledgement, multiRegulationClientId, solicitationAcceptance ) =>{
  const phone = `${phoneCode}${phoneValue}`
  let kartwheelIbId: number | undefined
  const cookie = getCookie('kartwheelIbId')
  if (cookie && Number(cookie) > 0) {
    kartwheelIbId = Number(cookie)
  }
  const params ={firstName,lastName,currency,phone,email,password,country,ageAcceptance,riskAcceptance,
    eulaAcceptance,privacyAcceptance,products,accountSubtype,optInMarketing,locale, skypeId, website,
    affiliateCountries,bta,am,company,whiteLabel,isCorporate,customDomain, birthday, checkField, recaptcha,
    referralCode, referral, regulationAcceptance, partnerReferenceNumber, licenseAcknowledgement, multiRegulationClientId, solicitationAcceptance,kartwheelIbId}
  return backendRequest('api/signup-web',pickBy(params,identity))
    .then((res) =>res.json())
    .catch((error)=>error.json())
}
export const checkEmail = (email,company,product) =>
  backendRequest('api/check-email',{email,company,product})
    .then((res) =>{
      if (res.ok) {
        return false
      } else {
        return res.json().then((result) => result.key)
      }
    }).catch((error)=>error.json())

export const getIPCountry = () =>
  backendGetRequest('/api/get-country', true)
    .then((res) => res.json())
    .catch((error)=>error)

export const getSignupCountries = (company) =>
  backendGetRequest(`/api/signup-countries?company=${company}`)
    .then((res) => res.json())
    .catch((error)=>error)

export const getAutochartistDetails = (locale) =>
  backendGetRequest(`/api/getAutochartistDetails?locale=${locale}`, true)
    .then((res) => res.json())
    .catch((error)=>error)

export const getStrategiesDetails = (accountId) =>
  backendGetRequest(`/api/v1/crm/strategies/${accountId}`, true)
    .then((res) => res.json())
    .catch((error)=> error)

export const getLeaderboardDetails = (competitionId) =>
  backendGetRequest(`/api/v1/marketing/leaderboards/${competitionId}`, true)
    .then((res) => res.json())
    .catch((error)=> error)

export const getLeaderboardClientDetails = (competitionId, clientId) =>
  backendGetRequest(`/api/v1/marketing/leaderboards/${competitionId}/members/${clientId}`, true)
    .then((res) => res.json())
    .catch((error)=> error)

export const getMemberFromLeaderboard = (competitionId, clientId) =>
  backendGetRequest(`/api/v1/marketing/leaderboards/${competitionId}/${clientId}`, true)
    .then((res) => res.json())
    .catch((error)=> error)

export const checkReferralCode = (referralCode, referral) =>
  backendRequest('api/check-referral-code',{referralCode, referral})
    .then((res) => res.json()).catch((error) => error.json())

export const startCopying = (accountId, providerAccountId) =>
  backendRequest(`/api/v1/crm/strategies/${accountId}/start_copying/${providerAccountId}`)
    .then((res) => res.json())
    .catch((error)=> error)

export const stopCopying = (accountId) =>
  backendRequest(`/api/v1/crm/strategies/${accountId}/stop_copying`)
    .then((res) => res.json())
    .catch((error)=> error)

export const getConfigVars = () =>
  backendGetRequest('app-config')
    .then((res) => res.json()).catch((error) => error.json())

export const loginVerificationRequest = (token) =>
  backendRequest('/auth/login-verification', {token})
    .then((res) => {
      if (res.ok) {
        return true
      } else {
        return res.json().then(({error}) => {
          throw error
        })
      }
    })

export const getStrategiesSummary = (accountId, month, year) =>
  backendGetRequest(`/api/v1/crm/strategies/${accountId}/summary`, true, {month, year})
    .then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        return false
      }
    })
    .catch((error)=> error)

export const getStrategiesFollowers = (accountId, month, year, page) =>
  backendGetRequest(`/api/v1/crm/strategies/${accountId}/followers`, true, {month, year, page})
    .then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        return false
      }
    })
    .catch((error)=> error)

export const getCampaignCookie = () =>
  backendGetRequest('/api/get-campaign-cookie', true)
    .then((res) =>res.json())
    .catch((error)=>error)

export const getCountries = (product?, company?) =>
  backendGetRequest('/api/get-countries-web', true, {product,company})
    .then((res) => res.json().then((res) => res)
      .catch((error)=>error)
    ).catch((error)=>error)

export const getTradeCompanionDetails = () =>
  backendGetRequest('/api/get-trade-companion-details', true)
    .then((res) => res.json()).catch((error)=>error)

export const eSignDocument = (paymentProvider, amount) =>
  backendRequest('/api/external/eSignGenie', {paymentProvider, amount})
    .then((res) => res.json())
    .catch((error)=>error)

export const eSignDocumentConfirmation = (paymentProvider, folderId, folderName) =>
  backendRequest('/api/external/eSignGenie/confirmSigning', {paymentProvider, folderId, folderName})
    .then((res) => res.json())
    .catch((error)=>error)

export const setCampaignCookie = (queryParams) =>
  backendRequest('/api/set-campaign-cookie', {queryParams})
    .then((res) => res.json())
    .catch((error) => error)

export const authSession = () =>
  backendRequest('/auth/session')
    .then((res) => res.json())
    .catch((error) => error)

export const getExternalAccountData = (params) => {
  const reqOptions : RequestInit = {
    // 'Content-Type': 'application/json',
    method: 'POST' as const,
    body: (params ? JSON.stringify(params) : undefined),
  }

  const url = config.externalAccountURL + '?' + (new URLSearchParams(params)).toString()

  return fetch(url, reqOptions).then((res) => {
    if (res.ok) {
      return res.json()
    } else {
      return undefined
    }
  })
    .catch((error)=> error)
}

export const getLocale = (viewer: Viewer) : string => {
  const locale : any = get(viewer, 'locale') || getItem('locale', 'en')
  return locale
}
