import React from 'react'
import {Route, Switch, withRouter, Redirect} from 'react-router-dom'
import AffiliateAccountOverview from './AffiliateAccountOverview'

const AffiliateRoutes = ({match: {path}}) => (
  <Switch>
    <Redirect from={`${path}/transactions`} to="/transactions/withdraw"/>
    <Route exact path={`${path}`} component={AffiliateAccountOverview} />
  </Switch>
)

export default withRouter(AffiliateRoutes)
