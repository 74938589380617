import React, {Component} from 'react'
import {get, find, first, findIndex, values, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import {withNamespaces} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import SettingRoutes from './routes'
import messages from '../../assets/messages'
import {PROFILE_SETTINGS_QUERY} from '../../graphql/queries'
import CustomNotificationAlert from '../Common/CustomNotificationAlert'
import {isMobile} from '../../common/utils'
import {config} from '../../config'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
})

const settingsTabs = [
  {key: 'profile', title: messages.profile, relativePath: '/profile'},
  {key: 'profileSettings', title: messages.settings, relativePath: '/other-settings'},
]

class Settings extends Component<any,any> {
  constructor(props) {
    super(props)
    this.state = {
      value: findIndex(settingsTabs, first(settingsTabs)),
    }
  }

  handleChange(value) {
    const {history, match} = this.props
    const currentTab = settingsTabs[value]
    history.push(`${match.url}${currentTab.relativePath}`)
    this.setState({value: value})
  }

  render() {
    const {key} = config
    const {t, location, match, history} = this.props
    const subRoute = values(settingsTabs).filter(settings => `${match.url}${settings.relativePath}`===location.pathname).length > 0

    const currentTab =
      find(settingsTabs, (tab) => location.pathname.indexOf(`${match.url}${tab.relativePath}`) > -1) || first(settingsTabs)

    const currentTabIndex = findIndex(settingsTabs, currentTab)

    let SettingsWL
    switch (key) {
      case 'lydian':
        SettingsWL = <PageTitle title={t(messages.profileAndSettings.i18nKey, messages.profileAndSettings.defaults)}  onBack={() => history.push('/accounts')}>
          <Tabs TabIndicatorProps={{style: {background: '#06262E'}}} value={currentTabIndex} onChange={(_, i) => this.handleChange(i)} variant={!isMobile() ? 'standard' : 'fullWidth'}>
            <Tab label={t(messages.settingsProfileTab.i18nKey, messages.settingsProfileTab.defaults)} style={{fontWeight: 500}}/>
            <Tab label={t(messages.settings.i18nKey, messages.settings.defaults)} style={{fontWeight: 500}}/>
          </Tabs>
        </PageTitle>
        break
      default:
        SettingsWL = <PageTitle title={t(messages.profileAndSettings.i18nKey, messages.profileAndSettings.defaults)}  onBack={() => history.push('/accounts')}>
          <Tabs value={currentTabIndex} onChange={(_, i) => this.handleChange(i)} variant={!isMobile() ? 'standard' : 'fullWidth'}>
            <Tab label={t(messages.settingsProfileTab.i18nKey, messages.settingsProfileTab.defaults)} />
            <Tab label={t(messages.settings.i18nKey, messages.settings.defaults)} />
          </Tabs>
        </PageTitle>
    }

    return (
      <React.Fragment>
        {subRoute && SettingsWL}
        <PageBody removePadding={!subRoute}>
          <CustomNotificationAlert />
          <SettingRoutes />
        </PageBody>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => ({
      loading:props.data?.loading,
      error:props.data?.error,
      documents: get(props.data, 'viewer.documents'),
      appropTests: get(props.data, 'viewer.appropTests'),
      profileChanges: get(props.data, 'viewer.profileChanges'),
      globalQuestionnaire: get(props.data, 'viewer.globalQuestionnaire'),
    })
  }),
)(Settings)
