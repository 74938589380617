import React, {Component} from 'react'
import {withNamespaces,Trans} from 'react-i18next'
import {flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import {Typography} from '@material-ui/core'
import AccountVerificationIcon from './Icon'
import {Link} from 'react-router-dom'
import messages from '../../../assets/messages'
import classNames from 'classnames'

const styles = theme => ({
  iconCell: {
    verticalAlign: 'top',
    paddingTop: '14px',
  },
  submitLink: {
    width: '25%',
    verticalAlign: 'top',
    paddingTop: '17px',
    '& a': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  textCell: {
    padding: 12
  },
  kyc: {
    '&.pending':{
      backgroundColor: theme.palette.lightyellow.color,
    },
    '&.done':{
      backgroundColor: theme.palette.lightgreen.color,
    }
  }
})

class AccountVerificationItem extends Component {
  static propTypes = {
    status: PropTypes.oneOf(['missing', 'pending', 'done']),
    title: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
    ]).isRequired,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
      PropTypes.bool
    ]),
    link: PropTypes.string,
  }

  render() {
    const {type, status, title, children, link, classes} = this.props

    return (
      <TableRow className={classNames(classes[type], status)}>

        <TableCell className={classes.iconCell}>
          <AccountVerificationIcon status={status} />
        </TableCell>

        <TableCell className={classes.textCell}>
          <Typography variant="body1">{title}</Typography>
          {children}
        </TableCell>

        <TableCell className={classes.submitLink} align='right'>
          {link && <Link to={link}><Trans {...messages.submit} /></Link>}
        </TableCell>

      </TableRow>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles( styles, {withTheme: true}),
)(AccountVerificationItem)
