import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {flowRight as compose, omit} from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import {ClickAwayListener} from '@material-ui/core'
import {isMobile} from '../../common/utils'

const styles = theme => ({
  toolTip:{
    '& p':{
      color: theme.palette.common.white
    }
  },
  arrow: {
    color: theme.palette.common.black,
  },
})

class CustomTooltip extends Component {

  constructor(props) {
    super(props)

    this.state = {
      openPopper: false
    }
  }

  render() {
    const {classes, title, placement, children} = this.props
    const {openPopper} = this.state
    const popperProps = {}
    const mobile = isMobile()
    if (mobile) {
      popperProps.open = openPopper
      popperProps.onClick = () => this.setState({openPopper: !openPopper})
      popperProps.onClickAway = () => this.setState({openPopper: false})
    }
    return <ClickAwayListener onClickAway={() => popperProps.onClickAway && popperProps.onClickAway()}>
      <span onClick={() => popperProps.onClick && popperProps.onClick()}>
        <Tooltip
          title={title}
          classes={{tooltip: classes.toolTip, arrow: classes.arrow}}
          placement={placement}
          arrow
          disableFocusListener={mobile}
          disableHoverListener={mobile}
          disableTouchListener={mobile}
          {...omit(popperProps, ['onClickAway'])}
        >
          {children}
        </Tooltip>
      </span>
    </ClickAwayListener>
  }
}

export default compose(
  withStyles(styles))(CustomTooltip)
