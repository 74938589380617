import React from 'react'
import {isMobile} from '../../common/utils/browser'

const PageBody = ({removePadding = false, children, ...props}) => {
  const mobile = isMobile()

  const styles : any = mobile ? {overflow: 'scroll', flex: 1} : {}
  if (mobile && removePadding) {
    styles.padding='0'
  }
  return <div style={styles} {...props}>{children}</div>
}

export default PageBody
