import React from 'react'
import {Font, Page, Text, Image, Document, StyleSheet, PDFViewer} from '@react-pdf/renderer'
import {Trans} from 'react-i18next'
import {toLower} from 'lodash'
import Images from '../Common/Images'
import messages from '../../assets/messages'
import {findCompany} from '../../common/utils'

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf'
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: 'white',
    color: '#023c7d',
  },
  title: {
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 12,
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  text: {
    marginLeft: 12,
    marginBottom: 15,
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 100,
  },
  image: {
    width: 220,
    textAlign: 'center',
    marginBottom: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  footer: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 20,
    right: 20,
    textAlign: 'center',
    color: '#999999',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 5,
  },
  pdfViewerWrapper: {
    width: '100%',
    minHeight: '100vh',
  }
})
export const SolicitationAcceptance = () => {
  const companyObject = findCompany()
  return <PDFViewer style={styles.pdfViewerWrapper}>
    {/*@ts-ignore*/}
    <Document>
      {/*@ts-ignore*/}
      <Page size="A4" style={styles.body}>
        <Image
          style={styles.image}
          source={Images[`logo-${toLower(companyObject.brand)}.png`]}
        />
        <Text style={styles.title} fixed>
          <Trans {...messages.MVEHoldingsLtd} />
          <Trans {...messages.solicitationAcceptanceTitle} />
        </Text>
        <Text style={styles.text} fixed>
          <Trans {...messages.solicitationAcceptancePart1} />
        </Text>
        <Text style={styles.text} fixed>
          <Trans {...messages.solicitationAcceptancePart2} />
        </Text>
        <Text style={styles.footer} fixed>
          <Trans {...messages.MVEHoldingsLtdFooter}/>
        </Text>
      </Page>
    </Document>
  </PDFViewer>
}
