import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import DoneIcon from '@material-ui/icons/Done'
import classNames from 'classnames'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {currencies} from '@bdswiss/common-enums'
import messages from '../../assets/messages'
import {Trans, withNamespaces} from 'react-i18next'
import {map, isArray, flowRight as compose, get} from 'lodash'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import {FormHelperText} from '@material-ui/core'
import {getFormattedAmount, isFirstRawPlan} from '../../common/utils/general'
import {withRouter} from 'react-router-dom'

const styles = theme => ({
  title: {
    color: theme.palette.secondary.dark,
    fontSize: 24,
  },
  subheader: {
    color: theme.palette.primary.main,
    fontSize: 35,
    paddingTop: 20
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  cardLoadingActions: {
    display: 'block',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  cardContent: {
    flex: '1 0 auto',
    justifyContent: 'center',
  },
  cardPricing: {
    textAlign: 'left' as const
  },
  cardRoot: {
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100%',
    '&:hover':{
      boxShadow: `0 0 5px 0px ${theme.palette.primary.main}`
    }
  },
  cardSelected:{
    boxShadow: `0 0 5px 0px ${theme.palette.primary.main}`
  },
  textGreen:{
    color: theme.palette.green.color
  },
  textYellow:{
    color: theme.palette.yellow.color
  },
  listItem: {
    fontSize: 14,
    paddingLeft: 4,
    paddingRight: 4,
  },
  item: {
    padding: 5
  },
  currentButton:{
    '&:hover':{
      backgroundColor: 'transparent'
    }
  },
  highlightedText: {
    color: theme.palette.primary.main,
  },
  errorMessage:{
    color:  theme.palette.error.main,
  },
  dialogTitle:{
    margin: 10,
    textAlign: 'center' as const
  },
  symbol:{
    color:  theme.palette.secondary.dark,
    fontSize: 20
  },
  hightlight: {
    fontWeight: 400,
    color:  theme.palette.secondary.main
  },
  links: {
    textAlign: 'right' as const,
    marginTop: 17,
    [theme.breakpoints.down('sm')]: {
      marginTop: 5
    },
    cursor:'pointer' as const
  },
  textBlue:{
    color: theme.palette.primary.main
  },
  inlineTypography: {
    paddingLeft: '1rem',
    display:'inline-block',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  proFreeTicks:{
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      display:'block',
      lineHeight: '32px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    topDiv:{
      paddingBottom: '1rem'
    }
  },
})
export class PlanCard extends Component<any,any> {

  constructor(props) {
    super(props)

    this.state = {
      openChangeModal: false,
      openCancelReactivateModal: false,
      loadingProceed: false,
      loadingCancelReactivate: false,
    } as any
  }

  render() {
    const {classes,t, onClick, subscription, plan, currentPlan, showActions, subscriptionStatus, currentState,
      buttonAction, single, noActions, vps, isFree} = this.props

    const subHeaderFree =`${t(messages.free.i18nKey, messages.free.defaults)}`
    const subHeader =  <span><span className={classes.symbol}>{`${currencies[get(plan, 'currency') || 'EUR']['symbol']}`}</span>{` ${get(plan, 'defaultAmount')} `}
      <span className={classes.symbol}>{`/ ${t(messages.month.i18nKey, messages.month.defaults)}`}</span></span>
    const planVolumeAmount = !vps && (isFirstRawPlan(plan) ? get(plan, 'minVolume') : get(plan, 'minVolume')+0.01)

    const isFreePlan = get(plan, 'isFree') && isArray(get(plan, 'isFree')) ? isFree : get(plan, 'isFree')
    return (
      <Card className={classNames(!single && classes.cardRoot, (currentPlan && !single) ? classes.cardSelected:'')}
        onClick={() => onClick && onClick()}>
        <CardHeader
          title={get(plan, 'label')}
          subheader={(!isFreePlan) ? subHeader : subHeaderFree}
          titleTypographyProps={{align: 'center'}}
          subheaderTypographyProps={{align: 'center'}}
          classes={{
            title: classes.title,
            subheader: classes.subheader,
          }}
        />
        <CardContent className={classes.cardContent}>
          <List>
            {!vps && <ListItem divider={true} classes={{root: classes.item}}>
              <ListItemIcon>
                <DoneIcon className={classNames([classes.textGreen, classes.floatRight])} />
              </ListItemIcon>
              <ListItemText
                primary={`${getFormattedAmount({currency: 'USD',amount:(planVolumeAmount)})} - ${getFormattedAmount({currency: 'USD',amount:get(plan, 'maxVolume')})} ${t(messages.volume.i18nKey, messages.volume.defaults)}`}
                classes={{primary: classes.listItem}}
              />
            </ListItem>
            }
            {map((get(plan, 'features')),(value, key) =>{
              const icon = (value === 'done')
                ? <DoneIcon className={classNames([classes.textGreen, classes.floatRight])} />
                : <InfoOutlined className={classNames([classes.textYellow, classes.floatRight])} />
              return <ListItem divider={true} key={key} classes={{root: classes.item}}>
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={t(messages[key].i18nKey, messages[key].defaults)}
                  classes={{primary: classes.listItem}}
                />
              </ListItem>
            })}
          </List>
        </CardContent>
        <CardActions className={classes.cardActions} disableSpacing>
          {showActions && !noActions && <Button fullWidth variant={(currentPlan) ? 'text' : 'contained'} color="primary"
            className={(currentPlan) ? classes.currentButton : ''} disabled={subscription && (!subscription.isActive && !subscription.isExpired)} size={'large'}
            onClick = {() => buttonAction()} >
            {(currentPlan)
              ? t(messages.currentPlan.i18nKey, messages.currentPlan.defaults)
              : <Trans {...messages[subscriptionStatus.actionText]} />
            }
          </Button>}
        </CardActions>
        {showActions && currentPlan && subscriptionStatus.currentActionText && <CardActions className={classes.cardLoadingActions} disableSpacing>
          <Button
            fullWidth
            variant="contained"
            size="large"
            color={(subscription && subscription.isActive) ? 'secondary' : 'primary'}
            onClick = {() => buttonAction(true)}
          >{<Trans {...messages[subscriptionStatus.currentActionText]} />}
          </Button>
          {currentState.statusCancelReactivate && currentState.statusCancelReactivate==='failure' &&
          <FormHelperText className={classes.errorMessage}>{currentState.cancelReactivateError}</FormHelperText>}
        </CardActions>}
      </Card>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles),
  withRouter)(PlanCard)
