import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {flowRight as compose} from 'lodash'
import {depositVendors, frontends} from '@bdswiss/common-enums'
import {withNamespaces, Trans} from 'react-i18next'
import {withStyles} from '@material-ui/core'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import InputAdornment from '@material-ui/core/InputAdornment'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import messages from '../../../../assets/messages'
import {getPlatform, findCompany} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'

const style = (theme) => ({
  cryptoMessage: {
    color: theme.palette.red.color,
    fontStyle:'italic'
  },
  warningMessage: {
    fontWeight: 400,
    margin: '0 50px 0 35px',
  },
})
class CryptoProvider extends Component {

  state = {}

  getDepositWalletAddress() {
    const {account, amount, providerProperties: {provider, paymentKey}, onError, useVirtualPaymentPage, bonusAmount, bonusTerms} = this.props
    this.setState({gettingWalletAddress: true})

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        depositCurrency: paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url: walletAddress}} = newDeposit
        this.setState({walletAddress, gettingWalletAddress: false})
      })
      .catch((e) => {
        this.setState({gettingWalletAddress: false})
        onError && onError(e)
      })
  }

  render() {
    const {classes, providerProperties: {provider, paymentKey}} = this.props
    const companyObject = findCompany()
    const {brandLabel: companyLabel, emailDomain} = companyObject
    const {gettingWalletAddress, walletAddress, walletCopied} = this.state

    return (
      <React.Fragment>
        {
          !walletAddress &&
          <React.Fragment>
            <PaymentActionButton
              onClick={() => this.getDepositWalletAddress()}
              buttonMessage={messages.getCryptoWalletAddress}
              loading={gettingWalletAddress}
            />
            <br/>
            {provider === depositVendors.btcproxy.value && <Typography variant="subtitle2" paragraph className={classes.warningMessage}>
              <Trans {...messages.bech32WalletAddress} />
              <br/>
              <br/>
              <Trans {...messages.extraFeeForCryptoWarning} />
            </Typography>}
          </React.Fragment>
        }
        <Grid container alignItems="center" alignContent="center" justifyContent="center" spacing={3}>
          {
            walletAddress &&
            <Grid item>
              <Typography variant="body2" paragraph>
                <Trans {...messages.copyCryptoWalletAddress} values={{walletType: paymentKey}}/>
              </Typography>
              <TextField
                id="crypto-deposit-wallet-address"
                variant="outlined"
                disabled
                fullWidth
                value={walletAddress}
                onCopy={() => this.setState({walletCopied: true})}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard text={walletAddress} onCopy={() => this.setState({walletCopied: true})}>
                        <IconButton aria-label="Copy to clipboard">
                          <FileCopyOutlined  />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              {
                walletCopied &&
                <Typography variant="caption">
                  <Trans {...messages.cryptoWalletAddressCopied} values={{walletType: paymentKey, company: companyLabel}}/>
                </Typography>
              }
              {
                !walletCopied &&
                <Typography variant="caption" className={classes.cryptoMessage}>
                  <Trans {...messages.belowWalletAddressMessage} values={{walletType: paymentKey}}/>
                </Typography>
              }
            </Grid>
          }
          <Grid item>
            <Typography align="center" variant="h6">
              <Trans {...messages.importantNotes} />
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={<Trans {...messages.cryptoDepositNote1} values={{company: companyLabel}} />} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Trans {...messages.cryptoDepositNote2} />} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Trans {...messages.cryptoDepositNote3} values={{emailDomain}}/>} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Trans {...messages.cryptoDepositNote4} />} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Trans {...messages.cryptoDepositNote5} />} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

CryptoProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withNamespaces(),
  withStyles(style),
  withCreateDeposit
)(CryptoProvider)
