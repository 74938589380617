import {forEach, includes, remove} from 'lodash'
import {socketEvents} from '@bdswiss/common-enums'
import {config} from './config'


const CONNECTION_STATE = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  RECONNECTING: 'RECONNECTING',
}

class SocketManager {

  constructor () {
    this.rooms = []
    this.isInitialized = false
  }

  initialize(viewerId) {

    if (this.isInitialized) return true
    //@ts-ignore
    if (!(window.io && config && viewerId)) return false
    const {restEndpointUrl} = config
    this.viewerId = viewerId
    this.connectionState = CONNECTION_STATE.DISCONNECTED
    //@ts-ignore
    this.connection = window.io(`${restEndpointUrl}/clients`, {
      transports: ['websocket'],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 10000,
      reconnectionAttempts: 10,
    })

    this.connection.on('connect', () => {
      this.connection.emit(socketEvents.online.value)
      if (this.connectionState === CONNECTION_STATE.RECONNECTING) {
        forEach(this.rooms, (room) => this.joinRoom(room))
      } else {
        this.joinRoom(`web-client-${this.viewerId}`)
      }
      this.connectionState = CONNECTION_STATE.CONNECTED
    })

    this.connection.on('reconnecting', () => {
      if (this.connectionState !== CONNECTION_STATE.RECONNECTING) {
        this.connectionState = CONNECTION_STATE.RECONNECTING
      }
    })

    this.isInitialized = true

    return this.isInitialized
  }

  deinitialize() {
    if (!this.isInitialized || !this.connection) return
    this.leaveRoom(`web-client-${this.viewerId}`)
    this.connection.close()
    this.rooms = []
    this.isInitialized = false
    this.connection = null
  }

  joinRoom(room) {
    if (!this.connection) return
    this.connection.emit(socketEvents.join.value, room)
    if (!includes(this.rooms, room)) this.rooms.push(room)
  }

  leaveRoom(room) {
    if (!this.connection) return
    this.connection.emit(socketEvents.leave.value, room)
    if (includes(this.rooms, room)) remove(this.rooms, (r) => r === room)
  }
}

export const socketManager = new SocketManager()

