import React from 'react'
import {get, map, isFunction, flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import {countries, withdrawalPaymentVendors, directa24TaxNumberLabels} from '@bdswiss/common-enums'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import {AlertDialog} from '../../Common/Dialog'
import messages from '../../../assets/messages'

const styles = theme => ({
  subtitleGrid: {
    padding: 8,
  },
  labelField: {
    paddingRight: 4,
  },
  valueField: {
    paddingLeft: 4,
    paddingTop:6
  },
})

const ConfirmWithdrawalModal = (props) => {
  const {open, classes, t, locale, methodFields, form, width, onSubmit, onCancel, accountLabel, amount,
    buttonLoading, country, method, availableMethod,
  } = props
  const localeParams = {}
  if (method === withdrawalPaymentVendors.directa24.value) {
    localeParams.id_type = get(directa24TaxNumberLabels, country.toLowerCase()) ? directa24TaxNumberLabels[country.toLowerCase()].label : 'ID'
  } else if (method === withdrawalPaymentVendors.payRetailers.value) {
    localeParams.id_type = '#'
  }
  return (
    <AlertDialog
      open={open}
      title={t(messages.submitWithdrawalTitle.i18nKey, messages.submitWithdrawalTitle.defaults)}
      agreeText={t(messages.submit.i18nKey, messages.submit.defaults)}
      onAgree={() => onSubmit()}
      disagreeText={t(messages.edit.i18nKey, messages.edit.defaults)}
      onDisagree={() => onCancel()}
      buttonLoading={buttonLoading || false}
    >
      <Grid container direction="row" alignContent="center" justifyContent="center">
        <Grid item xs={12} className={classes.subtitleGrid}>
          <Typography variant="body2">
            <Trans {...messages.correctDetails} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={isWidthDown('sm', width) ? 'column' : 'row'}>
            <Grid
              container item md={6} xs={12}
              justifyContent={isWidthDown('sm', width) ? 'flex-start' : 'flex-end'}
              className={classes.labelField}
            >
              <Typography variant="body2">
                <Trans {...messages.account} />:
              </Typography>
            </Grid>
            <Grid container item md={6} xs={12} justifyContent="flex-start" className={classes.valueField}>
              <Typography variant="body1">
                {accountLabel}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={isWidthDown('sm', width) ? 'column' : 'row'}>
            <Grid
              container item md={6} xs={12}
              justifyContent={isWidthDown('sm', width) ? 'flex-start' : 'flex-end'}
              className={classes.labelField}
            >
              <Typography variant="body2">
                <Trans {...messages.amount} />:
              </Typography>
            </Grid>
            <Grid container item md={6} xs={12} justifyContent="flex-start" className={classes.valueField}>
              <Typography variant="body1">
                {amount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {map((methodFields), (field, i) => {
          const options = isFunction(field.options)
            ? field.options({
              withdrawalPaymentVendor: method,
              country: get(countries[country], 'value'),
              paymentOption: get(availableMethod, 'paymentOption'),
              bankCode: get(form, 'provider'),
            }) : field.options
          const label = field.localization.t(locale, localeParams)
          const fieldValue = get(form, `${field.key}_label`) ||
           (options ? get(options[get(form, field.key, '')], 'label') : get(form, field.key, ''))
          if (!fieldValue) return null
          return <Grid item xs={12} key={i}>
            <Grid container direction={isWidthDown('sm', width) ? 'column' : 'row'}>
              <Grid
                container item md={6} xs={12}
                justifyContent={isWidthDown('sm', width) ? 'flex-start' : 'flex-end'}
                className={classes.labelField}
              >
                <Typography variant="body2">
                  {label}:
                </Typography>
              </Grid>
              <Grid container item md={6} xs={12} justifyContent="flex-start" className={classes.valueField}>
                <Typography variant="body1">
                  {fieldValue}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        })}
      </Grid>
    </AlertDialog>
  )
}

ConfirmWithdrawalModal.propTypes = {
  locale: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  methodFields: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  method: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  accountLabel: PropTypes.string.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth(),
  withNamespaces(),
)(ConfirmWithdrawalModal)
