import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'

const StatusParamRoute = props => {
  const {search} = useLocation()
  const urlQuery = new URLSearchParams(search)
  const statusParam = urlQuery.get('status')

  const accountId = props.match.params.accountId

  const status = {
    INITIATED: 'pending',
    PENDING: 'pending',
    APPROVED: 'success',
    DECLINED: 'cancelled',
    ERROR: 'failed',
  }

  return <Redirect to={`/transactions/${accountId}/deposit/result/${status[statusParam]}`} />
}

export default StatusParamRoute


