import React, {Component} from 'react'
import {includes, get, find} from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import {accountTypes, kycStatuses, mt4Servers, whiteLabels} from '@bdswiss/common-enums'
import Amount from '../../Common/Amount'
import classNames from 'classnames'
import {isMAMScSubAccount, isAffiliatesAccount, isForexAccount, getForcedServerName, getAccountSubtype, getAccountLabel, isBitnukAccount, isCentAccount} from '../../../common/utils/accounts'
import messages from '../../../assets/messages'
import AppContext from '../../Common/contexts/AppContext'
import {isMobile, isWhiteLabel} from '../../../common/utils'
import {config} from '../../../config'
import {accountCategoriesDetails, serversLabel} from '../../../common/utils/uioptions'
import moment from 'moment'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  cardRoot: {
    cursor: 'pointer',
    position:'relative' as const,
    height: '100%'
  },
  cardLabel: {
    fontSize: 16,
    fontWeight: 400,
  },
  amount: {
    display: 'flex',
    flexDirection: 'row' as const,
  },
  amountCurrency: {
    fontSize: 21,
    fontWeight: 500,
    padding: '0 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  amountValue: {
    fontSize: 35,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  amountFraction: {
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  amountMobile:{
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  typeBadge: {
    height: 40,
    width: 40,
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '40px',
    borderRadius: 20,
    textTransform: 'uppercase' as const,
    textAlign: 'center' as const,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 18,
    backgroundColor:  theme.palette.extralightblue.color,
    color: theme.palette.lightdark.color,
    [theme.breakpoints.down('xs')]: {
      width: 35,
      height: 35,
      lineHeight: '37px',
      [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 14,
    },
  },
  accessNowText: {
    fontSize: 15,
    fontWeight: 500,
    color: theme.palette.lightgold.color,
  },
  forexMt4: {
    color: theme.palette.lightdark.color,
    backgroundColor:  theme.palette.extralightblue.color,
  },
  pammForex: {
    color: theme.palette.lightdark.color,
    backgroundColor: theme.palette.extralightblue.color,
  },
  spotOption: {
    color: theme.palette.lightdark.color,
    backgroundColor: theme.palette.extralightblue.color,
  },
  tradeSmarter: {
    color: theme.palette.lightdark.color,
    backgroundColor: theme.palette.extralightblue.color,
  },
  default: {
    color: theme.palette.lightdark.main,
    backgroundColor: theme.palette.extralightblue.color,
  },
  affiliate: {
    color: theme.palette.lightdark.color,
    backgroundColor: theme.palette.extralightblue.color,
  },
  cardContent:{
    flexGrow:1,
    [theme.breakpoints.up('sm')]: {
      minHeight: 80,
    },
  },
  isDemoArchived:{
    borderRadius: '5px',
    padding: '0px 7px',
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']:4
  },
  badgesBox:{
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: 8,
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    [theme.breakpoints.down('xs')]: {
      top: 5,
    },
  },
  isDemo:{
    backgroundColor: theme.palette.secondary.main,
  },
  isDemoText:{
    color: theme.palette.secondary.light,
    opacity: 1,
  },
  isArchived:{
    backgroundColor:  theme.palette.lightred.color,
  },
  isArchivedText:{
    color: theme.palette.red.color,
  },
  ib: {
    color: theme.palette.green.color,
    backgroundColor:  theme.palette.lightgreen.color,
  },
  basic: {
    color: theme.palette.primary.main,
    backgroundColor:  theme.palette.lightblue.color,
  },
  raw: {
    color: theme.palette.cyan.color,
    backgroundColor:  theme.palette.lightcyan.color,
  },
  black: {
    color: theme.palette.purple.color,
    backgroundColor:  theme.palette.lightpurple.color,
  },
  invest_plus: {
    color: theme.palette.harlequin.color,
    backgroundColor:  theme.palette.lightharlequin.color,
  },
  copyingText:{
    backgroundColor: theme.palette.red.color,
  },
  competitionDiv:{
    backgroundColor:  theme.palette.green.color,
  },
  competitionText:{
    color: theme.palette.green.textColor,
  },
  providing: {
    color: theme.palette.primary.main,
  },
  bonusDiv:{
    backgroundColor: theme.palette.cyan.color,
  },
  bonusText:{
    fontWeight: 500
  },
  premium: {
    color: theme.palette.premium.color,
    backgroundColor: theme.palette.lightpremium.color,
  },
})

class AccountCardLydian extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  static propTypes = {
    account: PropTypes.object,
    locale: PropTypes.string,
    onClick: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      fulldialogOpen: false,
    }
  }

  render() {
    const {classes, account, onClick, t, kycStatus} = this.props
    const {downloadTrader, key} = config
    const accountType = accountTypes[account.__typename]
    const accountSubtype = getAccountSubtype(account.accountSubtype) as any
    const category = get(accountTypes[account.__typename], 'category')
    const caption = account.isDemo ? `${serversLabel[category]} · ${account.remoteId} · Demo` :
      accountSubtype && !isMAMScSubAccount(account) ? `${serversLabel[category]} · ${account.remoteId} · ${accountSubtype.label}` :
        isBitnukAccount(account) ? account.login : account.remoteId
    const {locale, companyObject} = this.context
    // SubTypes for Mam account are not translated
    const label = isMAMScSubAccount(account) ? accountSubtype.label : getAccountLabel(account, locale)
    const verifiedAffiliate = (isAffiliatesAccount(account) && includes(['Approved'],
      get(account, 'serviceFields.affiliateStatus')))
    const traderConfig = accountTypes[account.__typename] ? downloadTrader[accountTypes[account.__typename].category] : downloadTrader.default
    const missingVerificationsAffiliate = (isAffiliatesAccount(account) && kycStatus !== kycStatuses.approved.value)
    const classesCategory = classes[accountType.subCategory] || (!account.isDemo && classes[account.accountSubtype])
      || classes[accountType.category]
    const shortLabel = (accountType.subCategory && accountType.shortLabel) || (account.accountSubtype
      && get(accountSubtype, 'shortLabel')) || accountType.shortLabel || account.currency
    const copyTradingEnabled = get(account.copyTrading,'isActive')
    const hasActiveCompetition = account.competitions && find(account.competitions, 'active')
    const provideCopyTrading =  get(account, 'provideCopyTrading')
    const hasActiveBonus = !!account.credit
    const {forcedServerName} = getForcedServerName(account)
    const whiteLabel = isWhiteLabel()
    const today = moment().format()
    const showBitnukDetails = accountCategoriesDetails['cryptoWallet'].bitnuk
    const bitnukEndDate = moment(showBitnukDetails.startDate).add(showBitnukDetails.activeDays, 'd')

    return <Card classes={{root: classes.cardRoot}} onClick={() => onClick && onClick(account)}>
      <Grid container>
        <Grid item style={{alignSelf: 'center'}}>
          <div className={classNames(classes.typeBadge, classesCategory)}>{shortLabel}</div>
        </Grid>
        <Grid item className={classes.cardContent}>
          <Typography className={classes.cardLabel}>{label}</Typography>
          {isMobile() &&  <div className={classes.amountMobile}><Typography variant='caption'>{caption}</Typography>
            <Typography variant='body2' className={classes.amount}>
              <Amount value={account.balance} currency={account.currency} locale='en-US' classes={{
                currency: classes.amountCurrency,
                value: classes.amountValue,
                fraction: classes.amountFraction
              }} />
            </Typography>
          </div>}
          {!isMobile() && <Typography variant='body1' className={classes.amount}>
            <Amount value={account.balance} currency={account.currency} locale='en-US' classes={{
              currency: classes.amountCurrency,
              value: classes.amountValue,
              fraction: classes.amountFraction
            }} />
          </Typography>
          }
          {(isForexAccount(account) || isCentAccount(account)) && <Typography variant='caption'>
            {forcedServerName || ((account.server && (!whiteLabel|| (whiteLabel && get(whiteLabels[key], 'internal')))) ? get(mt4Servers[account.server], `name[${companyObject['key']}]`):
              (account.isDemo ? traderConfig.serverName.demo : whiteLabel && !get(whiteLabels[key], 'internal') ? get(traderConfig.serverName, account.server) : traderConfig.serverName.real))}
          </Typography>
          }
        </Grid>
        <Grid item className={classes.badgesBox}>
          {accountCategoriesDetails[accountType.category] && get(accountCategoriesDetails[accountType.category][accountType.subCategory], 'newBadge')
            && moment(today).isSameOrBefore(moment(bitnukEndDate).format(), 'day') && !account.balance
            && !account.isArchived && !account.isReadOnly && !account.isViewOnly && !copyTradingEnabled &&
            <Grid item className={classNames(classes.isDemoArchived, classes.copyingText)}>
              <Typography variant='caption' className={classes.isDemoText}><Trans {...messages.new} /></Typography>
            </Grid>
          }
          {!account.isArchived && account.isReadOnly && !account.isViewOnly &&
          <Grid item className={classNames(classes.isDemoArchived, classes.isDemo)}>
            <Typography variant='caption' className={classes.isDemoText}><Trans {...messages.readOnly} /></Typography>
          </Grid>
          }
          {!account.isArchived && account.isViewOnly && <Grid item className={classNames(classes.isDemoArchived, classes.isDemo)}>
            <Typography variant='caption' className={classes.isDemoText}><Trans {...messages.viewOnly} /></Typography>
          </Grid>
          }
          {accountType.isDemo && <Grid item className={classNames(classes.isDemoArchived, classes.isDemo)}>
            <Typography variant='caption' className={classes.isDemoText}><Trans {...messages.demo} /></Typography>
          </Grid>
          }
          {account.isArchived && <Grid item className={classNames(classes.isDemoArchived, classes.isArchived)}>
            <Tooltip title={t(messages.archivedTooltip.i18nKey, messages.archivedTooltip.defaults)} placement="top">
              <Typography variant='caption' className={classes.isArchivedText}>
                <Trans {...messages.archived} /></Typography>
            </Tooltip>
          </Grid>
          }
          {isAffiliatesAccount(account) && <Grid item className={classNames(classes.isDemoArchived, classes.isDemo)}>
            <Typography variant='caption' className={classes.isDemoText}>
              {!verifiedAffiliate ? <Trans {...messages.pendingApproval} /> : ''}
              {verifiedAffiliate && missingVerificationsAffiliate ? <Trans {...messages.notVerified} /> : ''}
            </Typography>
          </Grid>
          }
          {hasActiveCompetition && !account.isArchived && !account.isReadOnly && !account.isViewOnly && !copyTradingEnabled &&
          <Grid item className={classNames(classes.isDemoArchived, classes.competitionDiv)}>
            <Typography variant='caption' className={classes.competitionText}><Trans {...messages.competition} /></Typography>
          </Grid>
          }
          {provideCopyTrading &&
          <Grid item className={classNames(classes.isDemoArchived, classes.basic)}>
            <Typography variant='caption' className={classes.providing}><Trans {...messages.providing} /></Typography>
          </Grid>
          }
          {isForexAccount(account) && copyTradingEnabled && <Grid item className={classNames(classes.isDemoArchived, classes.copyingText)}>
            <Typography variant='caption' className={classes.isDemoText}><Trans {...messages.copying} /></Typography>
          </Grid>
          }
          {hasActiveBonus && !account.isArchived && !account.isReadOnly && !account.isViewOnly && !copyTradingEnabled &&
          <Grid item className={classNames(classes.isDemoArchived, classes.bonusDiv)}>
            <Typography variant='caption' className={classNames(classes.isDemoText, classes.bonusText)}><Trans {...messages.bonus} /></Typography>
          </Grid>
          }
        </Grid>
        <Grid item style={{alignSelf: 'center'}}>
          <Typography className={classes.accessNowText} variant='inherit'><Trans {...messages.accessNow} /></Typography>
        </Grid>
      </Grid>
    </Card>
  }
}



export default withStyles(styles, {withTheme: true})(withNamespaces()(AccountCardLydian))
