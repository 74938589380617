//@ts-nocheck
import {find, get, flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import {withRouter} from 'react-router-dom'
import {graphql} from 'react-apollo'
import ListItem from '@material-ui/core/ListItem'
import Checkbox from '@material-ui/core/Checkbox'
import {withNamespaces, Trans} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import {frontends, depositStatuses} from '@bdswiss/common-enums'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import {withCreateDeposit, withCreateDepositPCT, PaymentActionButton} from './helpers'
import LoadingButton from '../../../Common/LoadingButton'
import {config} from '../../../../config'
import messages from '../../../../assets/messages'
import {isMobile} from '../../../../common/utils/browser'
import {CLIENT_DATA_QUERY} from '../../../../graphql/queries'
import {REMOVE_PCT_MUTATION} from '../../../../graphql/mutations'
import {getPlatform, safeParseJSON} from '../../../../common/utils'

const style = (theme) => ({
  rootGrid: {
    flexGrow: 1,
  },
  topSpacing: {
    marginTop: theme.spacing(10),
  },
  hide: {
    display: 'none',
  },
  checkbox: {
    padding:'6px 6px 6px 0',
    marginLeft: -3
  },
  link: {
    cursor: 'pointer',
  },
  email: {
    fontWeight: 400,
  },
  textCenter: {
    textAlign: 'justify'
  },
})

class PaypalProvider extends Component {

  state = {creatingDeposit: false, rememberMe: false, forgetMe: false}

  constructor(props) {
    super(props)
    this.createDeposit = this.createDeposit.bind(this)
  }

  componentDidMount() {
    const {account, amount, history, providerProperties: {additionalFields}} = this.props
    const {clientToken} = safeParseJSON(additionalFields || '{}')
    const {createDeposit} = this
    window.braintree.client.create({
      authorization: clientToken
    }).then((clientInstance) => {
      window.braintree.dataCollector.create({
        client: clientInstance,
        paypal: true,
      }, (err, dataCollectorInstance) => {
        if (err) return
        document.getElementById('data-collector').value = dataCollectorInstance.deviceData
        return
      })
      return window.braintree.paypalCheckout.create({
        client: clientInstance
      })
    }).then((paypalCheckoutInstance) => (
      window.paypal.Button.render({
        env: config.paypal.env,
        commit: true,
        style: {
          color: 'blue',
          shape: 'rect',
          size: 'large',
        },
        payment: () => (
          paypalCheckoutInstance.createPayment({
            flow: 'vault',
            amount,
            currency: account.currency,
            landingPageType: 'login',
            enableShippingAddress: false,
            shippingAddressEditable: false,
          })
        ),
        onAuthorize: (data) => (
          paypalCheckoutInstance.tokenizePayment(data).then((payload) => (
            createDeposit({...data, nonce: payload.nonce, payload})
          ))
        ),
        onCancel: () => {
          history.push(`/transactions/${account.id}/deposit/result/cancelled`)
        },
        onError: (err) => {
          history.push(`/transactions/${account.id}/deposit/result/failed`)
        },
      }, '#paypal-button-container')
    )).catch((e) => {
      history.push(`/transactions/${account.id}/deposit/result/failed`)
    })
  }

  removePct() {
    const {viewer: {paymentCardTokens}, providerProperties: {provider}} = this.props
    const methodToken = find(paymentCardTokens, (t) => t.vendor === provider)
    this.setState({creatingDeposit: true})
    this.props.removePct({variables: {paymentCardTokenId: methodToken.id}}).then(() => {
      this.setState({creatingDeposit: false})
    }).catch(() => this.setState({creatingDeposit: false}))
  }

  createDeposit(data, tokenId) {
    const {providerProperties: {provider, paymentKey}, account, amount, history, useVirtualPaymentPage, bonusAmount, bonusTerms} = this.props
    const {rememberMe, forgetMe} = this.state

    this.setState({creatingDeposit: true})

    let variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        paypalData: {
          ...(data || {}),
          rememberMe,
          forgetMe,
        },
        bonusAmount,
        bonusTerms,
      }),
    }
    let depositFunction = this.props.createDepositRequest
    if (tokenId) {
      depositFunction = this.props.createDepositRequestPCT
      variables = {
        accountId: account.id,
        amount,
        paymentCardTokenId: tokenId,
        frontend: frontends.web2.value,
        args: JSON.stringify({
          paymentKey,
          platform: getPlatform(),
        }),
      }
    }

    depositFunction({variables})
      .then(({data: {newDeposit}}) => {
        this.setState({creatingDeposit: false})
        const {deposit, payment} = newDeposit
        if (deposit.status !== depositStatuses.pending.key) {
          const resultStatus = deposit.status === depositStatuses.completed.key
            ? 'success' : deposit.status === depositStatuses.failed.key ? 'failed' : 'pending'
          history.push(`/transactions/${account.id}/deposit/result/${resultStatus}`)
        } else {
          const payload = JSON.parse(payment.payload)
          this.setState({formData: payload, creatingDeposit: false, doSubmitForm: true})
        }
      })
      .catch((err) => {
        this.setState({creatingDeposit: false})
        if (err.networkError) {
          history.push(`/transactions/${account.id}/deposit/result/failed`)
        } else {
          const {showActualError, message} = get(err, 'graphQLErrors[0]')
          history.push({
            pathname: `/transactions/${account.id}/deposit/result/failed`,
            state: {showActualError, errorMessage: message},
          })
        }
      })
  }

  render() {
    const {viewer: {paymentCardTokens}, providerProperties: {provider}, classes, width, t} = this.props
    const {creatingDeposit} = this.state
    const {paypal} = config
    const onSmallScreen = isWidthDown('xs', width)
    const methodToken = find(paymentCardTokens, (t) => t.vendor === provider)
    const {email} = safeParseJSON(get(methodToken, 'meta', '{}'))

    return (
      <React.Fragment>
        <Grid container className={(creatingDeposit || email) ? classes.hide : ''} direction="column" alignContent="center">
          <Grid item xs={12} className={!onSmallScreen ? classes.topSpacing : ''} id="paypal-button-container" />
          <Grid item xs={12} className={classes.hide} id="data-collector" />
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.rememberMe}
                  name='rememberMe'
                  onChange={(_, rememberMe) => this.setState({rememberMe})}
                  value="rememberMe"
                  color='primary'
                  className={isMobile() ? classes.checkbox : ''}
                />
              }
              label={t(messages.rememberMe.i18nKey, messages.rememberMe.defaults)}
              className={isMobile() ? classes.fullWidthMob : ''}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className={creatingDeposit && !email ? '' : classes.hide}>
          <LoadingButton
            id='loadingButton'
            onClick={() => {}}
            fullWidth={isMobile()}
            disabled={creatingDeposit}
            status=""
          >
            <Trans {...messages.pleaseWait} />
          </LoadingButton>
        </Grid>
        {email &&
          <React.Fragment>
            <Grid container justifyContent="center" className={creatingDeposit ? classes.hide : ''}
              direction="column" alignItems="center"
            >
              <Grid item xs={12} className={!onSmallScreen ? classes.topSpacing : ''}>
                <Typography variant='body1'> <Trans {...messages.payInstantlyWith} /></Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' className={classes.email}>{email}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant='body2'
                  onClick={() => this.removePct()}
                  color="primary"
                  className={classes.link}
                >
                  <Trans {...messages.unlinkMyAccount} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <PaymentActionButton
                  loading={creatingDeposit}
                  disable={false}
                  onClick={() => this.createDeposit({}, methodToken.id)}
                  noMargin
                />
              </Grid>
            </Grid>
          </React.Fragment>
        }
        <br />
        {get(paypal, 'disclaimer') &&
          <Grid container justifyContent="center" direction="column" alignItems="center">
            <Grid item>
              <Typography align="center" variant="body2">
                <Trans {...messages.disclaimer} />
              </Typography>
              <List>
                <ListItem>
                  <ListItemText className={classes.textCenter} primary={<Trans {...messages.paypalDisclaimer1} />} primaryTypographyProps={{variant: 'body1'}}/>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.textCenter} primary={<Trans {...messages.paypalDisclaimer2} />} primaryTypographyProps={{variant: 'body1'}} />
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.textCenter} primary={<Trans {...messages.paypalDisclaimer3} />} primaryTypographyProps={{variant: 'body1'}} />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        }
      </React.Fragment>
    )
  }
}

PaypalProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withCreateDepositPCT,
  withMobileDialog(),
  withStyles(style),
  withCreateDeposit,
  withNamespaces(),
  withWidth(),
  withRouter,
  graphql(REMOVE_PCT_MUTATION, {
    name: 'removePct',
    options: () => ({
      refetchQueries: [
        {query: CLIENT_DATA_QUERY}
      ]
    })
  })
)(PaypalProvider)
