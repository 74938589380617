//@ts-nocheck
import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import {withRouter} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import {Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {isMobile} from '../../../common/utils/browser'
import {CustomDialog} from '../../Common/Dialog'
import {config} from '../../../config'
import AppContext from '../../Common/contexts/AppContext'

const styles = theme => ({
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400
  },
})

class TradesmarterDialog extends Component {
  static contextType = AppContext

  state = {
    open: true,
  }

  transferFunds() {
    const {history, match} = this.props
    this.setState({open: false})
    history.push(`/transactions/${match.params.accountId}/transfer`)
  }

  render() {
    const {classes} = this.props
    const {supportEmail} = config
    const {companyObject} = this.context

    return (
      <div>
        <CustomDialog
          open={this.state.open}
          onClose={this.handleClose}
          maxWidth='lg'
          disableEscapeKeyDown
          customClasses={classes}
        >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Typography variant='body1'>
                <Trans {...messages.dearClient} />
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography variant='body1'>
                <Trans {...messages.tradesmarterMessage} values={{product:'Binary'}}/>
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography variant='body1'>
                <Trans {...messages.tradesmarterSubMessage} values={{company:companyObject['brandLabel']}}/>
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography variant='body1'>
                <Trans {...messages.tradesmarterNote} />
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography variant='body1'>
                <Trans {...messages.tradesmarterContactUs} values={{supportEmail:supportEmail,company:companyObject['brandLabel']}}
                  components={[
                    <a href={`mailto:${supportEmail}`} className={classes.link}>supportEmail</a>
                  ]}/>
              </Typography>
            </Grid>
            <Grid item sm={12} align={isMobile() ? 'center' : 'right'}>
              <Button
                onClick={() =>this.transferFunds()}
                color="primary"
                variant="contained"
                size="large"
              >
                <Trans {...messages.transferFunds} />
              </Button>
            </Grid>
          </Grid>
        </CustomDialog>
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withRouter,
)(TradesmarterDialog)
