import React from 'react'
import {Link} from 'react-router-dom'
import {get, flowRight as compose, includes, find} from 'lodash'
import classnames from 'classnames'
import {graphql} from 'react-apollo'
import {Trans, withNamespaces} from 'react-i18next'
import Badge from '@material-ui/core/Badge'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import {withStyles} from '@material-ui/core/styles'
import {kycStatuses, referralStatuses} from '@bdswiss/common-enums'
import Typography from '@material-ui/core/Typography'
import ListItemLink from './ListItemLink'
import Header from '../Header'
import {config} from '../../../config'
import messages from '../../../assets/messages'
import {isMobile} from '../../../common/utils/browser'
import {CLIENT_DATA_QUERY, PROFILE_SETTINGS_QUERY, REFERRAL_DETAILS_QUERY} from '../../../graphql/queries'
import AppContext from '../../Common/contexts/AppContext'
import PageBody from '../../Common/PageBody'
import {getPendingVerificationsCount} from '../../../common/utils/general'
import VerifiedIcon from '@material-ui/icons/CheckCircleOutlined'
import Missing from '@material-ui/icons/ReportProblemOutlined'
// import ThemeSelection from '../../ThemeSelection'
import {hasWalletProductAccount, hasOnlyWalletProductAccount,
  hasIntroducingBrokerAccount} from '../../../common/utils/accounts'
import {getItem} from '../../../common/utils'
import {InnerAppContext} from '../../../common/types'
import {ReactComponent as AccountsIcon} from '../../../assets/images/icon_accounts_dashboard.svg'
import {ReactComponent as TransactionsIcon} from '../../../assets/images/icon_transaction_dashboard.svg'
import {ReactComponent as NotificationsIcon} from '../../../assets/images/icon_notification_dashboard.svg'
import {ReactComponent as SupportIcon} from '../../../assets/images/icon_support_dashboard.svg'
import {ReactComponent as LogoutIcon} from '../../../assets/images/icon_logout_dashboard.svg'
import {ReactComponent as ProfileIcon} from '../../../assets/images/icon-profile-big.svg'

const styles = (theme) => ({
  invisible: {
    display: 'none'
  },
  badge: {
    top: theme.direction === 'rtl' ? 0 : 15,
    right: theme.direction === 'rtl' ? 0 : -30,
    backgroundColor: theme.palette.red.color,
    color: theme.palette.red.textColor,
    position: theme.direction === 'rtl' ? 'relative' as const : 'absolute' as const
  },
  nameGrid: {
    padding: '10px 24px 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 16px 30px',
    },
  },
  nameText:{
    lineHeight: '1'
  },
  statusIcon: {
    'vertical-align':'text-top',
    'font-size':'15px',
    'margin-right':'4px',
  },
  pendingIcon: {
    'color': theme.palette.yellow.color
  },
  doneIcon: {
    'color': theme.palette.green.color
  },
  verificationLink:{
    'color': theme.palette.yellow.color
  },
  buttonClass: {
    marginTop: 20,
    padding: '12px 24px',
    boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: theme.palette.extralightgrey.color
    }
  },
  darkButtonClass: {
    backgroundColor: theme.palette.extralightgrey.color,
    '&:hover': {
      backgroundColor: theme.palette.extralightgreyBackground.color
    }
  },
  listItem: {
    display: 'flex',
    [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: 24
  },
  listItemIcon: {
    width: 20,
    height: 20,
    marginRight: 15,
  },
})
const accountVerification = (kycStatus, classes) => {
  let verificationIcon
  if (kycStatus === kycStatuses.approved.value) {
    verificationIcon = <React.Fragment>
      <Typography variant="caption" className={classes.doneIcon}>
        <VerifiedIcon className={classnames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
        <Trans {...messages.verified} />
      </Typography>
    </React.Fragment>
  } else {
    verificationIcon = <React.Fragment>
      <Typography variant="caption" className={classes.pendingIcon}>
        <Missing className={classnames({[classes.statusIcon]: true, [classes.pendingIcon]: true})}/>
        <Link to={'/accounts/verification'} className={classes.verificationLink}><Trans {...messages.unVerified} /> </Link>
      </Typography>
    </React.Fragment>
  }
  return verificationIcon
}
const pendingStatuses = [referralStatuses.activated.value, referralStatuses.deposited.value, referralStatuses.verified.value, referralStatuses.pendingActivation.value]
class Sidebar extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  render() {
    const {classes, className, loading, viewer: {firstName, lastName, id, pendingNoticesCount = 0},
      viewer, appropTests, globalQuestionnaire, documents, referrals, linkedPartnerClientId, referAndEarn} = this.props
    const {kycStatus} = viewer
    const {clientType, blockedDeposit, accounts} = this.context
    const {featuresConfig:{vps}} = config
    const locale = (this.context.clientType) ? this.context.locale:getItem('locale','en')
    const showSolicitationAcceptance = viewer.company === 'equityflow' ? true : false

    if (loading) {
      return null
    }
    const pendingReferral = find(referrals, (r) => includes(pendingStatuses, r.status))
    const isIbOrAffiliate = hasWalletProductAccount(accounts) || linkedPartnerClientId
    const showReferral = referAndEarn && (!isIbOrAffiliate || (isIbOrAffiliate && pendingReferral))

    const pendingVerificationsCount = getPendingVerificationsCount(viewer, appropTests, globalQuestionnaire,
      documents, clientType, blockedDeposit)

    const referralAwardNotification = this.props.activatedReferralAwards && !get(this.props.activatedReferralAwards,'claimedAt')

    const badgeClasses = [classes.badge]
    const settingsBadgeClasses = [classes.badge]
    const totalNotificationsCount = referralAwardNotification ? pendingNoticesCount+1 : pendingNoticesCount
    totalNotificationsCount < 1 && badgeClasses.push(classes.invisible)
    pendingVerificationsCount < 1 && settingsBadgeClasses.push(classes.invisible)
    const onlyWalletAccount = hasOnlyWalletProductAccount(accounts)

    return (
      <div className={className}>
        <Grid container className={classes.nameGrid} direction="column">
          <Typography variant="body2" className={classes.nameText}>{[firstName, lastName].join(' ')}</Typography>
          <Typography variant="caption">{`${config.name} ID: ${id || ''}`}</Typography>
          {accountVerification(kycStatus, classes)}
        </Grid>
        <List component="nav">
          <ListItemLink to={'/accounts'} isIndex classesItem={classes.listItem}>
            {!!config.visual?.sideBar?.showIcons && <AccountsIcon className={classes.listItemIcon}/>}
            <Trans {...messages.accounts} />
          </ListItemLink>
          <ListItemLink to={'/transactions'} classesItem={classes.listItem}>
            {!!config.visual?.sideBar?.showIcons && <TransactionsIcon className={classes.listItemIcon}/>}
            <Trans {...messages.payments} />
          </ListItemLink>
          {/* {(!onlyWalletAccount || (tradingCentralEnabled && hasIbAccount)) && <ListItemLink to={'/tools'} classesItem={classes.listItem}>
            <Trans {...messages.tools} />
          </ListItemLink>} */}
          <ListItemLink to={'/notifications'} classesItem={classes.listItem}>
            {!!config.visual?.sideBar?.showIcons && <NotificationsIcon className={classes.listItemIcon}/>}
            <Badge badgeContent={totalNotificationsCount} classes={{badge: classnames(badgeClasses)}} >
              <div>
                <Trans {...messages.notifications} />
              </div>
            </Badge>
          </ListItemLink>
          <ListItemLink to={'/settings'} classesItem={classes.listItem}>
            {!!config.visual?.sideBar?.showIcons && <ProfileIcon className={classes.listItemIcon}/>}
            <Badge badgeContent={pendingVerificationsCount} classes={{badge: classnames(settingsBadgeClasses)}}>
              <div>
                <Trans {...messages.profileAndSettings} />
              </div>
            </Badge>
          </ListItemLink>
          {vps && vps.enabled && !onlyWalletAccount && <ListItemLink to={'/vps'} classesItem={classes.listItem}>
            <Trans {...messages.vps} />
          </ListItemLink>}
          {showReferral && <ListItemLink to={'/referAndEarn'} classesItem={classes.listItem}>
            <Trans {...messages.referAndEarn} />
          </ListItemLink>}
          <ListItemLink to={'/support'} classesItem={classes.listItem}>
            {!!config.visual?.sideBar?.showIcons && <SupportIcon className={classes.listItemIcon}/>}
            <Trans {...messages.support} />
          </ListItemLink>
          {showSolicitationAcceptance && <ListItemLink to={`/solicitationAcceptance/${locale}`} classesItem={classes.listItem}>
            <Trans {...messages.solicitationAcceptanceTitle} />
          </ListItemLink>}
          <ListItemLink to={'/logout'} onClick={() => this.context.logout()} classesItem={classes.listItem}>
            {!!config.visual?.sideBar?.showIcons && <LogoutIcon className={classes.listItemIcon} />}
            <Trans {...messages.logout} />
          </ListItemLink>
        </List>
      </div>
    )
  }
}

class MobileSidebar extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  render() {
    const {classes, className, loading, viewer: {firstName, lastName, id, pendingNoticesCount = 0},
      viewer, appropTests, globalQuestionnaire,
      documents, referrals, linkedPartnerClientId, referAndEarn} = this.props
    const {kycStatus, tradingCentralEnabled} = viewer
    const {clientType, accounts} = this.context
    const {featuresConfig:{vps}} = config
    if (loading) {
      return null
    }

    const pendingVerificationsCount = getPendingVerificationsCount(viewer, appropTests, globalQuestionnaire, documents, clientType)

    const settingsBadgeClasses = [classes.badge]
    pendingVerificationsCount < 1 && settingsBadgeClasses.push(classes.invisible)
    const pendingReferral = find(referrals, (r) => includes(pendingStatuses, r.status))
    const isIbOrAffiliate = hasWalletProductAccount(accounts) || linkedPartnerClientId
    const showReferral = referAndEarn && (!isIbOrAffiliate || (isIbOrAffiliate && pendingReferral))
    const onlyWalletAccount = hasOnlyWalletProductAccount(accounts)
    const hasIbAccount = hasIntroducingBrokerAccount(accounts)

    const badgeClasses = [classes.badge]
    const referralAwardNotification = this.props.activatedReferralAwards && !get(this.props.activatedReferralAwards,'claimedAt')
    const totalNotificationsCount = referralAwardNotification ? pendingNoticesCount+1 : pendingNoticesCount
    totalNotificationsCount < 1 && badgeClasses.push(classes.invisible)

    return (
      <PageBody className={className}>
        <Header />
        <Grid container className={classes.nameGrid} direction="column">
          <Typography variant="body2" className={classes.nameText}>{[firstName, lastName].join(' ')}</Typography>
          <Typography variant="caption">{`${config.name} ID: ${id || ''}`}</Typography>
          {accountVerification(kycStatus, classes)}
        </Grid>
        <List component="nav">
          <ListItemLink to={'/notifications'}>
            <Badge badgeContent={totalNotificationsCount} classes={{badge: classnames(badgeClasses)}}>
              <div>
                <Trans {...messages.notifications} />
              </div>
            </Badge>
          </ListItemLink>
          {(!onlyWalletAccount || (tradingCentralEnabled && hasIbAccount)) && <ListItemLink to={'/tools'}>
            <Trans {...messages.tools} />
          </ListItemLink>}
          <ListItemLink to={'/settings/profile'}>
            <Badge badgeContent={pendingVerificationsCount} classes={{badge: classnames(settingsBadgeClasses)}}>
              <div>
                <Trans {...messages.profileAndSettings} />
              </div>
            </Badge>
          </ListItemLink>
          <ListItemLink to={'/settings/privacy'}>
            <Trans {...messages.privacy} />
          </ListItemLink>
          <ListItemLink to={'/settings/change-password'}>
            <Trans {...messages.changePassword} />
          </ListItemLink>
          {vps && vps.enabled && !onlyWalletAccount && <ListItemLink to={'/vps'}>
            <Trans {...messages.vps} />
          </ListItemLink>}
          {showReferral && <ListItemLink to={'/referAndEarn'}>
            <Trans {...messages.referAndEarn} />
          </ListItemLink>}
          <ListItemLink to={'/logout'} onClick={() =>  this.context.logout()}>
            <Trans {...messages.logout} />
          </ListItemLink>
        </List>
        {/* TODO: temp removed SKYG-755 */}
        {/*<div>*/}
        {/*  <ThemeSelection />*/}
        {/*</div>*/}
      </PageBody>
    )
  }
}


export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer}}:any) => ({error, loading, viewer})
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error, loading, viewer}}:any) => {
      const globalQuestionnaire = get(viewer, 'globalQuestionnaire')
      const appropTests = get(viewer, 'appropTests')
      const documents = get(viewer, 'documents')

      return {
        error,
        loading,
        globalQuestionnaire,
        appropTests,
        documents,
      }
    }}),
  graphql(REFERRAL_DETAILS_QUERY, {
    props: ({data: {error, loading}, data}:any) => ({
      referralError: error,
      referralLoading: loading,
      referrals: get(data, 'viewer.referralDetails.referrals'),
      activatedReferralAwards: get(data, 'viewer.referralDetails.activatedReferralAwards'),
      linkedPartnerClientId:  get(data, 'viewer.linkedPartnerClientId'),
      referAndEarn:  get(data, 'viewer.referAndEarn'),
    })
  }),
)(isMobile() ? MobileSidebar : Sidebar)
