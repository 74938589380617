import React from 'react'
import {flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'
import {AlertDialog} from '../../Common/Dialog'
import messages from '../../../assets/messages'

const styles = {
  subtitleGrid: {
    padding: 8,
  },
  styledText: {
    whiteSpace: 'pre-line' as const,
    fontSize: 16,
    lineHeight: 1.5,
  }
}

const NonDepositorModal = (props) => {
  const {open, classes, t, onClose} = props
  return (
    <AlertDialog
      open={open}
      agreeText={t(messages.close.i18nKey, messages.close.defaults)}
      onAgree={() => onClose()}
    >
      <Grid container direction="row" alignContent="center" justifyContent="center">
        <Grid item xs={12} className={classes.subtitleGrid}>
          <p className={classes.styledText}>
            <Trans {...messages.depositToEnableFeature} />
          </p>
        </Grid>
      </Grid>
    </AlertDialog>
  )
}

NonDepositorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth(),
  withNamespaces(),
)(NonDepositorModal)
