import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {get, map, some, orderBy, lowerCase, filter, assign, flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'
import {graphql} from 'react-apollo'
import {Loading} from '../Common/Loading'
import {Trans, withNamespaces} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import messages from '../../assets/messages'
import {PENDING_NOTICES_QUERY, CLIENT_DATA_QUERY, ACKNOWLEDGED_NOTICES_QUERY, REFERRAL_DETAILS_QUERY} from '../../graphql/queries'
import {NOTICE_ACKNOWLEDGEMENT_MUTATION} from '../../graphql/mutations'
import AppContext from '../Common/contexts/AppContext'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import {clientNoticeTypes, clientNoticeAcknowledgementTypes, companies, regulators, partnersAppendices} from '@bdswiss/common-enums'
import {config} from '../../config'
import Images from '../Common/Images'
import NotificationBar from '../Common/NotificationBar'
import {Table, TableHead, TableCell, TableRow, TableBody} from '@material-ui/core'
import Info from '@material-ui/icons/InfoOutlined'
import {CustomDialog} from '../Common/Dialog'
import {addClassToElement, isMobile, removeClassToElement} from '../../common/utils'
import PageSubTitle from '../Common/PageSubTitle'
import {referAndEarnDetails} from '../../common/utils/uioptions'
import ClaimRewardPopupMessage from '../ReferAndEarn/claimRewards'
import {isDemoAccount, isForexAccount} from '../../common/utils/accounts'
import classNames from 'classnames'
import {InnerAppContext} from '../../common/types'

const style = (theme) => ({
  hightlight: {
    color: theme.palette.lightblue.textColor,
  },
  card: {
    width: '100%',
    padding: 0
  },
  chip: {
    fontSize: 10,
    height: 20,
    color: '#fff',
    backgroundColor: theme.palette.green.color
  },
  chipNew: {
    backgroundColor: theme.palette.red.color
  },
  noTradesIcon: {
    maxWidth: 280,
    minWidth:220,
    width:'75%',
  },
  noTradesIconWidth:{
    textAlign: 'center' as const
  },
  notificationContent:{
    '& h3':{
      fontWeight:'400'
    },
    '& a':{
      color: theme.palette.secondary.dark,
      textDecoration: 'underline'
    },
    '& th':{
      color: theme.palette.secondary.dark,
      fontWeight:'500'
    },
    '& table':{
      textAlign:'center' as const
    },
    '& h2 strong':{
      fontWeight:'400'
    },
  },
  table: {
    minWidth: '100%',
    border: 'none',
  },
  iconSmall: {
    fontSize: 20,
  },
  cellRoot: {
    padding: '4px 0px 4px 10px'
  },
  statusIcon: {
    'vertical-align':'middle',
    'font-size':'20px',
  },
  cancelButton:{
    margin: '0 15px',
  },
  notificationPopupContent:{
    [theme.breakpoints.down('sm')]: {
      '& h1':{
        fontSize:'1rem'
      },
      '& p':{
        fontSize: 12,
        lineHeight: 2
      }
    }
  },
  notificationPopupBtns:{
    [theme.breakpoints.down('sm')]: {
      margin:'auto'
    }
  },
  subSection:{
    marginTop: '4rem'
  },
  actionCell:{
    paddingLeft:0
  }
})

class Notifications extends Component<any,any> {
  static propTypes = {
    pendingNotices: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object
  }

  state = {
    showClaim: false
  } as any

  static contextType = AppContext
  context!: InnerAppContext

  getContent(notification) {

    const {locale, companyObject} = this.context
    const {weblinks: {termsAndConditions}} = config
    const {emailDomain, label: companyLabel, trademark} = companyObject
    const termsAndConditionsLink = termsAndConditions.replace('{lang}', locale)

    const lineBreak = notification.isContentHtml ? <br /> : '\n'

    let content

    if (!messages[notification.localizationKey]) {
      content = notification.content
    } else {
      content = (
        <Trans
          key='nofication-content-p1'
          {...messages[notification.localizationKey]}
          values={{lineBreak, company: companyLabel, emailDomain, trademark, termsAndConditionsLink}}
        />
      )
    }
    return content
  }

  sendAcknowledgement(notification, type) {

    const alreadySeen = some(notification.acknowledgements,
      ({acknowledgementType}) => acknowledgementType === clientNoticeAcknowledgementTypes.seen.key)

    if (type===clientNoticeAcknowledgementTypes.seen.key && (alreadySeen || (this.state && this.state.noticeSeen))) {
      this.closeNotification()
      return
    }

    const variables = {
      type:  clientNoticeAcknowledgementTypes[type].key,
      noticeId: notification.id
    }
    this.props.sendNoticeAcknowledgement({variables}).catch(console.error)  // eslint-disable-line
    this.closeNotification()
  }

  sendSeenAcknowledgement(notification) {

    const alreadySeen = some(notification.acknowledgements,
      ({acknowledgementType}) => acknowledgementType === clientNoticeAcknowledgementTypes.seen.key)

    if (alreadySeen) return

    const variables = {
      type:  clientNoticeAcknowledgementTypes.seen.key,
      noticeId: notification.id
    }

    this.props.sendNoticeAcknowledgement({variables}).then(this.setState({noticeSeen:true})).catch(console.error)  // eslint-disable-line

  }

  closeNotification () {
    this.setState({activeNotificationDialog: null})
    removeClassToElement('chat-widget-container', 'displayNone')
  }

  showNotification(notification) {
    const {classes} = this.props

    const localizedContent = this.getContent(notification)
    const dialogContent = notification.isContentHtml ?
      <span dangerouslySetInnerHTML={{__html: localizedContent}} /> : <span>{localizedContent}</span>
    const dismissDialog = notification.type === clientNoticeTypes.termsChange.key
    const {companyObject} = this.context
    const regulator = regulators[companies[companyObject.key].regulator!].label
    const acceptedNotice = notification.status === clientNoticeAcknowledgementTypes.accepted.value
    const notificationDialog = (
      <CustomDialog
        open
        onClose={() => this.closeNotification()}
        maxWidth='lg'
        disableEscapeKeyDown={dismissDialog}
      >
        <Grid container spacing={isMobile() ? 1 : 3} className={classes.notificationPopupContent}>
          <Grid item sm={12} /*align='center'*/>
            <PageTitle modal>
              {notification.title}
            </PageTitle>
          </Grid>
          <Grid item sm={12}>
            <Typography variant='body1' className={classes.notificationContent}>
              {dialogContent}
            </Typography>
            {notification.type === clientNoticeTypes.partners.key && <Typography variant='caption'><Trans {...messages[`${lowerCase(regulator)}Footer`]} /></Typography>}
          </Grid>
          {!acceptedNotice && <Grid item sm={12} className={classes.notificationPopupBtns} /*align={isMobile() ? 'center' : 'right'}*/>
            <Button
              onClick={() => this.sendAcknowledgement(notification, 'seen')}
              color="secondary"
              variant="contained"
              size={isMobile() ? 'medium' : 'large'}
              className={classes.cancelButton}
            >
              <Trans {...messages.actionRemindLater} />
            </Button>
            <Button
              onClick={() => this.sendAcknowledgement(notification, 'accepted')}
              color="primary"
              variant="contained"
              size={isMobile() ? 'medium' : 'large'}
            >
              <Trans {...messages.accept} />
            </Button>
          </Grid>}
          {acceptedNotice && <Grid item sm={12} className={classes.notificationPopupBtns} /*align={isMobile() ? 'center' : 'right'}*/>
            <Button
              onClick={() => this.closeNotification()}
              color="primary"
              variant="contained"
              size={isMobile() ? 'medium' : 'large'}
            >
              <Trans {...messages.close} />
            </Button>
          </Grid>}
        </Grid>
      </CustomDialog>
    )

    this.setState({activeNotificationDialog: notificationDialog},() => !acceptedNotice && this.sendSeenAcknowledgement(notification))
    addClassToElement('chat-widget-container', 'displayNone')
  }

  getContentText(text) {
    const div = document.createElement('div')
    div.innerHTML = text
    //@ts-ignore
    return div.textContent.replace(/ +(?= )/g,'')
  }

  renderPartnerAppendixes() {
    const {classes, partnerAppendixes} = this.props
    const {locale} = this.context
    return (
      <React.Fragment>
        <Grid container className={classes.subSection}>
          <Grid item xs={12}>
            <PageSubTitle>
              <Trans {...messages.acceptedAppendixesTitle} />
            </PageSubTitle>
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellRoot}><Trans {...messages.type} /></TableCell>
                  <TableCell className={classes.cellRoot}><Trans {...messages.date} /></TableCell>
                  <TableCell className={classes.cellRoot}><Trans {...messages.description} /></TableCell>
                  {!isMobile() && <TableCell className={classes.cellRoot}><Trans {...messages.action} /></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  map(orderBy(partnerAppendixes, (n) => n.id, ['desc']), (notification, i) => {
                    const publicationDate = moment(notification.createdAt).locale(this.context.locale)
                    return (
                      <TableRow key={notification.id} onClick={() => isMobile() && this.showNotification(notification)}>
                        <TableCell className={classes.cellRoot}>{partnersAppendices[notification.template].localization.t(locale)}</TableCell>
                        <TableCell className={classes.cellRoot}>{publicationDate.format('DD/MM/YYYY')}</TableCell>
                        <TableCell className={classes.cellRoot}>{notification.title}</TableCell>
                        {!isMobile() && <TableCell className={classNames(classes.cellRoot, classes.actionCell)} >
                          <Button
                            onClick={() => this.showNotification(notification)}
                            size="small"
                            color="primary"
                          >
                            <Trans {...messages.readMore} />
                          </Button>
                        </TableCell>}
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  renderReferralNotification() {
    const {classes, referralDetails:{activatedReferralAwards}} = this.props
    const activationDate = moment(activatedReferralAwards.activatedAt).locale(this.context.locale)
    return (
      <TableRow onClick={() => isMobile() && this.setState({showClaim: true})}>
        <TableCell className={classes.cellRoot}><Info color='primary' className={classes.statusIcon}/></TableCell>
        <TableCell className={classes.cellRoot}>{activationDate.format('DD/MM/YYYY')}</TableCell>
        <TableCell className={classes.cellRoot}>{<Trans {...messages.referralActivatedNotification}
          values={{amount: `${referAndEarnDetails.currency}${activatedReferralAwards.amount}`}} />}</TableCell>
        {!isMobile() && <TableCell className={classNames(classes.cellRoot, classes.actionCell)} >
          <Button
            onClick={() => this.setState({showClaim: true})}
            size="small"
            color="primary"
          >
            <Trans {...messages.claimReward} />
          </Button>
        </TableCell>}
      </TableRow>
    )
  }

  render() {
    const {loading, error, pendingNotices, classes, partnerAppendixes, loadingPartners, referralLoading, referralDetails} = this.props
    const {activeNotificationDialog, showClaim} = this.state

    if (loading || !pendingNotices || loadingPartners || referralLoading) return <Loading />
    if (error) return <Redirect to='/' />
    const {themePreference} =this.context

    const referralAwardNotification =  get(referralDetails,'activatedReferralAwards')
      && !get(referralDetails,'activatedReferralAwards.claimedAt')
    const pendingRewards = referralDetails &&  get(referralDetails,'activatedReferralAwards.amount')
    const validRewardsAccounts = filter(this.context.accounts, (account) => !isDemoAccount(account) && isForexAccount(account))

    return (
      <React.Fragment>
        <PageTitle
          onBack={()=> this.props.history.push('/accounts')}>
          <Trans {...messages.notifications} />
        </PageTitle>
        <PageBody>
          <Grid container>
            <Grid item xs={12}>
              {pendingNotices.length < 1 && !referralAwardNotification &&<React.Fragment>
                <NotificationBar status='info'>
                  <Trans {...messages.noNotification} />
                </NotificationBar>
                <Grid container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center">
                  <Grid item className={classes.noTradesIconWidth}>
                    <img className={classes.noTradesIcon} src={Images[`notifications-empty-${themePreference}.png`]} alt='noTrades' />
                  </Grid>
                </Grid>
              </React.Fragment>
              }
              {
                (pendingNotices.length > 0 || referralAwardNotification) &&
                <Grid item xs={12}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cellRoot}><Trans {...messages.type} /></TableCell>
                        <TableCell className={classes.cellRoot}><Trans {...messages.date} /></TableCell>
                        <TableCell className={classes.cellRoot}><Trans {...messages.description} /></TableCell>
                        {!isMobile() && <TableCell className={classes.cellRoot}><Trans {...messages.action} /></TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {referralAwardNotification && this.renderReferralNotification()}
                      {
                        map(orderBy(pendingNotices, (n) => n.id, ['desc']), (notification, i) => {
                          const publicationDate = moment(notification.createdAt).locale(this.context.locale)
                          return (
                            <TableRow key={notification.id} onClick={() => isMobile() && this.showNotification(notification)}>
                              <TableCell className={classes.cellRoot}><Info color='primary' className={classes.statusIcon}/></TableCell>
                              <TableCell className={classes.cellRoot}>{publicationDate.format('DD/MM/YYYY')}</TableCell>
                              <TableCell className={classes.cellRoot}>{notification.title}</TableCell>
                              {!isMobile() && <TableCell className={classNames(classes.cellRoot, classes.actionCell)} >
                                <Button
                                  onClick={() => this.showNotification(notification)}
                                  size="small"
                                  color="primary"
                                >
                                  <Trans {...messages.readMore} />
                                </Button>
                              </TableCell>}
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </Grid>
              }
              {partnerAppendixes && partnerAppendixes.length >0 && this.renderPartnerAppendixes()}
            </Grid>
          </Grid>
        </PageBody>
        {activeNotificationDialog}
        <ClaimRewardPopupMessage
          open={showClaim}
          rewardsObj={{pendingRewards}}
          accounts={validRewardsAccounts}
          rewardType='referree'
          onClose={() => {
            this.setState({showClaim: false})
            this.props.referralDetailsRefetch()
          }}
        />
      </React.Fragment>
    )
  }
}


export default compose(
  withNamespaces(),
  withStyles(style, {withTheme: true}),
  graphql(PENDING_NOTICES_QUERY, {
    props: ({data: {loading, error}, data}:any) => ({
      loading,
      error,
      pendingNotices: get(data, 'viewer.pendingNotices')
    })
  }),
  graphql(NOTICE_ACKNOWLEDGEMENT_MUTATION, {
    name: 'sendNoticeAcknowledgement',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}, {query: PENDING_NOTICES_QUERY}, {query: ACKNOWLEDGED_NOTICES_QUERY}],
    },
  }),
  graphql(ACKNOWLEDGED_NOTICES_QUERY, {
    props: ({data: {loading: loadingPartners, error:errorPartners}, data}:any) => {
      const partnerAppendixes = filter(get(data, 'viewer.acknowledgedNotices'),(acknowledgement) =>
        acknowledgement.notice.type === clientNoticeTypes.partners.value).map(({notice, createdAt, acknowledgementType})=>
        assign({}, notice, {status: acknowledgementType, createdAt}))
      return {
        loadingPartners,
        errorPartners,
        partnerAppendixes
      }
    }
  }),
  graphql(REFERRAL_DETAILS_QUERY, {
    props: ({data: {error, loading}, data}:any) => ({
      referralError: error,
      referralLoading: loading,
      referralDetails: get(data, 'viewer.referralDetails'),
      referralDetailsRefetch: data.refetch
    })}),
)(Notifications)
