import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import classNames from 'classnames'
import {withNamespaces, Trans} from 'react-i18next'
import {get, countBy, filter, map, isEmpty, includes, last, orderBy, flowRight as compose} from 'lodash'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'
import {graphql} from 'react-apollo'
import Grid from '@material-ui/core/Grid'
import Done from '@material-ui/icons/Done'
import Missing from '@material-ui/icons/Clear'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import {Loading} from '../../Common/Loading'
import messages from '../../../assets/messages'
import {config} from '../../../config'
import {DOCUMENT_CATEGORY_TYPES, getMissingVerifications, getMissingDocs} from '../../../common/utils/general'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY, SPOA_AUTHORIZATION_QUERY, ACCOUNTS_QUERY} from '../../../graphql/queries'
import Images from '../../Common/Images'
import {isMobile} from '../../../common/utils/browser'
import AppContext from '../../Common/contexts/AppContext'
import {kycStatuses, companies, dueDiligenceStatuses, suitabilityTestScoreResult} from '@bdswiss/common-enums'
import NotificationBar from '../../Common/NotificationBar'
import {hasWalletProductAccount, isAffiliateClient}  from '../../../common/utils/accounts'
import SwapFreeDialog from './SwapFreeDialog'
import CustomTooltip from '../../Common/CustomTooltip'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  blueAvatar: {
    margin: 10,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.lightblue.color,
    [theme.breakpoints.down('xs')]: {
      margin: '0 10px 0 0',
      height: 35,
      width: 35,
      lineHeight: 37,
    }
  },
  notValid: {
    color: theme.palette.error.main,
    fontSize: 12,
    fontWeight:400
  },
  valid: {
    color: theme.palette.green.color,
    fontSize: 14,
    fontWeight:400
  },
  underReview: {
    color: theme.palette.yellow.color,
    fontSize: 14,
    fontWeight:400
  },
  pending: {
    color: theme.palette.yellow.color,
    fontSize: 14,
    fontWeight:400
  },
  approved: {
    color: theme.palette.green.color,
    fontSize: 14,
    fontWeight:400
  },
  rejected: {
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight:400
  },
  statusIcon: {
    verticalAlign: 'middle',
    fontSize: 14,
    marginRight:8
  },
  doneIcon: {
    color: theme.palette.green.color
  },
  removeIcon: {
    color:theme.palette.error.main
  },
  subtitle: {
    color: theme.palette.grey.color,
    fontWeight:300
  },
  toolTip:{
    color: theme.palette.secondary.light,
    fontSize:14,
    fontWeight:300,
    padding:3
  },
  tool: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: 0,
      top: -15
    }
  },
  toolOuter: {
    [theme.breakpoints.down('xs')]: {
      position: 'relative'
    }
  },
  disabledCard:{
    '&:hover':{
      opacity:0.5,
      cursor: 'not-allowed'
    },
  },
})

class Profile extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)
    const {viewer} = props
    this.state = {
      visibleCategoryTypes: filter(DOCUMENT_CATEGORY_TYPES, (t) => t.show(viewer) && t.mandatory),
      arrowRef: null,
      showFillPersonalDetailsMsg: false,
    }
  }


  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    })
  }

  getOkDocs() {
    const {visibleCategoryTypes} = this.state
    const missingDocs = getMissingDocs(this.props.viewer)
    return visibleCategoryTypes.length - (get(countBy(missingDocs), 'true') || 0)
  }

  getKYCTooltip(kycStatusApproved) {
    const {classes} = this.props
    const missingDocs = getMissingDocs(this.props.viewer)

    return (
      <div>
        {!kycStatusApproved && map(missingDocs, (missing, key) => !missing
          ? (<div key={key} className={classes.toolTip}>
            <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
            <Trans {...messages[`${key}Title`]} />
          </div>)
          : (<div key={key} className={classes.toolTip}>
            <Missing className={classNames({[classes.statusIcon]: true, [classes.removeIcon]: true})}/>
            <Trans {...messages[`${key}Title`]} />
          </div>)
        )}
        {kycStatusApproved &&  (<div className={classes.toolTip}>
          <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
          <Trans {...messages.completed} />
        </div>)}
      </div>
    )
  }

  getApprTestStatus(appropTest) {
    const {classes} = this.props
    return (
      <div>
        {!isEmpty(appropTest) ?
          <Typography variant='body1' className={classes.toolTip}>
            <Trans {...messages.appropriatenessTestSubmitted} /></Typography>
          :
          <Typography variant='body1' className={classes.toolTip}>
            <Trans {...messages.appropriatenessTestMissing} /></Typography>
        }
      </div>
    )
  }

  checkDetails(client) {
    return Boolean(client)
  }

  checkEconomicDetails(client) {
    return Boolean(get(client, 'globalQuestionnaire'))
  }

  checkPersonalProfile(classes, t, missingVerification,verificationFields) {

    let check =  0, totalFields=0

    if (includes(verificationFields, 'profile')) {
      totalFields++
      if (!includes(missingVerification, 'profile'))
        check++
    }
    if (includes(verificationFields, 'economicProfile')) {
      totalFields++
      if (!includes(missingVerification, 'economicProfile'))
        check++
    }

    const checkStatus =  <Typography variant="subtitle1" className={check === totalFields ? classes.valid : classes.notValid}>
      {check}/{totalFields} {t(messages.profileSettingsStatus.i18nKey,
        messages.profileSettingsStatus.defaults)}</Typography>
    return checkStatus
  }

  fieldStatus = (classes, t, missingVerification, verificationFields) => {
    let firstCheck

    if (includes(verificationFields, 'profile')) {
      if (!includes(missingVerification, 'profile')) {
        firstCheck = <div  className={classes.toolTip}>
          <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
          {t(messages.profileSettingsTooltip1.i18nKey, messages.profileSettingsTooltip1.defaults)}
        </div>
      } else {
        firstCheck = <div  className={classes.toolTip}>
          <Missing className={classNames({[classes.statusIcon]: true, [classes.removeIcon]: true})}/>
          {t(messages.profileSettingsTooltip1Pending.i18nKey, messages.profileSettingsTooltip1Pending.defaults)}
        </div>
      }
    }

    let secondCheck
    if (includes(verificationFields, 'economicProfile')) {
      if (!includes(missingVerification, 'economicProfile')) {
        secondCheck = <div  className={classes.toolTip}>
          <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
          {t(messages.profileSettingsTooltip2.i18nKey, messages.profileSettingsTooltip2.defaults)}
        </div>
      } else {
        secondCheck = <div  className={classes.toolTip}>
          <Missing className={classNames({[classes.statusIcon]: true, [classes.removeIcon]: true})}/>
          {t(messages.profileSettingsTooltip2Pending.i18nKey, messages.profileSettingsTooltip2Pending.defaults)}
        </div>
      }
    }

    return (<div>{firstCheck}{secondCheck}</div>)
  }

  appropTestPath() {
    const {history,  match: {path}, viewer:{canProcessAppropTest}} = this.props
    if (canProcessAppropTest) {
      history.push(`${path}/appropriateness-test`)
    }
  }

  dueDiligencePath() {
    const {history,  match: {path}} = this.props
    if (!this.restrictDueDiligence()) {
      history.push(`${path}/due-diligence`)
    }
  }

  portfolioManagementPath() {
    const {history,  match: {path},} = this.props
    history.push(`${path}/portfolio-management`)
  }

  getDueDiligenceStatus(dueDiligence) {
    const {classes} = this.props
    let status
    if (get(dueDiligence,'status') === dueDiligenceStatuses.approved.key) {
      status = messages.dueDiligenceSubmitted
    } else if (get(dueDiligence,'status') === dueDiligenceStatuses.rejected.key) {
      status = messages.dueDiligenceRejected
    } else if (get(dueDiligence,'status') === dueDiligenceStatuses.pending.key) {
      status = messages.dueDiligenceSubmitted
    } else if (get(dueDiligence,'status') === dueDiligenceStatuses.underReview.key) {
      status = messages.dueDiligenceUnderReview
    } else {
      status = messages.missing
    }
    return (
      <div>
        {<Typography variant='body1' className={classes.toolTip}>
          <Trans {...status} />
        </Typography>}
      </div>
    )
  }

  getSuitabilityTestStatus(suitabilityTest) {
    const {classes} = this.props
    let status
    if (get(suitabilityTest,'scoreResult') === suitabilityTestScoreResult.high.key) {
      status = suitabilityTestScoreResult.high.label
    } else if (get(suitabilityTest,'scoreResult') === suitabilityTestScoreResult.medium.key) {
      status = suitabilityTestScoreResult.medium.label
    } else if (get(suitabilityTest,'scoreResult') === suitabilityTestScoreResult.low.key) {
      status = suitabilityTestScoreResult.low.label
    } else {
      status = messages.missing
    }
    return (
      <div>
        {<Typography variant='body1' className={classes.toolTip}>
          {!isEmpty(suitabilityTest) ? status : <Trans {...messages.missing} />}
        </Typography>}
      </div>
    )
  }

  restrictDueDiligence() {
    const {dueDiligence} = this.props
    const {clientType} = this.context
    return isAffiliateClient(clientType) && !isEmpty(dueDiligence)
  }

  handleChangeParent(name, value) {
    this.setState({[name]: value})
  }

  render() {
    const {loading, viewerLoading, spoaLoading, accountsLoading, hasGrantedSpoa, spoaRemoteClient, classes, t,
      appropTest, history, match: {path}, canGrantSpoa, hasPortfolioManagement, economicProfile, viewer,
      dueDiligence, suitabilityTest, accounts, location:{pathname}, viewer:{depositedAmount, swapFree, allowSwapFree, locale}} = this.props

    if (loading || viewerLoading || spoaLoading || accountsLoading) return <Loading />
    const {visibleCategoryTypes, showSwapFreePopup} = this.state

    const okDocs = this.getOkDocs()

    const {productConfigs, accountVerification, gdcExcludedCountries} = config
    const {clientType, blockedDeposit, companyObject} = this.context

    let verificationFields
    if (blockedDeposit && clientType && productConfigs[clientType]) {
      verificationFields  = productConfigs[clientType].accountVerification
    } else {
      verificationFields = accountVerification
    }

    const missingVerification = getMissingVerifications(viewer, appropTest, economicProfile, '', verificationFields)

    const hideDocuments = get(viewer, 'eIdVerificationApplicable') && !includes(gdcExcludedCountries, viewer['address']['country'])
    const kycStatusApproved = (viewer['kycStatus'] === kycStatuses.approved.key)

    const canProcessAppropTest = get(viewer,'canProcessAppropTest')
    const appropTestRequired = companies[companyObject.key].requiresAPTest && verificationFields.indexOf('appropriatenessTest') > 0
    const dueDiligenceStatus = get(dueDiligence, 'status')
    const clientDeposited = depositedAmount > 0
    const showDueDiligence = config.common.dueDilligenceRequired[clientType] || !isEmpty(dueDiligence)
    return (
      <div className={classes.root}>
        <Grid container spacing={isMobile()?1:3}>
          {appropTestRequired && !canProcessAppropTest &&  <NotificationBar status='error'>
            <Trans {...messages.appropTestFailed} values={{company: companyObject.trademark}} />
          </NotificationBar>}
          <Grid item xs={12} md={6}>
            <Card>
              <Grid container wrap="nowrap" spacing={isMobile()?1:2} onClick={() => history.push(`${path}/personal-profile/details`)} className={classes.toolOuter}>
                <Grid item>
                  <Avatar className={classes.blueAvatar}>
                    <img src={Images['icon-profile.svg']} alt={t(messages.profileSettingsCardTitle.i18nKey, messages.profileSettingsCardTitle.defaults)} />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography component="h3" variant="subtitle1">
                    {t(messages.profileSettingsCardTitle.i18nKey, messages.profileSettingsCardTitle.defaults)}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom  className={classes.subtitle}>
                    {t(messages.profileSettingsCard.i18nKey, messages.profileSettingsCard.defaults)}
                  </Typography>
                </Grid>
                <Grid item className={classes.tool}>
                  <CustomTooltip
                    title={this.fieldStatus(classes, t, missingVerification, verificationFields)}
                    placement={'top-start'}
                  >
                    {this.checkPersonalProfile(classes, t, missingVerification,verificationFields)}
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={isMobile()?1:3}>
          <Grid item xs={12} md={6}>
            <Card
              onMouseEnter={() => this.setState({showFillPersonalDetailsMsg: includes(missingVerification, 'profile')})}
              onMouseLeave={() => this.setState({showFillPersonalDetailsMsg: false})}
              className={`${includes(missingVerification, 'profile') ? classes.disabledCard : ''}`}>
              <Grid
                container
                wrap="nowrap"
                spacing={isMobile()?1:2}
                onClick={() => !includes(missingVerification, 'profile') && history.push(`${path}/documents`)}
                className={classes.toolOuter}>
                <Grid item>
                  <Avatar className={classes.blueAvatar}>
                    <img src={Images['icon-document.svg']} alt={t(messages.kycSettingsCardTitle.i18nKey, messages.kycSettingsCardTitle.defaults)} />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  {this.state.showFillPersonalDetailsMsg ? (
                    <>
                      <Typography component="h3" variant="subtitle1" color="error">
                        {t(messages.kycSettingsCardMessage.i18nKey, messages.kycSettingsCardMessage.defaults)}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography component="h3" variant="subtitle1">
                        {t(messages.kycSettingsCardTitle.i18nKey, messages.kycSettingsCardTitle.defaults)}
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                        {t(messages.kycSettingsCard.i18nKey, messages.kycSettingsCard.defaults)}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item className={classes.tool}>
                  <CustomTooltip
                    title={this.getKYCTooltip(kycStatusApproved)}
                    placement={'top-start'}
                  >
                    <Typography variant="caption" className={((!hideDocuments && okDocs === visibleCategoryTypes.length) || kycStatusApproved) ? classes.valid : classes.notValid}>
                      {kycStatusApproved && t(messages.completed.i18nKey, messages.completed.defaults)}
                      {!kycStatusApproved && !hideDocuments && (`${okDocs}/${visibleCategoryTypes.length} ${t(messages.documentSettingsStatus.i18nKey,
                        messages.documentSettingsStatus.defaults)}`)}
                      {!kycStatusApproved && hideDocuments && t(messages.missing.i18nKey, messages.missing.defaults)}
                    </Typography>
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {clientDeposited && <Grid container spacing={isMobile()?1:3}>
          <Grid item xs={12} md={6}>
            <Card>
              <Grid container wrap="nowrap" spacing={isMobile()?1:2}
                className={classes.toolOuter}
                onClick={() => history.push({pathname: `${path}/documents/upload/pof`,
                  state: {prevPath: pathname}})}>
                <Grid item>
                  <Avatar className={classes.blueAvatar}>
                    <img src={Images['icon-document.svg']} alt={t(messages.kycSettingsCardTitle.i18nKey, messages.kycSettingsCardTitle.defaults)} />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography component="h3" variant="subtitle1">
                    <Trans {...messages.depositVericationCardTitle} />
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom  className={classes.subtitle}>
                    <Trans {...messages.depositVericationCard} />
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>}
        {appropTestRequired && ( <Grid container spacing={isMobile()?1:3}><Grid item xs={12} md={6}><Card className={!canProcessAppropTest? classes.disabledCard :''}>
          <Grid container wrap="nowrap" spacing={isMobile()?1:2} onClick={() => this.appropTestPath()} className={classes.toolOuter}>
            <Grid item>
              <Avatar className={classes.blueAvatar}>
                <img src={Images['icon-checkbox.svg']} alt={t(messages.appropriatenessTest.i18nKey,
                  messages.appropriatenessTest.defaults)} />
              </Avatar>
            </Grid>
            <Grid item xs>
              <Typography component="h3" variant="subtitle1">
                {t(messages.appropriatenessTest.i18nKey, messages.appropriatenessTest.defaults)}
              </Typography>
              <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                {t(messages.apTestSettingsCard.i18nKey, messages.apTestSettingsCard.defaults)}
              </Typography>
            </Grid>
            {canProcessAppropTest && <Grid item className={classes.tool}>
              <CustomTooltip
                title={this.getApprTestStatus(appropTest)}
                placement={'top-start'}
              >
                <Typography variant="caption" className={!isEmpty(appropTest) ? classes.valid : classes.notValid}>
                  {!isEmpty(appropTest) ? t(messages.completed.i18nKey, messages.completed.defaults) :
                    t(messages.missing.i18nKey, messages.missing.defaults)}
                </Typography>
              </CustomTooltip>
            </Grid>}
          </Grid>
        </Card>
        </Grid></Grid>)}
        {hasWalletProductAccount(accounts) && showDueDiligence &&( <Grid container spacing={isMobile()?1:3}><Grid item xs={12} md={6}><Card className={this.restrictDueDiligence()  ? classes.disabledCard : ''}>
          <Grid container wrap="nowrap" spacing={isMobile()?1:2} onClick={() => this.dueDiligencePath()} className={classes.toolOuter}>
            <Grid item>
              <Avatar className={classes.blueAvatar}>
                <img src={Images['icon-checkbox.svg']} alt={t(messages.dueDiligence.i18nKey,
                  messages.dueDiligence.defaults)} />
              </Avatar>
            </Grid>
            <Grid item xs>
              <Typography component="h3" variant="subtitle1">
                {t(messages.dueDiligence.i18nKey, messages.dueDiligence.defaults)}
              </Typography>
              <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                {t(messages.dueDiligenceSettingsCard.i18nKey, messages.dueDiligenceSettingsCard.defaults)}
              </Typography>
            </Grid>
            <Grid item className={classes.tool}>
              <CustomTooltip
                title={this.getDueDiligenceStatus(dueDiligence)}
                placement={'top-start'}
              >
                <Typography variant="caption" className={!isEmpty(dueDiligence) ? classes[dueDiligenceStatus] : classes.notValid}>
                  {!isEmpty(dueDiligence) ? <Trans {...messages[dueDiligenceStatus]}/> : <Trans {...messages.missing}/>}
                </Typography>
              </CustomTooltip>
            </Grid>
          </Grid>
        </Card>
        </Grid></Grid>)}
        {canGrantSpoa && <Grid container spacing={isMobile()?1:3}><Grid item xs={12} md={6}><Card>
          <Grid container wrap="nowrap" spacing={isMobile()?1:2} onClick={() => history.push(`${path}/spoa-authorization`)} className={classes.toolOuter}>
            <Grid item>
              <Avatar className={classes.blueAvatar}>
                <img src={Images['icon-spoa.svg']} alt={t(messages.settingsSpoaTitle.i18nKey, messages.settingsSpoaTitle.defaults)} />
              </Avatar>
            </Grid>
            <Grid item xs>
              <Typography component="h3" variant="subtitle1" gutterBottom>
                {t(messages.settingsSpoaTitle.i18nKey, messages.settingsSpoaTitle.defaults)}
              </Typography>
              <Typography variant="caption" gutterBottom>
                {t(messages.settingsSpoaCaption.i18nKey, messages.settingsSpoaCaption.defaults)}
              </Typography>
            </Grid>
            <Grid item className={classes.tool}>
              <CustomTooltip
                title={hasGrantedSpoa
                  ? <Trans {...messages.spoaAuthorization} values={{
                    id: spoaRemoteClient.id,
                    firstName: spoaRemoteClient.firstName,
                    lastName: spoaRemoteClient.lastName,
                  }} />
                  : <Trans {...messages.spoaAuthorizationMissing}/>}
                placement={'top-start'}
              >
                <Typography variant="subtitle1" className={hasGrantedSpoa ? classes.valid : classes.notValid}>
                  {hasGrantedSpoa ? <Trans {...messages.granted} /> : <Trans {...messages.missing} />}
                </Typography>
              </CustomTooltip>
            </Grid>
          </Grid>
        </Card></Grid></Grid>}
        {hasPortfolioManagement && <Grid container spacing={isMobile()?1:3}><Grid item xs={12} md={6}><Card>
          <Grid container wrap="nowrap" spacing={isMobile()?1:2} onClick={() => this.portfolioManagementPath()} className={classes.toolOuter}>
            <Grid item>
              <Avatar className={classes.blueAvatar}>
                <img src={Images['icon-spoa.svg']} alt={t(messages.settingsPortfolioManagementTitle.i18nKey, messages.settingsPortfolioManagementTitle.defaults)} />
              </Avatar>
            </Grid>
            <Grid item xs>
              <Typography component="h3" variant="subtitle1">
                {t(messages.suitabilityAssessment.i18nKey, messages.suitabilityAssessment.defaults)}
              </Typography>
              <Typography variant="subtitle2" gutterBottom  className={classes.subtitle}>
                {t(messages.settingsPortfolioManagementCaption.i18nKey, messages.settingsPortfolioManagementCaption.defaults)}
              </Typography>
            </Grid>
            <Grid item className={classes.tool}>
              <CustomTooltip
                title={this.getSuitabilityTestStatus(suitabilityTest)}
                placement={'top-start'}
              >
                <Typography variant="caption" className={!isEmpty(suitabilityTest) ?
                  classes.valid : classes.notValid}>
                  {!isEmpty(suitabilityTest) ? t(messages.completed.i18nKey, messages.completed.defaults) :
                    t(messages.missing.i18nKey, messages.missing.defaults)}
                </Typography>
              </CustomTooltip>
            </Grid>
          </Grid>
        </Card></Grid></Grid>}
        {allowSwapFree && (<Grid container spacing={isMobile()?1:3}>
          <Grid item xs={12} md={6}>
            <Card className={swapFree ? classes.disabledCard : ''}>
              <Grid container wrap="nowrap" spacing={isMobile()?1:2} onClick={() => !swapFree && this.setState({showSwapFreePopup: true})} className={classes.toolOuter}>
                <Grid item>
                  <Avatar className={classes.blueAvatar}>
                    <img src={Images['icon-swapFree.svg']} alt={t(messages.swapFreeIslamicAccount.i18nKey,
                      messages.swapFreeIslamicAccount.defaults)} />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography component="h3" variant="subtitle1">
                    {t(messages.swapFreeIslamicAccount.i18nKey, messages.swapFreeIslamicAccount.defaults)}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom className={classes.subtitle}>
                    {t(messages.swapFreeIslamicAccountText.i18nKey, messages.swapFreeIslamicAccountText.defaults)}
                  </Typography>
                </Grid>
                <Grid item className={classes.tool}>
                  <CustomTooltip
                    title={swapFree ? t(messages.enabled.i18nKey, messages.enabled.defaults)
                      : (messages.disabled.i18nKey, messages.disabled.defaults)}
                    placement={'top-start'}
                  >
                    <Typography variant="caption" className={swapFree ? classes.valid : classes.disabled}>
                      {swapFree ? <Trans {...messages.enabled}/> : <Trans {...messages.disabled}/>}
                    </Typography>
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>)}
        {showSwapFreePopup && <SwapFreeDialog showSwapFreePopup={showSwapFreePopup} locale={locale}
          okDocs={okDocs} kycStatusApproved={kycStatusApproved} handleChangeParent={this.handleChangeParent.bind(this)}/>}
      </div>
    )
  }
}

export default compose (
  withNamespaces(),
  withRouter,
  withStyles(styles),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => {
      const economicProfile = get(props.data, 'viewer.globalQuestionnaire')
      const appropTest = get(props.data, 'viewer.appropTests')
      const dueDiligences = get(props.data, 'viewer.dueDiligences')
      let dueDiligence: any = last(orderBy(filter(dueDiligences, ['status', 'approved']), ['createdAt']))
      if (!dueDiligence) {
        dueDiligence = last(orderBy(dueDiligences, ['createdAt']))
      }
      const suitabilityTests = get(props.data, 'viewer.suitabilityTests')
      let suitabilityTest : any = last(orderBy(filter(suitabilityTests, ['status', 'approved']), ['createdAt']))
      if (!suitabilityTest) {
        suitabilityTest = last(orderBy(suitabilityTests, ['createdAt']))
      }
      return {
        error:props.data?.error,
        loading: props.data?.loading,
        economicProfile,
        appropTest,
        dueDiligence: dueDiligence || {},
        suitabilityTest: suitabilityTest || {},
        documents: get(props.data, 'viewer.documents', []),
        canGrantSpoa: get(props.data, 'viewer.canGrantSpoa', false),
        hasPortfolioManagement: get(props.data, 'viewer.hasPortfolioManagement', false)
      }
    }
  }),
  graphql(SPOA_AUTHORIZATION_QUERY, {
    props: (props) => ({
      error:props.data?.error,
      spoaLoading: props.data?.loading,
      spoaRemoteClient: get(props.data, 'viewer.spoaRemoteClient'),
      hasGrantedSpoa: Boolean(get(props.data, 'viewer.spoaRemoteId')),
    })
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => ({
      error:props.data?.error,
      viewerLoading: props.data?.loading,
      viewer: get(props.data, 'viewer')
    })
  }),
  graphql(ACCOUNTS_QUERY, {
    props: (props) => ({
      accountsLoading: props.data?.loading,
      error: props.data?.error,
      accounts: get(props.data, 'viewer.accounts'),
    })
  }),
)(Profile)
