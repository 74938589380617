
const importAll = (r) => {
  const images = {}
    r.keys().map((item) => { images[item.replace('./', '')] = r(item).default }) // eslint-disable-line
  return images
}
//@ts-ignore todo: this could be big, possibly need to fix that with esm
const Images = importAll(require.context('../../assets/images', false, /\.(png|jpe?g|svg)$/))

export default Images
