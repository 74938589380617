import React from 'react'
import {Route, Switch,} from 'react-router-dom'
import Forex from './Forex'
import Tradesmarter from './Tradesmarter'

const routes = ({match}) => (
  <Switch>
    <Route exact path={`${match.path}/forex/:login`} component={Forex} />
    <Route exact path={`${match.path}/options/:login`} component={Tradesmarter} />
  </Switch>
)

export default routes
