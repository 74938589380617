// @ts-nocheck
import React, {Component} from 'react'
import {graphql, withApollo} from 'react-apollo'
import {get, find, filter, isEmpty, capitalize, includes, flowRight as compose} from 'lodash'
import {Redirect} from 'react-router-dom'
import {withNamespaces, Trans} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PlayArrowOutlined from '@material-ui/icons/PlayArrowOutlined'
import StopOutlined from '@material-ui/icons/StopOutlined'
import Refresh from '@material-ui/icons/Refresh'
import LockOutlined from '@material-ui/icons/LockOutlined'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import CircularProgress from '@material-ui/core/CircularProgress'

import {safeParseJSON} from '../../common/utils/general'
import {socketManager} from '../../socketManager'
import {
  vpsSubscriptionStatuses,
  vpsVMActions,
  vpsServerStatuses
} from '@bdswiss/common-enums'
import messages from '../../assets/messages'
import {
  START_VPS_SERVER_MUTATION,
  STOP_VPS_SERVER_MUTATION,
  REBOOT_VPS_SERVER_MUTATION,
  RESET_VPS_SERVER_PASSWORD_MUTATION
} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY, ACCOUNTS_QUERY, VPS_SUBSCRIPTIONS_QUERY,
  VPS_SERVICE_PASSWORD_QUERY, CONFIG_QUERY} from '../../graphql/queries'
import {AlertDialog} from '../Common/Dialog/index'
import {Loading} from '../Common/Loading'
import PageSubTitle from '../Common/PageSubTitle'
import VpsSubscriptionMsg from './VpsSubscriptionMsg'
import LatencyChart from './LatencyChart'
import VpsInstructions from './VpsInstructions'

const styles = theme => ({
  successMessage:{
    color: theme.palette.green.color
  },
  vpsTable:{
    '& td':{
      borderBottom: 'none',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 16,
      },
    }
  },
  textBlue:{
    color: theme.palette.primary.main,
    marginRight: 0,
    paddingRight: 16,
    cursor: 'pointer'
  },
  inlineTypography: {
    display:'inline-block',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  listItem:{
    paddingLeft: '0px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
    },
  },
  listItemText:{
    color: theme.palette.primary.main,
    cursor: 'pointer',
    flexGrow: 0
  },
  icon: {
    margin:'0',
    width: '24px',
    height: '24px'
  },
  highlight: {
    fontWeight: 400,
  },
  buttonProgress:{
    color: theme.palette.primary.main,
  },
  howtoLink:{
    marginTop: 9,
    cursor:'pointer',
    [theme.breakpoints.down('xs')]: {
      marginTop: 5,
    }
  }
})


const vpsVMActionItems = {
  start: {action: 'startVpsServer', icon: <PlayArrowOutlined />},
  stop: {action: 'stopVpsServer', icon: <StopOutlined />},
  reboot: {action: 'rebootVpsServer', icon: <Refresh style={{transform: 'scale(0.9)'}}/>},
  resetPassword: {action: 'resetVpsServerPassword', icon: <LockOutlined style={{transform: 'scale(0.9)'}}/>},
}

export class VpsManage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: '',
      loading: false,
      openConfirmationModal: false,
      loadingConfirmation: false,
      action: null,
      buttonStatus: '',
      showPassword: false,
      vpsPassword: '********',
      openInstructions: false,
    }

    if (!socketManager.connection) {
      socketManager.initialize(get(props.clientData, 'id'))
    }
    socketManager.connection.on('vpsVMSUpdate', () => {
      this.props.vpsStartPolling(1000)
      setTimeout(()=> this.props.vpsStopPolling(), 2000)
    })
  }

  openConfirmationModal(action) {
    this.setState({
      action,
      openConfirmationModal: true,
    })
  }

  closeConfirmationModal() {
    this.setState({
      action: null,
      openConfirmationModal: false,
      loadingConfirmation: false,
      confirmationSuccess: '',
      confirmationError: '',
      buttonStatus: ''
    })
  }

  executeVMAction(subscription, action) {
    this.setState({loadingConfirmation: true})
    const {t} = this.props
    const actionMessage = messages[vpsVMActions[action].i18n]
    const gqlAction = vpsVMActionItems[action].action

    this.props[gqlAction]({variables: {
      subscriptionId: subscription.id,
    }})
      .then((res) => {
        const result = get(res, `data.${gqlAction}`)
        if (!result || 'error' in result) {
          this.setState({
            loadingConfirmation: false,
            buttonStatus: 'failure',
            confirmationError: <Trans {...messages.vpsActionFailure}
              values={{
                action: t(actionMessage.i18nKey),
                error: get(result, 'error')
              }}
            />
          })
        } else {
          this.setState({
            loadingConfirmation: false,
            buttonStatus: 'success',
            confirmationSuccess: <Trans {...messages.vpsActionSuccess}
              values={{
                action: t(actionMessage.i18nKey),
              }}
            />
          })
        }
      })
      .catch((err) => {
        // console.dir(err)
        this.setState({
          loadingConfirmation: false,
          buttonStatus: 'failure',
          confirmationError: <Trans {...messages.vpsActionFailure}
            values={{
              action: t(actionMessage.i18nKey),
              error: get(err, 'message')
            }}
          />
        })
      })
  }

  renderConfirmationModal(subscription) {
    const {t, classes} = this.props
    const {
      loadingConfirmation,
      confirmationSuccess,
      confirmationError,
      openConfirmationModal,
      buttonStatus,
      action
    } = this.state
    const actionMessage = messages[vpsVMActions[action].i18n]

    return <AlertDialog
      open={openConfirmationModal}
      onClose={() => this.closeConfirmationModal()}
      onAgree={() => this.executeVMAction(subscription, action)}
      onDisagree={() => this.closeConfirmationModal()}
      disagreeText={buttonStatus ? t(messages.close.i18nKey, messages.close.defaults) :
        t(messages.cancel.i18nKey, messages.cancel.defaults)}
      agreeText={t(messages.proceedButton.i18nKey, messages.proceedButton.defaults)}
      buttonLoading={loadingConfirmation}
      buttonStatus={buttonStatus}
      successText={confirmationSuccess}
      errorText={confirmationError}
      disabledTypography
      title = { <Typography variant='h4' >
        <Trans {...messages.submitWithdrawalTitle} />
      </Typography>}
    >
      <Typography variant='body1'>
        <Trans
          {...messages.vpsActionConfirmation}
          values={{action: t(actionMessage.i18nKey)}}
          components={[<span className={classes.highlight}>plan</span>]}
        /></Typography>
      {action === 'resetPassword' && <Typography variant='body1'>
        <Trans
          {...messages.vpsResetPassNotice}
          components={[<span className={classes.highlight}>plan</span>]}
        /></Typography>}
    </AlertDialog>
  }

  handleClickShowPassword = (subscription) => {
    const {client} = this.props
    const {vpsPassword} = this.state
    this.setState(state => ({loadingPassword: true}))
    if (includes(vpsPassword, '*****')) {
      client.query({query: VPS_SERVICE_PASSWORD_QUERY, variables: {serviceId: subscription.serviceId},
        fetchPolicy:'network-only'}).then((res) => {
        this.setState(state => ({showPassword: !state.showPassword,
          vpsPassword: res.data.getVpsServicePassword.password, loadingPassword: false}))
      }).catch((e) => {
        this.setState(state => ({vpsPasswordDisplayError:true, loadingPassword: false}))
      })
    } else {
      this.setState(state => ({showPassword: !state.showPassword, loadingPassword: false}))
    }

  }

  renderVpsServerDetails(subscription) {
    const {classes, accounts, configVariables:{vpsDunningManagementDays}} = this.props
    const {vpsPassword, loadingPassword, openInstructions} = this.state
    const vms = safeParseJSON(subscription.vms)
    const vmStatus = find(vpsServerStatuses, s => s.value == vms.status) // eslint-disable-line eqeqeq

    return <>
      <Grid item xs={12}>
        {subscription && <VpsSubscriptionMsg
          accounts={accounts}
          subscription={subscription}
          notificationStatus={this.getNotificationStatus(subscription)}
          dunningManagementDays={vpsDunningManagementDays}
        />}
      </Grid>
      <Grid item xs={12} sm={6}>
        <PageSubTitle><Trans {...messages.vpsServerDetails} /></PageSubTitle>
        <Table className={classes.vpsTable}>
          <TableBody>
            <TableRow>
              <TableCell><Trans {...messages.vpsOS} /></TableCell>
              <TableCell>{capitalize(vms.os_name.split('-')[0])}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><Trans {...messages.status} /></TableCell>
              <TableCell><Trans {...messages[vmStatus.i18n]} /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><Trans {...messages.ipAddress} /></TableCell>
              <TableCell>{Object.values(vms.ips).join()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><Trans {...messages.vpsPassword} /></TableCell>
              <TableCell>
                <Input
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={vpsPassword}
                  readOnly={true}
                  disableUnderline={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => this.handleClickShowPassword(subscription)}
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        {loadingPassword && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><Trans {...messages.vpsRAM} /></TableCell>
              <TableCell>{vms.ram} MB</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><Trans {...messages.vpsBandwidth} /></TableCell>
              <TableCell>{vms.bandwidth} GB</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PageSubTitle rightAction={
          <Typography className={classes.howtoLink} variant={'body2'} color="primary"  onClick={() =>this.setState({openInstructions: true})}>
            <span ><Trans {...messages.howToAccessVps} /></span>
          </Typography>
        }><Trans {...messages.vpsServerActions} /></PageSubTitle>
        <List>
          {Object.entries(vpsVMActionItems).map(([key, item]) => (
            <ListItem
              key={key}
              className={classes.listItem}
            >
              <ListItemIcon className={classes.textBlue} onClick={() => this.openConfirmationModal(key)}>{item.icon}</ListItemIcon>
              <ListItemText className={classes.listItemText}
                onClick={() => this.openConfirmationModal(key)}
                disableTypography={true}>
                <Trans {...messages[vpsVMActions[key].i18n]} />{}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <PageSubTitle><Trans {...messages.vpsLatencyChart} /></PageSubTitle>
        <LatencyChart t={this.props.t}/>
      </Grid>
      {openInstructions && <VpsInstructions onClose={() => this.setState({openInstructions: false})} />}
    </>
  }

  renderVpsSubscription(subscription) {
    const {openConfirmationModal} = this.state

    return  <Grid container spacing={1}>
      {openConfirmationModal && this.renderConfirmationModal(subscription)}
      {this.renderVpsServerDetails(subscription)}
    </Grid>
  }

  getNotificationStatus(subscription) {
    return subscription && subscription.lastPaymentFailed ? 'error' :
      !subscription.isActive ? 'warning' : 'info'
  }

  renderVpsSubscriptionWait(subscription) {
    const {accounts,configVariables:{vpsDunningManagementDays}} = this.props
    return <Grid item xs={12}>
      {subscription && <VpsSubscriptionMsg
        accounts={accounts}
        subscription={subscription}
        notificationStatus={this.getNotificationStatus(subscription)}
        dunningManagementDays={vpsDunningManagementDays}
      />}
    </Grid>
  }

  render() {
    const {vpsSubscriptionsLoading, classes, vpsSubscriptions, clientLoading, loading} = this.props

    if (vpsSubscriptionsLoading || clientLoading || loading) return <Loading />

    const currentSubscription = !isEmpty(vpsSubscriptions)
      && vpsSubscriptions.find(s => s.status !== vpsSubscriptionStatuses.deleted.value)

    const vms = currentSubscription && safeParseJSON(currentSubscription.vms)

    return (
      <React.Fragment>
        <Grid container className={classes.container} spacing={1}>
          {currentSubscription
            ? isEmpty(vms)
              ? this.renderVpsSubscriptionWait(currentSubscription)
              : this.renderVpsSubscription(currentSubscription)
            : <Redirect to={{pathname: '/vps/plan'}}/>
          }
        </Grid>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  withApollo,
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data, ownProps: {history}}) => ({
      clientLoading: loading,
      clientError: error,
      history,
      clientData: get(data, 'viewer'),
    }),
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const accounts = filter(get(data, 'viewer.accounts', []), (a) => a.accountSubtype)
      return {
        error,
        loading,
        accounts,
      }
    }
  }),
  graphql(VPS_SUBSCRIPTIONS_QUERY, {
    props: ({data: {error, loading, startPolling, stopPolling}, data}) => ({
      vpsSubscriptionsLoading: loading,
      vpsSubscriptionsError: error,
      vpsSubscriptions: get(data, 'viewer.vpsSubscriptions', []),
      vpsStartPolling: startPolling,
      vpsStopPolling: stopPolling,
    }),
  }),
  graphql(CONFIG_QUERY, {
    props: ({data: {error, loading: configLoading}, data}) => ({
      error,
      configLoading,
      configVariables: get(data, 'config'),
    })
  }),
  graphql(START_VPS_SERVER_MUTATION, {
    name: 'startVpsServer',
  }),
  graphql(STOP_VPS_SERVER_MUTATION, {
    name: 'stopVpsServer',
  }),
  graphql(REBOOT_VPS_SERVER_MUTATION, {
    name: 'rebootVpsServer',
  }),
  graphql(RESET_VPS_SERVER_PASSWORD_MUTATION, {
    name: 'resetVpsServerPassword',
  }),
)(VpsManage)
