import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import AppContext from '../../../Common/contexts/AppContext'
import {Typography} from '@material-ui/core'
import messages from '../../../../assets/messages'
import Amount from '../../../Common/Amount'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {map, isEmpty, flowRight as compose} from 'lodash'
import {config} from '../../../../config'
import moment from 'moment'
import TableFooter from '@material-ui/core/TableFooter'

const styles = theme => ({
  greenText: {
    color: theme.palette.green.color,
  },
  redText: {
    color: theme.palette.red.color,
  },
  yellowText: {
    color: theme.palette.yellow.color,
  },
  firstCell: {
    paddingLeft: 0
  },
})

class FollowersTableDesktop extends Component {
  static contextType = AppContext

  render() {
    const {classes, followers, showPagination, activeMonth, renderPagination, isIb} = this.props
    return <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.firstCell}>{(isIb) ? <Trans {...messages.affiliateAccountId} /> : `${config.name} ID`}</TableCell>
          <TableCell><Trans {...messages.startCopyingDate} /></TableCell>
          <TableCell><Trans {...messages.profitPerPeriod} /></TableCell>
          <TableCell><Trans {...messages.stopCopyingDate} /></TableCell>
          <TableCell><Trans {...messages.performanceFeePaid} /></TableCell>
          {activeMonth && <TableCell><Trans {...messages.performanceFee} /></TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {map(followers, (follower) => {
          const startDate = moment(follower.startCopyingDate)
          const stopDate = moment(follower.stopCopyingDate)
          return <TableRow key={(isIb) ? follower.remoteId : follower.accountId}>
            <TableCell className={classes.firstCell}>
              <Typography variant="body1">{(isIb) ? follower.remoteId : follower.accountId}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">{startDate.isValid() && startDate.format('DD/MM/YYYY')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">
                <Amount value={follower.profitPerPeriod} currency={follower.currency} locale='en-US' />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">
                {isEmpty(follower.stopCopyingDate) ? <Trans {...messages.copying} /> : stopDate.isValid() &&  stopDate.format('DD/MM/YYYY')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">
                <Amount
                  value={follower.performanceFeePaid} currency={follower.currency} locale='en-US' classes={{
                    root: (follower.performanceFeePaid > 0) ? classes.greenText :
                      (activeMonth && !follower.stopCopyingDate) ? classes.yellowText : classes.redText,
                  }} />
              </Typography>
            </TableCell>
            {activeMonth && <TableCell>
              <Typography variant="body1">
                <Amount
                  value={follower.projectionPerformanceFee} currency={follower.currency} locale='en-US' classes={{
                    root: classes.yellowText,
                  }} />
              </Typography>
            </TableCell>}
          </TableRow>
        })}
      </TableBody>
      {showPagination && <TableFooter>
        <TableRow>
          {renderPagination()}
        </TableRow>
      </TableFooter>}
    </Table>
  }
}


export default compose(
  withStyles(styles),
  withNamespaces(),
)(FollowersTableDesktop)
