import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {get, first, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import {AlertDialog} from '../Common/Dialog'
import AccountSelect from '../Common/AccountSelect'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'
import {referAndEarnDetails} from '../../common/utils/uioptions'
import {REFERRAL_DETAILS_QUERY} from '../../graphql/queries'
import {CLAIM_REWARD_MUTATION} from '../../graphql/mutations'
import {getPlatform} from '../../common/utils'
import {InnerAppContext} from '../../common/types'

export class ClaimRewardPopupMessage extends Component<any,any> {
    static contextType = AppContext
    context!: InnerAppContext
    constructor(props) {
      super(props)
      this.state = {
        account: get(first(props.accounts), 'id') || props.preselectedAccount,
        showClaim: true
      }
    }

    claimReward() {
      const {account} = this.state
      const {preselectedAccount, rewardType, onClose} = this.props
      this.setState({status: '', loading: true})
      const variables = {
        accountId: account || preselectedAccount,
        rewardType
      }

      this.props.claimReward({variables}).then((succ) => {
        this.setState({loading: false, status: 'success', submitMessageError: '',rewardDetails: get(succ, 'data.claimReward')})
        onClose()
      })
        .catch((err) => {
          this.setState({loading: false, status: 'failure', submitMessageError: get(err, 'graphQLErrors[0].message') || err.message})
        })
    }

    handleAccountChange(accountId) {
      this.setState({account: accountId})
    }

    render() {
      const {t, rewardsObj, open, accounts, onClose} = this.props
      const {account, submitMessageError, loading, status} = this.state
      const {locale} = this.context

      return (
        <AlertDialog
          open={open}
          onClose={() => onClose()}
          title={t(messages.claimReward.i18nKey, messages.claimReward.defaults)}
          agreeText={t(messages.claim.i18nKey, messages.claim.defaults)}
          onAgree={() => this.claimReward()}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          onDisagree={() => onClose()}
          errorText={submitMessageError}
          buttonLoading={loading}
          buttonStatus={status}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages.claimMessage} values={{amount: `${referAndEarnDetails.currency}${get(rewardsObj, 'pendingRewards')}`}}/></Typography>
            </Grid>
            <Grid item xs={12}>
              <AccountSelect
                label={t(messages.selectAccount.i18nKey, messages.selectAccount.defaults)}
                accounts={accounts}
                value={account}
                locale={locale}
                id="account"
                name="account"
                onChange={(e) => this.handleAccountChange(e.target.value)}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      )
    }
}
export default compose(
  withNamespaces(),
  graphql(CLAIM_REWARD_MUTATION, {
    name: 'claimReward',
    options: {
      refetchQueries: () => [{query: REFERRAL_DETAILS_QUERY, variables: {platform: JSON.stringify(getPlatform())}}],
    },
  }),
)(ClaimRewardPopupMessage)
