import Images from '../../components/Common/Images'
import messages from '../../assets/messages'
import {companies, regulators, countries} from '@bdswiss/common-enums'
import {map, filter} from 'lodash'

export const europeCountries= map(filter(countries, 'isEU'), 'value')

export const officialOnlineTradingSponsors = [
  {
    src: 'mercedesCup',
    alt: 'Mercedes Cup'
  },
  {
    src: 'dpWorldTourChampionship',
    alt: 'DP World Tour Championship'
  }
]

export const tabStats ={
  2012:{
    registeredUsers: '19,998',
    avgTrading: '1,066,291',
    depositMethods: '6',
    avgWithdrawal: '97hrs',
    supportTime: '32m 44s'
  },
  2013:{
    registeredUsers: '84,353',
    avgTrading: '3,282,822',
    depositMethods: '8',
    avgWithdrawal: '96hrs',
    supportTime: '15m 3s'
  },
  2014:{
    registeredUsers: '240,496',
    avgTrading: '9,308,132',
    depositMethods: '14',
    avgWithdrawal: '38hrs',
    supportTime: '8m 12s'
  },
  2015:{
    registeredUsers: '431,867',
    avgTrading: '14,346,207',
    depositMethods: '32',
    avgWithdrawal: '29hrs',
    supportTime: '5m 36s'
  },
  2016:{
    registeredUsers: '585,104',
    avgTrading: '1,758,909,310',
    depositMethods: '87',
    avgWithdrawal: '22hrs',
    supportTime: '3m 40s'
  },
  2017:{
    registeredUsers: '936,639',
    avgTrading: '4,824,558,933',
    depositMethods: '55',
    avgWithdrawal: '12hrs',
    supportTime: '3m 11s'
  },
  2018:{
    registeredUsers: '1,043,859',
    avgTrading: '11,448,594,083',
    depositMethods: '148',
    avgWithdrawal: '5hrs',
    supportTime: '35s'
  },
  2019:{
    registeredUsers: '1,198,295',
    avgTrading: '35,703,368,472',
    depositMethods: '151',
    avgWithdrawal: '5hrs',
    supportTime: '35s',
  },
  2020:{
    registeredUsers: '1,638,390',
    avgTrading: '73,405,180,902',
    depositMethods: '151',
    avgWithdrawal: '9hrs',
    supportTime: '50s',
  },
  2021:{
    registeredUsers: '',
    avgTrading: '',
    depositMethods: '',
    avgWithdrawal: '',
    supportTime: '',
    src: Images['spaceship.png'],
    alt: '2021'
  },
}

export const companiesDescription ={
  bdSwissMauritius:{
    regulatedBy: {
      message: messages.regulatorEntity,
      values: {
        regulator: regulators[companies.bdSwissMauritius.regulator!].label
      }
    },
    leverageUpTo: {
      message: messages.leverageUp,
      values: {
        leverage: 500
      }
    },
    insurance: messages.insurance,
    negativeBalance: messages.negativeBalance,
    wealthManagement: messages.wealthManagement,
  },
  bdSwiss:{
    regulatedBy: {
      message: messages.regulatorEntity,
      values: {
        regulator: regulators[companies.bdSwiss.regulator!].label
      }
    },
    leverageUpTo: {
      message: messages.leverageUp,
      values: {
        leverage: 30
      }
    },
    icfProtection: messages.icfProtection,
    negativeBalance: messages.negativeBalance,
  },
  bdSwissVanuatu:{
    regulatedBy: {
      message: messages.regulatorEntity,
      values: {
        regulator: regulators[companies.bdSwissVanuatu.regulator!].label
      }
    },
    leverageUpTo: {
      message: messages.leverageUp,
      values: {
        leverage: 500
      }
    },
    negativeBalance: messages.negativeBalance,
  },
}

export const accountTypeStats ={
  basic:{
    details :{
      minDepositAmount: 100,
      averageSpread: 1.5
    },
    commission: {
      forexMetals: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      energies: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      indices: {
        value: '$2',
        text: 'equivalentPerLot'
      },
      equities: {
        value: '0.15%',
        text: 'ofVolume'
      },
    },
    benefits: ['twentyfourFiveSupport', 'dailyAnalysis', 'tradingAcademy', 'freeLiveWebinars', 'basicAccessAutochartist', 'limitedAccessTradingAlerts'],
  },
  black:{
    details :{
      minDepositAmount: 3000,
      averageSpread: 1.1
    },
    commission: {
      forexMetals: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      energies: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      indices: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      equities: {
        value: '0.15%',
        text: 'ofVolume'
      },
    },
    benefits: ['priorityServices', 'dailyAnalysis', 'personalAccountManager', 'accessExclusiveWebinars', 'autochartistPerformanceStats', 'vipTradingAlerts'],
  },
  raw:{
    details :{
      minDepositAmount: 5000,
      averageSpread: 0.0
    },
    commission: {
      forexMetals: {
        value: '$5',
        text: 'equivalentPerLot',
        cn: {
          value: '$7',
        }
      },
      energies: {
        value: '$5',
        text: 'equivalentPerLot',
        cn: {
          value: '$7',
        }
      },
      indices: {
        value: '$2',
        text: 'equivalentPerLot'
      },
      equities: {
        value: '0.15%',
        text: 'ofVolume'
      },
    },
    benefits: ['rawSpreadsFromZero', 'priorityServices', 'dailyAnalysis', 'personalAccountManager', 'accessExclusiveWebinars', 'autochartistPerformanceStats', 'vipTradingAlerts'],
  },
  invest_plus:{
    details :{
      minDepositAmount: 5000,
      leverage: '1:5',
    },
    commission: {
      shares: {
        value: '0.1%',
        text: 'commissionValue'
      },
      etfs: {
        value: '0.1%',
        text: 'commissionValue'
      },
    },
    benefits: ['twentyfourFiveSupport', 'dailyAnalysis', 'tradingAcademy', 'freeLiveWebinars', 'basicAccessAutochartist', 'personalAccountManager', 'dividendsPaid'],
  },
  cent: {
    details: {
      minDepositAmountUsd: 10,
      averageSpread: 1.5,
      averageMetalsEnergiesSpread: 30,
    },
    commission: {
      forexMetals: {
        value: '$0',
        text: 'equivalentPerLotZero'
      },
      energies: {
        value: '$0',
        text: 'equivalentPerLotZero'
      },
    },
    benefits: ['upTo500Leverage', 'twentyfourFiveSupport', 'dailyAnalysis', 'tradingAcademy', 'freeLiveWebinars', 'tradingCentralTools', 'basicAccessAutochartist', 'limitedAccessTradingAlerts'],
  },

  premium: {
    details: {
      minDepositAmountUsd: 100,
      averageSpread: 1.1,
      averageMetalsEnergiesSpread: 30,
    },
    commission: {
      forexMetals: {
        value: '$0',
        text: 'equivalentPerLotZero'
      },
      energies: {
        value: '$0',
        text: 'equivalentPerLotZero'
      },
    },
    benefits: ['upToLeverage', 'twentyfourFiveSupport', 'dailyAnalysis', 'tradingAcademy', 'freeLiveWebinars', 'tradingCentralTools', 'basicAccessAutochartist', 'limitedAccessTradingAlerts'],
    note: 'leverageNote',
  },
}

export const documentHelperText ={
  poi:{
    makeSure: ['makeSureDocumentsTextPoi1', 'makeSureDocumentsTextPoi2', 'makeSureDocumentsTextPoi3'],
  },
  por:{
    acceptedDocuments: ['acceptedDocumentsTextPor1', 'acceptedDocumentsTextPor2', 'acceptedDocumentsTextPor3', 'acceptedDocumentsTextPor4', 'acceptedDocumentsTextPor5'],
    makeSure: ['makeSureDocumentsTextPor1', 'makeSureDocumentsTextPoi2'],
    shouldInclude: ['makeSureDocumentsTextPor3Sub1', 'makeSureDocumentsTextPor3Sub2', 'makeSureDocumentsTextPor3Sub3', 'makeSureDocumentsTextPor3Sub4'],
  },
  pof:{
    makeSure: ['makeSureDocumentsTextPoi1', 'makeSureDocumentsTextPoi2', 'makeSureDocumentsTextPoi3'],
    card: {
      instructions: ['pofCardInstructions']
    },
    virtualCard: {
      instructions: ['pofVirtualCardInstructions']
    }
  },
  partner: {
    makeSure: ['makeSureDocumentsTextPoi1', 'makeSureDocumentsTextPoi2', 'makeSureDocumentsTextPoi3'],
  },
  personal: {
    makeSure: ['makeSureDocumentsTextPoi1', 'makeSureDocumentsTextPoi2', 'makeSureDocumentsTextPoi3'],
  }
}

export const commonIbanCountries = {
  IM: 'GB',
}

export const performanceFeeOptions = {
  options: [0, 5, 10, 15, 20, 25, 30, 35, 40 ,45, 50],
  default: 10,
}

export const serversLabel = {
  forexMt4: 'MT4',
  forexMt5: 'MT5',
  cent: 'MT4',
}

export const referAndEarnDetails = {
  referrerInstructions: ['referAndEarnText1', 'referAndEarnText2', 'referAndEarnText3', 'referAndEarnText4', 'referAndEarnText5'],
  referralInstructions: ['congratulationsText1', 'congratulationsText2', 'congratulationsText3', 'congratulationsText4'],
  minDeposit: 500,
  minTrades: 6,
  days: 30,
  currency: '€',
}

export const isRtlLang = ['ar']

export const momentLocales = {
  no: 'nb',
  ph: 'tl-ph',
  zh: 'zh-cn',
  excluded:  ['ar', 'hi'],
  default: 'en'
}

export const eBookHelperText = {
  technicalIndicator:{
    points: ['technicalIndicatorListItem1', 'technicalIndicatorListItem2', 'technicalIndicatorListItem3',
      'technicalIndicatorListItem4', 'technicalIndicatorListItem5','technicalIndicatorListItem6',
      'technicalIndicatorListItem7', 'technicalIndicatorListItem8', 'technicalIndicatorListItem9'],
  },
  tradingPsychology:{
    points: ['tradingPsychologyListItem1', 'tradingPsychologyListItem2', 'tradingPsychologyListItem3',
      'tradingPsychologyListItem4', 'tradingPsychologyListItem5','tradingPsychologyListItem6',
      'tradingPsychologyListItem7', 'tradingPsychologyListItem8'],
  },
}

export const euRegulationHelperText = {
  bdSwiss: ['successfulMigrationText1', 'successfulMigrationText2', 'successfulMigrationText4'],
  swissMarkets: ['successfulMigrationText1', 'successfulMigrationText3', 'successfulMigrationText4']
}

export const wireTransferNotAvailableBanks = [
  {
    key: 'monsas',
    label: 'Monsas',
    startDate: '2021-08-20',
    activeDays: 42,
    availablePayments: ['bankWire'],
  }
]

export const partnerMigrationText = {
  bdSwiss: 'euBased'
}

export const accountCategoriesDetails = {
  cryptoWallet: {
    bitnuk: {
      dot: true,
      startDate: '2021-09-09',
      activeDays: 60,
      newBadge: true,
      benefits: ['bitwalletBenefit1', 'bitwalletBenefit2', 'bitwalletBenefit3', 'bitwalletBenefit4']
    }
  }
}

export const withdrawalWarnings = {
  bankTransfer: {
    title: 'bankTransferWithdrawalWarningTitle',
    content: ['bankTransferWithdrawalWarningText'],
  }
}

export const downloadMt5Notes = {
  fundYourAccountText: {
    key: 'fundYourAccountText',
    subContent: [{key: 'yourBalance'}],
  },
  downloadServerDesktopApplication: {
    key: 'downloadServerDesktopApplication',
  },
  openServerApplication: {
    key: 'openServerApplication',
  },
  addAccountLoginAccount: {
    key: 'addAccountLoginAccount',
  },
  enterAccountDetails: {
    key: 'fundYourAccountText',
    subContent: [{key: 'login'}, {key: 'password'}, {key: 'server', subContent: 'serverName'}],
  }
}

export const localesUpperNameFields = ['de']

export const fnsLocales = {
  no: 'nb',
  zh: 'zhCN',
  ar:  'arSA',
  default: 'enUS'
}
