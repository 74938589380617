import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const styles = (theme) => ({
  header: {
    marginBottom: 15,
    marginTop: 15
  },
  [theme.breakpoints.down('sm')]: {
    header: {
      marginBottom: 0,
      marginTop: 0
    },
  },
  action: {
    marginTop: 10,
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      margin: '0'
    }
  },
})


const PageSubTitle = props => <Grid container justifyContent="space-between" >
  <Grid item >
    <Typography component='h3' variant="h3" gutterBottom className={props.classes.header}>
      {props.children}
    </Typography>
  </Grid>
  {props.rightAction && <Grid item className={props.classes.action}>
    {props.rightAction}
  </Grid>}
</Grid>


PageSubTitle.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(PageSubTitle)
