//@ts-nocheck
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {isEmpty, values, flowRight as compose} from 'lodash'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import {frontends} from '@bdswiss/common-enums'
import {withNamespaces, Trans} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import CreditCardIcon from '@material-ui/icons/CreditCardRounded'
import SelfPostingForm from './SelfPostingForm'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import messages from '../../../../assets/messages'
import {getPlatform} from '../../../../common/utils'
import {validateNumber} from '../../../../common/utils/validations'

const validators = {
  cardNumber: (value) => {
    if (!value) return messages.requiredField
    if (value.length < 12 || !validateNumber(value)) return messages.invalidCardNumber
  }
}

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.main
  },
})

class FuntoPayProvider extends Component {

  state = {form:{cardNumber: ''}, errors:{}, doSubmitForm: false}

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      const validate = validators[k]
      errors[k] = validate && validate(form[k])
    })
    this.setState({errors})
  }

  formIsValid() {
    const {form, errors} = this.state
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(form).filter((v) => v))
  }

  handleChange(field, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [field]: value
      }
    }, () => this.validateFields())
  }

  doCreateDeposit() {
    if (!this.formIsValid()) return

    const {providerProperties: {provider}, account, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms} = this.props
    const {form: {cardNumber}} = this.state

    this.setState({creatingDeposit: true})

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        cardNumber,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        this.setState({formData: JSON.parse(url), creatingDeposit: false, doSubmitForm: true})
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  render() {
    const {onSubmit, classes} = this.props
    const {errors, formData, doSubmitForm, creatingDeposit} = this.state

    return (
      <React.Fragment>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              <Trans {...messages.enterPaymentDetails} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="cardNumber">
                <Trans {...messages.cardNumber} />
              </InputLabel>
              <Input
                id="cardNumber"
                name="cardNumber"
                type="text"
                error={!!errors.cardNumber}
                onChange={(e) => this.handleChange('cardNumber', e.target.value)}
                inputProps={{pattern: '[0-9]*', inputMode:'numeric'}}
                startAdornment={
                  <InputAdornment position="start" >
                    <CreditCardIcon />
                  </InputAdornment>
                }
              />
              {
                errors.cardNumber &&
                <FormHelperText className={classes.error}><Trans {...errors.cardNumber} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          <PaymentActionButton
            loading={creatingDeposit}
            disable={!this.formIsValid()}
            onClick={() => this.doCreateDeposit()}
          />
          <SelfPostingForm formData={formData} doSubmitForm={doSubmitForm} onSubmit={onSubmit}/>
        </Grid>

      </React.Fragment>
    )
  }
}

FuntoPayProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withNamespaces(),
  withStyles(style),
  withCreateDeposit
)(FuntoPayProvider)
