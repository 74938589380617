import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {get, map, some, find, floor, flowRight as compose} from 'lodash'
import Button from '@material-ui/core/Button'
import {withNamespaces, Trans} from 'react-i18next'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import {currencies, kycStatuses} from '@bdswiss/common-enums'
import messages from '../../../assets/messages'
import AmountInput from '../../Common/AmountInput'
import AccountSelect from '../../Common/AccountSelect'
import PageSubTitle from '../../Common/PageSubTitle'
import FreeMarginWarning from './FreeMarginWarning'
import {getFormattedAmount, getPlatform} from '../../../common/utils'
import NotificationBar from '../../Common/NotificationBar'
import {isBitnukAccount, isCentAccount, isForexAccount} from '../../../common/utils/accounts'
import {config} from '../../../config'

const validators = {
  amount: (initialAmount, account) => {
    const amount = isCentAccount(account) ? initialAmount * 100 : initialAmount
    if (!amount) {
      return {
        hasError: true,
        message: messages.requiredField,
      }
    }
    if (amount > (account.balance - account.pendingAmount)) {
      return {
        hasError: true,
        message: messages.insufficientFunds,
      }
    }
    if (amount <= 0) {
      return {
        hasError: true,
        message: messages.positiveNumber,
      }
    }
    return {
      hasError: false,
      message: {},
    }
  },
  accountId: (accountId, account) => ({
    hasError: !accountId,
    message: !accountId ? messages.requiredField : {}
  })
}

class ChooseAccount extends Component<any,any> {
  constructor(props) {
    super(props)
    const {accounts, viewer: {kycStatus}, accountId, amount} = props

    this.state = {
      kycStatusNotApproved: kycStatus !== kycStatuses.approved.key,
      form: {
        accountId: accountId || get(accounts, '[0].id'),
        amount: amount || '',
      },
      errors: {},
      showFreeMarginWarning: false,
    }
  }

  chooseMethod() {
    const {onContinue, accounts} = this.props
    const {form, form: {accountId, amount}} = this.state
    const selectedAccount = find(accounts, {id: accountId})

    const errors: any = {}
    for (const field of Object.keys(form)) {
      errors[field] = validators[field](form[field], selectedAccount)
    }

    if (some(errors, (e) => e.hasError)) {
      this.setState({errors})
      return
    }

    const {balance, freeMargin} = selectedAccount
    const isAmountNearFreeMargin = isForexAccount(selectedAccount) &&
      freeMargin !== balance && amount <= floor(freeMargin * 1.1, 2) && // 10% above
      amount >= floor(freeMargin * 0.9, 2) // 10% below

    if (isAmountNearFreeMargin) {
      this.setState({showFreeMarginWarning: true})
    } else {
      onContinue(accountId, isCentAccount(selectedAccount) ? amount * 100 : amount)
    }
  }

  handleChange = e => {
    const {accounts, getSelectedAccount} = this.props
    const {form: {accountId}} = this.state
    const selectedAccount = find(accounts, {id: accountId})
    if (e.target.name === 'accountId') {
      getSelectedAccount(find(accounts, {id: e.target.value}))
    }
    this.setState(state => ({
      form: {
        ...state.form,
        [e.target.name]: e.target.value
      },
      errors: {
        ...state.errors,
        [e.target.name]: validators[e.target.name](e.target.value, selectedAccount)
      }
    }))
  }

  handleSubmitLydian() {
    const {accounts, setLydianModalData, onLydianSubmit} = this.props
    const {form, form: {accountId, amount}} = this.state
    const selectedAccount = find(accounts, {id: accountId})

    const errors: any = {}
    for (const field of Object.keys(form)) {
      errors[field] = validators[field](form[field], selectedAccount)
    }

    if (some(errors, (e) => e.hasError)) {
      this.setState({errors})
      return
    }

    const variables = {
      amount,
      accountId,
      paymentVendor: 'lydian',
      paymentFields: {},
      platform: JSON.stringify(getPlatform())
    }

    if (accountId && amount) {
      onLydianSubmit(variables)
    } else {
      setLydianModalData({
        status: 'failure',
        message: 'Failed to create withdrawal. Amount or account id is empty',
        show: true
      })
    }
  }

  render() {
    const {key: wl} = config
    const {accounts, viewer: {locale}, t, width, onContinue, disabledAction} = this.props
    const {form: {accountId, amount}, errors, kycStatusNotApproved, showFreeMarginWarning} = this.state

    const selectedAccount = find(accounts, {id: accountId})
    const withdrawalAccounts = map(accounts, (a) => ({
      id:  a.id,
      remoteId:  a.remoteId,
      balance: a.balance,
      currency: a.currency,
      __typename: a.__typename,
      pendingAmount: a.pendingAmount,
      accountName: a.accountName,
    }))
    const withdrawalFee = `${selectedAccount.withdrawalFee} ${selectedAccount.currency}`

    let SubmitButton
    switch (wl) {
      case 'lydian':
        SubmitButton = <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => this.handleSubmitLydian()}
          fullWidth={isWidthDown('xs', width)}
        >
          <Trans {...messages.submit} />
        </Button>
        break

      default:
        SubmitButton = <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => this.chooseMethod()}
          disabled={kycStatusNotApproved || disabledAction}
          fullWidth={isWidthDown('xs', width)}
        >
          <Trans {...messages.continue} />
        </Button>
        break
    }

    return (
      <Grid container spacing={0}>
        <Grid item  xs={12} sm={8} lg={10}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <PageSubTitle><Trans {...messages.chooseAccount} /> </PageSubTitle>
            </Grid>
            <Grid item xs={12}>
              <AccountSelect
                accounts={withdrawalAccounts}
                label={t(messages.withdrawAccounts.i18nKey, messages.withdrawAccounts.defaults)}
                onChange={this.handleChange}
                value={accountId}
                id="account-selector"
                name="accountId"
                locale={locale}
              />
              {isBitnukAccount(selectedAccount) && <Typography variant='caption'>
                <Trans {...messages.bitnukWithdrawalFee} values={{withdrawalFee}} />
              </Typography>}
            </Grid>
            <Grid item xs={12}>
              {isCentAccount(selectedAccount) && (amount || 0) > 0 && <NotificationBar status="info" noMargin>
                <Typography variant="body1">
                  <Trans {...messages.withdrawInCUD} values={{amount: Number(amount * 100).toLocaleString()}} />
                </Typography>
              </NotificationBar>}
            </Grid>
            <Grid item xs={12}>
              <PageSubTitle>
                <Trans {...messages.selectAmount} />
              </PageSubTitle>
              <AmountInput
                jumbo
                onChange={e => this.handleChange({target: {name: 'amount', value: e.target.value}})}
                value={amount}
                id="amount-input"
                name="ammount"
                currency={isCentAccount(selectedAccount) ? currencies.USD.value : get(selectedAccount, 'currency')}
                locale={locale}
                TextFieldProps={{
                  error: get(errors, 'amount.hasError', false),
                  helperText: get(errors, 'amount.hasError', false) &&
                    t(get(errors, 'amount.message').i18nKey, get(errors, 'amount.message').defaults)
                }}
                disabled={disabledAction}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {SubmitButton}
        </Grid>
        <FreeMarginWarning
          open={showFreeMarginWarning}
          onSubmit={() => this.setState({showFreeMarginWarning: false})}
          onCancel={() => onContinue(accountId, amount)}
          amount={getFormattedAmount({amount, currency: selectedAccount.currency, locale})}
          freeMargin={getFormattedAmount({amount: selectedAccount.freeMargin, currency: selectedAccount.currency, locale})}
        />
      </Grid>
    )
  }
}

export default compose(
  withWidth(),
  withNamespaces(),
)(ChooseAccount)
