import React from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import {withStyles} from '@material-ui/core/styles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {isMobile} from '../../common/utils/browser'
import {config} from '../../config'

const {key} = config
const styles = (theme) => ({
  header: {
    marginBottom: 35,
    paddingLeft: 7,
    paddingRight: 10,
  },
  mobileHeader: {
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem'
    }
  },
  action: {
    marginTop: 10,
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      margin: 'auto'
    }
  },
  mobileTitleOnly: {
    marginLeft: 10,
  },
  appBar: {
    minWidth: '100%',
    boxShadow: isMobile() ? '0 0 5px rgba(0,0,0,0.2)' : 'none',
    zIndex: 1
  },
  backButton: {
    padding: 8,
    marginTop: 4,
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : 'none'
  },
  childrenContainer: {
    padding: '0 !important',
  },
  removeAppBarShadow:{
    boxShadow: 'none'
  },
  modalTitle:{
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%'
  },
  container:{
    flexWrap: 'initial',
  }
})

const lydianStyles = (theme) => ({
  header: {
    marginBottom: 35,
    paddingLeft: 7,
    paddingRight: 10,
    paddingTop: 16,
    fontWeight: 500,
  },
  mobileHeader: {
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem'
    }
  },
  action: {
    marginTop: 10,
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      margin: 'auto'
    }
  },
  mobileTitleOnly: {
    marginLeft: 10,
  },
  appBar: {
    minWidth: '100%',
    boxShadow: isMobile() ? '0 0 5px rgba(0,0,0,0.2)' : 'none',
    zIndex: 1
  },
  backButton: {
    padding: 8,
    marginTop: 4,
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : 'none'
  },
  childrenContainer: {
    padding: '0 !important',
  },
  removeAppBarShadow:{
    boxShadow: 'none'
  },
  modalTitle:{
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%'
  },
  container:{
    flexWrap: 'initial',
  }
})

let applyStyles = {}
switch (key) {
  case 'lydian':
    applyStyles = lydianStyles
    break
  default:
    applyStyles = styles
}

const PageTitle = props => {
  const mobile = isMobile()
  const onClick = props.onBack || props.history.goBack
  const titleStyle = mobile && (!props.hideArrow ? '' : props.classes.mobileTitleOnly)
  const justifyProp = mobile &&  props.justifyProp

  return (<React.Fragment>
    {(mobile || props.title) && <Grid container className={((!props.modal && !props.withoutPadding) ?
      props.classes.appBar : '') } justifyContent={justifyProp || 'space-between'} spacing={1} alignContent="center">
      <Grid item className={(props.uinotification ? props.classes.modalTitle : '')}>
        <Grid container spacing={0} classes={{container: props.classes.container}}>
          {mobile && !props.modal && !props.hideArrow && <Grid item >
            <IconButton
              aria-label="back"
              onClick={() => onClick()}
              className={props.classes.backButton}
              color="primary"
            >
              <ArrowBack fontSize="small" />
            </IconButton>
          </Grid>}
          <Grid item className={(props.uinotification ? props.classes.modalTitle : (titleStyle || ''))}>
            <Typography component='h1' variant="h1" gutterBottom className={mobile ?
              props.classes.mobileHeader : props.classes.header}>
              {props.title || props.children}
              {props.subtitle && <Typography variant='caption'>{props.subtitle}</Typography>}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {props.rightAction && <Grid item className={props.classes.action}>
        {props.rightAction}
      </Grid>}
      {props.title && <Grid item xs={12} classes={{item: props.classes.childrenContainer}}>
        {props.children}
      </Grid>
      }
    </Grid>
    }
    {(!mobile && !props.title) &&  <Typography component='h1' variant="h1" gutterBottom className={mobile ?
      props.classes.mobileHeader : props.classes.header}>
      {props.children}
    </Typography>}
  </React.Fragment>)
}

PageTitle.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  // rightAction: PropTypes.component,
  title: PropTypes.string,
}

export default withStyles(applyStyles)(withRouter(PageTitle))
