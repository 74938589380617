//@ts-nocheck
import PropTypes from 'prop-types'
import {Trans} from 'react-i18next'
import {Link} from 'react-router-dom'
import React, {Component} from 'react'
import {flowRight as compose, get} from 'lodash'
import {Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {frontends, kycStatuses} from '@bdswiss/common-enums'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import messages from '../../../../assets/messages'
import NotificationBar from '../../../Common/NotificationBar'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import {getPlatform, safeParseJSON} from '../../../../common/utils'
import BankWireTransferProvider from './BankWireTransferProvider'

const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
})

class JMFinancialProvider extends Component {

  state = {loading: false}

  submit() {
    const {providerProperties: {provider, paymentKey}, account, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms} = this.props

    this.setState({loading: true})

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {payload}} = newDeposit
        const data = safeParseJSON(payload)
        window.goSell.config({
          gateway: {
            publicKey: data.publicKey,
            merchant_id: null,
            language: 'en',
            contactInfo: false,
            supportedCurrencies: 'all',
            supportedPaymentMethods: 'all',
            saveCardOption: false,
            customerCards: true,
            onClose: () => {},
            onLoad:() => {
              setTimeout(() => {window.goSell.openPaymentPage()}, 2000)
            },
          },
          customer: {
            first_name: data.firstName,
            middle_name: '',
            last_name: data.lastName,
            email: data.email,
          },
          order: {
            amount: data.amount,
            currency: data.currency,
          },
          transaction: {
            mode: 'charge',
            charge: {
              saveCard: false,
              threeDSecure: true,
              reference: {
                transaction: data.reference,
                order: data.reference,
              },
              metadata: {},
              receipt: {
                email: false,
                sms: true,
              },
              redirect: data.redirectUrl,
              post: data.post,
            }
          },
        })

      })
      .catch((e) => {
        this.setState({loading: false})
        onError && onError(e)
      })
  }

  render() {
    const {loading} = this.state
    const {viewer, classes, amount, account, providerProperties, onError, onSubmit} = this.props
    return (
      <React.Fragment>
        {viewer.kycStatus !== kycStatuses.approved.value
          ? <NotificationBar status="warning">
            <Typography variant="body1">
              <Trans {...messages.paymentMethodKycRequired} />
              <Link to={'/settings/profile/documents'} className={classes.link}><Trans {...messages.verifyAccount} /></Link>
            </Typography>
          </NotificationBar>
          : get(providerProperties, 'paymentKey') === 'creditCard' ? <PaymentActionButton
            loading={loading}
            onClick={() =>  this.submit()}
          />: <BankWireTransferProvider viewer={viewer} amount={amount} account={account} providerProperties={providerProperties} onError={onError} onSubmit={onSubmit} />}
      </React.Fragment>
    )
  }
}

JMFinancialProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withMobileDialog(),
  withCreateDeposit
)(JMFinancialProvider)
