//@ts-nocheck
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Input from '@material-ui/core/Input'
import {withRouter} from 'react-router-dom'
import Select from '@material-ui/core/Select'
import ListItem from '@material-ui/core/ListItem'
import {withStyles} from '@material-ui/core/styles'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import FormHelperText from '@material-ui/core/FormHelperText'
import {filter, some, map, get, isEmpty, flowRight as compose, find} from 'lodash'
import {frontends, dusupayPaymentTypes, countries} from '@bdswiss/common-enums'
import messages from '../../../../assets/messages'
import {PaymentActionButton, withCreateDeposit} from './helpers'
import {getPlatform, safeParseJSON} from '../../../../common/utils'
import CountriesSelect from '../../../Common/CountriesSelect'

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.main
  },
  justify: {
    textAlign: 'justify',
  },
  instructionsHeader: {
    fontWeight: 400,
  },
  phoneCode: {
    paddingTop: '20px !important',
    paddingLeft: '20px !important',
  },
})

const validators = {
  providerId: (value) => {
    if (!value) return messages.requiredField
  },
  accountNumber: (value, {paymentKey}) => {
    if (paymentKey === dusupayPaymentTypes.mobileMoney.value && !value) return messages.requiredField
  },
  voucher: (value, {paymentKey, country}) => {
    if (paymentKey === dusupayPaymentTypes.mobileMoney.value &&
      country === countries.gh.key &&
      !value
    ) {
      return messages.requiredField
    }
  },
  prefixFlag: (value, {country}) => {
    if (country === countries.ug.key && !value) {
      return messages.requiredField
    }
  },
}

const initialState = {
  form: {
    providerId: '',
    accountNumber: '',
    voucher: '',
    prefixFlag: 'UG',
  },
  instructions: [],
  errors: {},
  creatingDeposit: false,
}

class DusupayProvider extends Component {

  constructor(props) {
    super(props)
    const additionalFields = safeParseJSON(props.providerProperties.additionalFields)
    this.state = {
      ...initialState,
      paymentOptions: additionalFields.paymentOptions,
    }
  }

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields(key))
  }

  validateFields(key) {
    const {form} = this.state
    const {providerProperties: {paymentKey}, viewer} = this.props
    const errors = {}
    filter(Object.keys(form), (k) => k === key || !key).forEach((k) => {
      const validate = validators[k]
      errors[k] = validate && validate(form[k], {paymentKey, country: get(viewer, 'address.country')})
    })
    this.setState({errors})
    return errors
  }

  doCreateDeposit() {
    const errors = this.validateFields()
    if (some(errors)) return

    this.setState({creatingDeposit: true})

    const {amount, account: {id: accountId}, onError, useVirtualPaymentPage,
      providerProperties: {provider, paymentKey}, bonusAmount, bonusTerms,
    } = this.props
    const {form: {providerId, accountNumber, prefixFlag}} = this.state

    const prefix = get(find(countries, {value: prefixFlag}), 'callingCode')
    const fullAccountNumber = prefixFlag ? `+${prefix}${accountNumber}` : accountNumber
    const variables = {
      accountId: accountId,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        providerId,
        accountNumber: fullAccountNumber,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        const options = safeParseJSON(url)
        if (get(options, 'paymentUrl')) {
          window.location = options.paymentUrl
        } else if (get(options, 'instructions')) {
          this.setState({instructions: options.instructions})
        }
        this.setState({creatingDeposit: false})
      })
      .catch((e) => {
        onError && onError(e)
        this.setState({creatingDeposit: false})
      })
  }

  render() {
    const {classes, providerProperties: {paymentKey}, account, history, viewer, t} = this.props
    const {form: {providerId, accountNumber, voucher, prefixFlag}, errors, creatingDeposit, paymentOptions,
      instructions,
    } = this.state

    return (
      <React.Fragment>
        {isEmpty(instructions) && <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="providerId">
                <Trans {...messages.paymentProvider} />
              </InputLabel>
              <Select
                native
                id="providerId"
                name="providerId"
                type="text"
                value={providerId}
                onChange={(e) => this.handleChange('providerId', e.target.value)}
                inputProps={{name: 'providerId'}}
              >
                <option disabled key="empty" value="" />
                {map(paymentOptions, (item, i) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
              {
                errors.providerId &&
                <FormHelperText className={classes.error}><Trans {...errors.providerId} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          {paymentKey === dusupayPaymentTypes.mobileMoney.value && get(viewer, 'address.country') !== countries.ug.key &&
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="account-number">
                  <Trans {...messages.accountNumber} />
                </InputLabel>
                <Input
                  id="account-number"
                  type="text"
                  value={accountNumber}
                  onChange={(e) => this.handleChange('accountNumber', e.target.value)}
                />
                {errors.accountNumber &&
                  <FormHelperText className={classes.error}><Trans {...errors.accountNumber} /></FormHelperText>
                }
              </FormControl>
            </Grid>
          }
          {paymentKey === dusupayPaymentTypes.mobileMoney.value && get(viewer, 'address.country') === countries.ug.key &&
            <React.Fragment>
              <Grid item sm={4} xs={5} id="prefixGrid" className={classes.phoneCode}>
                <CountriesSelect
                  countryList={map(countries, (c) => c)}
                  handleChangeField={(e) => this.handleChange('prefixFlag', e)}
                  handleChange={() => {/**/}}
                  setStateOuter={() => {/**/}}
                  errors={{}}
                  value={prefixFlag}
                  name="prefix"
                  label={t(messages.phoneCode.i18nKey, messages.phoneCode.defaults)}
                  phonePrefix
                  prefixValue=''
                />
                {errors.prefixFlag &&
                  <FormHelperText className={classes.error}><Trans {...errors.prefixFlag} /></FormHelperText>
                }
              </Grid>
              <Grid item sm={8} xs={7}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="phone-number">
                    <Trans {...messages.phone} />
                  </InputLabel>
                  <Input
                    id="phone-number"
                    type="text"
                    value={accountNumber}
                    onChange={(e) => this.handleChange('accountNumber', e.target.value)}
                  />
                  {errors.accountNumber &&
                    <FormHelperText className={classes.error}><Trans {...errors.accountNumber} /></FormHelperText>
                  }
                </FormControl>
              </Grid>
            </React.Fragment>
          }
          {paymentKey === dusupayPaymentTypes.mobileMoney.value && get(viewer, 'address.country') === countries.gh.key &&
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="voucher">
                  <Trans {...messages.voucher} />
                </InputLabel>
                <Input
                  id="voucher"
                  type="text"
                  value={voucher}
                  onChange={(e) => this.handleChange('voucher', e.target.value)}
                />
                {errors.voucher &&
                  <FormHelperText className={classes.error}><Trans {...errors.voucher} /></FormHelperText>
                }
              </FormControl>
            </Grid>
          }
          <PaymentActionButton
            loading={creatingDeposit}
            onClick={() => this.doCreateDeposit()}
          />
        </Grid>}
        {!isEmpty(instructions) && <Grid item>
          <Typography align="center" variant="h6" className={classes.instructionsHeader}>
            <Trans {...messages.instructions} />
          </Typography>
          <List>
            {map(instructions, (i) => (
              <ListItem key={i.step_no} className={classes.justify}>
                <ListItemText primary={i.description} primaryTypographyProps={{variant: 'body1'}} />
              </ListItem>
            ))}
          </List>
          <PaymentActionButton
            loading={creatingDeposit}
            buttonMessage={messages.proceedButton}
            onClick={() => history.push(`/transactions/${account.id}/deposit/result/pending`)}
          />
        </Grid>}
      </React.Fragment>
    )
  }
}

DusupayProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withCreateDeposit,
  withNamespaces(),
  withRouter,
)(DusupayProvider)
