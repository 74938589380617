import React from 'react'
import {flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'
import {AlertDialog} from '../../Common/Dialog'
import messages from '../../../assets/messages'

const styles = {
  subtitleGrid: {
    padding: 8,
  },
  labelField: {
    paddingRight: 4,
  },
  valueField: {
    paddingLeft: 4,
    paddingTop:6
  },
  styledText: {
    whiteSpace: 'pre-line' as const,
    fontSize: 16,
    lineHeight: 1.5,
  }
}

const BonusesTermsAndConditionsModal = (props) => {
  const {open, classes, t, onSubmit, onCancel} = props
  return (
    <AlertDialog
      open={open}
      title={t(messages.bonusTermsTitle.i18nKey, messages.bonusTermsTitle.defaults)}
      agreeText={t(messages.acceptAndContinueWithBonus.i18nKey, messages.acceptAndContinueWithBonus.defaults)}
      onAgree={() => onSubmit()}
      disagreeText={t(messages.continueWithoutBonus.i18nKey, messages.continueWithoutBonus.defaults)}
      onDisagree={() => onCancel()}
    >
      <Grid container direction="row" alignContent="center" justifyContent="center">
        <Grid item xs={12} className={classes.subtitleGrid}>
          <p className={classes.styledText}>
            <Trans {...messages.bonusTermsBody} />
          </p>

          <ul>
            <li><Trans {...messages.bonusTermsBodyReminder1} /></li>
            <li><Trans {...messages.bonusTermsBodyReminder2} /></li>
            <li><Trans {...messages.bonusTermsBodyReminder3} /></li>
          </ul>
        </Grid>
      </Grid>
    </AlertDialog>
  )
}

BonusesTermsAndConditionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth(),
  withNamespaces(),
)(BonusesTermsAndConditionsModal)
