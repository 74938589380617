import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import messages from '../../../../assets/messages'
import {withCreateDeposit} from './helpers'
import {depositVendors} from '@bdswiss/common-enums'
import {getPlatform} from '../../../../common/utils'

class DescriptionPaymentMethod extends Component {

  renderDirecta24Description() {
    return <React.Fragment>
      <Grid container alignItems="center" alignContent="center" justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Trans {...messages.directa24Message} />
          </Typography>
          <ol>
            <li><Typography variant="body1"><Trans {...messages.directa24MessageItem1} /></Typography></li>
            <li><Typography variant="body1"><Trans {...messages.directa24MessageItem2} /></Typography></li>
          </ol>
          <Typography variant="body1">
            <Trans {...messages.directa24MessageEnd} />
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  }

  render() {
    const {provider} = this.props
    const showDirecta24Message = (provider === depositVendors.directa24.key) && (getPlatform().browser === 'Safari')
    if (showDirecta24Message) {
      return this.renderDirecta24Description()
    } else {
      return null
    }
  }
}

export default compose(
  withNamespaces(),
  withCreateDeposit
)(DescriptionPaymentMethod)
