import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'

const JMFinancialKWParamRoute = props => {
  const {search} = useLocation()
  const urlQuery = new URLSearchParams(search)
  const statusParam = urlQuery.get('status')

  const accountId = props.match.params.accountId

  // TODO@jkica: for this one (based on docs) available statues are success, fail, waiting
  const status = {
    success: 'success',
    fail: 'failed',
    waiting: 'pending',
    pending: 'pending',
    blocked: 'failed',
  }

  return <Redirect to={`/transactions/${accountId}/deposit/result/${status[statusParam]}`}/>
}

export default JMFinancialKWParamRoute
