import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import moment from 'moment'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import {Loading} from '../Common/Loading'
import {withNamespaces, Trans} from 'react-i18next'
import {accountTypes, accountCategories, clientTypes, countries, kycStatuses} from '@bdswiss/common-enums'
import _, {get, includes, filter, size, every, isEmpty, flowRight as compose, map, find, keys} from 'lodash'
import {graphql, withApollo} from 'react-apollo'
import {CLIENT_DATA_QUERY, ACCOUNTS_QUERY} from '../../graphql/queries'
import messages from '../../assets/messages'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import AccountCard from './AccountCard'
import {Link, Redirect} from 'react-router-dom'
import MifirPopup from './MifirPopup'
import {
  isForexAccount,
  isPAMMFundManagerAccount,
  isAffiliatesAccount,
  isIntroducingBrokerAccount,
  hasWalletProductAccount,
  getAllowedAccounts,
  isBitnukAccount,
  isKartwheelAccount
} from '../../common/utils/accounts'
import {isMobile, getCookie} from '../../common/utils/browser'
import ClientNotificationBar from './ClientNotificationBar'
import NotificationBar from '../Common/NotificationBar'
import AppContext from '../Common/contexts/AppContext'
import {config} from '../../config'
import {AFFILIATE_SSO_LINK_QUERY, IB_SSO_LINK_MUTATION} from '../../graphql/mutations'
import {AlertDialog} from '../Common/Dialog'
import CFDNoticePopup from './CFDNoticePopup'
import UiNotification from '../Common/UiNotification'
import IbAccountSelectModal from './Ib/IbAccountSelectModal'
import Images from '../Common/Images'
import classNames from 'classnames'
import CustomNotificationAlert from '../Common/CustomNotificationAlert'
import {getItem} from '../../common/utils'
import PartnerMigrationPopup from './PartnerMigrationPopup'
import MigrationToEuPopup from './MigrationToEuPopup'
import {accountCategoriesDetails} from '../../common/utils/uioptions'
import {InnerAppContext} from '../../common/types'
import {KartwheelAccountBase} from './Kartwheel'

require('intl/locale-data/jsonp/en')

const styles = theme => ({
  main: {
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: `${theme.sidebarWidth}`,
  },
  header: {
    marginBottom: 40,
    display: 'inline-block'
  },
  flexGrow:{
    flexGrow:1
  },
  zeroPaddingLeft:{
    paddingLeft:0
  },
  addAccount:{
    display: 'block'
  },
  addAccountMobile:{
    display: 'block',
    fontSize: 25,
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: 12,
    top: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
    },
  },
  textLink: {
    color: theme.palette.primary.main,
  },
  competitionImg: {
    height: 25,
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'right' : 'left']: -35,
    top: -2,
    [theme.breakpoints.down('sm')]: {
      [theme.direction === 'rtl' ? 'right' : 'left']: -25,
      height: 23,
    },
  },
  displayInline: {
    display: 'inline-flex'
  },
  competitionDivider: {
    lineHeight: '15px',
    minHeight: 15,
  },
  competitionsLink: {
    [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: `1px solid ${theme.palette.primary.main}`,
    [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: 30,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 30,
  },
  competitionsLinkMobile: {
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 0,
    paddingRight: 11,
    border: 'none'
  },
  enterCompetition: {
    right: 37,
    top: 20,
  },
  enterCompetitionMobile: {
    fontSize: 14,
    right: 17,
  },
  competitionSpan: {
    position: 'relative' as const
  },
  maxAccountsDiv: {
    textAlign: 'center' as const,
    padding: '15px 0'
  },
  pointer: {
    cursor: 'pointer',
  },
  dot: {
    color: theme.palette.error.main,
    fontSize: 10,
    marginLeft: 5
  },
})

class Accounts extends Component<any,any> {
  static propTypes = {
    accounts: PropTypes.array,
    error: PropTypes.object,
    loading: PropTypes.bool,
  }
  static contextType = AppContext
  context!: InnerAppContext
  timerComponentLoad!: NodeJS.Timer
  constructor(props) {
    super(props)
    const appropTestScore = props.history.location.search.split('score=')[1]
    const accountCreated = props.history.location.search.split('accountCreated=')[1]
    this.state = {
      activeTab: 0,
      step: 0,
      appropTestScore: appropTestScore,
      showAppropTestMessage: !!appropTestScore,
      showMifirPopup: true,
      showCFDNoticePopup: true,
      showAffiliatePopup: true,
      showAffiliatePopupClick: false,
      showRedirectionModal: true,
      showAccountCreatedMessage: !!accountCreated,
      showIBPopup: false,
      showIbAccountSelectionModal: false,
      ibAccountSelected: {},
      showRefreshNotification: false,
      showPartnerMigration: true,
      showBitnukPopup: false,
    }
  }

  componentDidMount() {
    const {location} = this.props
    this.timerComponentLoad = setTimeout(() => this.checkComponent(), 30000)
    if (get(location, 'state.showBitnukPopup')) this.setState({showBitnukPopup: !!location.state.showBitnukPopup})
  }

  componentWillUnmount () {
    clearTimeout(this.timerComponentLoad)
  }

  checkComponent() {
    const {loading, loadingClient, error, errorClient, accounts} = this.props
    if (loading || loadingClient || error || errorClient || !accounts) {
      this.setState({showRefreshNotification: true})
    }
  }


  decreaseStep() {
    const {step} = this.state
    const {history} = this.props;
    (step > 0) ? this.setState({step:step-1}): history.push('/')
  }
  setStep(step) {
    this.setState({step})
  }

  toAffiliatePortal(account) {
    const {client: apolloClient} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab!.document.write('<h4>Please wait</h4>')

    apolloClient.query({query: AFFILIATE_SSO_LINK_QUERY, variables: {accountId: account.id}, fetchPolicy:'network-only'}).then((res) => {
      newTab!.location = res.data.data.link
    }).catch((e) => {
      newTab!.close()
    })
  }

  toIBPortal(account, ibId?) {
    const {createIBSingleSignOnLink} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab!.document.write('<h4>Please wait</h4>')
    createIBSingleSignOnLink({variables: {accountId: account.id}}).then((res) => {
      newTab!.location = res.data.data.link
    }).catch((e) => {
      newTab!.close()
    })
  }

  openAccount(account) {
    const verifiedAffiliate = (isAffiliatesAccount(account) && includes(['Approved'],
      get(account, 'serviceFields.affiliateStatus')))
    const approvedIb = (isIntroducingBrokerAccount(account) && get(account, 'approved', false))
    const {history, kycStatus} = this.props
    if (isAffiliatesAccount(account)) {
      !verifiedAffiliate ? this.setState({showAffiliatePopupClick: true, showAffiliatePopup: true}) : this.toAffiliatePortal(account)
    } else if (isIntroducingBrokerAccount(account)) {
      if (!approvedIb) {
        this.setState({showIBPopup: true})
      } else if (get(account, 'ibId', []).length > 1) {
        this.setState({showIbAccountSelectionModal: true, ibAccountSelected: account})
      } else {
        this.toIBPortal(account)
      }
    } else if (isBitnukAccount(account) && kycStatus !== kycStatuses.approved.value) {
      this.setState({showBitnukPopup: true})
    }
    else if (isKartwheelAccount(account)) {
      if (!get(account, 'approved', false)) {
        this.setState({showIBPopup: true})
      } else {
        const component = new KartwheelAccountBase({},{})
        component.toKartwheelIBPortal(account)
      }
    }
    else {
      history.push(`/accounts/${account.id}`)
    }
  }

  hideMifirPopup() {
    this.props.history.push('/accounts')
    this.setState({showMifirPopup: false})
  }

  hasOnlyWalletAccounts(accounts) {
    return size(accounts) > 0 &&
      every(accounts, (a) => ['fiatWallet', 'cryptoWallet'].includes(accountTypes[a.__typename].category))
  }

  hideAffiliatePopup() {
    this.props.history.push('/accounts')
    this.setState({showAffiliatePopup: false, showAffiliatePopupClick:false})
  }

  continueAffiliatePopup(continueAffiliatePopup) {
    if (continueAffiliatePopup) {
      this.props.history.push( {
        pathname: '/settings/profile/due-diligence',
        state: {force: true}
      })
    }

    this.setState({showAffiliatePopup: false, showAffiliatePopupClick:false})
  }

  hideCFDNoticePopup() {
    this.setState({showCFDNoticePopup: false})
  }

  hideRedirectionsModal() {
    if (getCookie('missing_approp_test') === 'true') {
      this.props.history.push('/settings/profile/appropriateness-test')
    } else {
      this.setState({showRedirectionModal: false})
    }
  }

  renderLinks(showCompetitions) {
    const {classes} = this.props
    const {disabledAccountCreation} = config

    return <React.Fragment>
      {showCompetitions && <Link to={'/trading-competitions'} className={classNames(classes.displayInline, isMobile() ? classes.competitionsLinkMobile : classes.competitionsLink)}>
        <Typography variant={'body2'} color="primary" className={classes.competitionDivider}>
          <span className={classNames(classes.enterCompetition, (isMobile()) ? [classes.addAccountMobile,classes.enterCompetitionMobile] : '')}>
            <span className={classes.competitionSpan}><img src={Images['competitions.png']} alt={'competitions'} className={classes.competitionImg}/></span>
            {!isMobile() ? <Trans {...messages.enterCompetition} /> : <Trans {...messages.competition} /> }
          </span>
        </Typography>
      </Link>}
      {!isMobile() && !disabledAccountCreation && <Link to={'/accounts/add-account'} className={classes.displayInline}>
        <Typography variant={'body2'} color="primary">
          <span className={classes.addAccount}><Trans {...messages.addNewAccount} /></span>
        </Typography>
      </Link>}
      <br/>
    </React.Fragment>
  }

  render() {
    const {classes, loading, accounts, categories, nationality, mifirId, mifirType, history,
      country, t, depositedAmount, kycStatus, spanishCfdNotice, loadingClient, competitions, allowedAccountTypes, viewer} = this.props
    const {activeTab, showMifirPopup, showCFDNoticePopup, showBitnukPopup,
      showRedirectionModal, showAccountCreatedMessage, showIBPopup,
      showIbAccountSelectionModal, ibAccountSelected, showRefreshNotification, showPartnerMigration} = this.state
    const {blockedDeposit, clientType, companyObject, locale} = this.context
    const {ibPartnersEmail, featuresConfig: {competitions: competitionsAllowed}, name, common: {nationalitiesDisabledMifir},
      euRegulation: {showTermsPopup}} = config
    if ((loading || loadingClient || !accounts) && !showRefreshNotification) return <Loading />
    const activeCategories = activeTab === 0 ? [] : categories[activeTab-1]?.accountTypeCategories
    const filteredAccounts = isEmpty(accounts) ? [] : accounts
      .map(account => ({...account, accountType: accountTypes[account.__typename]}))
      .filter(account => activeTab === 0 || activeCategories.includes(account.accountType.category))

    const emptyMifir = (mifirId === null || mifirId === '') || (mifirType === null || mifirType === '')
    const checkAff = (clientType === clientTypes.affiliate.value) ||
      clientType === clientTypes.affiliateCorporate.value

    const mifirCondition = depositedAmount > 0 && config.featuresConfig.mifirPopup && emptyMifir
      && !checkAff && !includes(nationalitiesDisabledMifir, nationality) && !this.hasOnlyWalletAccounts(accounts)

    const liveAccounts = filter(accounts, (a)=> isForexAccount(a) && !a.isDemo)
    const hasNonReadonlyLiveAccounts = filter(liveAccounts, (account) => !account.isReadOnly)
    const hasSignedCfdNotice = _.find(spanishCfdNotice, {noticeId: 'generalCFDDisclaimer'})
    const cfdNoticeCondition = config.featuresConfig.spanishCfdNotice && country === countries.es.key && !hasSignedCfdNotice
      && !isEmpty(hasNonReadonlyLiveAccounts) && depositedAmount > 0

    let redirected
    let wlRedirection
    if (this.props.location.query) {
      redirected = this.props.location.query.search.indexOf('refEntity') > 0
      wlRedirection = this.props.location.query.search.indexOf('wlRedirection') > 0
      if (redirected && config.featuresConfig.migrateCompany.enable && !getCookie('__mig-rejected')
        && !!getCookie('RegulationAccepted') && !hasWalletProductAccount(accounts) && !get(viewer, 'euMigration')) {
        return <Redirect to='/migrate' />
      }
    }
    const showCompetitions = competitionsAllowed && get(competitions, 'length') > 0 && !includes(get(competitionsAllowed, 'blockedCountries'), country.toUpperCase())
    const accountLimitReached = getAllowedAccounts(accounts, companyObject, t, allowedAccountTypes || [], viewer)
    const showEuMigration = !!showTermsPopup && get(viewer, 'fromCompany') && !get(viewer, 'acceptEuMigrationTerms')
    const multipleReglation = get(viewer ,'multipleRegulation') && showPartnerMigration && !getItem('migrationPopupClosed')
    const hasBitnuk = find(filteredAccounts, (filteredAccount) => isBitnukAccount(filteredAccount) && !filteredAccount.balance)
    const today = moment().format()

    return (
      <React.Fragment>
        {redirected && showRedirectionModal && !showEuMigration && <AlertDialog
          open={showRedirectionModal}
          title={t(messages.entityRedirectionHeader.i18nKey, {trademark: get(companyObject, 'trademark')})}
          children={<Typography variant="body1"> <Trans {...messages.entityRedirectionMessage} values={{currentEntity: companyObject.trademark}} /> </Typography>}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          onClose={() => this.hideRedirectionsModal()}
          onAgree={() => this.hideRedirectionsModal()}
        />}
        {showEuMigration && <MigrationToEuPopup />}
        {!showEuMigration && mifirCondition && <MifirPopup
          open={showMifirPopup}
          onClose={() => this.hideMifirPopup()}
          nationality={nationality}
        />}
        {!showEuMigration && cfdNoticeCondition && <CFDNoticePopup
          noticeId={'generalCFDDisclaimer'}
          open={showCFDNoticePopup}
          onClose={() => this.hideCFDNoticePopup()}
        />}
        {showRefreshNotification && <UiNotification
          open={true}
          status={'failure'}
          title={'refresh'}
          subTitle={<Trans {...messages.somethingWrongRefresh} />}
          type="page-not-found"
          buttonMessage={t(messages.refresh.i18nKey, messages.refresh.defaults)}
          onClose={() => window.location.reload()}
        >
          <Typography variant="caption">
            <Trans {...messages.somethingWrongRefreshText} />
          </Typography>
        </UiNotification>}
        {wlRedirection && <AlertDialog
          open={showRedirectionModal}
          title={t(messages.entityRedirectionHeader.i18nKey, messages.entityRedirectionHeader.defaults)}
          children={<Typography variant="body1"> <Trans {...messages.entityRedirectionMessage} values={{currentEntity: name}} /> </Typography>}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          onClose={()=> this.hideRedirectionsModal()}
          onAgree={()=> this.hideRedirectionsModal()}
        />}
        <AlertDialog
          open={showIBPopup}
          onClose={() => this.setState({showIBPopup: false})}
          title={t(messages.welcome.i18nKey, messages.welcome.defaults)}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          onAgree={() => this.setState({showIBPopup: false})}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages.ibAccountNotApproved} /></Typography>
              <Typography variant="body1"><Trans {...messages.ibAccountNotApprovedText2} values={{email: ibPartnersEmail}}/></Typography>
            </Grid>
          </Grid>
        </AlertDialog>
        <IbAccountSelectModal
          open={showIbAccountSelectionModal}
          onClose={() => this.setState({showIbAccountSelectionModal: false})}
          account={ibAccountSelected}
          onSelectAccount={(ibId) => this.toIBPortal(ibAccountSelected, ibId)}
        />
        {multipleReglation && <PartnerMigrationPopup client={viewer}/>}
        <AlertDialog
          open={showBitnukPopup}
          onClose={() => this.setState({showBitnukPopup: false})}
          title={t(messages.unlockWallet.i18nKey, {wallet: get(hasBitnuk, 'accountType.localization') && hasBitnuk.accountType.localization.t(locale)})}
          agreeText={t(messages.completeVerification.i18nKey, messages.completeVerification.defaults)}
          disagreeText={t(messages.close.i18nKey, messages.close.defaults)}
          onAgree={() => history.push('/accounts/verification')}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages.bitwalletBenefits} values={{company: companyObject.brandLabel}}/></Typography>
            </Grid>
            <ul>
              {map(get(accountCategoriesDetails, 'cryptoWallet.bitnuk.benefits'), (benefit) => <li key={benefit}>
                <Grid item xs={12}>
                  <Typography variant="body1"><Trans {...messages[benefit]}
                    values={{company: companyObject.brandLabel, wallet: get(hasBitnuk, 'accountType.localization') && hasBitnuk.accountType.localization.t(locale)}}/></Typography>
                </Grid>
              </li>)}
            </ul>
          </Grid>
        </AlertDialog>
        <PageTitle
          hideArrow
          title={t(messages[isMobile() ? 'accounts' : 'allAccounts'].i18nKey, messages[isMobile() ? 'accounts' : 'allAccounts'].defaults)}
          rightAction={this.renderLinks(showCompetitions)}
        >
          <Tabs
            value={isEmpty(accounts) ? 0 : this.state.activeTab}
            onChange={(_, activeTab) => this.setState({activeTab})}
            classes={{root: classes.tabsRoot}}
            variant={!isMobile() ? 'standard' : 'fullWidth'}
          >
            <Tab label={config.visual?.accounts?.noTabsLabel ?? 'All'}/>
            {!config.visual?.accounts?.noTabs && _.map(categories, category => {
              const accountTypeDetails = accountCategoriesDetails[category.key]
              const dot = find(keys(accountTypeDetails), (accountType) => {
                const accountTypeEndDate = moment(accountTypeDetails[accountType].startDate).add(accountTypeDetails[accountType].activeDays, 'd')
                return moment(today).isSameOrBefore(moment(accountTypeEndDate).format(), 'day')
              })
              return <Tab key={category.key} label={(dot && hasBitnuk) ? <span>{category.label}<FiberManualRecordIcon className={classes.dot}/></span> : category.label} />
            }
            )}
          </Tabs>
        </PageTitle>
        <PageBody>
          <Grid container spacing={0}>
            {showAccountCreatedMessage &&
              <Grid item xs={12}>
                <NotificationBar status='success'>
                  <Trans {...messages.createAccountSuccess}
                    components={[
                      <Link to={'/'} className={classes.textLink}>Dismiss</Link>
                    ]} /></NotificationBar>
              </Grid>
            }
            {!blockedDeposit && <ClientNotificationBar accounts={accounts} hasBitnuk={hasBitnuk}/>}
            <Grid item xs={12}>
              <CustomNotificationAlert global/>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={!isMobile() ? 3 : 1}>
                {filteredAccounts.map(account =>
                  <Grid item xs={12} sm={6} key={account.id}>
                    <AccountCard
                      account={account}
                      kycStatus={kycStatus}
                      onClick={account => this.openAccount(account)} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {accountLimitReached.length < 1 && isMobile() && <Grid item xs={12} className={classes.maxAccountsDiv}>
              <Typography variant="caption"><Trans {...messages.maximumAccounts} /></Typography>
              <Link to={'/support'} className={classes.textLink}><Trans {...messages.contactUs} /></Link>
            </Grid>}
          </Grid>
        </PageBody>
      </React.Fragment>
    )
  }
}


export default compose(
  withApollo,
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}: any) => {
      const accounts = filter(get(data, 'viewer.accounts'), (account) => !isPAMMFundManagerAccount(account))
      const availableAccountTypeCategories = _.chain(accounts).map(account =>
        accountTypes[account.__typename].category
      ).uniq().value()
      const categories = _.chain(accountCategories).values().filter(category =>
        _.intersection(category.accountTypeCategories, availableAccountTypeCategories).length > 0
      ).value()
      return {
        error,
        loading,
        accounts,
        categories,
      }
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}: any) => {
      const mifirId = get(data, 'viewer.mifirId')
      const mifirType = get(data, 'viewer.mifirType')
      const nationality = get(data, 'viewer.nationality')
      const country = get(data, 'viewer.address.country')
      const depositedAmount = get(data, 'viewer.depositedAmount')
      const registration = get(data, 'viewer.registration')
      const kycStatus = get(data, 'viewer.kycStatus')
      const spanishCfdNotice = get(data, 'viewer.signableNoticeAckSignatures', '')
      const appropTest = get(data, 'viewer.appropTests', '')
      const globalQuestionnaire = get(data, 'viewer.globalQuestionnaire', '')
      const competitions = get(data, 'viewer.competitions' ,'')
      const allowedAccountTypes = get(data, 'viewer.allowedAccountTypes' ,'')
      return {
        errorClient: error,
        loadingClient: loading,
        mifirId,
        mifirType,
        nationality,
        country,
        depositedAmount,
        registration,
        kycStatus,
        spanishCfdNotice,
        appropTest,
        globalQuestionnaire,
        competitions,
        allowedAccountTypes,
        viewer: get(data, 'viewer'),
      }
    }
  }),
  graphql(IB_SSO_LINK_MUTATION, {
    name: 'createIBSingleSignOnLink',
  }),
)(Accounts)
