import React, {Component} from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import {Link} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import LoadingButton from '../Common/LoadingButton'
import HeaderDialog from '../Common/Dialog/HeaderDialog'
import messages from '../../assets/messages'
import PageTitle from '../Common/PageTitle'
import AppContext from '../Common/contexts/AppContext'
import {get, flowRight as compose, isEmpty} from 'lodash'
import {graphql} from 'react-apollo'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import Loading from '../Common/Loading'
import {loginVerificationRequest, logoutRequest} from '../../common/utils/requests'
import {FormHelperText} from '@material-ui/core'
import classNames from 'classnames'
import {removeSessInfoFromLocalStorage} from '../../common/utils'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'auto',
    position: 'relative' as const,
  },
  item: {
    padding: theme.spacing(1),
    maxWidth: 800,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'unset',
      margin: 0,
    },
  },
  grid: {
    margin: '20px 0'
  },
  error: {
    color: theme.palette.error.main,
  },
  textCenter: {
    textAlign: 'center' as const
  },
  textJustify: {
    textAlign: 'justify' as const
  },
  div: {
    padding: '30px 0 0'
  },
  btn: {
    marginTop: 40
  }
})

class LoginVerification extends Component<any,any> {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      token: '',
      loading: false,
      status: '',
      errorMessage: ''
    }
  }

  logout() {
    const {apolloClient} = this.state
    logoutRequest()
      .then(() => {
        removeSessInfoFromLocalStorage()
        apolloClient && apolloClient.clearStore()
        window.location.href = '/login'
      })
  }

  handleSubmit() {
    const {token} = this.state
    const {t} = this.props
    this.setState({loading: true})
    loginVerificationRequest(token)
      .then((res) => {
        this.setState({loading: false, status: 'success'}, () => { window.location.href = '/accounts'})
      })
      .catch((err) => {
        if (err === 'expiredToken') {
          this.setState({status: 'failure', errorMessage: t(messages.expiredToken.i18nKey, messages.expiredToken.defaults)})
          setTimeout(() => this.logout(), 5000)
        } else {
          this.setState({loading: false, status: 'failure', errorMessage: t(messages[(messages[err]) ? err : 'somethingWrongError' ].i18nKey, messages[(messages[err]) ? err : 'somethingWrongError' ].defaults)})
        }
      })
  }

  render() {
    const {t, classes, viewer, loadingViewer, history} = this.props
    const {token, loading, status, errorMessage} = this.state
    if (loadingViewer) return (<Loading />)
    if (!get(viewer, 'pendingLoginVerification')) history.push('/accounts')
    return  <div className={classes.mainBody}>
      <HeaderDialog />
      <Grid container>
        <Hidden xsDown>
          <Grid item sm={3} />
        </Hidden>
        <Grid item sm={9} xs={12} className={classes.item}>
          <Grid container spacing={1} className={classes.textCenter}>
            <Grid item xs={12} sm={12} className={classes.item}>
              <PageTitle modal>
                <Trans {...messages.loginVerification} />
              </PageTitle>
              <Typography variant='body1' className={classes.textJustify}>
                <Trans {...messages.loginVerificationText}/>
              </Typography>
              <Typography variant='body1' className={classes.textJustify}>
                <Trans {...messages.loginVerificationText2}/>
              </Typography>
              <Typography variant='body1' className={classes.div}>
                <Trans {...messages.enterCodeBelow} />
              </Typography>
              <Grid item sm={12} className={classes.grid}>
                <TextField
                  autoFocus
                  id="token"
                  value={token}
                  onChange={(e) => this.setState({token: e.target.value, errorMessage: '', status: ''})}
                  label={t(messages.code.i18nKey, messages.code.defaults)}
                  error={!!errorMessage}
                />
              </Grid>
              <Grid item sm={12} className={classes.btn}>
                <LoadingButton
                  disabled={isEmpty(token) || loading}
                  hideProgressBar={isEmpty(token) || !!status}
                  status={status}
                  onClick={() => this.handleSubmit()}
                  noMargin
                >
                  <Trans {...messages.confirm} />
                </LoadingButton>
                {errorMessage && <FormHelperText className={classNames(classes.error, classes.textCenter)}>{errorMessage}</FormHelperText>}
                <Link to="/login" onClick={()=> this.logout()}>
                  <Typography variant='body2' color='primary' className={classes.backToLoginText}>
                    <Trans {...messages.backToLogin} />
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}:any) => ({
      error,
      loadingViewer: loading,
      viewer: get(data, 'viewer')
    })
  }),
)(LoginVerification)

