//@ts-nocheck
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import {withRouter} from 'react-router-dom'
import {frontends} from '@bdswiss/common-enums'
import {withStyles} from '@material-ui/core/styles'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import {get, isEmpty, flowRight as compose} from 'lodash'
import messages from '../../../../assets/messages'
import {PaymentActionButton, withCreateDeposit} from './helpers'
import {getPlatform, safeParseJSON} from '../../../../common/utils'

const style = (theme) => ({
  textCenter: {
    textAlign: 'center',
  },
  highlightedText: {
    color: theme.palette.primary.main,
    cursor:'pointer'
  },
})

class KlarpayProvider extends Component {

  state = {creatingDeposit: false, qrcode: ''}

  doCreateDeposit() {
    this.setState({creatingDeposit: true})

    const {amount, account: {id: accountId}, onError, useVirtualPaymentPage,
      providerProperties: {provider, paymentKey}, bonusAmount, bonusTerms,
    } = this.props

    const variables = {
      accountId: accountId,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        const options = safeParseJSON(url)
        if (get(options, 'qrcode')) {
          this.setState({qrcode: options.qrcode, paymentUrl: options.payment_url})
        } else if (get(options, 'payment_url')) {
          window.location = options.payment_url
        } else {
          window.location = url
        }
        this.setState({creatingDeposit: false})
      })
      .catch((e) => {
        onError && onError(e)
        this.setState({creatingDeposit: false})
      })
  }

  render() {
    const {classes} = this.props
    const {creatingDeposit, qrcode, paymentUrl} = this.state

    return (
      <React.Fragment>
        {isEmpty(qrcode) && <Grid container direction="row" spacing={3}>
          <PaymentActionButton
            loading={creatingDeposit}
            onClick={() => this.doCreateDeposit()}
          />
        </Grid>}
        {!isEmpty(qrcode) && <Grid item className={classes.textCenter}>
          <Typography align="center" variant="h4">
            <Trans {...messages.scanQRCode} />
          </Typography>
          <img src={qrcode} alt="Scan" />
          <Typography align="center" variant="subtitle2">
            <Trans
              {...messages.proceedWithBrowser}
              components={[
                <span onClick={() => window.location = paymentUrl} className={classes.highlightedText}>amount</span>
              ]}
            />
          </Typography>
        </Grid>}
      </React.Fragment>
    )
  }
}

KlarpayProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withCreateDeposit,
  withNamespaces(),
  withRouter,
)(KlarpayProvider)
