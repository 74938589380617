import React from 'react'
import {get, flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {withNamespaces} from 'react-i18next'
import {graphql} from 'react-apollo'
import {Loading} from '../../Common/Loading'
import {accountSubtypes} from '@bdswiss/common-enums'
import AccountOverview from '../Common/AccountOverview'
import ForexAccountOverview from '../Forex/ForexAccountOverview'
import {ACCOUNTS_QUERY} from '../../../graphql/queries'

class Overview extends React.Component<any,any> {
  static propTypes = {
    account: PropTypes.object,
  }
  constructor(props) {
    super(props)
    this.state = {positionsType: 'open'}
  }

  render() {
    const {account, loading} = this.props

    if (loading) return (<Loading />)

    if (account.accountSubtype === accountSubtypes.sc_sub.key) {
      return <ForexAccountOverview {...this.props} />
    }
    return <AccountOverview {...this.props} />
  }
}



export default compose(
  withNamespaces(),
  withRouter,
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps: {match}}:any) => {
      const {accountId} = match.params
      const account = get(data, 'viewer.accounts', []).find((account: any) => account.id === Number(accountId))
      return {
        error,
        loading,
        account,
      }
    }
  }),
)(Overview)
