import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'

const OpenUpParamRoute = props => {
  const {search} = useLocation()
  const urlQuery = new URLSearchParams(search)
  const statusParam = urlQuery.get('status')

  const accountId = props.match.params.accountId

  const status = {
    success: 'success',
    fail: 'failed',
    pending: 'pending',
    blocked: 'failed',
  }

  return <Redirect to={`/transactions/${accountId}/deposit/result/${status[statusParam]}`} />
}

export default OpenUpParamRoute
