import React from 'react'
import {get, first, filter, find, includes, isEmpty, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import {withNamespaces} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import messages from '../../assets/messages'
import PageTitle from '../Common/PageTitle'
import {ACCOUNTS_QUERY, REFERRAL_DETAILS_QUERY} from '../../graphql/queries'
import AppContext from '../Common/contexts/AppContext'
import withStyles from '@material-ui/core/styles/withStyles'
import UiNotification from '../Common/UiNotification'
import {isDemoAccount, isForexAccount, hasWalletProductAccount} from '../../common/utils/accounts'
import ReferralsTable from './ReferralsTable'
import FinancialInfo from './FinancialInfo'
import ReferralDetails from './ReferralDetails'
import Instructions from './Instructions'
import {config} from '../../config'
import {getPlatform} from '../../common/utils'
import {Loading} from '../Common/Loading'
import {kycStatuses, referralStatuses, tradingStatuses} from '@bdswiss/common-enums'
import {withRouter} from 'react-router-dom'
import ClaimRewardPopupMessage from '../ReferAndEarn/claimRewards'
import PageBody from '../Common/PageBody'
import {InnerAppContext} from '../../common/types'

class ReferAndEarn extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      showMessage: true,
      showClaim: false,
      showReferralLimit: true,
      account: '',
      loading: false,
      status: '',
      submitMessageError: '',
      referrals: [],
      rewards: 0,
      rewardDetails: {},
    }
  }

  componentDidMount() {
    const {accounts} = this.props
    this.setState({account: get(first(accounts), 'id')})

  }

  handleAccountChange(accountId) {
    this.setState({account: accountId})
  }

  renderLimitReachedNotification() {
    const {t, location} = this.props
    const {showReferralLimit} = this.state
    const {ibPartnersLink} = config

    return <UiNotification
      open={showReferralLimit}
      onClose={() => this.setState({showReferralLimit: false})}
      title={'referralsLimit'}
      type={'limit'}
      status={'failure'}
      children={<React.Fragment><span>{t(messages.referralLimitReached.i18nKey, messages.referralLimitReached.defaults)}</span>
        <span>{t(messages.wishRefer.i18nKey, messages.wishRefer.defaults)}</span></React.Fragment>}
      buttonMessage={t(messages.becomeAPartner.i18nKey, messages.becomeAPartner.defaults)}
      buttonAction={() => window.open(ibPartnersLink, '_blank')}
      linkAction={get(location, 'pathname')}
      linkActionMessage={t(messages.viewMyPerformance.i18nKey, messages.viewMyPerformance.defaults)}
    />
  }

  claimReward() {
    const {account} = this.state
    const {preselectedAccount} = this.props
    this.setState({status: '', loading: true})
    const variables = {
      accountId: account || preselectedAccount,
    }

    this.props.claimReward({variables}).then((succ) => {
      this.setState({loading: false, status: 'success', submitMessageError: '', showClaim: false, rewardDetails: get(succ, 'data.claimReward')})
    })
      .catch((err) => {
        this.setState({loading: false, status: 'failure', submitMessageError: get(err, 'graphQLErrors[0].message') || err.message})
      })
  }

  render() {
    const {history, t, accounts, referralDetails, referralLoading, accountsLoading, preselectedAccount, linkedPartnerClientId, verified, referAndEarn} = this.props
    const {showMessage, showClaim, rewardDetails} = this.state
    const {locale} = this.context

    const pendingStatuses = [referralStatuses.activated.value, referralStatuses.deposited.value, referralStatuses.verified.value, referralStatuses.pendingActivation.value]
    if (accountsLoading || referralLoading) return  <Loading />
    const referrals = get(rewardDetails, 'referrals') || get(referralDetails, 'referrals')
    const pendingReferral = find(referrals, (r) => includes(pendingStatuses, r.status))
    if (!referAndEarn || (!pendingReferral && hasWalletProductAccount(accounts))) history.push('/')
    const activated = filter(referrals, (r) => r.status === referralStatuses.activated.value)
    const claimed = filter(referrals, (r) => r.status === referralStatuses.claimed.value)
    const awards = referralDetails && JSON.parse(referralDetails.awards)
    const conditions = referralDetails && JSON.parse(referralDetails.conditions)
    const rewardsObj = (!isEmpty(rewardDetails) && rewardDetails) || (referralDetails && referralDetails.rewardsObj)
    const isIbOrAffiliate = hasWalletProductAccount(accounts) || linkedPartnerClientId

    const limitReached = referrals && (activated.length +  claimed.length) >= conditions.maxReferrals
    const pendingRewards = get(rewardsObj, 'pendingRewards', 0)

    return <React.Fragment>
      <PageTitle title={t(messages.referAndEarn.i18nKey, messages.referAndEarn.defaults)} onBack={() => history.push('/accounts')} />
      <PageBody removePadding>
        <Grid container spacing={0}>
          {!(!showMessage || limitReached) && <Grid item xs={12}>
            <Instructions onClick={() => this.setState({showMessage: false})} awards={awards}/>
          </Grid>}
          <Grid item xs={12}>
            <ReferralDetails referralDetails={referralDetails} awards={awards} limitReached={limitReached} isIbOrAffiliate={isIbOrAffiliate} isVerified={verified}/>
          </Grid>
          <Grid item xs={12}>
            <FinancialInfo referrals={referrals} referralDetails={referralDetails} rewardsObj={rewardsObj}
              pendingRewards={pendingRewards}/>
          </Grid>
          <Grid item xs={12}>
            <ReferralsTable referralDetails={referralDetails} referrals={referrals} onClaim={() => this.setState({showClaim: true})}
              pendingRewards={pendingRewards} locale={locale}/>
          </Grid>
          <ClaimRewardPopupMessage
            open={showClaim}
            rewardsObj={rewardsObj}
            accounts={accounts}
            preselectedAccount={preselectedAccount}
            rewardType='referrer'
            onClose={() => this.setState({showClaim: false})}
          />
          {limitReached && !isIbOrAffiliate && this.renderLimitReachedNotification()}
        </Grid>
      </PageBody>
    </React.Fragment>
  }
}

export default compose(
  withNamespaces(),
  withStyles({},{withTheme: true}),
  withRouter,
  graphql(ACCOUNTS_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: (props) => {
      const accounts = filter(get(props.data, 'viewer.accounts'), (account:any) => !isDemoAccount(account) && isForexAccount(account))
      return {
        accountsError: props.data?.error,
        accountsLoading: props.data?.loading,
        accounts,
        preselectedAccount: get(accounts, '[0].id'),
      }
    }}),
  graphql(REFERRAL_DETAILS_QUERY, {
    options: () => ({
      variables: {platform: JSON.stringify(getPlatform())},
      fetchPolicy: 'network-only',
    }),
    props: (props) => ({
      referralError: props.data?.error,
      referralLoading: props.data?.loading,
      referralDetails: get(props.data, 'viewer.referralDetails'),
      linkedPartnerClientId:  get(props.data, 'viewer.linkedPartnerClientId'),
      verified:  (get(props.data, 'viewer.kycStatus') === kycStatuses.approved.value &&
        includes([tradingStatuses.registered.value, tradingStatuses.depositor.value,
          tradingStatuses.active.value],get(props.data, 'viewer.tradingStatus'))),
      referAndEarn: get(props.data, 'viewer.referAndEarn'),
    })}),
)(ReferAndEarn)
