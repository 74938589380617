import {map, flowRight as compose} from 'lodash'
import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import {apTestSteps} from '@bdswiss/common-enums'
import {Typography} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import messages from '../../../../assets/messages'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AppContext from '../../../Common/contexts/AppContext'
import AppropTestExplanation from '../../../Common/AppropTestExplanationModal'
import {InnerAppContext} from '../../../../common/types'

const styles = theme => ({
  appTestHeader : {
    marginTop:30
  },
  link: {
    color: theme.palette.primary.main,
  },
  supportText:{
    textAlign: 'center' as const,
    paddingTop: '1rem',
    fontWeight: 400
  }
})

export class APTestHeader extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  state = {
    openPopup: !!(this.props.forced || this.props.afterDepositPage),
  }

  handleOpen = () => {
    this.setState({openPopup: true})
  }

  handleClose = () => {
    this.setState({openPopup: false})
  }

  render() {
    const {t, progress, activeStep, afterDepositPage} = this.props
    const {locale} = this.context

    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button onClick={this.handleOpen}>
            <Typography align="right" variant="body2" color="primary"> {t(messages.whyIsThisNeeded.i18nKey, messages.whyIsThisNeeded.defaults)} </Typography>
          </Button>
        </Grid>
        <LinearProgress variant="determinate" color="primary" value={progress}/>
        <Typography variant="body1"> {activeStep + 1}/3 </Typography>
        {map(apTestSteps, (step, stepKey) =>
          activeStep === step.stepPosition && (<Typography key={stepKey} variant="h3"> {step.localization.t(locale)} </Typography>)
        )}
        <AppropTestExplanation open={this.state.openPopup} onClose={() => this.handleClose()} redirectedFromDeposit = {afterDepositPage} />
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(APTestHeader)
