import React, {Component} from 'react'
import {get, find, filter, includes, map, has, difference, omit, isEmpty, some, toUpper, sortBy, isNil,
  flowRight as compose, values, flatten, groupBy} from 'lodash'
import {withStyles} from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import {ADD_ACCOUNT_MUTATION} from '../../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {graphql} from 'react-apollo'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../../assets/messages'
import LoadingButton from '../../Common/LoadingButton'
import Images from '../../Common/Images'
import {FullScreenDialog, AlertDialog} from '../../Common/Dialog'
import PageTitle from '../../Common/PageTitle'
import PageBody from '../../Common/PageBody'
import PageSubTitle from '../../Common/PageSubTitle'
import classNames from 'classnames'
import {accountTypes, accountSubtypes, currencies, kycStatuses} from '@bdswiss/common-enums'
import {isMobile} from '../../../common/utils/browser'
import AppContext from '../../Common/contexts/AppContext'
import ChangePlan from '../Forex/Plan/ChangePlan'
import {findCompany} from '../../../common/utils'
import {getAccountTypeForStats, getAllowedAccounts, isCentAccount, isWalletAccount} from '../../../common/utils/accounts'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import {config} from '../../../config'
import {validateLength} from '../../../common/utils/validations'
import {validCountries, getMissingDocs} from '../../../common/utils/general'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import Popper from '@material-ui/core/Popper'
import {accountTypeStats} from '../../../common/utils/uioptions'
import CheckIcon from '@material-ui/icons/Check'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import UiNotification from '../../Common/UiNotification'
import CloseIcon from '@material-ui/icons/Close'
import {Password} from '../../Common/Password'
import {Fab} from '@material-ui/core'
import {InnerAppContext} from '../../../common/types'

const oneCurrencyOnly = config.featuresConfig.oneCurrencyOnly

const styles = theme => ({
  cardRoot: {
    padding: 20,
    margin: '20px 0px',
    cursor: 'pointer',
    position: 'relative' as const,
  },
  formControl:{
    display: 'block',
    margin: '20px 0px'
  },
  pointer:{
    cursor: 'pointer'
  },
  passwordTitle:{
    paddingBottom: '30px'
  },
  error:{
    color: theme.palette.error.main,
  },
  button:{
    marginTop: '40px',
    padding: '15px 30px'
  },
  typeBadge: {
    height: 40,
    width: 40,
    fontSize: 15,
    lineHeight: '40px',
    borderRadius: 20,
    textTransform: 'uppercase' as const,
    textAlign: 'center' as const,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 18,
  },
  label:{
    padding: '6px 0px'
  },
  badge: {
    padding: '9px',
    margin: '0px 0px 0px auto'
  },
  currIcon:{
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: '18px'
  },
  errorMessage:{
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  successDiv:{
    backgroundColor: theme.palette.lightgreen.color,
    padding: '15px 10px',
    marginBottom: 40
  },
  successMessage:{
    color: theme.palette.green.color,
  },
  forexMt4: {
    color: theme.palette.red.color,
    backgroundColor:  theme.palette.lightred.color,
  },
  forexMt5: {
    color: theme.palette.red.color,
    backgroundColor:  theme.palette.lightred.color,
  },
  affiliate: {
    color: theme.palette.yellow.color,
    backgroundColor:  theme.palette.lightyellow.color,
  },
  ib: {
    color: theme.palette.green.color,
    backgroundColor:  theme.palette.lightgreen.color,
  },
  badgeDiv:{
    paddingTop: 4
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400
  },
  basic: {
    color: theme.palette.primary.main,
    backgroundColor:  theme.palette.lightblue.color,
  },
  raw: {
    color: theme.palette.cyan.color,
    backgroundColor:  theme.palette.lightcyan.color,
  },
  black: {
    color: theme.palette.purple.color,
    backgroundColor:  theme.palette.lightpurple.color,
  },
  invest_plus: {
    color: theme.palette.harlequin.color,
    backgroundColor:  theme.palette.lightharlequin.color,
  },
  pammForex: {
    color: theme.palette.green.color,
    backgroundColor:  theme.palette.lightgreen.color,
  },
  premium: {
    color: theme.palette.premium.color,
    backgroundColor: theme.palette.lightpremium.color,
  },
  stepper: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonCompare:{
    fontSize: 16,
    float: 'right' as const,
    paddingRight: 0,
    '&:hover':{
      backgroundColor: 'transparent'
    },
    '&:active':{
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.down('sm')]: {
      float: 'none' as const,
      width: '100%',
      padding: 0,
    },
  },
  labelButton: {
    alignItems: 'initial',
    justifyContent: 'initial',
  },
  dialog: {
    width: '100%'
  },
  helpIcon: {
    color: theme.palette.lightgrey.color,
    marginBottom: '-6px',
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 10,
    padding: 2
  },
  popper: {
    zIndex: 9999,
    backgroundColor: theme.palette.lightgreyBackgroundSolid.color,
    padding: '10px 20px',
    boxShadow: theme.boxShadowStyle,
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: 'auto'
    },
  },
  subtitlePopper: {
    paddingTop: 10
  },
  checkIcon: {
    color: theme.palette.green.color,
    marginBottom: -7,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 10,
    width: 16
  },
  availableAccounts:{
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: 10,
    top: 10,
    color: theme.palette.primary.main
  },
  buttonPopper: {
    background: 'none',
    position: 'absolute' as const,
    top:0,
    [theme.direction === 'rtl' ? 'left' : 'right']: 0,
    boxShadow: 'none',
    color: theme.palette.black.color,
    '&:hover':{
      backgroundColor: theme.palette.background.default,
    },
    '&:active':{
      backgroundColor: theme.palette.background.default,
    }
  },
  cent: {
    color: theme.palette.red.color,
    backgroundColor:  theme.palette.lightred.color,
  },
})

export class AddAccount extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext

  constructor(props) {
    super(props)
    const viewer = get(props, 'viewer', {})
    const affCountries = get(viewer, 'affiliateCountries[0].countriesPromoted') || []
    this.state = {
      accountType:'',
      currency: config.featuresConfig.oneCurrencyOnly || '',
      password: '',
      confirmationPassword: '',
      showCurrentPassword: false,
      showConfirmationPassword: false,
      messageErrorPassword:'',
      messageErrorConfirmPassword:'',
      loading: false,
      status: '',
      step:0,
      token: '',
      selectAllAffCountries:false,
      formPartnership: {
        skypeId: get(viewer, 'skypeId') || '',
        website: get(viewer, 'website') || '',
        affiliateCountries: map(affCountries, (c) => toUpper(c)),
        isCorporate: '',
        acceptTerms: '',
      },
      errorsPartnership: {},
      websiteLength: false,
      companyObject: findCompany(),
      openPopper: {},
      showPartnerMessage: false
    }
  }

  handleChangePassword(prop,event) {
    this.setState({[prop]: event.target.value, messageErrorPassword: '', messageErrorConfirmPassword: ''},this.checkPassword)
  }

  checkPassword() {
    const {t} = this.props
    const {password, confirmationPassword} = this.state
    if (confirmationPassword && password !== confirmationPassword)
      this.setState({messageErrorConfirmPassword: t(messages.passwordsNotSame.i18nKey, messages.passwordsNotSame.defaults)})
    else
      this.setState({messageErrorConfirmPassword: ''})
  }

  handleChangeForm(prop, value) {
    if (prop === 'affiliateCountries' && value.indexOf('selectAll') !== -1 && !this.state.selectAllAffCountries)
      this.setState(state => ({
        formPartnership: {...state.formPartnership,
          affiliateCountries: map(validCountries(), country => country.value)},
        errorsPartnership:{...state.errorsPartnership, 'affiliateCountries': !value,},
        selectAllAffCountries:true
      }))
    else if (prop === 'affiliateCountries' && value.indexOf('selectAll') !== -1 && this.state.selectAllAffCountries)
      this.setState(state => ({
        formPartnership: {...state.formPartnership, affiliateCountries: []},
        errorsPartnership:{...state.errorsPartnership, 'affiliateCountries': !value,},
        selectAllAffCountries:false
      }))
    else
      this.setState(state => ({
        formPartnership: {...state.formPartnership,[prop]: value},
        errorsPartnership: {...state.errorsPartnership,[prop]: !value, websiteLength: false}
      }))
  }

  checkForm(fields) {
    const {registrationFields} = config
    const exceptions = ['currency']
    const validateFields = difference(fields, registrationFields['default'])
    const {formPartnership} = this.state
    const errorsPartnership = {}
    const partnership = formPartnership
    for (const field in formPartnership) {
      if (field === 'acceptTerms') {
        errorsPartnership[field] = isEmpty(formPartnership[field])
      }
      else if (includes(validateFields, field)) {
        if (field === 'website') {
          errorsPartnership[field] = isEmpty(formPartnership[field])
          errorsPartnership['websiteLength'] = !validateLength(formPartnership[field], 150)
        }
        else {
          errorsPartnership[field] = isEmpty(formPartnership[field])
        }
      } else {
        partnership[field] = ''
      }
    }

    if (some(omit(errorsPartnership,exceptions))) {
      this.setState({errorsPartnership})
      return
    }
    this.handleSubmit(partnership)
  }

  handleClickShowPassword(prop) {
    this.setState(state => ({[prop]: !state[prop]}))
  }

  handleKeyDown(prop,event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      document.getElementById(prop)?.focus()
    }
  }

  handleSubmit = (formPartnership) => {
    const {accountType, currency, password, messageErrorConfirmPassword, confirmationPassword,
      token} = this.state
    let {accountSubtype} = this.state
    const onlyPartnerAccount = isWalletAccount(accountType)
    const {t, viewer} = this.props
    const missingPofOrPoi = values(getMissingDocs(viewer)).some(d => d === true)
    if ((password && confirmationPassword && !messageErrorConfirmPassword) || (get(accountTypes[accountType], 'walletProduct', ''))) {
      this.setState({status: '', loading: true})
      if (get(accountTypes[accountType],'supportedSubtypes') && accountTypes[accountType].supportedSubtypes.length === 1) {
        accountSubtype =  get(find(accountSubtypes, (t) => t.value===accountTypes[accountType].supportedSubtypes[0]), 'key') //MT5 add subtype for max allowed check
      }
      this.props.addAccountMutation({
        variables: {password, currency, accountType, accountSubtype, args: JSON.stringify({tokenId: token, countriesPromoted: formPartnership['affiliateCountries'],
          website: formPartnership.website, skypeId: formPartnership.skypeId, isCorporate: formPartnership.isCorporate})}
      }).then(({data}) => {
        if (get(data, 'account')) {
          if (onlyPartnerAccount && missingPofOrPoi) {
            this.setState({showPartnerMessage: true})
          } else {
            this.setState({loading: false,status: 'success',submitMessageError: ''})
            window.location.href = '/accounts?accountCreated=true'
          }
        }
        else {
          this.setState({loading: false,status: 'failure',
            submitMessageError: t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults),
          })
        }
      })
        .catch((err) => {
          if (err.networkError) {
            this.setState({loading: false,status: 'failure',
              submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
            })
          } else {
            const error = get( err, 'graphQLErrors[0].message') || err.message
            this.setState({loading: false,status: 'failure',
              submitMessageError: error,
            })
          }

        })
    }
    if (!confirmationPassword)
      this.setState({messageErrorConfirmPassword: t(messages.requiredField.i18nKey, messages.requiredField.defaults)})
    if (!password)
      this.setState({messageErrorPassword: t(messages.requiredField.i18nKey, messages.requiredField.defaults)})
  }

  chooseAccount(accountType, accountSubtype) {
    this.setState({accountType:accountType})
    if (isCentAccount({__typename: accountType})) {
      this.setState({currency: currencies.CUD.value})
    }
    (!accountSubtype || accountSubtype==='demo')
      ? this.setState({accountSubtype:undefined},this.increaseStep)
      : this.setState({accountSubtype:accountSubtype},this.increaseStep)
  }

  chooseCurrency(props) {
    this.setState({currency:props},this.increaseStep)
  }

  decreaseStep() {
    const {history} = this.props
    const {step,status} = this.state
    this.setState({openPopper: {}})
    if (step === 1) {
      this.setState({accountType:'', accountSubtype: undefined})
    }
    (step > 0 && !status) ? this.setState({step:step-1}): history.push('/')
  }

  increaseStep() {
    this.setState(state => ({step:++state.step}))
  }

  renderForm() {
    const {classes} = this.props
    const {showCurrentPassword, password, showConfirmationPassword, confirmationPassword, messageErrorConfirmPassword,
      messageErrorPassword, loading, status, submitMessageError} = this.state

    return (
      <Grid container>
        <Grid item xs={12} sm={10}>
          <PageSubTitle>
            <Trans {...messages.enterPassword} />
            <Typography variant='caption'> <Trans {...messages.addAccountPassDesc} /></Typography>
          </PageSubTitle>
          <Password
            classes={classes}
            showPassword={showCurrentPassword}
            onChange={(e) => this.handleChangePassword('password',e)}
            onKeyDown={(e)=>this.handleKeyDown('confirmationPassword',e)}
            error={messageErrorPassword}
            onClickShow={() => this.handleClickShowPassword('showCurrentPassword')}
            fullWidth
            errorText={messageErrorPassword}
            value={password}
          />
          <Password
            id={'confirmationPassword'}
            classes={classes}
            showPassword={showConfirmationPassword}
            onChange={(e)=>this.handleChangePassword('confirmationPassword',e)}
            onKeyDown={(e)=>this.handleKeyDown('loadingButton',e)}
            error={messageErrorConfirmPassword}
            onClickShow={() => this.handleClickShowPassword('showConfirmationPassword')}
            fullWidth
            errorText={messageErrorConfirmPassword}
            label={'passwordConfirmation'}
            value={confirmationPassword}
          />
          <LoadingButton
            id='loadingButton'
            onClick={this.handleSubmit}
            fullWidth={isMobile()}
            disabled={loading}
            status={status}
          ><Trans {...messages.addAccount} />
          </LoadingButton>
          {status==='failure' &&
            <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
        </Grid>
      </Grid>
    )
  }

  renderDetailsForm() {
    const {classes, t} = this.props
    const {registrationFields, weblinks: {termsAndConditions, legalDocuments, privacyPolicy, affiliateTermsAndConditions, affiliateGuidelines, affiliateCodeConduct, ibTermsAndConditions, ibGuidelines,
      ibCodeConduct, ibAppendix, ibMasterTermsAndConditions}, translatedPartnerGuidelines} = config
    const {accountType, formPartnership: {skypeId, website, affiliateCountries, isCorporate}, selectAllAffCountries, submitMessageError, loading, status, companyObject, acceptTerms} = this.state
    const accountTypeObj = accountTypes[accountType]
    const fields = (has(registrationFields, accountTypeObj.category) || has(registrationFields, accountTypeObj.subCategory)) &&
      ((registrationFields[accountTypeObj.category]) ? registrationFields[accountTypeObj.category] : registrationFields[accountTypeObj.subCategory])
    const {locale} = this.context
    const isAffiliate = includes(accountTypeObj.category,'affiliate')
    return (
      <Grid container spacing={3}>
        <PageSubTitle>
          <Trans {...messages.moreDetails} />
          <Typography variant='caption'> <Trans {...messages.addAccountDetailsDesc} /></Typography>
        </PageSubTitle>
        {includes(fields,'skypeId') && <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="skypeId"
            label={t(messages.skypeId.i18nKey, messages.skypeId.defaults)}
            onChange={(e) => this.handleChangeForm('skypeId', e.target.value)}
            error={this.state.errorsPartnership.skypeId}
            value={skypeId}
          />
        </Grid>}
        {includes(fields,'website') && <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="website"
            label={t(messages.website.i18nKey, messages.website.defaults)}
            onChange={(e) => this.handleChangeForm('website', e.target.value)}
            error={this.state.errorsPartnership.website || this.state.errorsPartnership.websiteLength}
            value={website}
          />
          {this.state.errorsPartnership.websiteLength && <FormHelperText className={classes.error}>
            <Trans {...messages.maxLengthError} values={{length: 150}}/>
          </FormHelperText>}
        </Grid>}
        {includes(fields,'affiliateCountries') && <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="affiliateCountries" error={this.state.errorsPartnership.affiliateCountries} required>
              {t(messages.affiliateCountries.i18nKey, messages.affiliateCountries.defaults)}
            </InputLabel>
            <Select
              multiple
              required
              id="affiliateCountries"
              placeholder="affiliateCountries"
              name="affiliateCountries"
              onChange={(e) => this.handleChangeForm('affiliateCountries', e.target.value)}
              error={this.state.errorsPartnership.affiliateCountries}
              value={affiliateCountries}
              renderValue={(selected: any) => selected.join(', ')}
              classes={{selectMenu:classes.selectMenu}}
            >
              <MenuItem value={'selectAll'}>
                <Checkbox checked={selectAllAffCountries} />
                <ListItemText primary={t(messages.selectAll.i18nKey, messages.selectAll.defaults)} />
              </MenuItem>
              {map(validCountries(), (option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={affiliateCountries.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>}
        {includes(fields,'isCorporate') && <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel error={this.state.errorsPartnership.isCorporate}>
              <Trans {...messages.company} /> / <Trans {...messages.individual} />
            </InputLabel>
            <Select
              value={isCorporate}
              onChange={(e) => this.handleChangeForm('isCorporate', e.target.value)}
              error={this.state.errorsPartnership.isCorporate}
              required
            >
              <MenuItem key={'company'} value={'true'}><Trans {...messages.company} /></MenuItem>
              <MenuItem key={'individual'} value={'false'}><Trans {...messages.individual} /></MenuItem>
            </Select>
          </FormControl>
        </Grid>}
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel
              classes={{label:classes.checkboxLabel}}
              control={
                <Checkbox
                  checked={this.state.acceptTerms}
                  onChange={(e) => this.handleChangeForm('acceptTerms', e.target.checked? 'accept' : '')}
                  color="primary"
                  value={acceptTerms}
                  className={this.state.errorsPartnership.acceptTerms ? classes.error : ''}
                />
              }
              label={isAffiliate ?
                <Trans {...messages.signUpAffiliateCheckbox} components={[
                  <a href={termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                  <a href={legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
                  <a href={privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
                  <a href={affiliateTermsAndConditions} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                  <a href={affiliateCodeConduct} target='_blank' rel='noreferrer noopener' className={classes.link}>code</a>,
                  <a href={includes(translatedPartnerGuidelines, locale) ? affiliateGuidelines.replace('.pdf', `_${locale.toUpperCase()}.pdf`) : affiliateGuidelines}
                    target='_blank' rel='noreferrer noopener' className={classes.link}>guideliness</a>,
                ]} />
                :
                <Trans {...messages.signUpIBCheckbox} values={{company: companyObject.brandLabel}} components={[
                  <a href={termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                  <a href={legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
                  <a href={privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
                  <a href={ibCodeConduct} target='_blank' rel='noreferrer noopener' className={classes.link}>code</a>,
                  <a href={includes(translatedPartnerGuidelines, locale) ? ibGuidelines!.replace('.pdf', `_${locale.toUpperCase()}.pdf`) : ibGuidelines}
                    target='_blank' rel='noreferrer noopener' className={classes.link}>guideliness</a>,
                  <a href={ibTermsAndConditions} target='_blank' rel='noreferrer noopener' className={classes.link}>ibTerms</a>,
                  <a href={ibMasterTermsAndConditions} target='_blank' rel='noreferrer noopener' className={classes.link}>ibMasterTerms</a>,
                  <a href={ibAppendix} target='_blank' rel='noreferrer noopener' className={classes.link}>appendix</a>,
                ]} />}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            id='loadingButtonPartnership'
            onClick={() => this.checkForm(fields)}
            fullWidth={isMobile()}
            disabled={loading}
            status={status}
          ><Trans {...messages.addAccount} />
          </LoadingButton>
          {status==='failure' &&
          <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
        </Grid>
      </Grid>
    )
  }


  clickOutListener(e) {
    if (!includes(get(e, 'toElement.id'), 'helpIcon') && !includes(get(e, 'target.id'), 'helpIcon')
      && !includes(get(e, 'target.ownerSVGElement.id'), 'helpIcon'))
      this.setState({openPopper: {}})
  }

  renderAccountDetails(account) {
    const {classes, t} = this.props
    const accountTypeForStats = getAccountTypeForStats(account)
    if (!accountTypeForStats) return
    return <ClickAwayListener onClickAway={(e) => this.clickOutListener(e)}>
      <Grid container spacing={0} onClick={(e) => e.stopPropagation()}>
        <Fab className={classes.buttonPopper} onClick={() => this.setState({openPopper: {}})}>
          <CloseIcon/>
        </Fab>
        <Grid item xs={12}>
          <Typography variant='body2'>
            {account.label}
          </Typography>
        </Grid>
        {map(get(accountTypeStats[accountTypeForStats],'details'), (value, key) =>
          <Grid container key={key}>
            <Grid item xs={6}>
              <Typography variant='body1'>{t(messages[key].i18nKey)}:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>{t(messages[`${key}Text`].i18nKey, {value})}</Typography>
            </Grid>
          </Grid>
        )}
        {!isNil(get(accountTypeStats[accountTypeForStats],'commission')) && <React.Fragment>
          <Grid item xs={12}>
            <Typography variant='body2' className={classes.subtitlePopper}>
              {t(messages.commission.i18nKey)}:
            </Typography>
          </Grid>
          {map(get(accountTypeStats[accountTypeForStats],'commission'), (value, key) => {
            const country = get(this.props.viewer, 'address.country', '')
            const commissionValue = get(value, `${country}.value`) || value.value
            return <Grid container key={key}>
              <Grid item xs={6}>
                <Typography variant='body1'>{t(messages[key].i18nKey)}:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body1'>{t(messages[value.text].i18nKey, {value: commissionValue})}</Typography>
              </Grid>
            </Grid>
          })}
        </React.Fragment>}
        <Grid item xs={12}>
          <Typography variant='body2' className={classes.subtitlePopper}>
            {t(messages.benefits.i18nKey)}:
          </Typography>
        </Grid>
        {map(get(accountTypeStats[accountTypeForStats],'benefits'), (benefit) =>
          <Grid item xs={12} key={benefit}>
            <Typography variant='body1'>
              <CheckIcon className={classes.checkIcon} />
              {t(messages[benefit].i18nKey)}
            </Typography>
          </Grid>
        )}
        {get(accountTypeStats[account.accountSubtype], 'note') &&
          <Grid item xs={12}>
            <Typography variant='body1' className={classes.subtitlePopper}>
              {t(messages[get(accountTypeStats[account.accountSubtype], 'note')].i18nKey)}
            </Typography>
          </Grid>
        }
      </Grid>
    </ClickAwayListener>
  }

  showAccountdDetails(event, key, account) {
    const {currentTarget} = event
    event.stopPropagation()
    this.setState(prevState => ({
      openPopper: {
        [key]: !prevState.openPopper[key]
      },
      anchorEl: currentTarget,
    }))
  }

  getCurrenciesList() {
    const {accountType} = this.state
    let currenciesList : any = []
    if (accountType && accountTypes[accountType].disallowedCurrencies) {
      currenciesList =
          filter(currencies, (p)=> !includes(accountTypes[accountType].disallowedCurrencies, p.key))
            // TODO@jkica: should we keep this, since it was implemented for a specific temporary change? (SKYG-776)
            .filter(currency => !config.visual?.accounts?.allowedAccountCurrencies || config.visual?.accounts?.allowedAccountCurrencies.includes(currency.key))
      currenciesList = map(currenciesList, (o: any) => ({
        ...o,
        label: `${o.label} ( ${o.symbol} )`,
        icon: Images[`${o.key.toLowerCase()}.png`],
      }))
    }
    return currenciesList
  }

  getAccountsCategoryList(accountTypesList) {
    const {accounts} = this.context
    const {accountSubtype, accountType: typeKey} =  this.state
    if (!accounts) return []
    const allowedAccountsList : any[] = []
    const type : any = find(accountTypes, {key: typeKey})
    const selectedAccountType = find(accountTypesList, (l) => l.accountSubtype === accountSubtype && l.isDemo === type.isDemo)
    selectedAccountType && map(selectedAccountType.accountTypesList, (type) => {
      const accountType = get(accountTypes,type)
      if (accountType) {
        const account = {
          label:`${accountType.label}`,
          icon: accountType.shortLabel,
          accountType: accountType.key,
          accountSubtype: accountSubtype,
          category: accountType.category,
          subCategory: accountType.subCategory || '',
          baseProduct: accountType.baseProduct && accountType.baseProduct.replace(/^\w/, c => c.toLowerCase()),
          walletProduct: accountType.walletProduct,
          isDemo: accountType.isDemo,
        }
        allowedAccountsList.push(account)
      }
    })
    return sortBy(allowedAccountsList, (a) => !!a.walletProduct)
  }

  renderPartnerMessageNotification() {
    const {t, history} = this.props
    const {showPartnerMessage} = this.state
    return <UiNotification
      title={'additionalDocumentsNeeded'}
      open={showPartnerMessage}
      status={'success'}
      type={'document-upload'}
      buttonMessage={t(messages.uploadDocuments.i18nKey)}
      onClose={() => history.push('/accounts?accountCreated=true')}
      buttonAction={() => history.push('/settings/profile/documents')}
      linkActionMessage={t(messages.maybeLater.i18nKey, messages.maybeLater.defaults)}
      linkAction={'/accounts?accountCreated=true'}
    >
      <Trans {...messages.messagePartnerAddAccount} />
    </UiNotification>
  }

  render() {
    const {accounts} = this.context
    const {classes, t, history, viewer} = this.props
    const {disabledAccountCreation} = config

    if (disabledAccountCreation) {
      history.push('/')
    }

    const {step, accountType, openPopper, anchorEl, companyObject} = this.state
    // const {accountTypesComparisonUrl, key} = config
    const checkSubscription = accountTypes[accountType] && accountTypes[accountType]['subscription']
    const moreDetails = accountTypes[accountType]?.walletProduct
    const allowedAccountTypes = get(viewer, 'allowedAccountTypes') || []
    const allowedAccounts = getAllowedAccounts(accounts, companyObject, t, allowedAccountTypes, viewer)
    const showComparison = false
    // const showComparison = accountTypesComparisonUrl && (isEmpty(allowedAccountTypes) || allowedAccountTypes.length > 1)
    //   && !(isWhiteLabel() && has(whiteLabels[key], 'supportedSubtypes'))
    const missingDocs = values(getMissingDocs(viewer)).some(d => d === true)

    if (allowedAccounts.length < 1) return <UiNotification
      title={'limitReached'}
      open
      status={'failure'}
      type="limit"
      buttonMessage={t(messages.contactUs.i18nKey)}
      onClose={() => history.push('/')}
      buttonAction={() => history.push('/support')}
    >
      <Trans {...messages.maximumAllowedAccounts} />
    </UiNotification>

    let accountCategoryList : any[] = []
    let categoryList
    if (step>0) {
      accountCategoryList = this.getAccountsCategoryList(allowedAccounts)

      categoryList = map(accountCategoryList, (account: any, key) => <Card classes={{root: classes.cardRoot}} className={classes.pointer} key={key}
        onClick={()=> this.chooseAccount(account.accountType,account.accountSubtype)}>
        <Grid container>
          <Grid item xs={2} className={classes.badgeDiv}>
            <div className={classNames(classes.typeBadge,classes[account.subCategory] || classes[account.category])}>{account.icon}</div>
          </Grid>
          <Grid item xs={10}>
            <Typography variant='subtitle1' className={classes.label}>{account.label}</Typography>
            <Typography variant='caption'>
              { get(messages, `${account.baseProduct}Desc`)
                ? t(messages[`${account.baseProduct}Desc`].i18nKey, messages[`${account.baseProduct}Desc`].defaults)
                : t(messages[`${account.category}${account.subCategory}Desc`].i18nKey, messages[`${account.category}${account.subCategory}Desc`].defaults)
              }
            </Typography>
          </Grid>
        </Grid>
      </Card>
      )

    }

    const accountList = map(flatten(map(groupBy(allowedAccounts, 'accountSubtype'))), (account: any, key) => <Card classes={{root: classes.cardRoot}} className={classes.pointer} key={key}
      onClick={()=> this.chooseAccount(account.accountType,account.accountSubtype)}>
      <Grid container>
        <Grid item xs={2} className={classes.badgeDiv}>
          <div className={classNames(classes.typeBadge,classes[account.subCategory] || classes[account.accountSubtype]
      || classes[account.category])}>{account.icon}</div>
        </Grid>
        <Grid item xs={10}>
          <Typography variant='subtitle1' className={classes.label}>
            {account.showDemoLabel ? `${toUpper(t(messages.demo.i18nKey))} ` : ''}{account.label}
            {/* {!account.isDemo && get(accountTypeStats, getAccountTypeForStats(account)) && <Help id={'helpIcon'} className={classes.helpIcon} onClick={(e) => this.showAccountdDetails(e, key)}/>} */}
            {account.available && account.maxAllowed > 1 && <Typography variant='caption' className={classes.availableAccounts}>
              <Trans {...messages.availableAccounts} values={{available: account.available, maxAllowed: account.maxAllowed}}/>
            </Typography>}
          </Typography>
          <Typography variant='caption'>
            {(account.isDemo) && get(messages, `${account.category}${account.accountSubtype}DemoDesc`) ? t(messages[`${account.category}${account.accountSubtype}DemoDesc`].i18nKey)
              : get(messages, `${account.category}${account.accountSubtype}Desc`) ? t(messages[`${account.category}${account.accountSubtype}Desc`].i18nKey)
                :get(messages, `${account.baseProduct}Desc`)
                  ? t(messages[`${account.baseProduct}Desc`].i18nKey, messages[`${account.baseProduct}Desc`].defaults)
                  : t(messages[`${account.category}${account.subCategory}Desc`].i18nKey, messages[`${account.category}${account.subCategory}Desc`].defaults)
            }
          </Typography>
        </Grid>
        <Popper id={`${key}`} open={openPopper[key] || false} anchorEl={anchorEl} className={classes.popper}
          placement={isMobile() ? 'bottom' : 'right-end'}>
          <span>{this.renderAccountDetails(account)}</span>
        </Popper>
      </Grid>
    </Card>
    )

    const allowedCurrencyList = this.getCurrenciesList()
    const currencyList = allowedCurrencyList.map( (currency:any) =>
      <Card classes={{root: classes.cardRoot}} key={currency.key} onClick={()=> this.chooseCurrency(currency.key)}>
        <Grid container>
          <Avatar alt={currency['icon']} src={currency['icon']} className={classes.currIcon}/>
          <Grid item>
            <Typography variant='subtitle1' className={classes.label}>{currency['label']}</Typography>
          </Grid>
        </Grid>
      </Card>
    )

    const stepTwoOrOne = !oneCurrencyOnly ? 2 : 1
    const stepThreeOrTwo = !oneCurrencyOnly ? 3 : 2

    const multipleAccountsOrCheckSubscription = accountCategoryList.length > 1 || checkSubscription
    const notCentAccountAndNotSubscription = accountCategoryList.length <= 1 && !checkSubscription && !isCentAccount({__typename: accountType})

    const categoryStep = (step === 1 && accountCategoryList.length > 1) || (step === 1 && checkSubscription)

    const currenciesStep = ((step === 1 && notCentAccountAndNotSubscription) ||
      (step === 2 && multipleAccountsOrCheckSubscription)) && !oneCurrencyOnly

    const passwordStep = (
      (step === 3 && multipleAccountsOrCheckSubscription) ||
      (step === stepTwoOrOne && notCentAccountAndNotSubscription) ||
      (step === 1 && isCentAccount({__typename: accountType}))
    ) && !moreDetails

    const detailsStep =  ((step === stepThreeOrTwo && accountCategoryList.length > 1) ||
      (step === stepTwoOrOne && notCentAccountAndNotSubscription)) && moreDetails

    let maxStep = isCentAccount({__typename: accountType}) ? 2 : get(accountCategoryList,'length') > 1 ? 4 : 3
    maxStep = !oneCurrencyOnly ? maxStep : maxStep - 1

    return <FullScreenDialog
      fullScreen
      desktopOnly
      open={true}
      onClose={() => this.decreaseStep()}
    >
      <PageTitle
        onBack={() => this.decreaseStep()}
        title={t(messages.addNewAccount.i18nKey, messages.addNewAccount.defaults)}
      />
      <PageBody>
        <Grid item xs={12} sm={(categoryStep && checkSubscription) ? false : 8} lg={(categoryStep && checkSubscription) ? false : 6}>
          {/**@ts-ignore*/}
          {step > 0 && <span><MobileStepper
            variant="progress"
            steps={maxStep+1}
            position="static"
            activeStep={step+1}
            className={classes.stepper}
            classes={{progress: classes.stepper}}
          /><Typography variant='caption'>{step+1}/{maxStep}</Typography></span>}
          {step === 0 && <div>
            <PageSubTitle>
              <Trans {...messages.selectAccountType} />
              {showComparison && <Button color="primary" className={classes.buttonCompare} classes={{label: classes.labelButton}}
                onClick={() => history.push('/compare-accounts')}>
                <Trans {...messages.compareAccountTypes} />
              </Button>}
            </PageSubTitle>{ accountList }</div>}
          {categoryStep && !checkSubscription && <div>
            <PageSubTitle><Trans {...messages.selectAccountType} /></PageSubTitle>{ categoryList }</div>}
          {categoryStep && checkSubscription && <div>
            <PageSubTitle><Trans {...messages.selectPlan} /></PageSubTitle>
            <ChangePlan onCardClick={(plan)=> this.chooseAccount(accountType,get(plan, 'accountSubtype'))}/></div>}
          {currenciesStep && <div>
            <PageSubTitle>
              <Trans {...messages.selectCurrency} />
              {checkSubscription && <Typography variant='caption'> <Trans {...messages.tradingAccountCurrency} /></Typography>}
            </PageSubTitle>
            {currencyList}
          </div>}
          {passwordStep && this.renderForm()}
          {detailsStep && this.renderDetailsForm()}
          {(missingDocs && detailsStep) && this.renderPartnerMessageNotification()}
        </Grid>
        {accountType && accountTypes[accountType].kycRequired && get(viewer, 'kycStatus') !== kycStatuses.approved.value && <AlertDialog
          open
          onClose={() => this.setState({accountType: '', step: 0})}
          agreeText={t(messages[missingDocs ? 'verifyAccount' : 'close'].i18nKey, messages[missingDocs ? 'verifyAccount' : 'close'].defaults)}
          onAgree={() => missingDocs ? history.push('/settings/profile') : this.setState({accountType: '', step: 0})}
          disagreeText={missingDocs ? t(messages.cancel.i18nKey, messages.cancel.defaults) : ''}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages[missingDocs ? 'accountKycRequiredMsg' : 'accountKycRequiredPendingMsg']} values={{account: accountTypes[accountType].label}}/></Typography>
            </Grid>
          </Grid>
        </AlertDialog>}
      </PageBody>
    </FullScreenDialog>
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}:any) => {
      const viewer = get(data, 'viewer')
      return {
        error,
        loading,
        viewer,
        socialSignup:!!get(viewer, 'oauthId')
      }
    }
  }),
  graphql(ADD_ACCOUNT_MUTATION, {
    name: 'addAccountMutation',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
)(AddAccount)
