// @ts-nocheck
import React, {Component} from 'react'
import {get, find, first, findIndex, isEmpty, reject, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import {withNamespaces} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {vpsSubscriptionStatuses} from '@bdswiss/common-enums'
import messages from '../../assets/messages'
import {VPS_SUBSCRIPTIONS_QUERY} from '../../graphql/queries'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import {Loading} from '../Common/Loading'
import VpsRoutes from './routes'
import AppContext from '../Common/contexts/AppContext'
import {config} from '../../config'
import {hasOnlyWalletProductAccount} from '../../common/utils/accounts'
import {isMobile} from '../../common/utils'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
})


class Vps extends Component {
  static contextType = AppContext

  handleChange(vpsTabs, value) {
    const {history, match} = this.props
    const currentTab = vpsTabs[value]
    history.push(`${match.url}${currentTab.relativePath}`)
  }

  componentDidMount() {
    const {featuresConfig:{vps}} = config
    const {accounts} = this.context
    const {history} = this.props
    const onlyWalletAccount = hasOnlyWalletProductAccount(accounts)
    if (!vps || (vps && !vps.enabled) || onlyWalletAccount) {
      history.push('/accounts/')
    }
  }

  render() {
    const {t, location, match} = this.props
    const {vpsSubscriptionsLoading, vpsSubscriptions, clientLoading, loading} = this.props

    if (vpsSubscriptionsLoading || clientLoading || loading) return <Loading />

    const currentSubscription = !isEmpty(vpsSubscriptions)
    && vpsSubscriptions.find(s => s.status !== vpsSubscriptionStatuses.deleted.value)

    let vpsTabs = [
      {key: 'details', relativePath: '/manage', hidden: !currentSubscription},
      {key: 'plan', relativePath: '/plan'},
    ]
    vpsTabs = reject(vpsTabs, 'hidden')

    const currentTab =
      find(vpsTabs, (tab) => !tab.hidden && location.pathname.indexOf(`${match.url}${tab.relativePath}`) > -1) || first(vpsTabs)
    const currentTabIndex = findIndex(vpsTabs, currentTab)

    return (
      <React.Fragment>
        <PageTitle title={t(messages.vpsAccess.i18nKey, messages.vpsAccess.defaults)}>
          <Tabs value={currentTabIndex} onChange={(_, i) => this.handleChange(vpsTabs, i)}  variant={!isMobile() ? 'standard' : 'fullWidth'}>
            {vpsTabs.map(({key})=>
              <Tab key={key} label={t(messages[key].i18nKey, messages[key].defaults)} />
            )}
          </Tabs>
        </PageTitle>
        <PageBody>
          <VpsRoutes />
        </PageBody>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(VPS_SUBSCRIPTIONS_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      vpsSubscriptionsLoading: loading,
      vpsSubscriptionsError: error,
      vpsSubscriptions: get(data, 'viewer.vpsSubscriptions', []),
    }),
  }),
)(Vps)
