import React, {Component} from 'react'
import {flowRight as compose, get} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'
import Typography from '@material-ui/core/Typography'
import messages from '../../../assets/messages'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import AppContext from '../../Common/contexts/AppContext'
import Dialog from '@material-ui/core/Dialog'
import Images from '../../Common/Images'
import CloseIcon from '@material-ui/icons/Close'
import Star from '@material-ui/icons/Star'
import {accountSubtypes} from '@bdswiss/common-enums'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import {isMobile} from '../../../common/utils'
import {Fab} from '@material-ui/core'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 1000,
    margin:'0 auto',
    position: 'relative' as const,
  },
  title:{
    fontSize: '3em',
    paddingBottom: 25,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
      lineHeight:'2.5rem'
    },
  },
  whiteText: {
    color: theme.palette.secondary.light
  },
  captionText: {
    paddingBottom: 10,
    color: '#B9C3C3'
  },
  buttonClose: {
    background: 'none',
    '&:hover':{
      backgroundColor: theme.palette.common.black,
    },
    position: 'absolute' as const,
    top: 0,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      top: -5,
      right: -5,
    },
  },
  button: {
    boxShadow: 'none',
    backgroundColor: '#9c8258',
    '&:hover':{
      backgroundColor:'#b79a6c'
    },
  },
  paper: {
    backgroundColor: theme.palette.common.black,
  },
  closeButtonDiv:{
    textAlign: theme.direction === 'rtl' ? 'left' as const : 'right' as const
  },
  greyText:{
    color: '#778281',
    fontWeight: 400
  },
  boldText:{
    fontWeight: 500
  },
  goldText:{
    color: '#9c8258'
  },
  img:{
    maxWidth: 45
  },
  upperCase:{
    textTransform:'uppercase' as const
  },
  closeIcon:{
    fontSize: 35,
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
  },
  subtitle:{
    paddingBottom: 7,
    lineHeight: 1.3,
    fontWeight:600,
    color: theme.palette.secondary.light,
    textTransform:'uppercase' as const
  },
  mb15:{
    marginBottom:15
  },
  mb70:{
    marginBottom:70
  },
  cardRoot: {
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100%',
    color: theme.palette.secondary.light,
    border: '1px solid #9c8258',
    '&:hover':{
      backgroundColor: 'transparent',
    },
    padding:0,
  },
  cardHeader:{
    padding:0
  },
  pointContent:{
    lineHeight: 1.3,
    fontWeight:400,
    color: theme.palette.secondary.light,
  },
  textCenter: {
    textAlign: 'center' as const,
  },
  upgradeText: {
    maxWidth: 300
  },
  fontBigger: {
    fontSize: 18,
  },
  lightBold: {
    fontWeight: 400
  },
  mobileLeftText: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left' as const
    },
  }
})

class BlackAccount extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  render() {
    const {classes, history, location, t} = this.props
    const closePath = (location.search && location.search === '?close') ? '/' : (get(location, 'state.from') ? location.state.from : null)
    const {companyObject} = this.context
    return (
      <Dialog
        fullScreen
        open={true}
        PaperProps={{classes: {root: classes.paper}}}
      >
        <Grid container spacing={3} className={classNames(classes.root)} /*align='center'*/>
          <Fab className={classNames(classes.buttonClose,classes.goldText)} onClick={() => closePath ? history.push(closePath) : history.goBack()}>
            <CloseIcon className={classes.closeIcon}/>
          </Fab>
          <Grid item xs={12} className={classes.goldText}>
            <Star /><Star /><Star /><Star /><Star />
          </Grid>
          <Grid item xs={12} className={classes.textCenter}>
            <Typography className={classNames(classes.title, classes.goldText, classes.boldText, classes.upperCase)}>
              <Trans {...messages.joinVipClubTitle} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={12} xs={12}>
              <Typography variant="body1" className={classNames(classes.lightBold, classes.upgradeText, classes.whiteText, classes.fontBigger)}>
                <Trans {...messages.upgradeAccountAccess}/>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.mb15}>
            <Grid item sm={12} xs={12}>
              <Typography variant="body1" className={classes.whiteText}>
                <Trans {...messages.vipAccountPrivilagedText2} values={{company:companyObject.brandLabel}}/>
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} className={classes.mb70}>
            <Grid container spacing={isMobile() ? 5 : 3}>
              <Grid item md={3} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <img className={classNames(classes.img)} src={Images['Black_Account_Icons-07.png']} alt="icon1" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="caption" className={classNames(classes.subtitle)} align='left'>
                      <Trans {...messages.vipAccountAdvantage}/>
                    </Typography>
                    <Typography variant="caption" className={classes.pointContent} align='left'>
                      <Trans {...messages.vipAccountAdvantageText} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <img className={classNames(classes.img)} src={Images['Black_Account_Icons-08.png']} alt="icon1" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="caption" className={classNames(classes.subtitle)} align='left'>
                      <Trans {...messages.vipAccountAdvantage2}/>
                    </Typography>
                    <Typography variant="caption" className={classes.pointContent} align='left'>
                      <Trans {...messages.vipAccountAdvantageText2} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <img className={classNames(classes.img)} src={Images['Black_Account_Icons-09.png']} alt="icon1" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="caption" className={classNames(classes.subtitle)} align='left'>
                      <Trans {...messages.vipAccountAdvantage3}/>
                    </Typography>
                    <Typography variant="caption" className={classes.pointContent} align='left'>
                      <Trans {...messages.vipAccountAdvantageText3} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <img className={classNames(classes.img)} src={Images['Black_Account_Icons-10.png']} alt="icon1" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="caption" className={classNames(classes.subtitle)} align='left'>
                      <Trans {...messages.vipAccountAdvantage4}/>
                    </Typography>
                    <Typography variant="caption" className={classes.pointContent} align='left'>
                      <Trans {...messages.vipAccountAdvantageText4} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={isMobile() ? 5 : 3}>
              <Grid item md={3} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <img className={classNames(classes.img)} src={Images['Black_Account_Icons-11.png']} alt="icon1" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="caption" className={classNames(classes.subtitle)} align='left'>
                      <Trans {...messages.vipAccountAdvantage5}/>
                    </Typography>
                    <Typography variant="caption" className={classes.pointContent} align='left'>
                      <Trans {...messages.vipAccountAdvantageText5} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <img className={classNames(classes.img)} src={Images['Black_Account_Icons-12.png']} alt="icon1" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="caption" className={classNames(classes.subtitle)} align='left'>
                      <Trans {...messages.vipAccountAdvantage6}/>
                    </Typography>
                    <Typography variant="caption" className={classes.pointContent} align='left'>
                      <Trans {...messages.vipAccountAdvantageText6} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <img className={classNames(classes.img)} src={Images['Black_Account_Icons-13.png']} alt="icon1" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="caption" className={classNames(classes.subtitle)} align='left'>
                      <Trans {...messages.vipAccountAdvantage7}/>
                    </Typography>
                    <Typography variant="caption" className={classes.pointContent} align='left'>
                      <Trans {...messages.vipAccountAdvantageText7} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <img className={classNames(classes.img)} src={Images['Black_Account_Icons-14.png']} alt="icon1" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="caption" className={classNames(classes.subtitle)} align='left'>
                      <Trans {...messages.vipAccountAdvantage8}/>
                    </Typography>
                    <Typography variant="caption" className={classes.pointContent} align='left'>
                      <Trans {...messages.vipAccountAdvantageText8} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={12} xs={12}>
              <Typography variant="body1" className={classNames(classes.lightBold, classes.fontBigger, classes.whiteText)}>
                <Trans {...messages.takeAdvantage}/>
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} /*align='center'*/>
                <Card className={classes.cardRoot}>
                  <CardHeader
                    title={t(messages.vipAccountTitle.i18nKey)}
                    titleTypographyProps={{variant:'h3', align: 'center'}}
                    classes={{root: classes.cardHeader, title: classNames(classes.whiteText, classes.boldText)}}
                  />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={6} /*align='right'*/>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.spreadsFrom} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} /*align='center'*/>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>1.1</Typography>
                      </Grid>
                      <Grid item xs={6} /*align='right'*/>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.commission} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} /*align='center'*/>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>$0</Typography>
                      </Grid>
                      <Grid item xs={6} /*align='right'*/>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.from} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} /*align='center'*/>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>$3,000</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          size="medium"
                          onClick={() => history.push({pathname:'/accounts/add-account'})}
                          className={classNames(classes.button,classes.whiteText)}
                        >
                          <Trans {...messages.openAccountType} values={{type: accountSubtypes.black.label}}/>
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" className={classNames(classes.greyText,classes.boldText)}>
                          <Trans {...messages.yourCapitalRisk} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} /*align='center'*/>
                <Card className={classes.cardRoot}>
                  <CardHeader
                    title={t(messages.rawAccountTitle.i18nKey)}
                    titleTypographyProps={{variant:'h3', align: 'center'}}
                    classes={{root: classes.cardHeader, title: classNames(classes.whiteText, classes.boldText)}}
                  />
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} /*align='right'*/>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.spreadsFrom} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} /*align='center'*/>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>0.0</Typography>
                      </Grid>
                      <Grid item xs={6} /*align='right'*/>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.commission} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} /*align='center'*/>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>$5</Typography>
                      </Grid>
                      <Grid item xs={6} /*align='right'*/>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.from} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} /*align='center'*/>
                        <Typography variant="body1" className={classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>$5,000</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          size="medium"
                          onClick={() => history.push({pathname:'/accounts/add-account'})}
                          className={classNames(classes.button,classes.whiteText)}
                        >
                          <Trans {...messages.openAccountType} values={{type: accountSubtypes.raw.label}}/>
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" className={classNames(classes.greyText,classes.boldText)}>
                          <Trans {...messages.yourCapitalRisk} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth(),
  withNamespaces(),
)(BlackAccount)
