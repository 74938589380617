import React, {Component} from 'react'
import moment from 'moment'
import {graphql} from 'react-apollo'
import {get, map, find, filter, some, includes, every, isEmpty, flowRight as compose} from 'lodash'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import TimerIcon from '@material-ui/icons/Timer'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../Common/Loading'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  companies,
  kycStatuses,
  documentStatuses,
  fundingCategories,
  proofOfIdentityDocumentTypes,
  proofOfResidencyDocumentTypes
} from '@bdswiss/common-enums'
import PageSubTitle from '../../Common/PageSubTitle'
import AppContext from '../../Common/contexts/AppContext'
import messages from '../../../assets/messages'
import {DOCUMENT_CATEGORY_TYPES} from '../../../common/utils/general'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {Link} from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import classNames from 'classnames'
import {getVerificationRow} from '../../Common/AccountVerification/VerificationHelper'
import {config} from '../../../config'
import AccountVerificationItem  from '../../Common/AccountVerification/Item'
import {isWalletAccountClientType, hasVipOrRaw} from '../../../common/utils/accounts'
import {InnerAppContext} from '../../../common/types'
import NotificationBar from '../../Common/NotificationBar'

const styles = theme => ({
  error: {
    color: theme.palette.red.color
  },
  checkIcon: {
    color: theme.palette.green.color
  },
  timerIcon: {
    color: theme.palette.yellow.color
  },
  cell: {
    paddingRight: 0,
  },
  iconCell: {
    width: '10%',
    padding: '10px 12px'
  },
  pendingAccountText:{
    'font-weight': '400'
  },
  submitLink:{
    'color':`${theme.palette.primary.main}`,
    'cursor': 'pointer',
    'font-weight':'400'
  },
  marginTop20:{
    marginTop:20
  },
  zeroMarginTop:{
    marginTop:0
  },
  marginTop40:{
    marginTop:40
  },
  buttonLink:{
    padding:0,
    '&:hover':{
      background:'none'
    }
  },
  inline: {
    display: 'inline'
  },
  skipDiv: {
    marginTop: 20,
    padding:10,
    backgroundColor: theme.palette.lightgreen.color
  },
  infoIcon: {
    marginBottom: -6,
    marginRight: 5,
    color: theme.palette.grey.color
  },
  skipText:{
    padding: '15px 34px 5px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0',
    }
  },
  textRight: {
    textAlign: 'right' as const
  },
})

class UploadDocument extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext

  state = {
    open: false,
  }

  handleOpen = () => {
    this.setState({open: true})
  }

  handleClose = () => {
    this.setState({open: false})
  }

  getMappedCategories(mandatory) {
    const {classes, viewer: {depositedAmount, paymentMethods, pendingUploadDocuments, address}} = this.props
    const clientType = this.context.clientType || this.props.viewer.clientType
    return filter(DOCUMENT_CATEGORY_TYPES, (t) => t.mandatory === mandatory && t.show({depositedAmount, clientType, address})).map(t => {
      let categoryStatus
      if (t.category === 'pof' && !isEmpty(paymentMethods)) {
        if (every(paymentMethods, (d) => d.confirmed)) {
          categoryStatus = 'approved'
        } else if (some(paymentMethods, (d) => get(d.pendingUpload,'status') ==='pending')) {
          categoryStatus = 'pending'
        } else if (some(paymentMethods, (d) => get(d.pendingUpload,'status') ==='rejected')) {
          categoryStatus = 'rejected'
        }
      }
      else {
        categoryStatus = get(get(pendingUploadDocuments, t.category.toLowerCase()),'status')
      }
      if (!categoryStatus) categoryStatus = 'notUploaded'

      let icon
      switch (categoryStatus) {
        case 'rejected':
          icon = <CloseIcon className={classes.error} />
          break
        case 'approved':
          icon = <CheckIcon className={classes.checkIcon} />
          break
        case 'pending':
          icon = <TimerIcon className={classes.timerIcon} />
          break
        case 'notUploaded':
        default:
          icon = <InfoIcon className={classes.error} />
          break
      }

      const messageTitle = t.category === 'partner' ? messages[`${clientType}VerificationTitle`] :
        (messages[`${t.category}Title${categoryStatus}`] || messages[`${t.category}Title`])
      const obj = {
        message: messageTitle,
        icon,
        category: t.category,
        categoryStatus,
        verifiedMethodsPof: null as any //todo: fix that
      }

      const isPof = t.category === 'pof'
      if (isPof) {
        obj.verifiedMethodsPof = filter(paymentMethods, (method) => method.confirmed)
      }
      return obj
    })
  }

  transformTooltip(text) {
    return text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, c => c.toUpperCase())
  }

  renderSkipWaitingDiv() {
    const {classes, history} = this.props

    return <Grid item xs={12} sm={8} lg={6} className={classes.skipDiv}>
      <Grid container>
        <Grid item xs={12}>
          <InfoIcon className={classes.infoIcon}/>
          <Typography variant="subtitle1" className={classes.inline}> <Trans {...messages.wantSkipWaitingList} /> </Typography>
        </Grid>
        <Grid item xs={12} className={classes.skipText}>
          <Typography variant="body1"> <Trans {...messages.vipAccountsPriority} /> </Typography>
        </Grid>
        <Grid item xs={12} className={classes.textRight}>
          <Button color={'primary'} className={classes.exitBtn}
            onClick={() => history.push('/vip-account')}>
            <Trans {...messages.skipWaitingList} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  }

  getLinkByStatus(categoryStatus) {
    let uploadLink
    switch (categoryStatus) {
      case 'notUploaded':
        uploadLink='upload'
        break
      case 'pending':
        uploadLink=''
        break
      case 'approved':
        uploadLink=''
        break
      case 'rejected':
        uploadLink='resubmit'
        break
      default:
        uploadLink=''
    }
    return uploadLink
  }

  render() {
    const {t, loading, documentsLoading, accountsLoading, classes} = this.props

    if (loading || documentsLoading || accountsLoading) return <Loading />

    const {viewer,viewer: {company, ftdDate, kycStatus, missingDocuments}, register, documents} = this.props
    const rejectedDocuments = documents.filter(doc => doc.status = documentStatuses.rejected.value)
    const isPoiDocument = (doc) => Boolean(
      Object.keys(proofOfIdentityDocumentTypes).find((type) => type === doc.type)
    )
    const isPorDocument = (doc) => Boolean(
      Object.keys(proofOfResidencyDocumentTypes).find((type) => type === doc.type)
    )
    const PORRejectedDocument = rejectedDocuments.find(rejDoc => isPorDocument(rejDoc))
    const POIRejectedDocument = rejectedDocuments.find(rejDoc => isPoiDocument(rejDoc))

    const {blockedDeposit, clientType, locale, accounts} = this.context
    const {featuresConfig :{skipWaitingList}, gdcExcludedCountries} = config
    const electronicVerification = get(viewer, 'eIdVerificationApplicable')
    const kycApproved = ((kycStatus === kycStatuses.approved.key) || (kycStatus === kycStatuses.pendingAppropTest.key))
    const missingDocs = missingDocuments && JSON.parse(missingDocuments)
    const noMissingDocs = kycApproved && (isEmpty(missingDocs) || every(missingDocs, (doc) => doc === false))
    const hideUploadDocuments = ((!kycApproved && electronicVerification && !includes(gdcExcludedCountries, viewer['address']['country']))
      || noMissingDocs)
    const kycDays = get(find(companies, {value: company}), 'kycDeadline')
    const kycDeadline = moment(ftdDate).add(kycDays, 'day')
    let remainingDays
    if (!blockedDeposit && kycDeadline.isAfter(moment()) && kycStatus !== kycStatuses.approved.key) {
      remainingDays = kycDeadline.diff(moment(), 'days')
    }

    const verifications = find(getVerificationRow(viewer,'','',['profile','kycStatus']),{key: 'kycStatus'})
    const showKycStatusBox = (!kycApproved && electronicVerification) || noMissingDocs
    const categoriesMandatory = this.getMappedCategories(true)
    const categoriesOptional = this.getMappedCategories(false)
    const pendingMandatory = find(categoriesMandatory, (c) => c.categoryStatus === documentStatuses.pending.value)
    const pendingOptional = find(categoriesOptional, (c) => c.categoryStatus === documentStatuses.pending.value)

    return (
      <div>
        <Grid container spacing={0} direction="column">
          <Grid item xs={12} sm={12} lg={12}>
            <PageSubTitle><Trans {...messages.documentsList} /></PageSubTitle>
          </Grid>
          <Grid item xs={12} sm={8} lg={6}>
            <Table padding="checkbox">
              <TableBody>
                {showKycStatusBox && <React.Fragment>
                  <AccountVerificationItem {...verifications} hideAction />
                  <TableRow></TableRow>
                </React.Fragment>}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} sm={8} lg={10}>
            <Table padding="checkbox">
              <TableBody>
                {!hideUploadDocuments && map(categoriesMandatory, (c) => {
                  const uploadLink = this.getLinkByStatus(c.categoryStatus)
                  return (!kycApproved || (kycApproved && get(missingDocs, c.category))) && <TableRow key={c.category}>
                    <TableCell className={classNames(classes.cell, classes.iconCell)} component="th" scope="row">
                      <Tooltip title={t(messages[c.categoryStatus].i18nKey, messages[c.categoryStatus].default)} placement="top">
                        {c.icon}
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.cell} component="th" scope="row" colSpan={isEmpty(uploadLink) ? 2 : 0}>
                      <Typography variant="body1" className={classes.inline}><Trans {...c.message} /></Typography>
                    </TableCell>
                    {!isEmpty(uploadLink) && <TableCell className={classes.cell}>
                      <Button
                        disableFocusRipple
                        disableRipple
                        variant="text"
                        color="primary"
                        onClick={() => this.props.history.push((!register)?`/settings/profile/documents/upload/${c.category}`:`/register/step3/${c.category}`)}
                      >
                        <Trans {...messages[uploadLink]} />
                      </Button>
                    </TableCell>}
                    <TableCell>
                      {
                        !!POIRejectedDocument && POIRejectedDocument?.rejectionReason?.length !== 0 && c.category === 'poi' &&
                        <NotificationBar status="error">
                          {POIRejectedDocument?.rejectionReason?.split('.')[0].trim()}
                        </NotificationBar>
                      }
                    </TableCell>
                    <TableCell>
                      {
                        !!PORRejectedDocument && PORRejectedDocument?.rejectionReason?.length !== 0 && c.category === 'por' &&
                        <NotificationBar status="error">
                          {PORRejectedDocument?.rejectionReason?.split('.')[0].trim()}
                        </NotificationBar>
                      }
                    </TableCell>
                  </TableRow>}
                )}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} sm={8} lg={6}>
            {remainingDays && <Typography  variant="body1">
              <Trans {...messages.daysToVerifyAccount}
                values={{days: remainingDays}}
                components={[<span className={classes.pendingAccountText}>days</span>,
                  <Link to={'/'} className={classes.submitLink}>trading now</Link>,
                ]} />
            </Typography>}
          </Grid>
          {categoriesOptional.length > 0 &&  <Grid container spacing={0} direction="column" className={!hideUploadDocuments ? classes.marginTop40 : ''}>
            <Grid item xs={12} sm={12} lg={12}>
              <PageSubTitle><Trans {...messages.optionalDocumentsList} /></PageSubTitle>
            </Grid>
            <Grid item xs={12} sm={8} lg={6}>
              <Table>
                <TableBody>
                  {map(categoriesOptional, (c) => {
                    const isPof = c.category === 'pof'
                    const uploadLink = this.getLinkByStatus(c.categoryStatus)
                    const missingCategoryDocs = get(get(viewer.pendingUploadDocuments, c.category.toLowerCase()),'allowUpload')
                    const checkSpan = !isEmpty(uploadLink) || isPof || missingCategoryDocs
                    return <TableRow key={c.category}>
                      <TableCell className={classNames(classes.cell, classes.iconCell)} component="th" scope="row">
                        <Tooltip title={this.transformTooltip(c.categoryStatus)} placement="top">
                          {c.icon}
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.cell} component="th" scope="row" colSpan={!checkSpan ? 2 : 0}>
                        <Typography variant="body1" className={classes.inline}><Trans {...c.message} /></Typography>
                        {isPof && !isEmpty(c.verifiedMethodsPof) && <Typography variant="caption">
                          <Trans {...messages.verifedPaymentMethods} /> {map(c.verifiedMethodsPof, (m, index) =>
                            `${fundingCategories[m.fundingCategory].localization.t(locale)}:${m.details} ${Number(index) < (c.verifiedMethodsPof.length - 1) ? ', ' : ''}`)}
                        </Typography>}
                      </TableCell>
                      {checkSpan && <TableCell className={classes.cell}>
                        <Button
                          disableFocusRipple
                          disableRipple
                          variant="text"
                          color="primary"
                          onClick={() => this.props.history.push((!register)?`/settings/profile/documents/upload/${c.category}`: `/register/step3/${c.category}`)}
                        >
                          <Trans {...messages[!isEmpty(uploadLink) ? uploadLink : 'upload']} />
                        </Button>
                      </TableCell>}
                    </TableRow>}
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          }
          {skipWaitingList && !isWalletAccountClientType(clientType) && !hasVipOrRaw(accounts)
            && !kycApproved && (!!pendingMandatory || !!pendingOptional) && this.renderSkipWaitingDiv()}
        </Grid>
        {register &&
          <Grid container spacing={3}>
            <Grid item>
              <Button color="primary" size="large" variant="contained" onClick={() => this.props.history.push('/')}>
                <Trans {...messages.continue}/>
              </Button>
            </Grid>
          </Grid>
        }
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => ({
      error:props.data?.error,
      documentsLoading:props.data?.loading,
      documents: get(props.data, 'viewer.documents', []),
    })
  }),
  graphql(CLIENT_DATA_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: (props) => ({
      loading:props.data?.loading,
      error:props.data?.error,
      viewer: get(props.data, 'viewer'),
    })
  }),
)(UploadDocument)
