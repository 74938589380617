import React from 'react'
import {config} from '../../../config'

export default class BannerRedirectLink extends React.Component {
  render() {
    const {loginSignupLink, frontendUrl} = config
    const {history} = this.props

    switch (history.location.pathname) {
      case '/login':
      case '/register':
        window.location.href = `${frontendUrl}${loginSignupLink}`
        break
      default:
        return null
    }
  }
}
