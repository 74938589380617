import moment from 'moment'
import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {get, flowRight as compose, trim, replace} from 'lodash'
import {withStyles} from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import {CustomDialog} from '../Common/Dialog'
import LoadingButton from '../Common/LoadingButton'
import messages from '../../assets/messages'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import {SIGN_CLIENT_NOTICE_MUTATION} from '../../graphql/mutations'

const styles = theme => ({
  selectClass:  {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 17,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: '13px 13px 13px 0'
  },
  grid: {
    [theme.breakpoints.down('xs')]: {
      padding: '0px 10px 25px'
    },
  }
})
export class CFDNoticePopup extends Component {

  constructor(props) {
    super(props)
    this.state = {
      signature: '',
      signatureError: false,
      status: '',
      loading: false,
    }
  }

  onSubmit() {
    const {noticeId} = this.props
    const {signature} = this.state
    if (!signature) {
      this.setState({signatureError: true})
    } else {
      //@ts-ignore
      const signDate = new moment().format()
      const trimSignature = replace(trim(signature), /\s+/g, ' ')
      this.setState({loading:true})
      this.props.signClientNotice({variables: {noticeId, signature: trimSignature, signDate}})
        .then((res) => {
          if (get(res.data.signClientNotice, 'status')) {
            this.setState({loading:false, status: 'success'})
            this.props.onClose()
          } else {
            this.setState({signatureMatchingError: true, loading: false, status: 'failure'})
          }
        })
        .catch((e) => {
          console.log(e) //eslint-disable-line no-console
          this.props.onClose()
        })
    }
  }

  renderDashboardModal() {
    const {classes} = this.props
    const {signatureMatchingError, signature, signatureError, loading, status} = this.state
    return (
      <Grid container spacing={2} className={classes.grid}>
        <Grid item>
          <Trans {...messages.cfdModalMessage} />
        </Grid>
        <Grid item>
          <Trans {...messages.cfdModalAcknowledgement} />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl fullWidth>
            <TextField
              type="text"
              value={signature}
              label={ <Trans {...messages.cfdModalClientSignature} /> }
              onChange={(e) => this.setState({signature: e.target.value,signatureError: false, signatureMatchingError: false, status: ''})}
              onKeyPress={(e) => e.charCode === 13 && this.onSubmit()}
              error={signatureMatchingError || signatureError}
              inputProps={{
                id: 'signature',
                name: 'signature',
                className: classes.selectClass
              }}
            />
          </FormControl>
        </Grid>
        <LoadingButton
          fullWidth
          hideProgressBar={!!status}
          disabled={loading || status === 'failure'}
          status={status}
          onClick={() => this.onSubmit()}
        > <Trans {...messages.submit} />
        </LoadingButton>
        {signatureMatchingError &&
          <FormHelperText className={classes.errorMessage}>
            <Trans {...messages.signatureNotMatch} /></FormHelperText>}
      </Grid>
    )
  }

  render() {
    const {open, onClose, noticeId} = this.props
    return (
      <CustomDialog
        open={open}
        onClose={() => onClose}
        title={ <Trans {...messages.warning} />}
      >
        {noticeId === 'generalCFDDisclaimer' && this.renderDashboardModal()}
      </CustomDialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(SIGN_CLIENT_NOTICE_MUTATION, {
    name: 'signClientNotice',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    },
  }),
)(CFDNoticePopup)
