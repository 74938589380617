import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ChatIcon from '@material-ui/icons/Chat'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import messages from '../../assets/messages'
import bgImageLight from '../../assets/images/page-not-found-light.svg'
import bgImageDark from '../../assets/images/page-not-found-dark.svg'
import PageTitle from './PageTitle'
import AppContext from '../Common/contexts/AppContext'
import {isDarkTheme} from '../../common/utils/general'
import {InnerAppContext} from '../../common/types'

const style = (theme) => ({
  content: {
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      width: 700,
      margin: 'auto'
    },
  },
  image: {
    width: 300,
  },
  children: {
    textAlign: 'center' as const
  }
})

export class ErrorPage extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  render() {
    const {classes} = this.props
    const {themePreference} = this.context
    const bgImage = isDarkTheme(themePreference) ? bgImageDark : bgImageLight

    return (
      <Grid container direction="column" spacing={3} alignItems="center" className={classes.content}>
        <Grid item xs={12}>
          <PageTitle>
            <Trans {...messages.pageError} />
          </PageTitle>
        </Grid>
        <Grid item xs={12}>
          <img
            className={classes.image}
            src={bgImage}
            //@ts-ignore
            alt={<Trans {...messages.pageError} />}
          />
        </Grid>
        <Grid item xs={12} sm={11} className={classes.children}>
          <Typography variant="body2">
            <Trans {...messages.pageErrorInfo} />
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              //@ts-ignore
              onClick={() => window.location = window.location.origin}
              fullWidth
            >
              <ArrowBackIcon />
              &nbsp;
              <Trans {...messages.backToDashboard} />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              //@ts-ignore
              onClick={() => window.location = `${window.location.origin}/support`}
              fullWidth
            >
              <ChatIcon />
              &nbsp;
              <Trans {...messages.contactSupport} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withStyles(style),
  withNamespaces(),
)(ErrorPage)
