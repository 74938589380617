import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AppContext from '../Common/contexts/AppContext'
import {flowRight as compose} from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'
import {FormGroup, FormControlLabel, Switch} from '@material-ui/core'
import classNames from 'classnames'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  switchRoot: {
    float: 'right' as const,
  },
  switchLabel:{
    display:'inline-block',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'inherit'
    }
  },
  formControlLabel:{
    float: theme.direction === 'rtl' ? 'none' as const : 'left' as const,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  underline: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: 15,
  },
})

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[500]}`,
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({classes, ...props}:any) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
))

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.green.color,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
  },
  checked: {},
}))(Switch)

class SwitchButton extends Component<any,any> {
    static propTypes = {
      checked: PropTypes.bool.isRequired,
      onChange: PropTypes.func.isRequired,
    }
    static contextType = AppContext
    context!: InnerAppContext
    render() {
      const {classes, checked, value, onChange, label, classesNameLabel, classesLabel, labelPlacement, id, disabled, iOSSwitch} = this.props
      return <FormGroup>
        <FormControlLabel
          control={
            iOSSwitch ? <IOSSwitch checked={checked} onChange={(e)=> onChange(e)} classes={{root: classes.switchRoot}}/> : <AntSwitch
              id={id || 'switchButton'}
              checked={checked}
              onChange={(e)=> onChange(e)}
              value={value}
              disabled={!!disabled}
              classes={{root: classes.switchRoot}}
            />
          }
          labelPlacement={labelPlacement || 'start'}
          label={label}
          className={label ? classNames(classesNameLabel || [classes.underline, classes.switchLabel]) : ''}
          classes={(label || classesLabel)? classesLabel || {label: classes.formControlLabel, labelPlacementStart: classes.labelPlacementStart,
            root: classes.root} : {}}
        />
      </FormGroup>
    }
}



export default compose(
  withStyles(styles, {withTheme: true}),
)(SwitchButton)
