import React from 'react'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
//todo: either remove bugsnag or fill the data
const bugsnagClient = bugsnag({
  apiKey: 'XXX',
  notifyReleaseStages: ['production'],
  releaseStage: 'XXX',
  appVersion: '0',
  filters: [
    'authtoken',
    /^password$/i,
  ],
  logger: null,
})

bugsnagClient.use(bugsnagReact, React)

export const BugsnagErrorBoundary = bugsnagClient.getPlugin('react')

export default bugsnagClient
