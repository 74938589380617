import React from 'react'
import {map, get, flowRight as compose} from 'lodash'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {withNamespaces, Trans} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import {CustomDialog} from '../../Common/Dialog'
import messages from '../../../assets/messages'

const styles = {
  button:{
    margin: 5,
    minWidth: 150
  },
  grid: {
    textAlign: 'center' as const,
  },
}

const IbAccountSelectModal = (({open, t, onClose, account, classes, width, onSelectAccount}) => {
  const onSmallScreen = isWidthDown('xs', width)
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={t(messages.selectAccount.i18nKey, messages.selectAccount.defaults)}
    >
      <Grid container direction={onSmallScreen ? 'column' : 'row'} justifyContent="center" spacing={1} alignItems="center">
        <Grid item xs={12} className={classes.grid}>
          <Typography variant="body1"><Trans {...messages.pleaseChooseAccount} /></Typography>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          {map(get(account, 'ibId', []), (ibId) => (
            <Button
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              key={ibId}
              onClick={() => onSelectAccount(ibId)}
            >
              {ibId}
            </Button>
          ))}
        </Grid>
      </Grid>
    </CustomDialog>
  )
})

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withWidth(),
)(IbAccountSelectModal)
