import React from 'react'
import {Route, Switch, withRouter, Redirect} from 'react-router-dom'
import VpsPlan from './VpsPlan'
import VpsManage from './VpsManage'
import Payment from './Payment'

const VpsRoutes = ({match: {path}}) => (
  <Switch>
    <Route path={`${path}/manage`} component={VpsManage} />
    <Route path={`${path}/plan/payment`} component={Payment} />
    <Route path={`${path}/plan`} component={VpsPlan} />
    <Redirect exact from={`${path}`} to={`${path}/manage`} />
  </Switch>
)

export default withRouter(VpsRoutes)
