import React, {Component} from 'react'
import {
  filter,
  map,
  flowRight as compose,
  get,
  includes,
  replace,
  omit,
  isEmpty,
} from 'lodash'
import {graphql} from 'react-apollo'
import {languages} from '@bdswiss/common-enums'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import {storeItem} from '../common/utils'
import messages from '../assets/messages'
import PageTitle from './Common/PageTitle'
import PageBody from './Common/PageBody'
import i18nApp from '../i18n'
import {UPDATE_LOCALE} from '../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../graphql/queries'
import FlagIcon from './FlagIcon'
import {withRouter} from 'react-router-dom'
import AppContext from './Common/contexts/AppContext'
import NotificationBar from './Common/NotificationBar'
import queryString from 'querystring'
import {config} from '../config'
import {InnerAppContext} from '../common/types'

const styles = theme => ({
  list: {
    listStyle: 'none',
    paddingLeft: 0,
    columns: 2,
    '-webkit-columns': 2,
    '-moz-columns': 2,
  },
  li: {
    marginBottom: 10,
    fontSize: 15,
  },
  item: {
    paddingLeft: 20,
    fontSize: '1em',
    cursor: 'pointer',
    'white-space': 'pre',
    color: theme.palette.secondary.dark,
  },
  flatItem: {
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 10,
  },
  bold: {
    fontWeight: 400,
  },
})

class LanguageSelection extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext

  changeLanguage(locale) {
    const {history, updateOwnLocale} = this.props
    const prevLocation = get(history.location, 'state.prevLocation')
    i18nApp.changeLanguage(locale, this.context , () => {
      updateOwnLocale({variables: {locale}}).then(() => {
        storeItem('locale', locale)
        if (get(history.location, 'state.prevPath') === '/login') {
          history.push('/accounts')
        } else {
          history.goBack()
        }
      }).catch(() => {
        storeItem('locale', locale)
        if (get(history.location, 'state.prevPath') === '/login') {
          history.push('/accounts')
        } else if (includes(get(prevLocation, 'pathname'), 'register')) {
          let params = queryString.parse(replace(get(prevLocation, 'search'), '?', ''))
          params = {...omit(params, ['campaign', 'p', 'p1', 'p2']), lang: locale}
          const url = Object.keys(params).map(key => key + '=' + params[key]).join('&')
          const redirectTo = `${get(history.location, 'state.prevLocation.pathname')}?${url}`
          history.push(redirectTo)
        } else {
          history.goBack()
        }
      })
    })
  }

  languageDisclaimer() {
    const {classes} = this.props
    const {companyObject: {brandLabel: companyLabel}} = this.context
    return <NotificationBar status="info">
      <React.Fragment>
        <Trans {...messages.languageDisclaimer}
          components={[<span className={classes.bold}>communication</span>]}
          values={{company: companyLabel}}
        />
      </React.Fragment>
    </NotificationBar>
  }

  render() {
    const {classes, t, outer, history} = this.props
    const {excludedTranslations} = config
    let clientLanguages = map(filter(languages, (a) => !!a.client && !a.disabled), 'value')
    const isRegisterPrevPath = ['/register', '/login'].includes(get(history.location, 'state.prevLocation.pathname'))

    if (!isEmpty(excludedTranslations) && isRegisterPrevPath) {
      clientLanguages = clientLanguages.filter((lang) => !excludedTranslations.includes(lang))
    }

    return (
      <React.Fragment>
        <PageTitle title={t(messages.language.i18nKey, messages.language.defaults)} modal={outer}/>
        <PageBody>
          {this.languageDisclaimer()}
          <ul className={classes.list}>
            {clientLanguages.map((lang) => (
              <li key={languages[lang].key} className={classes.li}>
                <span
                  onClick={() => {this.changeLanguage(languages[lang].key)}}
                  id={`lang-${languages[lang].key}`}
                  className={classes.item}
                >
                  <FlagIcon
                    code={languages[lang].flag}
                    className={classes.flatItem}
                    size={'lg'}
                  />{languages[lang].native}
                </span>
              </li>
            ))}
          </ul>
        </PageBody>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
  graphql(UPDATE_LOCALE, {
    name: 'updateOwnLocale',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  })
)(LanguageSelection)
