import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import messages from '../../../assets/messages'
import {isMobile} from '../../../common/utils/browser'
import {get, isEmpty, flowRight as compose} from 'lodash'
import classNames from 'classnames'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import CloseIcon from '@material-ui/icons/HighlightOffOutlined'
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = (theme) => ({
  upload: {
    height: 210,
    cursor: 'pointer',
    position: 'relative' as const,
    [theme.breakpoints.down('md')]: {
      height:'100%',
      padding: '10px 0',
    },
  },
  uploadedImage: {
    maxWidth: '100%',
    height: '100%',
  },
  alignCenter: {
    textAlign: 'center' as const
  },
  uploadPaper: {
    height: 210,
    width: '100%',
    borderRadius: 10,
    [theme.breakpoints.down('md')]: {
      height:'100%'
    },
  },
  input: {
    display: 'none',
  },
  defaultImage: {
    maxWidth: 150,
    maxHeight: 100,
  },
  icon: {
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'right' : 'left']: 15,
    top: 15,
    fontSize: 20
  },
  checkIcon:{
    color: theme.palette.green.color,
  },
  closeIcon:{
    color: theme.palette.error.main,
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`
  },
  success: {
    border: `1px solid ${theme.palette.green.color}`
  },
  img: {
    maxHeight: 70,
  },
  poiDefault: {
    width: '100%',
  },
  card: {
    width: '100%',
  },
  overflowHidden: {
    overflow: 'hidden',
    wordBreak: 'break-word' as const
  },
  textHeight: {
    maxHeight: 50
  },
  helperText: {
    marginTop: 0
  }
})

class SelectDocument extends Component<any,any> {

  render() {
    const {classes, onChange, fileKey, category, status, errors, selectedDocTypeKey, src, srcSuccess, srcError, multiple, showLabel} = this.props
    let {uploadedFile} = this.props

    const multipleUploadedFiles= multiple && uploadedFile && uploadedFile.length > 1
    const multipleUploadedFilesLength = uploadedFile &&  uploadedFile.length
    if (multiple) {
      uploadedFile = uploadedFile[0]
    }

    return (
      <React.Fragment>
        <input
          className={classes.input}
          id={`paper-upload-file-${fileKey}`}
          type="file"
          onChange={(e) => onChange(e)}
          multiple={multiple}
        />
        <label htmlFor={`paper-upload-file-${fileKey}`}>
          <Paper className={classNames(classes.uploadPaper, status && (status === 'error' ? classes.error: classes.success))}>
            <Grid
              container
              alignContent={!isMobile() ? 'center' : 'stretch'}
              justifyContent="center"
              spacing={1}
              className={classNames(classes.upload, classes.overflowHidden)}
            >
              {status && status === 'success' && <CheckIcon className={classNames(classes.icon, classes.checkIcon)} />}
              {status && status === 'error' && <CloseIcon className={classNames(classes.icon, classes.closeIcon)} />}
              {showLabel && <Grid item xs={12} className={classes.alignCenter}>
                <Typography variant="body1">
                  <Trans {...messages[(fileKey === '2') ? 'backSide' : 'frontSide']} />
                </Typography>
              </Grid>}
              {uploadedFile && <Grid item xs={5} className={classNames(classes.alignCenter, classes.img)}>
                <img className={classes.uploadedImage} src={status === 'error' ? srcError || src : URL.createObjectURL(uploadedFile)}
                  alt="" onError={(e)=> {
                    //@ts-ignore
                    if (status !== 'error') e.target.src = srcSuccess || src
                  }}
                />
              </Grid>}
              {uploadedFile && <Grid item xs={12} className={classes.alignCenter}>
                {multipleUploadedFiles ?
                  <Typography component="span" variant="caption" color="primary">({multipleUploadedFilesLength})</Typography> :
                  <Typography variant="caption" classes={{root: classNames(classes.overflowHidden, classes.textHeight)}}>{get(uploadedFile, 'name')}</Typography>}
              </Grid>}
              {!uploadedFile && <Grid item xs={8} className={classes.alignCenter}>
                <img className={classNames(classes.defaultImage, category && classes[`${category.replace('-front','').replace('-back','')}Default`], classes[selectedDocTypeKey])}
                  src={!uploadedFile && src}
                  // @ts-ignore
                  alt={<Trans {...messages.uploadDocument} />} />
              </Grid>}
              {!isEmpty(errors) && <Grid item xs={12}>
                <FormHelperText error className={classNames(classes.alignCenter, classes.helperText)}>  <Trans {...messages[`${errors.error}FileError`]} /> </FormHelperText>
              </Grid>}
              <Grid item xs={12} className={classes.alignCenter}>
                <Typography variant="body2" color="primary">
                  <Trans {...messages[(uploadedFile) ? 'change' : 'uploadDocument']} />
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </label>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(SelectDocument)
