import React, {Component} from 'react'
import moment from 'moment'
import classnames from 'classnames'
import {map, get, some, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import TimerIcon from '@material-ui/icons/Timer'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import {documentStatuses, documentTypes, documentRejectionReasons} from '@bdswiss/common-enums'
import {Loading} from '../../Common/Loading'
import messages from '../../../assets/messages'
import {CLIENT_DATA_QUERY, PROFILE_SETTINGS_QUERY} from '../../../graphql/queries'
import Tooltip from '@material-ui/core/Tooltip'
import Images from '../../Common/Images'
import NotificationBar from '../../Common/NotificationBar'
import AppContext from '../../Common/contexts/AppContext'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  error: {
    color: theme.palette.red.color
  },
  checkIcon: {
    color: theme.palette.green.color
  },
  timerIcon: {
    color: theme.palette.yellow.color
  },
  lastCell: {
    maxWidth:150
  },
  dateTime:{
    color: theme.palette.secondary.main
  },
  emptyListsIcon: {
    ...theme.emptyListsIcon
  },
  noTradesIconWidth:{
    textAlign: 'center' as const
  }
})

class DocumentsHistory extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  getIcon(classes, status) {
    if (status === documentStatuses.accepted.key) {
      return <CheckIcon className={classes.checkIcon} />
    } else if (status === documentStatuses.rejected.key) {
      return <CloseIcon className={classes.error} />
    }
    return <TimerIcon className={classes.timerIcon} />
  }

  transformTooltip(text) {
    return text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, c => c.toUpperCase())
  }

  otherReasonMessage(reason, locale) {
    if (reason !== documentRejectionReasons.other.key) {
      return (
        documentRejectionReasons[reason].localization.t(locale)
      )
    } else {
      return (
        <Trans {...messages.documentsOtherReasonMessage} />
      )
    }
  }

  render() {
    const {t, loading, documentsLoading, classes, documents, viewer: {locale}} = this.props
    const {themePreference} = this.context
    if (loading || documentsLoading) return <Loading />

    const hasRejectedDocs = some(documents, (d) => d.status === documentStatuses.rejected.key)

    return (
      <Grid container spacing={3} direction="column">
        { documents.length < 1 && <Grid item xs={12}>
          <NotificationBar status='info'>
            <Trans {...messages.noDocuments} />
          </NotificationBar>
          <Grid container
            direction="column"
            justifyContent="space-between"
            alignItems="center">
            <Grid item className={classes.noTradesIconWidth}>
              <img className={classes.emptyListsIcon} src={Images[`documents-empty-${themePreference}.png`]} alt='noTrades' />
            </Grid>
          </Grid>
        </Grid>
        }
        {documents.length > 0 && <Grid item xs={12} sm={8} lg={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell><Trans {...messages.documentType} /></TableCell>
                <TableCell><Trans {...messages.date} /></TableCell>
                {hasRejectedDocs && <TableCell className={classes.cell} />}
              </TableRow>
            </TableHead>
            <TableBody>
              {map(documents, (d) =>
                <TableRow key={d.id} aria-label={d.status}>
                  <TableCell className={classes.cell} component="th" scope="row">
                    <Tooltip title={t(messages[d.status].i18nKey, messages[d.status].defaults)} placement="top">
                      {this.getIcon(classes, d.status)}
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classnames([classes.cell])}>
                    <Typography variant="body1">{documentTypes[d.type].localization.t(locale)}</Typography>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Typography variant="body1" className={classes.dateTime}>{moment(d.createdAt).format('DD MMM YYYY')}</Typography>
                  </TableCell>
                  {hasRejectedDocs &&
                    <TableCell className={classnames([classes.cell,classes.lastCell])}>
                      {d.status === documentStatuses.rejected.key && <Typography variant="caption">
                        {d.rejectionReasonCode && this.otherReasonMessage(d.rejectionReasonCode, locale)}
                      </Typography>}
                    </TableCell>
                  }
                </TableRow>)
              }
            </TableBody>
          </Table>
        </Grid>}
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => ({
      error:props.data?.error,
      documentsLoading:props.data?.loading,
      documents: get(props.data, 'viewer.documents', []),
    })
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => ({
      loading:props.data?.loading,
      error:props.data?.error,
      viewer: get(props.data, 'viewer'),
    })
  }),
)(DocumentsHistory)
