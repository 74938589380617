import React, {Component} from 'react'
import {get, flowRight as compose} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import {Link} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import messages from '../../assets/messages'
import NotificationBar from '../Common/NotificationBar'
import {graphql} from 'react-apollo'
import {CONFIRM_EMAIL_MUTATION} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import Loading from '../Common/Loading'

const styles = theme => ({
  successDiv:{
    backgroundColor: theme.palette.lightgreen.color,
    padding: '15px 10px',
    marginBottom: 40
  },
  successMessage:{
    color: theme.palette.green.color,
  },
  failedDiv:{
    backgroundColor: theme.palette.lightred.color,
    padding: '15px 10px',
    marginBottom: 40
  },
  failedMessage:{
    color: theme.palette.red.color,
  },
  textLink: {
    color: theme.palette.primary.main,
  },
})

export class EmailVerification extends Component<any,any> {
  constructor(props) {
    super(props)
    this.state ={
      token: props.match.params.confirmEmailToken,
      successMessage: '',
    }
  }

  componentDidMount() {
    const {token} = this.state
    return this.props.confirmOwnEmail({variables: {token}})
      .then(() => {
        this.setState({successMessage: true})
      })
      .catch((error) => {
        this.setState({successMessage: false})
      })
  }

  render() {
    const {classes, client} = this.props
    const {successMessage} = this.state
    if (successMessage === '') return <Loading />
    const alreadyConfirmed = client.emailConfirmed && !successMessage

    return (
      <Grid item xs={12} sm={10}>
        {!alreadyConfirmed && <NotificationBar status={successMessage ? 'success' : 'error' }>
          {successMessage ? <Trans {...messages.successfulEmailConfirmation} />
            : <Trans {...messages.failedEmailConfirmation} />}
        </NotificationBar>}
        {alreadyConfirmed&&  <NotificationBar status='success'>
          <Trans {...messages.emailAreadyConfirmed}
            components={[
              <Link to={'/'} className={classes.textLink}>Dashboard</Link>,
            ]} />
        </NotificationBar>}
      </Grid>
    )
  }
}

export default compose (
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CONFIRM_EMAIL_MUTATION, {
    name: 'confirmOwnEmail',
    //@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data, ownProps: {history}}: any) => ({
      clientLoading: loading,
      error,
      history,
      client: get(data, 'viewer'),
    })
  }),
)(EmailVerification)
