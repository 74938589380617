import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import {get, flowRight as compose} from 'lodash'
import Amount from '../../Common/Amount'
import Grid from '@material-ui/core/Grid'
import {Link} from 'react-router-dom'
import ForexTradeNow from '../Forex/ForexTradeNow'
import AppContext from '../../Common/contexts/AppContext'
import {isMt5ForexAccount} from '../../../common/utils/accounts'
import {hasWebTrader} from '../../../common/utils/general'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  textCenter: {
    textAlign: 'center' as const
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  container: {
    padding: '20px 0'
  },
  boldText: {
    fontWeight: 400
  },
  greyText: {
    color: theme.palette.secondary.main
  },
})

class MemberDetails extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  render() {
    const {classes, t, currentEntry, nickname} = this.props
    const {locale} = this.context
    return <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4}><Typography variant="body1" className={classNames(classes.greyText, classes.boldText)}><Trans {...messages.participatingAccount} /> </Typography></Grid>
            <Grid item xs={4}><Typography variant="body1">{get(currentEntry, 'remoteId')}</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1"><Link to={`/accounts/${get(currentEntry, 'id')}`} className={classes.link}>
              {t(messages.manage.i18nKey, messages.manage.defaults)}</Link></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4}><Typography variant="body1" className={classNames(classes.greyText, classes.boldText)}><Trans {...messages.nickname} /> </Typography></Grid>
            <Grid item xs={8}><Typography variant="body1">{nickname}</Typography></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4}><Typography variant="body1" className={classNames(classes.greyText, classes.boldText)}><Trans {...messages.balance} /> </Typography></Grid>
            <Grid item xs={4}><Typography variant="body1"><Amount value={get(currentEntry, 'balance')} currency={get(currentEntry, 'currency', 'EUR')} locale='en-US' /></Typography></Grid>
            <Grid item xs={4}><Typography variant="body1"><Link to={`/transactions/${get(currentEntry, 'id')}/deposit`} className={classes.link}>
              {t(messages.depositNow.i18nKey, messages.rank.defaults)}</Link></Typography>
            </Grid>
          </Grid>
        </Grid>
        {!isMt5ForexAccount(get(currentEntry, '__typename')) && hasWebTrader() &&  <Grid item xs={12} className={classNames(classes.textCenter, classes.container)}>
          <ForexTradeNow type='button' account={currentEntry} locale={locale} alignLeft/>
        </Grid>}
      </Grid>
    </Grid>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(MemberDetails)
