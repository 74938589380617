// @ts-nocheck
import React, {Component} from 'react'
import AppContext from '../Common/contexts/AppContext'
import Grid from '@material-ui/core/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import messages from '../../assets/messages'
import Images from '../Common/Images'
import {isMobile} from '../../common/utils/browser'
import {flowRight as compose, map} from 'lodash'
import classNames from 'classnames'
import {CustomDialog} from '../Common/Dialog'
import PageTitle from '../Common/PageTitle'
import {getCurrentTheme} from '../../common/utils'

const styles = (theme) => ({
  iconClose: {
    width: 46,
    height: 30,
    position: 'relative',
    left: 7,
    backgroundSize: '30px 30px',
    paddingLeft: 10,
    display: 'block',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    backgroundImage: `url(${Images[`icon-close-${getCurrentTheme()}.svg`]})`,
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '20px 20px',
    },
  },
  popup: {
    maxWidth: 1000,
    margin: '0 auto'
  },
  textCenter:{
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'justify',
    }
  },
  imgMobile: {
    width: '100%'
  },
  imgDektop:{
    maxWidth: '70%'
  },
  dialogContent: {
    marginTop: -10
  },
  subol:{
    textAlign: 'left',
    fontWeight: 400,
    marginLeft: '35%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    }
  }

})

class VpsInstructions extends Component {
  static contextType = AppContext

  render() {
    const {classes, onClose} = this.props
    const vpsInstructionsStep1Notes = ['howToAccessVpsStep1Note1', 'howToAccessVpsStep1Note2', 'howToAccessVpsStep1Note3']
    return <CustomDialog
      fullScreen
      open
      customClasses={classes}
    >
      <Grid container alignItems="center" alignContent='center' className={classNames(classes.popup, classes.textCenter)} spacing={3}>
        <Grid item container justifyContent="flex-end">
          <div className={classes.iconClose} onClick={() => onClose()} />
        </Grid>
        <Grid item xs={12} className={classes.centerItem}>
          <PageTitle hideArrow={isMobile()} modal>
            <Trans {...messages.howToAccessVps} />
          </PageTitle>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Trans {...messages.howToAccessVpsStep1} />
          </Typography>
          <ol className={classes.subol}>
            {map(vpsInstructionsStep1Notes, (key) => <li key={key} className={classes.li}>
              <Typography variant='body1'>
                <Trans {...messages[key]}/></Typography>
            </li>)
            }
          </ol>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Trans {...messages.howToAccessVpsStep2} />
          </Typography>
          <Typography variant="caption">
            <Trans {...messages.howToAccessVpsStep2Note1} />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.centerItem}>
          <img src={Images['vps-remote-desktop-windows.png']} alt={'windows remote desktop'} className={isMobile() ? classes.imgMobile : classes.imgDektop} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Trans {...messages.howToAccessVpsStep3} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img src={Images['vps-remote-desktop.png']} alt={'remote desktop'} className={isMobile() ? classes.imgMobile : classes.imgDektop} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Trans {...messages.howToAccessVpsStep4} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img src={Images['vps-enter-credentials.png']} alt={'enter-credentials'} className={isMobile() ? classes.imgMobile : classes.imgDektop} />
        </Grid>
      </Grid>
    </CustomDialog>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(VpsInstructions)
