//@ts-nocheck
import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {get, flowRight as compose, isEmpty, some, toString, includes, keys, find} from 'lodash'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {CustomDialog} from '../Common/Dialog'
import LoadingButton from '../Common/LoadingButton'
import messages from '../../assets/messages'
import {UPDATE_OWN_DETAILS_MUTATION} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import withStyles from '@material-ui/core/styles/withStyles'
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@material-ui/core'
import {checkEmail, register} from '../../common/utils/requests'
import {validateEmail} from '../../common/utils/validations'
import {config} from '../../config'
import AppContext from '../Common/contexts/AppContext'
import {checkPassword, isMobile, storeItem} from '../../common/utils'
import {Password} from '../Common/Password'
import {companies, regulators} from '@bdswiss/common-enums'
import {partnerMigrationText} from '../../common/utils/uioptions'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  error: {
    color: theme.palette.red.color
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 400
  },
  closeIcon: {
    position: 'absolute' as const,
    top: 5,
    right: 5,
    color: theme.palette.grey[400],
    cursor: 'pointer'
  },
  container: {
    position: 'relative' as const
  },
  textCenter: {
    textAlign: 'center' as const
  }
})

export class PartnerMigrationPopup extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      form: {
        email: '',
        password: '',
        acceptTerms: '',
      },
      errors: {},
      showMigrationPopup: true,
      status: '',
      loading: false,
      showPassword: false,
      passwordError: ''
    }
  }

  handleClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword
    }))
  }

  checkPassword(password) {
    const passwordError = checkPassword(password)
    if (isEmpty(passwordError)) {
      this.setState({passwordError: ''})
    } else {
      this.setState({passwordError: <Trans {...messages[passwordError]} />})
    }
  }

  validate() {
    const {t} = this.props
    const {form} = this.state
    const {registrationMultiRegulation} = config
    const errors ={}


    for (const field of Object.keys(form)) {
      if (field === 'email') {
        errors[field] = isEmpty(form[field]) || !validateEmail(form[field])
      } else {
        errors[field] = isEmpty(form[field])
      }
    }

    if (some(errors)) {
      this.setState({errors})
      return
    }

    this.setState({status: '', loading: true})
    const regulation = get(keys(registrationMultiRegulation), '[0]')
    checkEmail(form.email, regulation, get(registrationMultiRegulation[regulation], 'products[0]'))
      .then((res) => {
        this.setState(state => ({
          errors: {
            ...state.errors,
            emailExists: res === 'emailIsUsed',
            emailNotValidDomain: res === 'invalidEmail',
            affiliateEmailExists: res === 'affiliateEmailIsUsed',
            closedAccount: res === 'emailIsUsedAccountClosed'
          }
        }))
        if (!res)
          this.handleSubmit()
        else
          this.setState({loading: false})
      })
      .catch((err) => {
        this.setState({status: 'failure', loading: false, submitMessageError: t(messages.connectionError.i18nKey,messages.connectionError.defaults)})
      })

  }

  handleChange(prop, value) {
    this.setState(state => ({
      form: {...state.form,[prop]: value,},
      errors: {...state.errors,[prop]: !value}
    }))
  }


  async handleSubmit() {
    const {client: {id, firstName, lastName, phone, address: {country}, skypeId, website, countriesAffiliate, birthday}, t} = this.props
    const {form: {email, password}} = this.state
    const {locale} = this.context
    const {registrationMultiRegulation} = config

    const regulation = get(keys(registrationMultiRegulation), '[0]')
    register(firstName, lastName, '', '', phone, email, password, country, true, true, true, true, get(registrationMultiRegulation[regulation], 'products'),
      '', true, locale, skypeId, website, countriesAffiliate, '', '', regulation, '', '', '',
      birthday, '', '',  '', '', '', '', '', toString(id))
      .then((res) => {
        this.setState({loading: false})
        if (res.result === 'success') {
          const url = get(res, 'relatedEntities[0].frontendUrl')
          if (url) {
            window.location.href = get(res, 'relatedEntities[0].frontendUrl')
          }
        } else {
          this.setState({status: 'failure', loading: false, submitMessageError: t(messages.somethingWrongError.i18nKey,messages.somethingWrongError.defaults)})
        }
      })
      .catch(() => {
        this.setState({status: 'failure', loading: false, submitMessageError: t(messages.somethingWrongError.i18nKey,messages.somethingWrongError.defaults)})
      })
  }

  render() {
    const {classes, t} = this.props
    const {showMigrationPopup, form: {email, password, acceptTerms}, errors, status, loading, submitMessageError, showPassword, passwordError} = this.state
    const {supportEmail, weblinks: {affiliateTermsAndConditions, affiliateGuidelines, affiliateCodeConduct}, translatedPartnerGuidelines, registrationMultiRegulation} = config
    const {locale} = this.context
    const regulation = get(keys(registrationMultiRegulation), '[0]')
    const company = find(companies, {key: regulation})
    const entity = find(regulators, {key: get(company, 'regulator')})

    return <CustomDialog
      open={showMigrationPopup}
      title={t(messages.migratePartnersTitle.i18nKey, {entity: get(entity, 'label')})}
      onCloseIcon={() => {
        this.setState({showMigrationPopup: false})
        storeItem('migrationPopupClosed', true)
      }}
      customClasses={{closeIcon: classes.closeIcon}}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1"><Trans {...messages.migratePartners} values={{entity: get(entity, 'label'), company: get(company, 'tradingName'),
            basedClients: t(messages[get(partnerMigrationText, regulation)].i18nKey, messages[get(partnerMigrationText, regulation)].defaults)}}/></Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1"><Trans {...messages.migratePartnersText2} values={{entity: get(entity, 'label')}}/></Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id={'email'}
            label={t(messages.email.i18nKey, messages.email.defaults)}
            onChange={(e) => this.handleChange('email', e.target.value)}
            error={get(errors, 'email', false)}
            value={email}
          />
          {get(errors, 'emailExists', false) &&
          <FormHelperText className={classes.error}><Trans {...messages.emailExists} className={classes.link} /></FormHelperText>}
          {get(errors, 'closedAccount', false) &&
          <FormHelperText className={classes.error}><Trans {...messages.emailExistsClosed}
            components={[<a href={`mailto:${supportEmail}`} className={classes.link}>support</a>]}/></FormHelperText>}
          {get(errors, 'emailNotValidDomain', false) &&
          <FormHelperText className={classes.error}><Trans {...messages.emailNotValidDomain} /></FormHelperText>}
          {get(errors, 'affiliateEmailExists', false) &&
          <FormHelperText className={classes.error}><Trans {...messages.affiliateEmailExists} /></FormHelperText>}
          {get(errors, 'email') && <FormHelperText className={classes.error}><Trans {...messages.emailValidation} /></FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <Password
            classes={classes}
            showPassword={showPassword}
            onChange={(e)=>{
              this.handleChange('password', e.target.value)
              this.checkPassword(e.target.value)
            }}
            error={get(errors, 'password', false)}
            onClickShow={()=> this.handleClickShowPassword()}
            fullWidth
            errorText={passwordError}
            value={password}
            showErrorText={true}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel
              classes={{label:classes.checkboxLabel}}
              control={
                <Checkbox
                  required
                  checked={acceptTerms}
                  onChange={(e) => this.handleChange('acceptTerms', e.target.checked? 'accept' : '')}
                  color="primary"
                  value={acceptTerms}
                  className={errors.acceptTerms ? classes.error : ''}
                />
              }
              label={<Trans {...messages.signUpAffiliateCheckbox} components={[
                <a href={registrationMultiRegulation[regulation].termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                <a href={registrationMultiRegulation[regulation].legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
                <a href={registrationMultiRegulation[regulation].privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
                <a href={affiliateTermsAndConditions} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                <a href={affiliateCodeConduct} target='_blank' rel='noreferrer noopener' className={classes.link}>code</a>,
                <a href={includes(translatedPartnerGuidelines, locale) ? affiliateGuidelines.replace('.pdf', `_${locale.toUpperCase()}.pdf`) : affiliateGuidelines}
                  target='_blank' rel='noreferrer noopener' className={classes.link}>guideliness</a>,
              ]} />
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.textCenter}>
          <LoadingButton
            id='loadingButton'
            onClick={() => this.validate()}
            disabled={loading}
            status={status}
            fullWidth={isMobile()}
          >
            <Trans {...messages.submit} />
          </LoadingButton>
          {status==='failure' &&
          <FormHelperText classes={{root: classes.errorRoot}}className={classes.error}>{submitMessageError}</FormHelperText>}
        </Grid>
      </Grid>
    </CustomDialog>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(UPDATE_OWN_DETAILS_MUTATION, {
    name: 'updateOwnDetails',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    },
  })
)(PartnerMigrationPopup)
