import React, {Component} from 'react'
import Payment from 'payment'
import PropTypes from 'prop-types'
import {frontends} from '@bdswiss/common-enums'
import {getPlatform} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import CreditCardIcon from '@material-ui/icons/CreditCardRounded'
import {withStyles} from '@material-ui/core/styles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../../assets/messages'
import {debounce, isNumber, isEmpty, values, flowRight as compose} from 'lodash'
import SelfPostingForm from './SelfPostingForm'

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textCenter:{
    textAlign: 'center' as const
  },
})

const validators = {
  cardNumber: (value) => {
    if (!value) return messages.requiredField
    if (!isNumber(+value) || value.length < 16) return messages.invalidCardNumber
  },
  cardName: (value) => {
    if (!value) return messages.requiredField
  }
}

const initialState = {form: {cardNumber: '', cardName: ''}, errors: {}, creatingDeposit: false, doSubmitForm: false}

class RPNPayProvider extends Component<any,any> {
  static propTypes = {
    account: PropTypes.shape({
      id: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    providerProperties: PropTypes.shape({
      name: PropTypes.string.isRequired,
      provider: PropTypes.string.isRequired,
      paymentKey: PropTypes.string.isRequired,
    }).isRequired,
    amount: PropTypes.number.isRequired,
    onError: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
  }
  constructor(props) {
    super(props)
    this.validateFields = debounce(this.validateFields, 200)
    this.state = initialState
  }

  componentDidMount() {
    document.querySelector('[name="rpnpay-card-number"]') && Payment.formatCardNumber(document.querySelector('[name="rpnpay-card-number"]'))
  }

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields())
  }

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      const validate = validators[k]
      errors[k] = validate && validate(form[k])
    })
    this.setState({errors})
  }

  formIsValid() {
    const {form, errors} = this.state
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(form).filter((v) => v))
  }

  doCreateDeposit() {
    const {account, providerProperties: {provider, paymentKey}, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms} = this.props
    const {form: {cardNumber, cardName}} = this.state

    if (!this.formIsValid()) return

    this.setState({creatingDeposit: true})

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        cardNumber,
        cardName,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment} = newDeposit
        const parsedFormData = JSON.parse(payment.url)
        this.setState({formData: parsedFormData, doSubmitForm: true})
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  render() {
    const {classes} = this.props
    const {form: {cardNumber, cardName}, errors, creatingDeposit, formData, doSubmitForm} = this.state

    return (
      <React.Fragment>
        <SelfPostingForm formData={formData} doSubmitForm={doSubmitForm} />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              <Trans {...messages.enterCardDetails} />
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="rpnpay-card-number">
                <Trans {...messages.cardNumber} />
              </InputLabel>
              <Input
                id="rpnpay-card-number"
                name="rpnpay-card-number"
                type="text"
                error={!!errors.cardNumber}
                value={cardNumber}
                onChange={(e) => this.handleChange('cardNumber', e.target.value)}
                inputProps={{pattern: '[0-9]*', inputMode:'numeric', autoComplete: 'off'}}
                startAdornment={
                  <InputAdornment position="start" >
                    <CreditCardIcon />
                  </InputAdornment>
                }
              />
              {
                errors.cardNumber &&
                <FormHelperText className={classes.error}><Trans {...errors.cardNumber} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="rpnpay-card-name">
                <Trans {...messages.fullName} />
              </InputLabel>
              <Input
                id="rpnpay-card-name"
                type="text"
                error={!!errors.cardName}
                value={cardName}
                onChange={(e) => this.handleChange('cardName', e.target.value)}
              />
              {
                errors.cardName &&
                <FormHelperText className={classes.error}><Trans {...errors.cardName} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          <PaymentActionButton
            loading={creatingDeposit}
            disable={!this.formIsValid()}
            onClick={() => this.doCreateDeposit()}
          />
        </Grid>
      </React.Fragment>
    )
  }
}


export default compose(
  withStyles(style),
  withNamespaces(),
  withCreateDeposit
)(RPNPayProvider)
