import React from 'react'
import {graphql} from 'react-apollo'
import {get, flowRight as compose} from 'lodash'
import {accountTypes, kycStatuses} from '@bdswiss/common-enums'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY} from '../../graphql/queries'
import IbAccount from './Ib'
import MamAccount from './Mam'
import PammAccount from './Pamm'
import ForexAccount from './Forex'
import BinaryAccount from './Binary'
import AffiliateAccount from './Affiliate'
import SMPrimeWalletAccount from './SMPrimeWallet'
import BitnukAccount from './Bitnuk'
import {FullScreenDialog} from '../Common/Dialog'
import {isBitnukAccount, isIntroducingBrokerAccount} from '../../common/utils/accounts'
import {Loading} from '../Common/Loading'
import AppContext from '../Common/contexts/AppContext'
import {Redirect} from 'react-router-dom'
import {InnerAppContext} from '../../common/types'
import {KartwheelAccount} from './Kartwheel'

class AccountView extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  closeDialog() {
    const {history} = this.props
    history.location.state && history.location.state.from && history.location.state.from==='/login' ?
      this.context.logout() : history.push('/accounts')
  }

  render() {
    const {loading, account, viewer, viewerLoading} = this.props
    if (loading || viewerLoading || !account) return (<Loading />)
    const accountCategory = get(accountTypes, [account.__typename, 'category'])
    const category = isIntroducingBrokerAccount(account) || isBitnukAccount(account)
      ? get(accountTypes, [account.__typename, 'subCategory']) : accountCategory

    if (isBitnukAccount(account) && get(viewer, 'kycStatus') !== kycStatuses.approved.value)
      return <Redirect to={{pathname: '/accounts', state:{showBitnukPopup: true}}}/>

    switch (category) {
      case 'forexMt5':
      case 'forexMt4':
      case 'cent':
        return (
          <FullScreenDialog
            desktopOnly
            fullScreen
            open={true}
            classes={this.props.classes}
            onClose={() => this.closeDialog()}
          >
            <ForexAccount />
          </FullScreenDialog>
        )
      case 'mam':
        return (
          <FullScreenDialog
            desktopOnly
            fullScreen
            open={true}
            classes={this.props.classes}
            onClose={() => this.props.history.push('/accounts')}
          >
            <MamAccount />
          </FullScreenDialog>
        )
      case 'tradeSmarter':
        return (
          <FullScreenDialog
            desktopOnly
            fullScreen
            open={true}
            classes={this.props.classes}
            onClose={() => this.props.history.push('/accounts')}
          >
            <BinaryAccount />
          </FullScreenDialog>
        )
      case 'pammForex':
        return (
          <FullScreenDialog
            desktopOnly
            fullScreen
            open={true}
            classes={this.props.classes}
            onClose={() => this.props.history.push('/accounts')}
          >
            <PammAccount />
          </FullScreenDialog>
        )
      case 'affiliate':
        return (
          <FullScreenDialog
            desktopOnly
            fullScreen
            open={true}
            classes={this.props.classes}
            onClose={() => this.props.history.push('/accounts')}
          >
            <AffiliateAccount />
          </FullScreenDialog>
        )
      case 'ib':
        return (
          <FullScreenDialog
            desktopOnly
            fullScreen
            open={true}
            classes={this.props.classes}
            onClose={() => this.props.history.push('/accounts')}
          >
            <IbAccount />
          </FullScreenDialog>
        )
      case 'kartwheel':
        return (
          <FullScreenDialog
            desktopOnly
            fullScreen
            open={true}
            classes={this.props.classes}
            onClose={() => this.props.history.push('/accounts')}
          >
            <KartwheelAccount />
          </FullScreenDialog>
        )
      case 'smPrime':
        return (
          <FullScreenDialog
            desktopOnly
            fullScreen
            open={true}
            classes={this.props.classes}
            onClose={() => this.props.history.push('/accounts')}
          >
            <SMPrimeWalletAccount />
          </FullScreenDialog>
        )
      case 'bitnuk':
        return (
          <FullScreenDialog
            desktopOnly
            fullScreen
            open={true}
            classes={this.props.classes}
            onClose={() => this.props.history.push('/accounts')}
          >
            <BitnukAccount />
          </FullScreenDialog>
        )
      default:
        return (<Loading />)
    }
  }
}

export default compose(
  graphql(ACCOUNTS_QUERY, {
    options: (props:any) => ({variables: {id: props.match.params.accountId}}),
    props: ({data: {error, loading}, data}:any) => ({
      error,
      loading,
      account: get(data, 'viewer.accounts.0'),
    })
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading}, data}:any) => ({
      viewerLoading: loading,
      viewer: get(data, 'viewer'),
    })
  }),
)(AccountView)
