import React from 'react'
import {flowRight as compose} from 'lodash'
import Grid from '@material-ui/core/Grid'
import {withRouter} from 'react-router-dom'
import {withNamespaces} from 'react-i18next'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import PaymentsFilter from './PaymentsFilter'

const MobileFilterModal = (props) => {
  const {classes, accounts, onChange, filter,accountId, rows, transactionsLimits} = props
  return (
    <Grid container direction="column">
      <PaymentsFilter
        accountId={accountId}
        accounts={accounts}
        filter={filter}
        onChange={filter => onChange(filter)}
        rows={rows}
        transactionsLimits={transactionsLimits}/>
    </Grid>
  )
}

export default compose(
  withNamespaces(),
  withRouter,
  withMobileDialog()
)(MobileFilterModal)
