import React from 'react'
import {Route, Switch, withRouter, Redirect} from 'react-router-dom'
import AccountOverview from '../Common/AccountOverview'

const IbRoutes = ({match: {path}}) => (
  <Switch>
    <Redirect from={`${path}/transactions`} to="/transactions/withdraw"/>
    <Route exact path={`${path}`} component={AccountOverview} />
  </Switch>
)

export default withRouter(IbRoutes)
