import {map, get, has, omit, includes, keys, isEmpty, flowRight as compose} from 'lodash'
import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import Hidden from '@material-ui/core/Hidden'
import FormLabel from '@material-ui/core/FormLabel'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import messages from '../../../../assets/messages'
import AppContext from '../../../Common/contexts/AppContext'
import FormHelperText from '@material-ui/core/FormHelperText'
import {Checkbox, FormGroup, TextField} from '@material-ui/core'
import SelectDocument from '../../Documents/SelectDocument'
import {checkFileMimeType} from '../../../../common/utils/validations'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import {isMobile} from '../../../../common/utils/browser'
import DueDiligenceDescriptionModal from '../../../Common/DueDiligenceDescriptionModal'
import {maxFileSize} from '../../../../common/utils/variables'
import Images from '../../../Common/Images'
import {InnerAppContext} from '../../../../common/types'

const styles = theme => ({
  questions: {
    marginBottom:20,
    width: '100%'
  },
  answersGroup:{
    margin:16
  },
  answersGroupTextField:{
    margin:16,
    width: '80%'
  },
  question: {
    marginBottom:10
  },
  answer:{
    lineHeight:1.5
  },
  error:{
    color: theme.palette.red.color,
  },
  errorText:{
    color: theme.palette.red.color,
    marginTop: 0,
    marginLeft: 18
  },
  onlyText:{
    visibility: 'hidden' as const
  },
  helpIcon: {
    marginBottom: '-5px',
    paddingTop: 5,
    cursor: 'pointer'
  },
})

export class DueDiligenceQuestions extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      uploadedFile: {},
      errorFiles: {},
      openPopup: {promoteProducts: false, servicesOffer: false},
    }
  }

  openPopup(key) {
    this.setState(state => ({
      openPopup: {
        ...state.openPopup,
        [key]: true
      },
    }))
  }

  closePopup () {
    this.setState({openPopup: false})
  }

  handleFileUpload(prop, e, questionKey) {
    const updatedProp = e.target.files[0]
    this.setState(prevState => ({
      [prop]: {
        ...prevState[prop],
        [questionKey]: updatedProp,
      }
    }), this.props.onChange(e.target.files[0], questionKey))
    const {errorFiles} = this.state
    map(e.target.files,(file) => {
      try {
        checkFileMimeType(file).then( (res: any) => {
          this.setState({fileChecked: true})
          const fileFormat = res && res.indexOf('image') === -1 && res.indexOf('pdf') === -1
          const fileSize = file.size > maxFileSize * 1024 * 1024
          if (fileFormat || fileSize) {
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [questionKey]: {
                  name: file.name,
                  error: fileFormat ? 'format' : 'size'
                }
              }
            }))
          }
          else {
            const errors = omit(errorFiles,[questionKey])
            this.setState({errorFiles:errors})
          }
        })

      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
  }

  render() {
    const {form, formState, errors, activeStep, onChange, classes, questions} = this.props
    const {locale, companyObject, themePreference} = this.context
    const {uploadedFile, errorFiles, openPopup} = this.state
    return (
      map(questions, (q, questionKey) => {
        const {step: {stepPosition}, options, description} = q
        const answerKey = get(form, `answers[${questionKey}]` ,'')
        const hasError = formState === 'validation' && has(errors, questionKey)
        const termsAnswerKey = q.key === 'termsConditions'
        return (
          stepPosition === activeStep &&
          <Grid key={`${questionKey}-row`} container>
            <Grid key={`${questionKey}-column-1`} item xs={12} sm={9}>
              <FormControl key={questionKey} component="fieldset" className={classes.questions} >
                <FormLabel
                  id={questionKey}
                  component="legend"
                  error={hasError}
                  className={classes.question}>
                  <Typography variant='body2' className={hasError ? (classes.error): ''}>
                    {q.orderLabel}. {q.localization.t(locale, {domain: companyObject.emailDomain})}
                    {isMobile() && description ?
                      <InfoIcon className={classes.helpIcon} color="secondary" onClick={() => this.openPopup(q.key)}/>
                      : ''}
                  </Typography>
                  {hasError && <FormHelperText className={classes.errorText}>
                    <Trans {...messages.requiredField} /></FormHelperText>}
                </FormLabel>
                <FormGroup
                  row
                  className={!q.textfield ? classes.answersGroup : classes.answersGroupTextField}
                >
                  {q.multiselect && map(options, (answer, answerKeySub) =>
                  {
                    const answerKeyValue = termsAnswerKey ? 'termsAnswer' : answerKeySub
                    return (!has(answer, ['disable']) && <FormControlLabel
                      style={!termsAnswerKey ? {width: '49%', minWidth: '310px'}
                        : {width: '100%', minWidth: '100%'}}
                      key={answerKeySub}
                      value={answerKeyValue}
                      classes={{label: classes.answer}}
                      label={answer.localization.t(locale, {domain: companyObject.emailDomain})}
                      onChange={(e) => onChange(e.target, questionKey)}
                      control={<Checkbox
                        checked={answerKey.includes(answerKeyValue)}
                        color="primary"
                        classes={{root: get(answer, 'onlyText') && classes.onlyText}}/>}
                    />)
                  }
                  )}
                  {q.textfield && <Grid item xs={12} style={{width: '50%', minWidth: '50%'}} key={q.value}>
                    <TextField
                      required={q.mandatory(form.answers, 'key')}
                      id={q.key}
                      name={q.value}
                      label={q.localization.t(locale)}
                      fullWidth
                      multiline
                      value={answerKey || ''}
                      onChange={(e) => onChange(e.target.value, questionKey)}
                    /></Grid>}
                  {q.radioBtn && <RadioGroup
                    row
                    name={questionKey}
                    value={answerKey}
                    onChange={(e) => onChange(e.target, questionKey)}
                    className={classes.answersGroup}
                  >
                    {map(options, (answer, answerKey) =>
                      (!has(answer, ['disable']) && <FormControlLabel
                        style={{width: '49%', minWidth: '310px'}}
                        key={answerKey}
                        value={answerKey}
                        classes={{label: classes.answer}}
                        label={answer.localization.t(locale, {domain: companyObject.emailDomain})}
                        control={<Radio
                          color="primary"/>}
                      />)
                    )}
                  </RadioGroup>}
                  {q.uploadBtn && <Grid container>
                    <Grid item xs={5}>
                      <SelectDocument
                        uploadedFile={uploadedFile[questionKey]}
                        onChange={(e) => this.handleFileUpload('uploadedFile', e, questionKey)}
                        key={answerKey}
                        type={'upload'}
                        status={uploadedFile[questionKey] && (includes(keys(errorFiles), questionKey) ? 'error': 'success')}
                        errors={!isEmpty(errorFiles) && errorFiles[questionKey]}
                        fileKey={questionKey}
                        category={`document-${themePreference}`}
                        src={Images[`upload-document-${themePreference}.png`]}
                      />
                    </Grid>
                  </Grid>
                  }
                </FormGroup>
              </FormControl>
            </Grid>
            <Hidden mdDown>
              <Grid key={`${questionKey}-column-2`} item xs={3} sm={3}>
                {description &&
                  <Typography variant="body1">
                    {q.description.localization.t(locale, {domain: companyObject.emailDomain})}
                  </Typography>}
              </Grid>
            </Hidden>
            <DueDiligenceDescriptionModal
              open={get(openPopup, [q.key], false)}
              q={q} onClose={() => this.closePopup()}
            />
          </Grid>
        )
      })
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(DueDiligenceQuestions)
