import React from 'react'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import classNames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import {config} from '../../config'
import {newStyle} from '../../themes/components'
import {StyledProps} from '../../common/types'
import {NotificationBarStyle} from '../../themes/components/styles/NotificationBarStyle'

const {key} = config


const lydianStyles = newStyle('NotificationBar',theme => ({
  notificationBox:{
    borderRadius: 8,
    padding: 10,
    marginBottom: 20,
    position: 'relative' as const,
  },
  notificationMessage:{
    padding: '0px 16px',
    fontSize: 17,
  },
  success: {
    backgroundColor:  theme.palette.lightgreen.color,
  },
  successMessage: {
    color:  theme.palette.secondary.dark,
  },
  error: {
    backgroundColor:  theme.palette.lightred.color,
  },
  errorMessage:{
    color:  theme.palette.secondary.dark,
  },
  info: {
    backgroundColor:  theme.palette.extralightblue.color,
  },
  infoMessage:{
    color:  theme.palette.secondary.dark,
  },
  warning: {
    backgroundColor:  theme.palette.lightyellow.color,
  },
  warningMessage: {
    color:  theme.palette.secondary.dark,
  },
  noMargin: {
    marginBottom: 0
  },
  closeIcon: {
    cursor: 'pointer' as const,
    position: 'absolute' as const,
    top: 5,
    right: 5,
    color: theme.palette.grey[400],
    fontSize: 20
  }
}))

const styles = newStyle('NotificationBar',theme => ({
  notificationBox:{
    borderRadius: 8,
    padding: 10,
    marginBottom: 20,
    position: 'relative' as const,
  },
  notificationMessage:{
    padding: '0px 16px'
  },
  success: {
    backgroundColor:  theme.palette.lightgreen.color,
  },
  successMessage: {
    color:  theme.palette.secondary.dark,
  },
  error: {
    backgroundColor:  theme.palette.lightred.color,
  },
  errorMessage:{
    color:  theme.palette.secondary.dark,
  },
  info: {
    backgroundColor:  theme.palette.lightblue.color,
  },
  infoMessage:{
    color:  theme.palette.secondary.dark,
  },
  warning: {
    backgroundColor:  theme.palette.lightyellow.color,
  },
  warningMessage: {
    color:  theme.palette.secondary.dark,
  },
  noMargin: {
    marginBottom: 0
  },
  closeIcon: {
    cursor: 'pointer' as const,
    position: 'absolute' as const,
    top: 5,
    right: 5,
    color: theme.palette.grey[400],
    fontSize: 20
  }
}))

let applyStyles = styles
if (key === 'lydian') {
  applyStyles = lydianStyles
}
type NotificationBarProps = StyledProps<NotificationBarStyle> & {
  variant?: any
  children?: any
  component?: any
  noMargin?: boolean
  status: ''| 'success' | 'successMessage' | 'error' | 'info' | 'warning'
  onClose?: () => void
}
const NotificationBar = (props: NotificationBarProps) => (
  <Paper className={classNames(props.classes.notificationBox, props.classes[props.status], (props.noMargin) && props.classes.noMargin)} elevation={0}>
    <Typography component={props.component ? props.component : 'p'} variant={props.variant ? props.variant : 'body1'} className={classNames(props.classes.notificationMessage,props.classes[`${props.status}Message`])}>
      {props.children}
    </Typography>
    {props.onClose && <CloseIcon onClick={() => props.onClose!()} className={props.classes.closeIcon}/>}
  </Paper>
)

export default withStyles(applyStyles)(NotificationBar)
