import {tauroMarketsStyleOverrides} from './overrides/tauro-markets'
import {ComponentStyleCallback, ComponentStyles, WhiteLabelStyleOverrides} from './types'
import {config} from '../../config'
import {CompanyEnumIndices} from '@bdswiss/common-enums/lib/esm/enums/companies'
import {Theme} from '@material-ui/core'


const overrides : WhiteLabelStyleOverrides = {
  tauroMarkets: tauroMarketsStyleOverrides
}
export const newStyle = <X extends keyof ComponentStyles >(key: X,style: ComponentStyleCallback<ComponentStyles[X]>) : ComponentStyleCallback<ComponentStyles[X]> => {
  const wl = config.key as CompanyEnumIndices
  if (!overrides[wl]) {
    return style
  }
  const wlOverrides = overrides[wl]!
  if (!wlOverrides[key]) {
    return style
  }
  const overrider = wlOverrides[key]! as any
  const newOne : any = (theme: Theme) => {
    const oldStyle = style(theme)
    return overrider(theme,oldStyle)
  }
  return newOne
}


