import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import messages from '../../assets/messages'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import HelpIcon from '@material-ui/icons/HelpOutline'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Tooltip from '@material-ui/core/Tooltip'
import {get, flowRight as compose} from 'lodash'
import {config} from '../../config'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import AppContext from '../Common/contexts/AppContext'
import {referAndEarnDetails} from '../../common/utils/uioptions'
import {InnerAppContext, ProvidedProps} from '../../common/types'

const styles = theme => ({
  displayFlex: {
    display: 'flex'
  },
  icon: {
    marginBottom: -3,
    marginLeft: 3,
    fontSize: 16,
    color: theme.palette.secondary.main,
  },
  cardHover: {
    width: '100%',
    '&:hover':{
      backgroundColor: theme.palette.cardBackground.color,
      cursor: 'default',
    },
  },
  opacity: {
    opacity: 0.5
  },
  inline: {
    display: 'inline-block'
  },
  pointer: {
    cursor: 'pointer',
  },
  link: {
    color: theme.palette.primary.main,
  },
  customTooltip:{
    fontSize: 12
  },
  toolTipStatus: {
    marginLeft: 79,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 7
    },
  },
  toolTipPartner: {
    marginLeft: 7
  },
})
type Props = ProvidedProps<ReturnType<typeof styles>> & {
  referralDetails: any,
  classes
  awards
  limitReached
  isIbOrAffiliate
  isVerified
}
type State = {
  open: boolean,
  openStatus: boolean,
  clickedEvent: boolean,
}
class ReferralDetails extends Component<Props,State> {
  static contextType = AppContext
  context!: InnerAppContext
  state = {
    open: false,
    openStatus: false,
    clickedEvent: false,
  }

  renderReferralLink(referralDetails, company) {
    const {t, classes, awards} = this.props
    const {currency} = referAndEarnDetails
    return <React.Fragment>
      <Grid item xs={12}>
        <Typography variant='caption' className={classes.inline}><Trans {...messages.yourPartnerLink} /></Typography>
        <ClickAwayListener onClickAway={() => this.setState({open: false})}>
          <Tooltip title={t(messages.partnerMessage.i18nKey, {company, amount: `${currency}${get(awards, 'referrerAward')}`})}
            placement="top"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => this.setState({open: false})}
            open={this.state.open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            className={classNames(classes.pointer, classes.icon)}
            classes={{tooltip: classNames(classes.customTooltip, classes.toolTipPartner)}}>
            <HelpIcon className={classes.icon} onClick={() => this.setState({open: true})}/>
          </Tooltip>
        </ClickAwayListener>
      </Grid>
      <Grid item md={9} xs={12}>
        <Typography variant='body1' className={classes.inline}>{get(referralDetails, 'link')}</Typography>
      </Grid>
      <Grid item md={3} xs={12}>
        <CopyToClipboard text={get(referralDetails, 'link')}>
          <Typography variant='body2' className={classNames(classes.link, classes.pointer)}
            onClick={()=> this.setState({clickedEvent: true})}
            onMouseLeave={()=> this.setState({clickedEvent: false})}><Trans {...messages.copyLink} /></Typography>
        </CopyToClipboard>
        <Tooltip
          open={this.state.clickedEvent}
          title={t(messages.partnerLinkCopied.i18nKey,messages.partnerLinkCopied.defaults)}
          placement="right"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={() => this.setState({clickedEvent: false})}
        ><span></span>
        </Tooltip>
      </Grid>
    </React.Fragment>
  }

  renderRerralLimitPartner(limitReached, isIbOrAffiliate) {
    const {ibPartnersLink} = config
    const {classes} = this.props

    return (<React.Fragment>
      {limitReached && <Grid item xs={12}>
        <Typography variant='h4' className={classes.inline}><Trans {...messages.referralsLimit} /></Typography>
      </Grid>}
      {!limitReached && <Grid item xs={12}>
        <Typography variant='h4' className={classes.inline}><Trans {...messages.referralAlreadyPartnerTitle} /></Typography>
      </Grid>}
      {!isIbOrAffiliate &&  <React.Fragment>
        <Grid item md={8} xs={12}>
          <Typography variant='subtitle2' className={classes.inline}><Trans {...messages.wishRefer} /></Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <Button
            onClick={() => window.open(ibPartnersLink, '_blank')}
            variant="contained"
            size="medium"
            color="primary"
            className={classes.button}
          >
            <Trans {...messages.becomeAPartner} />
          </Button>
        </Grid>
      </React.Fragment>}
      {isIbOrAffiliate && <Grid item md={8} xs={12}>
        <Typography variant='subtitle2' className={classes.inline}><Trans {...messages.referralAlreadyPartner} /></Typography>
      </Grid>}
    </React.Fragment>)
  }

  renderReferralUnverified() {
    const {classes, history} = this.props

    return <React.Fragment>
      <Grid item xs={12}>
        <Typography variant='h4' className={classes.inline}><Trans {...messages.referralLinkUnavailableTitle} /></Typography>
      </Grid>
      <Grid item md={8} xs={12}>
        <Typography variant='subtitle2' className={classes.inline}><Trans {...messages.referralLinkUnavailable} /></Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        <Button
          onClick={() => history.push('/accounts/verification')}
          variant="contained"
          size="medium"
          color="primary"
          className={classes.button}
        >
          <Trans {...messages.verifyAccount} />
        </Button>
      </Grid>
    </React.Fragment>
  }

  render() {
    const {classes, referralDetails, t, awards, limitReached, isIbOrAffiliate, isVerified} = this.props
    const {companyObject} = this.context
    const company = companyObject.whiteLabelBrand || companyObject.brandLabel
    const {minDeposit, minTrades, days, currency} = referAndEarnDetails

    return <Grid container spacing={3} >
      <Grid item xs={12} md={12} lg={6} className={classes.displayFlex}>
        <Card classes={{root: classes.cardHover}}>
          <Grid container spacing={1}>
            {!isVerified ? this.renderReferralUnverified() :
              (!limitReached && !isIbOrAffiliate ? this.renderReferralLink(referralDetails, company)
                : this.renderRerralLimitPartner(limitReached, isIbOrAffiliate))}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={6} className={classNames(classes.displayFlex, limitReached && classes.opacity)}>
        <Card classes={{root: classes.cardHover}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant='caption' className={classes.inline}><Trans {...messages.yourStatus} /></Typography>
                  <ClickAwayListener onClickAway={() => this.setState({openStatus: false})}>
                    <Tooltip title={t(messages.yourStatusMessage.i18nKey, {company, minDeposit: `${currency}${minDeposit}`, minTrades, days, amount: `${currency}${get(awards, 'referrerAward')}`})}
                      placement="top"
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => this.setState({openStatus: false})}
                      open={this.state.openStatus}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      className={classNames(classes.pointer, classes.icon)}
                      classes={{tooltip: classNames(classes.customTooltip, classes.toolTipStatus)}}>
                      <HelpIcon className={classes.icon} onClick={() => this.setState({openStatus: true})}/>
                    </Tooltip>
                  </ClickAwayListener>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='caption'><Trans {...messages.yourReward} /></Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='caption'><Trans {...messages.referralsReward} /></Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant='subtitle1'><Trans {...messages.introducer} /></Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='subtitle1'>{`${currency}${get(awards, 'referrerAward')}`}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='subtitle1'>{`${currency}${get(awards, 'referralAward')}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  }
}

export default  compose(
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(ReferralDetails)
