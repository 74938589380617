import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import PropTypes from 'prop-types'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../Common/Loading'
import {get, find, findIndex, first, map, flowRight as compose} from 'lodash'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import withStyles from '@material-ui/core/styles/withStyles'
import DocumentRoutes from './routes'
import PageTitle from '../../Common/PageTitle'
import {FullScreenDialog} from '../../Common/Dialog'
import messages from '../../../assets/messages'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {isMobile} from '../../../common/utils'

const availableTabs = [
  {key: 'upload', title: messages.upload, relativePath: '/upload'},
  {key: 'history', title: messages.history, relativePath: '/history'},
]

const styles = (theme) => ({
  main: {
    marginLeft: `${theme.sidebarWidth}`,
  },
  header: {
    marginBottom: 40,
  },
  textLink: {
    textDecoration: 'underline',
    'color':`${theme.palette.secondary.main}`,
  },
  footNote: {
    padding: 10,
  }
})
export class Documents extends Component<any,any> {

  constructor(props) {
    super(props)
    const {match, location} = props
    const currentTab = find(availableTabs, (tab) => location.pathname.indexOf(`${match.url}${tab.relativePath}`) > -1)
      || first(availableTabs)
    this.state = {
      activeTab: findIndex(availableTabs, currentTab)
    }
  }
  static propTypes = {
    viewer: PropTypes.object.isRequired,
    documents: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.object
  }
  handleTabChanged(activeTabIndex) {
    const {history, match} = this.props
    this.setState({activeTab: activeTabIndex})
    const currentTab = availableTabs[activeTabIndex]
    history.push(`${match.path}${currentTab.relativePath}`)
  }

  render() {
    const {t, loading, documentsLoading, classes, history, history: {location: {pathname}}, match: {url}} = this.props

    if (loading || documentsLoading) return <Loading />

    const subRoute = availableTabs.filter(tab => `${url}${tab.relativePath}` === pathname).length > 0
      || url === pathname

    return (
      <FullScreenDialog
        desktopOnly
        open={true}
        onClose={() => history.push('/settings/profile')}
      >
        {subRoute &&
        <PageTitle
          title={t(messages.verificationDocuments.i18nKey, messages.verificationDocuments.defaults)}
          onBack={() => history.push('/settings/profile')}>
          <Tabs
            value={this.state.activeTab}
            onChange={(_, activeTab) => this.handleTabChanged(activeTab)}
            classes={{root: classes.tabsRoot}}
            variant={isMobile()? 'scrollable' : 'standard'}
            scrollButtons='off'
          >
            {map(availableTabs, (tab) => <Tab key={tab.key} label={<Trans {...tab.title} />} />)}
          </Tabs>
        </PageTitle>}
        <DocumentRoutes />
      </FullScreenDialog>
    )
  }
}


export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => {
      const client = get(props.data, 'viewer.profileChanges[0].answers')
      return {
        error:props.data?.error,
        documentsLoading:props.data?.loading,
        client,
        documents: get(props.data, 'viewer.documents', []),
      }
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => ({
      loading:props.data?.loading,
      error:props.data?.error,
      viewer: get(props.data, 'viewer'),
    })
  }),
)(Documents)
