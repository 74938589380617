//@ts-nocheck
import React from 'react'
import QRCode from 'qrcode.react'
import PropTypes from 'prop-types'
import {graphql} from 'react-apollo'
import Grid from '@material-ui/core/Grid'
import {withRouter} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import {get, flowRight as compose} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import CopyToClipboard from 'react-copy-to-clipboard'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Amount from '../../Common/Amount'
import {Loading} from '../../Common/Loading'
import messages from '../../../assets/messages'
import PageSubTitle from '../../Common/PageSubTitle'
import {ACCOUNTS_QUERY} from '../../../graphql/queries'
import AppContext from '../../Common/contexts/AppContext'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import {isMobile} from '../../../common/utils/browser'
import {getAccountLabel} from '../../../common/utils/accounts'
import CustomTooltip from '../../Common/CustomTooltip'
import Images from '../../Common/Images'

const styles = theme => ({
  root: {
    marginTop: 30,
  },
  amount: {
    marginTop: 10,
    display: 'block',
  },
  amountCurrency: {
    fontSize: 24,
    fontWeight: 200,
    padding: '0 3px',
  },
  amountValue: {
    fontSize: 36,
    fontWeight: 200,
  },
  amountFraction: {
    fontSize: 24,
    fontWeight: 200,
  },
  marginTop: {
    marginTop: 30
  },
  financialInfoItem: {
    color: theme.palette.secondary.dark,
  },
  copyButton: {
    padding: '0',
    '&:hover':{
      backgroundColor:'transparent'
    }
  },
  tooltipText:{
    color: '#ffffff',
    fontSize:14
  },
  highlight:{
    fontWeight:400,
  },
  paddingRight:{
    paddingRight:10
  },
  link: {
    color: theme.palette.primary.main,
  },
  img : {
    width: 230,
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
  }
})

class AccountOverview extends React.Component {
  static contextType = AppContext
  state = {}

  render() {
    const {loading, clientLoading, account, classes, t} = this.props
    const {companyObject, locale, themePreference} = this.context
    if (loading || clientLoading) return <Loading />

    const productName = getAccountLabel(account, locale)

    return (
      <div className={classes.root}>
        <PageSubTitle variant='h3'
          rightAction={<img className={classes.img} src={Images[`bitwallet-${companyObject.brand}-${themePreference}.svg`]} alt='bitwallet' />}
        >
          <Trans {...messages.financialInfo} />
        </PageSubTitle>
        <Grid container>
          <Grid item xs={12} className={classes.financialInfoItem}>
            <Typography variant='caption'><Trans {...messages.balance} /></Typography>
            <Amount
              value={account.balance} currency={account.currency} locale='en-US' classes={{
                root: classes.amount,
                currency: classes.amountCurrency,
                value: classes.amountValue,
                fraction: classes.amountFraction
              }} />
          </Grid>
          <Grid item xs={12} className={classes.marginTop}>
            <PageSubTitle variant='h3'><Trans {...messages.walletAddress} /></PageSubTitle>
            <Typography variant='body2'><Trans {...messages.cryptoWalletDepositInstructionsTitle}
              values={{company: companyObject.brandLabel, productName: productName}}/>
            </Typography>
            <Typography variant='body1'><Trans {...messages.cryptoWalletDepositInstructions} values={{accountCurrency:account.currency}}
              components={[<span className={classes.highlight}>currency</span>]}/>
            </Typography>
            {/*<Typography variant='caption'>*<Trans {...messages.cryptoWalletDepositDisclaimer} /></Typography>*/}
          </Grid>
          <Grid item xs={12} className={classes.marginTop}>
            <CustomTooltip
              title={<React.Fragment>
                <Typography variant='body1' className={classes.tooltipText}>
                  {this.state.clickedEvent ? t(messages.walletCopied.i18nKey,messages.walletCopied.defaults) :
                    t(messages.clickToCopy.i18nKey, messages.clickToCopy.defaults)
                  }
                </Typography>
              </React.Fragment>}
              placement={'right'}
            >
              <CopyToClipboard text={account.walletAddress}>
                <Button className={classes.copyButton} onClick={()=> this.setState({clickedEvent: true})}
                  onMouseOut={()=> this.setState({clickedEvent: false})}>
                  <Typography variant='body2' className={classes.paddingRight}>{account.walletAddress}</Typography>
                  <FileCopyOutlined />
                </Button>
              </CopyToClipboard>
            </CustomTooltip>
            {isMobile() && this.state.clickedEvent && <Typography variant='caption'>
              {t(messages.walletCopied.i18nKey,messages.walletCopied.defaults)}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <QRCode value={account.walletAddress} className={classes.marginTop} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

AccountOverview.propTypes = {
  account: PropTypes.object,
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  withRouter,
  graphql(ACCOUNTS_QUERY, {
    options: (props) => ({variables: {id: Number(props.match.params.accountId)}}),
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      account: get(data, 'viewer.accounts.0') || {}
    })
  }),
)(AccountOverview)
