import React from 'react'
import AppContext from '../../Common/contexts/AppContext'
import {filter, flowRight as compose, get, includes, map} from 'lodash'
import {config} from '../../../config'
import {
  getCurrentTheme,
  getItem,
  getSessInfoFromLocalStorage,
  isDarkTheme,
} from '../../../common/utils'
import {languages} from '@bdswiss/common-enums'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import {Link, withRouter} from 'react-router-dom'
import FlagIcon from '../../FlagIcon'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import ArrowDown from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  root: {
    backgroundImage: '-webkit-linear-gradient(135deg, #031417 0%, #062A33 100%)',
    backgroundSize: '100% 42px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.background.default,
    width: '100%',
  },
  rootPartner: {
    backgroundColor: 'transparent',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    margin: '0 auto',
  },
  container: {
    maxWidth: theme.mainBodyWidth,
    margin:'14px auto',
  },
  menuIcon: {
    marginRight: '14px'
  },
  logo: {
    maxWidth: 230,
    maxHeight: 80,
    position: 'relative' as const,
    top: -5,
    left: -20
  },
  logoDiv: {
    flexGrow:1
  },
  languageButton: {
    width: 116,
    height: 42,
    border: '1px solid #D5D6D7',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.0468204)',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'end',
    cursor: 'pointer',
    position: 'relative' as const,
    top: 6,
  },
  fullWidth: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  link: {
    paddingLeft: 25,
    fontSize: 14,
    color: theme.palette.grey.color
  },
  arrowLink:{
    verticalAlign: 'middle',
    padding: 1,
    marginTop: '-3px',
    color: theme.palette.secondary.dark,
  },
  arrowLinkPartner:{
    verticalAlign: 'middle',
    padding: 1,
    marginTop: '-3px',
    color: theme.palette.secondary.light,
  },
  pointer:{
    cursor: 'pointer'
  }
})

class LydianHeader extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  static propTypes = {
    handleSidebarToggle: PropTypes.func,
  }

  closeLanguage() {
    if (this.props.location.pathname==='/change-language' || this.props.location.pathname==='/language') {
      if (get(this.props.history.location, 'state.prevPath') === '/login') {
        this.props.history.push('/accounts')
      } else {
        this.props.history.goBack()
      }
    }
  }

  render() {
    const {classes, handleSidebarToggle, history, linkTo, outer, customBackground} = this.props
    const {logoFileName, logoWhiteFileName, name: logoAlt} = config
    let locale = (this.context.clientType) ? this.context.locale:getItem('locale','en')

    if (!includes(map(filter(languages, (lang) => !lang.disabled && !!lang.client), 'value'), locale)) {
      locale = 'en'
    }

    const themePreference = getCurrentTheme(this.context)

    let logo = ''
    const logoAsset = customBackground ||isDarkTheme(themePreference) ? logoWhiteFileName : logoFileName
    try {
      logo = require(`../../../assets/images/${logoAsset}`).default
    } catch (e)
    {
      //
    }
    const isMobileView = this.props.location.pathname==='/more'
    const onClickLink = linkTo || '/accounts'

    return (<AppBar position="static"
      elevation={0}
      color="default"
      className={customBackground ? classes.rootPartner : classes.root}
    >
      <Toolbar>
        <Hidden mdUp>
          {/* eslint-disable-next-line eqeqeq */}
          {!isMobileView && getSessInfoFromLocalStorage()==true && <IconButton
            aria-label="Open drawer"
            className={classes.menuIcon}
            onClick={(e) => handleSidebarToggle && handleSidebarToggle(e)}
          >
            <MenuIcon />
          </IconButton>}
        </Hidden>
        <Grid className={classes.container} container alignItems='center'>
          <Grid item className={classes.logoDiv}>
            <img src={logo} alt={logoAlt} className={classNames(classes.logo,classes.pointer)} onClick={()=> history.push(onClickLink)} />
          </Grid>
          <Grid item className={classes.languageButton}>
            <Link to={{pathname: (outer)?'/language':'/change-language', state: {prevLocation: this.props.location}}}
              className={classes.fullWidth} onClick={() => {this.closeLanguage()}}>
              <Grid lang={locale}>
                <FlagIcon
                  code={languages[locale].flag}
                  className={classes.link}
                  size={'lg'}
                />
                <span className={customBackground ? classes.arrowLinkPartner : classes.arrowLink}>
                  {isWidthDown('sm', this.props.width) ? ' ' : languages[locale].native}</span>
                <ArrowDown className={customBackground ? classes.arrowLinkPartner : classes.arrowLink} />
              </Grid>
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>)
  }
}


export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
  withWidth()
)(LydianHeader)
