//@ts-nocheck
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {flowRight as compose} from 'lodash'
import {frontends} from '@bdswiss/common-enums'
import {getPlatform, safeParseJSON} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'

class BridgerPayProvider extends Component {

  state = {}

  doCreateDeposit() {
    const {account, providerProperties: {provider, paymentKey}, amount, onError, useVirtualPaymentPage, showIframe, bonusAmount, bonusTerms} = this.props

    this.setState({creatingDeposit: true})

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        const data = safeParseJSON(url)

        const epElement = document.createElement('script')
        epElement.id = 'ep-script'
        epElement.type = 'text/javascript'
        epElement.src = data.url
        epElement.setAttribute('data-cashier-key', data.cashierKey)
        epElement.setAttribute('data-cashier-token', data.token)
        epElement.setAttribute('data-theme', 'transparent')
        document.getElementById('deposit-methods-container').appendChild(epElement)

        this.setState({creatingDeposit: false})
        showIframe()
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  render() {
    const {creatingDeposit} = this.state
    return (
      <PaymentActionButton
        loading={creatingDeposit}
        onClick={() => this.doCreateDeposit()}
      />
    )
  }
}

BridgerPayProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  showIframe: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withCreateDeposit
)(BridgerPayProvider)
