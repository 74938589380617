import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import {flowRight as compose} from 'lodash'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Done from '@material-ui/icons/Done'
import Pending from '@material-ui/icons/Schedule'
import Missing from '@material-ui/icons/Clear'

const styles = theme => ({
  statusIcon: {
    'vertical-align':'middle',
    'font-size': '20px',
  },
  doneIcon: {
    'color':theme.palette.green.color
  },
  pendingIcon: {
    'color': theme.palette.yellow.color
  },
})

class AccountVerificationIcon extends Component {
  static propTypes = {
    status: PropTypes.oneOf(['missing', 'pending', 'done']),
  }

  render() {
    const {status, classes} = this.props

    switch (status) {
      case 'missing':
        return <Missing color='error' className={classes.statusIcon} />
      case 'pending':
        return <Pending className={classNames({[classes.statusIcon]: true, [classes.pendingIcon]: true})} />
      case 'done':
        return <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})} />
      default: return
    }
  }
}

export default compose(
  withNamespaces(),
  withStyles( styles, {withTheme: true}),
)(AccountVerificationIcon)
