import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AppContext from '../Common/contexts/AppContext'
import {withRouter, Link} from 'react-router-dom'
import classNames from 'classnames'
import {flowRight as compose, get} from 'lodash'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import messages from '../../assets/messages'
import Images from './Images'
import PageTitle from './PageTitle'
import PageSubTitle from './PageSubTitle'
import {InnerAppContext} from '../../common/types'

const closeImageLight = Images['icon-close-light.svg']
const closeImageDark = Images['icon-close-dark.svg']

const styles = theme => ({
  content: {
    textAlign:'center' as const,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      width: 960,
      margin: 'auto'
    },
  },
  image: {
    width: 300,
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    }
  },
  centerItem: {
    textAlign: 'center' as const,
  },
  iconClose: {
    width: 46,
    height: 30,
    position: 'relative' as const,
    left: 7,
    backgroundSize: '30px 30px',
    paddingLeft: 10,
    display: 'block',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer'
  },
  darkClose: {
    backgroundImage: `url(${closeImageDark})`,
  },
  lightClose: {
    backgroundImage: `url(${closeImageLight})`,
  },
  children: {
    textAlign: 'center' as const,
    fontSize:16,
    fontWeight:300,
    color: theme.palette.secondary.dark,
  },
  appBar: {
    boxShadow: 'none',
  },
  fullWidth:{
    [theme.breakpoints.down('xs')]: {
      width: '97%',
    }
  },
  linkAction:{
    paddingTop:8,
    display:'block'
  },
  cancelButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: 10
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10
    }
  },
})
class UiNotification extends Component<any,any> {
    static propTypes = {
      status: PropTypes.oneOf(['success', 'failure', 'pending', 'skip', 'tradingAlerts','startTrading', 'filters', 'referral']),
      type: PropTypes.string.isRequired,
      open: PropTypes.bool.isRequired,
      onClose: PropTypes.func.isRequired,
      buttonMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      hideButton: PropTypes.bool,
      subTitle: PropTypes.object
    }
    static contextType = AppContext
    context!: InnerAppContext
    render() {
      const {classes, children, status, type, onClose, width, buttonMessage, buttonAction, linkAction, open,
        hideButton, subTitle, linkActionMessage, title, titleComponents, location, subTitleChildren, cancelButton, onCancel, content} = this.props
      const {themePreference} = this.context
      const buttonOnClick = buttonAction || onClose
      const bgImage = Images[`${type}-${status}-${themePreference}.png`] || Images[`${type}-${themePreference}.svg`] || Images[`${type}-${status}-${themePreference}.svg`]
      const uinotification = !['startTrading', 'filters'].includes(status) ? 'uinotification' : ''
      const buttonOnClose = onCancel || onClose

      return (
        <Dialog
          fullScreen
          open={open}
          onClose={() => onClose(status)}
        >
          <Grid container spacing={3} alignItems="center" alignContent='center' className={classes.content}>
            <Grid container item justifyContent="flex-end">
              <div className={classNames(classes.iconClose, classes[`${themePreference}Close`])} onClick={() => onClose(status)} />
            </Grid>
            <Grid item xs={12} className={classes.centerItem}>
              <PageTitle modal {...{uinotification}}>
                <Trans {...messages[title || status]} components={titleComponents}/>
              </PageTitle>
              {subTitle && <PageSubTitle>{subTitle}</PageSubTitle>}
              {subTitleChildren && <Grid item xs={12} className={classes.children}>
                {subTitleChildren}
              </Grid>}
            </Grid>
            <Grid item xs={12} className={classes.centerItem}>
              {
                bgImage &&
                <img
                  className={classes.image}
                  src={bgImage}
                  alt={status}
                />
              }
            </Grid>
            <Grid item xs={12} className={classes.children}>
              {content || children}
            </Grid>
            {!hideButton && <Grid item xs={12}>
              {cancelButton && <Button
                fullWidth={isWidthDown('sm', width)}
                color="secondary" size="large"
                variant="contained"
                onClick={() => buttonOnClose()}
                className={classNames(classes.cancelButton, classes.fullWidth)}
              >
                {cancelButton}
              </Button>}
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => buttonOnClick(status)}
                fullWidth={isWidthDown('sm', width)}
                className={classes.fullWidth}
              >
                {buttonMessage}
              </Button>
              {linkAction &&
              <Link to={linkAction} className={classes.linkAction} onClick={() => linkAction === get(location, 'pathname') && onClose(status)}>
                <Typography variant="body2" color="primary">
                  {linkActionMessage}
                </Typography>
              </Link>
              }
            </Grid>
            }
          </Grid>
        </Dialog>
      )
    }
}


export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth(),
  withRouter,
  withNamespaces(),
)(UiNotification)
