import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import {map, get, flowRight as compose} from 'lodash'
import Grid from '@material-ui/core/Grid'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import {safeParseJSON} from '../../../common/utils/general'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'

const styles = theme => ({
  rulesDiv:{
    padding: 20,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 13,
  },
  rulesList: {
    marginLeft: 11,
    color: theme.palette.black.color,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginLeft: 0,
    }
  },
  infoIcon: {
    marginBottom: -6,
    marginRight: 10,
  },
  blueIcon: {
    color: theme.palette.primary.main,
  },
  tableHead :{
    fontWeight: 500,
    fontSize: '16px',
    textAlign: 'center' as const,
  },
  displayInline: {
    display: 'inline-flex',
  },
  textCentered: {
    textAlign: 'center' as const
  },
  prizeText: {
    paddingTop: 3,
    width:113,
    margin: 'auto'
  },
})

class CompetitionRules extends Component<any,any> {

  render() {
    const {classes, competition, rules, t} = this.props
    const prizes = map(safeParseJSON(get(competition, 'prizes')), (value, key) => ({key, value}))
    return  <Grid container className={classes.rulesDiv}>
      <Grid item xs={12}>
        <InfoIcon className={classNames(classes.infoIcon, classes.blueIcon)}/>
        <Typography variant="subtitle1" className={classes.displayInline}> <Trans {...messages.competitionRules} /> </Typography>
      </Grid>
      <Grid item xs={12}>
        <ul className={classes.rulesList}>
          {map(rules, (value, key) => <li key={key}>
            <Typography variant="body1" className={classes.displayInline}>
              {value.label}
            </Typography>
          </li>)}
        </ul>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.textCentered}>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>{t(messages.competitionPosition.i18nKey, messages.competitionPosition.defaults)}</TableCell>
                <TableCell className={classes.tableHead}>{t(messages.prize.i18nKey, messages.prize.defaults)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(prizes, (row, index) =>
                row.value !== '' ?
                  <TableRow key={index} id={`${index}`}>
                    <TableCell className={classes.textCentered}>{row.key}</TableCell>
                    <TableCell className={classes.textCentered}>{row.value}</TableCell>
                  </TableRow> : null

              )}
            </TableBody>
          </Table>

        </Grid>
      </Grid>
    </Grid>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(CompetitionRules)
