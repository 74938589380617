import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import {graphql} from 'react-apollo'
import {Link, Redirect} from 'react-router-dom'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import {depositVendors, safechargePaymentTypes, fundingCategories, accountTypes} from '@bdswiss/common-enums'
import {get, find, first, groupBy, keys, filter, size, isEmpty, countBy, flowRight as compose, max, includes} from 'lodash'
import {Loading} from '../../Common/Loading'
import {getItem, isMobile, scrollElementIntoView, safeParseJSON} from '../../../common/utils'
import messages from '../../../assets/messages'
import {COUNTRY_PAYMENT_OPTIONS_QUERY, CLIENT_DATA_QUERY, PAYMENTS_ACCOUNTS_QUERY,
  CONFIG_QUERY} from '../../../graphql/queries'
import AppContext from '../../Common/contexts/AppContext'
import AccountAmountSelection from './AccountAmountSelection'
import PaymentMethodSelection from './PaymentMethodSelection'
import {PaymentProviderFactory} from './PaymentProviders'
import {blockedDepositAccount} from '../../../common/utils/accounts'
import {withStyles} from '@material-ui/core/styles'
import NotificationBar from '../../Common/NotificationBar'
import DateOfBirthForm from '../../Common/DateOfBirthForm'
import ClientNotificationBar from '../../Accounts/ClientNotificationBar'
import {config} from '../../../config'
import BonusesTermsAndConditionsModal from './BonusesTermsAndConditionsModal'
import {InnerAppContext} from '../../../common/types'

const styles = (theme)=> ({
  paymentProviderSection:{
    padding: isMobile() ? '12px' : '100px 12px 12px 12px !important'
  },
  link: {
    color: theme.palette.primary.main,
  },
  threeCCsWarning: {
    color: theme.palette.red.color,
    marginTop: 15,
    textAlign: 'center' as const,
  },
})

const DefaultPreFilledAmounts = [100, 200, 500, 1000, 2000]
const PaymentMethodProviderInfoGridId = 'payment-provider-grid'

// Potential Golf Scripting Entry :)
const toNextHundredth = (v) => ((v  > 0) && ((v % 100) === 0)) ? v : (v + (100 - (v % 100)))

export class Deposit extends Component<any,any> {

  static contextType = AppContext
  paymentInputSectionRef: React.Ref<any>
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      amount: first(DefaultPreFilledAmounts),
      currentStep: 0,
      showIframe: false,
      bonus: false,
      showMaximumEquityWarning: false,
      bonusesModalOpen: false,
    }
    this.paymentInputSectionRef = React.createRef()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const derivedState : any = {}
    const {match: {params: urlParams}, history} = nextProps
    let selectedAccountId = Number(get(urlParams, 'accountId'))

    if (nextProps.accounts) {
      if (selectedAccountId && filter(nextProps.accounts, (a) => a.id === selectedAccountId && a.isDemo).length > 0 ) {
        derivedState.liveAccounts = filter(nextProps.accounts, (a) =>  !blockedDepositAccount(a))
      } else {
        derivedState.liveAccounts = filter(nextProps.accounts, (a) => !a.isDemo && !blockedDepositAccount(a))
      }
    }

    if (nextProps.countryPaymentOptions) {
      const allPaymentProviders = get(nextProps.countryPaymentOptions, 'data', [])
      const safechargeProvider = find(allPaymentProviders, (o) =>
        (get(o, 'paymentOption.provider') === depositVendors.safecharge.key) && get(o, 'paymentOption.paymentKey') === safechargePaymentTypes.creditCard.value )
      if (safechargeProvider && !isMobile()) {
        derivedState.selectedPaymentMethodId = safechargeProvider.id
      }
    }

    if (!prevState.selectedAccountId && size(derivedState.liveAccounts) > 0) {
      if (!find(derivedState.liveAccounts, {id: selectedAccountId})) {
        selectedAccountId = get(first(derivedState.liveAccounts), 'id') as any
      }
      derivedState.selectedAccountId = selectedAccountId
      history.push(`/transactions/${selectedAccountId}/deposit`)
      return derivedState
    }
    return null
  }

  handleSelectedAccountChanged(newSelectedAccountId, maximumAccountEquity) {
    const {history} = this.props
    const selectedAccount = find(this.props.accounts, {id: newSelectedAccountId})
    const accountType = accountTypes[selectedAccount.__typename]
    const maxEquity = maximumAccountEquity[accountType.subCategory]
    const equity = get(selectedAccount, 'marginInfo.equity', 0)

    this.setState({
      selectedAccountId: newSelectedAccountId,
      showMaximumEquityWarning: maxEquity && (maxEquity - equity) < Number(this.state.amount),
    })
    history.push(`/transactions/${newSelectedAccountId}/deposit`)
  }

  handleSelectedAmountChanged(newAmount, maximumAccountEquity, ignoreEquityCheck, showModal) {
    const selectedAccount = find(this.props.accounts, {id: this.state.selectedAccountId})
    const accountType = accountTypes[selectedAccount.__typename]
    const maxEquity = maximumAccountEquity[accountType.subCategory]
    const equity = get(selectedAccount, 'marginInfo.equity', 0)

    if (this.state.bonus && showModal) {
      return this.setState({
        bonusesModalOpen: true,
        showMaximumEquityWarning: maxEquity && (maxEquity - equity) < Number(newAmount) && !ignoreEquityCheck
      })
    }

    this.setState({
      amount: newAmount,
      showMaximumEquityWarning: maxEquity && (maxEquity - equity) < Number(newAmount) && !ignoreEquityCheck,
      ...(ignoreEquityCheck ? {currentStep: this.state.currentStep + 1} : {})
    })
  }

  handlePaymentMethodSelected(paymentMethod) {
    this.setState({selectedPaymentMethodId: paymentMethod}, () => scrollElementIntoView(PaymentMethodProviderInfoGridId, 250))
  }

  handlePaymentError() {
    const notificationParams = {
      type: 'payment',
      status: 'failure',
      buttonMessage: <Trans {...messages.close} />,
      content: <Trans {...messages.depositErrorRetry} />
    }
    this.context.showNotification(notificationParams)
  }

  generatedPrefilledAmounts(minimumDeposit) {
    const nextHundredth = toNextHundredth(minimumDeposit)
    const minThousands = nextHundredth <= 1000
    return [
      nextHundredth,
      nextHundredth * 2,
      nextHundredth * (minThousands ? 5 : 3),
      nextHundredth * (minThousands ? 10 : 4),
      nextHundredth * (minThousands ? 20 : 5),
    ]
  }

  appropTestMessage(accounts) {
    return <ClientNotificationBar accounts={accounts} />
  }

  onShowIframe = () => {
    this.setState({showIframe: true})
  }

  iframeGrid = () => <Grid item xs={12} id="deposit-methods-container" />
  checkIfClientCanMakePayment = (paymentProviders) => {
    const {selectedPaymentMethodId} = this.state
    const {viewer} = this.props
    const [providerObj] = paymentProviders.filter((paymentMethod) => paymentMethod.id === selectedPaymentMethodId)

    if (providerObj && providerObj.paymentOption.clientAddressRequired) {
      const {address: {city, country, line1, region, zip}} = viewer
      return !!(city && country && line1 && region && zip)
    }

    return true
  }

  acceptBonusTerms() {
    this.setState((prev) => ({
      currentStep: prev.currentStep + 1,
      showMaximumEquityWarning: false ,
      bonusesModalOpen: false
    }))
  }

  discardBonusTerms() {
    this.setState({bonus: false, bonusesModalOpen: false})
  }

  handleNextStep() {
    const {bonus, bonusesModalOpen, currentStep} = this.state
    if (!bonus && !bonusesModalOpen) {
      return this.setState({currentStep: currentStep + 1, showMaximumEquityWarning: false})
    }

    this.setState({bonusesModalOpen: true})
  }

  render() {
    const {loading, viewerLoading, accountsLoading, configLoading, accounts, viewer, countryPaymentOptions,
      classes, configVariables, history} = this.props

    if (loading || viewerLoading || accountsLoading || configLoading) return <Loading />
    const {currentStep, amount, selectedAccountId, selectedPaymentMethodId, liveAccounts, showIframe, bonus, showMaximumEquityWarning, bonusesModalOpen} = this.state
    const {blockedDeposit} = this.context
    const {countriesBonusTerms} = config
    const {minimumDeposits, ftdDate} = viewer
    const minimumDepositDefault = get(configVariables,'minimumDepositDefault')
    const maximumAccountEquity = safeParseJSON(get(configVariables, 'maximumAccountEquity'))
    const locale = get(viewer, 'locale') || getItem('locale', 'en')
    const selectedAccount = find(accounts, {id: selectedAccountId})
    const accountMinimumDeposit = (get(selectedAccount,'isReadOnly') && Number(get(selectedAccount, 'minimumDeposit')))
      || get(find(minimumDeposits, {currency: get(selectedAccount, 'currency')}), 'amount', 0)
    const preFilledAmounts = this.generatedPrefilledAmounts(accountMinimumDeposit)
    const allPaymentProviders = get(countryPaymentOptions, 'data', [])
    const avaliablePaymentProviders = filter(allPaymentProviders, (p) => PaymentProviderFactory.hasImplementation(get(p, 'paymentOption.provider')))
    const selectedPaymentOption = find(get(countryPaymentOptions, 'data'), {id: selectedPaymentMethodId})
    const PaymentProvider = PaymentProviderFactory.get(get(selectedPaymentOption, 'paymentOption.provider'),get(selectedPaymentOption, 'paymentOption.paymentKey'))

    const allowedDeposit = !get(viewer,'verificationActions.blockDeposit')
    const missingDob = viewer && isEmpty(viewer.birthday)

    const totalCCsUsed = countBy(viewer.paymentMethods, (p) => p.fundingCategory === fundingCategories.card.key &&
      (p.details || '-') !== '-'
    ).true
    const provider = get(selectedPaymentOption, 'paymentOption.provider')
    const paymentKey = get(selectedPaymentOption, 'paymentOption.paymentKey')
    const paymentOption = find(get(depositVendors[provider], 'options') || {}, {value: paymentKey}) ||
    find(get(depositVendors[provider], 'options') || {}, {key: paymentKey})
    const fundingCategory = get(depositVendors[provider], 'fundingCategory') || get(paymentOption, 'fundingCategory')

    /*AccountTypes without Deposit*/
    if (blockedDeposit) {
      return (<Redirect to='/transactions/withdraw' />)
    }

    if (showIframe) {
      return (
        <Grid container spacing={0}>
          {this.iframeGrid()}
        </Grid>
      )
    }
    const eligibleBonus = get(viewer, 'eligibleBonus')
    const bonusAmount = amount && amount * get(eligibleBonus, 'percentage')
    const finalBonusAmount = bonusAmount < get(eligibleBonus, 'maxAmount') ? bonusAmount : eligibleBonus.maxAmount
    const sendBonus = get(eligibleBonus, 'eligibleBonus') && get(selectedAccount, 'eligibleBonus') && bonus
    const bonusCountry = find(countriesBonusTerms, (bonus) => includes(bonus.countries, get(viewer, 'address.country')))
    const bonusTerms =  sendBonus && get((bonusCountry || config.weblinks), 'bonusTerms').replace('{lang}', locale)

    const accountType = accountTypes[selectedAccount.__typename]
    const maxEquity = maximumAccountEquity[accountType.subCategory]
    const equity = get(selectedAccount, 'marginInfo.equity', 0)
    const showSelectAmountEquityWarning = maxEquity && (max(preFilledAmounts) + equity) > Number(maxEquity) &&
      !selectedAccount.leverageChangedBasedOnEquity
    const disabledMethod = !this.checkIfClientCanMakePayment(avaliablePaymentProviders)

    return (
      <Grid container spacing={0}>
        {isEmpty(liveAccounts) && <NotificationBar status="info">
          <Trans {...messages.noAccounts} />
          <Link to="/accounts/add-account" className={classes.link}> <Trans {...messages.addNewAccount} /> </Link>
        </NotificationBar>}
        {this.iframeGrid()}
        { !allowedDeposit && this.appropTestMessage(accounts)}
        { disabledMethod && <NotificationBar status="error">
          <Trans {...messages.completeResidentialAddress} />
          <Link to="/settings/profile/personal-profile/details" className={classes.link}> <Trans {...messages.fillAddressInfo} /> </Link>
        </NotificationBar>
        }
        {
          currentStep === 0 && !isEmpty(liveAccounts) && allowedDeposit &&
          <Grid item xs={12}>
            <AccountAmountSelection
              accounts={liveAccounts}
              selectedAccountId={selectedAccountId}
              amount={amount}
              onSelectedAccountChanged={(accountId) => this.handleSelectedAccountChanged(accountId, maximumAccountEquity)}
              onAmountChanged={(newAmount, ignoreEquityCheck, showModal) =>
                this.handleSelectedAmountChanged(newAmount, maximumAccountEquity, ignoreEquityCheck, showModal)
              }
              showMaximumEquityWarning={showMaximumEquityWarning && !selectedAccount.leverageChangedBasedOnEquity}
              showSelectAmountEquityWarning={showSelectAmountEquityWarning}
              onNextStep={() => this.handleNextStep()}
              preFilledAmounts={preFilledAmounts}
              minimumDeposit={accountMinimumDeposit}
              locale={locale}
              ftdDate={ftdDate}
              minimumDepositDefault={Number(get(safeParseJSON(minimumDepositDefault),'USD', 0))}
              maximumAccountEquity={maximumAccountEquity}
              eligibleBonus={eligibleBonus}
              bonusAmount={finalBonusAmount}
              bonus={bonus}
              changeSwitch={() => this.setState({bonus: !bonus})}
              country={get(viewer, 'address.country') || ''}
              bonusTerms={bonusTerms}
            />
          </Grid>
        }
        {
          currentStep === 1 &&
          <Grid item container xs={12} direction='row' spacing={3}>
            <Grid item  xs={12} sm={8} lg={6}>
              <PaymentMethodSelection
                account={selectedAccount}
                locale={locale}
                amount={amount}
                countryPaymentOptions={avaliablePaymentProviders}
                selectedPaymentMethodId={selectedPaymentMethodId}
                onPaymentMethodSelected={(paymentMethod) => this.handlePaymentMethodSelected(paymentMethod) }
                goBack={() => this.setState({currentStep: currentStep-1})}
                bonusAmount={sendBonus && finalBonusAmount}
              />
            </Grid>
            { missingDob &&
            <Grid id={PaymentMethodProviderInfoGridId} item  xs={12} sm={8} lg={6} className={classes.paymentProviderSection}>
              <DateOfBirthForm />
            </Grid>
            }
            { !missingDob && PaymentProvider &&
              <Grid id={PaymentMethodProviderInfoGridId} item  xs={12} sm={8} lg={6} className={classes.paymentProviderSection}>
                <PaymentProvider
                  key={selectedPaymentMethodId}
                  viewer={viewer}
                  account={selectedAccount}
                  amount={Number(amount)}
                  providerProperties={get(selectedPaymentOption, 'paymentOption')}
                  onError={(e) => this.handlePaymentError()}
                  showIframe={() => this.onShowIframe()}
                  history={history}
                  bonusAmount={sendBonus && finalBonusAmount}
                  bonusTerms={bonusTerms}
                  disablePayment={disabledMethod}
                />
                {fundingCategory === fundingCategories.card.value && totalCCsUsed >= 2 &&
                  <Typography className={classes.threeCCsWarning} variant='caption'>
                    <Trans {...messages.threeCCsWarning} />
                  </Typography>
                }
              </Grid>
            }
          </Grid>
        }
        <BonusesTermsAndConditionsModal
          open={bonusesModalOpen && bonus}
          onSubmit={this.acceptBonusTerms.bind(this)}
          onCancel={this.discardBonusTerms.bind(this)}
        />
      </Grid>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(COUNTRY_PAYMENT_OPTIONS_QUERY, {
    props: ({data: {error, loading}, data}:any) => {
      const allCountryPaymentOptions = groupBy(get(data,'countryPaymentOptions'), 'country')
      const viewerCountryKey : any = find(keys(allCountryPaymentOptions), (k) => k !== '_default')
      const countryPaymentOptions = allCountryPaymentOptions[viewerCountryKey] || allCountryPaymentOptions._default
      return {
        countryPaymentOptions: {
          data: countryPaymentOptions,
          loading,
          error
        }
      }
    }
  }),
  graphql(PAYMENTS_ACCOUNTS_QUERY, {
    props: ({data: {error, loading: accountsLoading}, data}:any) => ({
      error,
      accountsLoading,
      accounts: get(data, 'viewer.accounts'),
    })
  }),
  graphql(CONFIG_QUERY, {
    props: ({data: {error, loading: configLoading}, data}:any) => ({
      error,
      configLoading,
      configVariables: get(data, 'config'),
    })
  }),
  graphql(CLIENT_DATA_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading: viewerLoading}, data}:any) => {
      const viewer = get(data, 'viewer')
      return {
        error,
        viewerLoading,
        viewer,
      }
    }
  })
)(Deposit)
