import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import {withNamespaces} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import HeaderDialog from '../../Common/Dialog/HeaderDialog'
import {isMobile} from '../../../common/utils/browser'
import AppropriatenessTest from '../../Settings/Profile/AppropriatenessTest'
import {signupSteps} from '@bdswiss/common-enums'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import withStyles from '@material-ui/core/styles/withStyles'
import AppContext from '../../Common/contexts/AppContext'
import Hidden from '@material-ui/core/Hidden'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  root: {
    paddingTop: 40,
  },
  steps:{
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'auto',
    minHeight: '100%',
    position: 'relative' as const,
  },
})

class RegisterStep2 extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext

  getSteps() {
    const {locale, companyObject} = this.context
    const steps = []

    for (const step of companyObject.registrationSteps!) {
      //@ts-ignore
      steps.push(signupSteps[step].localization.t(locale))
    }

    return steps
  }

  steps() {
    const {classes} = this.props
    const steps = this.getSteps()
    return (
      <div>
        <Stepper activeStep={1} alternativeLabel classes={{root:classes.steps}}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    )
  }

  render() {
    const {classes} = this.props

    return (
      <div className={classes.mainBody}>
        <HeaderDialog />
        <Grid container>
          {!isMobile() && <Hidden xsDown>
            <Grid item sm={3} />
          </Hidden>}
          <Grid item sm={8} className={classes.root}>
            <Grid container>
              <Grid item xs={12}>
                {this.steps()}
              </Grid>
              <Grid item xs={12}>
                <AppropriatenessTest register={true}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(RegisterStep2)
