import React from 'react'
import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    ...theme.typography.body1,
    lineHeight: '2.2rem',
    color: theme.palette.secondary.main,
    fontSize: 15,
  },
  navLink: {
    display: 'block'
  },
  selected: {
    [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']: `3px solid ${theme.palette.lightgold.color}`,
    color:theme.palette.secondary.dark,
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: '0px'
  }
})

const isActiveLink = (match, location, isIndex) =>
  (!!match || (location && location.pathname === '/' && isIndex))


class LydianListItemLink extends React.Component<any,any> {
  static propTypes = {
    classesItem: PropTypes.object,
    classes: PropTypes.object.isRequired,
    to: PropTypes.string.isRequired,
    isIndex: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node
  }

  render() {
    const {classes, to, children, onClick, isIndex, classesItem} = this.props
    return (
      <ListItem component={NavLink} to={to} className={classNames(classes.navLink,classes.root, classesItem)} activeClassName={classes.selected} isActive={(m, l) => isActiveLink(m, l, isIndex)}
        button
        onClick={onClick}
      >{children}</ListItem>
    )
  }
}

export default withStyles(styles, {withTheme: true})(LydianListItemLink)
