import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {get, some, flowRight as compose} from 'lodash'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {CLIENT_DATA_QUERY, CONFIG_QUERY} from '../../graphql/queries'
import {PHONE_VERIFICATION_MUTATION} from '../../graphql/mutations'
import {graphql} from 'react-apollo'
import {Loading} from '../Common/Loading'
import {FullScreenDialog} from '../Common/Dialog'
import PageTitle from '../Common/PageTitle'
import messages from '../../assets/messages'
import {Typography} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import LoadingButton from '../Common/LoadingButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import {withRouter} from 'react-router-dom'
import TextsmsOutlined from '@material-ui/icons/TextsmsOutlined'
import CallOutlined from '@material-ui/icons/CallOutlined'
import Button from '@material-ui/core/Button'
import {config} from '../../config'
import AppContext from '../Common/contexts/AppContext'
import {removeItem} from '../../common/utils/localStorage'
import {phoneVerificationTypes} from '@bdswiss/common-enums'
import {callerNumber} from '../../common/utils/variables'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  errorMessage:{
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400,
    fontSize: 16
  },
  chip: {
    padding: 10,
    cursor: 'pointer'
  },
  messageIcon:{
    marginRight: 30,
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    }
  },
  textCenter:{
    textAlign: 'center' as const
  },
  button:{
    marginTop: -15
  },
  infoText:{
    fontSize: '0.75rem',
    color: theme.palette.grey.color
  }
})

class AwaitingVerification extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      form:{
        code:'',
      },
      errors: {},
      status: '',
      showPhone:false,
      loading: false,
      phoneVerificationId: '',
      loadingSms:false
    }
  }

  handleChange (name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      }
    }))
  }

  handleSubmit() {
    const {form:{code}} = this.state
    const {phoneVerificationId} = this.props
    const errors = this.validateCode(code)

    if (some(errors)) return

    this.setState({loadingSms: true})
    this.props.phoneVerification({variables: {phoneVerificationId, code}})
      .then((res) => {
        this.setState({loadingSms: false})
        removeItem(`smsObject${this.props.viewer.id}`)
        if (res['data']['verifyPhone']) {
          this.setState({status: 'success'})
          window.location.href = '/accounts'
        }
        else
          this.setState({status: 'failure'})
      })
      .catch((err) => {
        this.setState({loadingSms: false, status:'failure', submitError: 'unableToSend'})
      })
  }

  validateCode(code) {
    const errors = {}
    errors['code'] = !RegExp(/^[0-9]{4}$/).test(code)
    this.setState({errors})
    return errors
  }

  closeDialog() {
    this.context.logout()
  }

  render() {
    const {classes, loading, t, secondsToTime, checkTime, disableSend, handleChangeParent, statusSms, showPhone, submitTime, smsError, configVariables} = this.props
    const {weblinks: {contact}} = config
    const {locale} = this.context
    const textToSpeechEnabled = get(configVariables, 'textToSpeechVerificationEnabled')

    if (loading) return <Loading />
    const {form: {code}, submitError, status, loadingSms} = this.state

    return <FullScreenDialog
      desktopOnly
      open
      onClose={() => this.closeDialog()}
    >
      <Grid container spacing={3} className={classes.textCenter} alignItems="center" justifyContent="center">
        <PageTitle onBack={() => this.closeDialog()}>
          <Trans {...messages.awaitingVerification} />
        </PageTitle>
        <Grid container item xs={12} spacing={3} alignItems="center" justifyContent="center" className={classes.textCenter}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans {...messages.smsCode} />
            </Typography>
          </Grid>
          <Grid item xs={9} sm={9} md={4}>
            <TextField
              id="code"
              name="code"
              label={t(messages.enterCode.i18nKey, messages.enterCode.defaults)}
              fullWidth
              autoComplete="code"
              error={this.state.errors.code}
              value={code}
              InputLabelProps={{classes: {root: classes.textField}}}
              onChange={(e) => {
                this.handleChange('code', e.target.value)
                this.validateCode(e.target.value)
              }}
            />
          </Grid>
          {(statusSms!=='failure') && <Grid item xs={12} sm={12} className={classes.button}>
            <LoadingButton
              id='loadingButton'
              onClick={() => this.handleSubmit()}
              disabled={loadingSms}
              status={status}
            ><Trans {...messages.proceedSecure} />
            </LoadingButton>
            {(status==='failure') && <Grid item xs={12}>
              <FormHelperText className={classes.errorMessage}>{t(messages.wrongCode.i18nKey, messages.wrongCode.defaults)}</FormHelperText>
            </Grid>}
          </Grid>}
          {(status==='failure' || statusSms==='failure') && messages[submitError || smsError] && <Grid item xs={12} sm={12}>
            <FormHelperText className={classes.errorMessage}>
              {t(messages[submitError || smsError].i18nKey, messages[submitError || smsError].defaults)}
            </FormHelperText>
          </Grid>}
          <Grid item xs={12}>
            <span className={classes.link}
              onClick={() => {
                handleChangeParent('showPhone',!showPhone)
              }}>
              <Trans {...messages.changePhoneNumber} />
            </span>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => checkTime({type: phoneVerificationTypes.sms.key})}
            className={classes.chip}
            disabled={disableSend}
            color="secondary"
            variant="contained"
          >
            <TextsmsOutlined className={classes.messageIcon}/>
            {t(messages.resendSms.i18nKey, messages.resendSms.defaults)}{disableSend && `: ${secondsToTime(submitTime)}`}
          </Button>
        </Grid>
        {textToSpeechEnabled && <Grid item xs={12}>
          <Button
            onClick={() => checkTime({type: phoneVerificationTypes.textToSpeech.key})}
            className={classes.chip}
            disabled={disableSend}
            color="secondary"
            variant="contained"
          >
            <CallOutlined className={classes.messageIcon}/>
            {t(messages.call.i18nKey, messages.call.defaults)}{disableSend && `: ${secondsToTime(submitTime)}`}
          </Button>
          <Grid item xs={12}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={5} className={classes.infoText}>
                <Trans {...messages.infoCallText} values={{callerNumber}} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
        <Grid item xs={12}>
          <a rel="noopener noreferrer" target="_blank" href={contact.replace('{lang}', locale)}>
            <Typography variant="body1" className={classes.link}>
              <Trans {...messages.contactSupportProblems} />
            </Typography>
          </a>
        </Grid>

      </Grid>
    </FullScreenDialog>
  }
}

export default compose (
  withStyles(styles),
  withNamespaces(),
  withRouter,
  graphql(CLIENT_DATA_QUERY, {
    props: (prop) => {
      const viewer = get(prop.data, 'viewer')
      return {
        error: prop.data?.error,
        loading: prop.data?.loading,
        viewer:viewer,
      }
    }
  }),
  graphql(PHONE_VERIFICATION_MUTATION, {
    name: 'phoneVerification',
    //@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(CONFIG_QUERY, {
    props: (prop) => ({
      error: prop.data?.error,
      configLoading: prop.data?.loading,
      configVariables: get(prop.data, 'config'),
    })
  }),
)(AwaitingVerification)
