import {map, flowRight as compose} from 'lodash'
import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import {dueDiligenceSteps} from '@bdswiss/common-enums'
import {Typography} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import AppContext from '../../../Common/contexts/AppContext'
import {InnerAppContext} from '../../../../common/types.js'

export class DueDiligenceHeader extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  state = {
    open: false,
  }

  handleOpen = () => {
    this.setState({open: true})
  }

  handleClose = () => {
    this.setState({open: false})
  }
  render() {
    const {progress, activeStep} = this.props
    const {locale} = this.context

    return (
      <div>
        <LinearProgress variant="determinate" color="primary" value={progress}/>
        <Typography variant="body1"> {activeStep + 1}/2 </Typography>
        {map(dueDiligenceSteps, (step, stepKey) =>
          activeStep === step.stepPosition && (<Typography key={stepKey} variant="h3">
            {/** @ts-ignore **/}
            {step.localization.t(locale)} </Typography>)
        )}
      </div>
    )
  }
}

export default compose(withStyles({},{withTheme: true}),withNamespaces())(DueDiligenceHeader)
