import React, {Component} from 'react'
import {includes, flowRight as compose, map, filter, get, split} from 'lodash'
import {withNamespaces} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import {MuiThemeProvider} from '@material-ui/core/styles'
import {config} from './config'
import appThemes from './themes'
import TopLevelRoutes from './routes'
import i18nApp from './i18n'
import {getItem, storeItem} from './common/utils'
import {getCookie,setCookie} from './common/utils/browser'
import AppContext from './components/Common/contexts/AppContext'
import withStyles from '@material-ui/core/styles/withStyles'
import {findCompany, getCurrentTheme, isDarkTheme} from './common/utils/general'
import {languages} from '@bdswiss/common-enums'
import {isRtlLang} from './common/utils/uioptions'
import Images from './components/Common/Images'
import lydianWatermark from './assets/images/lydian/lydian-watermark.png'

let locale = getItem('locale', 'en')

const getWatermark = () => {
  const {key, showWatermark} = config

  if (!showWatermark) return null

  switch (key) {
    case 'lydian':
      return lydianWatermark
    default: return null
  }
}

const watermark = getWatermark()

const styles = theme => ({
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'auto',
    minHeight: '100%',
    position: 'relative' as const,
    background: watermark ? `url(${lydianWatermark}) no-repeat 100% 100%` : '#fff',
    backgroundSize: 800,
  },
  affiliateBackground: {
    background: `url(${Images[get(config, 'registrationBackground.affiliate')]}) no-repeat center center fixed`,
    'background-size': 'cover',
    overflow: 'hidden',
  },
})

const websiteLanguage = getCookie('current_website_language')
const supportedLanguages = map(filter(languages, (a) => !!a.client && !a.disabled), 'value')
if (websiteLanguage && includes(supportedLanguages,websiteLanguage)) {
  storeItem('locale', websiteLanguage)
  locale=websiteLanguage
  setCookie('current_website_language', null, 0, `.${window.location.hostname}`)
}

i18nApp.init(locale)

class AppProvider extends Component<any,any> {
  static contextType = AppContext

  state = {
    theme: getCurrentTheme(),
    locale: getItem('locale', 'en')
  } as any

  getTheme(partnerRegistration) {
    const themePreference = this.state.theme
    let appTheme
    const currentLocale = this.state.locale || getItem('locale', 'en')
    const isRtl = currentLocale && includes(isRtlLang,currentLocale) ? 'Rtl' : ''
    if (isDarkTheme(themePreference) && !partnerRegistration) {
      appTheme = appThemes[`${config.brand}Dark${isRtl}`]
    } else {
      appTheme = appThemes[`${config.brand}${isRtl}`]
    }

    return appTheme
  }


  render() {
    const {classes, location} = this.props
    const pathnameValue = get(split(get(location, 'pathname'), '/'), '[2]')
    const partnerRegistration = includes(get(this.props.location, 'pathname'), 'register') && !!get(config, `registrationBackground[${pathnameValue}]`)
    const theme = this.getTheme(partnerRegistration)
    return (
      <MuiThemeProvider theme={theme}>
        <AppContext.Provider value={{
          toggleTheme: (selectedTheme, locale)=> this.setState({theme: partnerRegistration ? 'light' : selectedTheme, locale}),
          themePreference: this.state.theme,
          showNotification: () => {},
          locale: getItem('locale', 'en'),
          company: config.key,
          companyObject: findCompany(),
        }}>
          <CssBaseline>
            <div className={classes[`${pathnameValue}Background`] || classes.mainBody}>
              <TopLevelRoutes />
            </div>
          </CssBaseline>
        </AppContext.Provider>
      </MuiThemeProvider>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
)(AppProvider)
