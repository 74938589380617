import React from 'react'
import PropTypes from 'prop-types'
import {graphql, withApollo} from 'react-apollo'
import {withRouter} from 'react-router-dom'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import {Loading} from '../../Common/Loading'
import {FOREX_ACCOUNT_QUERY} from '../../../graphql/queries'
import {get, isEmpty, flowRight as compose} from 'lodash'
import messages from '../../../assets/messages'
import ClientNotificationBar from '../ClientNotificationBar'
import LydianClientNotificationBar from '../../RedesignedWLComponents/Lydian/ClientNotificationBar'
import AppContext from '../../Common/contexts/AppContext'
import ForexTradingInfo from './ForexTradingInfo'
import PlanBar from './Plan/Bar'
import {config} from '../../../config'


const styles = theme => ({
  root: {
    marginTop: 30,
  },
  positionsTypeHeader: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  positionsTypeHeaderIcon: {
    top: 4,
    position: 'relative' as const,
  },
})


class ForexAccountOverview extends React.Component<any,any> {
  static propTypes = {
    account: PropTypes.object,
  }
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {positionsType: 'open'}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.account !== prevState.account) {
      if (get(nextProps, 'account.isArchived') || get(nextProps, 'account.isViewOnly')) {
        return ({positionsType: 'closed'})
      }
    }
    return null
  }

  selectPositionsType(positionsType) {
    this.setState({
      positionsType,
      positionsMenuEl: null
    })
  }

  render() {
    const {loading, account, classes} = this.props
    const {positionsMenuEl, positionsType} = this.state
    const hasSubscription = !isEmpty(account.subscription)
    if (loading) return <Loading />

    const {key} = config
    let StyledClientNotificationBar
    switch (key) {
      case 'lydian':
        StyledClientNotificationBar = <LydianClientNotificationBar account={account}/>
        break
      default:
        StyledClientNotificationBar = <ClientNotificationBar account={account}/>
    }

    return (<Grid container spacing={0}>
      {StyledClientNotificationBar}
      {hasSubscription && <Grid item xs={12}><PlanBar account={account} /></Grid>}
      <Grid container>
        <Grid item xs={12}>
          <Typography component='h3' variant="h3" gutterBottom
            className={classes.positionsTypeHeader}
            aria-owns={positionsMenuEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={e => this.setState({positionsMenuEl: e.currentTarget})}>
            <Trans {...messages[`tradesHeader_${positionsType}`]} />
            <ArrowDropDown className={classes.positionsTypeHeaderIcon} />
          </Typography>
          <Menu
            id="positions-types"
            anchorEl={positionsMenuEl}
            open={Boolean(positionsMenuEl)}
            onClose={() => this.selectPositionsType(positionsType)}
          >
            <MenuItem onClick={() => this.selectPositionsType('open')}><Trans {...messages.openTrades} /></MenuItem>
            <MenuItem onClick={() => this.selectPositionsType('closed')}><Trans {...messages.closedTrades} /></MenuItem>
            <MenuItem onClick={() => this.selectPositionsType('pending')}><Trans {...messages.pendingTrades} /></MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <ForexTradingInfo account={account} status={positionsType} enablePagination={positionsType === 'closed'} />
        </Grid>
      </Grid>
    </Grid>)
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  withRouter,
  withApollo,
  graphql(FOREX_ACCOUNT_QUERY, {
    options: (props: any) => ({variables: {id: props.match.params.accountId}, fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}:any) => {
      const account = get(data, 'viewer.accounts.0') || {}
      return {
        error,
        loading,
        account,
      }
    }
  }),
)(ForexAccountOverview)
