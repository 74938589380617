import React from 'react'
import {debounce, isFunction} from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'
import Sidebar from './sidebar/Sidebar'
import LydianSidebar from '../RedesignedWLComponents/Lydian/Sidebar'
import UiNotification from '../Common/UiNotification'
import Header from './Header'
import LydianHeader from '../RedesignedWLComponents/Lydian/Header'
import AppRoutes from './routes'
import classNames from 'classnames'
import LivechatBubble from '../Common/LivechatBubble'
import {config} from '../../config'

const styles = theme => ({
  wrap: {
    width: `calc(100% + ${theme.sidebarWidth})`,
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: `-${theme.sidebarWidth}`,
    height: '100%',
    transition: 'margin .2s',
    paddingBottom: 50,
    overflow: 'hidden',
  },
  wrapVisible: {
    width:'100%',
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 0,
    [theme.breakpoints.down('sm')]: {
      '& .sidebar':{
        flex:' 1 0 auto',
        height: '100%',
        display: 'flex',
        zIndex: '1200',
        outline: 'none',
        position: 'fixed' as const,
        overflowY: 'auto',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        top: '85px'
      },
      '& .main':{
        [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 0,
      }
    }
  },
  [theme.breakpoints.up('md')]: {
    wrap: {
      width: '100%',
      [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: theme.direction === 'rtl' ? 'inherit' : 0,
    },
  },
  sidebar: {
    float: theme.direction === 'rtl' ? 'right' as const: 'left' as const,
    width: `${theme.sidebarWidth}`,
    height: '100%',
    marginLeft: 0,
  },
  main: {
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: theme.sidebarWidth,
  },
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'auto',
    minHeight: '80vh',
    position: 'relative' as const,
  }
})

class Desktop extends React.Component<any,any> {
  constructor(props) {
    super(props)
    this.state = {visibleSidebar: false}
  }

  hideSidebar = debounce(() => {
    this.setState({visibleSidebar: false})
  }, 200)

  handleSidebarToggle = () => {
    this.setState({visibleSidebar: !this.state.visibleSidebar})
  }

  render() {
    const {notification, classes, viewer: {firstName, lastName, email}} = this.props
    const fullName =[firstName, lastName].join(' ')
    const {visibleSidebar} = this.state
    const {key} = config

    let StyledHeader
    let StyledSidebar
    switch (key) {
      case 'lydian':
        StyledHeader = <LydianHeader handleSidebarToggle={this.handleSidebarToggle} />
        StyledSidebar = <LydianSidebar className={classNames(classes.sidebar, 'sidebar')} />
        break
      default:
        StyledHeader = <Header handleSidebarToggle={this.handleSidebarToggle} />
        StyledSidebar = <Sidebar className={classNames(classes.sidebar, 'sidebar')} />
    }

    return (<>
      {StyledHeader}
      <div className={classes.mainBody}>
        <div
          className={[
            classes.wrap,
            visibleSidebar ? classes.wrapVisible : ''
          ].join(' ')}
        >
          {StyledSidebar}
          <main className={classNames(classes.main,'main')}>
            <AppRoutes />
          </main>
          {
            notification &&
              <UiNotification
                open={!!notification}
                onClose={() => {
                  isFunction(notification.onClose) && notification.onClose()
                  this.props.onClose()
                }}
                type={notification.type}
                status={notification.status}
                buttonMessage={notification.buttonMessage}
                hideButton={notification.hideButton}
                subTitle={notification.subTitle}
                buttonAction={notification.buttonAction}
                linkAction={notification.linkAction}
                linkActionMessage={notification.linkActionMessage}
              >
                {notification.content}
              </UiNotification>
          }
          <LivechatBubble fullName={fullName} email={email} />
        </div>
      </div>
    </>)
  }
}

export default withStyles(styles, {withTheme: true})(Desktop)
