import React, {Component} from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import {get, map, isEmpty, flowRight as compose} from 'lodash'
import {withStyles} from '@material-ui/core/styles'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {graphql} from 'react-apollo'
import {withRouter} from 'react-router-dom'
import messages from '../../../assets/messages'
import {AlertDialog} from '../../Common/Dialog'
import {ENABLE_SWAP_FREE} from '../../../graphql/mutations'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {config} from '../../../config'

const styles = theme => ({
  disabled: {
    color: theme.palette.lightgrey.color,
    fontSize: 14,
    fontWeight:400
  },
  itemSwapFree: {
    paddingBottom: 20
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
  },
  highlight: {
    fontWeight: 400,
  },
})

class SwapFreeDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      swapFreeError: '',
      swapFreeLoading: false,
      swapFreeStatus: '',
      swapFreeFailedAccounts: '',
    }
  }

  swapFreeContent(kycStatusApproved, okDocs) {
    const {classes, locale} = this.props
    const {weblinks: {termsAndConditions}} = config
    return <Grid container>
      {kycStatusApproved ? <React.Fragment>
        <Grid item xs={12} className={classes.itemSwapFree}>
          <Typography variant="body1">
            <Trans {...messages.swapFreeIslamicAccountPopupText1} />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.itemSwapFree}>
          <Typography variant="body1">
            <Trans {...messages.swapFreeIslamicAccountPopupText2} />
          </Typography>
          <Typography variant="body1">
            <Trans {...messages.termsApply} components={[<a href={termsAndConditions.replace('{lang}', locale)}
              target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>]}/> <Trans {...messages.swapFreeIslamicAccountPopupText3} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Trans
              {...messages.swapFreeIslamicAccountPopupNote}
              components={[<span className={classes.highlight}>is not available for Raw accounts</span>]}
            /></Typography>
        </Grid>
      </React.Fragment>: <Grid item xs={12}>
        <Typography variant="body1">
          <Trans {...messages.swapFreeAvailable} />&nbsp;
          <Trans {...messages[(okDocs > 0) ? 'swapFreeKycRequiredPendingMsg' : 'swapFreeIslamicAccountPopupText4']} />
        </Typography>
      </Grid>}
    </Grid>
  }

  enableSwapFree() {
    const {handleChangeParent} = this.props
    this.setState({swapFreeLoading: true, swapFreeStatus: '', swapFreeError: ''})
    this.props.enableSwapFree()
      .then((res) => {
        const accountsFailed = get(res, 'data.enableSwapFree.failedAccountRemoteIds')
        this.setState({swapFreeLoading: false, swapFreeStatus: 'success', swapFreeFailedAccounts: !isEmpty(accountsFailed) && accountsFailed})
        if (isEmpty(accountsFailed)) handleChangeParent('showSwapFreePopup', false)
      })
      .catch((err) => {
        this.setState({swapFreeLoading: false, swapFreeStatus: 'failure', swapFreeError: err.message.replace('GraphQL error: ', '')})
      })
  }

  render() {
    const {showSwapFreePopup, okDocs, kycStatusApproved, t, history, handleChangeParent} = this.props
    const {swapFreeError, swapFreeLoading, swapFreeStatus, swapFreeFailedAccounts} = this.state
    return <AlertDialog
      open={showSwapFreePopup}
      title={t(messages.swapFreeIslamicAccount.i18nKey, messages.swapFreeIslamicAccount.defaults)}
      children={this.swapFreeContent(kycStatusApproved, okDocs)}
      agreeText={(kycStatusApproved) ? t(messages.enable.i18nKey, messages.enable.defaults) :
        (okDocs > 0) ? '' : t(messages.verifyAccount.i18nKey, messages.verifyAccount.defaults)
      }
      disagreeText={(!kycStatusApproved && okDocs > 0) ? '' : t(messages.cancel.i18nKey, messages.cancel.defaults)}
      onAgree={()=> kycStatusApproved ? this.enableSwapFree() : history.push('/accounts/verification')}
      onDisagree={()=> this.setState({swapFreeStatus: '', swapFreeFailedAccounts: ''}, handleChangeParent('showSwapFreePopup', false))}
      errorText={swapFreeError || (!isEmpty(swapFreeFailedAccounts) && <React.Fragment>
        <span>{t(messages.accountsFailedSwapFree.i18nKey, messages.accountsFailedSwapFree.defaults)}</span>
        <span>{map(swapFreeFailedAccounts, (account, index) => (index ? ', ': '') + account)}</span></React.Fragment>)}
      buttonLoading={swapFreeLoading || !!swapFreeStatus}
      buttonStatus={swapFreeStatus}
      hideProgressBar={!!swapFreeStatus}
      closeBtn
      onClose={() => this.setState({swapFreeStatus: '', swapFreeFailedAccounts: ''}, handleChangeParent('showSwapFreePopup', false))}
    />
  }
}

export default compose (
  withStyles(styles),
  withNamespaces(),
  withRouter,
  graphql(ENABLE_SWAP_FREE, {
    name: 'enableSwapFree',
    options: () => ({
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }),
  }),
)(SwapFreeDialog)
