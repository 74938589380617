import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import Typography from '@material-ui/core/Typography'
import {get, replace, map, flowRight as compose, isEmpty} from 'lodash'
import Amount from '../../Common/Amount'
import Grid from '@material-ui/core/Grid'
import AppContext from '../../Common/contexts/AppContext'
import {getMemberFromLeaderboard} from '../../../common/utils/requests'
import HelpOutline from '@material-ui/icons/HelpOutline'
import Done from '@material-ui/icons/Done'
import Schedule from '@material-ui/icons/Schedule'
import {competitionRules} from '@bdswiss/common-enums'
import {isMobile} from '../../../common/utils'
import CustomTooltip from '../../Common/CustomTooltip'

const styles = theme => ({
  textCenter: {
    textAlign: 'center' as const
  },
  container: {
    padding: '20px 0'
  },
  boldText: {
    fontWeight: 400
  },
  greyText: {
    color: theme.palette.secondary.main
  },
  statusIcon: {
    verticalAlign: 'middle',
    fontSize: 12,
    display: 'inline',
    marginRight: 8
  },
  helpIcon: {
    verticalAlign: 'super',
    fontSize: 16,
    opacity: 0.4,
    cursor: 'pointer',
  },
  doneIcon: {
    color: theme.palette.green.color,
    display: 'inline',
  },
  scheduleIcon: {
    color: theme.palette.yellow.color,
    display: 'inline',
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  paddingTop: {
    paddingTop: 2
  }
})

class CompetitionMemberDetails extends Component<any,any> {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      tableRows: 5,
      participants: [],
      open: false,
      openStatus: false,
      clickedEvent: false,
    }
  }

  componentDidMount() {
    const {competition, currentEntry, oldEntry} = this.props
    getMemberFromLeaderboard(competition.id, get(currentEntry, 'remoteId') || get(oldEntry, 'remoteId')).then((res) => {
      if (get(res, 'error')) {
        this.setState({leaderboardError: true})
      } else {
        this.setState({currentParticipant: res[0]})
      }
    })
  }

  checkRule(participant, classes, value, key) {
    switch (get(value, 'rule')) {
      case competitionRules.minimumBalance.value:
        return <div key={key} className={classes.paddingTop}>
          <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})} />
          <Typography variant="caption" className={classes.statusIcon}>
            {value.label}
          </Typography>
        </div>
      case competitionRules.roundTrips.value:
        if (get(participant, 'tradesCount') > value.values.roundTrips) {
          return <div key={key} className={classes.paddingTop}>
            <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})} />
            <Typography variant="caption" className={classes.statusIcon}>
              {value.label}
            </Typography>
          </div>
        } else {
          return <div key={key} className={classes.paddingTop}>
            <Schedule className={classNames({[classes.statusIcon]: true, [classes.scheduleIcon]: true})} />
            <Typography variant="caption" className={classes.statusIcon}>
              {value.label}
              (<Trans {...messages.remainingTrades} values={{trades: Number(value.values.roundTrips - get(participant, 'tradesCount', 0))}} />)
            </Typography>
          </div>
        }
      default:
        return <div key={key} className={classes.paddingTop}>
          <Schedule className={classNames({[classes.statusIcon]: true, [classes.scheduleIcon]: true})} />
          <Typography variant="caption" className={classes.statusIcon}>
            {value.label}
          </Typography>
        </div>
    }
  }

  checkRank(participant, classes, rules) {
    if (get(participant, 'rank') < 0 || isEmpty(participant)) {
      return (
        <div>
          <Typography variant={!isMobile() ? 'h2' : 'body1'}>
            <Trans {...messages.pending} />
            <CustomTooltip
              title={
                <React.Fragment>
                  <Typography variant="body1">
                    <Trans {...messages.howToParticipateCompetition} />
                  </Typography>
                  {map(rules, (value, key) => this.checkRule(participant, classes, value, key))}
                </React.Fragment>
              }
              placement={'top-start'}
            >
              <HelpOutline onClick={() => this.setState({openStatus: true})} className={classNames({[classes.helpIcon]: true})} />
            </CustomTooltip>
          </Typography>

        </div>
      )
    } else {
      return <Typography variant="h2">{get(participant, 'rank', 0)}</Typography>
    }
  }
  render() {
    const {classes, currentEntry, oldEntry, rules} = this.props
    const {currentParticipant} = this.state
    return <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={4}><Typography variant="caption"><Trans {...messages.yourRank} /></Typography></Grid>
        <Grid item xs={4}><Typography variant="caption"><Trans {...messages.roi} /></Typography></Grid>
        <Grid item xs={4}><Typography variant="caption"><Trans {...messages.yourBalanceOnly} /></Typography></Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}>{this.checkRank(currentParticipant, classes, rules)}</Grid>
        <Grid item xs={4}>
          <Typography variant={!isMobile() ? 'h2' : 'body1'}>
            {replace(get(currentParticipant, 'roi', 0), '%')} <span className={classes.amountFraction}>%</span>
          </Typography>
        </Grid>
        <Grid item xs={4}><Typography variant={!isMobile() ? 'h2' : 'body1'}>
          <Amount value={get((currentEntry || oldEntry), 'balance')} currency={get((currentEntry || oldEntry), 'currency', 'USD')} locale='en-US' classes={{
            currency: classes.amountCurrency,
            value: classes.amountValue,
            fraction: classes.amountFraction
          }} /></Typography></Grid>
      </Grid>
    </Grid>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(CompetitionMemberDetails)
