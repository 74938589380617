import {Trans} from 'react-i18next'
import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import AppContext from '../Common/contexts/AppContext'
import messages from '../../assets/messages'
import IconButton from '@material-ui/core/IconButton'
import Help from '@material-ui/icons/Help'
import {withRouter} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import {config} from '../../config'
import {map, includes, find, filter, get, flowRight as compose, size, omitBy, isEmpty} from 'lodash'
import Popper from '@material-ui/core/Popper'
import {isMobile, scrollElementIntoView, setCookie} from '../../common/utils/browser'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {companiesDescription, europeCountries} from '../../common/utils/uioptions'
import {companies, regulators, countries} from '@bdswiss/common-enums'
import {CustomDialog} from '../Common/Dialog'
import Button from '@material-ui/core/Button'
import {isWhiteLabel} from '../../common/utils/general'
import {fireEntitiesSelectionEvent} from '../../common/utils/tagsManager'
import {getCountries, setCampaignCookie} from '../../common/utils/requests'
import queryString from 'querystring'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  lightGreyText: {
    color:theme.palette.lightgrey.color
  },
  greyText: {
    color:theme.palette.grey.color
  },
  textSecondary: {
    color:theme.palette.secondary.main
  },
  blackText: {
    fontWeight:500,
  },
  regList:{
    padding: 12,
  },
  helpIconDiv: {
    padding: 5
  },
  helpIcon: {
    color: theme.palette.lightgrey.color
  },
  regulationDiv:{
    background: theme.palette.lightgreyBackgroundSolid.color,
    padding:40,
    borderRadius: 25,
    position: 'relative' as const,
    margin: '0 auto',
    width: '80%',
    maxWidth: 1200,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
      width: '100%',
      margin: 'none'
    }
  },
  regTitle:{
    padding: '10px 0 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 0 5px'
    }
  },
  closeButton: {
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey.color,
    [theme.breakpoints.down('sm')]: {
      top: -10,
      [theme.direction === 'rtl' ? 'left' : 'right']: -13
    }
  },
  card:{
    overflow: 'visible',
    backfaceVisibility: 'hidden' as const,
    '-webkit-transform-style': 'preserve-3d',
    minHeight: 400,
    '&:hover':{
      backgroundColor: theme.palette.extralightgreyBackground.color,
      pointer: 'normal'
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
      padding: 0
    },
    height: '100%'
  },
  regLinks: {
    display: 'inline-block',
    textTransform: 'uppercase' as const,
    padding: 10,
  },
  textBlue: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginTop: 0
  },
  popTitle:{
    fontWeight: 500,
  },
  uppercase:{
    textTransform: 'uppercase' as const,
  },
  closeBtn:{
    width: '1.5em',
    height: '1.5em',
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      width: '1em',
      height: '1em',
    }
  },
  cardContent: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
      marginBottom: 10
    },
    minHeight: '100%',
  },
  cardContainer:{
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px 0'
    }
  },
  regPopper: {
    width:'100%',
    zIndex: 9999,
    transform: 'none !important',
    height: '100%',
    paddingTop: '10%',
    [theme.breakpoints.down('sm')]: {
      width:'97%',
    }
  },
  inline:{
    display: 'inline'
  },
  inlineButtons:{
    display: 'inline',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    }
  },
  listItem:{
    fontSize:8
  },
  button:{
    textAlign: 'right' as const
  },
  buttonDiv:{
    textAlign: 'right' as const,
    paddingTop: 20,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center' as const
    }
  },
  regButton:{
    minWidth: 0,
    width: '100%',
    padding: 5
  },
  proceedButton:{
    textTransform: 'uppercase' as const,
    '&:hover':{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.light
    },
    padding: 0,
  },
  cardText:{
    minHeight: 140
  },
  error:{
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`
  },
  fullHeight: {
    height: '100%'
  },
  displayTable: {
    display: 'table'
  },
  displayTableRow: {
    display: 'table-row'
  },
  displayTableCell: {
    display: 'table-cell',
    verticalAlign: 'bottom'
  },
  regulationDivSingle: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: 'inherit',
    }
  },
  footNote: {
    marginBottom: 16,
    fontWeight: 300,
  },
  regButtonText: {
    fontSize: 11,
  },
  regButtonTextTitle: {
    fontSize: 14,
  },
  displayBlock: {
    display: 'block'
  },
  displayFlex: {
    display: 'flex'
  },
  bold: {
    fontWeight: 400
  }
})

export class RegulationBar extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext

  state = {
    open: false,
    anchorEl: null,
    showPopup: false,
    entitySelected: '',
    showNotAllowedPopup: false,
    showForbiddenCountryPopup: false,
    allowedEntityCountries: []
  } as any

  async componentDidMount() {
    const {location} = this.props
    const urlParams = queryString.parse(location.search.replace('?', ''))
    if (get(urlParams, 'campaign')) {
      setCampaignCookie(JSON.stringify(urlParams))
        .then(async () => {
          await this.setAllowedEntityCountries()
        })
        .catch(() => {})
    } else {
      await this.setAllowedEntityCountries()
    }
  }

  async setAllowedEntityCountries() {
    const allowedEntityCountries = await getCountries()
    this.setState({allowedEntityCountries})
  }

  componentDidUpdate(prevProps) {
    const {country, selectedRegulator} = this.props
    if (country !== prevProps.country && selectedRegulator!==prevProps.selectedRegulator) {
      this.setState({previousRegulator:prevProps.selectedRegulator})
      if (selectedRegulator) this.handleChangeRegulator(selectedRegulator)
    }
  }

  handleClickButton = event => {
    const {currentTarget} = event
    fireEntitiesSelectionEvent(this.props.country)
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open,
    }), () => {scrollElementIntoView('scroll-div', 250)})
  }

  handleClose = () => {
    this.setState({open: false})
  }

  toCapitalize(value) {
    return value.charAt(0).toUpperCase() + value.substr(1)
  }

  forbiddenCountry() {
    const {country} = this.props
    const forbiddenCountries = map(filter(countries, country => !!country.forbidden), 'value')
    return includes(forbiddenCountries, country)
  }

  async notAllowedCountry(company) {
    const {country} = this.props
    const {allowedEntityCountries} = this.state
    const allowedCountries = map(allowedEntityCountries['countries'][company], 'value')
    return company && !isEmpty(allowedCountries) && !includes(allowedCountries, country)
  }

  async handleChangeRegulator(company) {
    const previousRegulator =  company !== this.props.selectedRegulator && this.props.selectedRegulator
    const notAllowedCountry = await this.notAllowedCountry(company)
    if (this.forbiddenCountry()) {
      this.setState({showForbiddenCountryPopup: true, previousRegulator})
    } else if (notAllowedCountry) {
      this.setState({entitySelected: this.toCapitalize(companies[company].regulator), showNotAllowedPopup: true, previousRegulator})
    } else {
      this.setState({entitySelected: this.toCapitalize(companies[company].regulator), showPopup: true, previousRegulator})
    }
  }

  checkRegulationAcceptance(company) {
    const {country} = this.props
    const {supportedCompanies} = config
    //@ts-ignore
    const cookieDomain = window.location.hostname.match(/[^.]+\.\w+$/) ? window.location.hostname.match(/[^.]+\.\w+$/)[0] : window.location.hostname
    if (europeCountries.indexOf(country) !== -1 && get(supportedCompanies[get(company, 'value')], 'showRegulationPopup')) {
      setCookie('RegulationAccepted', new Date().toISOString(), 1, '.'+cookieDomain)
    } else {
      setCookie('RegulationAccepted', new Date().toISOString(), -1, '.'+cookieDomain)
    }
  }

  renderPopupContent(allowed, regSelected) {
    const {classes, country} = this.props
    const {entitySelected} = this.state
    const {supportedCompanies} = config
    const brand = isWhiteLabel() ? (get(config, 'companyBrand') || config.brand)  : config.brand
    const company = find(companies, (company) => company.regulator === entitySelected.toLowerCase() && company.brand === brand)
    //@ts-ignore todo: maybe fix that
    const previousRegulator = (!this.state.previousRegulator || this.notAllowedCountry(this.state.previousRegulator)
      || includes(europeCountries, country)) ? null : this.state.previousRegulator
    const showRegulationPopup = company && includes(europeCountries, country) && get(supportedCompanies[company.key], 'showRegulationPopup')
    const regulator = get(regulators[entitySelected.toLowerCase()], 'label')
    const regulatorCountry = get(regulators[entitySelected.toLowerCase()], 'country')
    return (<React.Fragment>
      {(allowed) ? <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="body1"> <Trans {...messages[`regulationPopupMessage${entitySelected}`] || messages.regulationPopupMessage}
            components={[<br key={'br'} />, <br key={'br2'} />]} />
          </Typography>
        </Grid>
        {showRegulationPopup && <React.Fragment>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="body1"> <Trans {...messages.regulationPopupNotification} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="body1" className={classes.bold}> <Trans {...messages.regulationPopupMessage3}
              values={{regulator, regulatorCountry}}/>
            </Typography>
          </Grid>
        </React.Fragment>}
        <Grid container spacing={!isMobile() ? 3 : 0} direction="row" justifyContent="flex-end" alignItems="flex-end"className={classes.buttonDiv}>
          <Grid item xs={12} md={5} lg={5}>
            <Button onClick={() =>{
              this.setState({showPopup: false})
              this.props.regSelected(previousRegulator)
              this.state.previousRegulator && !this.notAllowedCountry(this.state.previousRegulator) && this.props.history.push(`?regulator=${get(find(regulators, (r) => r.value === (companies[this.state.previousRegulator].regulator).toLowerCase()), 'label')!.toLowerCase()}`)
            }}
            color="secondary" size="large" variant="contained">
              <Trans {...messages.noThanks} />
            </Button>
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <Button
              onClick={() => {
                this.setState({showPopup: false})
                this.props.regSelected(company!.value)
                this.props.history.push(`?regulator=${get(find(regulators, (r) => r.value === entitySelected.toLowerCase()), 'label')!.toLowerCase()}`)
                this.checkRegulationAcceptance(company)
              }}
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
            >
              <Trans {...messages.accept} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
        : <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="body1">
              {company && <Trans {...messages[`regNotAllowedPopup${company.regulator}`] || messages.regNotAllowedPopup}
                components={[<br key={'br'} />, <br key={'br2'} />]}
                values={{entity: get(regulators[entitySelected.toLowerCase()], 'label'), choosedEntity: get(regulators[regSelected], 'label')}} />}
            </Typography>
          </Grid>
          <Grid container spacing={!isMobile() ? 3 : 0} direction="row" justifyContent="flex-end" alignItems="flex-end"className={classes.buttonDiv}>
            <Grid item xs={12} md={5} lg={5}>
              <Button onClick={() =>this.setState({showNotAllowedPopup: false})}
                color="secondary" size="large" variant="contained">
                <Trans {...messages.close} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    </React.Fragment>
    )
  }

  render() {
    const {classes, error, regulator, country} = this.props
    const {showPopup, entitySelected, showNotAllowedPopup, showForbiddenCountryPopup, allowedEntityCountries} = this.state
    const {supportedCompanies} = config
    const {companyObject, locale} = this.context

    const forbiddenCountry = this.forbiddenCountry()
    const entityCountries = get(allowedEntityCountries, 'countries')
    const entityCountry = find(entityCountries, (entityCountry) => entityCountry.countryCode === country)
    const allowedCompanies = entityCountries && !forbiddenCountry ? omitBy(supportedCompanies,
      (entity, key) => !includes(get(entityCountry, 'regulators'), key)) : supportedCompanies
    const multiCompanies = size(allowedCompanies) > 1
    const popperDialog = <div>
      <Grid container className={classNames(classes.regulationDiv, !multiCompanies ? classes.regulationDivSingle : '')}>
        <Grid item xs={12} /*align="center"*/ className={classes.regTitle}>
          <Typography variant='h4' className={classes.popTitle}><Trans {...messages.companyFirms} values={{company:companyObject.brandLabel}} />:</Typography>
          <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose} >
            <CloseIcon classes={{root:classes.closeBtn}}/>
          </IconButton>
        </Grid>
        <Grid container spacing={!isMobile()?5:0} className={classes.cardContainer}>
          {map(allowedCompanies, (value, key) => (
            //@ts-ignore
            <Grid item md={(12/size(allowedCompanies))} xs={12} key={key}>
              <Card className={classes.card}>
                <CardContent classes={{root:classNames(classes.cardContent, classes.displayTable)}}>
                  <Grid container className={classNames(classes.fullHeight, classes.displayTableRow)}>
                    <Grid item xs={12}>
                      <Typography variant='h3' className={classNames(classes.textBlue,classes.uppercase)}>
                        {regulators[companies[key].regulator].label}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body1' className={classes.blackText}>
                        {companies[key].trademark}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ul className={classes.cardText}>
                        {map(companiesDescription[key], (keyPoint,index)=>
                          <li key={index} className={classNames(classes.textSecondary,classes.listItem)}>
                            <Typography variant='body1' color='secondary'>
                              <Trans {...(keyPoint.message) ? keyPoint.message : keyPoint} values={(keyPoint.values) ? keyPoint.values : ''}/>
                            </Typography>
                          </li>
                        )}
                      </ul>
                      {supportedCompanies[key].showFootNote &&
                      <Typography variant='caption' color='secondary' className={classes.footNote}>
                        <Trans {...messages.footNote} />
                      </Typography>}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.displayTableRow}>
                    <Grid item xs={12} className={classes.displayTableCell}>
                      <Button
                        key={key}
                        onClick={() => {
                          this.setState({open: false})
                          this.handleChangeRegulator(key)
                        }}
                        variant='outlined'
                        size="large"
                        color="primary"
                        className={classes.proceedButton}
                        fullWidth
                      >
                        <Trans {...messages.proceedWith} values={{regulator: regulators[companies[key].regulator].label}}/>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>

              </Card>
            </Grid>))}
        </Grid>
      </Grid>
    </div>

    const countryObj = find(countries, {value: country})
    const forbiddenCountryMessage = countryObj && includes(config.common.forbiddenCountryPopupSpecific, country) ?
      messages.forbiddenCountryPopupSpecific : messages.forbiddenCountryPopup
    return !isEmpty(allowedEntityCountries) ? <Grid item xs={12} className={classes.regList}>
      <Grid container>
        <Grid item  xs={12}>
          <Typography variant='body1' className={classNames(classes.inline, classes.greyText)}>
            <Trans {...messages.chooseEntity} />
          </Typography>
          <IconButton onClick={this.handleClickButton} aria-describedby={'dialog'} classes={{root:classes.helpIconDiv}}>
            <Help className={classes.helpIcon} aria-describedby={'popper'} />
          </IconButton>
        </Grid>
        <Grid item xs={12} className={classes.inlineButtons}>
          <Grid container spacing={1}>
            {map(allowedCompanies, (value, key) =>
              <Grid item xs={4} key={key} className={classes.displayFlex}>
                <Button
                  key={key}
                  onClick={() => (companies[key].regulator !== regulator) ? this.handleChangeRegulator(key) : ''}
                  variant={(companies[key].regulator === regulator) ? 'contained' : 'outlined'}
                  size="large"
                  color="primary"
                  className={error ? classNames(classes.regButton,classes.error) : classes.regButton}
                  classes={{label: classes.displayBlock}}
                >
                  <React.Fragment>
                    <div className={classes.regButtonTextTitle}>{companies[key].trademark}</div>
                    <div className={classes.regButtonText}>
                      {`(${get(regulators[companies[key].regulator.toLowerCase()], 'label')} ${get(regulators[companies[key].regulator.toLowerCase()], 'country')})`}
                    </div>
                  </React.Fragment>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Popper id={'popper'} open={this.state.open} anchorEl={this.state.anchorEl} className={classes.regPopper} placement="bottom-end">
          {popperDialog}
        </Popper>
        {!forbiddenCountry && <CustomDialog open={showPopup} title={<Trans {...messages[`regulationDialogTitle${entitySelected}`] || messages['regulationDialogTitle']} />}>
          {this.renderPopupContent(true, regulator)}
        </CustomDialog>}
        {!forbiddenCountry && <CustomDialog open={showNotAllowedPopup} title={<Trans {...messages.importantNotification} />} onClose={() => this.setState({showNotAllowedPopup: false})}>
          {this.renderPopupContent(false, regulator)}
        </CustomDialog>}
        {(forbiddenCountry) && <CustomDialog open={showForbiddenCountryPopup} title={<Trans {...messages.importantNotification} />} onClose={() => this.setState({showForbiddenCountryPopup: false})}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">
                <Trans {...forbiddenCountryMessage} values={{country:countryObj!.localization.t(locale)}} components={[<br key={'br'} />, <br key={'br2'} />]} />
              </Typography>
            </Grid>
          </Grid>
        </CustomDialog>}
      </Grid>
    </Grid> : ''
  }
}
export default compose(withRouter,withStyles(styles, {withTheme: true}))(RegulationBar)
