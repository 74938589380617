import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {capitalize} from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'
import AppContext from '../../Common/contexts/AppContext'
import Grid from '@material-ui/core/Grid'
// import classNames from 'classnames'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Amount from '../../Common/Amount'
import messages from '../../../assets/messages'

const styles = theme => ({
  cardRoot: {
    cursor: 'pointer',
    position:'relative' as const,
    height: '100%'
  },
  cardLabel: {
    fontSize: 16,
    fontWeight: 400,
  },
  amount: {
    display: 'flex',
    flexDirection: 'row' as const,
  },
  amountCurrency: {
    fontSize: 21,
    fontWeight: 500,
    padding: '0 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  amountValue: {
    fontSize: 35,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  amountFraction: {
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  amountMobile:{
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  typeBadge: {
    height: 40,
    width: 40,
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '40px',
    borderRadius: 20,
    textTransform: 'uppercase' as const,
    textAlign: 'center' as const,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 18,
    backgroundColor:  theme.palette.extralightblue.color,
    color: theme.palette.lightdark.color,
    [theme.breakpoints.down('xs')]: {
      width: 35,
      height: 35,
      lineHeight: '37px',
      [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 14,
    },
  },
  accessNowText: {
    fontSize: 15,
    fontWeight: 500,
    color: theme.palette.lightgold.color,
  },
  forexMt4: {
    color: theme.palette.lightdark.color,
    backgroundColor:  theme.palette.extralightblue.color,
  },
  pammForex: {
    color: theme.palette.lightdark.color,
    backgroundColor: theme.palette.extralightblue.color,
  },
  spotOption: {
    color: theme.palette.lightdark.color,
    backgroundColor: theme.palette.extralightblue.color,
  },
  tradeSmarter: {
    color: theme.palette.lightdark.color,
    backgroundColor: theme.palette.extralightblue.color,
  },
  default: {
    color: theme.palette.lightdark.main,
    backgroundColor: theme.palette.extralightblue.color,
  },
  affiliate: {
    color: theme.palette.lightdark.color,
    backgroundColor: theme.palette.extralightblue.color,
  },
  cardContent:{
    flexGrow:1,
    [theme.breakpoints.up('sm')]: {
      minHeight: 80,
    },
  },
  isDemoArchived:{
    borderRadius: '5px',
    padding: '0px 7px',
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']:4
  },
  badgesBox:{
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: 8,
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    [theme.breakpoints.down('xs')]: {
      top: 5,
    },
  },
  isDemo:{
    backgroundColor: theme.palette.secondary.main,
  },
  isDemoText:{
    color: theme.palette.secondary.light,
    opacity: 1,
  },
  isArchived:{
    backgroundColor:  theme.palette.lightred.color,
  },
  isArchivedText:{
    color: theme.palette.red.color,
  },
  ib: {
    color: theme.palette.green.color,
    backgroundColor:  theme.palette.lightgreen.color,
  },
  basic: {
    color: theme.palette.primary.main,
    backgroundColor:  theme.palette.lightblue.color,
  },
  raw: {
    color: theme.palette.cyan.color,
    backgroundColor:  theme.palette.lightcyan.color,
  },
  black: {
    color: theme.palette.purple.color,
    backgroundColor:  theme.palette.lightpurple.color,
  },
  invest_plus: {
    color: theme.palette.harlequin.color,
    backgroundColor:  theme.palette.lightharlequin.color,
  },
  copyingText:{
    backgroundColor: theme.palette.red.color,
  },
  competitionDiv:{
    backgroundColor:  theme.palette.green.color,
  },
  competitionText:{
    color: theme.palette.green.textColor,
  },
  providing: {
    color: theme.palette.primary.main,
  },
  bonusDiv:{
    backgroundColor: theme.palette.cyan.color,
  },
  bonusText:{
    fontWeight: 500
  },
  premium: {
    color: theme.palette.premium.color,
    backgroundColor: theme.palette.lightpremium.color,
  },
})

class ExternalAccountCard extends Component<any,any> {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      fulldialogOpen: false,
    }
  }

  render() {
    const {balance, classes, onClick, name} = this.props
    const formattedBalance = String(balance).replace(/,/g, '')
    return <Card classes={{root: classes.cardRoot}} onClick={() => onClick && onClick()}>
      <Grid container>
        <Grid item style={{alignSelf: 'center'}}>
          <div className={classes.typeBadge}>LD</div>
        </Grid>

        <Grid item className={classes.cardContent}>
          <Typography className={classes.cardLabel}>{name?.split('_').map(capitalize).join(' ') ?? 'Lydian Account'}</Typography>
          <Typography variant='body1' className={classes.amount}>
            <Amount value={Number(formattedBalance)} currency={'EUR'} locale='en-US' classes={{
              currency: classes.amountCurrency,
              value: classes.amountValue,
              fraction: classes.amountFraction
            }} />
          </Typography>
        </Grid>

        <Grid item style={{alignSelf: 'center'}}>
          <Typography className={classes.accessNowText} variant='inherit'><Trans {...messages.accessNow} /></Typography>
        </Grid>
      </Grid>
    </Card>
  }
}

export default withStyles(styles, {withTheme: true})(withNamespaces()(ExternalAccountCard))

