import React, {Component} from 'react'
import {get, map, isEmpty, orderBy,first, has, flowRight as compose} from 'lodash'
import moment from 'moment'
import {withStyles} from '@material-ui/core/styles'
import {depositVendors,transactionTypes} from '@bdswiss/common-enums'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import {graphql} from 'react-apollo'
import {TRANSACTIONS_CLIENT_QUERY} from '../../../graphql/queries'
import classNames from 'classnames'
import {safeParseJSON} from '../../../common/utils/general'
import {Loading} from '../../Common/Loading'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import LoadingButton from '../../Common/LoadingButton'
import {hasVipOrRaw, blockedDepositAccount} from '../../../common/utils/accounts'
import NotificationBar from '../../Common/NotificationBar'
import {config} from '../../../config'
import * as PaymentsHistoryHelper from './PaymentsHistoryHelper'
import AppContext from '../../Common/contexts/AppContext'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  table: {
    minWidth: 700,
    border: 'none',
    marginBottom: 50
  },
  statusIcon: {
    'vertical-align':'middle',
    'font-size':'20px',
    'margin-right':'8px',
  },
  doneIcon: {
    'color': theme.palette.green.color
  },
  pendingIcon: {
    'color': theme.palette.yellow.color
  },
  deposit: {
    'color': theme.palette.green.color,
    whiteSpace: 'nowrap' as const,
    padding: '4px 56px 4px 24px',
  },
  withdrawal: {
    color: theme.palette.red.color,
    whiteSpace: 'nowrap' as const,
    padding: '4px 56px 4px 24px',
  },
  subscription: {
    'color': theme.palette.green.color,
    whiteSpace: 'nowrap' as const,
  },
  transfer: {
    'color': theme.palette.yellow.color
  },
  action: {
    'color': theme.palette.primary.main,
    fontWeight: 400
  },
  dateTime:{
    color: theme.palette.secondary.main
  },
  statusCell: {
    whiteSpace: 'nowrap' as const,
  },
  filterButton: {
    cursor: 'pointer'
  },
  link: {
    color: theme.palette.primary.main,
  },
  status: {
    maxWidth: 130,
  },
  cellRoot: {
    padding: '4px 0px 4px 30px'
  },
  cancelBtn:{
    marginTop:0
  },
  waitingBtn:{
    padding:0
  },
  detailsInfo: {
    padding: 0,
    minWidth: '28px',
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  textCenter:{
    textAlign: 'center' as const
  },
  textRight:{
    textAlign: 'right' as const
  },
  cancelPartialBtn: {
    color: theme.palette.red.color,
  },
  rowButton: {
    borderBottom: 'none'
  },
  notificationBox: {
    marginTop: 20
  },
  showMore: {
    display: 'inline',
    fontWeight: 400,
    padding: '8px 0',
    '&:hover, &:active':{
      backgroundColor: 'transparent',
    },
  },
  loading: {
    paddingTop: 20
  },
  highlight:{
    fontWeight:400
  },
  selectedRow: {
    backgroundColor:  theme.palette.lightyellow.color,
  },
  selectedRowRemove: {
    backgroundColor:  theme.palette.background.default,
    transition: 'background-color 1000ms linear'
  },
  spanDetails: {
    paddingLeft: 12
  }
})


class DesktopPaymentsList extends Component<any,any> {
    static contextType = AppContext
    context!: InnerAppContext
    componentDidUpdate() {
      const {scrollTo} = this.props
      scrollTo()
    }

    render() {
      const {classes, state, transactions, accounts, history, transactionsCount, setLoading, setState,
        state: {limit, localLimit, limitEnd, filter, disableShowMore,loading: buttonLoading}, cancelPendingWithdrawal,
        t, loading, accountsLoading, lastRow} = this.props
      const {featuresConfig :{skipWaitingList}} = config
      const {blockedDeposit, locale} = this.context

      if (loading || accountsLoading) {
        return (<Loading />)
      }

      const sortedTransactions = !isEmpty(transactions) ? orderBy(transactions, ['createdAt'], ['desc']) : []
      const shownTransactions = PaymentsHistoryHelper.applyFilter(sortedTransactions, state.filter)

      const showMoreCheck = transactions && shownTransactions && shownTransactions.length <= 0
      const moreTransactions = transactions && transactions.length < transactionsCount

      //@ts-ignore
      const showMore = <span variant="subtitle2" disabled={disableShowMore} className={classNames(classes.link, classes.filterButton, classes.showMore)}
        onClick={() => {
          setState({limit: localLimit || first(PaymentsHistoryHelper.transactionsLimits), limitEnd: limitEnd + (limit || first(PaymentsHistoryHelper.transactionsLimits)), disableShowMore: true})
          this.props.highlightRow(shownTransactions.length)
        }}
      ><Trans {...messages.showMore} /></span>

      return (<React.Fragment>
        {showMoreCheck &&
        <NotificationBar status="info" classes={{notificationBox: classes.notificationBox}}>
          <Trans {...messages.noTransactionsCurrent}
            components={[moreTransactions && showMore, moreTransactions && <span className={classes.highlight}>highlight</span>]}
            values={{showMore: moreTransactions ? t(messages.showMore.i18nKey, messages.showMore.defaults) : '',
              totalTransactions: moreTransactions ? t(messages.entriesOfInline.i18nKey,
                {entries: transactions.length, entriesCount: transactionsCount, showStar: ''}) : ''}} />
        </NotificationBar>}
        {!showMoreCheck && <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t(messages.account.i18nKey, messages.account.defaults)}</TableCell>
              <TableCell>{t(messages.time.i18nKey, messages.time.defaults)}</TableCell>
              <TableCell>{t(messages.details.i18nKey, messages.details.defaults)}</TableCell>
              <TableCell className={classes.cellRoot}>{t(messages.type.i18nKey, messages.type.defaults)}</TableCell>
              <TableCell className={classes.cellRoot}>{t(messages.amount.i18nKey, messages.amount.defaults)}</TableCell>
              <TableCell className={classes.cellRoot}>{t(messages.status.i18nKey, messages.status.defaults)}</TableCell>
              <TableCell className={classes.cellRoot}>{t(messages.action.i18nKey, messages.action.defaults)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(shownTransactions, (row, index) =>
              <TableRow key={row.id} id={lastRow === index ? 'scroll-row' : row.id}
                className={(lastRow === index && state.highlightRow) ? classes.selectedRow : classes.selectedRowRemove}>
                <TableCell className={classes.statusCell}>{PaymentsHistoryHelper.transactionAccountName(row, locale)}</TableCell>
                <TableCell className={classes.statusCell} component="th" scope="row">
                  <Tooltip title={moment(row.createdAt).format('lll')} placement="top-start">
                    <Typography variant="subtitle1" className={classes.dateTime}>
                      {moment(row.createdAt).format('DD MMM YYYY, HH:mm')}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.cellRoot}>
                  {PaymentsHistoryHelper.showAllDetails(row, this.props, locale)}
                </TableCell>
                <TableCell className={classes.cellRoot}>{PaymentsHistoryHelper.transactionType(row)}</TableCell>
                <TableCell className={row.vendor === 'transfer' ?
                  classes.transfer : classes[row.transactionType.toLowerCase()]}>
                  {PaymentsHistoryHelper.showAmount(row)}
                </TableCell>
                <TableCell className={classNames([classes.status, classes.cellRoot])}>
                  <Tooltip title={PaymentsHistoryHelper.fieldStatus(row, classes, accounts, locale, true)} placement="top">
                    <span>{PaymentsHistoryHelper.fieldStatus(row, classes, accounts, locale)}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.action}>
                  {PaymentsHistoryHelper.pendingWithdrawalStatuses.includes(row.status) && !hasVipOrRaw(accounts) && !blockedDeposit && !blockedDepositAccount(row.account) && skipWaitingList &&
                    <Button
                      disableFocusRipple
                      disableRipple
                      variant="text"
                      color="primary"
                      classes={{root:classes.waitingBtn}}
                      onClick={() => history.push('/vip-account')}
                    >
                      <Trans {...messages.skipWaitingList} />
                    </Button>
                  }
                  {PaymentsHistoryHelper.canCancelWithdrawal(row) && <LoadingButton
                    disableFocusRipple
                    disableRipple
                    variant="text"
                    color="primary"
                    disabled={buttonLoading}
                    status=""
                    size="medium"
                    classes={{root:classes.cancelBtn}}
                    onClick={() => cancelPendingWithdrawal(row)}
                  >
                    <Trans {...messages.cancel} />
                  </LoadingButton>
                  }
                  {row.transactionType === transactionTypes.deposit.key && PaymentsHistoryHelper.pendingStatuses.includes(row.status) &&
                    <Button
                      disableFocusRipple
                      disableRipple
                      variant="text"
                      color="primary"
                      classes={{root:classes.waitingBtn}}
                      onClick={() => {
                        const meta = safeParseJSON(get(row, 'meta') || '{}')
                        if (get(row, 'vendor', '') === depositVendors.isignthis.value && has(meta, 'iSignthisUrl')) {
                          window.location = get(meta, 'iSignthisUrl')
                        } else {
                          history.push('/accounts/verification')
                        }
                      }}
                    >
                      <Trans {...messages.verifyAccount} />
                    </Button>
                  }
                  {PaymentsHistoryHelper.getPartialDepositButtons(row, this.props, state, setLoading)}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className={classNames(classes.textRight, classes.rowButton)} colSpan={7}>
                <Typography variant="caption"><Trans {...messages.entriesOf}
                  values={{entries: transactions.length, entriesCount: transactionsCount, showStar: PaymentsHistoryHelper.renderHelperText(filter, classes) ? '*' : ''}}/></Typography>
                {PaymentsHistoryHelper.renderHelperText(filter, classes)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classNames(classes.textCenter, classes.rowButton)} colSpan={7}>
                {(transactions.length < transactionsCount) && showMore}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>}
      </React.Fragment>)
    }
}

export default compose(
  withNamespaces(),
  withStyles(styles),
  graphql(TRANSACTIONS_CLIENT_QUERY, {
    options: (props:any) => ({
      variables: {limit: props.state.limit + props.state.limitEnd, offset: 0},
    }),
    props: ({data: {error, loading}, data}: any)  => {
      const transactions = get(data, 'viewer.transactions')
      return {
        error,
        loading,
        transactions,
        transactionsCount: get(data, 'viewer.transactionsCount'),
        refetchPayments: data.refetch
      }
    }}),
)(DesktopPaymentsList)
