// @ts-nocheck
import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {get, map, filter, isEmpty, flowRight as compose, first, values, includes} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import {vpsSubscriptionPlans, vpsSubscriptionStatuses, vpsSubscriptionTypes} from '@bdswiss/common-enums'
import messages from '../../assets/messages'
import {
  CREATE_VPS_SUBSCRIPTION_MUTATION,
  CANCEL_VPS_SUBSCRIPTION_MUTATION,
  TERMINATE_VPS_SUBSCRIPTION_MUTATION,
} from '../../graphql/mutations'
import {
  CLIENT_DATA_QUERY,
  ACCOUNTS_QUERY,
  VPS_SUBSCRIPTIONS_QUERY
} from '../../graphql/queries'
import PlanCard from '../Common/PlanCard'
import {AlertDialog} from '../Common/Dialog/index'
import {Loading} from '../Common/Loading'
import PageSubTitle from '../Common/PageSubTitle'
import VpsSubscriptionMsg from './VpsSubscriptionMsg'
import {isMobile} from '../../common/utils/browser'
import {config} from '../../config'
import NotificationBar from '../Common/NotificationBar'
import {getMissingVerifications, getMissingDocs} from '../../common/utils/general'

const styles = theme => ({
  highlight: {
    /** @type {const} */
    fontWeight: 'normal',
  },
  textBlue:{
    color: theme.palette.primary.main
  },
  inlineTypography: {
    paddingLeft: '1rem',
    display:'inline-block',
  },
  dialogTitle:{
    marginBottom: 10,
    /** @type {const} */
    textAlign: 'center'
  },
  highlightedText: {
    color: theme.palette.primary.main,
  },
  gridSpacing:{
    padding:25
  }
})
export class VpsPlan extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: '',
      loading: false,
      openConfirmationModal: false,
      loadingProceed: false,
      plan: null,
      isFree: false,
      action: null // 'activate', 'cancel'
    }
  }

  renderConfirmationModal(subscription) {
    const {t, classes} = this.props
    const {
      action,
      loadingProceed,
      statusProceed,
      submitMessageError,
      openConfirmationModal,
      isFree,
      plan
    } = this.state
    let actionOptions = {}
    let dialogContent = null

    switch (true) {
      case action === 'cancel':
        actionOptions = {...actionOptions,
          onClose: this.closeConfirmationModal,
          onAgree: () => this.cancelSubscription(subscription),
          onDisagree: this.closeConfirmationModal,
          disagreeText: t(messages.cancel.i18nKey),
          agreeText: t(messages.proceedButton.i18nKey),
          disabledTypography: true,
          title: <Typography variant='h4' className={classes.dialogTitle}>
            <Trans {...messages['vpsCancelSubscriptionTitle']} values={{plan: get(plan, 'label')}}
            />
          </Typography>
        }
        dialogContent = <Typography variant='body1'>
          <Trans {...messages['vpsCancelSubscriptionMessage']} values={{plan: get(plan, 'label')}}
            components={[<span className={classes.highlight}>current VPS</span>]}
          />
        </Typography>
        break
      case action === 'activate':
        actionOptions = {...actionOptions,
          onClose: this.closeConfirmationModal,
          onAgree: isFree ? this.createVpsSubscription() : this.initiatePayment,
          onDisagree: this.closeConfirmationModal,
          disagreeText: t(messages.cancel.i18nKey),
          agreeText: t(messages.proceedButton.i18nKey),
          closeBtn: true,
          disabledTypography: true,
          title: <Typography variant='h4' className={classes.dialogTitle}>
            {plan.label}
          </Typography>
        }
        dialogContent = <Typography variant='body1'>
          <Trans
            {...messages.submitVpsSubscriptionTitle}
          /></Typography>
        break
      case action === 'terminate':
        actionOptions = {...actionOptions,
          onClose: this.closeConfirmationModal,
          onAgree: () => this.terminateSubscription(subscription),
          onDisagree: () =>  this.initiatePayment(),
          disagreeText: t(messages.changePaymentMethod.i18nKey),
          agreeText: t(messages.proceedButton.i18nKey),
          closeBtn: true,
          disabledTypography: true,
          title: <Typography variant='h4' className={classes.dialogTitle}>
            <Trans
              {...messages.vpsTerminateSubscriptionTitle}
            />
          </Typography>
        }
        dialogContent = <Typography variant='body1'>
          <Trans
            {...messages.vpsTerminateFailedPaymentMessage}
            components={[<span className={classes.highlight}>current VPS</span>]}
          /></Typography>
        break
      default:
    }
    return <AlertDialog
      open={openConfirmationModal}
      buttonLoading={loadingProceed}
      buttonStatus={statusProceed}
      errorText={submitMessageError}
      {...actionOptions}
    >
      {dialogContent}
    </AlertDialog>
  }

  openConfirmationModal = (action, plan, isFree) => {
    this.setState({
      openConfirmationModal: true,
      action,
      plan,
      isFree,
      submitMessageError:''
    })
  }

  closeConfirmationModal = () => {
    this.setState({
      openConfirmationModal: false,
      action: null,
      plan: null,
      isFree: false,
    })
  }

  cancelSubscription = (subscription) => {
    const {t, location:{pathname}, client} = this.props

    this.setState({loadingProceed:true, statusProceed:''})
    this.props.cancelVpsSubscription({variables: {
      id: subscription.id,
      clientId: client.id,
      reason: 'Cancelled by customer',
      immediate: false,
    }}).then((succ) => {
      this.setState({loadingProceed: false, statusProceed: 'success'})
      window.location.href = pathname
    })
      .catch((err) => {
        if (err.networkError) {
          this.setState({loadingProceed: false, statusProceed: 'failure',
            submitMessageError: t(messages.networkError.i18nKey),
          })
        } else {
          this.setState({loadingProceed: false, statusProceed: 'failure',
            submitMessageError: get(err, 'graphQLErrors[0].message') || err.message,
          })
        }
      })
  }

  createVpsSubscription = () => {
    const {t, client, location:{pathname}} = this.props
    const {plan} = this.state

    this.setState({loadingProceed:true, statusProceed:''})
    this.props.createVpsSubscription({variables: {
      clientId: client.id,
      planName: plan.value,
    }})
      .then((succ) => {
        this.setState({loadingProceed: false, statusProceed: 'success'})
        window.location.href = pathname
      })
      .catch((err) => {
        if (err.networkError) {
          this.setState({loadingProceed: false, statusProceed: 'failure',
            submitMessageError: t(messages.networkError.i18nKey),
          })
        } else {
          this.setState({loadingProceed: false, statusProceed: 'failure',
            submitMessageError: get(err, 'graphQLErrors[0].message') || err.message,
          })
        }
      })
  }

  terminateSubscription = (subscription) => {
    const {t, location:{pathname}, client} = this.props

    this.setState({loadingProceed:true, statusProceed:''})
    this.props.terminateVpsSubscription({variables: {
      id: subscription.id,
      clientId: client.id,
      reason: 'Terminated by customer',
      immediate: true,
    }}).then((succ) => {
      this.setState({loadingProceed: false, statusProceed: 'success'})
      window.location.href = pathname
    })
      .catch((err) => {
        if (err.networkError) {
          this.setState({loadingProceed: false, statusProceed: 'failure',
            submitMessageError: t(messages.networkError.i18nKey),
          })
        } else {
          this.setState({loadingProceed: false, statusProceed: 'failure',
            submitMessageError: get(err, 'graphQLErrors[0].message') || err.message,
          })
        }
      })
  }

  initiatePayment = () => {
    const {history, location:{pathname}} = this.props
    const {plan} = this.state

    this.setState({openConfirmationModal: false})
    history.push({pathname:`${pathname}/payment`, state: {plan: plan}})
  }

  getSubscriptionStatus(subscription) {
    const notificationStatus = subscription && subscription.lastPaymentFailed ? 'error' :
      subscription && !subscription.isActive ? 'warning' : 'info'
    const actionText  = subscription && !subscription.isExpired ? '' : 'activate'
    const currentActionText  = subscription && subscription.isActive ?
      (subscription && subscription.lastPaymentFailed ? 'terminate' : 'cancel') :  (((subscription && subscription.isExpired) || !subscription) ? 'activate' : '')
    return {notificationStatus, actionText, currentActionText}
  }

  renderPlansList(subscription) {
    const {statusProceed, submitMessageError, openConfirmationModal} = this.state
    const {t, classes, location:{pathname}, accounts, client, manualVpsPlan} = this.props
    const {accountVerification} = config
    const accountTypes = map(accounts, (a) => a.accountSubtype)
    const subscriptionPlan = subscription && vpsSubscriptionPlans[subscription.planName]
    const subscriptionStatus = this.getSubscriptionStatus(subscription)
    const missingVerifications = getMissingVerifications(client,
      first(get(client, 'appropTests')),get(client, 'globalQuestionnaire'),'',accountVerification)
    const missingDocs = values(getMissingDocs(client)).some(d => d === true)
    const verificationPending = missingVerifications.length === 1 && includes(missingVerifications,'kycStatus')
      && !missingDocs

    let filteredVpsSubscriptionPlans = vpsSubscriptionPlans
    if (manualVpsPlan) {
      filteredVpsSubscriptionPlans = filter( vpsSubscriptionPlans, (plan) => plan.value === manualVpsPlan)
    }

    return <React.Fragment>
      {openConfirmationModal && this.renderConfirmationModal(subscription)}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <VpsSubscriptionMsg
            accounts={accounts}
            subscription={subscription}
            notificationStatus={subscriptionStatus.notificationStatus}
            verificationRequired={verificationPending ? 'pending' : missingVerifications.length >0}
            manualVpsPlan={manualVpsPlan}
          />
        </Grid>
        {subscription && <Grid item xs={12}>
          <PageSubTitle>{subscriptionPlan.label}
            {subscription.type !== vpsSubscriptionTypes.free.value &&
              <Link to={`${pathname}/payment`}>
                <Typography variant='body2' className={classNames(classes.textBlue,classes.inlineTypography)}>
                  <Trans {...messages.changePaymentMethod} />
                </Typography>
              </Link>}
          </PageSubTitle>
        </Grid>}
      </Grid>
      <Grid container spacing={isMobile() ? 3 : 5}>
        {map((filter(filteredVpsSubscriptionPlans, {'enabled': true})), (plan) => {
          const isFree = get(plan, 'isFree').some((r) => accountTypes.includes(r))
          const currentPlan = subscription && subscription.planName === plan.value
          const actionMessage = currentPlan?'cancel':'changePlan'

          return <Grid item key={plan.label} xs={12} md={4}>
            <PlanCard
              key={plan.label}
              plan={plan}
              subscription={subscription}
              buttonAction ={() => this.openConfirmationModal(subscriptionStatus.currentActionText,plan,isFree, currentPlan)}
              showActions={isEmpty(missingVerifications)}
              isFree={isFree}
              currentState={{statusProceed, submitMessageError}}
              currentPlan={currentPlan}
              subscriptionStatus={subscriptionStatus}
              actionText={t(messages[actionMessage].i18nKey, messages[actionMessage].defaults)}
              noActions={subscription}
              vps
            />
          </Grid>
        }
        )}
      </Grid>
    </React.Fragment>
  }

  renderNotificationMessage() {
    return (
      <Grid item  xs={12}>
        <NotificationBar status={'error'}>
          <Trans {...messages.newVpsDisabled}/>
        </NotificationBar>
      </Grid>
    )
  }

  render() {
    const {vpsSubscriptionsLoading, classes, vpsSubscriptions, clientLoading, loading} = this.props

    if (vpsSubscriptionsLoading || clientLoading || loading) return <Loading />

    const currentSubscription = !isEmpty(vpsSubscriptions)
      && vpsSubscriptions.find(s => s.status !== vpsSubscriptionStatuses.deleted.value)
    const showPlans = currentSubscription || (config.featuresConfig.vps && config.featuresConfig.vps.createNewVPS)

    return (
      <React.Fragment>
        <Grid container className={classes.container} spacing={1}>
          {showPlans && this.renderPlansList(currentSubscription)}
          {!showPlans && this.renderNotificationMessage()}
        </Grid>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => ({
      clientLoading: props.data?.loading,
      clientError: props.data?.error,
      history: props.ownProps.history,
      client: get(props.data, 'viewer'),
    }),
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const accounts = filter(get(data, 'viewer.accounts', []), (a) => a.accountSubtype)
      return {
        error,
        loading,
        accounts,
      }
    }
  }),
  graphql(VPS_SUBSCRIPTIONS_QUERY, {
    props: (props) => ({
      vpsSubscriptionsLoading: props.data?.loading,
      vpsSubscriptionsError: props.data?.error,
      vpsSubscriptions: get(props.data, 'viewer.vpsSubscriptions', []),
      manualVpsPlan: get(props.data, 'viewer.manualVpsPlan'),
    }),
  }),
  graphql(CANCEL_VPS_SUBSCRIPTION_MUTATION, {
    name: 'cancelVpsSubscription',
  }),
  graphql(CREATE_VPS_SUBSCRIPTION_MUTATION, {
    name: 'createVpsSubscription',
  }),
  graphql(TERMINATE_VPS_SUBSCRIPTION_MUTATION, {
    name: 'terminateVpsSubscription',
  }),
)(VpsPlan)
