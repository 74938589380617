import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import messages from '../../assets/messages'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Amount from '../Common/Amount'
import {get, filter, includes, flowRight as compose} from 'lodash'
import {referralStatuses} from '@bdswiss/common-enums'
import PageSubTitle from '../Common/PageSubTitle'
import classNames from 'classnames'

const styles = theme => ({
  inline: {
    display: 'inline-block'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 400,
    float: 'right' as const
  },
  amount: {
    display: 'block',
    marginTop: 10,
  },
  amountCurrency: {
    fontSize: 24,
    fontWeight: 200,
  },
  amountValue: {
    fontSize: 36,
    fontWeight: 200,
    marginTop: 20,
  },
  amountFraction: {
    fontSize: 24,
    fontWeight: 200,
  },
  financialInfo:{
    justifyContent: 'space-between'
  },
  financialInfoItem: {
    marginBottom: 20,
    color: theme.palette.secondary.dark,
  },
  disabled: {
    opacity: 0.5
  },
  button: {
    float: 'right' as const,
    '&:hover':{
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      padding: 0,
      float: 'unset' as const,
    },
  },
  rightItem:{
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right'
    },
    '&:last-child':{
      [theme.breakpoints.up('md')]: {
        textAlign: 'right'
      }
    }
  }
})

class FinancialInfo extends Component<any,any> {

  render() {
    const {classes, referralDetails, referrals, rewardsObj, pendingRewards} = this.props
    const activated = filter(referrals, (r) => includes([referralStatuses.activated.value, referralStatuses.claimed.value], r.status))

    return <Grid container spacing={3}>
      <Grid item xs={12}>
        <PageSubTitle><Trans {...messages.financialInfo} /></PageSubTitle>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container className={classes.financialInfo}>
              <Grid item md={1} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.reward} /></Typography>
                <Amount value={get(rewardsObj, 'claimedRewards', 0)+pendingRewards} currency={'EUR'} locale='en-US' classes={{
                  root: classes.amount,
                  currency: classes.amountCurrency,
                  value: classes.amountValue,
                  fraction: classes.amountFraction
                }} />
              </Grid>
              <Grid item md={1} xs={6} className={classNames(classes.financialInfoItem, classes.rightItem)}>
                <Typography variant='caption'><Trans {...messages.referrals} /></Typography>
                <Typography variant='body1' className={classes.amountValue}>{get(referralDetails, 'countReferrals', 0)}</Typography>
              </Grid>
              <Grid item md={1} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.activated} /></Typography>
                <Typography variant='body1' className={classes.amountValue}>{activated.length}</Typography>
              </Grid>
              <Grid item md={2} xs={6} className={classNames(classes.financialInfoItem, classes.rightItem)}>
                <Typography variant='caption'><Trans {...messages.unclaimedRewards} /></Typography>
                <Typography variant='body1' className={classes.amountValue}>
                  <Amount value={pendingRewards} currency={'EUR'} locale='en-US' classes={{
                    root: classes.amount,
                    currency: classes.amountCurrency,
                    value: classes.amountValue,
                    fraction: classes.amountFraction
                  }} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }
}

export default  compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(FinancialInfo)
