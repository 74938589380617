//@ts-nocheck
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get, values, isEmpty, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../../Common/Loading'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'
import {frontends} from '@bdswiss/common-enums'
import messages from '../../../../assets/messages'
import {getPlatform} from '../../../../common/utils'
import {DLOCAL_BANKS_QUERY} from '../../../../graphql/queries'
import GenericIFramedProvider from './GenericIFramedProvider'
import {withCreateDeposit, PaymentActionButton} from './helpers'


const validators = {
  pidNumber: (value) => !value && messages.requiredField,
  bankCode: (value) => !value && messages.requiredField
}

const style = (theme) => ({
  menuItemAvatar: {
    width: 20,
    height: 20,
    float: 'left',
    marginRight: 10
  }
})

export class DLocalProvider extends Component {

  state = {form: {pidNumber: '', bankCode: ''}, errors: {}}

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      const validate = validators[k]
      errors[k] = validate && validate(form[k])
    })
    this.setState({errors})
  }

  formIsValid() {
    const {form, errors} = this.state
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(form).filter((v) => v))
  }

  handleChange(field, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [field]: value
      }
    }, () => this.validateFields())

  }

  doCreateDeposit() {

    if (!this.formIsValid()) return

    const {providerProperties: {provider}, account, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms} = this.props

    const {form: {bankCode, pidNumber}} = this.state

    this.setState({gettingIframeUrl: true})

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        dlocalBanks: bankCode,
        pIdNo: pidNumber,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url: iframeUrl}} = newDeposit
        this.setState({iframeUrl, gettingIframeUrl: false})
      })
      .catch((e) => {
        this.setState({gettingIframeUrl: false})
        onError && onError(e)
      })
  }

  render() {
    const {errors, form: {pidNumber, bankCode}, gettingIframeUrl} = this.state

    const {classes, bankList:{error: bankLoadError, loading, data: bankOptions}} = this.props

    if (loading || !bankOptions) return <Loading />

    const providerUnavailable = bankLoadError || bankOptions.length < 1

    const {iframeUrl} = this.state

    return (
      <React.Fragment>
        {iframeUrl && <GenericIFramedProvider {...this.props} iframeUrl={iframeUrl} />}
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              <Trans {...messages.enterPaymentDetails} />
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="dlocal-bankCode">
                <Trans {...messages.bank} />
              </InputLabel>
              <Select
                id="dlocal-bankCode"
                type="text"
                error={!!errors.bankCode}
                value={bankCode}
                onChange={(e) => this.handleChange('bankCode', e.target.value)}
                inputProps={{name: 'bankCode'}}
              >
                {bankOptions.map((opt) => (
                  <MenuItem key={opt.code} value={opt.code}>
                    {opt.logo && <Avatar className={classes.menuItemAvatar} src={opt.logo}/>}
                    <ListItemText>{opt.name}</ListItemText>
                  </MenuItem>)
                )}
              </Select>
              {
                errors.bankCode &&
                <FormHelperText className={classes.error}><Trans {...errors.bankCode} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="dlocal-pid-number">
                <Trans {...messages.personalIdentificationNumber} />
              </InputLabel>
              <Input
                error={!!errors.pidNumber}
                id="dlocal-pid-number"
                type="text"
                value={pidNumber}
                onChange={(e) => this.handleChange('pidNumber', e.target.value)}
              />
              {
                errors.pidNumber &&
                <FormHelperText className={classes.error}><Trans {...errors.pidNumber} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          <PaymentActionButton
            loading={gettingIframeUrl}
            disable={providerUnavailable || !this.formIsValid()}
            onClick={() => this.doCreateDeposit()}
          />
          {providerUnavailable &&
            <Grid item xs={12}>
              <Typography align="center" variant="caption" className={classes.spacingTop}>
                <Trans {...messages.providerUnavailable} />
              </Typography>
            </Grid>
          }
        </Grid>

      </React.Fragment>
    )
  }
}

DLocalProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withNamespaces(),
  withCreateDeposit,
  graphql(DLOCAL_BANKS_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      bankList: {
        loading,
        error,
        data: get(data, 'viewer.dlocalBanksList')
      }
    })
  })
)(DLocalProvider)
