import React, {Component} from 'react'
import {get, reject,isEmpty,orderBy, first, omit, flowRight as compose} from 'lodash'
import {withRouter, Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import AppContext from '../../Common/contexts/AppContext'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {graphql} from 'react-apollo'
import {TRANSACTIONS_CLIENT_QUERY, ACCOUNTS_QUERY} from '../../../graphql/queries'
import {Loading} from '../../Common/Loading'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import {isMobile} from '../../../common/utils/browser'
import Images from '../../Common/Images'
import MobileFilterModal from './MobileFilterModal'
import NotificationBar from '../../Common/NotificationBar'
import {CANCEL_WITHDRAWAL_MUTATION, CANCEL_PARTIAL_DEPOSIT_MUTATION, COMPLETE_PARTIAL_DEPOSIT_MUTATION} from '../../../graphql/mutations'
import {AlertDialog} from '../../Common/Dialog'
import MobilePaymentsList from './MobilePaymentsList'
import * as PaymentsHistoryHelper from './PaymentsHistoryHelper'
import DesktopPaymentsList from './DesktopPaymentsList'
import PaymentsFilter from './PaymentsFilter'
import {scrollElementIntoView} from '../../../common/utils'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  statusIcon: {
    'vertical-align':'middle',
    'font-size':'20px',
    'margin-right':'8px',
  },
  doneIcon: {
    'color': theme.palette.green.color
  },
  pendingIcon: {
    'color': theme.palette.yellow.color
  },
  filterButton: {
    cursor: 'pointer'
  },
  link: {
    color: theme.palette.primary.main,
  },
  cancelBtn:{
    marginTop:0
  },
  emptyListsIcon: {
    ...theme.emptyListsIcon
  },
  textCenter:{
    textAlign: 'center' as const
  },
  textRight:{
    textAlign: 'right' as const
  },
  cancelPartialBtn: {
    color: theme.palette.red.color,
  },
  lineHeight: {
    lineHeight: '2.5rem',
  }
})

class History extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  static propTypes = {
    clientInfo: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object
  }
  constructor(props) {
    super(props)
    this.state={
      isToggleOn: true,
      filter: {},
      loading: false,
      open: false,
      transactionDetails: '',
      limit: first(PaymentsHistoryHelper.transactionsLimits),
      limitEnd: 0,
      localLimit:first(PaymentsHistoryHelper.transactionsLimits),
      disableShowMore: false,
      lastRow: 0
    }

    this.setLoading = this.setLoading.bind(this)
  }

  setLoading = (loading) => this.setState({loading})

  cancelPendingWithdrawal(row) {
    this.setState({loading: true})
    this.props.cancelWithdrawal({variables: {withdrawalId: row.id}}).then((res) => {
      this.setState({loading: false})
    }).catch((e) => {
      this.setState({loading: false})
    })
  }

  transformTooltip(text) {
    return text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, c => c.toUpperCase())
  }

  handleOpen = (row, locale, country) => {
    const {classes} = this.props
    this.setState({open: true,transactionDetails: PaymentsHistoryHelper.fieldDetails(row, locale, country, classes)})
  }

  handleClose = () => {
    this.setState({open: false})
  }

  openMobileFilter(accounts,accountId) {
    this.context.showNotification({
      type: 'success',
      status: 'filters',
      buttonMessage: <Trans {...messages.apply} />,
      content: <MobileFilterModal filter={this.state.filter}  accounts={accounts} acountId={accountId} rows={this.state.limit}
        onChange={filter => this.setState({filter})} transactionsLimits={PaymentsHistoryHelper.transactionsLimits} />,
    })
  }

  highlightRow(lastRow) {
    this.setState({highlightRow: true, lastRow})
    setTimeout(() => this.setState({highlightRow: false}), 1000)
  }

  render() {
    const {classes, loading, accounts, accountsLoading, accountId, t, transactions, transactionsCount} = this.props
    const {themePreference, country} = this.context
    const {transactionDetails, limitEnd, lastRow} = this.state
    if (loading || accountsLoading) {
      return (<Loading />)
    }
    const rootProps = omit(this.props, ['classes'])

    const sortedTransactions = !isEmpty(transactions) ? orderBy(transactions, ['createdAt'], ['desc']) : []
    const {blockedDeposit} = this.context

    return (
      <React.Fragment>
        <AlertDialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
          title={t(messages.transactionDetails.i18nKey, messages.transactionDetails.defaults)}
        >
          {transactionDetails}
        </AlertDialog>
        {!blockedDeposit && isEmpty(transactions) && limitEnd >= transactionsCount && <NotificationBar status="info">
          <Trans {...messages.noTransactions} /> <Trans {...messages.depositPending} />
          <Link to="/transactions" className={classes.link}> <Trans {...messages.depositNow} /> </Link>
        </NotificationBar>}
        {isEmpty(sortedTransactions) && limitEnd >= transactionsCount &&
        <Grid container
          direction="column"
          justifyContent="space-between"
          alignItems="center">
          <Grid item className={classes.textCenter}>
            <img className={classes.emptyListsIcon} src={Images[`payments-history-empty-${themePreference}.png`]} alt='noTrades' />
          </Grid>
        </Grid>}
        {(!isEmpty(sortedTransactions) || limitEnd < transactionsCount) && <React.Fragment>
          {isMobile() ? <React.Fragment>
            <Grid container spacing={0} justifyContent="flex-end">
              <Button
                onClick={() => {this.openMobileFilter(this.props.accounts, this.props.accountId)}}
                size="small" className={classes.filterButton}>
                <img src={Images['filterIcon.png']} alt="filter"/>
              </Button>
            </Grid>
            <MobilePaymentsList {...rootProps} state={this.state} setState={(state) => this.setState(state)}
              setLoading={this.setLoading} handleOpen={(row, locale) => this.handleOpen(row, locale, country)}
              cancelPendingWithdrawal={(row) => this.cancelPendingWithdrawal(row)} scrollTo={() => this.setState(() => scrollElementIntoView('scroll-row', 250))}
              highlightRow={(lastRow) => this.highlightRow(lastRow)} lastRow={lastRow}/>
          </React.Fragment> : <React.Fragment>
            <PaymentsFilter
              accountId={accountId}
              accounts={accounts}
              filter={this.state.filter}
              rows={this.state.localLimit}
              onChange={filter => this.setState({filter, localLimit: filter.limit})}
              transactionsLimits={PaymentsHistoryHelper.transactionsLimits}/>
            <DesktopPaymentsList {...rootProps} state={this.state} setState={(state) => this.setState(state)}
              setLoading={this.setLoading} handleOpen={(row, locale) => this.handleOpen(row, locale, country)}
              cancelPendingWithdrawal={(row) => this.cancelPendingWithdrawal(row)} scrollTo={() => this.setState(() => scrollElementIntoView('scroll-row', 250))}
              highlightRow={(lastRow) => this.highlightRow(lastRow)} lastRow={lastRow}/>
          </React.Fragment>}
        </React.Fragment>}
      </React.Fragment>
    )
  }
}


export default compose (
  withStyles(styles),
  withNamespaces(),
  withRouter,
  graphql(TRANSACTIONS_CLIENT_QUERY, {
    options: () => ({
      variables: {limit: first(PaymentsHistoryHelper.transactionsLimits), offset: 0},
    }),
    props: ({data: {error, loading}, data}: any)  => ({
      error,
      loading,
      transactions: get(data, 'viewer.transactions'),
      transactionsCount: get(data, 'viewer.transactionsCount'),
    })}),
  graphql(CANCEL_WITHDRAWAL_MUTATION, {
    name: 'cancelWithdrawal',
    options: {
      refetchQueries: [{query: TRANSACTIONS_CLIENT_QUERY}],
    }
  }),
  graphql(CANCEL_PARTIAL_DEPOSIT_MUTATION, {
    name: 'cancelDeposit',
    options: {
      refetchQueries: [{query: TRANSACTIONS_CLIENT_QUERY}],
    },
  }),
  graphql(COMPLETE_PARTIAL_DEPOSIT_MUTATION, {
    name: 'completeDeposit',
    options: {
      refetchQueries: [{query: TRANSACTIONS_CLIENT_QUERY}],
    },
  }),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, accountsLoading, viewer}}: any) => {
      const allAccounts = get(viewer, 'accounts', [])
      const accounts = reject(allAccounts, a => a.isDemo || a.isHidden)
      return {error,
        accountsLoading,
        accounts
      }}
  }),
)(History)
