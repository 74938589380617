import React, {Component} from 'react'
import {get, isEmpty, some, omit, omitBy, mapValues, findKey, forEach, isArray, uniq, filter, find, includes,
  flowRight as compose, trim, toString, lowerCase} from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {accountTypes, countries} from '@bdswiss/common-enums'
import {withNamespaces, Trans} from 'react-i18next'
import FormHelperText from '@material-ui/core/FormHelperText'
import {graphql} from 'react-apollo'
import {CLIENT_PROFILE_QUERY, PROFILE_SETTINGS_QUERY} from '../../../../graphql/queries'
import {UPDATE_OWN_DETAILS_MUTATION, UPDATE_OWN_EMAIL_MUTATION} from '../../../../graphql/mutations'
import messages from '../../../../assets/messages'
import {Loading} from '../../../Common/Loading'
import LoadingButton from '../../../Common/LoadingButton'
import NotificationBar from '../../../Common/NotificationBar'
import AddressAutocomplete from '../../../Common/AddressAutocomplete'
import {withRouter, Link} from 'react-router-dom'
import AppContext from '../../../Common/contexts/AppContext'
import {scrollElementIntoView, formatGooglePlacesAddress} from '../../../../common/utils'
import {
  validateEmail,
  validatePhone,
  validateDate,
  validateDOB,
  validateCharacters,
  validateCountryCodePrefix,
  validateAddress,
  validateLength
} from '../../../../common/utils/validations'
import CountriesSelect from '../../../Common/CountriesSelect'
import {isMobile} from '../../../../common/utils/browser'
import Button from '@material-ui/core/Button'
import {getCountries} from '../../../../common/utils/requests'
import {hasOnlyWalletProductAccount} from '../../../../common/utils/accounts'
import {getLocaleMoment, nationalitiesListWithFlag} from '../../../../common/utils/general'
import classNames from 'classnames'
import {momentLocales, fnsLocales} from '../../../../common/utils/uioptions'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import * as dateFnsLocales from 'date-fns/locale'
import {InnerAppContext} from '../../../../common/types'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: 24,
  },
  secondary: {
    margin: theme.spacing(1),
    marginLeft: 0,
    '&:hover': {
      'cursor': 'pointer',
    }
  },
  errorMessage:{
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  linkText:{
    color:  theme.palette.primary.main,
  },
  displayNone:{
    display: 'none'
  },
  helperTextError:{
    color:  theme.palette.error.main,
  },
  button:{
    marginTop: 0
  },
  disabledField:{
    color: theme.palette.secondary.dark,
    cursor: 'pointer'
  },
  orangeButton: {
    backgroundColor: '#ef9756',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ef7e2b',
    }
  },
  contentPadding:{
    padding: '0 1.5rem'
  },
  italicText:{
    fontStyle: 'italic'
  },
  dateInput:{
    width: '100%'
  }
})

class PersonalDetails extends Component<any,any> {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }
  static contextType = AppContext
  context!: InnerAppContext
  geocoderService: any
  constructor(props) {
    super(props)
    const form = {
      ...mapValues(omit(props.viewer, ['address','secondaryEmails','secondaryPhones']), (v) => v || ''),
      ...mapValues(props.viewer.address, (v) => v || ''),
      ...mapValues(props.viewer.secondaryEmails, (v) => v || ''),
      ...mapValues(props.viewer.secondaryPhones, (v) => v || ''),
    }

    if (window.google && !isEmpty(window.google))
      this.geocoderService = new window.google.maps.Geocoder()

    this.state = {
      form,
      errors: {}, showSecondaryEmail: false, showSecondaryPhone: false, loading: false, status: '',
      submitMessageError:'',
      countryFlag:'',
      nationalityFlag:'',
      countryChanged: false,
      nationalityChanged: false,
      formChanged: {},
      errorsKeys: {},
      allowedCountries: [],
      nationalitiesFlags: nationalitiesListWithFlag(),
    }
  }

  componentDidMount() {
    const {locale, companyObject, accounts} = this.context
    moment.locale(getLocaleMoment(locale, momentLocales.excluded))
    let walletAccount = find(accounts, (a) => get(accountTypes[a.__typename], 'walletProduct'))
    if (walletAccount) walletAccount = find(accountTypes, (accountType) => accountType.key === get(walletAccount, '__typename'))
    this.getCountries(walletAccount && walletAccount.value, companyObject.key)
  }

  getSecondaryEmail() {
    this.setState({showSecondaryEmail: true})
  }

  getSecondaryPhone() {
    this.setState({showSecondaryPhone: true})
  }

  handleChange (name, value) {
    const removeSpecialChars = ['line1', 'houseNumber', 'city', 'region', 'zip']

    if (includes(removeSpecialChars, name)) {
      value = toString(value)
        .normalize('NFD')
        .replace(/[']/g, ' ')
        // Allows for arabic characters as well as per task SKYG-793
        .replace(/[^a-zA-Z0-9 \u0600-\u06FF]/g, '')
    }

    if (name === 'region' && !validateLength(value, 32)) {
      this.setState((state) => ({
        ...state,
        form: {
          ...state.form,
          [name]: value,
        },
        errors: {
          ...state.errors,
          [name]: true
        },
        errorsKeys: {
          ...state.errorsKeys,
          [name]: 'maxRegionLengthError'
        },
        formChanged:{
          ...state.formChanged,
          [name]: false,
        },
      }))
      return
    }
    else {
      this.setState(state => ({
        form: {
          ...state.form,
          [name]: value
        },
        errors: {
          ...state.errors,
          [name]: !value,
        },
        formChanged:{
          ...state.formChanged,
          [name]: (value !== this.props.viewer[name]),
        },
        status: ''
      }))
    }
  }

  scrollUp(errors : any = null) {
    this.setState(() => scrollElementIntoView(isEmpty(errors)?gridScroll:findKey(errors), 250))
  }

  handleSubmit(countryList) {
    const {form, form: {email}, nationalitiesFlags} = this.state
    const {viewer, t, handleChangeParent} = this.props

    const validateField = (field, value, countryList, viewer) => {
      switch (field) {
        case 'phone':
        case 'secondaryPhone1':
          return validatePhoneField(field, value, countryList)
        case 'email':
          return validateEmailField(value)
        case 'secondaryEmail1':
          return validateSecondaryEmailField(value)
        case 'firstName':
        case 'lastName':
          return validateNameField(field, value, viewer)
        case 'birthday':
          return validateDOBField(value)
        case 'line1':
        case 'houseNumber':
        case 'city':
        case 'region':
          return validateAddressField(field, value, viewer)
        case 'zip':
          return validateZipField(value)
        default:
          return isEmpty(value)
      }
    }

    const validatePhoneField = (field, value, countryList) => {
      const prefixError = !validateCountryCodePrefix(value, countryList)
      setErrorState(field, prefixError)
      return isEmpty(value) || !validatePhone(value, true) || prefixError
    }

    const validateEmailField = (value) => isEmpty(value) || !validateEmail(value)

    const validateSecondaryEmailField = (value) => !validateEmail(value)

    const validateNameField = (field, value, viewer) => {
      const isChanged = viewer[field] !== value
      if (isChanged) {
        const nameError = validateCharacters(trim(value))
        setErrorState(field, nameError)
        return !!nameError
      }
      return false
    }

    const validateDOBField = (value) => !validateDOB(value)

    const validateAddressField = (field, value, viewer) => {
      const isChanged = viewer[field] !== value
      if (isChanged) {
        if (field === 'region' && !validateLength(value, 32)) {
          return true
        }
        const addressError = validateAddress(value)
        setErrorState(field, addressError)
        return !!addressError
      }
      return false
    }

    const validateZipField = (value) => {
      if (!validateLength(value, 10)) {
        setErrorState('zip', 'maxZipCodeLengthError')
        return true
      }
      return false
    }

    const setErrorState = (field, error) => {
      this.setState(state => ({errorsKeys: {...state.errorsKeys, [field]: error}}))
    }

    const errors = {}
    for (const field of Object.keys(form)) {
      errors[field] = validateField(field, form[field], countryList, viewer)
    }

    if (some(omitBy(errors, (e, key) => includes(['secondaryEmail1', 'secondaryPhone1', 'houseNumber'], key) && !form[key]))) {
      this.setState({errors},() => {this.scrollUp(errors)})
      return
    }

    const variables = omitBy(form, (value, key) => {
      if ((!['secondaryEmail1', 'secondaryPhone1'].includes(key) && isEmpty(value)) || viewer[key] === value ||
          (key === 'secondaryEmail1' && get(viewer['secondaryEmails'], 'secondaryEmail1') === value ) ||
          (key === 'secondaryPhone1' && get(viewer['secondaryPhones'], 'secondaryPhone1') === value)
      ) {
        return true
      }
    })

    variables['line1'] = (variables['houseNumber'] || variables['line1']) && (form.houseNumber ? `${form.line1} {HouseNumber:[${form.houseNumber}]}` : form.line1)
    variables['country'] = variables['country'] && variables['country'].toLowerCase()
    variables['nationality'] = variables['nationality'] && get(find(nationalitiesFlags,(a)=> variables['nationality'] && a.key === variables['nationality']), 'keyCountry', '')
    //@ts-ignore
    variables['birthday'] = variables['birthday'] && moment(variables['birthday'], validateDate(variables['birthday'], true, true)).format('YYYY-MM-DD') /* convert to DB format */
    variables['firstName'] = variables['firstName'] && trim(variables['firstName'])
    variables['lastName'] = variables['lastName'] && trim(variables['lastName'])

    this.setState({status: '', loading: true})
    this.props.updateDetails({variables}).then((succ) => {
      if (variables.email) {
        this.props.updateOwnEmail({variables: {email}}).then((updateEmailResult) => {
          this.scrollUp()
          this.setState({loading: false,status: 'success',submitMessageError: '',formChanged: false})
          handleChangeParent && handleChangeParent('profileCompleted', true)
        }).catch((err) => {
          if (err.networkError) {
            this.setState({loading: false,status: 'failure',
              submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
            })
          } else {
            this.setState({loading: false,status: 'failure',
              submitMessageError: get( err, 'graphQLErrors[0].message') || err.message,
            })
          }
        })
      } else {
        this.scrollUp()
        this.setState({loading: false,status: 'success',submitMessageError: '',formChanged: false})
        handleChangeParent && handleChangeParent('profileCompleted', true)
      }
    }).catch((err) => {
      if (err.networkError) {
        this.setState({loading: false,status: 'failure',
          submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
        })
      } else {
        this.setState({loading: false,status: 'failure',
          submitMessageError: get( err, 'graphQLErrors[0].message') || err.message,
        })
      }
    })
  }

  fillInAddressFields(address) {
    this.geocoderService.geocode({address}, result => {
      const addressComponents = get(result[0], 'address_components',{})
      const addressForm : any = !isEmpty(addressComponents) && formatGooglePlacesAddress(addressComponents)

      forEach(addressForm, (field, key) => {
        if (isArray(field)) {
          const removeDuplcates = uniq(field)
          this.handleChange(key, removeDuplcates.join(', '))
        } else {
          this.handleChange(key, field)
        }
      })
    })
  }

  handleChangeCountry(value) {
    this.setState({showCountries:false,countryFlag:filter(countries,(country)=>country.value===value), status: ''})
  }

  handleChangeNationality(value) {
    const {nationalitiesFlags} = this.state
    this.setState({nationalityFlag: get(find(nationalitiesFlags,(a)=>
      value && (a.key === lowerCase(value) || lowerCase(a.value) === lowerCase(value))), 'label', ''), status: ''})
  }

  setStateOuter = (prop, value) => {
    this.setState({[prop]: value})
  }

  async getCountries(product, company) {
    const {viewer: {country}} = this.props
    await getCountries(product, company)
      .then((res) => {
        if (!find(res.countries, {key: country})) {
          res.countries.push(countries[country])
        }
        this.setState({allowedCountries: res.countries})
      })
      .catch((err) => err)
  }

  render() {
    const {classes, t, economicProfile, location, emailPendingChange, viewer, viewerLoading, profileLoading, register} = this.props
    const {form : {firstName, lastName, line1, houseNumber, birthday, email, secondaryEmail1, city, region, country, zip, phone,
      secondaryPhone1, nationality}, showSecondaryEmail, showSecondaryPhone, loading, submitMessageError,
    status, countryFlag, countryChanged, nationalityChanged, nationalityFlag, formChanged, errors,
    errorsKeys, allowedCountries, nationalitiesFlags} = this.state
    const {accounts, locale} = this.context

    if (!viewer || viewerLoading || profileLoading) return <Loading />
    const {blockedDeposit} = this.context
    const maxDate = moment().subtract(18,'year').format('YYYY-MM-DD')
    const minDate = moment().subtract(120,'year').format('YYYY-MM-DD')
    const showPendingConfirmation = emailPendingChange !== null
    const countryList = allowedCountries || []
    const birthdayFormat =  birthday && validateDate(birthday, true, true)
    const birthdayValidFormat = birthdayFormat && moment(birthday,birthdayFormat).format('YYYY-MM-DD')
    const isFormChanged = includes(formChanged, true)
    const nationalitiesList = filter(nationalitiesFlags, nationality => !hasOnlyWalletProductAccount(accounts) ?
      (!nationality.forbidden && !nationality.hidden) : !nationality.hidden)
    return (
      <React.Fragment>
        <Grid container spacing={0} id={gridScroll}>
          {status==='success' && !register &&
          <Grid item xs={12} sm={12}>
            <NotificationBar status='success'>
              <Trans {...messages.personalDetailsChanged} />
              {isEmpty(economicProfile)  && !blockedDeposit && <span>&nbsp;
                <Trans {...messages.updateEconomicProfile}
                  components={[
                    <Link to={`${location.pathname.replace(/[^/]+$/,'')}economic`} className={classes.linkText}>Economic Profile</Link>,
                  ]} /></span>
              }
            </NotificationBar>
          </Grid>
          }
          <Grid item xs={12} sm={12}>
            <Typography variant="caption" color="secondary" className={classNames(classes.contentPadding, classes.italicText)}>
              *<Trans {...messages.confirmValidPersonalDetails} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="firstName"
                      name="firstName"
                      label={t(messages.firstName.i18nKey, messages.firstName.defaults)}
                      fullWidth
                      autoComplete="firstName"
                      error={errors.firstName}
                      value={firstName}
                      onChange={(e) => this.handleChange('firstName', e.target.value)}
                    />
                    {firstName && errors.firstName && messages[errorsKeys.firstName] &&
                      <FormHelperText className={classes.errorMessage}><Trans {...messages[errorsKeys.firstName]} /></FormHelperText>}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="lastName"
                      name="lastName"
                      label={t(messages.lastName.i18nKey, messages.lastName.defaults)}
                      fullWidth
                      autoComplete="lastname"
                      value={lastName}
                      error={errors.lastName}
                      onChange={(e) => this.handleChange('lastName', e.target.value)}
                    />
                    {lastName && errors.lastName && messages[errorsKeys.lastName] &&
                      <FormHelperText className={classes.errorMessage}><Trans {...messages[errorsKeys.lastName]} /></FormHelperText>}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={get(dateFnsLocales, locale) || get(dateFnsLocales, fnsLocales[locale]) || get(dateFnsLocales, fnsLocales['default'])}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="birthday"
                        label={t(messages.dateOfBirth.i18nKey, messages.dateOfBirth.defaults)}
                        format="dd/MM/yyyy"
                        value={birthday ? new Date(birthdayValidFormat) : null}
                        onChange={(selectedDate) => this.handleChange('birthday', moment(selectedDate).format('DD/MM/YYYY'))}
                        KeyboardButtonProps={{'aria-label': 'change date'}}
                        className={classes.dateInput}
                        maxDate={maxDate}
                        minDate={minDate}
                        error={this.state.errors.birthday}
                        helperText={null}
                        required={true}
                        okLabel={t(messages.submit.i18nKey, messages.submit.defaults)}
                        cancelLabel={t(messages.cancel.i18nKey, messages.cancel.defaults)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="email"
                      name="email"
                      label={t(messages.email.i18nKey, messages.email.defaults)}
                      fullWidth
                      autoComplete="email"
                      value={email}
                      error={errors.email}
                      onChange={(e) => this.handleChange('email', e.target.value)}
                      helperText={showPendingConfirmation &&
                        <span className={classes.helperTextError}>
                          <Trans {...messages.pendingConfirmation} /> {emailPendingChange}</span>}
                    />
                    {isEmpty(secondaryEmail1) && <Typography
                      variant="body2"
                      onClick={() => this.getSecondaryEmail()}
                      className={classes.secondary}
                      color="primary"
                    >
                      <Trans {...messages.secondaryEmailText} /></Typography>}
                  </Grid>
                  {(showSecondaryEmail || secondaryEmail1) &&<Grid item xs={12} sm={12}>
                    <TextField
                      id="secondaryEmail1"
                      name="secondaryEmail1"
                      label={t(messages.secondaryEmailLabel.i18nKey, messages.secondaryEmailLabel.defaults)}
                      fullWidth
                      autoComplete="secondaryEmail1"
                      value={secondaryEmail1}
                      onChange={(e) => this.handleChange('secondaryEmail1', e.target.value)}
                      error={errors.secondaryEmail1}
                    />
                  </Grid>}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="phone"
                      name="phone"
                      label={t(messages.phone.i18nKey, messages.phone.defaults)}
                      fullWidth
                      autoComplete="phone"
                      value={phone}
                      error={errors.phone}
                      onChange={(e) => this.handleChange('phone', e.target.value)}
                    />
                    {phone && errors.phone && errorsKeys.phone &&
                    <FormHelperText className={classes.errorMessage}><Trans {...messages.prefixValidation} /></FormHelperText>}
                    {isEmpty(secondaryPhone1) &&<Typography
                      variant="body2"
                      onClick={() => this.getSecondaryPhone()}
                      className={classes.secondary}
                      color="primary"
                    >
                      <Trans {...messages.secondaryPhoneText} /></Typography>}
                  </Grid>
                  {(showSecondaryPhone || secondaryPhone1) && <Grid item xs={12} sm={12}>
                    <TextField
                      id="secondaryPhone1"
                      name="secondaryPhone1"
                      label={t(messages.secondaryPhoneLabel.i18nKey, messages.secondaryPhoneLabel.defaults)}
                      fullWidth
                      autoComplete="secondaryPhone1"
                      value={secondaryPhone1}
                      onChange={(e) => this.handleChange('secondaryPhone1', e.target.value)}
                      error={errors.secondaryPhone1}
                    />
                    {secondaryPhone1 && errors.secondaryPhone1 && errorsKeys.secondaryPhone1 &&
                    <FormHelperText className={classes.errorMessage}><Trans {...messages.prefixValidation} /></FormHelperText>}
                  </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    {this.geocoderService && <AddressAutocomplete
                      address={line1}
                      onChange={(value) => this.handleChange('line1', value)}
                      onSelect={(value) => value && this.fillInAddressFields(value)}
                      error={errors.line1}
                      country={country}
                    />}
                    {!this.geocoderService &&  <TextField
                      required
                      id="line1"
                      label={t(messages.streetLabel.i18nKey, messages.streetLabel.defaults)}
                      fullWidth
                      value={line1}
                      onChange={(e) => this.handleChange('line1', e.target.value)}
                    />
                    }
                    {!line1 && <FormHelperText className={classes.errorMessage}>{<Trans {...messages.streetNameRequired} />}</FormHelperText>}
                    {line1 && errors.line1 && messages[errorsKeys.line1] &&
                      <FormHelperText className={classes.errorMessage}><Trans {...messages[errorsKeys.line1]} /></FormHelperText>}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="houseNumber"
                      name="houseNumber"
                      label={t(messages.houseNumberLabel.i18nKey, messages.houseNumberLabel.defaults)}
                      fullWidth
                      autoComplete="houseNumber"
                      error={errors.houseNumber}
                      value={houseNumber}
                      onChange={(e) => this.handleChange('houseNumber', e.target.value)}
                    />
                    {!houseNumber && <FormHelperText className={classes.errorMessage}>{<Trans {...messages.houseNumberRequired} />}</FormHelperText>}
                    {houseNumber && errors.houseNumber && messages[errorsKeys.houseNumber] &&
                      <FormHelperText className={classes.errorMessage}><Trans {...messages[errorsKeys.houseNumber]} /></FormHelperText>}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="city"
                      name="city"
                      label={t(messages.cityLabel.i18nKey, messages.cityLabel.defaults)}
                      fullWidth
                      autoComplete="city"
                      value={city}
                      error={errors.city}
                      onChange={(e) => this.handleChange('city', e.target.value)}
                    />
                    {city && errors.city && messages[errorsKeys.city] &&
                    <FormHelperText className={classes.errorMessage}><Trans {...messages[errorsKeys.city]} /></FormHelperText>}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="zip"
                      name="zip"
                      label={t(messages.zipLabel.i18nKey, messages.zipLabel.defaults)}
                      fullWidth
                      autoComplete="zip"
                      value={zip}
                      error={errors.zip}
                      onChange={(e) => this.handleChange('zip', e.target.value)}
                    />
                    {
                      zip && errors.zip && messages[errorsKeys.zip] &&
                      <FormHelperText className={classes.errorMessage}><Trans {...messages[errorsKeys.zip]} /></FormHelperText>
                    }
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="region"
                      name="region"
                      label={t(messages.regionLabel.i18nKey, messages.regionLabel.defaults)}
                      fullWidth
                      value={region}
                      error={errors.region}
                      onChange={(e) => this.handleChange('region', e.target.value)}
                    />
                    {region && errors.region && messages[errorsKeys.region] &&
                    <FormHelperText className={classes.errorMessage}><Trans {...messages[errorsKeys.region]} /></FormHelperText>}
                  </Grid>
                  <Grid item xs={12} sm={12}  id="countryGrid">
                    <CountriesSelect
                      countryList={countryList}
                      handleChangeField={this.handleChangeCountry.bind(this)}
                      handleChange={this.handleChange.bind(this)}
                      setStateOuter={this.setStateOuter.bind(this)}
                      errors={errors}
                      value={(!countryChanged)?get(find(countries,(countryObj, key)=> (country)  && (key === country)),'label',''):countryFlag[0]['label']}
                      name="country"
                      label={t(messages.countryLabel.i18nKey, messages.countryLabel.defaults)}
                      returnKey
                      showRequired
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <CountriesSelect
                      countryList={nationalitiesList}
                      handleChangeField={this.handleChangeNationality.bind(this)}
                      handleChange={this.handleChange.bind(this)}
                      setStateOuter={this.setStateOuter.bind(this)}
                      errors={errors}
                      value={(!nationalityChanged) ?get(find(nationalitiesList,(a)=> nationality && a.keyCountry === nationality), 'label', '') : nationalityFlag}
                      name="nationality"
                      label={t(messages.nationalityLabel.i18nKey, messages.nationalityLabel.defaults)}
                      returnKey
                      showRequired
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LoadingButton
                  id='loadingButton'
                  onClick={() => this.handleSubmit(countryList)}
                  disabled={!isFormChanged || loading}
                  hideProgressBar={!isFormChanged}
                  status={status}
                  className={isMobile() || register ? classes.button:''}
                ><Trans {...messages.saveButtonSettings} />
                </LoadingButton>
                {status==='failure' &&
                  <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
              </Grid>
            </Grid>
            {register &&
            <Grid container spacing={3}>
              <Grid item>
                <Button className={classes.orangeButton} variant="contained" size="large" onClick={() => this.props.history.push('/')}>
                  <Trans {...messages.skipButton}/>
                </Button>
              </Grid>
            </Grid>}
          </Grid>
        </Grid>
      </React.Fragment>
    )

  }
}

function applyValues(client) {
  const address = get(client['address'], 'line1')
  const houseNumber = get(client['address'], 'houseNumber')
  const streetName = houseNumber ? address.replace(houseNumber, '').trim() : address
  return {
    firstName: get(client, 'firstName') || '',
    lastName: get(client, 'lastName') || '',
    birthday: get(client, 'birthday') || '',
    email: get(client, 'email') || '',
    phone: get(client, 'phone') || '',
    houseNumber: houseNumber || '',
    line1: streetName || '',
    city: get(client['address'], 'city') || '',
    zip: get(client['address'], 'zip') || '',
    region: get(client['address'], 'region') || '',
    country: get(client['address'], 'country') || '',
    nationality: get(client, 'nationality') || '',
    secondaryEmails: {
      secondaryEmail1 : get(client['secondaryEmails'], 'secondaryEmail1') || '',
    },
    secondaryPhones: {
      secondaryPhone1 : get(client['secondaryPhones'], 'secondaryPhone1') || '',
    },
  }
}

export default compose (
  withStyles(styles),
  withNamespaces(),
  withRouter,
  graphql(CLIENT_PROFILE_QUERY, {
    props: (props) => {
      const viewer = get(props.data, 'viewer')
      const emailPendingChange = get(props.data, 'viewer.emailPendingChange')
      return {
        error:props.data?.error,
        viewerLoading: props.data?.loading,
        viewer:applyValues(viewer),
        emailPendingChange,
      }
    }
  }),
  graphql(UPDATE_OWN_DETAILS_MUTATION, {
    name: 'updateDetails',
    options: {
      refetchQueries: [{query: CLIENT_PROFILE_QUERY}],
    }, //@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(UPDATE_OWN_EMAIL_MUTATION, {
    name: 'updateOwnEmail',
    options: {
      refetchQueries: [{query: CLIENT_PROFILE_QUERY}],
    },//@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => {
      const economicProfile = get(props.data, 'viewer.globalQuestionnaire')
      return {
        profileLoading: props.data?.loading,
        economicProfile
      }
    }
  })
)(PersonalDetails)
