import React, {Component} from 'react'
import {get, flowRight as compose, map, keys, omit} from 'lodash'
import {Grid} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import {Trans, withNamespaces} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import {config} from '../../config'
import messages from '../../assets/messages'
import {getFormattedAmount} from '../../common/utils/'
import Avatar from '@material-ui/core/Avatar'
import AppContext from '../Common/contexts/AppContext'
import {accountTypes} from '@bdswiss/common-enums'
import {AlertDialog} from '../Common/Dialog'
import {downloadMt5Notes, serversLabel} from '../../common/utils/uioptions'
import {InnerAppContext} from '../../common/types'

const styles = (theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  subContent: {
    paddingTop: 10
  },
  avatar: {
    marginRight: 10,
    backgroundColor: theme.palette.primary.main,
    width: 30,
    height: 30,
    fontSize: 16,
    fontWeight: 400
  },
  flex: {
    display: 'flex'
  }
})


class Mt5TradeModal extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  render() {
    const {classes, showMt5Popup, t, onClose, mt5Account} = this.props
    const {downloadTrader} = config
    const {locale} = this.context
    const formattedMt5Balance = mt5Account && getFormattedAmount({amount: mt5Account.balance, currency: mt5Account.currency, locale})
    const category = mt5Account && get(accountTypes[mt5Account.__typename], 'category')
    const downloadTraderInfo = get(downloadTrader, category)
    let mt5Notes = downloadMt5Notes
    if (get(mt5Account, 'balance') > 0) {
      mt5Notes = omit(downloadMt5Notes, ['fundYourAccountText']) as any
    }

    return <AlertDialog
      open={showMt5Popup}
      onClose={() => onClose()}
      title={t(messages.mt5TTradeNoteTitle.i18nKey, messages.mt5TTradeNoteTitle.defaults)}
      agreeText={t(messages.confirm.i18nKey, messages.confirm.defaults)}
      onAgree={() => onClose()}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <Typography variant={'body1'}>
            <Trans {...messages.mt5TTradeNote} components={[<Link to={'/accounts/add-account'} className={classes.link} onClick={() => onClose()}> </Link>]}/>
          </Typography>
        </Grid>
        {map(keys(mt5Notes), (note, index) => <Grid item xs={12} className={classes.flex} key={note}>
          <Avatar className={classes.avatar}>
            {index + 1}
          </Avatar>
          <div>
            <Typography variant={'body1'}>
              <Trans {...messages[note]} values={{server: get(downloadTraderInfo, 'shortLabel')}}
                components={[<a href={get(downloadTraderInfo, 'downloadTraderInfo')} className={classes.link} target="_blank" rel="noopener noreferrer"> </a>,
                  <Link to={`/transactions/${get(mt5Account, 'id')}/deposit`} onClick={() => onClose()} className={classes.link}> </Link>]}
              />
            </Typography>
            {get(downloadMt5Notes[note], 'subContent') && map(downloadMt5Notes[note].subContent, (content, index) => {
              const subContent = messages[`${content.key}MT5Note`] || get(downloadTraderInfo, `${content.subContent}.real`)
              return <div className={(Number(index) >= 1) ? classes.subContent : ''} key={index}>
                <Typography variant={'caption'}>
                  <Trans {...messages[content.key]} values={{balance: formattedMt5Balance}} />
                </Typography>
                {subContent && <Typography variant={'body1'}>
                  {(messages[`${content.key}MT5Note`]) ? <Trans {...messages[`${content.key}MT5Note`]} values={{server: serversLabel[category]}} components={[<Link to={'/accounts'} className={classes.link} onClick={() => onClose()}> </Link>]}/>
                    : subContent}
                </Typography>}
              </div>})}
          </div>
        </Grid>)}
      </Grid>
    </AlertDialog>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
)(Mt5TradeModal)
