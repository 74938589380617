import {safeParseJSON} from '.'

const KEY_PREFIX = '__BD:'

export const getItem = <T>(key, defaultValue? : T):T => {
  let item = (window.localStorage) ? localStorage.getItem(KEY_PREFIX + key) : defaultValue
  item = safeParseJSON(item) || item
  return item as T || defaultValue as T
}

export const storeItem = (key, item) => {
  if (window.localStorage) {
    localStorage.setItem(KEY_PREFIX + key, JSON.stringify(item))
  }
}

export const removeItem = (key) => (window.localStorage) ? localStorage.removeItem(KEY_PREFIX + key) : ''

export const storeSessInfoInLocalStorage = (sessionInfo) => storeItem('si', sessionInfo)

export const getSessInfoFromLocalStorage = () => getItem('si')

export const removeSessInfoFromLocalStorage = () => {
  removeItem('si')
  removeItem('subscription_redirect')
  removeItem('autochartist_id')
  removeItem('termsUpdate')
  removeItem('termsUpdateSeen')
  removeItem('ibduediligence_redirect')
  removeItem('partnersNoticeUpdate')
  removeItem('partnersNoticeUpdateSeen')
  removeItem('temporaryCeasingPopup')
  removeItem('ebook')
  removeItem('migrationPopupClosed')
}
