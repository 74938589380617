import React from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import ChangePlan from './ChangePlan'
import Payment from './Payment'

const PlanRoutes = ({match: {path,url}}) => (
  <Switch>
    <Route path={`${path}/payment`} component={Payment} />
    <Route path={`${path}/change-plan`} component={ChangePlan} />
    <Route exact path={`${path}`} component={ChangePlan} />
  </Switch>
)

export default withRouter(PlanRoutes)
