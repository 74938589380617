import MobileDetect from 'mobile-detect'
import platform from 'platform'

export function getPlatform() {
  const md = new MobileDetect(window.navigator.userAgent)
  const isMobile = md.mobile()

  return {
    platform: 'web',
    platformType: isMobile ? 'mobile' : 'desktop',
    operatingSystem: `${platform.os.family}/${platform.os.architecture}`,
    device: platform.product,
    browser: platform.name,
  }
}

export function isMobile() {
  const md = new MobileDetect(window.navigator.userAgent)
  return !!md.mobile() || !!md.versionStr('Mobile')
}

export function isIos() {
  const md = new MobileDetect(window.navigator.userAgent)
  return !!md.mobile() && md.is('iOS')
}

export function isAndroid() {
  const md = new MobileDetect(window.navigator.userAgent)
  return !!md.mobile() && md.is('AndroidOS')
}

export function putFile(file, signedUrl, RequestClass = window.XMLHttpRequest) {
  return new Promise((resolve, reject) => {
    const xhr = new RequestClass()
    xhr.open('PUT', signedUrl)
    if (file.name) {
      xhr.setRequestHeader('Content-Disposition', `filename=${encodeURIComponent(file.name)}`)
    }
    xhr.setRequestHeader('x-amz-acl', 'public-read')
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(undefined)
        } else {
          reject(`PUT: Wrong status code ${xhr.status}`)
        }
      }
    }
    xhr.send(file)
  })
}

export function scrollElementIntoView(elementId, delay = 0, scrollOptions = {block: 'start' as const, behaviour: 'smooth' as const}) {
  const el = document.getElementById(elementId)
  el && setTimeout(() => {
    el && el.scrollIntoView(scrollOptions)
  }, delay)
}

export function setCookie(name, value, expirationDays, domain = '') {
  const d = new Date()
  d.setTime(d.getTime() + (expirationDays * 24 * 60 * 60 * 1000))
  return document.cookie = `${name}=${value};expires=${d.toUTCString()};path=/;domain=${domain};`
}

export function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop()!.split(';').shift()
  }
}

export function addClassToElement(id, className) {
  const element = document.getElementById(id)
  element && element.classList.add(className)
}

export function removeClassToElement(id, className) {
  const element = document.getElementById(id)
  element && element.classList.remove(className)
}
