import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {range, get, map, reject, includes, isEmpty, pickBy, size, startsWith, keys, omit, flowRight as compose} from 'lodash'
import {withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Check from '@material-ui/icons/Check'
import {withNamespaces, Trans} from 'react-i18next'
import {supportQuestions} from '@bdswiss/common-enums'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import messages from '../../assets/messages'
import chatIcon from '../../assets/images/icon-contact-chat@2x.png'
import {config} from '../../config'
import {scrollElementIntoView, putFile, isWhiteLabel} from '../../common/utils'
import {CLIENT_DATA_QUERY, FAQ_QUERY} from '../../graphql/queries'
import {CREATE_SUPPORT_TICKET, SIGN_UPLOAD_URL_MUTATION} from '../../graphql/mutations'
import AppContext from '../Common/contexts/AppContext'
import LoadingButton from '../Common/LoadingButton'
import Images from '../Common/Images'
import {validateEmail,checkFileMimeType} from '../../common/utils/validations'
import moment from 'moment'
import {isMobile} from '../../common/utils/browser'
import CustomNotificationAlert from '../Common/CustomNotificationAlert'
import SelectDocument from '../Settings/Documents/SelectDocument'
import {hasValidAccountSubTypes} from '../../common/utils/accounts'
import LivechatBubble from '../Common/LivechatBubble'
import classNames from 'classnames'
import {Loading} from '../Common/Loading'
import {InnerAppContext, ProvidedProps, Viewer} from '../../common/types'
const showSeeAllFAQs = config.featuresConfig.contactMethods.faqs.url && config.featuresConfig.contactMethods.faqs.url !== null ? true : false

const styles = theme => ({
  icon: {
    maxWidth: 44,
    maxHeight: 44,
  },
  listItemRoot: {
    [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: 5,
  },
  listItemBorder:{
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
  },
  pointer: {
    cursor: 'pointer',
  },
  checkMark: {
    color: theme.palette.secondary.main,
    transform: 'scale(0.8)',
  },
  instructionsTitle: {
    paddingLeft: 60,
  },
  instructionsStep: {
    fontSize: 16,
    color: '#000'
  },
  formControl: {
    marginTop: 30,
  },
  buttonsAlign: {
    textAlign: 'center' as const
  },
  hiddenInput:{
    display: 'none',
  },
  defaultImage:{
    width: 85
  },
  uploadFileImage:{
    cursor:'pointer',
  },
  container: {
    width: '100%',
    margin: 0
  },
  noMargin:{
    marginTop: 0
  },
  itemRoot: {
    paddingLeft: 0
  },
  faqContent: {
    paddingBottom: 25,
    '& a' :{
      color: theme.palette.primary.main
    },
    '& em' :{
      fontStyle: 'normal'
    }
  },
  faqContainer: {
    marginTop: 12
  },
  link: {
    color: theme.palette.primary.main,
  }
})

const SupportMethodDetailsGridId = 'support-method-details'

const WhatsappSupport = ({classes, company, phone}) => (
  <div>
    <Typography variant='h3' classes={{root: classes.instructionsTitle}}>
      <Trans {...messages.whatsappInstructionsTitle} />
    </Typography>
    {range(5).map(step =>
      <ListItem key={step} button>
        <ListItemIcon>
          <Typography variant='caption'>{step+1}. </Typography>
        </ListItemIcon>
        <ListItemText inset classes={{primary: classes.instructionsStep}}>
          <Typography variant='body1'>
            <Trans {...messages[`whatsappInstructionsStep${step+1}`]} values={{company: company.brandLabel, companyWhatsapp: phone}}/>
          </Typography>
        </ListItemText>
      </ListItem>
    )}
  </div>
)

const TelegramSupport = ({classes,company, phone}) => {
  const countSteps = (messages[`${company.brand}telegramInstructionsStep1`] && size(pickBy(messages, (v, k) => includes(k,`${company.brand}telegramInstructionsStep`)))) ||
    size(pickBy(messages, (v, k) => startsWith(k,'telegramInstructionsStep')))
  return (
    <div>
      <Typography variant='h3' classes={{root: classes.instructionsTitle}}>
        <Trans {...messages.telegramInstructionsTitle} />
      </Typography>
      {range(countSteps).map(step =>
        <ListItem key={step} button>
          <ListItemIcon>
            <Typography variant='caption'>{step+1}. </Typography>
          </ListItemIcon>
          <ListItemText inset classes={{primary: classes.instructionsStep}}>
            {messages[`${company.brand}telegramInstructionsStep${step+1}`] && <Typography variant='body1'>
              <Trans {...messages[`${company.brand}telegramInstructionsStep${step+1}`]} values={{company: company.brandLabel, companyTelegram: phone}} />
            </Typography>}
            {!messages[`${company.brand}telegramInstructionsStep${step+1}`] && <Typography variant='body1'>
              <Trans {...messages[`telegramInstructionsStep${step+1}`]} values={{company: company.brandLabel, companyTelegram: phone}} />
            </Typography>}
          </ListItemText>
        </ListItem>
      )}
    </div>
  )}

const CallBackSupport = ({classes, company, history}) => (
  <Grid container spacing={3}>
    <Grid container item justifyContent="center">
      <Typography variant='h3'>
        <Trans {...messages.bookCall} />
      </Typography>
    </Grid>
    <Grid container item justifyContent="center">
      <Typography variant='body1'><Trans {...messages.bookCallp1} values={{company: company.brandLabel}}/></Typography>
    </Grid>
    <Grid container item justifyContent="center">
      <Typography variant='body1'><Trans {...messages.bookCallp2} /></Typography>
    </Grid>
    <Grid container spacing={1} item direction="row" justifyContent="center" alignItems="center">
      <Grid item className={classes.buttonsAlign}>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => history.push({pathname:'/callback', state: {type: 'support',  prevPath: 'support'}})}
        >
          <Trans {...messages.requestCallback} />
        </Button>
      </Grid>
    </Grid>
  </Grid>
)

const OneOnOneCallback = ({classes, history}) =>
  (<Grid container spacing={3}>
    <Grid container item justifyContent="center">
      <Typography variant='h3'>
        <Trans {...messages.oneOnOne} />
      </Typography>
    </Grid>
    <Grid container item justifyContent="center">
      <Typography variant='body1'><Trans {...messages.oneOnOnep1}/></Typography>
    </Grid>
    <Grid container spacing={1} item direction="row" justifyContent="center" alignItems="center">
      <Grid item className={classes.buttonsAlign}>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => history.push({pathname:'/callback', state: {type: 'oneOnOne',  prevPath: '/support'}})}
        >
          <Trans {...messages.requestCallback} />
        </Button>
      </Grid>
    </Grid>
  </Grid>)

const FAQs = ({classes, faqs, activeQuestion, selectQuestion, showSeeAllFAQs}) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant='h3' className={classes.noMargin}>
        <Trans {...messages.frequentlyAskedQuestions} />
      </Typography>
    </Grid>
    <Grid item xs={12} className={classes.faqContainer}>
      <List>
        {map(faqs, (faq, key) => <React.Fragment key={key}>
          <ListItem classes={{root: classNames(classes.itemRoot, classes.pointer, activeQuestion !== key && classes.listItemBorder)}} onClick={() => selectQuestion(key)}>
            <ListItemText>
              <Typography variant='subtitle1'>{faq.faqTitle}</Typography>
            </ListItemText>
            {activeQuestion === key ? <ExpandMoreIcon className={classes.checkMark}/> : <ChevronRightIcon className={classes.checkMark}/>}
          </ListItem>
          {activeQuestion === key && <Typography variant='body1' className={classNames(classes.listItemBorder, classes.faqContent)}>
            <span dangerouslySetInnerHTML={{__html: faq.faqContent.replace(/\n/g, '<br />')}}/>
          </Typography>}
        </React.Fragment>
        )}
        {showSeeAllFAQs && <ListItem classes={{root: classNames(classes.itemRoot, classes.pointer, classes.listItemBorder)}}
          onClick={() => window.open(`${get(config, 'weblinks.websiteUrl')}/${get(config, 'featuresConfig.contactMethods.faqs.url')}`, '_blank')}>
          <ListItemText>
            <Typography variant='subtitle1' className={classes.link}>
              <Trans {...messages.seeAllFaqs} />
            </Typography>
          </ListItemText>
        </ListItem>}
      </List>
    </Grid>
  </Grid>
)
type EmailSupportComponentProps = ProvidedProps<ReturnType<typeof styles>>  & {
  location: any
  context: React.ContextType<typeof AppContext>
  clientId: number
  createOwnSupportTicket: (x)=>Promise<any>
  signUploadUrl: (x)=>Promise<any>
}
type EmailSupportComponentState = {
  name?: string,
  email?:string,
  topic: string
  fileChecked?: boolean
  sendLoading: boolean,
  sendStatus: string
  emailError: boolean,
  message: string
  uploadedFile: string
  errorFiles: any,
  phone?: string
}
class EmailSupportComponent extends React.Component<EmailSupportComponentProps,EmailSupportComponentState> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    const startDate =  moment(new Date().setDate((new Date()).getDate()-30)).format('DD/MM/YYYY')
    const endDate =  moment(new Date()).format('DD/MM/YYYY')
    this.state = {
      topic: props.location.search.indexOf('complaint') > 0 ? 'complaintQuestion' :
        props.location.search.indexOf('account') > 0 ? 'accountQuestion' : '',
      sendLoading: false,
      sendStatus: '',
      emailError: false,
      message: props.location.search.indexOf('account') > 0
        ? props.t(messages.proFreeTicks.i18nKey, {startDate: startDate, endDate:endDate})
        : '',
      uploadedFile: '',
      errorFiles: {},
    }
  }

  handleFileUpload(prop, e) {
    const files = e.target.files
    this.setState(state => ({...state,[prop]: files, fileChecked: false}))
    const {errorFiles} = this.state
    map(files,(file) => {
      try {
        checkFileMimeType(file).then( (res:any) => {
          this.setState<'fileChecked'>({fileChecked: true})
          const fileFormat = res && res.indexOf('image') === -1 && res.indexOf('pdf') === -1
          const fileSize = file.size > 1 * 1024 * 1024
          if (fileFormat || fileSize) {
            // @ts-ignore
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [prop]: {
                  name: file.name,
                  error: fileFormat ? 'format' : 'size'
                }
              }
            }))
          }
          else {
            const errors = omit(errorFiles,[prop])
            this.setState<'errorFiles'>({errorFiles:errors})
          }
        })
      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
  }

  checkEmail(email) {
    if (!validateEmail(email)) {
      this.setState((state) => ({...state, emailError: true}))
    } else {
      this.setState((state) => ({...state, emailError: false}))
    }
    this.setState((state) => ({...state, email: email}))
  }

  async getSignedFileKeys() {
    const {clientId, signUploadUrl} = this.props
    const {uploadedFile} = this.state
    return Promise.all(map(uploadedFile, async (file) =>
      signUploadUrl({variables: {clientId}}).then((res) => {
        const {signedUrl, plainUrl} = res.data.signedDetails
        return putFile(file, signedUrl).then(() => plainUrl)
      })
    ))
  }

  async sendMessage(context) {
    const {name, email, phone, topic, message, uploadedFile} = this.state
    const {locale} = context
    const {companyObject} = this.context
    const {complaintsEmail, supportEmail}= config
    this.setState((state) => ({...state, sendLoading: true}))

    let to = `${supportQuestions[topic].email}@${companyObject.emailDomain}`
    if (isWhiteLabel()) {
      switch (topic) {
        case 'complaintQuestion': to = complaintsEmail
          break
        default: to = supportEmail
      }
    }

    const subject = supportQuestions[topic].localization.t(locale)
    const fileLinks =  !isEmpty(uploadedFile) && await this.getSignedFileKeys()

    this.props.createOwnSupportTicket({variables: {name, email, phone, topic: subject, message, to, attachments: fileLinks}})
      .then(() => {
        this.setState((state) => ({...state,name: '', email: '', phone: '', topic: '', message: '', sendLoading: false,
          sendStatus: 'success'}))
        context.showNotification({
          type: 'document-upload',
          status: 'success',
          content: <Trans {...messages.supportMessageSuccess} />,
          buttonMessage: <Trans {...messages.continue} />,
          onClose: () => this.props.history.push('/')
        })
      })
  }

  render() {
    const {t, classes,context, context:{locale , company}} = this.props
    const {sendLoading, sendStatus, uploadedFile, errorFiles, emailError} = this.state
    const {themePreference} =this.context

    return (
      <Grid container spacing={1}>
        <Typography variant='h3' className={classes.noMargin}>
          <Trans {...messages.contactForm} />
        </Typography>
        <FormControl className={classes.formControl} fullWidth>
          <TextField
            required
            label={t(messages.name.i18nKey, messages.name.defaults)}
            value={this.state.name}
            onChange={e => this.setState({name: e.target.value})}
          />
        </FormControl>
        <Grid item xs={6} lg={6}>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              required
              label={t(messages.email.i18nKey, messages.email.defaults)}
              value={this.state.email}
              onChange={e => this.checkEmail(e.target.value)}
              error={this.state.emailError}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={6}>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              label={t(messages.phone.i18nKey, messages.phone.defaults)}
              value={this.state.phone}
              onChange={e => this.setState<'phone'>({phone: e.target.value})}
            />
          </FormControl>
        </Grid>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel required htmlFor="supportTopic"><Trans {...messages.supportYourQuestion} /></InputLabel>
          <Select
            value={this.state.topic}
            onChange={e => {this.setState<'topic'>({topic: e.target.value as string})}}
            inputProps={{id: 'supportTopic'}}
          >
            {map(reject(supportQuestions, (q) => {
              //@ts-ignore
              if (q.disabled || includes(q.blockedCompanies, company)) {
                return true
              }
              return false
            }), (m) => (
              <MenuItem
                value={m.key}
                key={m.key}
              >
                {m.localization.t!(locale)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} fullWidth>
          <TextField
            required
            multiline
            label={t(messages.supportYourMessage.i18nKey, messages.supportYourMessage.defaults)}
            value={this.state.message}
            onChange={e => this.setState<'message'>({message: e.target.value})}
          />
        </FormControl>
        <FormControl className={classes.formControl} fullWidth>
          <Grid container>
            <Grid item md={5}>
              <SelectDocument
                multiple
                uploadedFile={uploadedFile}
                onChange={(e) => this.handleFileUpload('uploadedFile', e)}
                category={`document-${themePreference}`}
                status={(uploadedFile && uploadedFile.length>0) ? (includes(keys(errorFiles), 'uploadedFile') ? 'error': 'success') : ''}
                errors={!isEmpty(errorFiles) && errorFiles.uploadedFile}
                src={Images[`upload-document-${themePreference}.png`]}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <Typography variant="caption"> <Trans {...messages.uploadRules} /> </Typography>
            </Grid>
          </Grid>
        </FormControl>
        <LoadingButton
          id='sendEmailMessageButton'
          onClick={() => this.sendMessage(context)}
          disabled={!this.state.name || !this.state.email || !this.state.topic || !this.state.message || sendLoading || emailError || !isEmpty(errorFiles)}
          status={sendStatus}
          hideProgressBar={(!this.state.name || !this.state.email || !this.state.topic || !this.state.message || emailError || !isEmpty(errorFiles)) && !sendLoading }
        >
          <Trans {...messages.sendMessage} />
        </LoadingButton>
      </Grid>
    )
  }
}

const EmailSupport = compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CREATE_SUPPORT_TICKET, {
    name: 'createOwnSupportTicket',
  }),
  graphql(SIGN_UPLOAD_URL_MUTATION, {
    name: 'signUploadUrl',
  }),
  withRouter
)(EmailSupportComponent)

type SupportProps = ProvidedProps<ReturnType<typeof styles>> & {
  viewer: Viewer
  loadingFaq
  loading
  faqs
}
type SupportState =  {
  activeMethod: string
  activeQuestion?: string
}
class Support extends Component<SupportProps,SupportState> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    const {featuresConfig: {contactMethods}} = config
    const hasFaq = get(contactMethods, 'faqs')
    let initialActiveMethod: string = 'email'
    if (props.location.search.indexOf('complaint') > 0 || props.location.search.indexOf('account') > 0)
      initialActiveMethod = 'email'
    else if (size(contactMethods) === 1) {
      initialActiveMethod = Object.keys(contactMethods)[0]
    }
    this.state = {
      activeMethod: initialActiveMethod ? initialActiveMethod : (hasFaq) ? 'faqs' : '',
    }
  }

  startLiveChat() {
    this.setState({activeMethod: 'liveChat'})
  }

  handleSupportMethodChange(activeMethod) {
    this.setState({activeMethod}, () => scrollElementIntoView(SupportMethodDetailsGridId, 250))
  }

  render() {
    const {classes, t, viewer: {email, firstName, lastName}, history, faqs, loading, loadingFaq} = this.props
    const {activeMethod, activeQuestion} = this.state
    const {featuresConfig: {livechat, contactMethods}} = config
    const fullName =[firstName, lastName].join(' ')
    const {companyObject, accounts} = this.context

    if (loading || loadingFaq) return <Loading />

    return (
      <React.Fragment>
        {activeMethod === 'liveChat' && <LivechatBubble fullName={fullName} email={email} />}
        <PageTitle title={t(messages.support.i18nKey, messages.support.defaults)} />
        <PageBody>
          <Grid container>
            <Grid item xs={12}>
              <CustomNotificationAlert support/>
            </Grid>
          </Grid>
          <Grid container spacing={isMobile() ? 3 : 5} className={classes.container}>
            <Grid item xs={12} lg={5}>
              <Typography variant='h3' className={classes.noMargin}>
                <Trans {...messages.supportMethodsSubtitle} />
              </Typography>
              <List>
                {map(pickBy(contactMethods, (c) => !c.disabled), (method, key) => {
                  if (method.accountSubTypes && !hasValidAccountSubTypes(accounts, method.accountSubTypes)) {
                    return
                  }
                  return <React.Fragment key={key}>
                    <ListItem classes={{root: classNames(classes.listItemRoot, classes.pointer, classes.listItemBorder)}} onClick={() => this.handleSupportMethodChange(key)}>
                      <ListItemIcon>
                        <img src={Images[`icon-contact-${method.key}@2x.png`]} alt="" className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText>
                        <Trans {...messages[method.key]} />
                      </ListItemText>
                      {activeMethod === key && <Check className={classes.checkMark} />}
                    </ListItem>
                  </React.Fragment>
                })}
                {livechat && <React.Fragment>
                  <ListItem classes={{root: classNames(classes.listItemRoot, classes.pointer, classes.listItemBorder)}} onClick={() => this.startLiveChat()}>
                    <ListItemIcon>
                      <img src={chatIcon} alt="" className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText>
                      <Trans {...messages.liveChat} />
                    </ListItemText>
                    {activeMethod === 'liveChat' && <Check className={classes.checkMark} />}
                  </ListItem>
                </React.Fragment>}
              </List>
            </Grid>
            <Grid id={SupportMethodDetailsGridId} item xs={12} lg={6}>
              {activeMethod === 'whatsapp' && <WhatsappSupport classes={classes} company={companyObject} phone={contactMethods.whatsapp.phone}/>}
              {activeMethod === 'telegram' && <TelegramSupport classes={classes} company={companyObject} phone={contactMethods.telegram.phone}/>}
              {activeMethod === 'email' && <EmailSupport context={this.context} clientId={this.props.viewer.id}/>}
              {(activeMethod === 'callback') && <CallBackSupport classes={classes} company={companyObject} history={history}/>}
              {(activeMethod === 'oneOnOneSession') && <OneOnOneCallback classes={classes} history={history} />}
              {(activeMethod === 'faqs') && <FAQs classes={classes} faqs={faqs} activeQuestion={activeQuestion}
                selectQuestion={(key) => this.setState({activeQuestion: (activeQuestion === key) ? '' : key})} showSeeAllFAQs={showSeeAllFAQs}/>}
            </Grid>
          </Grid>
        </PageBody>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => {
      const viewer = get(props.data, 'viewer')
      return {
        loading:props.data?.loading,
        error: props.data?.error,
        viewer: viewer,
      }
    }
  }),
  graphql(FAQ_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: (props) => ({
      loadingFaq: props.data?.loading,
      errorFaq: props.data?.error,
      faqs: get(props.data, 'faq'),
    })
  }),
)(Support)
