import React, {Component} from 'react'
import {get, flowRight as compose} from 'lodash'
import moment from 'moment'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import FormHelperText from '@material-ui/core/FormHelperText'
import {graphql} from 'react-apollo'
import {CLIENT_PROFILE_QUERY} from '../../graphql/queries'
import {UPDATE_OWN_DETAILS_MUTATION} from '../../graphql/mutations'
import messages from '../../assets/messages'
import {Loading} from './Loading'
import LoadingButton from './LoadingButton'
import AppContext from './contexts/AppContext'
import {validateDOB,validateDate} from '../../common/utils/validations'
import Typography from '@material-ui/core/Typography'
import {getLocaleMoment} from '../../common/utils/general'
import {momentLocales, fnsLocales} from '../../common/utils/uioptions'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import * as dateFnsLocales from 'date-fns/locale'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: 24,
  },
  errorMessage:{
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  submitButton:{
    textAlign:'center' as const,
    marginBottom: '1rem'
  },
  fieldTitle:{
    marginBottom: '1rem'
  },
  disabledField:{
    color: theme.palette.black.color,
    opacity: 0.6,
    cursor: 'pointer'
  },
  dateInput:{
    width: '100%'
  }
})
class DateOfBirthForm extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext

  constructor(props) {
    super(props)

    const form = {
      birthday: '',
    }

    this.state = {
      form,
      errors: {}, loading: false, status: '',
      submitMessageError:'',
    }
  }

  componentDidMount() {
    const {locale} = this.context
    moment.locale(getLocaleMoment(locale, momentLocales.excluded))
  }

  handleChange (name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      }
    }))
  }

  handleSubmit() {
    const {form: {birthday}} = this.state
    const {t} = this.props
    const errors = {}
    errors['birthday'] = !validateDOB(birthday)
    if (errors['birthday']) {
      this.setState({errors})
      return
    }

    const birthdayFormat =  validateDate(birthday, true, true)
    const variables : any = {birthday} //@ts-ignore
    variables['birthday'] = birthdayFormat && moment(variables['birthday'],birthdayFormat).format('YYYY-MM-DD') /* convert to DB format */

    this.setState({status: '', loading: true})
    this.props.updateDetails({variables}).then((succ) => {
      this.setState({loading: false,status: 'success',submitMessageError: ''})
    }).catch((err) => {
      if (err.networkError) {
        this.setState({loading: false,status: 'failure',
          submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
        })
      } else {
        this.setState({loading: false,status: 'failure',
          submitMessageError: get( err, 'graphQLErrors[0].message') || err.message,
        })
      }
    })
  }


  render() {
    const {classes, viewer, viewerLoading, profileLoading, t} = this.props
    const {form : {birthday}, loading, submitMessageError,status} = this.state

    if (!viewer || viewerLoading || profileLoading) return <Loading />
    const {locale} =this.context
    const maxDate = moment().subtract(18,'year').format('YYYY-MM-DD')
    const minDate = moment().subtract(120,'year').format('YYYY-MM-DD')
    const birthdayFormat =  birthday && validateDate(birthday, true, true)
    const birthdayValidFormat = birthdayFormat && moment(birthday,birthdayFormat).format('YYYY-MM-DD')

    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12}>
            <Typography variant='body1' className={classes.fieldTitle} >
              <Trans {...messages.dobField}/>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={get(dateFnsLocales, locale) || get(dateFnsLocales, fnsLocales[locale]) || get(dateFnsLocales, fnsLocales['default'])}>
              <KeyboardDatePicker
                margin="normal"
                id="birthday"
                label={t(messages.dateOfBirth.i18nKey, messages.dateOfBirth.defaults)}
                format="dd/MM/yyyy"
                value={birthday ? new Date(birthdayValidFormat) : null}
                onChange={(selectedDate) => this.handleChange('birthday', moment(selectedDate).format('DD/MM/YYYY'))}
                KeyboardButtonProps={{'aria-label': 'change date'}}
                className={classes.dateInput}
                maxDate={maxDate}
                minDate={minDate}
                error={this.state.errors.birthday}
                helperText={null}
                required={true}
                okLabel={t(messages.submit.i18nKey, messages.submit.defaults)}
                cancelLabel={t(messages.cancel.i18nKey, messages.cancel.defaults)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} className={classes.submitButton}>
            <LoadingButton
              id='loadingButton'
              onClick={() => this.handleSubmit()}
              disabled={loading}
              status={status}
            ><Trans {...messages.saveButtonSettings} />
            </LoadingButton>
            {status==='failure' &&
            <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
          </Grid>
        </Grid>
      </React.Fragment>
    )

  }
}

export default compose (
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_PROFILE_QUERY, {
    props: ({data: {error, loading}, data}:any) => {
      const viewer = get(data, 'viewer')
      return {
        error,
        viewerLoading: loading,
        viewer,
      }
    }
  }),
  graphql(UPDATE_OWN_DETAILS_MUTATION, {
    name: 'updateDetails',
    options: {
      refetchQueries: [{query: CLIENT_PROFILE_QUERY}],
    },//@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  })
)(DateOfBirthForm)
