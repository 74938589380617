import React from 'react'
import _, {get, map, isEmpty, includes, omitBy, isNull,pickBy, keys, union, isFunction, has, find, size} from 'lodash'
import moment from 'moment'
import {withdrawalRejectionReasons, withdrawalPendingReasons, withdrawalStatuses, depositVendors, depositStatuses,
  withdrawalTypes, withdrawalPaymentFields, withdrawalPaymentVendors, transactionTypes, countries,
  directa24TaxNumberLabels, currencies,
} from '@bdswiss/common-enums'
import {Trans} from 'react-i18next'
import classNames from 'classnames'
import messages from '../../../assets/messages'
import FormHelperText from '@material-ui/core/FormHelperText'
import Done from '@material-ui/icons/Done'
import Schedule from '@material-ui/icons/Schedule'
import Missing from '@material-ui/icons/Clear'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {getFormattedAmount, safeParseJSON} from '../../../common/utils/general'
import {getAccountLabel, hasVipOrRaw, isCentAccount} from '../../../common/utils/accounts'
import LoadingButton from '../../Common/LoadingButton'
import Button from '@material-ui/core/Button'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import {friendlyFormatIBAN} from 'ibantools'

export const isDeposit = ({transactionType}) => (transactionType === transactionTypes.deposit.key)
export const isWithdrawal = ({transactionType}) => (transactionType === transactionTypes.withdrawal.key)
export const isSubscription = ({transactionType}) => (transactionType === transactionTypes.subscription.key)
export const isTransfer = ({category}) => (category === depositVendors.transfer.key)

export const pendingWithdrawalStatuses = [
  withdrawalStatuses.notProcessed.key,
  withdrawalStatuses.pending.key,
]

export const completedStatuses = [
  depositStatuses.authorized.key,
  depositStatuses.chargeback.key,
  depositStatuses.completed.key,
  depositStatuses.reversed.key,
  depositStatuses.partiallyReversed.key,
  withdrawalStatuses.accepted.key,
  withdrawalStatuses.processing.key,
  withdrawalStatuses.reversed.key,
  withdrawalStatuses.partiallyReversed.key,
]

export const pendingStatuses = [
  depositStatuses.held.key,
  withdrawalStatuses.notProcessed.key,
  withdrawalStatuses.pending.key,
]

export const rejectedStatuses = [
  depositStatuses.cancelled.key,
  depositStatuses.failed.key,
  depositStatuses.rejected.key,
  withdrawalStatuses.rejected.key,
]

export const fieldStatus = (row, classes, accounts, locale, showElement?) => {
  if (completedStatuses.includes(row.status)) {
    const processTime = isWithdrawal(row) && withdrawalPaymentVendors[row.vendor] && withdrawalPaymentVendors[row.vendor].processTime && withdrawalPaymentVendors[row.vendor].processTime.localization.t(locale)
    return <React.Fragment>
      <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})} />
      <Trans {...messages.completed} />
      {isWithdrawal(row) && showElement && processTime &&
        <Typography variant="body1" color="secondary" className={classes.lineHeight}> <Trans {...messages.expectedIn} values={{processTime}} /> </Typography>}
    </React.Fragment>
  } else if (rejectedStatuses.includes(row.status))
    return <React.Fragment>
      <Missing color='error' className={classes.statusIcon}/>
      <Trans {...messages.rejected} />
    </React.Fragment>
  else {
    return <React.Fragment>
      <Schedule className={classNames({[classes.statusIcon]: true, [classes.pendingIcon]: true})}/>
      {row.status !== depositStatuses.held.key && <Trans {...messages.inProgress} />}
      {row.status === depositStatuses.held.key && depositStatuses.held.localization.t(locale)}
      {isWithdrawal(row) && !hasVipOrRaw(accounts) && row.status === withdrawalStatuses.notProcessed.key && <Typography variant="caption">
        <Trans {...messages.inQueue} values={{position: `${row.withdrawalQueuePosition}`}} />
      </Typography>}
    </React.Fragment>
  }
}
export const fieldDetails = (row, locale, country, classes) => {
  if (isTransfer(row)) {
    const accountDetails = get(row, 'transferAccount')
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.lineHeight}>
            <Trans {...messages[(isDeposit(row)) ? 'transferFromDetails' : 'transferToDetails']}
              values={{type: getAccountLabel(accountDetails, locale),id: get(accountDetails, 'remoteId')}} />
          </Typography>
        </Grid>
      </Grid>
    )
  }
  else if (isWithdrawal(row)) {
    const showCurrencyFields =[withdrawalPaymentFields.withdrawalFee.key, withdrawalPaymentFields.withdrawalNetAmount.key]
    if (row.status === withdrawalStatuses.rejected.key) {
      return <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2"><Trans {...messages.rejectionReasonTitle} /></Typography>
          {row.withdrawalRejectionReason && row.withdrawalRejectionReason !== 'other' && <Typography variant="body1" className={classes.lineHeight}>
            {withdrawalRejectionReasons[row.withdrawalRejectionReason].localization.t(locale)}
          </Typography>}
          {row.withdrawalRejectionReason && row.withdrawalRejectionReason === 'other' && <Typography variant="body1" className={classes.lineHeight}>
            <Trans {...messages.withdrawalsOtherReasonMessage} />
          </Typography>}
        </Grid>
      </Grid>
    } else if (row.status === withdrawalStatuses.pending.key) {
      return <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2"><Trans {...messages.pendingReasonTitle} /></Typography>
          {row.withdrawalPendingReason && <Typography variant="body1" className={classes.lineHeight}>
            {withdrawalPendingReasons[row.withdrawalPendingReason].localization.t(locale)}
          </Typography>}
        </Grid>
      </Grid>
    } else if (row.withdrawalType === withdrawalTypes.performanceFee.key) {
      const meta = safeParseJSON(get(row, 'meta', '{}'))
      return <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2"><Trans {...messages.performanceFee} /></Typography>
          {get(meta,'providerName') && <Typography variant="body1" className={classes.lineHeight}>
            <Trans {...messages.performanceFeeDetails} values={{providerName: get(meta,'providerName')}} />
          </Typography>}
        </Grid>
      </Grid>
    } else {
      const withdrawalDetails = omitBy(row.paymentFields, (field, key) => {
        if (key === '__typename' || key === 'termsAndConditions' || isNull(field)) {
          return key
        }
      })
      const processTime = isWithdrawal(row) && withdrawalPaymentVendors[row.vendor] && withdrawalPaymentVendors[row.vendor].processTime && withdrawalPaymentVendors[row.vendor].processTime.localization.t(locale)
      return (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2"> <Trans {...messages.withdrawalMethod} /> </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" className={classes.lineHeight}> {withdrawalPaymentVendors[row.vendor] ? withdrawalPaymentVendors[row.vendor].localization.t(locale) : row.vendor} </Typography>
            {completedStatuses.includes(row.status) && processTime && <Typography variant="body1" color="secondary" className={classes.lineHeight}> <Trans {...messages.expectedIn} values={{processTime}} /> </Typography>}
          </Grid>
          {map(withdrawalDetails, (field, key) => {
            const withdrawalField = withdrawalPaymentFields[key]
            let options = []
            if (has(withdrawalField, 'options')) {
              options = isFunction(withdrawalField.options)
                ? withdrawalField.options({
                  withdrawalPaymentVendor: row.vendor,
                  dynamicFields: [],
                  country: get(countries[country], 'value'),
                  paymentOption: get(row.paymentFields, 'paymentOption'),
                  bankCode: get(row.paymentFields, 'provider'),
                }) : withdrawalField.options
            }

            const localeParams:any= {}
            if (row.vendor === withdrawalPaymentVendors.directa24.value) {
              localeParams.id_type = get(directa24TaxNumberLabels, country.toLowerCase())
                ? directa24TaxNumberLabels[country.toLowerCase()].label : 'ID'
            } else if (row.vendor === withdrawalPaymentVendors.payRetailers.value) {
              localeParams.id_type = '#'
            }

            return (<Grid key={key} container>
              <Grid key={`${key}1`} item xs={6}>
                <Typography variant="body2"> {withdrawalField.localization.t(locale, localeParams)} </Typography>
              </Grid>
              <Grid key={`${key}2`} item xs={6}>
                <Typography variant="body1" className={classes.lineHeight}>{includes(showCurrencyFields, key)
                  ? getFormattedAmount({amount: field, currency: row.currency})
                  : !isEmpty(options) ? get(find(options, {key: field}), 'label') || field : field}
                </Typography>
              </Grid>
            </Grid>)
          })}
        </Grid>
      )
    }
  }
  else {
    const details = get(row, 'depositPaymentFields') ||  JSON.parse(get(row, 'depositDetails'))
    const depositDetails = omitBy(details, (field, key) => {if (key === '__typename' || isNull(field)) return key })
    return (
      <Grid container>
        {map(depositDetails, (field, key) =>
          <Grid key={key} container>
            <Grid key={`${key}1`} item xs={6}>
              <Typography variant="body2"> {withdrawalPaymentFields[key].localization.t(locale)} </Typography>
            </Grid>
            <Grid key={`${key}2`} item xs={6}>
              <Typography variant="body1" className={classes.lineHeight}>{key !== 'iban' ? field : friendlyFormatIBAN(field)}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

export const showAmount = row => {
  let amount = row.amount, currency = row.currency
  if (isCentAccount(row.account)) {
    amount = row.amount / (currencies.CUD.baseCurrencyRate ?? 1)
    currency = currencies.CUD.baseCurrency
  }
  let transactionAmount : any
  if (row.transactionType === transactionTypes.deposit.key) {
    transactionAmount = `+ ${getFormattedAmount({amount, currency})}`
  } else if (row.transactionType === transactionTypes.withdrawal.key) {
    transactionAmount = `- ${getFormattedAmount({amount, currency})}`
  } else if (row.transactionType === transactionTypes.subscription.key) {
    transactionAmount = `${getFormattedAmount({amount, currency})}`
  }
  return transactionAmount
}

export const applyFilter = (transactions, _filter) => {
  const statuses = [
    ...(_filter.completed ? completedStatuses : []),
    ...(_filter.pending ? pendingStatuses : []),
    ...(_filter.rejected ? rejectedStatuses : []),
  ]

  const types = [
    ...(_filter.deposit ? [transactionTypes.deposit.key] : []),
    ...(_filter.withdrawal ? [transactionTypes.withdrawal.key] : []),
    ...(_filter.subscription ? [transactionTypes.subscription.key] : []),
    ...(_filter.transfer ? [transactionTypes.transfer.key] : []),
  ]

  const includingTransfers = get(_filter, transactionTypes.transfer.value) === true
  const onlyTransfers = size(types) === 1 && includes(types, transactionTypes.transfer.value)

  return _.chain(transactions).filter(t =>
    (!_filter.accountId ||  _filter.accountId === 'all' || Number(_filter.accountId) === t.account.id) &&
      (isEmpty(statuses) || includes(statuses, t.status)) &&
      ((!onlyTransfers && (isEmpty(types) || (includes(types, t.transactionType) && (includingTransfers || (!includingTransfers && t.category !== 'transfer'))))) ||
        (includingTransfers && t.category === transactionTypes.transfer.value)
      )
  ).value()
}

export const canCancelWithdrawal = row => {
  const isWithin24Hrs = moment(row.createdAt).add(1, 'day').isAfter(moment())
  const clientCancelPermitted = isWithdrawal(row) && get(withdrawalTypes, [row.withdrawalType, 'clientCreatePermitted'])
  return clientCancelPermitted &&
        isWithin24Hrs &&
        clientCancelPermitted &&
        pendingWithdrawalStatuses.includes(row.status)
}

export const showAllDetails = (row, props, locale) => {
  let details : any = '-'
  if (isSubscription(row) || (isDeposit(row) && row.vendor !== depositVendors.bankWire.value)) {
    details = get(row, 'depositDetails')
  }
  else {
    details = <Tooltip title={props.t(messages.viewDetails.i18nKey)} placement="top">
      <Button onClick={()=> props.handleOpen(row, locale)} className={props.classes.detailsInfo}>
        <InfoIcon style={{fontSize: 16}}/>
      </Button>
    </Tooltip>
  }
  if (details === '-') {
    details = <span className={props.classes.spanDetails}>{details}</span>
  }
  return details
}

export const transactionType = row => {
  if (isDeposit(row)) {
    const depositVendor = get(row, 'vendor', '')
    if (depositVendors[depositVendor].manuallyCreated && depositVendor !== depositVendors.manual.key) {
      let message
      switch (depositVendor) {
        case 'transfer':
        case 'transferb2b':
        case 'transferSpoab2b': {
          message = messages.transfer
          break
        }
        default: {
          message = messages[depositVendor]
        }
      }
      return <Trans {...message} />
    } else {
      if (depositVendor === depositVendors.depositBonus.key) return <Trans {...messages.bonus} />
      return <Trans {...messages.deposit} />
    }
  }
  if (isWithdrawal(row)) {
    let message
    switch (row.withdrawalType) {
      case 'vps_fee': {
        message = messages.vpsFee
        break
      }
      // todo:I think guessWithdrawalLabel() does a good job here, we should use that and provide overrides from CRM

      // case 'inactivityFee': {
      //   message = messages.inactivityFee
      //   break
      // }
      case 'transfer':
      case 'transferWithdrawalCommisions':
      case 'transferb2b':
      case 'transferSpoab2b': {
        message = messages.transferWithdrawal
        break
      }
      case 'performanceFee': {
        message = messages.performanceFee
        break
      }
      default: {
        message = messages[row.withdrawalType] ?? {
          i18nKey: row.withdrawalType,
          defaults: guessWithdrawalLabel(row.withdrawalType),
        }
      }
    }
    return <Trans {...message} />
  }
  if (isSubscription(row)) {
    return <Trans {...messages.subscription} />
  }
}

export const guessWithdrawalLabel = (key: string): string => {
  let parts = key.split(/(?=[A-Z])/)
  if (key.includes('_')) {
    parts = key.split('_')
  }
  const ucFirst = parts.map( x => x.charAt(0).toUpperCase() + x.slice(1))
  const result = ucFirst.join(' ')
  return result
}


export const transactionAccountName = (row, locale) => `${getAccountLabel(row.account, locale)} - ${row.account.remoteId}`

export const partialDepositAction = (depositAction, row, history, setLoading) => {
  setLoading(true)
  depositAction({
    variables: {depositId: row.id}
  }).then((res) => {
    const resultStatus = res.data.action.status === depositStatuses.completed.key
      ? 'success' : res.data.action.status === depositStatuses.failed.key ? 'failed' : 'pending'
    history.push(`/transactions/${get(row, 'account.id')}/deposit/result/${resultStatus}`)
  }).catch((e) => {
    history.push(`/transactions/${get(row, 'account.id')}/deposit/result/pending`)
  })
}

export const getPartialDepositButtons = (row, props, state, setLoading) => {
  const {classes, history} = props
  if (row.transactionType === transactionTypes.deposit.key && row.status === depositStatuses.incomplete.key) {
    const meta = safeParseJSON(get(row, 'meta', '{}'))
    if (!get(meta, 'isPartialApproval')) {
      return
    }
    const buttons = [
      <LoadingButton
        key="credit"
        size="small"
        variant="text"
        color="primary"
        disabled={state.loading}
        classes={{root: classes.cancelBtn}}
        onClick={() => partialDepositAction(props.completeDeposit, row, history, setLoading)}
      >
        <Trans {...messages.credit} />
      </LoadingButton>
    ]

    if (row.showCancelPartialDeposit) {
      buttons.push(<LoadingButton
        key="cancel"
        size="small"
        variant="text"
        classes={{root: classes.cancelBtn}}
        className={classes.cancelPartialBtn}
        disabled={state.loading}
        onClick={() => partialDepositAction(props.cancelDeposit, row, history, setLoading)}
      >
        <Trans {...messages.cancel} />
      </LoadingButton>)
    }
    return buttons
  }
}

export const transactionsLimits = [10, 20, 40]


export const renderHelperText = (filter, classes) => {
  const allowedStatus = [withdrawalStatuses.rejected.key, withdrawalStatuses.pending.key, depositStatuses.completed.key]
  const filteredStatuses = keys(pickBy(filter, (value, key) => includes(allowedStatus, key) && value === true ))
  const allowedTypes = [transactionTypes.deposit.key, transactionTypes.withdrawal.key, transactionTypes.subscription.key]
  const filteredTypes = keys(pickBy(filter, (value, key) => includes(allowedTypes, key) && value === true ))
  const filters = union(filteredStatuses, filteredTypes)
  const accountId = get(filter, 'accountId')

  return (!isEmpty(filters) || !isNaN(accountId)) && <FormHelperText className={classes.textRight}>
    * <Trans {...messages.filtersApplied} />
  </FormHelperText>
}
