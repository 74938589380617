import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import Grid from '@material-ui/core/Grid'
import {withNamespaces} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import {config} from '../../../config'
import AppContext from '../../Common/contexts/AppContext'
import {Fab} from '@material-ui/core'
import {InnerAppContext} from '../../../common/types.js'

const styles = (theme) => ({
  button: {
    background: 'none',
    marginLeft: 'auto',
    boxShadow: 'none',
    color: theme.palette.black.color,
    '&:hover':{
      backgroundColor: theme.palette.background.default,
    },
    '&:active':{
      backgroundColor: theme.palette.background.default,
    }
  },
  container:{
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    },
  }
})

class CompareAccountTypes extends Component<any,any> {

  static contextType = AppContext
  context!: InnerAppContext

  render() {
    const {locale, themePreference} = this.context
    const {classes, history} = this.props
    const {accountTypesComparisonUrl} = config
    const accountsComparisonUrl = accountTypesComparisonUrl.replace('{lang}', locale) +`?theme=${themePreference}`

    return  <Dialog
      fullScreen
      open={true}
    >
      <Grid container className={classes.container}>
        <Fab className={classes.button} onClick={() => history.push('/accounts/add-account')}>
          <CloseIcon/>
        </Fab>
        <iframe
          src={accountsComparisonUrl}
          frameBorder="0"
          name="CompareAccountTypes"
          id="CompareAccountTypes"
          title="CompareAccountTypes"
          style={{overflow: 'scroll', height: '100%', width: '100%'}}
        />
      </Grid>
    </Dialog>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(CompareAccountTypes)

