import {Link} from 'react-router-dom'
import React, {Component} from 'react'
import {isEmpty, split, omit} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import FormHelperText from '@material-ui/core/FormHelperText'
import LoadingButton from '../Common/LoadingButton'
import HeaderDialog from '../Common/Dialog/HeaderDialog'
import messages from '../../assets/messages'
import {resetPasswordRequest, resetPasswordFinish} from '../../common/utils/requests'
import PageTitle from '../Common/PageTitle'
import NotificationBar from '../Common/NotificationBar'
import {checkPassword} from '../../common/utils/general'
import AppContext from '../Common/contexts/AppContext'
import {Password} from '../Common/Password'
import {validateEmail} from '../../common/utils/validations'
import {InnerAppContext} from '../../common/types'

const styles = theme => ({
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'auto',
    position: 'relative' as const,
  },
  formControl:{
    display: 'block',
    marginTop: '40px',
    width: '100%'
  },
  wrapper: {
    padding: theme.spacing(1),
    maxWidth: 400,
    margin: 30
  },
  grid: {
    marginTop:30,
    marginBottom: 30,
  },
  successDiv: {
    backgroundColor:  theme.palette.lightgreen.color,
    padding: '15px 10px'
  },
  successMessage: {
    color:  theme.palette.lightgreen.textColor,
  },
  error: {
    color: theme.palette.error.main,
  },
  errorMessage:{
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '13px'
  },
  backToLoginText:{
    marginLeft:2
  },
  resetPassTitle:{
    paddingLeft:0
  },
})

class ForgotPassword extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    const path = props.history.location.pathname
    const token = split(path, '/')
    super(props)
    this.state = {
      token: token[2],
      form: {
        email: '',
        newPassword: '',
        confirmPassword: '',
      },
      formErrors: {
        email: false,
        newPassword: false,
        confirmPassword: false,
        passwordMismatch: false,
        onSubmit: false,
      },
      passwordError: '',
      formStatus: '',
      loading: false,
      showNewPassword: false,
      showConfirmPassword: false,
    }
  }

  onInputChange(field, value) {
    const {form, formErrors} = this.state
    form[field] = value
    let newFormErrors = this.checkMatchPasswords()
    if (isEmpty(form[field])) {
      formErrors[field] = true
      this.setState({form: form, formErrors: formErrors, formStatus: ''})
    } else {
      newFormErrors = omit(newFormErrors, [field, 'onSubmit'])
      this.setState({form: form, formErrors: newFormErrors, formStatus: ''})
    }
  }

  checkMatchPasswords() {
    const {form: {newPassword, confirmPassword}, formErrors} = this.state
    if (newPassword && confirmPassword && (newPassword !== confirmPassword)) {
      formErrors.passwordMismatch = true
    } else {
      const newFormErrors = omit(formErrors, ['passwordMismatch'])
      return newFormErrors
    }
    return formErrors
  }

  checkPassword(password) {
    const passwordError = checkPassword(password)
    this.setState({passwordError: (isEmpty(passwordError)) ? '' : <Trans {...messages[passwordError]} />})
  }

  validateForm() {
    const {token, form, formErrors, passwordError} = this.state

    if (token) {
      if (formErrors.passwordMismatch || passwordError) {
        return false
      } else if (form.newPassword === '' && form.confirmPassword === '') {
        this.setState({formErrors: {newPassword: true, confirmPassword: true}})
      } else if (form.newPassword === '') {
        this.setState({formErrors: {newPassword: true}})
      } else if (form.confirmPassword === '') {
        this.setState({formErrors: {confirmPassword: true}})
      } else {
        return true
      }
    } else {
      if (form.email === '' || !validateEmail(form.email)) {
        this.setState({formErrors: {email: true}})
      } else {
        return true
      }
    }
  }

  handleSubmit() {
    const {history} = this.props
    const {token, form: {email, newPassword}} = this.state
    if (this.validateForm()) {
      if (token) {
        this.setState({loading: true})
        resetPasswordFinish(newPassword, token)
          .then((res) => {
            this.setState({loading: false, formStatus: 'success', successMessage: messages.resetPasswordNotification},
              history.push({pathname: '/login', state: {resetPassword: true}}))
          })
          .catch((err) => {
            this.setState({loading: false, formStatus: 'failure', formErrors: {onSubmit: err}})
          })
      } else {
        this.setState({loading: true})
        resetPasswordRequest(email)
          .then((res) => {
            this.setState({loading: false, formStatus: 'success', successMessage: messages.forgotPasswordNotification})
          })
          .catch((err) => {
            this.setState({loading: false, formStatus: 'failure', formErrors: {onSubmit: err}})
          })
      }
    }
  }

  render() {
    const {t, classes} = this.props
    const {form: {email, newPassword, confirmPassword}, formErrors, formStatus, loading, token,
      showNewPassword, showConfirmPassword, successMessage, passwordError} = this.state
    const statusForm = !formStatus ? !!formStatus : formStatus

    return (
      <div className={classes.mainBody}>
        <HeaderDialog />
        <Grid container>
          <Hidden xsDown>
            <Grid item sm={3} />
          </Hidden>
          <Grid item sm={9} xs={12} className={classes.wrapper}>
            <Grid container spacing={1}>
              {formStatus ==='success' &&
              <Grid item xs={12} sm={12}>
                <NotificationBar status='success'><Trans {...successMessage} /></NotificationBar>
              </Grid>
              }
              <Grid item xs={12} sm={12} className={classes.wrapper}>
                <PageTitle modal>
                  <Trans {...messages.resetPassword} />
                </PageTitle>
                {!token && <Grid item sm={12} className={classes.grid}>
                  <TextField
                    fullWidth
                    autoFocus
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => this.onInputChange('email', e.target.value)}
                    label={t(messages.email.i18nKey, messages.email.defaults)}
                    error={formErrors.email}
                    helperText={formErrors.email && t(messages.emailValidation.i18nKey, messages.emailValidation.defaults)}
                  />
                </Grid>}
                {token && <Grid item sm={12} className={classes.grid}>
                  <Password
                    classes={classes}
                    showPassword={showNewPassword}
                    onChange={(e) => {
                      this.onInputChange('newPassword', e.target.value)
                      this.checkPassword(e.target.value)
                    }}
                    error={formErrors.newPassword || passwordError}
                    onClickShow={()=> this.setState({showNewPassword: !showNewPassword})}
                    fullWidth
                    errorText={passwordError ? passwordError :  <Trans {...messages.requiredField} />}
                    value={newPassword}
                    label={'newPassword'}
                  />
                </Grid>}
                {token && <Grid item sm={12} className={classes.grid}>
                  <Password
                    id={'confirmationPassword'}
                    classes={classes}
                    showPassword={showConfirmPassword}
                    onChange={(e) => this.onInputChange('confirmPassword', e.target.value)}
                    error={formErrors.confirmPassword}
                    onClickShow={()=> this.setState({showConfirmPassword: !showConfirmPassword})}
                    fullWidth
                    errorText={<Trans {...messages.requiredField} />}
                    value={confirmPassword}
                    label={'passwordConfirmation'}
                  />
                </Grid>}
                <LoadingButton
                  disabled={loading || statusForm}
                  status={formStatus}
                  onClick={() => this.handleSubmit()}
                  hideProgressBar={statusForm}
                >
                  <Trans {...messages.resetPassword} />
                </LoadingButton>
                {((formStatus === 'failure' && formErrors.onSubmit) || formErrors.passwordMismatch) &&
                <FormHelperText className={classes.errorMessage}>
                  {formErrors.onSubmit ? formErrors.onSubmit.toString() : <Trans {...messages.passwordMismatch} />}
                </FormHelperText>}
                {((token && formStatus === 'success') || !token )&& <Link to="/login">
                  <Typography variant='body2' color='primary' className={classes.backToLoginText}>
                    <Trans {...messages.backToLogin} />
                  </Typography>
                </Link>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(withNamespaces()(ForgotPassword))
