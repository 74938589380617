import {map, get, has, flowRight as compose} from 'lodash'
import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import Hidden from '@material-ui/core/Hidden'
import FormLabel from '@material-ui/core/FormLabel'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import messages from '../../../../assets/messages'
import AppContext from '../../../Common/contexts/AppContext'
import FormHelperText from '@material-ui/core/FormHelperText'
import {InnerAppContext} from '../../../../common/types'

const styles = theme => ({
  questions: {
    marginBottom:20
  },
  answersGroup:{
    margin:16
  },
  question: {
    marginBottom:10
  },
  answer:{
    lineHeight:1.5
  },
  error:{
    color: theme.palette.red.color,
  },
  errorText:{
    color: theme.palette.red.color,
    marginTop: 0,
    marginLeft: 18
  }
})

export class APTestQuestions extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  render() {
    const {t, form, formState, errors, activeStep, onChange, classes, questions} = this.props
    const {locale, companyObject} = this.context

    return (
      map(questions, (q, questionKey) => {
        const {step: {stepPosition}, options, description} = q
        const answerKey = get(form, `answers[${questionKey}]` ,'')
        const hasError = formState === 'validation' && has(errors, questionKey)

        return (
          stepPosition === activeStep &&
          <Grid key={`${questionKey}-row`} container>
            <Grid key={`${questionKey}-column-1`} item xs={9}>
              <FormControl key={questionKey} component="fieldset" className={classes.questions} >
                <FormLabel
                  id={questionKey}
                  component="legend"
                  error={hasError}
                  className={classes.question}>
                  <Typography variant='body2' className={hasError ? (classes.error): ''}>{q.orderLabel(companyObject.value)}. {q.localization.t(locale, {domain: companyObject.emailDomain})}</Typography>
                  {hasError && <FormHelperText className={classes.errorText}><Trans {...messages.requiredField} /></FormHelperText>}
                </FormLabel>
                <RadioGroup
                  row
                  name={questionKey}
                  value={answerKey}
                  onChange={(e) => onChange(e.target)}
                  className={classes.answersGroup}
                >
                  {map(options, (answer, answerKey) =>
                    (!has(answer, ['disable']) && <FormControlLabel
                      style={{width: '49%', minWidth: '310px'}}
                      key={answerKey}
                      value={answerKey}
                      classes={{label: classes.answer}}
                      label={answer.localization.t(locale, {domain: companyObject.emailDomain})}
                      control={<Radio
                        color="primary"/>}
                    />)
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Hidden mdDown>
              <Grid key={`${questionKey}-column-2`} item xs={3} sm={3}>
                {description &&
                  <Typography variant="body1">
                    {t(messages[description].i18nKey, messages[description].defaults)}
                  </Typography>}
              </Grid>
            </Hidden>
          </Grid>
        )
      })
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(APTestQuestions)
