import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import {Typography} from '@material-ui/core'
import messages from '../../../../assets/messages'
import Amount from '../../../Common/Amount'
import {isMobile} from '../../../../common/utils'
import {get} from 'lodash'
import classNames from 'classnames'

const fontRatio = isMobile() ? 0.5 : 0.7
const styles = theme => ({
  amountCurrency: {
    fontSize: Math.floor(24 * fontRatio),
    fontWeight: 300,
    padding: '0 3px',
  },
  amountValue: {
    fontSize: Math.floor(36 * fontRatio),
    fontWeight: 300,
  },
  amountFraction: {
    fontSize: Math.floor(24 * fontRatio),
    fontWeight: 300,
  },
  providerItem: {
    marginTop: 0,
    textAlign: 'left' as const,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  providerGrid: {
    padding: '0px 10px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  providerCardItem:{
    marginTop: isMobile() ? 2 : 5,
    display: 'block',
    fontSize: Math.floor(36 * fontRatio),
    fontWeight: 300,
  },
  textRight: {
    textAlign: 'right' as const
  },
  notificationBox: {
    backgroundColor: theme.palette.extralightgrey.color,
    marginTop: 15
  }
})

class ProviderStatsCard extends Component<any,any> {

  constructor(props) {
    super(props)
    this.state = {
      status: '',
      loading: false
    }
  }

  render() {
    const {classes, account, summaries, allTime} = this.props
    return <Grid container>
      <Grid item xs={12} className={classes.providerGrid}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item className={classes.providerItem} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages[allTime ? 'totalFollowersCount' : 'monthlyFollowersCount']} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>{get(summaries, allTime ? 'totalFollowersCount' : 'monthlyFollowersCount',0)}</Typography>
          </Grid>

          {
            allTime && (
              <Grid item className={ classNames(classes.providerItem, isMobile() && classes.textRight) } xs={ isMobile() ? 6 : 'auto' }>
                <Typography variant='caption'><Trans { ...messages.totalActiveFollowersCount } /></Typography>
                <Typography variant='body1' className={ classes.providerCardItem }>{ get(summaries, 'totalActiveFollowersCount') }</Typography>
              </Grid>
            )
          }

          <Grid item className={classNames(classes.providerItem)} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages[allTime ? 'totalLosingTradesCount' : 'monthlyLosingTradesCount']} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>{get(summaries, allTime ? 'totalLosingTradesCount' : 'monthlyLosingTradesCount', 0)}</Typography>
          </Grid>
          <Grid item className={classNames(classes.providerItem, isMobile() && classes.textRight)} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages[allTime ? 'totalWinningTradesCount' : 'monthlyWinningTradesCount']} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>{get(summaries, allTime ? 'totalWinningTradesCount' : 'monthlyWinningTradesCount', 0)}</Typography>
          </Grid>
          <Grid item className={classNames(classes.providerItem)} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages[allTime ? 'totalCopiedPnl' : 'monthlyCopiedPnl']} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>
              <Amount
                value={get(summaries, allTime ? 'totalCopiedPnl' : 'monthlyCopiedPnl')} currency={account.currency} locale='en-US' classes={{
                  currency: classes.amountCurrency,
                  value: classes.amountValue,
                  fraction: classes.amountFraction
                }} />
            </Typography>
          </Grid>
          <Grid item className={classNames(classes.providerItem, isMobile() && classes.textRight)} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages[allTime ? 'totalPerformanceFee' : 'monthlyPerformanceFee']} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>
              <Amount
                value={get(summaries, allTime ? 'totalPerformanceFee' : 'monthlyPerformanceFee')} currency={account.currency} locale='en-US' classes={{
                  currency: classes.amountCurrency,
                  value: classes.amountValue,
                  fraction: classes.amountFraction
                }} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }
}

export default withStyles(styles, {withTheme: true})(withNamespaces()(ProviderStatsCard))
