import moment from 'moment'
import {find, some, filter, isEmpty} from 'lodash'

export function validateEmail(email) {
  const pattern =  RegExp("(?!.*\\.$)(?!.*?\\.\\.)^(?!\\.)[^@]+@[^@]+\\.[^@]{2,}$") // eslint-disable-line
  const isValid = pattern.test(email)
  return isValid
}

export function  validatePhone(phone,prefix?) {
  const isPhone = (prefix)?RegExp(/^\+\d+$/).test(phone):RegExp(/^\d+$/).test(phone)
  return isPhone
}

export function validateDate(date, format = false, allowContinuously = false) {
  const dateFormats = [
    'YYYY-MM-DD',
    'DD/MM/YYYY',
    'MM/DD/YYYY',
    'DD-MM-YYYY',
    'MM-DD-YYYY',
    'YYYY/MM/DD',
  ]
  allowContinuously && dateFormats.push('DDMMYYYY')
  const valid = format ?  find(dateFormats, (dateFormat) => moment(date, dateFormat, true).isValid()) : some(dateFormats, (dateFormat) => moment(date, dateFormat, true).isValid())
  return  format ? valid : Boolean(valid)
}

export function validateNumber(value) {
  return !isNaN(Number(value))
}

export function getMimetype(signature) {
  switch (signature) {
    case '89504E47':
      return 'image/png'
    case '25504446':
      return 'application/pdf'
    case 'FFD8FFDB':
    case 'FFD8FFE0':
    case 'FFD8FFE1':
    case 'FFD8FFEE':
      return 'image/jpeg'
    case '52494646':
      return 'image/jpg'
    case '504B0304':
      return 'application/zip'
    default:
      return 'Unknown filetype'
  }
}

export function checkFileMimeType(file) {
  const blob = file.slice(0, 4)
  return new Promise((resolve, reject) => {
    const filereader = new FileReader()
    filereader.onerror = () => {
      filereader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }
    filereader.onload = function(evt) {
      if (evt.target?.readyState === FileReader.DONE) {
        //@ts-ignore
        const uint = new Uint8Array(evt.target!.result)
        const bytes: string[] = []
        uint.forEach((byte) => {
          bytes.push(byte.toString(16))
        })
        const hex = bytes.join('').toUpperCase()
        resolve(getMimetype(hex))
      }
    }
    filereader.readAsArrayBuffer(blob)
  })
}

export function validateDOB(birthday) {
  const dateFormat = validateDate(birthday, true, true)
  if (dateFormat) {
    //@ts-ignore
    const datesDifference = moment.duration(moment().diff(moment(birthday, dateFormat, true)))
    const age = datesDifference.years()
    const birthdayDay = datesDifference.days()
    return age >= 18 && (age < 120 || (age === 120 && birthdayDay === 0))
  } return false
}

export function validateLength(value, maxLength?, minLength?, exactLength?) {
  if (maxLength) return value.length <= maxLength
  if (minLength) return value.length >= minLength
  if (exactLength) return value.length === exactLength
  return value.length <= maxLength
}

export function validateCharacters(value) {
  if (!value) return true
  else if (value.length < 2) return 'minLengthError'
  else if (value.length > 15) return 'maxNameLengthError'
  else if (/(\s{2,})/gmi.test(value)) return 'consecutiveSpaces'
  // Checks if latin or arabic (arabic added in SKYG-782)
  else if (/(.)\1{2,}|^(?![A-Za-z\u0600-\u06FF]{1})|(?!([A-Za-z\u0600-\u06FF\- ]*$))/gmi.test(value)) return 'validationError'
  else return false
}

export function validateCountryCodePrefix(prefix, countryList) {
  const value = prefix.replace(/^\++/, '')
  const allowedPrefixes= filter(countryList, (c) => c.callingCodes ? find(c.callingCodes, (cc) => value.startsWith(cc))
    : value.startsWith(c.callingCode))
  return !isEmpty(allowedPrefixes)
}

export function validateAccountName(value) {
  if (!value) return true
  else if (!(RegExp("^(?! *$)[a-zA-Z0-9-_,.#/! ]{1,}$").test(value))) return 'validationAccountNameError'  // eslint-disable-line
  else return false
}

export const specificProviderValidations = {
  cardpay: {
    street: '^[A-Za-z0-9-\',"./; ]{1,50}$',
    city: '^[A-Za-z0-9-\',. ]{0,50}$',
    zip: '^.{0,12}$'
  }
}

export function validateAddress(address) {
  if (!address) return true

  // Allow for Arabic characters as well, as per task SKYG-793
  const checkAddress = RegExp(/^[\p{L}\d]+([\s\p{L}\d]+)*$/u).test(address)

  if (!checkAddress) {
    return 'validationAddressError'
  } else {
    return false
  }
}

export function validatePartnerCode(value) {
  return !(RegExp(/^[0-9]{4,10}$/).test(value))
}

export function validateOnlyNumbers(value) {
  return !(RegExp(/^[0-9]*$/).test(value))
}
